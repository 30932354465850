import { OmitProps } from "antd/lib/transfer/ListBody";
import React from "react";

const CompassSvg = (props) => (
  <svg
    style={{ margin: "0px", padding: "0px", height: "90px", width: "200px" }}
  >
    <svg
      height="50px"
      width="auto"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      stroke={props.strokeColor1}
      fill={props.strokeColor1}
    >
      <g transform={props.scaleFactor}>
        <g transform={props.rotationAngle}>
          <path d="M60,30H58.918A26.994,26.994,0,0,0,34,5.081V4a2,2,0,0,0-4,0V5.082A26.994,26.994,0,0,0,5.081,30H4a2,2,0,0,0,0,4H5.082A26.994,26.994,0,0,0,30,58.919V60a2,2,0,0,0,4,0V58.919A26.994,26.994,0,0,0,58.918,34H60a2,2,0,0,0,0-4Zm-6,4h.908A23,23,0,0,1,34,54.9V54a2,2,0,0,0-4,0v.9A23,23,0,0,1,9.092,34H10a2,2,0,0,0,0-4H9.1A23,23,0,0,1,30,9.092V10a2,2,0,0,0,4,0V9.1A23,23,0,0,1,54.908,30H54a2,2,0,0,0,0,4Z" />
          <polygon points="27 25 32 16 37 25 32 22 27 25" />
          <path d="M30,39H28V29a1,1,0,0,1,1.832-.555L34,34.7V28h2V38a1,1,0,0,1-1.832.555L30,32.3Z" />
        </g>
      </g>
    </svg>
  </svg>
);
export default CompassSvg;
