import React from "react";
import Joi from "joi-browser";
import { setJwt } from "../services/httpService";
import Form from "./common/form";
import {
  createCompany,
  updateCompanyContact,
  getCompanies
} from "../services/companyService";

import { addCompanyAdmin } from "../services/userService";
import BackGroundRectangle from "../components/backgroundRect";

class NewCompanyForm extends Form {
  state = {
    data: {
      companyName: "",
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      companyAddress: ""
    },
    errors: {}
  };

  schema = {
    companyName: Joi.string()
      .required()
      .label("Company Name"),
    username: Joi.string()
      .required()
      .label("Username"),
    contactName: Joi.string()
      .required()
      .label("Contact Name"),
    contactEmail: Joi.string()
      .required()
      .label("Contact Email Address"),
    contactPhone: Joi.string()
      .required()
      .label("Contact Phone Number"),
    companyAddress: Joi.string()
      .required()
      .label("Contact Address")
  };

  doSubmit = async () => {
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    await createCompany(this.state.data.companyName);
    await addCompanyAdmin(
      this.state.data.username,
      this.state.data.companyName
    );
    await updateCompanyContact(
      this.state.data.companyName,
      this.state.data.contactName,
      this.state.data.contactEmail,
      this.state.data.contactPhone,
      this.state.data.companyAddress
    );
    await getCompanies();
    this.props.history.push("/companies");
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1
          }}
        >
          <p className="basicParagraph">
            Register a new company: You must be logged in as Simpl Global's
            Admin.
          </p>
          <form onSubmit={this.handleSubmit}>
            {this.renderInput("companyName", "Company Name")}
            {this.renderInput("username", "Admin Username")}
            {this.renderInput("contactName", "Contact Name")}
            {this.renderInput("contactEmail", "Contact Email Address")}
            {this.renderInput("contactPhone", "Contact Phone Number")}
            {this.renderInput("companyAddress", "Contact Mailing Address")}
            {this.renderButton("Register Company")}
          </form>
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default NewCompanyForm;
