import React, { Component } from "react";

import * as echarts from "echarts/dist/echarts.js";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactExport from "react-data-export";
import "./dashboard.css";
import {
  getSystemIntervalData,
  getSystemInfo,
  getSystemIDfromHandle,
  getSystem,
  getSystemDailyData,
  getSystemBasics,
  setSystemSignals,
  initSystemUpdate,
  getSystemLatestData,
  ws_getSystemLatestData,
} from "../services/systemService";
import zIndex from "@material-ui/core/styles/zIndex";
import { faCommentsDollar } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import { webSocketMode } from "../config.json"; //0: http; 1:websocket
import { isNullOrUndefined } from "joi-browser";

class DemandDaily extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    this.chart = echarts.init(document.getElementById("demand-graph"));
    console.log("Demand Daily Max Priority:", this.props.dailyMaxPriority);
    this.generateGraph();
    this.chart.setOption({
      xAxis: {
        data: this.props.calendarDay,
      },
      series: [
        {
          name: "Grid (kWh)",
          data: this.props.gridDaily,
        },
        {
          name: "Max Priority",
          data: this.props.dailyMaxPriority,
        },
      ],
    });
  }

  generateGraph() {
    var option = {
      xAxis: {
        name: "",
        nameLocation: "end",
        nameGap: 50,
        type: "category",
        nameTextStyle: {
          show: true,
          fontStyle: "normal",
          fontSize: 16,
          fontWeight: "bolder",
          color: "green",
        },
        axisLabel: {
          fontStyle: "normal",
          fontSize: 8,
          fontWeight: "normal",
          color: "orange",
        },
        boundaryGap: false,
        data: this.props.date,
      },
      yAxis: [
        {
          show: true,
          name: "Power (kW)",
          position: "left",
          nameLocation: "end",
          splitLine: {
            show: false,
          },
          boundaryGap: [0, "10%"],
          type: "value",
          nameTextStyle: {
            fontStyle: "normal",
            fontSize: 16,
            fontWeight: "bolder",
            color: "green",
          },
          axisLabel: {
            fontStyle: "normal",
            fontSize: 10,
            fontWeight: "bold",
            color: "green",
          },
        },
        {
          show: true,
          name: "Priority Level",
          position: "right",
          nameLocation: "end",
          splitLine: {
            show: false,
          },
          boundaryGap: [0, "10%"],
          type: "value",
          nameTextStyle: {
            fontStyle: "normal",
            fontSize: 16,
            fontWeight: "bolder",
            color: "green",
          },
          axisLabel: {
            fontStyle: "normal",
            fontSize: 10,
            fontWeight: "bold",
            color: "green",
          },
        },
      ],
      dataZoom: [
        {
          type: "slider",
          show: true,
          xAxisIndex: [0],
          start: 1,
          end: 100,
        },
        {
          type: "inside",
          xAxisIndex: [0],
          start: 1,
          end: 100,
        },
      ],
      series: [
        {
          name: "Building Interval Data (kWh)",
          type: "bar",
          symbol: "none",
          barWidth: "100%",
          itemStyle: {
            borderColor: "gray",
            color: "purple",
          },
          areaStyle: {
            normal: {},
          },
          data: this.props.gridDaily,
        },
        {
          name: "Priority Level",
          type: "line",
          step: "middle",
          yAxisIndex: 1,
          symbol: "none",
          color: "orange",
          label: {
            show: true,
            position: "top",
          },
          data: this.props.dailyMaxPriority,
        },
      ],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
            precision: "2",
          },
        },
      },
    };
    this.chart.setOption(option);
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "100%",
            position: "absolute",
            left: "50%",
            transform: "translate(-50%, 60%)",
            marginTop: "200px",
            marginBottom: "0px",
            paddingTop: "100px",
          }}
        >
          <div id="demand-graph" />
        </div>
      </React.Fragment>
    );
  }
}
export default DemandDaily;
