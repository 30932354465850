import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ApplianceModal(props) {
  let topPosition = props.topPosition;
  let leftPosition = props.leftPosition;

  topPosition += 95;
  leftPosition -= 125;

  let startTime = "";
  let endTime = "";
  let tempLo = 0;
  let tempHi = 0;
  let temp = 0;
  let onTime = 0;
  let rh = 0;
  let rth = 0;
  let lux = 0;
  let ratedPower = 0;
  let dataAge = 0;
  let shedStatus = 0;
  let sheddable = 0;
  let doorOpenTime = 0;
  let statStage1Cooling = 0;
  let statStage1Heating = 0;
  let statStage2Cooling = 0;
  let statStage2Heating = 0;
  let statFan = 0;

  let loadType = "";

  loadType = props.applianceInfo[props.applianceIndex].loadType;
  startTime = props.applianceInfo[props.applianceIndex].startTime;
  endTime = props.applianceInfo[props.applianceIndex].endTime;
  onTime = props.applianceInfo[props.applianceIndex].onTime;
  tempLo = props.applianceInfo[props.applianceIndex].tempLo;
  tempHi = props.applianceInfo[props.applianceIndex].tempHi;
  temp = (
    (props.applianceInfo[props.applianceIndex].tempReal / 100) * 1.8 +
    32
  ).toFixed(1);

  rh = (props.applianceInfo[props.applianceIndex].rhReal / 100).toFixed(1);
  rth = props.applianceInfo[props.applianceIndex].rthReal / 100;
  lux = props.applianceInfo[props.applianceIndex].luxReal;
  ratedPower = props.applianceInfo[props.applianceIndex].powerRating.toFixed(1);
  dataAge = props.applianceInfo[props.applianceIndex].sensorDataAge;
  shedStatus = props.applianceInfo[props.applianceIndex].shedStatus;
  sheddable = props.applianceInfo[props.applianceIndex].sheddable;
  doorOpenTime = props.applianceInfo[props.applianceIndex].loraDoorOpenTime;

  statStage1Cooling =
    props.applianceInfo[props.applianceIndex].relaySetting & 0x0001;
  statStage1Heating =
    (props.applianceInfo[props.applianceIndex].relaySetting & 0x0002) >> 1;
  statStage2Cooling =
    (props.applianceInfo[props.applianceIndex].relaySetting & 0x0004) >> 2;
  statStage2Heating =
    (props.applianceInfo[props.applianceIndex].relaySetting & 0x0008) >> 3;
  statFan =
    (props.applianceInfo[props.applianceIndex].relaySetting & 0x0010) >> 4;

  return (
    <Modal
      style={{
        borderWidth: "0px",
        width: "200px",
        height: "300px",
        alignContent: "center",
        position: "fixed",
        top: "30%",
        left: "50%",
        transform: "translate(-25%, 0%)",
        // top: `${topPosition}px`,
        // left: `${leftPosition}px`,
      }}
      backdrop={false}
      show={props.show}
    >
      <Modal.Body>
        <button
          className="btn btn-outline-light"
          style={{
            backgroundColor: "red",
            borderColor: "none",
            borderWidth: "0px",
            outline: "none",
            opacity: "1.0",
            width: "25px",
            height: "25px",
            borderRadius: "3px",
            marginBottom: "5px",
          }}
          //onClick={props.handleOverRide}
          onClick={() => props.handleOverRide(`${props.nodeInfo.nodeName}`)}
        >
          <p
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "12px",
              textAlign: "center",
              margin: "0px",
              padding: "0px",
              display: "table-cell",
              verticalAlign: "middle",
              width: "10px",
              height: "10px",
              transform: "translate(-4px, -3px)",
            }}
          >
            O
          </p>
        </button>
        <button
          className="btn btn-outline-light"
          style={{
            backgroundColor: "green",
            borderColor: "none",
            borderWidth: "0px",
            outline: "none",
            opacity: "1.0",
            width: "25px",
            height: "25px",
            borderRadius: "3px",
            marginBottom: "5px",
            transform: "translate(20px, 0px)",
          }}
        >
          <p
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "12px",
              textAlign: "center",
              margin: "0px",
              padding: "0px",
              display: "table-cell",
              verticalAlign: "middle",
              width: "10px",
              height: "10px",
              transform: "translate(-4px, -3px)",
            }}
          >
            X
          </p>
        </button>
        {(loadType === "HVAC Unit" ||
          loadType === "HVAC w SimplTherm" ||
          loadType === "Space Heater" ||
          loadType === "Pole Lights" ||
          loadType === "Outdoor Sensed Lights" ||
          loadType === "Relay Driver" ||
          loadType === "Relay Driver w Light Sensor" ||
          loadType === "Quad 30A Relays" ||
          loadType === "Quad 30A w Light Sensor" ||
          loadType === "Indoor Lights" ||
          loadType === "Heat Lamp" ||
          loadType === "Water Heater" ||
          loadType === "Water Heater No Sensor" ||
          loadType === "SimplHeater" ||
          loadType === "Walk-in" ||
          loadType === "SimplWalkIn" ||
          loadType === "Reach-in" ||
          loadType === "Counter Reach-in" ||
          loadType === "Anti-sweat Heaters" ||
          loadType === "EV Charger" ||
          loadType === "Oven" ||
          loadType === "Hood" ||
          loadType === "Misc. Appliance" ||
          loadType === "Misc. Sheddable" ||
          loadType === "Ambient Temp Sensor" ||
          loadType === "External Temp Sensor" ||
          loadType === "Light Sensor" ||
          loadType === "Door Monitor" ||
          loadType === "Perimeter Outline" ||
          loadType === "Compass Indicator" ||
          loadType === "System Gateway") && (
          <div>
            <p className="basicParagraph" style={{ fontSize: "9px" }}>
              Name: {props.nodeInfo.nodeName}
              <p>Type: {props.nodeInfo.loadType}</p>
            </p>
          </div>
        )}
        {(loadType === "HVAC Unit" ||
          loadType === "HVAC w SimplTherm" ||
          loadType === "Space Heater" ||
          loadType === "Pole Lights" ||
          loadType === "Outdoor Sensed Lights" ||
          loadType === "Relay Driver" ||
          loadType === "Relay Driver w Light Sensor" ||
          loadType === "Quad 30A Relays" ||
          loadType === "Quad 30A w Light Sensor" ||
          loadType === "Indoor Lights" ||
          loadType === "Heat Lamp" ||
          loadType === "Water Heater" ||
          loadType === "Water Heater No Sensor" ||
          loadType === "SimplHeater" ||
          loadType === "Anti-sweat Heaters" ||
          loadType === "EV Charger" ||
          loadType === "Oven" ||
          loadType === "Hood" ||
          loadType === "Misc. Appliance" ||
          loadType === "Misc. Sheddable") && (
          <div>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                textDecoration: "underline",
              }}
            >
              Operating Hours
            </p>
            <p className="basicParagraph" style={{ fontSize: "9px" }}>
              Hours: {startTime} - {endTime}
              <p>On Time: {onTime}</p>
            </p>
          </div>
        )}
        {(loadType === "HVAC Unit" ||
          loadType === "HVAC w SimplTherm" ||
          loadType === "Space Heater" ||
          loadType === "Pole Lights" ||
          loadType === "Outdoor Sensed Lights" ||
          loadType === "Relay Driver" ||
          loadType === "Relay Driver w Light Sensor" ||
          loadType === "Quad 30A Relays" ||
          loadType === "Quad 30A w Light Sensor" ||
          loadType === "Indoor Lights" ||
          loadType === "Heat Lamp" ||
          loadType === "Water Heater" ||
          loadType === "Water Heater No Sensor" ||
          loadType === "Walk-in" ||
          loadType === "SimplWalkIn" ||
          loadType === "SimplHeater" ||
          loadType === "Reach-in" ||
          loadType === "Counter Reach-in" ||
          loadType === "Anti-sweat Heaters" ||
          loadType === "EV Charger" ||
          loadType === "Oven" ||
          loadType === "Hood" ||
          loadType === "Misc. Appliance" ||
          loadType === "Misc. Sheddable" ||
          loadType === "Ambient Temp Sensor" ||
          loadType === "External Temp Sensor" ||
          loadType === "Light Sensor") && (
          <div>
            <p className="basicParagraph" style={{ fontSize: "9px" }}>
              Data Age: {dataAge} sec.
            </p>
          </div>
        )}
        {(loadType === "HVAC Unit" ||
          loadType === "HVAC w SimplTherm" ||
          loadType === "Space Heater" ||
          loadType === "Ambient Temp Sensor") && (
          <div>
            <p className="basicParagraph" style={{ fontSize: "9px" }}>
              Temp. Range: {tempLo} - {tempHi} *F
              <p>
                Temperature: {temp} *F | RH: {rh}%
                <p style={{ fontWeight: "normal", fontSize: "9px" }}>
                  Sensor SN: {props.nodeInfo.nodeSensorSN}
                </p>
              </p>
            </p>
          </div>
        )}
        {(loadType === "Water Heater" ||
          loadType === "SimplHeater" ||
          loadType === "Walk-in" ||
          loadType === "SimplWalkIn" ||
          loadType === "Reach-in" ||
          loadType === "Counter Reach-in" ||
          loadType === "External Temp Sensor") && (
          <div>
            <p className="basicParagraph" style={{ fontSize: "9px" }}>
              Temp. Range: {tempLo} - {tempHi} *F
              <p>
                Temperature: {rth} *F
                <p style={{ fontWeight: "normal", fontSize: "9px" }}>
                  Sensor SN: {props.nodeInfo.nodeSensorSN}
                </p>
              </p>
            </p>
          </div>
        )}
        {(loadType === "Outdoor Sensed Lights" ||
          loadType === "Indoor Lights" ||
          loadType === "Light Sensor") && (
          <div>
            <p className="basicParagraph" style={{ fontSize: "9px" }}>
              Light Intensity (Lux): {lux}
              <p style={{ fontWeight: "normal", fontSize: "9px" }}>
                Sensor SN: {props.nodeInfo.nodeSensorSN}
              </p>
            </p>
          </div>
        )}

        {(loadType === "Walk-in" ||
          loadType === "SimplWalkIn" ||
          loadType === "SimplHeater" ||
          loadType === "Door Monitor") && (
          <div>
            {doorOpenTime === 0 && (
              <p className="basicParagraph" style={{ fontSize: "9px" }}>
                Door Status: Closed
              </p>
            )}
            {doorOpenTime !== 0 && (
              <p className="basicParagraph" style={{ fontSize: "9px" }}>
                Door Status: Open<p>Open for {doorOpenTime} sec. </p>
              </p>
            )}
            <p className="basicParagraph" style={{ fontSize: "9px" }}>
              Door Sensor SN: {props.nodeInfo.nodeDoorSensorSN}
            </p>
          </div>
        )}

        {(loadType === "HVAC Unit" ||
          loadType === "HVAC w SimplTherm" ||
          loadType === "Space Heater" ||
          loadType === "Pole Lights" ||
          loadType === "Outdoor Sensed Lights" ||
          loadType === "Relay Driver" ||
          loadType === "Relay Driver w Light Sensor" ||
          loadType === "Quad 30A Relays" ||
          loadType === "Quad 30A w Light Sensor" ||
          loadType === "Indoor Lights" ||
          loadType === "Heat Lamp" ||
          loadType === "Water Heater" ||
          loadType === "Water Heater No Sensor" ||
          loadType === "Walk-in" ||
          loadType === "SimplWalkIn" ||
          loadType === "SimplHeater" ||
          loadType === "Reach-in" ||
          loadType === "Counter Reach-in" ||
          loadType === "Anti-sweat Heaters" ||
          loadType === "EV Charger" ||
          loadType === "Oven" ||
          loadType === "Hood" ||
          loadType === "Misc. Appliance" ||
          loadType === "Misc. Sheddable") && (
          <div>
            <p className="basicParagraph" style={{ fontSize: "9px" }}>
              Qualified to shed: {sheddable}
              <p>Shed Status: {shedStatus}</p>
            </p>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                textDecoration: "underline",
              }}
            >
              Control Parameters:
            </p>
            <p style={{ fontWeight: "normal", fontSize: "9px" }}>
              Priority Level: {props.nodeInfo.nodePriority}
              <p>
                Min Off Time: {props.nodeInfo.nodeMinOffTime}
                <p>
                  Rated Power (kW): {ratedPower}
                  <p>
                    Fleet Control: {props.nodeInfo.fleetControl}
                    <p>Over-ride: {props.nodeInfo.overRide}</p>
                  </p>
                </p>
              </p>
            </p>
          </div>
        )}

        {(loadType === "Space Heater" ||
          loadType === "Pole Lights" ||
          loadType === "Outdoor Sensed Lights" ||
          loadType === "Relay Driver" ||
          loadType === "Relay Driver w Light Sensor" ||
          loadType === "Quad 30A Relays" ||
          loadType === "Quad 30A w Light Sensor" ||
          loadType === "Indoor Lights" ||
          loadType === "Heat Lamp" ||
          loadType === "Water Heater" ||
          loadType === "Water Heater No Sensor" ||
          loadType === "Walk-in" ||
          loadType === "SimplWalkIn" ||
          loadType === "SimplHeater" ||
          loadType === "Reach-in" ||
          loadType === "Counter Reach-in" ||
          loadType === "Anti-sweat Heaters" ||
          loadType === "EV Charger" ||
          loadType === "Oven" ||
          loadType === "Hood" ||
          loadType === "Misc. Appliance" ||
          loadType === "Misc. Sheddable") && (
          <div>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                textDecoration: "underline",
              }}
            >
              Control Relays:
            </p>
            <p style={{ fontWeight: "normal", fontSize: "9px" }}>
              Relay SN: {props.nodeInfo.nodeRelaySN}
              <p>{props.nodeInfo.stage1Cooling}</p>
            </p>
          </div>
        )}

        {loadType === "HVAC Unit" && (
          <div>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                textDecoration: "underline",
              }}
            >
              Control Relays:
            </p>
            <p style={{ fontWeight: "normal", fontSize: "9px" }}>
              Relay SN: {props.nodeInfo.nodeRelaySN}
              <p>
                Stage 1 Cooling Relay#: {props.nodeInfo.stage1Cooling}
                <p>
                  Stage 2 Cooling Relay#: {props.nodeInfo.stage2Cooling}
                  <p>
                    Stage 1 Heating Relay#: {props.nodeInfo.stage1Heating}
                    <p>
                      Stage 2 Heating Relay#: {props.nodeInfo.stage2Heating}
                    </p>
                  </p>
                </p>
              </p>
            </p>
          </div>
        )}
        {loadType === "HVAC w SimplTherm" && (
          <div>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                textDecoration: "underline",
              }}
            >
              Thermostat Relay Settings:
            </p>
            <p style={{ fontWeight: "normal", fontSize: "9px" }}>
              Thermostat SN: {props.nodeInfo.nodeThermostatSN}
              <p>
                Stage 1 Cooling:{statStage1Cooling}
                <p>
                  Stage 2 Cooling: {statStage2Cooling}
                  <p>
                    Stage 1 Heating: {statStage1Heating}
                    <p>
                      Stage 2 Heating: {statStage2Heating}
                      <p>Fan: {statFan}</p>
                    </p>
                  </p>
                </p>
              </p>
            </p>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
export default ApplianceModal;
