import React from "react";

const WaterheaterSvg = (props) => (
  <svg
    style={{ margin: "0px", padding: "0px", height: "90px", width: "200px" }}
  >
    <svg
      height="200px"
      width="auto"
      version="1.1"
      viewBox="0 0 1300 1300"
      xmlns="http://www.w3.org/2000/svg"
      stroke={props.strokeColor1}
      fill={props.strokeColor1}
    >
      <g /*fill={props.strokeColor1}*/ transform="translate(0,20)">
        <path d="M392.5,51.9h-43.1v-0.5h-8.6c-3.5,0-6.9,1-9.9,2.8l-37.8,23c-5,3.1-8.3,8.3-9,14L250,110.6l-34.1-19.3c-0.7-5.8-4-11-9-14  l-37.8-23c-3-1.8-6.4-2.8-9.9-2.8h-8.6v0.5h-43.1c-7.2,0-13,5.8-13,13v17.6c0,7.7,6.3,14,14,14h36.3c7.7,0,14-6.3,14-14V67.7h0.3  c0.5,0,1.1,0.1,1.5,0.4l37.8,23c0.9,0.5,1.4,1.5,1.4,2.6c-0.4,12.8,1.8,53.9,41.5,67.7v232.8h-2.1c-0.6,0-1.1,0.2-1.5,0.5  c-0.4,0.3-0.8,0.7-1,1.2c-0.1,0.3-0.2,0.7-0.2,1.1v43.7h-28.1c-1.5,0-2.7,1.2-2.7,2.7v2.6c0,1.5,1.2,2.7,2.7,2.7h83.2  c1.5,0,2.7-1.2,2.7-2.7v-2.6c0-1.5-1.2-2.7-2.7-2.7h-28v-43.7c0-0.4-0.1-0.8-0.2-1.1c-0.2-0.5-0.6-0.9-1-1.2c-0.4-0.3-1-0.5-1.5-0.5  h-3.3V161.7c40.7-13.4,42.9-55.1,42.6-68.1c0-1,0.5-2,1.4-2.6l37.8-23c0.5-0.3,1-0.4,1.5-0.4h0.3v14.8c0,7.7,6.3,14,14,14h36.3  c7.7,0,14-6.3,14-14V65C405.5,57.8,399.7,51.9,392.5,51.9z M144.8,82.7h-36.3l-0.1-0.1v-5.9h36.5L144.8,82.7z M241.4,143.8  c-15.2-7.4-21.6-20.7-24-33.1l24,13.6V143.8z M257.6,144.3v-19.4l25.1-14.2C280.2,123.4,273.5,137,257.6,144.3z M391.6,82.5  c0,0.1-0.1,0.1-0.1,0.1L355,82.5v-5.8h36.6V82.5z" />
      </g>
    </svg>
  </svg>
);
export default WaterheaterSvg;
