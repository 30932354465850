import React, { useEffect, useState, useMemo, Component } from "react";
import { loadTypesSchema } from "../config.json";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

import { getUser } from "../services/userService";
import {
  getSystemLatestData,
  getSystemIDfromHandle,
  getSystem,
  getAllAlarms,
  getAlarm,
  saveSystemAlarms

} from "../services/systemService";

import "./dashboard.css";
import "../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import "react-datepicker/dist/react-datepicker.css";
import { getCompanyUsers, getUserHistory } from "../services/companyService";
import { toast } from "react-toastify";

// Main component which calls the NotificationsTable interactive component
class UserHistory extends Component{

  constructor(props) {
    super(props);
    this.state = {
      isEmpty: false, // Initial state is false
      systemName: "",
      nodeName: "",
      systemHandleID: 0,
      user: "",
      userHistory: [],
      allUsers: [],
      historyFiltered: [],
      user: "All Users"
    };
  }

  async componentDidMount(){
    const companyName = this.props.companyName;
    this.setState({ companyName })

    // Get company history of users
    let userHistory = []
    const objUserHistory = (await getUserHistory(this.props.companyName)).data.history.user_history;
    if (!objUserHistory) {
      return this.setState({ isEmpty: true })
    }
    userHistory = Object.values(objUserHistory)
    this.setState({ userHistory })

    // Getting all users attached to the company
    let allUsers = []
    const allUsersObj = (await getCompanyUsers(this.props.companyName)).data.data;
    allUsers = Object.values(allUsersObj)
    this.setState({ allUsers });

    // Get last week for initial date filter
    const lastWeek = this.getLastWeeksDate();
    this.setState({ lastWeek })

    let currentDate = new Date()
    currentDate = this.formatDate(currentDate);
    this.setState({ currentDate })

    const endDateHtml = this.dateHtml(currentDate);
    const startDateHtml = this.dateHtml(lastWeek);
    this.setState({
        endDateHtml,
        startDateHtml
    });

    const filteredDates = userHistory.filter(history => startDateHtml <= this.dateHtml(history.date) && endDateHtml >= this.dateHtml(history.date));
    this.setState({historyFiltered: filteredDates})
  }

  // Change date format into HTML date format for filters
  dateHtml(date) {
    let format = new Date(date)
    let day = format.getDate();
    let month = format.getMonth() + 1;
    let year = format.getFullYear();

    format = `${year}-${month > 9 ? month : "0" + month}-${day > 9 ? day : "0" + day}`;
    return format;
  }

  // Get the last week date for initial date filter
  getLastWeeksDate() {
    const now = new Date();

    const unformatted = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);

    let formatted = this.formatDate(unformatted)
    return formatted;
  }

  // Format date in the style that is being saved in the db
  formatDate(unformatted) {
    let day = unformatted.getDate();
    let month = unformatted.getMonth() + 1;
    let year = unformatted.getFullYear();

    let formattedDate = `${month}/${day}/${year}`;
    return formattedDate
  }

  // Handler for user filter
  handleUser(e) {
    const user = e.target.value
    this.handleFilters("User", user);
  }

  // Handler for start date filter
  handleStartDate(e) {
    let date = new Date(e.target.value);
    date = this.dateHtml(date)
    this.handleFilters("Start Date", date);
  }

  // Handler for end date filter
  handleEndDate(e) {
    let date = new Date(e.target.value);
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
    date = this.dateHtml(date);
    this.handleFilters("End Date", date);
  }

  // Handler for make all filters work together
  handleFilters(opFilter, filterValue) {
    let endDateHtml = this.state.endDateHtml;
    let startDateHtml = this.state.startDateHtml;
    let user = this.state.user;

    if (opFilter === "End Date") {
        endDateHtml = filterValue;
        this.setState({ endDateHtml });


    } else if (opFilter === "Start Date") {
        startDateHtml = filterValue;
        this.setState({ startDateHtml });
    } 

    let historyFiltered = this.state.userHistory.filter(history => 
        startDateHtml < this.dateHtml(history.date) && endDateHtml >= this.dateHtml(history.date)
    );
    
    if (opFilter === "User") {
        user = filterValue;
        this.setState({ user });
    }

    if (user !== "All Users") {
        historyFiltered = historyFiltered.filter(history => history.username === user);
    }

    this.setState({ historyFiltered });
  }

  render(){

    return (
      <div
        style={{
          width: "1000px",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: "10px",
          marginBottom: "150px",
          zIndex: 1,
          paddingBottom: "100px",
        }}>
          <hr/>
          <div className="d-flex justify-content-center pb-2 mb-3">
              <p
              className="basicParagraph text-center"
              style={{
                  width: "1000px",
                  fontSize: "12px",
                  color: "#2D5C88"
              }}> User history for {this.state.companyName}
              </p>
          </div>

          {this.state.isEmpty && (
            <div className="mt-3">
              <p
                className="basicParagraph text-center"
                style={{
                  width: "1000px",
                  fontSize: "12px",
                  color: "#2D5C88"
                }}
              >There is no user history</p>
            </div>
          
          )}
          {!this.state.isEmpty && (
            <div>
              <div className="d-flex justify-content-center flex-row">
                <label className="basicParagraph" style={{width: "100px"}}>Filter by:</label>

                <select
                  style={{width: "150px", padding: "1px"}}
                  name="userFilter"
                  className="form-control2 mr-2"
                  onChange={e => this.handleUser(e)}>
                  <option value="All Users">All Users</option>
                  {this.state.allUsers.map((user) => (
                    <option value={user.username}>{user.username}</option>
                  ))}
                </select>
              </div>

              <div className="d-flex justify-content-center flex-row">
                <label className="basicParagraph" style={{width: "100px"}}>Date Range:</label>
                <input
                    style={{width: "150px"}}
                    name="startDate"
                    type="date"
                    className="form-control2 mr-2 p-1"
                    defaultValue={this.state.startDateHtml}
                    onChange={e => this.handleStartDate(e)}
                />

                <input
                  style={{width: "150px"}}
                  name="endDate"
                  type="date"
                  className="form-control2 mr-2 p-1"
                  defaultValue={this.state.endDateHtml}
                  onChange={e => this.handleEndDate(e)}
                />
              </div>
              <div className="d-flex justify-content-center" style={{ width: "1000px" }}>
                <table className="table" style={{ width: "500px" }}>
                  <thead>
                    <tr>
                      <th>
                        Date
                      </th>
                      <th>
                        User
                      </th>
                    </tr>
                  </thead>

                  {this.state.historyFiltered.map((user) => (
                    <tbody>
                      <tr
                        style={{ height: "15px" }}
                      >
                        <td>
                          {user.date}
                        </td>
                        <td>
                          {user.username}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          )}
      </div>
    )
  }
}

export default UserHistory;
