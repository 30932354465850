import React from "react";
import Joi from "joi-browser";
import { setJwt } from "../services/httpService";
import { getUser, getUserSystems } from "../services/userService";
import "./dashboard.css";
import "../index.css";
import ReactExport from "react-data-export";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PGEkwhperkw, SCEkwhperkw, SDGEkwhperkw } from "../config.json";
import { sb15SGIP, sb10SGIP, sb05SGIP, itcRate } from "../config.json";
import {
  PGEannualSavings,
  SCEannualSavings,
  SDGEannualSavings
} from "../config.json";
import {
  sb15MSRP,
  sb15Project,
  sb15Partner,
  sb15Preferred,
  sb15Major,
  sb15Disti,
  sb10MSRP,
  sb10Project,
  sb10Partner,
  sb10Preferred,
  sb10Major,
  sb10Disti,
  sb05MSRP,
  sb05Project,
  sb05Partner,
  sb05Preferred,
  sb05Major,
  sb05Disti
} from "../config.json";
import Form from "./common/form";
import BackGroundRectangle from "../components/backgroundRect";

class SimplSizing extends Form {
  state = {
    systemID: "",
    systemSN: "",
    systemName: "",
    data: {
      utilityCompany: "1",
      rateEscalator: 15,
      kWhPerkWSolar: 3.8,
      kWSolar: 6.2,
      capacityFade: 20,
      productPN: "1",
      commOption: "2",
      quantityDiscount: "4",
      productLife: "4",
      inverterType: "Micro-inverter",
      pvPanelType: "60-cell"
    },
    optionsUtility: [
      { _id: "1", name: "Pacific Gas & Electric" },
      { _id: "2", name: "Southern California Edison" },
      { _id: "3", name: "San Diego Gas & Electric" }
    ],
    optionsProductPN: [
      { _id: "1", name: "SimplBox-15: 1.5 kWh" },
      { _id: "2", name: "SimplBox-10: 1.0 kWh" },
      { _id: "3", name: "SimplBox-05: 0.5 kWh" }
    ],
    optionsComm: [
      { _id: "1", name: "Wifi" },
      { _id: "2", name: "4G" }
    ],

    optionsQuantityDiscount: [
      { _id: "1", name: "MSRP" },
      { _id: "2", name: "Project: 5 - 10" },
      { _id: "3", name: "Partner: 11 - 50" },
      { _id: "4", name: "Preferred: 51 - 200" },
      { _id: "5", name: "Major: 201 - 500" },
      { _id: "6", name: "Disti: 500+" }
    ],

    optionsProductLife: [
      { _id: "1", name: "10" },
      { _id: "2", name: "15" },
      { _id: "3", name: "20" },
      { _id: "4", name: "25" }
    ],
    optionsInverter: [
      { _id: "Micro-inverter", name: "Micro-inverter" },
      {
        _id: "String Inverter + Optimizers",
        name: "String Inverter + Optimizers"
      },
      { _id: "String Inverter", name: "String Inverter" }
    ],
    optionsPVPanel: [
      { _id: "60-cell", name: "60-cell" },
      { _id: "72-cell", name: "72-cell" },
      { _id: "96-cell", name: "96-cell" },
      { _id: "128-cell", name: "128-cell" }
    ],

    errors: {}
  };

  schema = {
    utilityCompany: Joi.string().label(
      "Pick the utility company to which your building is connected:"
    ),
    rateEscalator: Joi.number()
      .min(2)
      .max(25)
      .label(
        "We recommend 15%:  This is the rate at which the difference between On-Peak and Off-peak energy costs grow:"
      ),
    kWhPerkWSolar: Joi.number()
      .min(0.25)
      .max(4.0)
      .label(
        "Energy storage in kWh to be installed per kW of solar installation:"
      ),

    capacityFade: Joi.number()
      .min(10)
      .max(35)
      .label(
        "Combined impact of capacity fade, round-trip efficiency and depth-of-discharge (%):"
      ),
    kWSolar: Joi.number()
      .min(3.0)
      .max(30.0)
      .label("The kW rating of the installed or proposed solar system:"),
    productPN: Joi.string().label(
      "Select a product from the SimplBox product family:"
    ),
    commOption: Joi.string().label("Select a Wifi or 4G communication method:"),
    quantityDiscount: Joi.string().label(
      "Select your quarterly purchase quantity:"
    ),
    productLife: Joi.string().label(
      "Select estimated product life in years.  We estimate 25 years:"
    ),
    inverterType: Joi.string().label("Select Inverter Type:"),
    pvPanelType: Joi.string().label("Select Inverter Type:")
  };

  async componentDidMount() {
    const usernameprops = this.props.match.params.username;
    console.log("User name :", usernameprops);
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    const user = await getUser();
    console.log("User", user);
    const username = user.data.data.username;
    const firstName = user.data.data.firstName;
    const lastName = user.data.data.lastName;
    const emailAddress = user.data.data.emailAddress;
    this.setState({ username, firstName, lastName, emailAddress });
    const conditionsAgreed = false;
    this.setState({ conditionsAgreed });
    const userSystems = (await getUserSystems()).data.data;
    console.log("Logged in User", user.data.data.username);
    console.log("Company Name", user.data.data.company);
    console.log("User Systems", userSystems);
  }
  doSubmit = async () => {
    console.log("Utility Company", this.state.data.utilityCompany);

    let kWhPerkWSolar = this.state.data.kWhPerkWSolar;
    let firstYearSavingsPerkWh = 0;
    let utility = "";
    if (parseInt(this.state.data.utilityCompany) === 1) {
      kWhPerkWSolar = PGEkwhperkw;
      firstYearSavingsPerkWh = PGEannualSavings;
      utility = "Pacific Gas & Electric (PG&E)";
    } else if (parseInt(this.state.data.utilityCompany) === 2) {
      kWhPerkWSolar = SCEkwhperkw;
      firstYearSavingsPerkWh = SCEannualSavings;
      utility = "Southern California Edison (SCE)";
    } else if (parseInt(this.state.data.utilityCompany) === 3) {
      kWhPerkWSolar = SDGEkwhperkw;
      firstYearSavingsPerkWh = SDGEannualSavings;
      utility = "San Diego Gas and Electric (SDG&E)";
    } else {
      kWhPerkWSolar = SDGEkwhperkw;
      firstYearSavingsPerkWh = SDGEannualSavings;
    }
    const capacityFade = parseFloat(this.state.data.capacityFade) / 100;
    const totalStorage = kWhPerkWSolar * this.state.data.kWSolar;
    let unitCapacity = 1.5;
    let product = "";
    if (parseInt(this.state.data.productPN) === 1) {
      unitCapacity = 1.5;
      product = "SimplBox-15: 1.5 kWh";
    } else if (parseInt(this.state.data.productPN) === 2) {
      unitCapacity = 1.0;
      product = "SimplBox-10: 1.0 kWh";
    } else if (parseInt(this.state.data.productPN) === 3) {
      unitCapacity = 0.5;
      product = "SimplBox-05: 0.5 kWh";
    }
    console.log("Unit Capacity", unitCapacity);

    let numOfUnits = Math.round(totalStorage / unitCapacity);
    if (numOfUnits < 8 && this.state.data.inverterType === "2") numOfUnits = 8;
    console.log("Number of Units", numOfUnits);
    console.log(
      "Quantity Discount",
      parseInt(this.state.data.quantityDiscount)
    );
    console.log("Product PN", parseInt(this.state.data.productPN));

    let unitPrice = sb15MSRP;
    let pricing = "";
    if (parseInt(this.state.data.quantityDiscount) === 1) {
      pricing = "MSRP";
      if (parseInt(this.state.data.productPN) === 1) unitPrice = sb15MSRP;
      else if (parseInt(this.state.data.productPN) === 2) unitPrice = sb10MSRP;
      else if (parseInt(this.state.data.productPN) === 3) unitPrice = sb05MSRP;
    } else if (parseInt(this.state.data.quantityDiscount) === 2) {
      pricing = "Project: Qty: 5 - 10";
      if (parseInt(this.state.data.productPN) === 1) unitPrice = sb15Project;
      else if (parseInt(this.state.data.productPN) === 2)
        unitPrice = sb10Project;
      else if (parseInt(this.state.data.productPN) === 3)
        unitPrice = sb05Project;
    } else if (parseInt(this.state.data.quantityDiscount) === 3) {
      pricing = "Partner: Qty: 11 - 50";
      if (parseInt(this.state.data.productPN) === 1) unitPrice = sb15Partner;
      else if (parseInt(this.state.data.productPN) === 2)
        unitPrice = sb10Partner;
      else if (parseInt(this.state.data.productPN) === 3)
        unitPrice = sb05Partner;
    } else if (parseInt(this.state.data.quantityDiscount) === 4) {
      pricing = "Preferred: Qty: 51 - 200";
      if (parseInt(this.state.data.productPN) === 1) unitPrice = sb15Preferred;
      else if (parseInt(this.state.data.productPN) === 2)
        unitPrice = sb10Preferred;
      else if (parseInt(this.state.data.productPN) === 3)
        unitPrice = sb05Preferred;
    } else if (parseInt(this.state.data.quantityDiscount) === 5) {
      pricing = "Major: Qty: 201 - 500";
      if (parseInt(this.state.data.productPN) === 1) unitPrice = sb15Major;
      else if (parseInt(this.state.data.productPN) === 2) unitPrice = sb10Major;
      else if (parseInt(this.state.data.productPN) === 3) unitPrice = sb05Major;
    } else if (parseInt(this.state.data.quantityDiscount) === 6) {
      pricing = "Disti: Qty: 500+";
      if (parseInt(this.state.data.productPN) === 1) unitPrice = sb15Disti;
      else if (parseInt(this.state.data.productPN) === 2) unitPrice = sb10Disti;
      else if (parseInt(this.state.data.productPN) === 3) unitPrice = sb05Disti;
    }
    console.log("Unit Price", unitPrice);
    let pricePerKwh = parseFloat(unitPrice / unitCapacity).toFixed(2);
    let grossCosts = parseFloat(unitPrice * numOfUnits).toFixed(2);
    console.log("Project Costs", grossCosts);
    const costAfterITC = parseFloat(grossCosts * (1.0 - itcRate)).toFixed(2);
    let sgipIncentive = sb15SGIP;
    if (parseInt(this.state.data.productPN) === 1) sgipIncentive = sb15SGIP;
    else if (parseInt(this.state.data.productPN) === 2)
      sgipIncentive = sb10SGIP;
    else if (parseInt(this.state.data.productPN) === 3)
      sgipIncentive = sb05SGIP;
    const sgipIncentives = parseFloat(sgipIncentive * numOfUnits).toFixed(2);
    const netSystemCost = parseFloat(costAfterITC - sgipIncentives).toFixed(2);
    console.log("Costs After ITC", costAfterITC);
    console.log("Net System Cost", netSystemCost);

    let productLife = 25;
    if (parseInt(this.state.data.productLife) === 1) productLife = 10;
    else if (parseInt(this.state.data.productLife) === 2) productLife = 15;
    else if (parseInt(this.state.data.productLife) === 3) productLife = 20;
    else if (parseInt(this.state.data.productLife) === 4) productLife = 25;
    console.log("Product Life", productLife);

    const systemSavings = [0];
    let cumSystemSavings = 0;
    const cashFlow = [0];
    let year = 0;
    systemSavings[year] = 0;
    cashFlow[year] = -1.0 * netSystemCost;

    for (year = 1; year <= productLife; year++) {
      systemSavings[year] =
        firstYearSavingsPerkWh *
        numOfUnits *
        unitCapacity *
        (1 - capacityFade) *
        Math.pow(1 + this.state.data.rateEscalator / 100, year - 1);
      cashFlow[year] = cashFlow[year - 1] + systemSavings[year];
      cumSystemSavings += systemSavings[year];
    }
    const firstYearSavings = (
      firstYearSavingsPerkWh *
      numOfUnits *
      unitCapacity *
      (1 - capacityFade)
    ).toFixed(2);

    const cumulativeSavings = cumSystemSavings.toLocaleString(undefined, {
      maximumFractionDigits: 2
    });
    const cashFlowPerUnit = (cashFlow[productLife] / numOfUnits).toLocaleString(
      undefined,
      {
        maximumFractionDigits: 2
      }
    );
    const lifeTimeCashFlow = cashFlow[productLife].toLocaleString(undefined, {
      maximumFractionDigits: 2
    });

    let param = [0];
    let paramValue = [0];
    param[0] = "Utility Company";
    paramValue[0] = utility;
    param[1] =
      "Escalator for difference between On-peak and Off-peak energy costs";
    paramValue[1] = this.state.data.rateEscalator / 100;
    param[2] =
      "Recommended kWh of energy storage to be installed per kW of proposed or installed Solar";
    paramValue[2] = kWhPerkWSolar;
    param[3] = "Solar rated size (kW)";
    paramValue[3] = this.state.data.kWSolar;
    param[4] =
      "Estimated Year-one Savings per installed kWh of energy storage ($)";
    paramValue[4] = firstYearSavingsPerkWh;
    param[5] = "SimplBox Model Number / Unit Rated Capacity (kWh)";
    paramValue[5] = product;
    param[6] = "Pricing Table";
    paramValue[6] = pricing;

    param[7] = "Unit Price ($)";
    paramValue[7] = unitPrice;

    param[8] = "Price / kWh ($)";
    paramValue[8] = pricePerKwh;

    param[9] = "Expected Product Life (Years)";
    paramValue[9] = productLife;
    param[10] =
      "Cumulative impact of Capacity Fade, round-trip efficiency and reduced depth-of-discharge";
    paramValue[10] = capacityFade;
    param[11] = "Number of SimplBoxes";
    paramValue[11] = numOfUnits;
    param[12] = "Total Nominal energy storage capacity (kWh)";
    paramValue[12] = numOfUnits * unitCapacity;
    param[13] = "Year-One estimated savings:";
    paramValue[13] = parseFloat(firstYearSavings);
    param[14] = "Gross System Costs";
    paramValue[14] = parseFloat(grossCosts);
    param[15] = "Cost after federal investment tax credit (ITC)";
    paramValue[15] = parseFloat(costAfterITC);
    param[16] = "Estimated Self-Generation Incentive Programs (SGIP)";
    paramValue[16] = parseFloat(sgipIncentives);
    param[17] = "Net system cost after ITC and SGIP incentives";
    paramValue[17] = parseFloat(netSystemCost);
    param[18] = "Cumulative system savings";
    paramValue[18] = cumulativeSavings;
    param[19] = "Lifetime cash flow per SimplBox";
    paramValue[19] = cashFlowPerUnit;
    param[20] = "Lifetime system cash flow";
    paramValue[20] = lifeTimeCashFlow;
    param[21] = "Inverter Type";
    paramValue[21] = this.state.data.inverterType;
    param[22] = "PV Panel Type";
    paramValue[22] = this.state.data.pvPanelType;
    param[23] = "User Name";
    paramValue[23] = this.state.username;
    param[24] = "First Name";
    paramValue[24] = this.state.firstName;
    param[25] = "Last Name";
    paramValue[25] = this.state.lastName;
    param[26] = "email Address";
    paramValue[26] = this.state.emailAddress;

    const numOfRows = 26;
    let payBack = [0];
    let i = 0;
    for (i = 0; i <= numOfRows /*productLife*/; i++) {
      if (i > productLife) {
        systemSavings[i] = 0;
        cashFlow[i] = 0;
      }
      payBack[i] = JSON.parse(
        JSON.stringify({
          param: param[i],
          paramValue: paramValue[i],
          Year: parseInt(i),
          AnnualSavings: parseFloat(systemSavings[i].toFixed(2)),
          CashFlow: parseFloat(cashFlow[i].toFixed(2))
        })
      );
    }
    console.log("Pay Back JSON", payBack);
    this.setState({ payBack });
  };

  handleAgree = () => {
    this.setState({ anchorEl: null });
    const conditionsAgreed = true;
    this.setState({ conditionsAgreed });
  };
  handleDisAgree = () => {
    this.setState({ anchorEl: null });
    const conditionsAgreed = false;
    window.location = "/";
    this.setState({ conditionsAgreed });
  };

  render() {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1
          }}
        >
          <p className="basicParagraph">
            Edit Simpl Sizing, Pricing and Payback parameters:
          </p>
          <form onSubmit={this.handleSubmit}>
            {this.renderSelect(
              "utilityCompany",
              "Utility Company:",
              this.state.optionsUtility
            )}
            {this.renderInput(
              "rateEscalator",
              "Annual rate escalator (%): Suggested by Simpl Global:"
            )}
            {this.renderInput(
              "kWhPerkWSolar",
              "Energy storage in kWh to be installed per kW of solar installation.  We recommend PG&E: 1.8; SCE: 2.5, SDGE: 3.8"
            )}
            {this.renderInput(
              "kWSolar",
              "The kW rating of the installed or proposed solar system:"
            )}
            {this.renderSelect(
              "inverterType",
              "Select Inverter Type:",
              this.state.optionsInverter
            )}
            {this.renderSelect(
              "pvPanelType",
              "Select PV Panel Type:",
              this.state.optionsPVPanel
            )}
            {this.renderSelect(
              "productPN",
              "Select a product from the SimplBox product family:",
              this.state.optionsProductPN
            )}
            {this.renderInput(
              "capacityFade",
              "Combined impact of capacity fade, round-trip efficiency and depth-of-discharge (%):"
            )}
            {this.renderSelect(
              "commOption",
              "Select a Wifi or 4G communication method:",
              this.state.optionsComm
            )}
            {this.renderSelect(
              "quantityDiscount",
              "Select your estimated quarterly purchase quantity:",
              this.state.optionsQuantityDiscount
            )}

            {this.renderSelect(
              "productLife",
              "Select estimated product life.  We estimate 25 years:",
              this.state.optionsProductLife
            )}
            {this.renderButton("Save Parameters")}
          </form>
          <h6 style={{ marginBottom: "40px" }}> </h6>
          {this.state.payBack && (
            <div>
              <p></p>
              <button
                className="btn btn-outline-light"
                style={{
                  padding: "0px",
                  float: "left",
                  marginRight: "5px",
                  position: "relative",
                  right: "0px"
                }}
                //onClick={this.handlePause}
              >
                <FontAwesomeIcon
                  icon="exclamation-triangle"
                  size="3x"
                  color="orange"
                  title="Caution"
                />
              </button>
              <p
                className="basicParagraph"
                style={{ width: "400px", fontSize: "14px", color: "red" }}
              >
                Before you proceed, please agree to the following conditions:
              </p>
              <hr></hr>
              <p className="basicParagraph">
                1. This analysis is intended only for {this.state.firstName}{" "}
                {this.state.lastName}.
              </p>
              <p className="basicParagraph" style={{ width: "400px" }}>
                2. This is ONLY an estimate and not a performance guarantee.
              </p>
              <p className="basicParagraph" style={{ width: "400px" }}>
                3. This analysis may be viewed by the staff at Simpl Global,
                Inc.
              </p>
              <button
                className="basicButton"
                style={{
                  padding: "0px",
                  float: "right",
                  marginRight: "5px",
                  position: "relative",
                  right: "0px",
                  width: 150,
                  //marginBottom: "40px",
                  backgroundColor: "green"
                }}
                onClick={this.handleAgree}
              >
                Agree
              </button>
              <button
                className="basicButton"
                style={{
                  padding: "0px",
                  float: "left",
                  marginRight: "5px",
                  position: "relative",
                  right: "0px",
                  width: 150,
                  //marginBottom: "40px",
                  backgroundColor: "Tomato"
                }}
                onClick={this.handleDisAgree}
              >
                Do Not Agree
              </button>
              <p></p>
              {this.state.conditionsAgreed && (
                <div style={{ marginTop: "70px" }}>
                  <ExcelFile
                    filename="Simpl Sizing, Pricing and Payback"
                    element={
                      <button
                        className="btn btn-outline-light"
                        style={{ float: "right" }}
                      >
                        <FontAwesomeIcon
                          icon="file-excel"
                          size="lg"
                          color="green"
                          title="Results in excel..."
                        />
                      </button>
                    }
                  >
                    <ExcelSheet data={this.state.payBack} name="S2P2">
                      <ExcelColumn label="System Parameters:" value="param" />
                      <ExcelColumn label="" value="paramValue" />
                      <ExcelColumn label="Year" value="Year" />
                      <ExcelColumn
                        label="Annual Savings"
                        value="AnnualSavings"
                      />
                      <ExcelColumn
                        label="Cumulative Cash Flows"
                        value="CashFlow"
                      />
                    </ExcelSheet>
                  </ExcelFile>
                  <table className="table" style={{ width: 350 }}>
                    <thead>
                      <tr>
                        <th>Parameters</th>
                        <th>Settings</th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.payBack.slice(0, 11).map(payback => (
                        <tr style={{ lineHeight: "10px" }} key={payback.Year}>
                          <td>{payback.param}</td>
                          <td>{payback.paramValue}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <table className="table" style={{ width: 350 }}>
                    <thead>
                      <tr>
                        <th>SimplBox Units and Capacity</th>
                        <th />
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.payBack.slice(11, 13).map(payback => (
                        <tr style={{ lineHeight: "10px" }} key={payback.Year}>
                          <td>{payback.param}</td>
                          <td>{payback.paramValue}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <table className="table" style={{ width: 350 }}>
                    <thead>
                      <tr>
                        <th>Calculations</th>
                        <th>Results</th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.payBack.slice(13, 21).map(payback => (
                        <tr style={{ lineHeight: "10px" }} key={payback.Year}>
                          <td>{payback.param}</td>
                          <td>${payback.paramValue}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <table className="table" style={{ width: 350 }}>
                    <thead>
                      <tr>
                        <th>Year</th>
                        <th>Savings/Year</th>
                        <th>Cumulative Cash Flow</th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.payBack.map(payback => (
                        <tr style={{ lineHeight: "3px" }} key={payback.Year}>
                          <td>{payback.Year}</td>
                          <td>${payback.AnnualSavings}</td>
                          <td>${payback.CashFlow}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default SimplSizing;
