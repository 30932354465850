import React from "react";
import Joi from "joi-browser";
import { setJwt } from "../services/httpService";
import Form from "./common/form";
import {
  installSystem,
  changeSystemName,
  setSystemBasics,
  setSystemControls,
  setSystemLimits,
  setSystemSignals,
  getSystem,
  setSystemControlsObject,
  getSystemControls,
  setSystemLimitsObject,
} from "../services/systemService";
import BackGroundRectangle from "../components/backgroundRect";

class CommForm extends Form {
  state = {
    data: {
      systemName: "",
      SystemStreetAddress: "",
      SystemCity: "",
      SystemState: "",
      SystemCountry: "",
      SystemZipCode: "",
    },
    optionsTimeZone: [
      { _id: "1", name: "(UTC -08:00) Pacific Time (US & Canada)" },
      { _id: "2", name: "(UTC -07:00) Mountain Time (US & Canada)" },
      { _id: "3", name: "(UTC -06:00) Central Time (US & Canada)" },
      { _id: "4", name: "(UTC -05:00) Eastern Time (US & Canada)" },
      { _id: "5", name: "(UTC -10:00) Hawaii Time (US & Canada)" },
      { _id: "6", name: "(UTC +1:00) Western Europe" },
      { _id: "7", name: "(UTC +2:00) Eastern Europe" },
      { _id: "8", name: "(UTC +3:00) Moscow, Russia" },
      { _id: "9", name: "(UTC +4:00) Abu Dhabi" },
      { _id: "10", name: "(UTC +5:30) New Dehli, India" },
      { _id: "11", name: "(UTC +08:00) Beijing, China & Hong Kong" },
      { _id: "12", name: "(UTC +09:00) Seoul, Korea and Tokyo, Japan" },
      { _id: "13", name: "(UTC +09:30) Adelaide, Australia" },
      { _id: "14", name: "(UTC +10:00) Brisbane, Australia" },
      { _id: "15", name: "(UTC +12:00) Auckland, New Zealand" },
    ],
    /*    optionsStatus: [
      { _id: "Tested", name: "Tested" },
      { _id: "Calibrated", name: "Calibrated" },
      { _id: "Installed", name: "Installed" },
      { _id: "Commissioned", name: "Commissioned" },
      { _id: "de-Comm'd", name: "de-Comm'd" }
    ],*/
    errors: {},
  };

  schema = {
    systemName: Joi.string().required().label("System Name"),

    timeZone: Joi.string().label("System Time Zone"),
    SystemStreetAddress: Joi.string()
      .required()
      .label("Street Address: System Location"),
    SystemCity: Joi.string().required().label("City:  System Location"),
    SystemState: Joi.string().required().label("State: System Location"),
    SystemCountry: Joi.string().required().label("Country: System Location"),
    SystemZipCode: Joi.string()
      .required()
      //.usZipCode()
      .label("Zip Code: System Location"),
  };
  async componentDidMount() {
    const systemID = this.props.systemId;
    const system = await getSystem(systemID);
    console.log("Commission System: ", system);
    const systemProduct = system.data.data[0].systemProduct;
    const systemType = system.data.data[0].systemType;
    const handleId = system.data.data[0].systemID.id;
    console.log("Product", systemProduct);
    console.log("Type: ", systemType);
    console.log("Handle ID: ", handleId);
    this.setState({
      systemProduct,
      systemType,
      systemID,
      handleId,
    });
  }
  doSubmit = async () => {
    console.log("Props", this.props);
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    console.log("Token", jwt);
    console.log("Install System now");
    await installSystem(
      this.props.macAddress,
      this.props.serialNumber,
      this.state.data.timeZone,
      this.state.data.SystemStreetAddress,
      this.state.data.SystemCity,
      this.state.data.SystemState,
      this.state.data.SystemCountry,
      this.state.data.SystemZipCode
    );
    await changeSystemName(this.props.systemId, this.state.data.systemName);

    //System Basics are loaded at the time of Manufacturing
    //System Controls, Limits and Signals are loaded here
    await setSystemSignals(
      this.props.systemId,
      "0",
      "Param1",
      "Param2",
      "Param3"
    );

    if (
      this.state.systemProduct === "SimplNodes" &&
      this.state.systemType === "SimplGateway"
    ) {
      console.log("Set it up as a Node Gateway");
      let simplNodes = [];
      let systemControls = {};
      const systemID = this.state.systemID;
      let newNode = {
        loadType: "HVAC Unit",
        nodeName: "1",
        nodeDescription: "1",
        nodeSensorSN: "1",
        nodeMaxT: "1",
        nodeMinT: "1",
        nodePriority: "1",
        nodePowerRating: "1",
        nodeMaxOffTime: "1",
        nodeRelaySN: "1",
        stage1Cooling: "Relay1",
        stage2Cooling: "Relay1",
        stage1Heating: "Relay1",
        stage2Heating: "Relay1",
        startTime: "12:00",
        endTime: "12:00",
      };
      simplNodes.push(newNode);
      systemControls = JSON.stringify(simplNodes);
      await setSystemControlsObject(systemID, systemControls);
      systemControls = (await getSystemControls(this.state.handleId)).data.data
        .controls;
      let limits = {
        startTimeA: "12:00",
        startTimeB: "12:00",
        startTimeC: "12:00",
        startTimeD: "12:00",
        startTimeE: "12:00",
        startTimeF: "12:00",
        endTimeA: "12:00",
        endTimeB: "12:00",
        endTimeC: "12:00",
        endTimeD: "12:00",
        endTimeE: "12:00",
        endTimeF: "12:00",
        lowTempA: "62",
        lowTempB: "62",
        lowTempC: "62",
        lowTempD: "62",
        lowTempE: "62",
        lowTempF: "62",
        hiTempA: "78",
        hiTempB: "78",
        hiTempC: "78",
        hiTempD: "78",
        hiTempE: "78",
        hiTempF: "78",
      };
      await setSystemLimitsObject(systemID, limits);
    } else {
      await setSystemControls(
        this.props.systemId,
        "1",
        "1",
        "21",
        "1",
        "5",
        "11",
        "3",
        "100",
        "0",
        "AC Unit",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Ice maker",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Shop Compressor",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "simpl-999999", //SN for SimplModule Connected
        0 //handleId for SimplModule connected
      );
      await setSystemLimits(
        this.props.systemId,
        "275",
        "50",
        "37",
        "60000",
        "130",
        "-20",
        "200",
        "-20",
        "3.60",
        "2.9",
        "0.25",
        "3.40",
        "3.333",
        "3.14",
        "3.08",
        "300",
        "20"
      );
    }

    window.location = "/systems";
  };

  render() {
    //console.log("Commissioning Props", this.props);
    return (
      <React.Fragment>
        <div
          className="form-group"
          style={{ marginBottom: "150px", zIndex: 1 }}
        >
          <h6 className="basicParagraph">Proceed to register:</h6>

          <form onSubmit={this.handleSubmit}>
            {this.renderInput("systemName", "System Name")}
            {this.renderSelect(
              "timeZone",
              "Select Time Zone:",
              this.state.optionsTimeZone
            )}
            {this.renderInput("SystemStreetAddress", "System Street Address")}
            {this.renderInput("SystemCity", "System City Address")}
            {this.renderInput("SystemState", "System State")}
            {this.renderInput("SystemCountry", "System Country")}
            {this.renderInput("SystemZipCode", "System Zip Code")}
            {this.renderButton("Save Parameters")}
          </form>
          <h6> </h6>
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default CommForm;
