import React from "react";
import Form from "./common/form";
import {
  getSystemIDfromHandle,
  restoreSystem
} from "../services/systemService";
import BackGroundRectangle from "../components/backgroundRect";

class RestoreSystem extends Form {
  state = {
    systemId: "",
    errors: {}
  };

  async componentDidMount() {
    const handleId = this.props.match.params.systemHandleID;
    const systemId = (await getSystemIDfromHandle(handleId)).data.data
      .system_id;
    this.setState({ systemId });
  }

  handleRestore = async e => {
    e.preventDefault();
    await restoreSystem(this.state.systemId);
    this.props.history.push(
      `/Companies/systems/${this.props.match.params.companyName}`
    );
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1
          }}
        >
          <p className="basicParagraph">
            You are about to restore system with Handle ID:{" "}
            {this.props.match.params.systemHandleID}.
          </p>
          <p className="basicParagraph">Please confirm:</p>
          <button
            className="btn btn-success btn-sm"
            onClick={this.handleRestore}
          >
            Confirm
          </button>
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default RestoreSystem;
