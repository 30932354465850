import React, { Component } from "react";

import "./dashboard.css";
import "../index.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";

function EnergyAnimation(props) {
  //console.log("Battery Power: ", props.batteryPower);
  return (
    <React.Fragment>
      {!props.dataTimeInvalid && props.batteryPower > 0.0 && (
        <svg
          width="65"
          height="148"
          viewBox="0 0 36 82"
          filter="drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"
          style={{
            border: "0px",
            borderStyle: "solid",
            borderColor: "black",
            transform: "translate(45px,-90px)",
          }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeWidth="1.8"
            fill="none"
            stroke="dodgerblue"
            d="M 5 80 C 5 25, 14 25, 34 5"
          />

          <circle r="2.5" fill="dodgerblue">
            <animateMotion
              dur="20s"
              repeatCount="indefinite"
              path="M 5 80 C 5 25, 14 25, 34 5"
            />
          </circle>
        </svg>
      )}
      {!props.dataTimeInvalid && props.batteryPower <= 0.0 && (
        <svg
          width="65"
          height="148"
          viewBox="0 0 36 82"
          style={{
            border: "0px",
            borderStyle: "solid",
            borderColor: "black",
            transform: "translate(45px,-90px)",
          }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeWidth="1.8"
            fill="none"
            stroke="white"
            d="M 5 80 C 5 25, 14 25, 34 5"
          />
        </svg>
      )}
      {!props.dataTimeInvalid && props.bSVU > 0 && (
        <svg
          width="65"
          height="148"
          viewBox="0 0 36 82"
          filter="drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"
          style={{
            border: "0px",
            borderStyle: "solid",
            borderColor: "black",
            transform: "translate(65px,-85px)",
          }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeWidth="1.8"
            fill="none"
            stroke="orange"
            d="M 18 80 L 18 5"
            style={{ boxShadow: "3px 6px 2px #bbbbbb" }}
          />

          <circle r="2.5" fill="orange">
            <animateMotion
              dur="20s"
              repeatCount="indefinite"
              path="M 18 80 L 18 5"
            />
          </circle>
        </svg>
      )}
      {!props.dataTimeInvalid && props.bSVU <= 0 && (
        <svg
          width="65"
          height="148"
          viewBox="0 0 36 82"
          style={{
            border: "0px",
            borderStyle: "solid",
            borderColor: "black",
            transform: "translate(65px,-90px)",
          }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeWidth="1.8"
            fill="none"
            stroke="white"
            d="M 18 80 L 18 5"
          />
        </svg>
      )}
      {!props.dataTimeInvalid && props.cSVU > 0 && (
        <svg
          width="65"
          height="148"
          viewBox="0 0 36 82"
          filter="drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"
          style={{
            border: "0px",
            borderStyle: "solid",
            borderColor: "black",
            transform: "translate(85px,-90px)",
          }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeWidth="1.8"
            fill="none"
            stroke="purple"
            d="M 31 80 C 31 25, 22 25, 2 5"
          />

          <circle r="2.5" fill="purple">
            <animateMotion
              dur="20s"
              repeatCount="indefinite"
              path="M 31 80 C 31 25, 22 25, 2 5"
            />
          </circle>
        </svg>
      )}
      {!props.dataTimeInvalid && props.cSVU <= 0 && (
        <svg
          width="65"
          height="148"
          viewBox="0 0 36 82"
          style={{
            border: "0px",
            borderStyle: "solid",
            borderColor: "black",
            transform: "translate(85px,-90px)",
          }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeWidth="1.8"
            fill="none"
            stroke="white"
            d="M 31 80 C 31 25, 22 25, 2 5"
          />
        </svg>
      )}

      {!props.dataTimeInvalid && props.eSVU > 0 && (
        <svg
          width="90"
          height="30"
          viewBox="0 0 90 30"
          filter="drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"
          style={{
            border: "0px",
            borderStyle: "solid",
            borderColor: "black",
            transform: "translate(-20px,70px)",
          }}
        >
          <path
            strokeWidth="2.5"
            fill="none"
            stroke="magenta"
            d="M 89 1 C 89 25, 1 25, 1 1"
          />

          <circle r="3.5" fill="magenta">
            <animateMotion
              dur="20s"
              repeatCount="indefinite"
              path="M 1 1 C 1 25, 89 25,89 1 "
            />
          </circle>
        </svg>
      )}
      {!props.dataTimeInvalid && props.eSVU <= 0 && (
        <svg
          width="90"
          height="30"
          viewBox="0 0 90 30"
          style={{
            border: "0px",
            borderStyle: "solid",
            borderColor: "black",
            transform: "translate(-20px,70px)",
          }}
        >
          <path
            strokeWidth="2.5"
            fill="none"
            stroke="white"
            d="M 89 1 C 89 25, 1 25, 1 1"
          />
        </svg>
      )}
      {!props.dataTimeInvalid && props.batteryPower < 0.0 && (
        <svg
          width="90"
          height="30"
          viewBox="0 0 90 30"
          filter="drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"
          style={{
            border: "0px",
            borderStyle: "solid",
            borderColor: "black",
            transform: "translate(58px,-20px)",
            zIndex: 2,
          }}
        >
          <path
            strokeWidth="2.5"
            fill="none"
            stroke="red"
            d="M 88 2 C 88 25, 2 25, 2 2"
          />
          <circle r="3.5" fill="red">
            <animateMotion
              dur="20s"
              repeatCount="indefinite"
              path="M 88 2 C 88 25, 2 25, 2 2"
            />
          </circle>
        </svg>
      )}
      {!props.dataTimeInvalid && props.batteryPower > 0 && (
        <svg
          width="90"
          height="30"
          viewBox="0 0 90 30"
          style={{
            border: "0px",
            borderStyle: "solid",
            borderColor: "black",
            transform: "translate(58px,-20px)",
          }}
        >
          <path
            strokeWidth="2.5"
            fill="none"
            stroke="white"
            d="M 89 1 C 89 25, 1 25, 1 1"
          />
        </svg>
      )}
    </React.Fragment>
  );
}
export default EnergyAnimation;
