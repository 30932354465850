import React from "react";
import { ReactSVG } from "react-svg";
import styled, { css } from "styled-components";

const StyledSVGIcon = styled(ReactSVG)`
  svg {
    appliancename: ${(props) => props.applianceName};
    fill: black;
    ${({ size }) =>
      size &&
      css`
        width: ${size};
        height: ${size};
      `}
    ${({ transform }) =>
      transform &&
      css`
        transform: ${transform};
      `}
    path {
      ${({ color }) =>
        color &&
        css`
          fill: ${color};
        `}
    }
  }

  g {
    ${({ strokeColor }) =>
      strokeColor &&
      css`
        stroke: ${strokeColor};
      `}
  }
`;

const Icon = (props) => {
  return (
    <ReactSVG
      src={props.name}
      color={props.color}
      strokeColor={props.strokeColor}
      size={props.size}
      transform={props.transform}
      applianceName={props.applianceName}
    />
  );
};
export default Icon;

/*const Icon = (props) => {
  return (
    <StyledSVGIcon
      src={props.name}
      color={props.color}
      strokeColor={props.strokeColor}
      size={props.size}
      transform={props.transform}
      applianceName={props.applianceName}
    />
  );
};
export default Icon;*/
