import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getSystems } from "../services/systemService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setJwt } from "../services/httpService";

class Systems extends Component {
  state = {
    systemId: "",
    systems: []
  };

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    const systems = await getSystems();
    this.setState({ systems: systems.data.data });
  }

  render() {
    const systemCount = this.state.systems.length;
    if (systemCount === 0)
      return (
        <React.Fragment>
          <p> There are no systems in the database.</p>
          <Link
            to="/systems/new"
            className="btn btn-outline-info btn-sm"
            style={{ marginBottom: 20 }}
          >
            New System
          </Link>
        </React.Fragment>
      );

    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150"
          }}
        >
          <p />
          <Link
            to="/systems/new"
            className="btn btn-outline-info btn-sm"
            style={{ marginBottom: 20 }}
          >
            New System
          </Link>
          <table className="table" style={{ width: 350 }}>
            <thead>
              <tr>
                <th style={{ width: 175 }}>System </th>
                <th style={{ width: 75 }}>Type</th>
                <th style={{ width: 100 }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {this.state.systems.map(system => (
                <tr key={system.systemHandleID}>
                  <td>{system.systemName}</td>
                  <td>{system.systemProduct}</td>
                  <td>{system.systemType}</td>
                  <td className="icon">
                    <Link to={`/systems/dashboard/${system.systemHandleID}`}>
                      <FontAwesomeIcon
                        icon="tachometer-alt"
                        size="lg"
                        color="green"
                        title="monitor"
                      />
                    </Link>
                  </td>
                  <td className="icon">
                    <Link to={`/systems/signals/${system.systemHandleID}`}>
                      <FontAwesomeIcon
                        icon="satellite-dish"
                        size="lg"
                        color="black"
                        title="Signals"
                      />
                    </Link>
                  </td>
                  <td className="icon">
                    <Link to={`/systems/basics/${system.systemHandleID}`}>
                      <FontAwesomeIcon
                        icon="edit"
                        size="lg"
                        color="brown"
                        title="basics"
                      />
                    </Link>
                  </td>
                  <td className="icon">
                    <Link to={`/systems/controls/${system.systemHandleID}`}>
                      <FontAwesomeIcon
                        icon="sliders-h"
                        size="lg"
                        color="magenta"
                        title="controls"
                      />
                    </Link>
                  </td>
                  <td className="icon">
                    <Link to={`/systems/limits/${system.systemHandleID}`}>
                      <FontAwesomeIcon
                        icon="arrows-alt-v"
                        size="lg"
                        color="red"
                        title="limits"
                      />
                    </Link>
                  </td>
                  <td className="icon">
                    <Link to="/users">
                      <FontAwesomeIcon
                        icon="user-friends"
                        size="lg"
                        color="Tomato"
                        title="users"
                      />
                    </Link>
                  </td>
                  <td className="icon">
                    <Link to={`/systems/location/${system.systemHandleID}`}>
                      <FontAwesomeIcon
                        icon="map-marker-alt"
                        size="lg"
                        color="dodgerblue"
                        title="location"
                      />
                    </Link>
                  </td>
                  <td className="icon">
                    <Link to={`/systems/delete/${system.systemHandleID}`}>
                      <FontAwesomeIcon
                        icon="trash-alt"
                        size="lg"
                        color="grey"
                        title="delete"
                      />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default Systems;
