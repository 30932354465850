import React, { Component } from "react";

import { revokeSubAdmin } from "../services/userService";
import { setJwt } from "../services/httpService";
import { toast } from "react-toastify";

class UserRemoveSub extends Component {
  state = {
    errors: {},
  };

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    console.log("jwt", jwt);
    console.log("Props", this.props);
    const username = this.props.match.params.username;
    const companyName = this.props.match.params.companyName;
    const handleID = this.props.match.params.handleID;
    const res = await revokeSubAdmin(username, companyName);
    if (res.status !== 200) toast.error("Only the company admin can give subadmin privileges.")
    console.log("Revoke subadmin response", res);
    this.props.history.push(`/systems/users/${handleID}/${companyName}`);
    // this.props.history.push(`/systems/webSocketComp`);
  }

  render() {
    return null;
  }
}

export default UserRemoveSub;
