import React, { Component } from "react";

import "./dashboard.css";
import "../index.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import "react-datepicker/dist/react-datepicker.css";

function SimplSOC(props) {
  return (
    <React.Fragment>
      <ProgressBar
        style={{
          fontSize: "12px",
          fontWeight: "bold",
          width: "250px",
          transform: "translate(10px,330px)",
          boxShadow: "3px 5px 2px rgb(0 0 0 / 0.4)",
          zIndex: 2,
        }}
        animated
        now={props.overallSOC}
        label={`${props.overallSOC}%`}
      />{" "}
      <p
        className="basicParagraph"
        style={{
          width: "80px",
          fontSize: "12px",
          fontWeight: "bold",
          color: "dodgerblue",
          textAlign: "center",
          paddingRight: "0px",
          transform: "translate(250px,313px)",
        }}
      >
        {props.rangeHours} hrs.
      </p>
    </React.Fragment>
  );
}
export default SimplSOC;
