import React from "react";
import Joi from "joi-browser";

import "./dashboard.css";
import "../index.css";

import Form from "./common/form";
import BackGroundRectangle from "../components/backgroundRect";

class SetChartPoints extends Form {
  constructor(props) {
    super(props);

    this.state = {
      data: { chartPoints: localStorage.getItem("pointsOnChart") },

      optionsChartPoints: [
        { _id: "5", name: "5" },
        { _id: "10", name: "10" },
        { _id: "20", name: "20" },
        { _id: "50", name: "50" },
        { _id: "100", name: "100" },
        { _id: "200", name: "200" },
        { _id: "500", name: "500" },
        { _id: "1000", name: "1000" }
      ],
      errors: {}
    };
  }
  componentDidMount() {
    console.log("in Set Chart Points' CDM");
    //window.location = `/systems/chartpoints/${this.props.match.params.id}`;
  }
  schema = {
    chartPoints: Joi.number()
      .required()
      .label("Chart Points")
  };

  doSubmit = e => {
    const ptsOnChart = this.state.data.chartPoints;
    localStorage.setItem("pointsOnChart", ptsOnChart);
    this.props.history.push(
      `/systems/dashboardChart/${this.props.match.params.id}`
    );
  };

  render() {
    console.log("Points on Chart", this.state.data.chartPoints);
    return (
      <React.Fragment>
        <h6>Enter number of points on chart:</h6>

        <form onSubmit={this.handleSubmit}>
          {this.renderSelect(
            "chartPoints",
            "Number of Points on the Chart",
            this.state.optionsChartPoints
          )}

          {this.renderButton("Set Chart Points")}
        </form>
        <h6> </h6>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}
export default SetChartPoints;
