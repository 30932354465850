import React, {useState, useEffect} from "react";
import Form from "./common/form";
import Joi from "joi-browser";

import { addUserToCompany, getUser, getUserByUsername, addSubAdmin, revokeSubAdmin } from "../services/userService";
import { Link } from "react-router-dom";
import { setJwt } from "../services/httpService";
import BackGroundRectangle from "../components/backgroundRect";

import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { toast } from "react-toastify";


function CheckBox({user}){

  const [checked, changeCheck] = useState(false)

  const changeSubAdminPermissions = async (user) => {
    try {
      if (checked) {
        await addSubAdmin(user.username, user.company)
        toast.success("User succesfully added as sub-admin")
      } else if (!checked){
        await revokeSubAdmin(user.username, user.company)
        toast.success("User succesfully removed as sub-admin")
      } else {
        toast.error("You are unable to complete this operation")
      }
    } catch (e) {
      console.log(e);
      toast.error("You are unauthorized to complete this operation")
    }
  }

  try {
    useEffect(()=>{
      if (user.subadmin){
        changeCheck(true)
      }
    }, checked)
  } catch (e){
    toast.error(e.message)
  }

  return (
    <>
      <div style={{
        marginTop: "30px",
        display: "flex",
        flexFlow: "column wrap",
        alignItems: "center",
      }} >
        {user.subadmin && (
          <p className="basicParagraph text-center">
            This user is currently granted sub-admin permissions to your company systems management. Would you like to switch it? 
          </p>
        )}
        {!user.subadmin && (
          <p className="basicParagraph text-center">
            This user is not currently granted sub-admin permissions to your company systems management. Would you like to switch it? 
          </p>
        )}
        {checked === true && (
          <>
            <div class="custom-control custom-switch">
                <input
                type="checkbox"
                class="custom-control-input"
                id="customSwitches"
                checked
                onChange={()=>{
                    changeCheck(false)
                }}
                ></input>
                <label class="custom-control-label" for="customSwitches">
                    <p
                    style={{ fontSize: "10px", paddingTop: "5px" }}
                    className="font-weight-bold"
                    >
                      By clicking Update your user will now be a Sub-Admin
                    </p>
                </label>
            </div>
          </>
          
        )}
        {checked === false && (
          <>
            <div class="custom-control custom-switch">
                <input
                type="checkbox"
                class="custom-control-input"
                id="customSwitches"
                onChange={()=>{
                    changeCheck(true)
                }}
                ></input>
                <label class="custom-control-label" for="customSwitches">
                    <p
                    className="font-weight-bold"
                    style={{ fontSize: "10px", paddingTop: "5px" }}
                    >
                        By clicking Update your user will not be a Sub-Admin anymore.
                    </p>
                </label>
            </div>
          </>
        )}

        <button onClick={() => changeSubAdminPermissions(user)}
            type="button"
            class="btn btn-outline-warning btn-sm mt-4">
              Update
        </button>
      </div>
    </>
  )
}

class AddUserToCompany extends Form {
  state = {
    data: { username: "" },
    userToAdd : {},
    companyName: "",
    errors: {}
  };

  schema = {
    username: Joi.string().label("Username")
  };

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    const user = await getUser();
    const companyName = this.props.companyName;
    console.log("Company Name", companyName);
    this.setState({ companyName: companyName });
  }

  doSubmit = async () => {
    try {
      const { data } = this.state;
      const jwt = localStorage.getItem("token");
      setJwt(jwt);
      console.log("User to be added", data.username); 
      await addUserToCompany(data.username);
      let userToBeAdded = await getUserByUsername(data.username)
      userToBeAdded = userToBeAdded.data.data

      this.setState({ userToAdd: userToBeAdded });

      // window.location = "/users";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors: errors });
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "20px",
            marginBottom: "150px",
            zIndex: 1
          }}
        >
          <p className="basicParagraph">
            Enter the username of the user you are adding to{" "}
            {this.state.companyName}
          </p>
          <form onSubmit={this.handleSubmit}>
            {this.renderInput("username", "Username")}
            <div style={{
              marginTop: "10px",
              display: "flex",
              flexFlow: "row nowrap",
              justifyContent: "center"
            }}>
              {this.renderButton("Add User")}
            </div>
          </form>

          {Object.keys(this.state.userToAdd).length > 0 && (
            <CheckBox user={this.state.userToAdd} />
          )}


        </div>
      </React.Fragment>
    );
  }
}

export default AddUserToCompany;
