import React, { Component } from "react";
import { toast } from "react-toastify";
import {
  getSystems,
  findSystemBySerialNumber,
  getSystem,
  getSystemInfo,
  uninstallSystem,
} from "../services/systemService";
import CommForm from "./sfCommission";
import BackGroundRectangle from "../components/backgroundRect";
import ReactExport from "react-data-export";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import QrContainer from "./qrContainer";

class SearchProduct extends Component {
  state = {
    systemId: "",
    serialNumber: "",
    macAddress: "",
    message: "",
    status: "",
    handleId: "",
    systems: [],
    filteredSystem: [],
  };

  handleSubmitSN = async (e) => {
    e.preventDefault();
    const numOfSystems = (await getSystems()).data.data.length;
    let systems = (await getSystems()).data.data;
    console.log("Systems:", systems);
    console.log("Number of Systems", numOfSystems);
    const filteredByMAC = systems.filter(
      (macSystem) => macSystem.systemMAC === "34:86:5d:47:fb:94"
    );
    console.log("Filtered by MAC System: ", filteredByMAC);
    let filteredSystem = [];
    if (this.state.serialNumber === "*") {
      filteredSystem = systems;
      let numOfAllSystems = filteredSystem.length;
      console.log("Filtered Systems", filteredSystem);
      this.setState({ message: "All systems are exported to a spreadsheet." });
      this.setState({ numOfAllSystems });
      let excelDataSet = [0];
      let productSNInfo = "";
      let fourGSN = "";
      let systemBasics = [];
      for (let i = 0; i < filteredSystem.length; i++) {
        if (
          filteredSystem[i].systemBasics === null ||
          filteredSystem[i].systemBasics === undefined
        )
          systemBasics[i] = "NA";
        else systemBasics[i] = filteredSystem[i].systemBasics;

        if (systemBasics[i] === "NA") {
          productSNInfo = "NA";
          fourGSN = "NA";
        } else {
          productSNInfo = systemBasics[i].productSN;
          fourGSN = systemBasics[i].fourGserialNumber;
        }

        /*filteredSystem[i].systemBasics.productSN;*/
        excelDataSet[i] = JSON.parse(
          JSON.stringify({
            systemName: filteredSystem[i].systemName,
            systemSN: filteredSystem[i].systemSN,
            productSN: productSNInfo,
            fourGSN: fourGSN,
            systemHandleID: filteredSystem[i].systemHandleID,
            systemMAC: filteredSystem[i].systemMAC,
            systemType: filteredSystem[i].systemType,
            systemProduct: filteredSystem[i].systemProduct,
          })
        );
      }
      console.log("Excel Data JSON", excelDataSet);
      this.setState({ excelDataSet });
    } else {
      //First search for boards
      const filteredSystem = systems.filter(
        (thisSystem) => thisSystem.systemSN === this.state.serialNumber
      );
      console.log("Filtered System", filteredSystem);

      if (filteredSystem.length === 0) {
        //This is not a board so let's search for it as a Product
        systems = (await getSystems()).data;
        let i = 0;
        let numOfFoundSystems = 0;
        let foundSystem;
        for (i = 0; i < numOfSystems; i++) {
          if (systems.data[i].systemBasics !== null) {
            if (systems.data[i].systemBasics.productSN !== undefined) {
              if (
                systems.data[i].systemBasics.productSN ===
                this.state.serialNumber
              ) {
                numOfFoundSystems++;
                foundSystem = (await getSystems()).data.data[i];
              }
            }
          }
        }

        if (numOfFoundSystems === 0) {
          this.setState({ message: "Error: Invalid serial number." });
          toast.error("Error: Invalid serial number.");
        } else {
          this.setState({ message: "This serial number is a Product." });

          const macAddress = foundSystem.systemMAC;
          const handleId = foundSystem.systemHandleID;
          const info = await getSystemInfo(handleId);
          console.log("Info", info);
          const systemProduct = info.data.data.basics.systemProduct;
          const endProductPN = info.data.data.basics.endProductPN;
          if (
            endProductPN !== "SimplBox Hub" &&
            endProductPN !== "SimplBox 4G-Hub" &&
            numOfFoundSystems >= 2
          )
            toast.error("Error: Duplicate serial numbers in the system.");
          else {
            let serialNumberMaster = "NA";
            if (
              endProductPN === "SimplBox Hub" ||
              endProductPN === "SimplBox 4G-Hub" ||
              endProductPN === "SimplMeter-1R" ||
              endProductPN === "SimplMeter-1C" ||
              endProductPN === "SimplMeter-2R" ||
              endProductPN === "SimplMeter-2C" ||
              endProductPN === "SimplHub Module" ||
              endProductPN === "SimplHub 4G" ||
              endProductPN === "SimplGateway" ||
              endProductPN === "SimplGateway 4G"
            )
              serialNumberMaster = info.data.data.basics.masterBoardSN;
            const systemType = info.data.data.basics.systemType;
            const slaveSN = info.data.data.basics.slaveSN;
            const fourGSN = info.data.data.basics.fourGserialNumber;
            this.setState({
              serialNumberMaster,
              handleId,
              slaveSN,
              systemProduct,
              endProductPN,
              systemType,
              fourGSN,
            });
          }
        }
      } else if (filteredSystem.length === 1) {
        this.setState({ message: "This serial number is a Board." });
        const systemName = filteredSystem[0].systemName;
        const handleId = filteredSystem[0].systemHandleID;
        const systemMAC = filteredSystem[0].systemMAC;
        const systemType = filteredSystem[0].systemType;
        const systemProduct = filteredSystem[0].systemProduct;

        let installedInside = "";
        let installedSlave = "";
        let fourGserialNumber = "";
        if (filteredSystem[0].systemBasics !== null) {
          if (
            filteredSystem[0].systemBasics.productSN !== null &&
            filteredSystem[0].systemBasics.productSN !== undefined
          ) {
            installedInside = filteredSystem[0].systemBasics.productSN;
            // if(installedInside ==="1")
            // installedInside = filteredSystem[0].systemBasics.productSN;
            installedSlave = filteredSystem[0].systemBasics.slaveSN;
            fourGserialNumber =
              filteredSystem[0].systemBasics.fourGserialNumber;
          }
        }

        this.setState({
          systemName,
          handleId,
          systemMAC,
          systemType,
          systemProduct,
          installedInside,
          installedSlave,
          fourGserialNumber,
        });
      } else toast.error("Error: Duplicate serial numbers in the system.");
    }
  };

  handleSubmitMAC = async (e) => {
    e.preventDefault();
    const numOfSystems = (await getSystems()).data.data.length;
    let systems = (await getSystems()).data.data;
    console.log("Systems:", systems);
    console.log("Number of Systems", numOfSystems);
    const filteredByMAC = systems.filter(
      (macSystem) => macSystem.systemMAC === "34:86:5d:47:fb:94"
    );
    console.log("Filtered by MAC System: ", filteredByMAC);
    let filteredSystem = [];
    if (this.state.serialNumber === "*") {
      filteredSystem = systems;
      let numOfAllSystems = filteredSystem.length;
      console.log("Filtered Systems", filteredSystem);
      this.setState({ message: "All systems are exported to a spreadsheet." });
      this.setState({ numOfAllSystems });
      let excelDataSet = [0];
      let productSNInfo = "";
      let fourGSN = "";
      let systemBasics = [];
      for (let i = 0; i < filteredSystem.length; i++) {
        if (
          filteredSystem[i].systemBasics === null ||
          filteredSystem[i].systemBasics === undefined
        )
          systemBasics[i] = "NA";
        else systemBasics[i] = filteredSystem[i].systemBasics;

        if (systemBasics[i] === "NA") {
          productSNInfo = "NA";
          fourGSN = "NA";
        } else {
          productSNInfo = systemBasics[i].productSN;
          fourGSN = systemBasics[i].fourGserialNumber;
        }

        /*filteredSystem[i].systemBasics.productSN;*/
        excelDataSet[i] = JSON.parse(
          JSON.stringify({
            systemName: filteredSystem[i].systemName,
            systemSN: filteredSystem[i].systemSN,
            productSN: productSNInfo,
            fourGSN: fourGSN,
            systemHandleID: filteredSystem[i].systemHandleID,
            systemMAC: filteredSystem[i].systemMAC,
            systemType: filteredSystem[i].systemType,
            systemProduct: filteredSystem[i].systemProduct,
          })
        );
      }
      console.log("Excel Data JSON", excelDataSet);
      this.setState({ excelDataSet });
    } else {
      //First search for boards
      const filteredSystem = systems.filter(
        (thisSystem) => thisSystem.systemMAC === this.state.macAddress
      );
      console.log("Filtered System", filteredSystem);

      if (filteredSystem.length === 0) {
        //This is not a board so let's search for it as a Product
        systems = (await getSystems()).data;
        let i = 0;
        let numOfFoundSystems = 0;
        let foundSystem;
        for (i = 0; i < numOfSystems; i++) {
          if (systems.data[i].systemBasics !== null) {
            if (systems.data[i].systemBasics.productSN !== undefined) {
              if (
                systems.data[i].systemBasics.productSN ===
                this.state.serialNumber
              ) {
                numOfFoundSystems++;
                foundSystem = (await getSystems()).data.data[i];
              }
            }
          }
        }

        if (numOfFoundSystems === 0) {
          this.setState({ message: "Error: Invalid Mac Address." });
          toast.error("Error: Invalid MAC Address.");
        } else {
          this.setState({ message: "This serial number is a Product." });

          const foundSN = foundSystem.systemSN;
          const macAddress = foundSystem.systemMAC;
          const handleId = foundSystem.systemHandleID;
          const info = await getSystemInfo(handleId);
          console.log("Info", info);
          const systemProduct = info.data.data.basics.systemProduct;
          const endProductPN = info.data.data.basics.endProductPN;
          if (
            endProductPN !== "SimplBox Hub" &&
            endProductPN !== "SimplBox 4G-Hub" &&
            numOfFoundSystems >= 2
          )
            toast.error("Error: Duplicate serial numbers in the system.");
          else {
            let serialNumberMaster = "NA";
            if (
              endProductPN === "SimplBox Hub" ||
              endProductPN === "SimplBox 4G-Hub" ||
              endProductPN === "SimplMeter-1R" ||
              endProductPN === "SimplMeter-1C" ||
              endProductPN === "SimplMeter-2R" ||
              endProductPN === "SimplMeter-2C" ||
              endProductPN === "SimplHub Module" ||
              endProductPN === "SimplHub 4G" ||
              endProductPN === "SimplGateway" ||
              endProductPN === "SimplGateway 4G"
            )
              serialNumberMaster = info.data.data.basics.masterBoardSN;
            const systemType = info.data.data.basics.systemType;
            const slaveSN = info.data.data.basics.slaveSN;
            const fourGSN = info.data.data.basics.fourGserialNumber;
            this.setState({
              serialNumberMaster,
              handleId,
              slaveSN,
              systemProduct,
              endProductPN,
              systemType,
              fourGSN,
            });
          }
        }
      } else if (filteredSystem.length === 1) {
        this.setState({ message: "This serial number is a Board." });
        const systemName = filteredSystem[0].systemName;
        const handleId = filteredSystem[0].systemHandleID;
        const systemMAC = filteredSystem[0].systemMAC;
        const systemType = filteredSystem[0].systemType;
        const systemProduct = filteredSystem[0].systemProduct;
        const systemSN = filteredSystem[0].systemSN;

        let installedInside = "";
        let installedSlave = "";
        let fourGserialNumber = "";
        if (filteredSystem[0].systemBasics !== null) {
          if (
            filteredSystem[0].systemBasics.productSN !== null &&
            filteredSystem[0].systemBasics.productSN !== undefined
          ) {
            installedInside = filteredSystem[0].systemBasics.productSN;
            // if(installedInside ==="1")
            // installedInside = filteredSystem[0].systemBasics.productSN;
            installedSlave = filteredSystem[0].systemBasics.slaveSN;
            fourGserialNumber =
              filteredSystem[0].systemBasics.fourGserialNumber;
          }
        }

        this.setState({
          systemName,
          handleId,
          systemMAC,
          systemType,
          systemProduct,
          installedInside,
          installedSlave,
          fourGserialNumber,
          systemSN,
        });
      } else toast.error("Error: Duplicate MAC Address.");
    }
  };

  handleSubmitHandleID = async (e) => {
    e.preventDefault();
    const numOfSystems = (await getSystems()).data.data.length;
    let systems = (await getSystems()).data.data;
    console.log("Systems:", systems);
    console.log("Number of Systems", numOfSystems);

    let filteredSystem = [];
    if (this.state.serialNumber === "*") {
      filteredSystem = systems;
      let numOfAllSystems = filteredSystem.length;
      console.log("Filtered Systems", filteredSystem);
      this.setState({ message: "All systems are exported to a spreadsheet." });
      this.setState({ numOfAllSystems });
      let excelDataSet = [0];
      let productSNInfo = "";
      let fourGSN = "";
      let systemBasics = [];
      for (let i = 0; i < filteredSystem.length; i++) {
        if (
          filteredSystem[i].systemBasics === null ||
          filteredSystem[i].systemBasics === undefined
        )
          systemBasics[i] = "NA";
        else systemBasics[i] = filteredSystem[i].systemBasics;

        if (systemBasics[i] === "NA") {
          productSNInfo = "NA";
          fourGSN = "NA";
        } else {
          productSNInfo = systemBasics[i].productSN;
          fourGSN = systemBasics[i].fourGserialNumber;
        }

        /*filteredSystem[i].systemBasics.productSN;*/
        excelDataSet[i] = JSON.parse(
          JSON.stringify({
            systemName: filteredSystem[i].systemName,
            systemSN: filteredSystem[i].systemSN,
            productSN: productSNInfo,
            fourGSN: fourGSN,
            systemHandleID: filteredSystem[i].systemHandleID,
            systemMAC: filteredSystem[i].systemMAC,
            systemType: filteredSystem[i].systemType,
            systemProduct: filteredSystem[i].systemProduct,
          })
        );
      }
      console.log("Excel Data JSON", excelDataSet);
      this.setState({ excelDataSet });
    } else {
      console.log("Handle Id of the sysem: ", parseInt(this.state.handleID));
      const filteredSystem = systems.filter(
        (thisSystem) =>
          thisSystem.systemHandleID === parseInt(this.state.handleID)
      );
      console.log("Filtered System", filteredSystem);

      if (filteredSystem.length === 0) {
        //This is not a board so let's search for it as a Product
        systems = (await getSystems()).data;
        let i = 0;
        let numOfFoundSystems = 0;
        let foundSystem;
        for (i = 0; i < numOfSystems; i++) {
          if (systems.data[i].systemBasics !== null) {
            if (systems.data[i].systemBasics.productSN !== undefined) {
              if (
                systems.data[i].systemBasics.productSN ===
                this.state.serialNumber
              ) {
                numOfFoundSystems++;
                foundSystem = (await getSystems()).data.data[i];
              }
            }
          }
        }

        if (numOfFoundSystems === 0) {
          this.setState({ message: "Error: Invalid Mac Address." });
          toast.error("Error: Invalid MAC Address.");
        } else {
          this.setState({ message: "This serial number is a Product." });

          const foundSN = foundSystem.systemSN;
          const macAddress = foundSystem.systemMAC;
          const handleId = foundSystem.systemHandleID;
          const info = await getSystemInfo(handleId);
          console.log("Info", info);
          const systemProduct = info.data.data.basics.systemProduct;
          const endProductPN = info.data.data.basics.endProductPN;
          if (
            endProductPN !== "SimplBox Hub" &&
            endProductPN !== "SimplBox 4G-Hub" &&
            numOfFoundSystems >= 2
          )
            toast.error("Error: Duplicate serial numbers in the system.");
          else {
            let serialNumberMaster = "NA";
            if (
              endProductPN === "SimplBox Hub" ||
              endProductPN === "SimplBox 4G-Hub" ||
              endProductPN === "SimplMeter-1R" ||
              endProductPN === "SimplMeter-1C" ||
              endProductPN === "SimplMeter-2R" ||
              endProductPN === "SimplMeter-2C" ||
              endProductPN === "SimplHub Module" ||
              endProductPN === "SimplHub 4G" ||
              endProductPN === "SimplGateway" ||
              endProductPN === "SimplGateway 4G"
            )
              serialNumberMaster = info.data.data.basics.masterBoardSN;
            const systemType = info.data.data.basics.systemType;
            const slaveSN = info.data.data.basics.slaveSN;
            const fourGSN = info.data.data.basics.fourGserialNumber;
            this.setState({
              serialNumberMaster,
              handleId,
              slaveSN,
              systemProduct,
              endProductPN,
              systemType,
              fourGSN,
            });
          }
        }
      } else if (filteredSystem.length === 1) {
        this.setState({ message: "This serial number is a Board." });
        const systemName = filteredSystem[0].systemName;
        const handleId = filteredSystem[0].systemHandleID;
        const systemMAC = filteredSystem[0].systemMAC;
        const systemType = filteredSystem[0].systemType;
        const systemProduct = filteredSystem[0].systemProduct;
        const systemSN = filteredSystem[0].systemSN;

        let installedInside = "";
        let installedSlave = "";
        let fourGserialNumber = "";
        if (filteredSystem[0].systemBasics !== null) {
          if (
            filteredSystem[0].systemBasics.productSN !== null &&
            filteredSystem[0].systemBasics.productSN !== undefined
          ) {
            installedInside = filteredSystem[0].systemBasics.productSN;
            // if(installedInside ==="1")
            // installedInside = filteredSystem[0].systemBasics.productSN;
            installedSlave = filteredSystem[0].systemBasics.slaveSN;
            fourGserialNumber =
              filteredSystem[0].systemBasics.fourGserialNumber;
          }
        }

        this.setState({
          systemName,
          handleId,
          systemMAC,
          systemType,
          systemProduct,
          installedInside,
          installedSlave,
          fourGserialNumber,
          systemSN,
        });
      } else toast.error("Error: Duplicate MAC Address.");
    }
  };

  handleUninstall = async (e) => {
    e.preventDefault();
    await uninstallSystem(this.state.systemId);
    console.log("Uninstall SystemId", this.state.systemId);
  };

  handleChangeSN = (e) => {
    //  const serialNumber = { ...this.state.serialNumber };
    const serialNumber = e.currentTarget.value;
    this.setState({ serialNumber });
  };
  handleChangeMAC = (e) => {
    //  const serialNumber = { ...this.state.serialNumber };
    const macAddress = e.currentTarget.value;
    this.setState({ macAddress });
  };
  handleChangeHandleID = (e) => {
    //  const serialNumber = { ...this.state.serialNumber };
    const handleID = e.currentTarget.value;
    this.setState({ handleID });
  };
  handleScan = (data) => {
    if (data) {
      this.setState({
        serialNumber: data,
      });
    }
  };

  handleSearchMethod = (e) => {
    const searchMethod = e.currentTarget.value;
    this.setState({ searchMethod });
  };
  render() {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    return (
      <React.Fragment>
        <div
          className="form-group"
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            zIndex: 1,
          }}
        >
          <div>
            <label
              className="basicParagraph"
              style={{ height: "30px", width: "150px" }}
            >
              Select a search method:
            </label>
            <select
              className="form-control2"
              value={this.state.nodeName}
              onChange={this.handleSearchMethod}
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                border: "none",
                borderRadius: "0px",
                borderBottom: "2px solid lightgrey",
              }}
            >
              <option value="Select Search">Select Search</option>
              <option value="by SN">Serial Number</option>
              <option value="by MAC">MAC Address</option>
              <option value="by Handle ID">Handle ID</option>
            </select>
          </div>
          {this.state.searchMethod === "by SN" && (
            <form onSubmit={this.handleSubmitSN}>
              <label className="basicParagraph" htmlFor="">
                Input serial number:
              </label>
              {!this.state.serialNumber && (
                <QrContainer onScan={this.handleScan.bind(this)} />
              )}

              <input
                value={this.state.serialNumber}
                onChange={this.handleChangeSN}
                id="serialNumber"
                type="text"
                className="form-control2"
              />

              <button
                className="basicButton"
                style={{ width: "200px", marginTop: "10px" }}
              >
                Search
              </button>
            </form>
          )}
          {this.state.searchMethod === "by MAC" && (
            <form onSubmit={this.handleSubmitMAC}>
              <label className="basicParagraph" htmlFor="">
                Input MAC Address:
              </label>

              <input
                value={this.state.macAddress}
                onChange={this.handleChangeMAC}
                id="serialNumber"
                type="text"
                className="form-control2"
              />

              <button
                className="basicButton"
                style={{ width: "200px", marginTop: "10px" }}
              >
                Search
              </button>
            </form>
          )}
          {this.state.searchMethod === "by Handle ID" && (
            <form onSubmit={this.handleSubmitHandleID}>
              <label className="basicParagraph" htmlFor="">
                Enter Handle ID of the device:
              </label>

              <input
                value={this.state.handleID}
                onChange={this.handleChangeHandleID}
                id="serialNumber"
                type="text"
                className="form-control2"
              />

              <button
                className="basicButton"
                style={{ width: "200px", marginTop: "10px" }}
              >
                Search
              </button>
            </form>
          )}
          <p />

          {this.state.message ===
            "All systems are exported to a spreadsheet." && (
            <div>
              <p>
                {this.state.numOfAllSystems} systems were found. Click here to
                export to a spreadsheet:
              </p>
              <ExcelFile
                filename="Simpl Global Systems Data"
                element={
                  <button
                    className="btn btn-outline-light"
                    style={{
                      borderWidth: "0px",
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  >
                    <FontAwesomeIcon
                      icon="file-excel"
                      size="lg"
                      color="green"
                      title="Export Systems' info to excel"
                    />
                  </button>
                }
              >
                <ExcelSheet data={this.state.excelDataSet} name="SystemData">
                  <ExcelColumn label="System Name" value="systemName" />
                  <ExcelColumn label="Board SN" value="systemSN" />
                  <ExcelColumn label="Product SN" value="productSN" />
                  <ExcelColumn label="4G Module SN" value="fourGSN" />
                  <ExcelColumn label="Handle ID" value="systemHandleID" />
                  <ExcelColumn label="System MAC" value="systemMAC" />
                  <ExcelColumn label="System Type" value="systemType" />
                  <ExcelColumn label="System Product" value="systemProduct" />
                </ExcelSheet>
              </ExcelFile>
            </div>
          )}
          {this.state.message === "Error: Invalid serial number." && (
            <p>Error: Invalid serial number.</p>
          )}
          {this.state.message === "This serial number is a Board." && (
            <div>
              <p>This is a board with the following information:</p>
              <p>MAC Address: {this.state.systemMAC}</p>
              <p>Serial Number: {this.state.systemSN}</p>
              <p>Type: {this.state.systemType}</p>
              <p>System Type: {this.state.systemProduct}</p>
              <p>Installed inside: {this.state.installedInside}</p>
              <p>This board's handle ID is: {this.state.handleId}</p>
              {/*} <p>To delete the system press here:</p>
              <Link
                style={{
                  borderWidth: "0px",
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
                to={`/systems/delete/${this.state.handleId}`}
              >
                <FontAwesomeIcon
                  icon="trash-alt"
                  size="lg"
                  color="red"
                  title="Delete System"
                />
              </Link>*/}
            </div>
          )}

          {this.state.message === "This serial number is a Product." && (
            <div>
              <p>This is a Product with the following information:</p>
              <p>Search with Board serial Numbers for more information.</p>
              <p>Board SN: {this.state.systemSN}</p>
              <p>Master Board SN: {this.state.serialNumberMaster}</p>
              <p>4G Module SN: {this.state.fourGSN}</p>
              <p>Main Board SN: {this.state.slaveSN}</p>
              <p>End Product PN: {this.state.endProductPN}</p>
              <p>Product PN: {this.state.systemProduct}</p>
              <p>System Type: {this.state.systemType}</p>
            </div>
          )}
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default SearchProduct;
