import React, { Component } from "react";
import { toast } from "react-toastify";
import Form from "./common/form";
import {
  getSystem,
  getSystemControls,
  setSystemControls,
  setSystemControlsObject,
  getSystemIDfromHandle,
  findSystemBySerialNumber,
  getSystems,
  getSystemInfo,
  setSystemLimitsObject,
  getSystemLimits,
} from "../services/systemService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import BackGroundRectangle from "../components/backgroundRect";
import Clipboard from "react-clipboard.js";
import ReactExport from "react-data-export";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import "../excel-2007.css";
import { getUser } from "../services/userService";
import { NUM_OF_HOLIDAYS } from "../config.json";

class SimplNodesLimits extends Component {
  constructor() {
    super();
  }
  state = {
    dataLoaded: false,
    message: "",
    systemID: "",
    systemSN: "",
    systemName: "",
    errors: {},
    username: "",
    systemHolidays: [],
    maxDmdIncrease: "5",
  };

  async componentDidMount() {
    const setControlsUpload = 0;
    const handleId = this.props.match.params.systemHandleID;
    const systemID = (await getSystemIDfromHandle(handleId)).data.data
      .system_id;

    const system = (await getSystem(systemID)).data.data[0];
    const systemName = system.systemID.name;
    console.log("System Info", system);

    let systemLimits = (await getSystemLimits(handleId)).data.data.limits;
    let systemHolidays = systemLimits.systemHolidays;

    if (!systemLimits) {
      console.log("System Limits is undefined.........");
      systemLimits = {};
    }
    let systemHolidaysLength = 0;
    if (systemHolidays) {
      systemHolidaysLength = systemHolidays.length;
    } else {
      systemHolidays = [];
    }
    const user = await getUser();
    this.setState({ username: user.data.data.username });
    this.setState({
      systemID,
      systemName,
      handleId,
      startTimeA: systemLimits.startTimeA,
      startTimeB: systemLimits.startTimeB,
      startTimeC: systemLimits.startTimeC,
      startTimeD: systemLimits.startTimeD,
      startTimeE: systemLimits.startTimeE,
      startTimeF: systemLimits.startTimeF,
      startTimeG: systemLimits.startTimeG,
      startTimeH: systemLimits.startTimeH,
      endTimeA: systemLimits.endTimeA,
      endTimeB: systemLimits.endTimeB,
      endTimeC: systemLimits.endTimeC,
      endTimeD: systemLimits.endTimeD,
      endTimeE: systemLimits.endTimeE,
      endTimeF: systemLimits.endTimeF,
      endTimeG: systemLimits.endTimeG,
      endTimeH: systemLimits.endTimeH,
      OffTimeOptionA: systemLimits.OffTimeOptionA,
      OffTimeOptionB: systemLimits.OffTimeOptionB,
      OffTimeOptionC: systemLimits.OffTimeOptionC,
      OffTimeOptionD: systemLimits.OffTimeOptionD,
      OffTimeOptionE: systemLimits.OffTimeOptionE,
      OffTimeOptionF: systemLimits.OffTimeOptionF,
      lowTempA: systemLimits.lowTempA,
      lowTempB: systemLimits.lowTempB,
      lowTempC: systemLimits.lowTempC,
      lowTempD: systemLimits.lowTempD,
      lowTempE: systemLimits.lowTempE,
      lowTempF: systemLimits.lowTempF,
      hiTempA: systemLimits.hiTempA,
      hiTempB: systemLimits.hiTempB,
      hiTempC: systemLimits.hiTempC,
      hiTempD: systemLimits.hiTempD,
      hiTempE: systemLimits.hiTempE,
      hiTempF: systemLimits.hiTempF,
      overRideMins: systemLimits.overRideMins,
      returnRoomDelta: systemLimits.returnRoomDelta,
      secondStageDelta: systemLimits.secondStageDelta,
      autoHeatCool: systemLimits.autoHeatCool,
      coolingHyst: systemLimits.coolingHyst,
      heatingHyst: systemLimits.heatingHyst,
      lightsOn: systemLimits.lightsOn,
      lightsOff: systemLimits.lightsOff,
      sunTimeOffset: systemLimits.sunTimeOffset,
      minORTemp: systemLimits.minORTemp,
      maxORTemp: systemLimits.maxORTemp,
      earlyStartFactor: systemLimits.earlyStartFactor,
      maxEarly: systemLimits.maxEarly,
      minEarlyDT: systemLimits.minEarlyDT,
      earlyStopOutsideDeltaT: systemLimits.earlyStopOutsideDeltaT,
      earlyStopRoomDeltaT: systemLimits.earlyStopRoomDeltaT,
      earlyStopMins: systemLimits.earlyStopMins,

      systemHolidays,
      systemHolidaysLength,
      maxDmdIncrease: systemLimits.maxDmdIncrease,
    });
  }

  handleNodeLimits = async (e) => {
    e.preventDefault();
    let simplNodes = [];
    let systemLimits = {};
    const systemID = this.state.systemID;
    let limits = {
      startTimeA: this.state.startTimeA,
      startTimeB: this.state.startTimeB,
      startTimeC: this.state.startTimeC,
      startTimeD: this.state.startTimeD,
      startTimeE: this.state.startTimeE,
      startTimeF: this.state.startTimeF,
      startTimeG: this.state.startTimeG,
      startTimeH: this.state.startTimeH,
      endTimeA: this.state.endTimeA,
      endTimeB: this.state.endTimeB,
      endTimeC: this.state.endTimeC,
      endTimeD: this.state.endTimeD,
      endTimeE: this.state.endTimeE,
      endTimeF: this.state.endTimeF,
      endTimeG: this.state.endTimeG,
      endTimeH: this.state.endTimeH,
      OffTimeOptionA: this.state.OffTimeOptionA,
      OffTimeOptionB: this.state.OffTimeOptionB,
      OffTimeOptionC: this.state.OffTimeOptionC,
      OffTimeOptionD: this.state.OffTimeOptionD,
      OffTimeOptionE: this.state.OffTimeOptionE,
      OffTimeOptionF: this.state.OffTimeOptionF,
      lowTempA: this.state.lowTempA,
      lowTempB: this.state.lowTempB,
      lowTempC: this.state.lowTempC,
      lowTempD: this.state.lowTempD,
      lowTempE: this.state.lowTempE,
      lowTempF: this.state.lowTempF,
      hiTempA: this.state.hiTempA,
      hiTempB: this.state.hiTempB,
      hiTempC: this.state.hiTempC,
      hiTempD: this.state.hiTempD,
      hiTempE: this.state.hiTempE,
      hiTempF: this.state.hiTempF,
      overRideMins: this.state.overRideMins,
      returnRoomDelta: this.state.returnRoomDelta,
      secondStageDelta: this.state.secondStageDelta,
      autoHeatCool: this.state.autoHeatCool,
      heatingHyst: this.state.heatingHyst,
      coolingHyst: this.state.coolingHyst,
      lightsOn: this.state.lightsOn,
      lightsOff: this.state.lightsOff,
      sunTimeOffset: this.state.sunTimeOffset,

      minORTemp: this.state.minORTemp,
      maxORTemp: this.state.maxORTemp,
      systemHolidays: this.state.systemHolidays,

      earlyStartFactor: this.state.earlyStartFactor,
      maxEarly: this.state.maxEarly,
      minEarlyDT: this.state.minEarlyDT,
      earlyStopOutsideDeltaT: this.state.earlyStopOutsideDeltaT,
      earlyStopRoomDeltaT: this.state.earlyStopRoomDeltaT,
      earlyStopMins: this.state.earlyStopMins,

      maxDmdIncrease: this.state.maxDmdIncrease,
    };

    const limitsRes = await setSystemLimitsObject(
      systemID,
      limits,
      this.state.username
    );
    console.log("Response of set limits function: ", limitsRes);
    console.log("Username in limits: ", this.state.username);

    systemLimits = (await getSystemLimits(this.state.handleId)).data.data
      .limits;
    console.log("System Limits:", systemLimits);
    this.props.history.goBack();
  };

  handleStartTimeA = (e) => {
    const startTimeA = e.currentTarget.value;
    this.setState({ startTimeA });
  };
  handleStartTimeB = (e) => {
    const startTimeB = e.currentTarget.value;
    this.setState({ startTimeB });
  };
  handleStartTimeC = (e) => {
    const startTimeC = e.currentTarget.value;
    this.setState({ startTimeC });
  };
  handleStartTimeD = (e) => {
    const startTimeD = e.currentTarget.value;
    this.setState({ startTimeD });
  };
  handleStartTimeE = (e) => {
    const startTimeE = e.currentTarget.value;
    this.setState({ startTimeE });
  };
  handleStartTimeF = (e) => {
    const startTimeF = e.currentTarget.value;
    this.setState({ startTimeF });
  };
  handleStartTimeG = (e) => {
    const startTimeG = e.currentTarget.value;
    this.setState({ startTimeG });
  };
  handleStartTimeH = (e) => {
    const startTimeH = e.currentTarget.value;
    this.setState({ startTimeH });
  };
  handleEndTimeA = (e) => {
    const endTimeA = e.currentTarget.value;
    this.setState({ endTimeA });
  };

  handleOffTimeOptionA = (e) => {
    const OffTimeOptionA = e.currentTarget.value;
    this.setState({ OffTimeOptionA });
  };
  handleOffTimeOptionB = (e) => {
    const OffTimeOptionB = e.currentTarget.value;
    this.setState({ OffTimeOptionB });
  };
  handleOffTimeOptionC = (e) => {
    const OffTimeOptionC = e.currentTarget.value;
    this.setState({ OffTimeOptionC });
  };
  handleOffTimeOptionD = (e) => {
    const OffTimeOptionD = e.currentTarget.value;
    this.setState({ OffTimeOptionD });
  };
  handleOffTimeOptionE = (e) => {
    const OffTimeOptionE = e.currentTarget.value;
    this.setState({ OffTimeOptionE });
  };
  handleOffTimeOptionF = (e) => {
    const OffTimeOptionF = e.currentTarget.value;
    this.setState({ OffTimeOptionF });
  };

  handleEndTimeB = (e) => {
    const endTimeB = e.currentTarget.value;
    this.setState({ endTimeB });
  };
  handleEndTimeC = (e) => {
    const endTimeC = e.currentTarget.value;
    this.setState({ endTimeC });
  };
  handleEndTimeD = (e) => {
    const endTimeD = e.currentTarget.value;
    this.setState({ endTimeD });
  };
  handleEndTimeE = (e) => {
    const endTimeE = e.currentTarget.value;
    this.setState({ endTimeE });
  };
  handleEndTimeF = (e) => {
    const endTimeF = e.currentTarget.value;
    this.setState({ endTimeF });
  };
  handleEndTimeG = (e) => {
    const endTimeG = e.currentTarget.value;
    this.setState({ endTimeG });
  };
  handleEndTimeH = (e) => {
    const endTimeH = e.currentTarget.value;
    this.setState({ endTimeH });
  };

  handleLowTempA = (e) => {
    const lowTempA = e.currentTarget.value;
    this.setState({ lowTempA });
  };
  handleLowTempB = (e) => {
    const lowTempB = e.currentTarget.value;
    this.setState({ lowTempB });
  };
  handleLowTempC = (e) => {
    const lowTempC = e.currentTarget.value;
    this.setState({ lowTempC });
  };
  handleLowTempD = (e) => {
    const lowTempD = e.currentTarget.value;
    this.setState({ lowTempD });
  };
  handleLowTempE = (e) => {
    const lowTempE = e.currentTarget.value;
    this.setState({ lowTempE });
  };
  handleLowTempF = (e) => {
    const lowTempF = e.currentTarget.value;
    this.setState({ lowTempF });
  };
  handleHiTempA = (e) => {
    const hiTempA = e.currentTarget.value;
    this.setState({ hiTempA });
  };
  handleHiTempB = (e) => {
    const hiTempB = e.currentTarget.value;
    this.setState({ hiTempB });
  };
  handleHiTempC = (e) => {
    const hiTempC = e.currentTarget.value;
    this.setState({ hiTempC });
  };
  handleHiTempD = (e) => {
    const hiTempD = e.currentTarget.value;
    this.setState({ hiTempD });
  };
  handleHiTempE = (e) => {
    const hiTempE = e.currentTarget.value;
    this.setState({ hiTempE });
  };
  handleHiTempF = (e) => {
    const hiTempF = e.currentTarget.value;
    this.setState({ hiTempF });
  };

  handleOverRideMins = (e) => {
    const overRideMins = e.currentTarget.value;
    this.setState({ overRideMins });
  };

  handleReturnRoomDelta = (e) => {
    const returnRoomDelta = e.currentTarget.value;
    this.setState({ returnRoomDelta });
  };

  handleSecondStageDelta = (e) => {
    const secondStageDelta = e.currentTarget.value;
    this.setState({ secondStageDelta });
  };
  handleAutoHeatCool = (e) => {
    const autoHeatCool = e.currentTarget.value;
    this.setState({ autoHeatCool });
  };
  handleCoolingHyst = (e) => {
    const coolingHyst = e.currentTarget.value;
    this.setState({ coolingHyst });
  };
  handleMaxDmdIncrease = (e) => {
    const maxDmdIncrease = e.currentTarget.value;
    this.setState({ maxDmdIncrease });
  };

  handleHeatingHyst = (e) => {
    const heatingHyst = e.currentTarget.value;
    this.setState({ heatingHyst });
  };
  handleLightsOn = (e) => {
    const lightsOn = e.currentTarget.value;
    this.setState({ lightsOn });
  };
  handleLightsOff = (e) => {
    const lightsOff = e.currentTarget.value;
    this.setState({ lightsOff });
  };

  handleSunTimeOffset = (e) => {
    const sunTimeOffset = e.currentTarget.value;
    this.setState({ sunTimeOffset });
  };

  handleMinORT = (e) => {
    const minORTemp = e.currentTarget.value;
    this.setState({ minORTemp });
  };
  handleMaxORT = (e) => {
    const maxORTemp = e.currentTarget.value;
    this.setState({ maxORTemp });
  };

  handleHolDate1 = (e) => {
    const holDate1 = e.currentTarget.value;
    let holString1 = "";
    const holStart1 = ""; //this.state.holStart1;
    const holStop1 = ""; //this.state.holStop1;
    holString1 = holString1.concat(holDate1, " ", holStart1, " ", holStop1);
    console.log("Holiday 1 String:", holString1);
    this.setState({ holDate1, holStart1, holStop1, holString1 });
  };
  handleHolStart1 = (e) => {
    const holStart1 = e.currentTarget.value;
    let holString1 = "";
    const holDate1 = this.state.holDate1;
    const holStop1 = this.state.holStop1;
    holString1 = holString1.concat(holDate1, " ", holStart1, " ", holStop1);
    console.log("Holiday 1 String:", holString1);
    this.setState({ holStart1, holString1 });
  };
  handleHolStop1 = (e) => {
    const holStop1 = e.currentTarget.value;
    let holString1 = "";
    const holStart1 = this.state.holStart1;
    const holDate1 = this.state.holDate1;
    holString1 = holString1.concat(holDate1, " ", holStart1, " ", holStop1);
    console.log("Holiday 1 String:", holString1);
    this.setState({ holStop1, holString1 });
  };
  handleAddHoliday = async (e) => {
    e.preventDefault();
    let holString1 = this.state.holString1;
    console.log("holString1:", holString1);
    let systemHolidays = this.state.systemHolidays;
    console.log("System Holidays before:", systemHolidays);
    systemHolidays.push(holString1);
    const systemHolidaysLength = systemHolidays.length;
    console.log("System Holidays After:", systemHolidays);

    const holStart1 = undefined;
    const holStop1 = undefined;
    const holDate1 = undefined;
    holString1 = undefined;
    this.setState({
      systemHolidays,
      systemHolidaysLength,
      holStart1,
      holStop1,
      holDate1,
      holString1,
    });
  };

  handleDeleteHoliday = async (e, index) => {
    e.preventDefault();
    let systemHolidays = this.state.systemHolidays;
    systemHolidays = systemHolidays.toSpliced(index, 1);
    const systemHolidaysLength = systemHolidays.length;
    this.setState({ systemHolidays, systemHolidaysLength });
  };

  handleEarlyStart = (e) => {
    const earlyStartFactor = e.currentTarget.value;
    this.setState({ earlyStartFactor });
  };
  handleMaxEarly = (e) => {
    const maxEarly = e.currentTarget.value;
    this.setState({ maxEarly });
  };
  handleMinEarlyDT = (e) => {
    const minEarlyDT = e.currentTarget.value;
    this.setState({ minEarlyDT });
  };

  handleEarlyStopOutsideDeltaT = (e) => {
    const earlyStopOutsideDeltaT = e.currentTarget.value;
    this.setState({ earlyStopOutsideDeltaT });
  };
  handleEarlyStopRoomDeltaT = (e) => {
    const earlyStopRoomDeltaT = e.currentTarget.value;
    this.setState({ earlyStopRoomDeltaT });
  };
  handleEarlyStopMins = (e) => {
    const earlyStopMins = e.currentTarget.value;
    this.setState({ earlyStopMins });
  };

  render() {
    /*const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;*/
    return (
      <React.Fragment>
        <div
          style={{
            width: "360px",
            height: "3000px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "120px",
            marginBottom: "250px",
            zIndex: 1,
          }}
        >
          <p
            className="basicParagraph"
            style={{
              fontSize: "12px",
              color: "#777575",
              textAlign: "center",
            }}
          >
            {this.state.systemName}
          </p>
          <p></p>
          <p
            className="basicParagraph"
            style={{
              width: "360px",
              fontSize: "12px",
              color: "#777575",
              textAlign: "center",
            }}
          >
            Define Time Ranges or Business Hours
          </p>
          <form
            onSubmit={this.handleNodeLimits}
            style={{ marginBottom: "15px" }}
          >
            <table
              className="table"
              style={{
                marginTop: "20px",
                width: "360px",
                transform: "translateX(0px)",
              }}
            >
              <th>Range</th>
              <th>Start Time</th>
              <th>Stop Time</th>
              <th>Off</th>
              <tr>
                <td
                  style={{
                    float: "center",
                    height: "30px",
                    width: "50px",
                    paddingTop: "20px",
                    fontSize: "12px",
                  }}
                >
                  A
                </td>
                <td>
                  <input
                    value={this.state.startTimeA}
                    onChange={this.handleStartTimeA}
                    type="time"
                    className="form-control2"
                    style={{
                      float: "center",
                      height: "30px",
                      width: "100px",
                    }}
                  ></input>
                </td>
                <td>
                  <input
                    value={this.state.endTimeA}
                    onChange={this.handleEndTimeA}
                    type="time"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
                <td
                  style={{
                    float: "center",
                    height: "30px",
                    width: "50px",
                    padding: "0px",
                    margin: "0px",
                    paddingTop: "11px",
                    fontSize: "10px",
                  }}
                >
                  <select
                    className="form-control2"
                    onChange={this.handleOffTimeOptionA}
                    value={this.state.OffTimeOptionA}
                    style={{
                      float: "right",
                      fontSize: "10px",
                      fontWeight: "bold",
                      padding: "0px",
                      width: "40px",
                    }}
                  >
                    <option value="N">N</option>
                    <option value="G">G</option>
                    <option value="H">H </option>
                  </select>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    float: "center",
                    height: "30px",
                    width: "50px",
                    paddingTop: "20px",
                    fontSize: "12px",
                  }}
                >
                  B
                </td>
                <td>
                  <input
                    value={this.state.startTimeB}
                    onChange={this.handleStartTimeB}
                    type="time"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
                <td>
                  <input
                    value={this.state.endTimeB}
                    onChange={this.handleEndTimeB}
                    type="time"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
                <td
                  style={{
                    float: "center",
                    height: "30px",
                    width: "50px",
                    padding: "0px",
                    margin: "0px",
                    paddingTop: "11px",
                    fontSize: "10px",
                  }}
                >
                  <select
                    className="form-control2"
                    onChange={this.handleOffTimeOptionB}
                    value={this.state.OffTimeOptionB}
                    style={{
                      float: "right",
                      fontSize: "10px",
                      fontWeight: "bold",
                      padding: "0px",
                      width: "40px",
                    }}
                  >
                    <option value="N">N</option>
                    <option value="G">G</option>
                    <option value="H">H </option>
                  </select>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    float: "center",
                    height: "30px",
                    width: "50px",
                    paddingTop: "20px",
                    fontSize: "12px",
                  }}
                >
                  C{" "}
                </td>
                <td>
                  <input
                    value={this.state.startTimeC}
                    onChange={this.handleStartTimeC}
                    type="time"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
                <td>
                  <input
                    value={this.state.endTimeC}
                    onChange={this.handleEndTimeC}
                    type="time"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
                <td
                  style={{
                    float: "center",
                    height: "30px",
                    width: "50px",
                    padding: "0px",
                    margin: "0px",
                    paddingTop: "11px",
                    fontSize: "10px",
                  }}
                >
                  <select
                    className="form-control2"
                    onChange={this.handleOffTimeOptionC}
                    value={this.state.OffTimeOptionC}
                    style={{
                      float: "right",
                      fontSize: "10px",
                      fontWeight: "bold",
                      padding: "0px",
                      width: "40px",
                    }}
                  >
                    <option value="N">N</option>
                    <option value="G">G</option>
                    <option value="H">H </option>
                  </select>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    float: "center",
                    height: "30px",
                    width: "50px",
                    paddingTop: "20px",
                    fontSize: "12px",
                  }}
                >
                  D{" "}
                </td>
                <td>
                  <input
                    value={this.state.startTimeD}
                    onChange={this.handleStartTimeD}
                    type="time"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
                <td>
                  <input
                    value={this.state.endTimeD}
                    onChange={this.handleEndTimeD}
                    type="time"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
                <td
                  style={{
                    float: "center",
                    height: "30px",
                    width: "50px",
                    padding: "0px",
                    margin: "0px",
                    paddingTop: "11px",
                    fontSize: "10px",
                  }}
                >
                  <select
                    className="form-control2"
                    onChange={this.handleOffTimeOptionD}
                    value={this.state.OffTimeOptionD}
                    style={{
                      float: "right",
                      fontSize: "10px",
                      fontWeight: "bold",
                      padding: "0px",
                      width: "40px",
                    }}
                  >
                    <option value="N">N</option>
                    <option value="G">G</option>
                    <option value="H">H </option>
                  </select>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    float: "center",
                    height: "30px",
                    width: "50px",
                    paddingTop: "20px",
                    fontSize: "12px",
                  }}
                >
                  E{" "}
                </td>
                <td>
                  <input
                    value={this.state.startTimeE}
                    onChange={this.handleStartTimeE}
                    type="time"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
                <td>
                  <input
                    value={this.state.endTimeE}
                    onChange={this.handleEndTimeE}
                    type="time"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
                <td
                  style={{
                    float: "center",
                    height: "30px",
                    width: "50px",
                    padding: "0px",
                    margin: "0px",
                    paddingTop: "11px",
                    fontSize: "10px",
                  }}
                >
                  <select
                    className="form-control2"
                    onChange={this.handleOffTimeOptionE}
                    value={this.state.OffTimeOptionE}
                    style={{
                      float: "right",
                      fontSize: "10px",
                      fontWeight: "bold",
                      padding: "0px",
                      width: "40px",
                    }}
                  >
                    <option value="N">N</option>
                    <option value="G">G</option>
                    <option value="H">H </option>
                  </select>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    float: "center",
                    height: "30px",
                    width: "50px",
                    paddingTop: "20px",
                    fontSize: "12px",
                  }}
                >
                  F{" "}
                </td>
                <td>
                  <input
                    value={this.state.startTimeF}
                    onChange={this.handleStartTimeF}
                    type="time"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
                <td>
                  <input
                    value={this.state.endTimeF}
                    onChange={this.handleEndTimeF}
                    type="time"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
                <td
                  style={{
                    float: "center",
                    height: "30px",
                    width: "50px",
                    padding: "0px",
                    margin: "0px",
                    paddingTop: "11px",
                    fontSize: "10px",
                  }}
                >
                  <select
                    className="form-control2"
                    onChange={this.handleOffTimeOptionF}
                    value={this.state.OffTimeOptionF}
                    style={{
                      float: "right",
                      fontSize: "10px",
                      fontWeight: "bold",
                      padding: "0px",
                      width: "40px",
                    }}
                  >
                    <option value="N">N</option>
                    <option value="G">G</option>
                    <option value="H">H </option>
                  </select>
                </td>
              </tr>
            </table>
            <hr></hr>
            <p
              className="basicParagraph"
              style={{
                width: "360px",
                fontSize: "12px",
                color: "red",
                textAlign: "center",
              }}
            >
              Note: G and H Time Ranges are only used for OFF times
            </p>
            <table
              className="table"
              style={{
                marginTop: "20px",
                width: "310px",
                transform: "translateX(0px)",
              }}
            >
              <tr>
                <td
                  style={{
                    float: "center",
                    height: "30px",
                    width: "50px",
                    paddingTop: "20px",
                    fontSize: "12px",
                    color: "red",
                  }}
                >
                  G
                </td>
                <td>
                  <input
                    value={this.state.startTimeG}
                    onChange={this.handleStartTimeG}
                    type="time"
                    className="form-control2"
                    style={{
                      float: "center",
                      height: "30px",
                      width: "100px",
                      borderColor: "red",
                    }}
                  ></input>
                </td>
                <td>
                  <input
                    value={this.state.endTimeG}
                    onChange={this.handleEndTimeG}
                    type="time"
                    className="form-control2"
                    style={{
                      float: "center",
                      height: "30px",
                      width: "100px",
                      borderColor: "red",
                    }}
                  ></input>
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    float: "center",
                    height: "30px",
                    width: "50px",
                    paddingTop: "20px",
                    fontSize: "12px",
                    color: "red",
                  }}
                >
                  H
                </td>
                <td>
                  <input
                    value={this.state.startTimeH}
                    onChange={this.handleStartTimeH}
                    type="time"
                    className="form-control2"
                    style={{
                      float: "center",
                      height: "30px",
                      width: "100px",
                      borderColor: "red",
                    }}
                  ></input>
                </td>
                <td>
                  <input
                    value={this.state.endTimeH}
                    onChange={this.handleEndTimeH}
                    type="time"
                    className="form-control2"
                    style={{
                      float: "center",
                      height: "30px",
                      width: "100px",
                      borderColor: "red",
                    }}
                  ></input>
                </td>
              </tr>
            </table>

            <hr></hr>
            <p
              className="basicParagraph"
              style={{
                width: "350px",
                fontSize: "12px",
                color: "#777575",
                textAlign: "center",
              }}
            >
              Specify Override Duration in Minutes:
            </p>
            <input
              value={this.state.overRideMins}
              onChange={this.handleOverRideMins}
              type="text"
              className="form-control2"
              style={{
                float: "center",
                height: "30px",
                width: "50px",
                transform: "translate(150px, 0px)",
              }}
            ></input>
            <hr></hr>

            <p
              className="basicParagraph"
              style={{
                width: "350px",
                fontSize: "12px",
                color: "#777575",
                textAlign: "center",
              }}
            >
              Specify Thermostat Parameters:
            </p>
            <label
              className="basicParagraph"
              style={{ height: "30px", width: "150px" }}
            >
              Max Delta Temp. for Second Stage (&#176;F):
            </label>
            <input
              value={this.state.secondStageDelta}
              onChange={this.handleSecondStageDelta}
              id="serialNumber"
              type="text"
              className="form-control2"
              style={{
                float: "right",
                height: "30px",
                width: "200px",
                marginLeft: "10px",
                paddingLeft: "10px",
              }}
            />
            <label
              className="basicParagraph"
              style={{ height: "30px", width: "150px" }}
            >
              Max Delta Temp. for Auto Heat/Cool (&#176;F):
            </label>
            <input
              value={this.state.autoHeatCool}
              onChange={this.handleAutoHeatCool}
              id="serialNumber"
              type="text"
              className="form-control2"
              style={{
                float: "right",
                height: "30px",
                width: "200px",
                marginLeft: "10px",
                paddingLeft: "10px",
              }}
            />
            <label
              className="basicParagraph"
              style={{ height: "30px", width: "150px" }}
            >
              Heating Hysteresis (&#176;F):
            </label>
            <input
              value={this.state.heatingHyst}
              onChange={this.handleHeatingHyst}
              id="serialNumber"
              type="text"
              className="form-control2"
              style={{
                float: "right",
                height: "30px",
                width: "200px",
                marginLeft: "10px",
                paddingLeft: "10px",
              }}
            />
            <label
              className="basicParagraph"
              style={{ height: "30px", width: "150px" }}
            >
              Cooling Hysteresis (&#176;F):
            </label>
            <input
              value={this.state.coolingHyst}
              onChange={this.handleCoolingHyst}
              id="serialNumber"
              type="text"
              className="form-control2"
              style={{
                float: "right",
                height: "30px",
                width: "200px",
                marginLeft: "10px",
                paddingLeft: "10px",
              }}
            />
            <hr></hr>
            <label
              className="basicParagraph"
              style={{ height: "30px", width: "150px" }}
            >
              Min. Override Temp (&#176;F):
            </label>
            <input
              value={this.state.minORTemp}
              onChange={this.handleMinORT}
              id="serialNumber"
              type="text"
              className="form-control2"
              style={{
                float: "right",
                height: "30px",
                width: "200px",
                marginLeft: "10px",
                paddingLeft: "10px",
              }}
            />
            <label
              className="basicParagraph"
              style={{ height: "30px", width: "150px" }}
            >
              Max. Override Temp (&#176;F):
            </label>
            <input
              value={this.state.maxORTemp}
              onChange={this.handleMaxORT}
              id="serialNumber"
              type="text"
              className="form-control2"
              style={{
                float: "right",
                height: "30px",
                width: "200px",
                marginLeft: "10px",
                paddingLeft: "10px",
              }}
            />
            <hr></hr>
            <p
              className="basicParagraph"
              style={{
                width: "350px",
                fontSize: "12px",
                color: "#777575",
                textAlign: "center",
              }}
            >
              Optimal Start Time Thermostat Parameters:
            </p>
            <label
              className="basicParagraph"
              style={{ height: "30px", width: "200px" }}
            >
              Optimal Early Start (mins/&#176;F):
            </label>
            <input
              value={this.state.earlyStartFactor}
              onChange={this.handleEarlyStart}
              id="serialNumber"
              type="text"
              className="form-control2"
              style={{
                float: "right",
                height: "30px",
                width: "150px",
                marginLeft: "10px",
                paddingLeft: "10px",
              }}
            />
            <label
              className="basicParagraph"
              style={{ height: "30px", width: "200px" }}
            >
              Max Early Start (mins):
            </label>
            <select
              className="form-control2"
              onChange={this.handleMaxEarly}
              value={this.state.maxEarly}
              style={{
                float: "right",
                height: "30px",
                width: "150px",
                marginLeft: "10px",
                paddingLeft: "10px",
              }}
            >
              <option value="Choose Here">0</option>
              <option value="30">30 mins</option>
              <option value="60">One hour</option>
              <option value="90">1 1/2 hr</option>
              <option value="120">2 hrs</option>
            </select>
            <label
              className="basicParagraph"
              style={{ height: "30px", width: "200px" }}
            >
              Min Early Start Delta Temp. (&#176;F):
            </label>
            <select
              className="form-control2"
              onChange={this.handleMinEarlyDT}
              value={this.state.minEarlyDT}
              style={{
                float: "right",
                height: "30px",
                width: "150px",
                marginLeft: "10px",
                paddingLeft: "10px",
              }}
            >
              <option value="Choose Here">0</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="5">5</option>
              <option value="10">10</option>
            </select>
            <hr></hr>
            {/****************************************************************************************** */}
            <p
              className="basicParagraph"
              style={{
                width: "350px",
                fontSize: "12px",
                color: "#777575",
                textAlign: "center",
              }}
            >
              Optimal Stop Time Thermostat Parameters:
            </p>
            <label
              className="basicParagraph"
              style={{ height: "30px", width: "200px" }}
            >
              Outside and Setting Delta (&#177;&#176;F):
            </label>
            <select
              className="form-control2"
              onChange={this.handleEarlyStopOutsideDeltaT}
              value={this.state.earlyStopOutsideDeltaT}
              style={{
                float: "right",
                height: "30px",
                width: "150px",
                marginLeft: "10px",
                paddingLeft: "10px",
              }}
            >
              <option value="3">3</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </select>

            <label
              className="basicParagraph"
              style={{ height: "30px", width: "200px" }}
            >
              Room and Setting Delta (&#177;&#176;F):
            </label>
            <select
              className="form-control2"
              onChange={this.handleEarlyStopRoomDeltaT}
              value={this.state.earlyStopRoomDeltaT}
              style={{
                float: "right",
                height: "30px",
                width: "150px",
                marginLeft: "10px",
                paddingLeft: "10px",
              }}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
            <label
              className="basicParagraph"
              style={{ height: "30px", width: "200px" }}
            >
              Turn Off HVAC Early (mins):
            </label>
            <select
              className="form-control2"
              onChange={this.handleEarlyStopMins}
              value={this.state.earlyStopMins}
              style={{
                float: "right",
                height: "30px",
                width: "150px",
                marginLeft: "10px",
                paddingLeft: "10px",
              }}
            >
              <option value="15">15</option>
              <option value="30">30</option>
              <option value="45">45</option>
              <option value="60">60</option>
            </select>
            <hr></hr>
            {/****************************************************************************************** */}

            <p
              className="basicParagraph"
              style={{
                width: "350px",
                fontSize: "12px",
                color: "#777575",
                textAlign: "center",
              }}
            >
              Specify Light Intensity Thresholds:
            </p>
            <label
              className="basicParagraph"
              style={{ height: "30px", width: "150px" }}
            >
              Turn Lights On When Lux Dips Below:
            </label>
            <input
              value={this.state.lightsOn}
              onChange={this.handleLightsOn}
              id="serialNumber"
              type="text"
              className="form-control2"
              style={{
                float: "right",
                height: "30px",
                width: "200px",
                marginLeft: "10px",
                paddingLeft: "10px",
              }}
            />
            <label
              className="basicParagraph"
              style={{ height: "30px", width: "150px" }}
            >
              Turn Lights Off When Lux Reaches Above:
            </label>
            <input
              value={this.state.lightsOff}
              onChange={this.handleLightsOff}
              id="serialNumber"
              type="text"
              className="form-control2"
              style={{
                float: "right",
                height: "30px",
                width: "200px",
                marginLeft: "10px",
                paddingLeft: "10px",
              }}
            />
            <label
              className="basicParagraph"
              style={{ height: "30px", width: "150px" }}
            >
              Ahead of Sunset and Delay after Sunrise (mins.):
            </label>
            <input
              value={this.state.sunTimeOffset}
              onChange={this.handleSunTimeOffset}
              id="serialNumber"
              type="text"
              className="form-control2"
              style={{
                float: "right",
                height: "30px",
                width: "200px",
                marginLeft: "10px",
                paddingLeft: "10px",
              }}
            />
            {/******************************************************************************** */}
            <hr></hr>
            <p
              className="basicParagraph"
              style={{
                width: "360px",
                fontSize: "12px",
                color: "#777575",
                textAlign: "center",
              }}
            >
              Demand Shedding Parameters
            </p>
            <label
              className="basicParagraph"
              style={{ height: "30px", width: "250px" }}
            >
              %Max Demand Prior to Increasing it:
            </label>
            <input
              value={this.state.maxDmdIncrease}
              onChange={this.handleMaxDmdIncrease}
              id="serialNumber"
              type="text"
              className="form-control2"
              style={{
                float: "right",
                height: "30px",
                width: "100px",
                marginLeft: "10px",
                paddingLeft: "10px",
              }}
            />

            <p
              className="basicParagraph"
              style={{
                width: "360px",
                fontSize: "12px",
                color: "#777575",
                textAlign: "center",
                marginTop: "20px",
              }}
            >
              Demand Shedding Temperature Ranges
            </p>
            <table
              className="table"
              style={{
                marginTop: "20px",
                width: "360px",
                transform: "translateX(0px)",
              }}
            >
              <th>Range</th>
              <th>Lowest Temp. (F)</th>
              <th>Highest Temp. (F)</th>
              <tr>
                <td
                  style={{
                    float: "center",
                    height: "30px",
                    width: "50px",
                    paddingTop: "20px",
                    fontSize: "12px",
                  }}
                >
                  A
                </td>
                <td>
                  <input
                    value={this.state.lowTempA}
                    onChange={this.handleLowTempA}
                    type="text"
                    className="form-control2"
                    style={{
                      float: "center",
                      height: "30px",
                      width: "100px",
                    }}
                  ></input>
                </td>
                <td>
                  <input
                    value={this.state.hiTempA}
                    onChange={this.handleHiTempA}
                    type="text"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    float: "center",
                    height: "30px",
                    width: "50px",
                    paddingTop: "20px",
                    fontSize: "12px",
                  }}
                >
                  B
                </td>
                <td>
                  <input
                    value={this.state.lowTempB}
                    onChange={this.handleLowTempB}
                    type="text"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
                <td>
                  <input
                    value={this.state.hiTempB}
                    onChange={this.handleHiTempB}
                    type="text"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    float: "center",
                    height: "30px",
                    width: "50px",
                    paddingTop: "20px",
                    fontSize: "12px",
                  }}
                >
                  C{" "}
                </td>
                <td>
                  <input
                    value={this.state.lowTempC}
                    onChange={this.handleLowTempC}
                    type="text"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
                <td>
                  <input
                    value={this.state.hiTempC}
                    onChange={this.handleHiTempC}
                    type="text"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    float: "center",
                    height: "30px",
                    width: "50px",
                    paddingTop: "20px",
                    fontSize: "12px",
                  }}
                >
                  D{" "}
                </td>
                <td>
                  <input
                    value={this.state.lowTempD}
                    onChange={this.handleLowTempD}
                    type="text"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
                <td>
                  <input
                    value={this.state.hiTempD}
                    onChange={this.handleHiTempD}
                    type="text"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    float: "center",
                    height: "30px",
                    width: "50px",
                    paddingTop: "20px",
                    fontSize: "12px",
                  }}
                >
                  E{" "}
                </td>
                <td>
                  <input
                    value={this.state.lowTempE}
                    onChange={this.handleLowTempE}
                    type="text"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
                <td>
                  <input
                    value={this.state.hiTempE}
                    onChange={this.handleHiTempE}
                    type="text"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    float: "center",
                    height: "30px",
                    width: "50px",
                    paddingTop: "20px",
                    fontSize: "12px",
                  }}
                >
                  F{" "}
                </td>
                <td>
                  <input
                    value={this.state.lowTempF}
                    onChange={this.handleLowTempF}
                    type="text"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
                <td>
                  <input
                    value={this.state.hiTempF}
                    onChange={this.handleHiTempF}
                    type="text"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
              </tr>
            </table>
            <hr></hr>

            <p
              className="basicParagraph"
              style={{
                width: "360px",
                fontSize: "12px",
                color: "#777575",
                textAlign: "center",
              }}
            >
              Specify Holidays
            </p>
            <table
              className="table"
              style={{
                marginTop: "20px",
                width: "360px",
                transform: "translateX(0px)",
              }}
            >
              <th>Date</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th></th>

              <tr>
                <td>
                  <input
                    value={this.state.holDate1}
                    onChange={this.handleHolDate1}
                    type="date"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
                <td>
                  <input
                    value={this.state.holStart1}
                    onChange={this.handleHolStart1}
                    type="time"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
                <td>
                  <input
                    value={this.state.holStop1}
                    onChange={this.handleHolStop1}
                    type="time"
                    className="form-control2"
                    style={{ float: "center", height: "30px", width: "100px" }}
                  ></input>
                </td>
                {this.state.holStart1 !== undefined &&
                  this.state.holStart1 !== "" &&
                  this.state.holStop1 !== undefined &&
                  this.state.holStop1 !== "" &&
                  this.state.holDate1 !== undefined && (
                    <td>
                      <button
                        className="btn btn-primary shadow-none"
                        style={{
                          width: "35px",
                          padding: "0px",
                          borderWidth: "0px",
                          color: "transparent",
                          backgroundColor: "transparent",
                        }}
                        onClick={this.handleAddHoliday}
                      >
                        <FontAwesomeIcon
                          icon="plus-circle"
                          size="1x"
                          color="#ffc000"
                          title="Add a New Node"
                        />
                      </button>
                    </td>
                  )}
              </tr>
            </table>
            {this.state.systemHolidaysLength > 0 && (
              <div
                style={{
                  marginTop: "10px",
                  width: "360px",
                }}
              >
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "12px",
                    textAlign: "center",
                    marginBottom: "5px",
                  }}
                >
                  Scheduled Holidays
                </p>
                <hr></hr>
                {this.state.systemHolidays.map((holiday, index) => (
                  <tr
                    key={holiday.id}
                    style={{ transform: "translateX(40px)" }}
                  >
                    {/*<td>{index}</td>*/}
                    <td
                      className="basicParagraph"
                      style={{
                        margin: "0px",
                        padding: "0px",
                        width: "100px",
                        height: "15px",
                      }}
                    >
                      {holiday.split(" ").slice(0, 1)}{" "}
                    </td>
                    <td
                      className="basicParagraph"
                      style={{
                        margin: "0px",
                        paddingLeft: "30px",
                        width: "100px",
                        height: "15px",
                      }}
                    >
                      {holiday.split(" ").slice(1, 2)}
                    </td>
                    <td
                      className="basicParagraph"
                      style={{
                        margin: "20px",
                        paddingLeft: "50px",
                        width: "100px",
                        height: "15px",
                      }}
                    >
                      {holiday.split(" ").slice(2, 3)}
                    </td>
                    <td>
                      <button
                        className="btn btn-primary shadow-none"
                        style={{
                          width: "40px",
                          padding: "0px",
                          borderWidth: "0px",
                          marginLeft: "15px",
                          marginTop: "0px",
                          color: "transparent",
                          backgroundColor: "transparent",
                        }}
                        onClick={(e) => this.handleDeleteHoliday(e, index)}
                      >
                        <FontAwesomeIcon
                          icon="trash-alt"
                          size="sm"
                          color="red"
                          title="Delete Holiday"
                        />
                      </button>
                    </td>
                  </tr>
                ))}
                <hr></hr>
              </div>
            )}

            <button
              className="basicButton"
              style={{
                width: "360px",
                transform: "translateX(0px)",
                marginTop: "10px",
              }}
            >
              Save Operating Hours, Temperature Ranges, and Holidays
            </button>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
export default SimplNodesLimits;

class BackUpModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <>
        <button
          className="btn btn-outline-light"
          style={{
            borderWidth: "0px",
            padding: "0px",
            float: "left",
            marginRight: "10px",
            position: "relative",
            left: "10px",
            bottom: "5px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="info-circle"
            size="sm"
            color="dodgerblue"
            title="System Information"
          />
        </button>

        <Modal
          style={{ width: "250px", alignContent: "center" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "12px" }}
            ></Modal.Title>
            Setup Requirements:
          </Modal.Header>

          <Modal.Body>
            {this.props.backUpMode === "2" && (
              <p
                style={{
                  color: "#2d5c88",
                  fontWeight: "bold",
                  fontSize: "9px",
                  textAlign: "left",
                  marginBottom: "1px",
                }}
              >
                When using SimplBox with the SimplBackUp (SBU) option, SimplBox
                Units automatically discover if they are equipped with backup
                capabilities. Output Voltage and other parameters may have to be
                specified.
              </p>
            )}
            {this.props.backUpMode === "3" && (
              <div>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  For an AC-coupled system, a SimplMeter must be attached to the
                  system by specifying its Serial Number above.
                </p>
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Important: All three voltages lines are used. Phase A and
                  Phase B lines on the SimplMeter must be connected to H1 and H2
                  of the AC-coupled backup system. Phase C voltage line must be
                  connected to the H1 of the grid input.
                </p>
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Meter 1 rope CTs must be connected to the output of the
                  external AC-coupled backup system.
                </p>{" "}
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Meter 2 rope CTs must be connected to the output of the solar
                  system feeding into the AC-coupled backup system.
                </p>{" "}
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  All CT arrows must point to the breakers for the solar and
                  AC-coupled systems.
                </p>
              </div>
            )}

            {this.props.backUpMode === "4" && (
              <div>
                {" "}
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  When using SimplBox with the SimplBackUp (SBU) option,
                  SimplBox Units automatically discover if they are equipped
                  with backup capabilities. Output Voltage and other parameters
                  may have to be specified.
                </p>
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  When adding an AC-coupled system, a SimplMeter must be
                  attached to the system by specifying its Serial Number above.
                </p>
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Meter 1 must be connected to the external AC-coupled backup
                  system.
                </p>{" "}
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Meter 2 must be connected to the solar system feeding into the
                  AC-coupled backup system.
                </p>{" "}
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  All SimplCT arrows must point to the breakers for the solar
                  and AC-coupled systems.
                </p>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

class ControlsModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <>
        <button
          className="btn btn-outline-light"
          style={{
            borderWidth: "0px",
            padding: "0px",
            float: "right",
            marginRight: "5px",
            position: "relative",
            right: "0px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="info-circle"
            size="sm"
            color="dodgerblue"
            title="System Information"
          />
        </button>

        <Modal
          style={{ width: "250px", alignContent: "center" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "14px" }}
            ></Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "10px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Description of Control Parameters
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Up to three load controllers may be programmed for each system.
              The Load controller capabilities may be expanded by installing
              additional SimplLoads units.
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "11px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Name Plate Rating:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Nominal power rating for the load in kW.
            </p>
            <hr></hr>

            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "11px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Load Priority: Range: 0 to 4
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              The lower the load priority level the earlier the load will be
              disconnected from power in a power shedding event. 0: Never
              Connected 4: Always Connected
            </p>
            <hr></hr>

            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "11px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Turn Off Threshold
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              The load is qualified for being disconnected once grid power level
              reaches this value in kW.
            </p>
            <hr></hr>

            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "11px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Turn On Threshold
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              The load is not turned back on until power level is below this
              level in kW. Along with Turn On Threshold, these two parameters
              provide a windown or a range in which the load is kept off once it
              is disconnected from the grid.
            </p>
            <hr></hr>

            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "11px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Turn On Duration
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              When a load is turned on, it might take several seconds before its
              power level reaches steady state. Power measurements must be
              ignored in this so-called turn-on stage. Different loads have
              different settling or turn-on duration times. This parameters
              helps the system measure power after it had stabilized.
            </p>
            <hr></hr>

            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "11px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Min Time Off
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Once a load is disconnected, in some cases such as when a
              compressor is involved, it is important the the load stays off a
              min period of time before it is turned back on.
            </p>
            <hr></hr>

            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "11px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Turn Time Before Restart
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              If a load is turned off, this parameters is designed to make sure
              the load stays off for a minimum period of time.
            </p>
            <hr></hr>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
