import React from "react";
import { toast } from "react-toastify";
import { loginUser } from "../services/userService";
///////////////////////////
import { getCompanyUsers } from "../services/companyService";
import { setJwt } from "../services/httpService";
import { getUser, googleLogin } from "../services/userService";
/////////////////////////////

import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Form from "./common/form";
import GoogleLogin from "./googleLogin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import {
  masterCompany,
  footerLogoSource,
  manufacturingCompany,
} from "../config.json";
import BackGroundRectangle from "./backgroundRect";

const linkStyle = {
  margin: "0px 0px 8px",
  textDecoration: "none",
  font: "10.5px arial",
}

class LoginForm extends Form {
  state = {
    data: { username: "", password: "" },
    shownPass: false,
    errors: {},
  };

  schema = {
    username: Joi.string().required().label("Username"),
    password: Joi.string().required().min(8).label("Password"),
  };

  hideOrShowPass = (event) => {
    event.preventDefault();
    const element = document.getElementById("password");

    if (this.state.shownPass){
      this.setState({shownPass: false})
      element.type = "password"
    } else {
      this.setState({shownPass: true})
      element.type = "text"
    }
  }

  doSubmit = async (e) => {
    try {
      const { data } = this.state;
      const verified = false;
      const logindata = await loginUser(data.username, data.password);
      console.log("logindata", logindata);
      const token = logindata.data.token;
      console.log("User Token", token);
      localStorage.setItem("token", token);
      localStorage.setItem("verified", verified);
      localStorage.setItem("userValidated", "valid");
      console.log("Got the user");

      localStorage.setItem("systemMapButton", "noButton");
      console.log(
        "System Button in Login",
        localStorage.getItem("systemMapButton")
      );
      window.location = "/";
    } catch (ex) {
      /* if (ex.response && ex.response.status === 401) {*/
      console.log("Login not successfull...Assume that we are offline...");
      const userValidation = localStorage.getItem("userValidated");
      if (userValidation === "valid")
        console.log("Switching to offline mode...");
      else console.log("Invalid Credentials...");
      /* }*/
    }
  };

  render() {
    console.log("username", this.state.data.username);
    return (
      <React.Fragment>
        <div
          style={{
            width: "400px",
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1,
          }}
        >
          <div>
            <form onSubmit={this.handleSubmit}>
              {this.renderInput("username", "Username")}
              <div className="d-flex justify-content-end">
                <button
                  className="position-absolute mr-4 mt-1" style={{
                    padding: "0px",
                    borderWidth: "0px",
                    color: "transparent",
                    backgroundColor: "transparent",
                    border: "none",
                    outline: "none"
                  }}
                  onClick={event=>this.hideOrShowPass(event)}
                >
                <FontAwesomeIcon
                  className="pr-1"
                  icon={this.state.shownPass ? faEye : faEyeSlash}
                  size="lg"
                  color="#2d5c88"
                  title={this.state.shownPass ? "Hide Password" : "Show Password"}
                />
                </button>
              </div>
              {this.renderInput("password", "Password", "password")}
              <Link
                id="forgot_password"
                className="text-danger"
                to="/reset"
                style={linkStyle}>Forgot Password or Username?</Link> <br /> <br />

              <div style={{
                display: "flex",
                flexFlow: "column wrap",
                alignItems: "center"
              }}>
                {this.renderButton("Login")}
                <Link
                  to="/users/new"
                  className="btn btn-outline-warning btn-sm"
                  style={{ width: "220px", marginTop: "10px" }}
                >
                  Register
                </Link>
                <GoogleLogin />
              </div>
            </form>
          </div>

        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default LoginForm;
