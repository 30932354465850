import React from "react";

const OvenSvg = (props) => (
  <svg
    style={{ margin: "0px", padding: "0px", height: "90px", width: "200px" }}
  >
    <text
      x="6"
      y="14"
      font-family="Verdana"
      font-size="10"
      font-weight="normal"
      fill={props.textColor}
    >
      {props.name}
    </text>
    <svg
      width="50"
      height="auto"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title />
      <g id="Grid">
        <path
          stroke={props.strokeColor2}
          fill={props.strokeColor2}
          d="M86.12,115.77H39.55a10.51,10.51,0,0,1-10.5-10.5V54a10.51,10.51,0,0,1,10.5-10.5H86.12A10.51,10.51,0,0,1,96.62,54v51.23A10.51,10.51,0,0,1,86.12,115.77ZM39.55,46.54a7.51,7.51,0,0,0-7.5,7.5v51.23a7.5,7.5,0,0,0,7.5,7.5H86.12a7.51,7.51,0,0,0,7.5-7.5V54a7.52,7.52,0,0,0-7.5-7.5Z"
        />
        <path
          stroke={props.strokeColor2}
          fill={props.strokeColor2}
          d="M80.79,73.17H45.54A10.51,10.51,0,0,1,35,62.67v-3.3a10.51,10.51,0,0,1,10.5-10.5H80.79a10.51,10.51,0,0,1,10.5,10.5v3.3A10.51,10.51,0,0,1,80.79,73.17ZM45.54,51.87a7.5,7.5,0,0,0-7.5,7.5v3.3a7.5,7.5,0,0,0,7.5,7.5H80.79a7.5,7.5,0,0,0,7.5-7.5v-3.3a7.5,7.5,0,0,0-7.5-7.5Z"
        />
        <path
          stroke={props.strokeColor2}
          fill={props.strokeColor2}
          d="M80.79,110.45H45.54A10.51,10.51,0,0,1,35,100V86a10.51,10.51,0,0,1,10.5-10.5H80.79A10.51,10.51,0,0,1,91.29,86v14A10.51,10.51,0,0,1,80.79,110.45ZM45.54,78.5A7.5,7.5,0,0,0,38,86v14a7.51,7.51,0,0,0,7.5,7.5H80.79a7.51,7.51,0,0,0,7.5-7.5V86a7.5,7.5,0,0,0-7.5-7.5Z"
        />
        <path
          stroke={props.strokeColor2}
          fill={props.strokeColor2}
          d="M95.12,41.22H29.88a1.5,1.5,0,1,1,0-3H95.12a1.5,1.5,0,0,1,0,3Z"
        />
        <path
          stroke={props.strokeColor2}
          fill={props.strokeColor2}
          d="M52.51,41.22a1.5,1.5,0,0,1-1.5-1.5V30.57H42v9.15a1.5,1.5,0,0,1-3,0V29.07a1.5,1.5,0,0,1,1.5-1.5h12a1.5,1.5,0,0,1,1.5,1.5V39.72A1.5,1.5,0,0,1,52.51,41.22Z"
        />
        <path
          stroke={props.strokeColor2}
          fill={props.strokeColor2}
          d="M84.47,41.22a1.5,1.5,0,0,1-1.5-1.5V30.57H59.34v9.15a1.5,1.5,0,0,1-3,0V29.07a1.5,1.5,0,0,1,1.5-1.5H84.47a1.5,1.5,0,0,1,1.5,1.5V39.72A1.5,1.5,0,0,1,84.47,41.22Z"
        />
        <path
          stroke={props.strokeColor1}
          fill={props.strokeColor1}
          d="M44.52,26.57A1.5,1.5,0,0,1,43.36,26C41.71,24,41,22.21,41,20.5a5.28,5.28,0,0,1,1.29-3.08A2.32,2.32,0,0,0,43,16a5.65,5.65,0,0,0-1.64-3.46,1.5,1.5,0,1,1,2.31-1.91c1.65,2,2.42,3.81,2.33,5.52a5.28,5.28,0,0,1-1.3,3.08A2.4,2.4,0,0,0,44,20.65a5.72,5.72,0,0,0,1.65,3.47,1.49,1.49,0,0,1-1.16,2.45Z"
        />
        <path
          stroke={props.strokeColor1}
          fill={props.strokeColor1}
          d="M49.86,21.25a1.51,1.51,0,0,1-1-.35,1.49,1.49,0,0,1-.2-2.11,5.75,5.75,0,0,0,1.65-3.46,2.49,2.49,0,0,0-.69-1.42,5.16,5.16,0,0,1-1.3-3.07c-.09-1.72.67-3.52,2.33-5.53A1.5,1.5,0,0,1,53,7.22a5.75,5.75,0,0,0-1.65,3.46A2.46,2.46,0,0,0,52,12.1a5.16,5.16,0,0,1,1.3,3.08c.09,1.71-.67,3.51-2.33,5.52A1.48,1.48,0,0,1,49.86,21.25Z"
        />
        <path
          stroke={props.strokeColor1}
          fill={props.strokeColor1}
          d="M68.49,25.91a1.48,1.48,0,0,1-1-.42c-1.74-1.67-2.54-3.22-2.45-4.73A4.15,4.15,0,0,1,66.41,18c.44-.45.57-.62.58-.83s-.1-1-1.53-2.37a1.5,1.5,0,0,1,2.08-2.17c1.74,1.67,2.54,3.22,2.44,4.73a4.11,4.11,0,0,1-1.41,2.72c-.43.45-.56.62-.57.83s.1,1,1.52,2.37a1.5,1.5,0,0,1-1,2.59Z"
        />
        <path
          stroke={props.strokeColor1}
          fill={props.strokeColor1}
          d="M73.82,20.58a1.48,1.48,0,0,1-.95-.34,1.51,1.51,0,0,1-.21-2.11,5.66,5.66,0,0,0,1.65-3.47,2.35,2.35,0,0,0-.69-1.41,5.35,5.35,0,0,1-1.3-3.08c-.08-1.71.68-3.52,2.33-5.52a1.5,1.5,0,0,1,2.11-.21A1.52,1.52,0,0,1,77,6.56,5.61,5.61,0,0,0,75.32,10,2.4,2.4,0,0,0,76,11.43a5.28,5.28,0,0,1,1.3,3.08C77.39,16.22,76.63,18,75,20A1.51,1.51,0,0,1,73.82,20.58Z"
        />
        <path
          stroke={props.strokeColor2}
          fill={props.strokeColor2}
          d="M39.87,121.1a1.5,1.5,0,0,1-1.5-1.5v-5.33a1.5,1.5,0,0,1,3,0v5.33A1.5,1.5,0,0,1,39.87,121.1Z"
        />
        <path
          stroke={props.strokeColor2}
          fill={props.strokeColor2}
          d="M87.8,121.1a1.5,1.5,0,0,1-1.5-1.5v-5.33a1.5,1.5,0,0,1,3,0v5.33A1.5,1.5,0,0,1,87.8,121.1Z"
        />
      </g>
    </svg>
  </svg>
);
export default OvenSvg;
