import React, { Component } from "react";

import * as echarts from "echarts/dist/echarts.js";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactExport from "react-data-export";
import "./dashboard.css";
import {
  getSystemIntervalData,
  getSystemInfo,
  getSystemIDfromHandle,
  getSystem,
  getSystemDailyData,
  getSystemBasics,
  setSystemSignals,
  initSystemUpdate,
  getSystemLatestData,
  ws_getSystemLatestData,
} from "../services/systemService";
import zIndex from "@material-ui/core/styles/zIndex";
import { faCommentsDollar } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import { webSocketMode } from "../config.json"; //0: http; 1:websocket
import { isNullOrUndefined } from "joi-browser";
import GraphDaily from "./graphDaily";
import DemandDaily from "./demandDaily";

class IntervalGraphDaily extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphLen: 0,
      base: new Date(),
      oneDay: 86400000,
      date: [],
      calendarDay: [],
      pvPower: [0],
      batteryPower: [0],
      now: new Date(),
      pvInterval: [],
      battInterval: [],
    };
  }

  async componentDidMount() {
    const startDate = new Date().setHours(0, 0, 0) - 86400000 * 6; //Last 7 days including today
    const endDate = new Date().getTime();

    const handleID = this.props.match.params.systemHandleID;

    const systemConfiguration = this.props.match.params.systemConfiguration;
    console.log(
      "System Configuration in Interval Graph Daily: ",
      systemConfiguration
    );
    this.setState({ startDate, endDate, handleID, systemConfiguration });
    const systemInfo = await getSystemInfo(handleID);
    const systemID = (await getSystemIDfromHandle(handleID)).data.data
      .system_id;
    const systemBasics = await getSystemBasics(handleID);
    console.log("System Basics", systemBasics);
    const circuitNameMeter1 = systemBasics.data.data.basics.circuitNameMeter1;
    console.log("Grid or Bldg: ", circuitNameMeter1);
    const systemData = (await getSystem(systemID)).data.data[0].systemID;
    const serialNumber = systemData.SN;
    const macAddress = systemData.MAC;
    const systemName = systemData.name;
    const dataReady = false;
    this.setState({
      serialNumber,
      macAddress,
      systemName,
      systemID,
      circuitNameMeter1,
      dataReady,
    });
    this.computeIntervalChart(startDate, endDate);
  }

  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  async unReportedDaily(startDate, endDate) {
    console.log("Unreported StartDate:", startDate);
    console.log("Unreported endDate:", endDate);

    const slaveMAC = "unKnown";
    await setSystemSignals(
      this.state.systemID,
      "19", //this.state.data.performFunction,
      "", //this.state.data.WiFi_SSID,
      "", //this.state.data.WiFi_PSWD,
      "", //this.state.data.parameterThree,
      slaveMAC
    );

    const systemUpdateInfo = await initSystemUpdate(
      this.props.match.params.systemHandleID
    );
    let systemStatus = (
      await getSystemInfo(this.props.match.params.systemHandleID)
    ).data.data.status;
    while (systemStatus != "ALIVE") {
      this.sleep(5000);
      systemStatus = (
        await getSystemInfo(this.props.match.params.systemHandleID)
      ).data.data.status;
      console.log("System Status:", systemStatus);
    }
    const unReportedDaily = await getSystemDailyData(
      this.props.match.params.systemHandleID,
      startDate,
      endDate
    );
    console.log("Unreported Daily Data", unReportedDaily);
  }

  async computeIntervalChart(startDate, endDate) {
    let response = {};

    if (webSocketMode) {
      response = ws_getSystemLatestData(this.state.handleID);
      console.log("Websocket: Latest Data", response);
    } else {
      response = (await getSystemLatestData(this.state.handleID)).data.data[0];
      console.log("Http: Latest Data", response);
    }

    const unRecCount = response.data.unRecCount;
    console.log("UnRecorded Count", unRecCount);
    let unRecLogic = false;
    if (unRecCount === null || unRecCount === undefined || unRecCount === 0)
      unRecLogic = false;
    else unRecLogic = true;

    console.log("UnRecLogic", unRecLogic);

    const systemIntervalRaw = await getSystemIntervalData(
      this.state.handleID,
      startDate,
      endDate
    );

    console.log("Raw Interval Data", systemIntervalRaw);
    const systemIntervalData = systemIntervalRaw.data.data;
    const recordLength = systemIntervalData.length;
    console.log("Interval Data", systemIntervalData);
    console.log("Last Data Point Time:", endDate);
    const numOfDays = parseInt((endDate - startDate) / 86400000);
    const numOfPoints = numOfDays + 1;
    console.log("Num of Days", numOfDays);

    let startTimeDay = [0];
    let stopTimeDay = [0];
    for (let k = 0; k < numOfPoints; k++) {
      if (k === 0) startTimeDay[k] = startDate;
      // else startTimeDay[k] = stopTimeDay[k - 1] + 60000;
      else startTimeDay[k] = stopTimeDay[k - 1];
      if (k === numOfPoints - 1) stopTimeDay[k] = endDate;
      else stopTimeDay[k] = startTimeDay[k] + 86400000;
      // console.log("Start Time Day:", k, startTimeDay[k]);
      //console.log("Stop Time Day:", k, stopTimeDay[k]);
    }
    //Subtract a minute from the start Time and add a minute to stop Time make sure the data in inclusive
    for (let k = 0; k < numOfPoints; k++) {
      startTimeDay[k] -= 0;
      stopTimeDay[k] += 60000;
      console.log("Start Time Day:", k, startTimeDay[k]);
      console.log("Stop Time Day:", k, stopTimeDay[k]);
    }

    let pvDaily = [0];
    let gridDaily = [0];
    let bldgDaily = [0];
    let pvValue = [0];
    let gridValue = [0];
    let bldgValue = [0];
    let calendarDay = [0];
    let countInDay = [0];
    let gridDemand = 0;
    let gridMaxDemand = 0;
    let gridMaxKW = 0;
    let demandRate = 0;
    let tempIndex = 0;
    let maxGlobalPriority = [0];
    let dailyMaxDemand = [0];
    let dailyMaxPriority = [0];
    let periodMaxDemand = 0;

    for (let j = 0; j <= numOfPoints - 1; j++) {
      pvDaily[j] = 0;
      gridDaily[j] = 0;
      bldgDaily[j] = 0;
      pvValue[j] = 0;
      gridValue[j] = 0;
      bldgValue[j] = 0;
      calendarDay[j] = 0;
      countInDay[j] = 0;
      dailyMaxDemand[j] = 0;
      dailyMaxPriority[j] = 0;
      ////////////////////////////////////////////

      ///////////////////////////////////////////

      for (let i = 0; i < recordLength; i++) {
        if (
          parseInt(this.state.systemConfiguration) === 2 &&
          parseInt(systemIntervalData[i].timestamp) >= startTimeDay[j] &&
          parseInt(systemIntervalData[i].timestamp) < stopTimeDay[j]
        ) {
          /////////////////////////////////////////////////////////////
          demandRate = systemIntervalData[i].data.DemandRate;
          ///////////////////////////////////////////////////////////
          countInDay[j] += 1;
          pvDaily[j] +=
            systemIntervalData[i].data.acuvimInterval_1 / (4 * 1000);
          pvValue[j] += systemIntervalData[i].data.acuvimEnergyValue_1;

          if (this.state.circuitNameMeter1 === "0") {
            gridDaily[j] +=
              systemIntervalData[i].data.acuvimInterval_0 / (4 * 1000);
            gridValue[j] += systemIntervalData[i].data.acuvimEnergyValue_0;
            bldgDaily[j] = pvDaily[j] + gridDaily[j];
            bldgValue[j] = pvValue[j] + gridValue[j];

            ////////////////////////////////////////////////
            gridDemand =
              (systemIntervalData[i].data.acuvimInterval_0 * demandRate) / 1000;
            //////////////////////////////////////////////
          } else if (this.state.circuitNameMeter1 === "1") {
            bldgDaily[j] +=
              systemIntervalData[i].data.acuvimInterval_0 / (4 * 1000);
            bldgValue[j] += systemIntervalData[i].data.acuvimEnergyValue_0;
            gridDaily[j] = bldgDaily[j] - pvDaily[j];
            gridValue[j] = bldgValue[j] - pvValue[j];
            ///////////////////////////////////////////////////
            gridDemand =
              ((systemIntervalData[i].data.acuvimInterval_0 -
                systemIntervalData[i].data.acuvimInterval_1) *
                demandRate) /
              1000;
            ///////////////////////////////////////////////////
          } else {
            ///////////////////////
            gridDemand = 0.0;
            /////////////////////////
            gridDaily[j] = 0.0;
            bldgDaily[j] = 0.0;
          }
          if (gridDemand > gridMaxDemand) {
            gridMaxDemand = gridDemand;
            gridMaxKW = gridDemand / demandRate;
          }
        }
        /////////////////////////////////////////////////////////////////////////////////
        else if (
          parseInt(this.state.systemConfiguration) === 3 &&
          parseInt(systemIntervalData[i].timestamp) >= startTimeDay[j] &&
          parseInt(systemIntervalData[i].timestamp) < stopTimeDay[j]
        ) {
          ////////////////////////////////////////////////////////////
          demandRate = systemIntervalData[i].data.DemandRate; //$/kW
          /////////////////////////////////////////////////////////////
          countInDay[j] += 1;
          pvDaily[j] += systemIntervalData[i].data.Interval_1 / 4;
          pvValue[j] += systemIntervalData[i].data.EnergyValue_1;

          if (this.state.circuitNameMeter1 === "0") {
            gridDaily[j] += systemIntervalData[i].data.Interval_0 / 4;
            gridValue[j] += systemIntervalData[i].data.EnergyValue_0;
            bldgDaily[j] = pvDaily[j] + gridDaily[j];
            bldgValue[j] = pvValue[j] + gridValue[j];
            //////////////////////////////////////////////////////
            gridDemand = systemIntervalData[i].data.Interval_0 * demandRate; //kW * $/kW
            /////////////////////////////////////////////////////
          } else if (this.state.circuitNameMeter1 === "1") {
            bldgDaily[j] += systemIntervalData[i].data.Interval_0 / 4;
            bldgValue[j] += systemIntervalData[i].data.EnergyValue_0;
            gridDaily[j] = bldgDaily[j] - pvDaily[j];
            gridValue[j] = bldgValue[j] - pvValue[j];
            ///////////////////////////////////////////////////////
            gridDemand =
              (systemIntervalData[i].data.Interval_0 -
                systemIntervalData[i].data.Interval_1) *
              demandRate;
            ////////////////////////////////////////////////////
          } else {
            ///////////////////////
            gridDemand = 0.0;
            /////////////////////////
            gridDaily[j] = 0.0;
            bldgDaily[j] = 0.0;
          }
          /////////////////////////////////////////////
          if (gridDemand > gridMaxDemand) {
            gridMaxDemand = gridDemand;
            gridMaxKW = gridDemand / demandRate;
          }
          ////////////////////////////////////////////
        }
        /////////////////////////////////////////////////////////////////////////////////
        else if (
          parseInt(this.state.systemConfiguration) === 6 &&
          parseInt(systemIntervalData[i].timestamp) >= startTimeDay[j] &&
          parseInt(systemIntervalData[i].timestamp) < stopTimeDay[j]
        ) {
          ////////////////////////////////////////////////////////////
          demandRate = systemIntervalData[i].data.DemandRate; //$/kW
          /////////////////////////////////////////////////////////////
          countInDay[j] += 1;
          if (
            systemIntervalData[i].data.meter2Interval === isNullOrUndefined ||
            systemIntervalData[i].data.meter1Interval === isNullOrUndefined
          )
            tempIndex = i - 1;
          else tempIndex = i;
          pvDaily[j] += systemIntervalData[tempIndex].data.meter2Interval / 4;
          pvValue[j] += systemIntervalData[tempIndex].data.meter2EnergyValue;

          if (this.state.circuitNameMeter1 === "0") {
            gridDaily[j] +=
              systemIntervalData[tempIndex].data.meter1Interval / 4;
            gridValue[j] +=
              systemIntervalData[tempIndex].data.meter1EnergyValue;
            bldgDaily[j] = pvDaily[j] + gridDaily[j];
            bldgValue[j] = pvValue[j] + gridValue[j];
            //////////////////////////////////////////////////////
            gridDemand =
              systemIntervalData[tempIndex].data.meter1Interval * demandRate; //kW * $/kW
            /////////////////////////////////////////////////////
          } else if (this.state.circuitNameMeter1 === "1") {
            bldgDaily[j] +=
              systemIntervalData[tempIndex].data.meter1Interval / 4;
            bldgValue[j] +=
              systemIntervalData[tempIndex].data.meter1EnergyValue;
            gridDaily[j] = bldgDaily[j] - pvDaily[j];
            gridValue[j] = bldgValue[j] - pvValue[j];
            ///////////////////////////////////////////////////////
            gridDemand =
              (systemIntervalData[tempIndex].data.meter1Interval -
                systemIntervalData[tempIndex].data.meter2Interval) *
              demandRate;
            ////////////////////////////////////////////////////
          }
        } else if (
          parseInt(this.state.systemConfiguration) === 8 &&
          parseInt(systemIntervalData[i].timestamp) >= startTimeDay[j] &&
          parseInt(systemIntervalData[i].timestamp) < stopTimeDay[j]
        ) {
          //const multiplier = 1.0;
          let multiplier = 10.0;

          ////////////////////////////////////////////////////////////
          demandRate = systemIntervalData[i].data.DemandRate; //$/kW
          /////////////////////////////////////////////////////////////
          countInDay[j] += 1;
          if (
            systemIntervalData[i].data.meter2Interval === isNullOrUndefined ||
            systemIntervalData[i].data.meter1Interval === isNullOrUndefined
          )
            tempIndex = i - 1;
          else tempIndex = i;

          if (systemIntervalData[i].data.maxGlobalPriority === undefined)
            maxGlobalPriority[tempIndex] = 0;
          else
            maxGlobalPriority[tempIndex] = systemIntervalData[
              i
            ].data.maxGlobalPriority.toFixed(0);
          if (dailyMaxPriority[j] < maxGlobalPriority[tempIndex])
            dailyMaxPriority[j] = parseInt(maxGlobalPriority[tempIndex]);
          //console.log("Max Global Priority:", maxGlobalPriority[tempIndex]);

          pvDaily[j] +=
            multiplier * systemIntervalData[tempIndex].data.meter2Interval;
          pvValue[j] +=
            multiplier * systemIntervalData[tempIndex].data.meter2EnergyValue;

          if (this.state.circuitNameMeter1 === "0") {
            if (
              dailyMaxDemand[j] <
              multiplier * systemIntervalData[tempIndex].data.meter1Interval
            )
              dailyMaxDemand[j] = (
                multiplier * systemIntervalData[tempIndex].data.meter1Interval
              ).toFixed(1);

            gridDaily[j] +=
              multiplier * systemIntervalData[tempIndex].data.meter1Interval;
            gridValue[j] +=
              multiplier * systemIntervalData[tempIndex].data.meter1EnergyValue;
            bldgDaily[j] = pvDaily[j] + gridDaily[j];
            bldgValue[j] = pvValue[j] + gridValue[j];
            //////////////////////////////////////////////////////
            gridDemand =
              multiplier *
              systemIntervalData[tempIndex].data.meter1Interval *
              demandRate; //kW * $/kW
            /////////////////////////////////////////////////////
          } else if (this.state.circuitNameMeter1 === "1") {
            if (
              dailyMaxDemand[j] <
              multiplier *
                (systemIntervalData[tempIndex].data.meter1Interval -
                  systemIntervalData[tempIndex].data.meter2Interval)
            )
              dailyMaxDemand[j] = (
                multiplier *
                (systemIntervalData[tempIndex].data.meter1Interval -
                  systemIntervalData[tempIndex].data.meter2Interval)
              ).toFixed(1);
            bldgDaily[j] +=
              multiplier * systemIntervalData[tempIndex].data.meter1Interval;
            bldgValue[j] +=
              multiplier * systemIntervalData[tempIndex].data.meter1EnergyValue;
            gridDaily[j] = bldgDaily[j] - pvDaily[j];

            gridValue[j] = bldgValue[j] - pvValue[j];
            ///////////////////////////////////////////////////////
            gridDemand =
              multiplier *
              (systemIntervalData[tempIndex].data.meter1Interval -
                systemIntervalData[tempIndex].data.meter2Interval) *
              demandRate;
            ////////////////////////////////////////////////////
          } else {
            ///////////////////////
            gridDemand = 0.0;
            /////////////////////////
            gridDaily[j] = 0.0;
            bldgDaily[j] = 0.0;
          }
          if (dailyMaxDemand[j] > periodMaxDemand)
            periodMaxDemand = dailyMaxDemand[j];
          console.log("Max Demand for Period", periodMaxDemand);
          /////////////////////////////////////////////
          if (gridDemand > gridMaxDemand) {
            gridMaxDemand = gridDemand;
            gridMaxKW = gridDemand / demandRate;
          }
          ////////////////////////////////////////////
        }
      }

      const lastTimeDay = new Date(startTimeDay[j]).getDay();
      const todayTimeDay = new Date().getDay();
      if (lastTimeDay !== todayTimeDay) {
        pvDaily[j] *= 96 / countInDay[j];
        gridDaily[j] *= 96 / countInDay[j];
        bldgDaily[j] *= 96 / countInDay[j];
        pvValue[j] *= 96 / countInDay[j];
        gridValue[j] *= 96 / countInDay[j];
        bldgValue[j] *= 96 / countInDay[j];
      }
      ///////////////////////////////////
      if (countInDay[j] < 85) {
        pvDaily[j] = pvDaily[j - 1];
        gridDaily[j] = gridDaily[j - 1];
        bldgDaily[j] = bldgDaily[j - 1];
        pvValue[j] = pvValue[j - 1];
        gridValue[j] = gridValue[j - 1];
        bldgValue[j] = bldgValue[j - 1];
      }

      //////////////////////////////////

      calendarDay[j] = [
        new Date(startTimeDay[j]).getMonth() + 1,
        new Date(startTimeDay[j]).getDate(),
        new Date(startTimeDay[j]).getFullYear(),
      ].join("/");

      console.log("PV Daily: ", j, pvDaily[j], countInDay[j]);
      console.log("Calendar Day", j, calendarDay[j]);
      console.log("Daily Max Demand: ", j, dailyMaxDemand[j]);
      console.log("Daily Max Priority: ", j, dailyMaxPriority[j]);
    }

    let pvTotal = 0;
    let gridTotal = 0;
    let bldgTotal = 0;
    let pvTotalValue = 0;
    let gridTotalValue = 0;
    let bldgTotalValue = 0;
    let totalBill = 0;
    for (let i = 0; i < numOfPoints; i++) {
      pvTotal += pvDaily[i];
      gridTotal += gridDaily[i];
      bldgTotal += bldgDaily[i];
      pvTotalValue += pvValue[i];
      gridTotalValue += gridValue[i];
      bldgTotalValue += bldgValue[i];
    }
    totalBill = gridTotalValue + gridMaxDemand;
    pvTotal = pvTotal
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    gridTotal = gridTotal
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    bldgTotal = bldgTotal
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    pvTotalValue = pvTotalValue
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    gridTotalValue = gridTotalValue
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    bldgTotalValue = bldgTotalValue
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    gridMaxKW = gridMaxKW
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    gridMaxDemand = gridMaxDemand
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    totalBill = totalBill
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    let excelDataSet = [0];
    for (let i = 0; i < numOfPoints; i++) {
      excelDataSet[i] = JSON.parse(
        JSON.stringify({
          Date: calendarDay[i],
          /* Time: date[i],*/
          Count: countInDay[i],

          DailyPV: parseFloat(pvDaily[i]).toFixed(2),
          DailyPVvalue: parseFloat(pvValue[i]).toFixed(2),

          Dailygrid: parseFloat(gridDaily[i]).toFixed(2),
          DailyGridValue: parseFloat(gridValue[i]).toFixed(2),

          DailyBldg: parseFloat(bldgDaily[i]).toFixed(2),
          DailyBldgValue: parseFloat(bldgValue[i]).toFixed(2),
        })
      );
    }
    //console.log("Excel Data JSON", excelDataSet);
    const dataReady = true;
    this.setState({
      pvDaily,
      gridDaily,
      bldgDaily,
      pvValue,
      gridValue,
      bldgValue,
      calendarDay,
      excelDataSet,
      pvTotal,
      gridTotal,
      bldgTotal,
      pvTotalValue,
      gridTotalValue,
      bldgTotalValue,
      unRecLogic,
      gridMaxKW,
      gridMaxDemand,
      totalBill,
      dataReady,
      dailyMaxDemand,
      dailyMaxPriority,
      periodMaxDemand,
    });
  }
  handleChangeStart = (event) => {
    const startDate = event.setHours(0, 0, 0);
    console.log("handleChangeStart: ", startDate);
    this.setState({ startDate });
  };

  handleChangeEnd = (event) => {
    const endDate = event.setHours(0, 0, 0);
    console.log("handleChangeEnd: ", endDate);
    this.setState({ endDate });
  };

  handleClickMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseDR = () => {
    this.setState({ anchorEl: null });
    const startDate = this.state.startDate;
    let endDate = this.state.endDate;
    //console.log("Daily Graph End Date 1 ", endDate);
    //const endTimeDay = new Date(endDate).getDay();
    //const todayTimeDay = new Date().getDay();
    //console.log("endTimeDay", endTimeDay);
    //console.log("todayTimeDay", todayTimeDay);
    //if (endTimeDay === todayTimeDay) endDate = new Date().getTime();
    //else endDate += 86400000 - 60000;

    //endDate += 86400000 - 60000;

    //console.log("Daily Graph End Date 2 ", endDate);
    const dataReady = false;
    this.setState({ dataReady });
    this.computeIntervalChart(startDate, endDate);
  };

  handleUnreportedDaily = () => {
    this.setState({ anchorEl: null });

    const endDate = new Date().getTime();
    const startDate = endDate - 60000;
    this.unReportedDaily(startDate, endDate);
  };

  render() {
    const intervalLength = this.state.calendarDay.length - 1;

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    return (
      <React.Fragment>
        <div
          className="containerSimplView"
          style={{ marginTop: "120px", marginBottom: "0px", zIndex: 1 }}
        >
          <p
            className="basicParagraph"
            style={{
              width: "350px",
              fontSize: "12px",
              color: "#777575",
              textAlign: "center",
              paddingRight: "25px",
              marginBottom: "15px",
              //zIndex: 2,
            }}
          >
            {this.state.systemName}: Daily Data
          </p>
          <div className="mainMenuIcons">
            <table>
              <tr style={{ height: "40px" }}>
                <td>
                  <div style={{ marginLeft: "50px", marginBottom: "8px" }}>
                    {" "}
                    <DatePicker
                      className="ui-datepicker"
                      selected={this.state.startDate}
                      selectsStart
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      onChange={this.handleChangeStart}
                    />
                    <DatePicker
                      className="ui-datepicker"
                      selected={this.state.endDate}
                      selectsEnd
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      onChange={this.handleChangeEnd}
                    />
                  </div>
                </td>
                <td>
                  <button
                    className="btn btn-outline-light"
                    style={{
                      borderWidth: "0px",
                      padding: "0px",
                      float: "right",
                      marginRight: "5px",
                      marginLeft: "20px",
                      marginBottom: "5px",
                      position: "relative",
                      right: "0px",
                    }}
                    onClick={this.handleCloseDR}
                  >
                    <FontAwesomeIcon
                      icon="chart-bar"
                      size="sm"
                      color="orange"
                      title="Show Daily Bar Chart"
                    />
                  </button>
                </td>
                {this.state.unRecLogic && (
                  <td>
                    <button
                      className="btn btn-outline-light"
                      style={{
                        borderWidth: "0px",
                        padding: "0px",
                        float: "right",
                        marginRight: "5px",
                        marginLeft: "5px",
                        marginBottom: "5px",
                        position: "relative",
                        right: "0px",
                      }}
                      onClick={this.handleUnreportedDaily}
                    >
                      <FontAwesomeIcon
                        icon="download"
                        size="sm"
                        color="tomato"
                        title="Download unreported data"
                      />
                    </button>
                  </td>
                )}
                {!this.state.unRecLogic && (
                  <td>
                    <button
                      className="btn btn-outline-light"
                      style={{
                        borderWidth: "0px",
                        padding: "0px",
                        float: "right",
                        marginRight: "5px",
                        marginLeft: "5px",
                        marginBottom: "5px",
                        position: "relative",
                        right: "0px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon="download"
                        size="sm"
                        color="#c9c3c3"
                        title="No unreported data"
                      />
                    </button>
                  </td>
                )}
                <td>
                  <ExcelFile
                    filename="Simpl Global Daily Data"
                    element={
                      <button
                        className="btn btn-outline-light"
                        style={{
                          padding: "0px",
                          float: "right",
                          marginRight: "5px",
                          marginLeft: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon="file-excel"
                          size="sm"
                          color="green"
                          title="Export daily data to excel"
                        />
                      </button>
                    }
                  >
                    <ExcelSheet
                      data={this.state.excelDataSet}
                      name="SystemData"
                    >
                      <ExcelColumn label="Count" value="Count" />
                      <ExcelColumn label="Date(mo/day/year)" value="Date" />

                      <ExcelColumn label="Daily Solar (kWh)" value="DailyPV" />
                      <ExcelColumn
                        label="Daily Solar Value ($)"
                        value="DailyPVvalue"
                      />

                      <ExcelColumn label="Daily Grid (kWh)" value="Dailygrid" />
                      <ExcelColumn
                        label="Daily Grid Energy Value ($)"
                        value="DailyGridValue"
                      />

                      <ExcelColumn
                        label="Daily Building (kWh)"
                        value="DailyBldg"
                      />
                      <ExcelColumn
                        label="Daily Building Energy Value ($)"
                        value="DailyBldgValue"
                      />
                    </ExcelSheet>
                  </ExcelFile>
                </td>
              </tr>
            </table>
          </div>
          <div className="mainMenuIcons" style={{ marginTop: "5px" }}>
            <table className="table">
              <thead>
                <tr style={{ height: "10px" }}>
                  <th style={{ padding: "0px", margin: "0px" }}></th>
                  <th style={{ padding: "0px", margin: "0px" }}></th>
                  <th style={{ padding: "0px", margin: "0px" }}>kWh</th>
                  <th style={{ padding: "0px", margin: "0px" }}>$Value</th>
                </tr>
              </thead>

              <tr
                style={{
                  height: "10px",
                  color: "orange",
                }}
              >
                <td style={{ padding: "0px", margin: "0px" }}>
                  <button
                    className="graphLegend"
                    style={{
                      backgroundColor: "orange",
                      borderColor: "orange",
                      opacity: "1.0",
                    }}
                  ></button>
                </td>
                <td
                  style={{ padding: "0px", margin: "0px", textAlign: "left" }}
                >
                  Solar
                </td>
                <td style={{ padding: "0px", margin: "0px" }}>
                  {this.state.pvTotal}
                </td>
                <td style={{ padding: "0px", margin: "0px" }}>
                  ${this.state.pvTotalValue}
                </td>
              </tr>
              <tr style={{ height: "10px", color: "magenta" }}>
                <td style={{ padding: "0px", margin: "0px" }}>
                  <button
                    className="graphLegend"
                    style={{
                      backgroundColor: "magenta",
                      borderColor: "magenta",
                      opacity: "1.0",
                    }}
                  ></button>
                </td>
                <td
                  style={{ padding: "0px", margin: "0px", textAlign: "left" }}
                >
                  Grid
                </td>
                <td style={{ padding: "0px", margin: "0px" }}>
                  {this.state.gridTotal}
                </td>
                <td style={{ padding: "0px", margin: "0px" }}>
                  ${this.state.gridTotalValue}
                </td>
              </tr>
              <tr style={{ height: "10px", color: "green" }}>
                <td style={{ padding: "0px", margin: "0px" }}>
                  <button
                    className="graphLegend"
                    style={{
                      backgroundColor: "green",
                      borderColor: "green",
                      opacity: "1.0",
                    }}
                  ></button>
                </td>
                <td
                  style={{ padding: "0px", margin: "0px", textAlign: "left" }}
                >
                  Bldg.
                </td>
                <td style={{ padding: "0px", margin: "0px" }}>
                  {this.state.bldgTotal}
                </td>
                <td style={{ padding: "0px", margin: "0px" }}>
                  ${this.state.bldgTotalValue}
                </td>
              </tr>

              <tr style={{ height: "10px", color: "blue" }}>
                <td style={{ padding: "0px", margin: "0px" }}>
                  <button
                    className="graphLegend"
                    style={{
                      backgroundColor: "blue",
                      borderColor: "blue",
                      opacity: "1.0",
                    }}
                  ></button>
                </td>
                <td
                  style={{ padding: "0px", margin: "0px", textAlign: "left" }}
                >
                  Demand Chgs.(kW)
                </td>
                <td style={{ padding: "0px", margin: "0px" }}>
                  {this.state.gridMaxKW}
                </td>
                <td style={{ padding: "0px", margin: "0px" }}>
                  ${this.state.gridMaxDemand}
                </td>
              </tr>

              <tr style={{ height: "10px", color: "blue" }}>
                <td style={{ padding: "0px", margin: "0px" }}>
                  <button
                    className="graphLegend"
                    style={{
                      backgroundColor: "blue",
                      borderColor: "blue",
                      opacity: "1.0",
                    }}
                  ></button>
                </td>
                <td
                  style={{ padding: "0px", margin: "0px", textAlign: "left" }}
                >
                  Max Demand (kW)
                </td>
                <td style={{ padding: "0px", margin: "0px" }}>
                  {this.state.periodMaxDemand}
                </td>
                <td style={{ padding: "0px", margin: "0px" }}>--</td>
              </tr>

              <tr style={{ height: "10px" }}></tr>
              <tr style={{ height: "10px", color: "dodgerblue" }}>
                <td style={{ padding: "0px", margin: "0px" }}>
                  <button
                    className="graphLegend"
                    style={{
                      backgroundColor: "dodgerblue",
                      borderColor: "dodgerblue",
                      opacity: "1.0",
                    }}
                  ></button>
                </td>
                <td
                  style={{
                    padding: "0px",
                    margin: "0px",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  Estimated Bill:
                </td>
                <td style={{ padding: "0px", margin: "0px" }}></td>
                <td
                  style={{
                    padding: "0px",
                    margin: "0px",
                    fontSize: "14px",
                    marginBottom: "150px",
                  }}
                >
                  ${this.state.totalBill}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div
          style={{
            width: "90%",
            position: "absolute",
            height: "1000px",
            marginTop: "150px",
          }}
        >
          {this.state.dataReady && (
            <GraphDaily
              pvDaily={this.state.pvDaily}
              gridDaily={this.state.gridDaily}
              bldgDaily={this.state.bldgDaily}
              date={this.state.date}
              calendarDay={this.state.calendarDay}
            />
          )}
          {this.state.dataReady && this.state.systemConfiguration === "8" && (
            <DemandDaily
              gridDaily={this.state.dailyMaxDemand}
              dailyMaxPriority={this.state.dailyMaxPriority}
              date={this.state.date}
              calendarDay={this.state.calendarDay}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default IntervalGraphDaily;
