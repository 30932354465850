import http from "./httpService";
import { emaUrl } from "../config.json";

export async function emaLogin() {
  return await http.post(emaUrl + "/accounts/login/user_pass", {
    username: "farid.dibachi@simplglobal.com",
    password: "Hebert()5253",
    accountId: 2187,
    applicationId: 5083,
  });
}

export async function getEmaDevices() {
  return await http.get(emaUrl + "/devices");
}

export async function getEmaDeviceInfo(emaSN) {
  return await http.get(emaUrl + "/devices/serial/" + `${emaSN}`);
}

export async function emaConnectionStatus(emaSN) {
  return await http.get(emaUrl + "/devices/ema/connection/" + `${emaSN}`);
}

export async function emaNotify(emaSN, emaCommand) {
  return await http.post(
    emaUrl + "/devices/ema/boardnotify/serial/" + `${emaSN}`,
    {
      cmd: `${emaCommand}`,
    }
  );
}
