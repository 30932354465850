import React, { Component } from "react";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import Form from "./common/form";
import {
  findIdByMac,
  createSystem,
  getSystems,
  setSystemBasics,
  getSystemBasics,
  findSystemBySerialNumber,
  getSystem,
  setSystemBasicsObject,
} from "../services/systemService";
import BackGroundRectangle from "../components/backgroundRect";
import { ConsoleView } from "react-device-detect";

class ManufactureProduct extends Component {
  state = {
    optionsType: [
      { _id: "SimplBox", name: "SimplBox" },
      { _id: "SimplMeter", name: "SimplMeter" },
      { _id: "SimplMeter II", name: "SimplMeter II" },
      { _id: "SimplRepeater", name: "SimplRepeater" },
      { _id: "SimplLoads", name: "SimplLoads" },
      { _id: "Simpl4G_HUB", name: "Simpl4G_HUB" },
    ],
    optionsSimplBox: [
      { _id: "SimplBox-05", name: "SimplBox-05" },
      { _id: "SimplBox-10", name: "SimplBox-10" },
      { _id: "SimplBox-15", name: "SimplBox-15" },
      { _id: "SimplBox-05-Master", name: "SimplBox-05-Master" },
      { _id: "SimplBox-10-Master", name: "SimplBox-10-Master" },
      { _id: "SimplBox-15-Master", name: "SimplBox-15-Master" },
    ],

    optionsSimplMeter: [
      { _id: "SimplMeter-R", name: "SimplMeter-R" },
      { _id: "SimplMeter-R-4G", name: "SimplMeter-R-4G" },
      { _id: "SimplMeter-C", name: "SimplMeter-C" },
      { _id: "SimplMeter-C-4G", name: "SimplMeter-C-4G" },
    ],

    optionsSimplMeterII: [
      { _id: "SimplMeter II-R1", name: "SimplMeter II-R1" },
      { _id: "SimplMeter II-R2", name: "SimplMeter II-R2" },
      { _id: "SimplMeter II-C1", name: "SimplMeter II-C1" },
      { _id: "SimplMeter II-C2", name: "SimplMeter II-C2" },
    ],

    optionsSimplRepeater: [
      { _id: "SimplRepeater", name: "SimplRepeater" },
      { _id: "SimplRepeater-4G", name: "SimplRepeater-4G" },
    ],
    optionsSimplLoads: [
      { _id: "SimplLoads", name: "SimplLoads" },
      { _id: "SimplLoads-4G", name: "SimplLoads-4G" },
    ],
    optionsSimpl4GHUB: [
      { _id: "SimplHub", name: "SimplHub" },
      { _id: "Simpl4G", name: "Simpl4G" },
    ],
    errors: {},
  };

  async componentDidMount() {
    const systemType = "";
    const productPN = "";
    const endProductPN = "";
    this.setState({ systemType, productPN, endProductPN });
  }

  schema = {
    MAC: Joi.string().required().label("Unit's MAC Address"),
    productSN: Joi.string().required().label("Unit's Serial Number"),
    systemType: Joi.string().required().label("Porduct Type"),
    productPN: Joi.string().required().label("Product"),
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const endProductPN = this.state.endProductPN;
    console.log("Product Part Number", endProductPN);
    let isSystemMaster = 0;
    //Determine if the end product is a master or slave unit
    if (
      endProductPN === "SimplBox" ||
      endProductPN === "SimplControl Quad 20A" ||
      endProductPN === "SimplControl Quad 30A" ||
      endProductPN === "SimplControl Inline 20A" ||
      endProductPN === "SimplControl Inline 30A" ||
      endProductPN === "Simpl-T" ||
      endProductPN === "Simpl-RH" ||
      endProductPN === "Simpl-TRH" ||
      endProductPN === "Simpl-DW" ||
      endProductPN === "Simpl-L" ||
      endProductPN === "Simpl-Therm"
    ) {
      isSystemMaster = 0;
    } else if (
      endProductPN === "SimplBox Hub" ||
      endProductPN === "SimplBox 4G-Hub" ||
      endProductPN === "SimplMeter-1R" ||
      endProductPN === "SimplMeter-1C" ||
      endProductPN === "SimplMeter-2R" ||
      endProductPN === "SimplMeter-2C" ||
      endProductPN === "SimplHub Module" ||
      endProductPN === "SimplHub 4G" ||
      endProductPN === "SimplGateway" ||
      endProductPN === "SimplGateway 4G"
    ) {
      isSystemMaster = 1;
    }
    //if a slave unit, add the product serial number to its Basics
    if (isSystemMaster === 0) {
      const slave_id = (await findSystemBySerialNumber(this.state.mainBoardSN))
        .data.data.system_id;
      console.log("Slave System ID", slave_id);
      //if not a master unit, then add the main boards serial number and the product SN to the Basics
      await setSystemBasics(
        slave_id,
        "Default Basic Description",
        "0", //system Configuration is always set to 0 at the time of manufacturing
        this.state.endProductPN, //System Product PN is grabbed from above
        this.state.systemType, //system Product Type is grabbed from above
        "1", //System Time zone -8 hours Pacific Time
        "2", //Data Verbosity set to Medium
        "1", //Data Frequency set to once/second
        "2", //Local Log Length
        "1", //Local Log Frequency
        15, //Local Log verbosity
        "2", //Reset Delay
        "1", //Param Update Rate
        "3", //Interval Data Period
        "123",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "456",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        this.state.serialNumber,
        this.state.mainBoardSN,
        "NA",
        this.state.endProductPN
      );
    } else if (isSystemMaster === 1) {
      let master_id = 0;
      let masterBoard_id = 0;
      let slaveBoard_id = 0;
      let systemBasics = 0;
      let productSystemBasics = 0;
      let masterHandleID = 0;
      if (
        endProductPN === "SimplBox Hub" ||
        endProductPN === "SimplBox 4G-Hub"
      ) {
        masterBoard_id = (
          await findSystemBySerialNumber(this.state.masterBoardSN)
        ).data.data.system_id;
        const masterHandleID = (await getSystem(masterBoard_id)).data.data[0]
          .systemID.id;
        systemBasics = (await getSystemBasics(masterHandleID)).data.data.basics;
        productSystemBasics = {
          ...systemBasics,
          systemProduct: this.state.productPN,
          systemType: this.state.systemType,
          productSN: this.state.serialNumber,
          slaveSN: this.state.mainBoardSN,
          fourGserialNumber: this.state.fourGserialNumber,
          endProductPN: this.state.endProductPN,
          masterBoardSN: this.state.masterBoardSN,
        };
        await setSystemBasicsObject(masterBoard_id, productSystemBasics);

        slaveBoard_id = (await findSystemBySerialNumber(this.state.mainBoardSN))
          .data.data.system_id;
        const slaveHandleID = (await getSystem(slaveBoard_id)).data.data[0]
          .systemID.id;
        systemBasics = (await getSystemBasics(slaveHandleID)).data.data.basics;
        productSystemBasics = {
          ...systemBasics,
          systemProduct: this.state.productPN,
          systemType: this.state.systemType,
          productSN: this.state.serialNumber,
          slaveSN: this.state.mainBoardSN,
          fourGserialNumber: this.state.fourGserialNumber,
          endProductPN: this.state.endProductPN,
          masterBoardSN: this.state.masterBoardSN,
        };
        await setSystemBasicsObject(slaveBoard_id, productSystemBasics);
      } else {
        master_id = (await findSystemBySerialNumber(this.state.mainBoardSN))
          .data.data.system_id;
        masterHandleID = (await getSystem(master_id)).data.data[0].systemID.id;
        systemBasics = (await getSystemBasics(masterHandleID)).data.data.basics;
        productSystemBasics = {
          ...systemBasics,
          systemProduct: this.state.productPN,
          systemType: this.state.systemType,
          productSN: this.state.serialNumber,
          slaveSN: this.state.mainBoardSN,
          fourGserialNumber: this.state.fourGserialNumber,
          endProductPN: this.state.endProductPN,
          masterBoardSN: this.state.masterBoardSN,
        };
        await setSystemBasicsObject(master_id, productSystemBasics);
      }

      //const systemBasicsPost = await getSystem(master_id);
      //console.log("System Basics Post", systemBasicsPost);
    }

    this.props.history.goBack();
    // this.props.history.push("/Companies");
  };
  handleInitial = async (e) => {
    e.preventDefault();
    try {
      const systemId = (await findSystemBySerialNumber(this.state.mainBoardSN))
        .data.data.system_id;
      console.log("Main Board Info:", systemId);
      const systemBasics = (await getSystem(systemId)).data.data[0]
        .systemBasics;
      console.log("System Basics:", systemBasics);
      const productSN = systemBasics.productSN;
      //if (productSN !== null && productSN !== undefined)
      //  toast.warning("This board has been perviously installed in a product.");
      const productPN = systemBasics.systemProduct;
      const systemType = systemBasics.systemType;
      const endProductPN = systemBasics.endProductPN;
      console.log("Product Part number:", productPN);
      console.log("System Type:", systemType);
      this.setState({ systemType, productPN, endProductPN });
    } catch (err) {
      toast.error("Invalid Serial Number.");
    }
  };
  handleChange = (e) => {
    const serialNumber = e.currentTarget.value;
    this.setState({ serialNumber });
  };
  handle4G = (e) => {
    const fourGserialNumber = e.currentTarget.value;
    this.setState({ fourGserialNumber });
  };
  handleMainBoard = (e) => {
    const mainBoardSN = e.currentTarget.value;
    console.log("Main Board SN:", mainBoardSN);
    this.setState({ mainBoardSN });
  };

  handleProductPN = (e) => {
    const endProductPN = e.currentTarget.value;
    console.log("Product PN:", endProductPN);
    this.setState({ endProductPN });
  };

  handleMasterBoard = (e) => {
    const masterBoardSN = e.currentTarget.value;
    console.log("Master Board SN:", masterBoardSN);
    this.setState({ masterBoardSN });
  };
  render() {
    console.log("System Type: ", this.state.systemType);
    console.log("productPN: ", this.state.productPN);
    return (
      <React.Fragment>
        <div
          className="form-group"
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            zIndex: 1,
          }}
        >
          <p className="basicParagraph">Manufacture a new product:</p>

          <form onSubmit={this.handleInitial}>
            <label className="basicParagraph" htmlFor="">
              Assign New Product Serial Number:
            </label>

            <input
              value={this.state.serialNumber}
              onChange={this.handleChange}
              id="serialNumber"
              type="text"
              className="form-control2"
            />
            <label className="basicParagraph" htmlFor="">
              Enter the Main Board's Serial Number:
            </label>

            <input
              value={this.state.mainBoardSN}
              onChange={this.handleMainBoard}
              id="serialNumber"
              type="text"
              className="form-control2"
            />
            <button
              className="basicButton"
              style={{
                width: "200px",
                marginTop: "10px",
              }}
            >
              Proceed
            </button>
          </form>
          <hr></hr>
          <p>End Product PN: {this.state.endProductPN}</p>
          <p>System Type: {this.state.systemType}</p>
          <p>Main Board PN: {this.state.productPN}</p>
          <hr></hr>
          <form onSubmit={this.handleSubmit}>
            <label className="basicParagraph" htmlFor="">
              Select the End Product Part Number:
            </label>
            {this.state.systemType === "SimplBox" && (
              <select
                className="form-control2"
                onChange={this.handleProductPN}
                value={this.state.endProductPN}
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                <option value="Choose here">Choose here</option>
                <option value="SimplBox">SimplBox</option>
                <option value="SimplBox Hub">SimplBox Hub</option>
                <option value="SimplBox 4G-Hub">SimplBox 4G-Hub</option>
              </select>
            )}

            {this.state.systemType === "SimplMeter" &&
              this.state.productPN === "Single Channel" && (
                <select
                  className="form-control2"
                  onChange={this.handleProductPN}
                  value={this.state.endProductPN}
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  <option value="Choose here">Choose here</option>
                  <option value="SimplMeter-1R">
                    SimplMeter Single Ch. Residential
                  </option>
                  <option value="SimplMeter-1C">
                    SimplMeter, Single Ch. Commercial
                  </option>
                </select>
              )}
            {this.state.systemType === "SimplMeter" &&
              this.state.productPN === "Dual Channel" && (
                <select
                  className="form-control2"
                  onChange={this.handleProductPN}
                  value={this.state.endProductPN}
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  <option value="Choose here">Choose here</option>
                  <option value="SimplMeter-2R">
                    SimplMeter 2 Ch. Residential
                  </option>
                  <option value="SimplMeter-2C">
                    SimplMeter, 2 Ch. Commercial
                  </option>
                </select>
              )}
            {this.state.systemType === "SimplHub" &&
              this.state.productPN !== "SimplHub SimplModule" && (
                <p>This product is not manufactured as an end product.</p>
              )}
            {this.state.systemType === "SimplHub" &&
              this.state.productPN === "SimplHub SimplModule" && (
                <select
                  className="form-control2"
                  onChange={this.handleProductPN}
                  value={this.state.endProductPN}
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  <option value="Choose here">Choose here</option>
                  <option value="SimplHub Module">SimplHub Module</option>
                  <option value="SimplHub 4G">SimplHub Module w 4G</option>
                </select>
              )}
            {(this.state.systemType === "SimplNodes" ||
              this.state.systemType === "SimplGateway") && (
              <select
                className="form-control2"
                onChange={this.handleProductPN}
                value={this.state.endProductPN}
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                <option value="Choose here">Choose here</option>
                <option value="SimplGateway">SimplGateway</option>
                <option value="SimplGateway 4G">SimplGateway 4G</option>
              </select>
            )}
            {this.state.systemType === "SimplNodes" &&
              this.state.productPN === "SimplControl: Quad Relay Driver" && (
                <select
                  className="form-control2"
                  onChange={this.handleProductPN}
                  value={this.state.endProductPN}
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  <option value="Choose here">Choose here</option>
                  <option value="SimplControl Quad 20A">
                    SimplControl Quad 20A
                  </option>
                  <option value="SimplControl Quad 30A">
                    SimplControl Quad 30A
                  </option>
                  <option value="SimplControl Inline 20A">
                    SimplControl Inline 20A
                  </option>
                  <option value="SimplControl Inline 30A">
                    SimplControl Inline 30A
                  </option>
                </select>
              )}
            {this.state.systemType === "SimplNodes" &&
              this.state.productPN === "SimplSensor: T/RH/D/L/Rth" && (
                <select
                  className="form-control2"
                  onChange={this.handleProductPN}
                  value={this.state.endProductPN}
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  <option value="Choose here">Choose here</option>
                  <option value="Simpl-T">Simpl Temperature Sensor</option>
                  <option value="Simpl-RH">
                    Simpl Relative Humidity Sensor
                  </option>
                  <option value="Simpl-TRH">
                    Simpl Temperature and RH Combo Sensor
                  </option>
                  <option value="Simpl-DW">Simpl Door/Window Sensor</option>
                  <option value="Simpl-L">Simpl Light Sensor</option>
                  <option value="Simpl-Therm">Simpl Thermistor Sensor</option>
                </select>
              )}
            {this.state.systemType === "SimplNodes" &&
              this.state.productPN === "SimplExtender" && (
                <select
                  className="form-control2"
                  onChange={this.handleProductPN}
                  value={this.state.endProductPN}
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  <option value="Choose here">Choose here</option>
                  <option value="SimplExtender">SimplExtender</option>
                </select>
              )}
            {(this.state.endProductPN === "SimplBox Hub" ||
              this.state.endProductPN === "SimplBox 4G-Hub") && (
              <div>
                <label className="basicParagraph" htmlFor="">
                  Enter the Master Board's Serial Number:
                </label>

                <input
                  value={this.state.masterBoardSN}
                  onChange={this.handleMasterBoard}
                  id="serialNumber"
                  type="text"
                  className="form-control2"
                />
              </div>
            )}
            {(this.state.endProductPN === "SimplBox 4G-Hub" ||
              this.state.endProductPN === "SimplHub 4G" ||
              this.state.endProductPN === "SimplGateway 4G") && (
              <div>
                <label className="basicParagraph" htmlFor="">
                  Enter 4G Module's Serial Number:
                </label>
                <input
                  value={this.state.fourGserialNumber}
                  onChange={this.handle4G}
                  id="serialNumber"
                  type="text"
                  className="form-control2"
                />
              </div>
            )}
            <button
              className="basicButton"
              style={{
                width: "200px",
                marginTop: "10px",
              }}
            >
              Manufacture End Product
            </button>
          </form>
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default ManufactureProduct;
