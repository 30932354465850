import React from "react";
import Joi, { isNullOrUndefined } from "joi-browser";
import { toast } from "react-toastify";

import Form from "./common/form";
import {
  getSystem,
  getSystemBasics,
  setSystemBasics,
  getSystemIDfromHandle,
  updateTimeZone,
} from "../services/systemService";

import BackGroundRectangle from "../components/backgroundRect";
import { getUser } from "../services/userService";
import { masterCompany, manufacturingCompany } from "../config.json";

class BasicsForm extends Form {
  state = {
    simplModulesCheckBox: 0,
    simplViewCheckBox: 0,
    simplAuthorized: 0,
    heaterCheckBox: 0,
    systemID: "",
    systemSN: "",
    systemName: "",
    systemProduct: "",
    systemType: "",
    systemConfiguration: "",
    data: {
      systemDescription: "Write a brief Description of the system here.",
      systemConfiguration: "1",
      systemTimeZone: "1",
      dataVerbosity: "1",
      dataFrequency: "2",
      localLogLength: "1",
      localLogFrequency: "2",
      localLogVerbosity: "1",
      resetDelay: "15",
      paramUpdateRate: "2",
      intervalDataPeriod: "3",

      circuitNameMeter1: "Building",
      ctRatioMeter1: 500,
      ctDirectionMeter1A: "+",
      ctDirectionMeter1B: "+",
      ctDirectionMeter1C: "+",
      voltageWiring1: "3LN",
      ctWiring1: "3CT",
      demandMode1: "Sliding",

      circuitNameMeter2: "Building",
      ctRatioMeter2: 500,
      ctDirectionMeter2A: "+",
      ctDirectionMeter2B: "+",
      ctDirectionMeter2C: "+",
      voltageWiring2: "3LN",
      ctWiring2: "3CT",
      demandMode2: "Sliding",
    },

    optionsConfiguration: [
      { _id: "0", name: "No SimplMeter" },
      { _id: "1", name: "One-Channel External SimplMeter" },
      { _id: "2", name: "Two-Channel External SimplMeter" },
      { _id: "3", name: "SimplModule: SimplMeter II" },
      { _id: "4", name: "SimplModule: SimplSwicth" },
      { _id: "5", name: "Three-Channel SimplLoads" },
      { _id: "6", name: "SimplModule: SimplMeter II Hub" },
      { _id: "7", name: "SimplATS" },
      { _id: "8", name: "SimplNodes:  SimplGateway" },
    ],

    optionsTimeZone: [
      { _id: "1", name: "(UTC -08:00) Pacific Time (US & Canada)" },
      { _id: "2", name: "(UTC -07:00) Mountain Time (US & Canada)" },
      { _id: "3", name: "(UTC -06:00) Central Time (US & Canada)" },
      { _id: "4", name: "(UTC -05:00) Eastern Time (US & Canada)" },
      { _id: "5", name: "(UTC -10:00) Hawaii Time (US & Canada)" },
      { _id: "6", name: "(UTC +1:00) Western Europe" },
      { _id: "7", name: "(UTC +2:00) Eastern Europe" },
      { _id: "8", name: "(UTC +3:00) Moscow, Russia" },
      { _id: "9", name: "(UTC +4:00) Abu Dhabi" },
      { _id: "10", name: "(UTC +5:30) New Dehli, India" },
      { _id: "11", name: "(UTC +08:00) Beijing, China & Hong Kong" },
      { _id: "12", name: "(UTC +09:00) Seoul, Korea and Tokyo, Japan" },
      { _id: "13", name: "(UTC +09:30) Adelaide, Australia" },
      { _id: "14", name: "(UTC +10:00) Brisbane, Australia" },
      { _id: "15", name: "(UTC +12:00) Auckland, New Zealand" },
    ],
    optionsDataVerbosity: [
      { _id: "1", name: "Low" },
      { _id: "2", name: "Medium" },
      { _id: "3", name: "High" },
    ],
    optionsDataFreq: [
      { _id: "1", name: "once / Second" },
      { _id: "2", name: "once / 5 Seconds" },
      { _id: "3", name: "once / 15 Seconds" },
      { _id: "4", name: "Once / Minute" },
      { _id: "5", name: "Once / 5 Minutes" },
      { _id: "6", name: "Once / 15 Minutes" },
      { _id: "7", name: "Once / 30 Minutes" },
      { _id: "8", name: "Once / Hour" },
    ],

    optionsLocalLogLength: [
      { _id: "1", name: "Low" },
      { _id: "2", name: "Medium" },
      { _id: "3", name: "High" },
    ],

    optionsLocalLogFreq: [
      { _id: "1", name: "5-min Intervals Data" },
      { _id: "2", name: "15-min Interval Data" },
      { _id: "3", name: "30-minute Interval Data" },
      { _id: "4", name: "One-hour Interval Data" },
    ],

    optionsLocalLogVerbosity: [
      { _id: "1", name: "Low" },
      { _id: "2", name: "Medium" },
      { _id: "3", name: "High" },
    ],

    optionsParamUpdateRate: [
      { _id: "1", name: "One Minute" },
      { _id: "2", name: "5 Minutes" },
      { _id: "3", name: "15 Minutes" },
      { _id: "4", name: "One HOUR" },
      { _id: "5", name: "One Day" },
    ],

    optionsIntervalDataPeriod: [
      { _id: "1", name: "One-Minute Interval Data" },
      { _id: "2", name: "Five-Minute Interval Data" },
      { _id: "3", name: "15-Minute Interval Data" },
      { _id: "4", name: "30-Minute Interval Data" },
      { _id: "5", name: "One-hour Interval Data" },
    ],
    optionsMeasPoint1: [{ _id: "2", name: "Solar" }],
    optionsMeasPoint0: [
      { _id: "0", name: "Line/Grid" },
      { _id: "1", name: "Load/Building" },
    ],
    optionsCTDirection: [
      { _id: "0", name: "+" },
      { _id: "1", name: "-" },
    ],
    optionsVoltageWiring: [
      { _id: "0", name: "3LN" },
      { _id: "1", name: "1LN" },
      { _id: "2", name: "2LL" },
      { _id: "3", name: "3LL" },
      { _id: "4", name: "1LL" },
    ],
    optionsCTWiring: [
      { _id: "0", name: "3CT" },
      { _id: "1", name: "1CT" },
      { _id: "2", name: "2CT" },
    ],
    optionsDemandMode: [
      { _id: "1", name: "Sliding" },
      { _id: "2", name: "Thermal" },
    ],

    errors: {},
    username: "",
  };

  schema = {
    systemDescription: Joi.string().label("System Description"),
    systemConfiguration: Joi.string().label("System Configuration"),
    systemTimeZone: Joi.string().label(
      "Select the time zone for the location of the system"
    ),
    dataVerbosity: Joi.string().label("Data Verbosity:  Low, Medium or High"),
    dataFrequency: Joi.string().label("Data Frequency:"),
    localLogLength: Joi.string().label(
      "Log length for the device local memory"
    ),
    localLogFrequency: Joi.string().label(
      "Select the frequency of the local logging function"
    ),
    localLogVerbosity: Joi.string().label(
      "Select the verbosity of the local logging function"
    ),
    resetDelay: Joi.number()
      .min(3)
      .max(100)
      .label("Delay in seconds after Power-on Reset"),
    paramUpdateRate: Joi.string().label(
      "Select the update rate of the system parameters"
    ),
    intervalDataPeriod: Joi.string().label("Select the interval data period"),

    circuitNameMeter1: Joi.string().label(
      "Select the frequency of the local logging function"
    ),

    ctRatioMeter1: Joi.number()
      .min(1)
      .max(5000)
      .label("CR Ratio Supplied by the CT Manufacturer"),
    ct2RatioMeter1: Joi.number()
      .min(1)
      .max(5000)
      .label("CR Ratio Supplied by the CT Manufacturer"),
    ctDirectionMeter1A: Joi.string().label(
      "Phase A, CT Direction: Positive or Negative"
    ),
    ctDirectionMeter1B: Joi.string().label(
      "Phase B, CT Direction: Positive or Negative"
    ),
    ctDirectionMeter1C: Joi.string().label(
      "Phase C, CT Direction: Positive or Negative"
    ),
    voltageWiring1: Joi.string().label(
      "Select voltage wiring topology for Meter 1"
    ),
    ctWiring1: Joi.string().label("Select CT wiring topology for Meter 1"),
    demandMode1: Joi.string().label(
      "Select Demand Mode Calculation Methodology for Meter 1"
    ),
    circuitNameMeter2: Joi.string().label(
      "Select the frequency of the local logging function"
    ),

    ctRatioMeter2: Joi.number()
      .min(1)
      .max(5000)
      .label("CR Ratio Supplied by the CT Manufacturer"),
    ct2RatioMeter2: Joi.number()
      .min(1)
      .max(5000)
      .label("CR Ratio Supplied by the CT Manufacturer"),
    ctDirectionMeter2A: Joi.string().label(
      "Phase A, CT Direction: Positive or Negative"
    ),
    ctDirectionMeter2B: Joi.string().label(
      "Phase B, CT Direction: Positive or Negative"
    ),
    ctDirectionMeter2C: Joi.string().label(
      "Phase C, CT Direction: Positive or Negative"
    ),
    voltageWiring2: Joi.string().label(
      "Select voltage wiring topology for Meter 2"
    ),
    ctWiring2: Joi.string().label("Select CT wiring topology for Meter 2"),
    demandMode2: Joi.string().label(
      "Select Demand Mode Calculation Methodology for Meter 2"
    ),
  };

  async componentDidMount() {
    const handleId = this.props.match.params.systemHandleID;
    const userRole = this.props.match.params.userRole;
    console.log("User Role", userRole);
    const systemID = (await getSystemIDfromHandle(handleId)).data.data
      .system_id;
    const system = (await getSystem(systemID)).data.data[0];
    console.log("System Info", system);
    const systemAddress = system.systemAddress;
    const systemCity = systemAddress.city;
    const systemState = systemAddress.state;
    const systemCountry = systemAddress.country;
    const systemZip = systemAddress.zip;
    this.setState({
      systemCity,
      systemState,
      systemCountry,
      systemZip,
    });
    const systemSN = system.systemID.SN;
    const systemName = system.systemID.name;
    const systemProduct = system.systemProduct;
    const systemType = system.systemType;
    const systemConfiguration = system.systemBasics.systemConfiguration;
    console.log("System Product", systemProduct);
    console.log("System Type", systemType);
    console.log("system Configuration", systemConfiguration);
    this.setState({ systemSN });
    const systemBasics = (await getSystemBasics(handleId)).data.data.basics;
    console.log("Get Systems Basics", systemBasics);
    const productSN = systemBasics.productSN;
    const fourGserialNumber = systemBasics.fourGserialNumber;
    const slaveSN = systemBasics.slaveSN;
    const CTtype = systemBasics.CTtype;
    const GridFrequency = systemBasics.GridFrequency;
    const VmeasGain = systemBasics.VmeasGain;
    const ImeasGain1 = systemBasics.ImeasGain1;
    const ImeasDigitalGain1 = systemBasics.ImeasDigitalGain1;
    const ImeasGain2 = systemBasics.ImeasGain2;
    const ImeasDigitalGain2 = systemBasics.ImeasDigitalGain2;

    const ctRatioMeter1 = systemBasics.ctRatioMeter1;
    const ct2RatioMeter1 = systemBasics.ct2RatioMeter1;
    const ctRatioMeter2 = systemBasics.ctRatioMeter2;
    const ct2RatioMeter2 = systemBasics.ct2RatioMeter2;

    const meter1CTtype = systemBasics.meter1CTtype;
    const meter2CTtype = systemBasics.meter2CTtype;

    const heaterCheckBox = systemBasics.heaterCheckBox;
    console.log("Heater Check Box in CDM: ", heaterCheckBox);

    const simplViewCheckBox = systemBasics.simplViewCheckBox;

    const user = await getUser();
    let simplAuthorized = this.state.simplAuthorized;
    if (
      user.data.data.admin &&
      (user.data.data.company === `${masterCompany}` ||
        user.data.data.company === `${manufacturingCompany}`)
    )
      simplAuthorized = 1;
    else simplAuthorized = 0;
    console.log("Simpl Authorized:", simplAuthorized);
    console.log("User in CDM Basics:", user);

    if (systemBasics) this.setState({ data: systemBasics });
    this.setState({
      handleId,
      userRole,
      systemID,
      systemName,
      systemType,
      systemProduct,
      systemConfiguration,
      productSN,
      fourGserialNumber,
      slaveSN,

      ctRatioMeter1,
      ct2RatioMeter1,
      ctRatioMeter2,
      ct2RatioMeter2,

      CTtype,
      GridFrequency,
      VmeasGain,
      ImeasGain1,
      ImeasDigitalGain1,
      ImeasGain2,
      ImeasDigitalGain2,

      meter1CTtype,
      meter2CTtype,

      heaterCheckBox,

      systemCity,
      systemState,
      systemCountry,
      systemZip,

      simplViewCheckBox,
      username: user.data.data.username,
      simplAuthorized,
    });
  }
  doSubmit = async () => {
    console.log("System ID: ", this.state.systemID);
    let heaterCheckBox = 0;
    if (this.state.heaterCheckBox !== isNullOrUndefined)
      heaterCheckBox = this.state.heaterCheckBox;

    let simplViewCheckBox = 0;
    if (this.state.simplViewCheckBox !== isNullOrUndefined)
      simplViewCheckBox = this.state.simplViewCheckBox;

    let simplAuthorized = 0;
    if (this.state.simplAuthorized !== isNullOrUndefined)
      simplAuthorized = this.state.simplAuthorized;

    const systemBasics = await setSystemBasics(
      this.state.systemID,
      this.state.data.systemDescription,
      this.state.data.systemConfiguration,
      this.state.systemProduct,
      this.state.systemType,
      this.state.data.systemTimeZone,
      this.state.data.dataVerbosity,
      this.state.data.dataFrequency,
      this.state.data.localLogLength,
      this.state.data.localLogFrequency,
      this.state.data.localLogVerbosity,
      this.state.data.resetDelay,
      this.state.data.paramUpdateRate,
      this.state.data.intervalDataPeriod,
      this.state.data.circuitNameMeter1,

      this.state.ctRatioMeter1, //Current Ratio for SimplMeter II
      this.state.ct2RatioMeter1, //Voltage Ratio for SimplMeter II

      this.state.data.ctDirectionMeter1A,
      this.state.data.ctDirectionMeter1B,
      this.state.data.ctDirectionMeter1C,
      this.state.data.voltageWiring1,
      this.state.data.ctWiring1,
      this.state.data.demandMode1,
      this.state.data.circuitNameMeter2,

      this.state.ctRatioMeter2, //Current Ratio for SimplMeter II
      this.state.ct2RatioMeter2, //Voltage Ratio for SimplMeter II

      this.state.data.ctDirectionMeter2A,
      this.state.data.ctDirectionMeter2B,
      this.state.data.ctDirectionMeter2C,
      this.state.data.voltageWiring2,
      this.state.data.ctWiring2,
      this.state.data.demandMode2,

      this.state.productSN,
      this.state.slaveSN,
      this.state.fourGserialNumber,

      this.state.endProductPN,
      this.state.masterBoardSN,

      this.state.CTtype,
      this.state.GridFrequency,
      this.state.VmeasGain,
      this.state.ImeasGain1,
      this.state.ImeasDigitalGain1,
      this.state.ImeasGain2,
      this.state.ImeasDigitalGain2,

      this.state.data.meter1CTtype,
      this.state.data.meter2CTtype,

      heaterCheckBox,

      this.state.systemCity,
      this.state.systemState,
      this.state.systemCountry,
      this.state.systemZip,
      this.state.username,

      simplViewCheckBox
    );
    console.log("System basics changes: ", systemBasics);
    await updateTimeZone(this.state.systemSN, this.state.data.systemTimeZone);
    toast.warning("Changes are saved but must be uploaded to take effect.");

    const simplModulesCheckBox = this.state.simplModulesCheckBox;
    if (simplModulesCheckBox === 0) this.props.history.goBack();
    /*this.props.history.push(
        `/Companies/systems/${this.props.match.params.companyName}`
      );*/ else {
      console.log("Redirecting to set SimplModules Parameters");
      this.props.history.push(
        `/systems/systemmodules/${this.props.match.params.systemHandleID}/${this.props.match.params.companyName}/${this.props.match.params.slaveMAC}/`
      );
    }
  };

  handleChangeRadio = (e) => {
    let simplModulesCheckBox = this.state.simplModulesCheckBox;
    if (simplModulesCheckBox === 0) simplModulesCheckBox = 1;
    else simplModulesCheckBox = 0;
    console.log("SimplModule", simplModulesCheckBox);
    this.setState({ simplModulesCheckBox });
  };
  handleSimplView = (e) => {
    let simplViewCheckBox = this.state.simplViewCheckBox;
    if (simplViewCheckBox === 0) simplViewCheckBox = 1;
    else simplViewCheckBox = 0;
    console.log("SimplView", simplViewCheckBox);
    this.setState({ simplViewCheckBox });
  };
  handleChangeHeaterRadio = (e) => {
    let heaterCheckBox = this.state.heaterCheckBox;
    if (heaterCheckBox === 0) heaterCheckBox = 1;
    else heaterCheckBox = 0;
    console.log("SimplHeater", heaterCheckBox);
    this.setState({ heaterCheckBox });
  };

  render() {
    console.log("SimplView Check Box", this.state.simplViewCheckBox);
    console.log("Simpl Authorized", this.state.simplAuthorized);
    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1,
          }}
        >
          <p className="basicParagraph">
            Edit System Basic Operating Settings for {this.state.systemName}:
          </p>
          <form onSubmit={this.handleSubmit}>
            {this.renderInput("systemDescription", "Brief System Description")}
            {this.renderSelect(
              "systemConfiguration",
              "System Configuration:",
              this.state.optionsConfiguration
            )}
            {this.renderSelect(
              "systemTimeZone",
              "System Time Zone:",
              this.state.optionsTimeZone
            )}
            {this.renderSelect(
              "dataVerbosity",
              "Data Verbosity:",
              this.state.optionsDataVerbosity
            )}
            {this.renderSelect(
              "dataFrequency",
              "Data Frequency:",
              this.state.optionsDataFreq
            )}
            {this.renderSelect(
              "intervalDataPeriod",
              "Interval Data Period:",
              this.state.optionsIntervalDataPeriod
            )}
            {this.renderSelect(
              "paramUpdateRate",
              "Internet Reconnect Attempts Every:",
              this.state.optionsParamUpdateRate
            )}
            {(this.state.systemConfiguration === "3" ||
              this.state.systemConfiguration === "6" ||
              this.state.systemConfiguration === "7" ||
              this.state.systemConfiguration === "8") && (
              <div className="form-check">
                <input
                  className="form-check-input"
                  style={{ marginTop: "8px" }}
                  type="checkbox"
                  name="registerReason"
                  id="registerReason1"
                  onChange={this.handleChangeRadio}
                  value="reason1"
                  // checked
                ></input>
                <label
                  className="basicParagraph"
                  style={{
                    fontSize: "10px",
                    width: "300px",
                    paddingBottom: "15px",
                  }}
                >
                  Set metering parameters
                </label>
              </div>
            )}
            {this.state.systemConfiguration === "8" &&
              this.state.simplViewCheckBox === 1 &&
              this.state.simplAuthorized === 1 && (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    style={{ marginTop: "8px" }}
                    type="checkbox"
                    name="registerReason"
                    id="registerReason1"
                    onChange={this.handleSimplView}
                    value="reason1"
                    checked
                  ></input>
                  <label
                    className="basicParagraph"
                    style={{
                      fontSize: "10px",
                      width: "300px",
                      paddingBottom: "15px",
                    }}
                  >
                    SimplView is enabled
                  </label>
                </div>
              )}
            {this.state.systemConfiguration === "8" &&
              (this.state.simplViewCheckBox === 0 ||
                this.state.simplViewCheckBox === undefined) &&
              this.state.simplAuthorized === 1 && (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    style={{ marginTop: "8px" }}
                    type="checkbox"
                    name="registerReason"
                    id="registerReason1"
                    onChange={this.handleSimplView}
                    value="reason1"
                    //checked
                  ></input>
                  <label
                    className="basicParagraph"
                    style={{
                      fontSize: "10px",
                      width: "300px",
                      paddingBottom: "15px",
                    }}
                  >
                    Simpl Global Admin must check to enable SimplView
                  </label>
                </div>
              )}

            {this.state.systemConfiguration === "0" && (
              <div className="form-check">
                {this.state.heaterCheckBox === 1 && (
                  <input
                    className="form-check-input"
                    style={{ marginTop: "8px" }}
                    type="checkbox"
                    name="registerReason"
                    id="registerReason1"
                    onChange={this.handleChangeHeaterRadio}
                    value="reason1"
                    checked
                  ></input>
                )}
                {this.state.heaterCheckBox === 0 && (
                  <input
                    className="form-check-input"
                    style={{ marginTop: "8px" }}
                    type="checkbox"
                    name="registerReason"
                    id="registerReason1"
                    onChange={this.handleChangeHeaterRadio}
                    value="reason1"
                    //checked
                  ></input>
                )}
                <label
                  className="basicParagraph"
                  style={{
                    fontSize: "10px",
                    width: "300px",
                    paddingBottom: "15px",
                  }}
                >
                  Turn on SimplBox heater when charging at below 2 *C (35 *F)
                </label>
              </div>
            )}
            {this.renderButton("Save Parameters")}
          </form>
          <h6> </h6>
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default BasicsForm;
