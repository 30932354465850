import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { getUser } from "../services/userService";
import { setJwt } from "../services/httpService";
import {
  getSystemLatestData,
  ws_getSystemLatestData,
  getSystem,
  getSystemSignals,
  setSystemSignals,
  getSystemIDfromHandle,
  initSystemUpdate,
  getSystemInfo,
  getSystemBasics,
  findIdByMac,
} from "../services/systemService";

import "./dashboard.css";
import "../index.css";
import {
  DEACTIVATE_SLAVE,
  REACTIVATE_SLAVE,
  LOCATOR_ON,
  LOCATOR_OFF,
  SLAVE_RESET,
  SLAVE_FACTORY_RESET,
  SLAVE_HIBERNATE,
  CALIBRATE,
  UPLOAD_FW,
} from "../config.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import BackGroundRectangle from "../components/backgroundRect";
import { webSocketMode } from "../config.json"; //0: http; 1:websocket
import jwtDecode from "jwt-decode";
import { isNullOrUndefined } from "joi-browser";
import {
  LORA_LOW,
  LORA_GOOD,
  WIFI_LOW,
  WIFI_GOOD,
  FOURGEE_LOW,
  FOURGEE_GOOD,
} from "../config.json";

import { ScrollView } from "@cantonjs/react-scroll-view";


class SignalStrength extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slaves: [],
      acuvimNumbers: {},
      realTime: {},
      companyName: "",
      systemID: "",
      handleID: 0,
      activeState: true,
      locatorEnabled: false,
      systemStatus: "",
      dataVerbosity: 1,
      systemConfiguration: "0",
    };
    //this.getSystemData = this.getSystemData.bind(this);
  }

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    const decodedJWT = jwtDecode(jwt).exp * 1000;
    console.log("Decoded JWT", decodedJWT);
    const date = Date.now();
    console.log("EPOCH Now:", date);
    let mustLoginAgain = this.state.mustLoginAgain;
    if (date >= decodedJWT) {
      mustLoginAgain = true;
      console.log("Go to Login Page");
    } else {
      mustLoginAgain = false;
    }
    setJwt(jwt);
    const user = await getUser();
    console.log("This User", user);
    const isUserAdmin = user.data.data.admin;
    console.log("Is User Admin", isUserAdmin);

    const handleID = this.props.match.params.systemHandleID;
    const systemID = (await getSystemIDfromHandle(handleID)).data.data
      .system_id;
    const system = (await getSystem(systemID)).data.data[0];
    const systemName = system.systemID.name;
    const systemSignals = (await getSystemSignals(handleID)).data.data.signals;
    if (systemSignals) this.setState({ data: systemSignals });
    const systemInfo = await getSystemInfo(handleID);
    const systemConfiguration = systemInfo.data.data.basics.systemConfiguration;
    console.log("System Configuration", systemConfiguration);
    const companyName = this.props.match.params.companyName;
    const BT_message = "No SimplBox system is in BlueTooth mode.";
    this.setState({
      handleID,
      systemID,
      systemName,
      companyName,
      systemConfiguration,
      isUserAdmin,
      BT_message,
      mustLoginAgain,
    });
    this.getSystemData(); //First got to mount it and then run this.  Must take this out
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  async getSystemData() {
    let response = {};

    if (webSocketMode) {
      response = ws_getSystemLatestData(this.props.match.params.systemHandleID);
      console.log("Websocket: Latest Data", response);
    } else {
      response = (await getSystemLatestData(this.props.match.params.systemHandleID)).data.data[0];
      console.log("Http: Latest Data", response);
    }
    console.log("System Response Data", response);

    let wanSignalProvider = "...";
    let wanSignalStrength = "...";
    let wanCommMode = 2;
    let gateWay_LC=0;
    let appName="...";
    console.log("Provider and strength", wanSignalProvider, wanSignalStrength);

    if (
      this.state.systemConfiguration === "8" &&
      response !== null &&
      response !== undefined
    ) {
      wanSignalProvider = response.data.commProvider;
      wanCommMode = response.data.commMode;
      wanSignalStrength = response.data.deviceLedger.realTime.RSSI;
      gateWay_LC=response.data.deviceLedger.realTime.loopCount;
      appName = response.data.deviceLedger.appName;
      console.log(
        "Provider and strength",
        wanSignalProvider,
        wanSignalStrength, wanCommMode
      );
      const slaves = response.data.deviceLedger.slaveUnits;

      const slaveCount = response.data.deviceLedger.realTime.slaveCount;
      let numOfSlaves = 0;
      if (slaves !== null && slaves !== undefined) numOfSlaves = slaves.length;
      let i = 0;
      let slaveSN = [];
      let newSlaves = [];
      // let BT_message = this.state.BT_message;
      for (i = 0; i < numOfSlaves; i++) {
        let slaveMAC = slaves[i].MAC;
        let slaveId = (await findIdByMac(slaveMAC)).data.data.system_id;
        let system = await getSystem(slaveId);
        slaveSN[i] = system.data.data[0].systemID.SN;
        newSlaves[i] = Object.assign(slaves[i], { SN: slaveSN[i] });
      }
      const realTimeData = response.data.deviceLedger.realTime;
      const loraDevices = response.data.deviceLedger.LoraDeviceItems;
      console.log("Slaves", newSlaves);

      this.setState({
        slaves: newSlaves,
        slaveCount,
        realTimeData,
        wanSignalProvider,
        wanSignalStrength,
        wanCommMode,
        gateWay_LC,
        appName,
        loraDevices,
      });
    }
    let dataFrequency = "1";
    const systemBasics = await getSystemBasics(this.props.match.params.systemHandleID);
    if (systemBasics !== null && systemBasics!==undefined/*&&
      systemBasics.data.data.basics !== null &&
      systemBasics.data.data.basics !== undefined*/
    ) {
      dataFrequency = systemBasics.data.data.basics.dataFrequency;
      let sleepTime = 0;
      if (dataFrequency === "1") sleepTime = 5000;
      //mSec
      //"1", name: "once / Second"
      else if (dataFrequency === "2") sleepTime = 15000;
      //5seconds + 5 seconds + 5 seconds
      //"2", name: "once / 5 Seconds"
      else sleepTime = 35000; //15 seconds + 15 seconds + 5 seconds
      const systemInfo = await getSystemInfo(this.props.match.params.systemHandleID);
      const systemStatus = systemInfo.data.data.status;
      const dataVerbosity = systemInfo.data.data.basics.dataVerbosity;
      console.log("Data Verbosity", dataVerbosity);
      this.setState({ systemStatus, dataVerbosity, sleepTime });
    }
  }

  tick() {
    this.setState({
      dateNow: new Date(),
    });
    this.getSystemData();
  }

  render() {
    return (
      <React.Fragment>
      <ScrollView
          style={{
            height: "200vh",
            width: "190vh",
          }}
          horizontal={true}
        >
          {this.state.mustLoginAgain && <Redirect from="/" exact to="/login" />}
          <div
            style={{
              width: "900px",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              marginTop: "150px",
              marginBottom: "150px",
              paddingBottom: "100px",
              zIndex: 1,
            }}
          >
            <p
              className="basicParagraph"
              style={{ width: "900px", textAlign: "center", fontSize: "11px" }}
            >
              {this.state.systemName} Signal Strengths:
            </p>
            {(this.state.dataVerbosity !== "3" ||
              this.state.systemConfiguration !== "8") && (
              <p
                className="basicParagraph"
                style={{
                  width: "900px",
                  textAlign: "center",
                  fontSize: "12px",
                  color: "red",
                }}
              >
                This feature is not available here...
              </p>
            )}
            {this.state.dataVerbosity === "3" && (
              <div>
                {this.state.systemConfiguration === "8" &&
                  this.state.slaveCount == 0 && (
                    <div>
                      <p className="basicParagraph">
                        {" "}
                        No Active subsystem is detected in the system...
                      </p>
                      <p className="basicParagraph">
                        {" "}
                        Please stand by as connections are being established.
                      </p>
                    </div>
                  )}
                {this.state.systemConfiguration === "8" &&
                  this.state.slaveCount != 0 && (
                    <div>
                      {this.state.BT_message !==
                        "No SimplBox system is in BlueTooth mode." && (
                        <p
                          className="basicParagraph"
                          style={{
                            textAlign: "center",
                            color: "orange",
                            marginLeft: "300px",
                          }}
                        >
                          {this.state.selectedSlaveSN} at MAC Adress:{" "}
                          {this.state.slaveMAC}+2 {this.state.BT_message}
                        </p>
                      )}
                      <hr></hr>
                      <p
                        className="basicParagraph"
                        style={{
                          width: "900px",
                          textAlign: "center",
                        }}
                      >
                        Internet connectivity is provided by:{" "}
                        {this.state.wanSignalProvider}
                        {(this.state.wanCommMode === 3 || this.state.wanCommMode === 1) &&
                          this.state.wanSignalStrength <= WIFI_LOW && (
                            <p style={{ color: "red" }}>
                              Signal strength is: {this.state.wanSignalStrength}{" "}
                              dBm, LoopCount: {this.state.gateWay_LC.toLocaleString("en-US")}, {this.state.appName}
                            </p>
                          )}
                        {(this.state.wanCommMode === 3 || this.state.wanCommMode === 1)  &&
                          this.state.wanSignalStrength > WIFI_LOW &&
                          this.state.wanSignalStrength <= WIFI_GOOD && (
                            <p style={{ color: "orange" }}>
                              Signal strength is: {this.state.wanSignalStrength}{" "}
                              dBm, LoopCount: {this.state.gateWay_LC.toLocaleString("en-US")}, {this.state.appName}
                            </p>
                          )}
                        {(this.state.wanCommMode === 3 || this.state.wanCommMode === 1) &&
                          this.state.wanSignalStrength > WIFI_GOOD && (
                            <p style={{ color: "#199b14" }}>
                              Signal strength is: {this.state.wanSignalStrength}{" "}
                              dBm, LoopCount: {this.state.gateWay_LC.toLocaleString("en-US")}, {this.state.appName}
                            </p>
                          )}
                        {this.state.wanCommMode === 2 &&
                          this.state.wanSignalStrength <= FOURGEE_LOW && (
                            <p style={{ color: "red" }}>
                              Signal strength is: {this.state.wanSignalStrength}{" "}
                              dBm, LoopCount: {this.state.gateWay_LC.toLocaleString("en-US")}, {this.state.appName}
                            </p>
                          )}
                        {this.state.wanCommMode === 2 &&
                          this.state.wanSignalStrength > FOURGEE_LOW &&
                          this.state.wanSignalStrength <= FOURGEE_GOOD && (
                            <p style={{ color: "orange" }}>
                              Signal strength is: {this.state.wanSignalStrength}{" "}
                              dBm, LoopCount: {this.state.gateWay_LC.toLocaleString("en-US")}, {this.state.appName}
                            </p>
                          )}
                        {this.state.wanCommMode === 2 &&
                          this.state.wanSignalStrength > FOURGEE_GOOD && (
                            <p style={{ color: "#199b14" }}>
                              Signal strength is: {this.state.wanSignalStrength}{" "}
                              dBm, LoopCount: {this.state.gateWay_LC.toLocaleString("en-US")}, {this.state.appName}
                            </p>
                          )}
                      </p>{" "}
                      <hr></hr>
                      <p
                        className="basicParagraph"
                        style={{
                          width: "900px",
                          textAlign: "center",
                        }}
                      >
                        WiFi Mesh Devices
                        <p>SimplRelays, SimplSensors, SimplThermostat, etc.</p>
                      </p>
                      <table
                        className="table"
                        style={{
                          width: "550px",
                          position: "relative",
                          left: "50%",
                          marginTop: "20px",
                          transform: "translateX(-50%)",
                        }}
                      >
                        <div>
                          <thead
                            style={{
                              backgroundColor: "#ece9e9",
                              marginTop: "50px",
                            }}
                          >
                            <th
                              style={{
                                color: "magenta",
                                width:"80px",
                                padding: "0px",
                                paddingBottom:"10px",
                              }}
                            >
                              SN
                            </th>
                            <th
                              style={{
                                color: "magenta",
                                width: "100px",
                                padding: "0px",
                                paddingBottom:"10px",
                              }}
                            >
                              Type
                            </th>
                            <th
                              style={{
                                width: "15px",
                                color: "magenta",
                                padding: "10px",
                                                            }}
                            >
                              Mesh Layer
                            </th>
                            <th
                              style={{
                                width: "20px",
                                color: "magenta",
                                padding: "10px",
                                                          }}
                            >
                              WiFi Signal Strength
                            </th>
                            <th
                              style={{
                                width: "20px",
                                color: "magenta",
                                padding: "20px",
                                                          }}
                            >
                              Loop Count
                            </th>
                            <th
                              style={{
                                width: "150px",
                                color: "magenta",
                                padding: "20px",
                                                          }}
                            >
                              FW Version
                            </th>
                          </thead>
                        </div>

                        {this.state.slaves.map((slave) => (
                          <div>
                            {slave.deviceRSSI <= WIFI_LOW && (
                              <tr key={slave.id} style={{ color: "red" }}>
                                <td>{slave.SN}</td>
                                <td>{slave.deviceType}</td>
                                <td
                                  style={{ position: "absolute", left: "185px" }}
                                >
                                  {slave.meshLayer}
                                </td>
                                <td
                                  style={{ position: "absolute", left: "230px" }}
                                >
                                  {slave.deviceRSSI}
                                </td>
                                <td
                                  style={{ position: "absolute", left: "285px" }}
                                >
                                  {slave.slave_loopCount.toLocaleString("en-US")}
                                </td>
                                <td
                                  style={{ position: "absolute", left: "340px" }}
                                >
                                  {slave.fwVersionSlave}
                                </td>
                              </tr>
                            )}
                            {slave.deviceRSSI > WIFI_LOW &&
                              slave.deviceRSSI <= WIFI_GOOD && (
                                <tr key={slave.id} style={{ color: "orange" }}>
                                  <td>{slave.SN}</td>
                                  <td>{slave.deviceType}</td>
                                  <td
                                    style={{
                                      position: "absolute",
                                      left: "185px",
                                    }}
                                  >
                                    {slave.meshLayer}
                                  </td>
                                  <td
                                    style={{
                                      position: "absolute",
                                      left: "230px",
                                    }}
                                  >
                                    {slave.deviceRSSI}
                                  </td>
                                  <td
                                  style={{ position: "absolute", left: "285px" }}
                                >
                                  {slave.slave_loopCount.toLocaleString("en-US")}
                                </td>
                                <td
                                  style={{ position: "absolute", left: "340px" }}
                                >
                                  {slave.fwVersionSlave}
                                </td>
                                </tr>
                              )}
                            {slave.deviceRSSI > WIFI_GOOD && (
                              <tr key={slave.id} style={{ color: "#199b14" }}>
                                <td>{slave.SN}</td>
                                <td>{slave.deviceType}</td>
                                <td
                                  style={{ position: "absolute", left: "185px" }}
                                >
                                  {slave.meshLayer}
                                </td>
                                <td
                                  style={{ position: "absolute", left: "230px" }}
                                >
                                  {slave.deviceRSSI}
                                </td>
                                <td
                                  style={{ position: "absolute", left: "285px" }}
                                >
                                  {slave.slave_loopCount.toLocaleString("en-US")}
                                </td>
                                <td
                                  style={{ position: "absolute", left: "340px" }}
                                >
                                  {slave.fwVersionSlave}
                                </td>
                                
                              </tr>
                            )}
                          </div>
                        ))}
                      </table>
                      <hr></hr>
                      <p
                        className="basicParagraph"
                        style={{
                          width: "900px",
                          textAlign: "center",
                        }}
                      >
                        LoRa Sensors and Devices
                        <p>SimplSensors, SimplRelays, etc.</p>
                      </p>
                      <table
                        className="table"
                        style={{
                          width: "350px",
                          position: "relative",
                          left: "50%",
                          marginTop: "20px",
                          transform: "translateX(-50%)",
                        }}
                      >
                        <div>
                          <thead
                            style={{
                              backgroundColor: "#ece9e9",
                              marginTop: "50px",
                            }}
                          >
                            <th
                              style={{
                                color: "magenta",
                                paddingRight: "50px",
                              }}
                            >
                              SN
                            </th>
                            <th
                              style={{
                                color: "magenta",
                              }}
                            >
                              Type
                            </th>
                            <th
                              style={{
                                color: "magenta",
                              }}
                            >
                              Data Age
                            </th>
                            <th
                              style={{
                                width: "2%",
                                color: "magenta",
                              }}
                            >
                              LoRa Signal Strength
                            </th>
                          </thead>
                        </div>

                        {this.state.loraDevices.map((loraDevice) => (
                          <div>
                            {loraDevice.loraRSSI <= LORA_LOW && (
                              <tr key={loraDevice.id} style={{ color: "red" }}>
                                <td>{loraDevice.loraSN}</td>
                                <td
                                  style={{
                                    position: "absolute",
                                    left: "100px",
                                  }}
                                >
                                  {loraDevice.loraType}
                                </td>
                                <td
                                  style={{
                                    position: "absolute",
                                    left: "215px",
                                  }}
                                >
                                  {loraDevice.loraDataAge}
                                </td>
                                <td
                                  style={{
                                    position: "absolute",
                                    left: "300px",
                                  }}
                                >
                                  {loraDevice.loraRSSI}
                                </td>
                              </tr>
                            )}
                            {loraDevice.loraRSSI > LORA_LOW &&
                              loraDevice.loraRSSI <= LORA_GOOD && (
                                <tr
                                  key={loraDevice.id}
                                  style={{ color: "orange" }}
                                >
                                  <td>{loraDevice.loraSN}</td>
                                  <td
                                    style={{
                                      position: "absolute",
                                      left: "100px",
                                    }}
                                  >
                                    {loraDevice.loraType}
                                  </td>
                                  <td
                                    style={{
                                      position: "absolute",
                                      left: "215px",
                                    }}
                                  >
                                    {loraDevice.loraDataAge}
                                  </td>
                                  <td
                                    style={{
                                      position: "absolute",
                                      left: "300px",
                                    }}
                                  >
                                    {loraDevice.loraRSSI}
                                  </td>
                                </tr>
                              )}
                            {loraDevice.loraRSSI > LORA_GOOD && (
                              <tr
                                key={loraDevice.id}
                                style={{ color: "#199b14" }}
                              >
                                <td>{loraDevice.loraSN}</td>
                                <td
                                  style={{
                                    position: "absolute",
                                    left: "100px",
                                  }}
                                >
                                  {loraDevice.loraType}
                                </td>
                                <td
                                  style={{
                                    position: "absolute",
                                    left: "215px",
                                  }}
                                >
                                  {loraDevice.loraDataAge}
                                </td>
                                <td
                                  style={{
                                    position: "absolute",
                                    left: "300px",
                                  }}
                                >
                                  {loraDevice.loraRSSI}
                                </td>
                              </tr>
                            )}
                          </div>
                        ))}
                      </table>
                      <hr></hr>
                    </div>
                  )}
              </div>
            )}
          </div>
          {/* <BackGroundRectangle />*/}

        </ScrollView>
      </React.Fragment>
    );
  }
}
export default SignalStrength;
