import React from "react";

const LightSensorSvg = (props) => (
  <svg
    style={{ margin: "0px", padding: "0px", height: "90px", width: "200px" }}
  >
    <svg
      height="200px"
      width="auto"
      id="ICONS"
      version="1.1"
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <g>
        <text
          x="1"
          y="10"
          font-family="Verdana"
          font-size="6"
          font-weight="normal"
          fill={props.textColor}
        >
          Light Sensor
        </text>
      </g>
    </svg>
    <svg
      height="auto"
      width="50"
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
      stroke="orange"
      fill="orange"
    >
      <title />
      <path d="M91,19a13.92,13.92,0,0,1-4.1,9.9,2,2,0,0,0,0,2.83,2,2,0,0,0,2.83,0,18,18,0,0,0,0-25.46A2,2,0,1,0,86.9,9.07,13.94,13.94,0,0,1,91,19Z" />
      <path d="M81.24,26a2,2,0,0,0,1.42.59A2,2,0,0,0,84.07,26a10,10,0,0,0,0-14.14,2,2,0,0,0-2.83,2.83,6,6,0,0,1,0,8.48A2,2,0,0,0,81.24,26Z" />
      <path d="M92.56,34.53a2,2,0,0,0,0,2.82,2,2,0,0,0,2.82,0,26,26,0,0,0,0-36.76,2,2,0,0,0-2.82,0,2,2,0,0,0,0,2.82A22,22,0,0,1,92.56,34.53Z" />
      <path d="M72,30.25V28h2a2,2,0,0,0,2-2V2a2,2,0,0,0-2-2H2A2,2,0,0,0,0,2V26a2,2,0,0,0,2,2H4v2.25A33.79,33.79,0,0,0,37.75,64h.5A33.79,33.79,0,0,0,72,30.25ZM4,4H72V24H4ZM38.25,60h-.5A29.79,29.79,0,0,1,8,30.25V28H68v2.25A29.79,29.79,0,0,1,38.25,60Z" />
      <path d="M90,68a22,22,0,1,0,22,22A22,22,0,0,0,90,68Zm0,40a18,18,0,1,1,18-18A18,18,0,0,1,90,108Z" />
      <path d="M64,90a2,2,0,0,0-2-2H54a2,2,0,0,0,0,4h8A2,2,0,0,0,64,90Z" />
      <path d="M126,88h-8a2,2,0,0,0,0,4h8a2,2,0,0,0,0-4Z" />
      <path d="M90,52a2,2,0,0,0-2,2v8a2,2,0,0,0,4,0V54A2,2,0,0,0,90,52Z" />
      <path d="M90,116a2,2,0,0,0-2,2v8a2,2,0,0,0,4,0v-8A2,2,0,0,0,90,116Z" />
      <path d="M66,63.13A2,2,0,0,0,63.13,66l5.66,5.66a2,2,0,0,0,1.41.58,2,2,0,0,0,1.42-3.41Z" />
      <path d="M111.21,108.38a2,2,0,1,0-2.83,2.83l5.66,5.66a2,2,0,1,0,2.83-2.83Z" />
      <path d="M109.8,72.2a2,2,0,0,0,1.41-.58L116.87,66A2,2,0,1,0,114,63.13l-5.66,5.66a2,2,0,0,0,1.42,3.41Z" />
      <path d="M68.79,108.38,63.13,114A2,2,0,1,0,66,116.87l5.66-5.66a2,2,0,1,0-2.83-2.83Z" />
    </svg>
  </svg>
);
export default LightSensorSvg;
