import React, { Component } from "react";
import { toast } from "react-toastify";
import { addOwner, removeOwner, removeInstaller, removeOperator } from "../services/userService";
import {
  findSystemBySerialNumber,
  getSystem,
  getSystemInfo,
} from "../services/systemService";
import { setJwt } from "../services/httpService";
import { getUser } from "../services/userService";
import { getCompanyUsers } from "../services/companyService";
import CommForm from "./sfCommission";
import BackGroundRectangle from "../components/backgroundRect";

class UninstallBoard extends Component {
  state = {
    systemId: "",
    serialNumber: "",
    macAddress: "",
    message: "",
    status: "",
    handleId: "",
    systems: [],
  };

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    const loggedInUser = (await getUser()).data.data;
    console.log("Logged in user's Info:", loggedInUser);
    const userIsAdmin = loggedInUser.admin;
    const userCompany = loggedInUser.company;
    const userName = loggedInUser.username;
    console.log("Use Name:", userName);
    console.log("Is User Admin", userIsAdmin);
    console.log("User's Company: ", userCompany);
    this.setState({ userCompany, userName });
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const systemId = (await findSystemBySerialNumber(this.state.serialNumber))
      .data.data.system_id;
    console.log("Found Master Board System ID", systemId);

    const systemInfo = (await getSystem(systemId)).data.data[0].systemID;
    console.log("System", systemInfo);

    const macAddress = systemInfo.MAC;
    const handleId = systemInfo.id;
    const serialNumber = systemInfo.SN;

    const info = await getSystemInfo(handleId);
    const status = info.data.data.status;
    console.log("Status", status);

    this.setState({ status, macAddress, handleId, systemId, serialNumber });
  };

  removeUsers = async (e) => {
    e.preventDefault();

    const systemReq = (await getSystem(this.state.systemId)).data.data[0]
    const systemPeople = systemReq.systemPeople

    if (systemPeople){

        if (systemPeople.owner){
            for (let owner of systemPeople.owner){
                await removeOwner(owner.username, this.state.serialNumber)
            }
        }
        if (systemPeople.installer){
            for (let installer of systemPeople.installer){
                await removeInstaller(installer.username, this.state.serialNumber)
            }
        }
        if (systemPeople.operator){
            for (let operator of systemPeople.operator){
                await removeOperator(operator.username, this.state.serialNumber)
            }
        }
    }
    toast.success("SimplGateway uninstalled.");
    // await addOwner(this.state.userName, this.state.serialNumber);
    window.location = `/Companies/systems/${this.state.userCompany}`;
  };

  handleChange = (e) => {
    //  const serialNumber = { ...this.state.serialNumber };
    const serialNumber = e.currentTarget.value;
    this.setState({ serialNumber });
  };

  render() {
    const status = this.state.status;
    if (status === "INSTALLED" || status === "ALIVE") {
      return (
        <React.Fragment>
          <div
            className="form-group"
            style={{
              width: "350px",
              position: "fixed",
              left: "50%",
              transform: "translateX(-50%)",
              marginTop: "150px",
              marginBottom: "150px",
              zIndex: 1,
            }}
          >
            <p className="basicParagraph">
                This gateway is installed. Click to uninstall.
            </p>

            <button
              className="btn btn-danger btn-sm "
              onClick={this.removeUsers}
            >
                Uninstall
            </button>
          </div>
          <BackGroundRectangle />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div
          className="form-group"
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            zIndex: 1,
          }}
        >
          <form onSubmit={this.handleSubmit}>
            <p className="basicParagraph">Uninstall a system.</p>
            <hr />
            <p className="basicParagraph">Input serial number for SimplGateway:</p>
            <input
              value={this.state.serialNumber}
              onChange={this.handleChange}
              id="serialNumber"
              type="text"
              className="form-control2"
            />

            <button
              className="basicButton"
              style={{ width: "200px", marginTop: "10px" }}
            >
              Search
            </button>
          </form>
          <p />
          {this.state.message !== "" && (
            <div>
              <p className="message text-danger">
                {this.state.message} {this.state.macAddress}
              </p>
              <CommForm
                systemId={this.state.systemId}
                serialNumber={this.state.serialNumber}
                macAddress={this.state.macAddress}
              />
            </div>
          )}
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default UninstallBoard;
