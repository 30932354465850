import React, { Component } from "react";

import * as echarts from "echarts/dist/echarts.js";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactExport from "react-data-export";
import "./dashboard.css";
import {
  getSystemIntervalData,
  getSystemInfo,
  getSystemIDfromHandle,
  getSystem,
  getSystemDailyData,
  getSystemBasics,
  setSystemSignals,
  initSystemUpdate,
  getSystemLatestData,
  ws_getSystemLatestData,
} from "../services/systemService";
import zIndex from "@material-ui/core/styles/zIndex";
import { faCommentsDollar } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import { webSocketMode } from "../config.json"; //0: http; 1:websocket
import { isNullOrUndefined } from "joi-browser";

class GraphDaily extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    this.chart = echarts.init(document.getElementById("graph-area-interval"));
    this.generateGraph();
    this.chart.setOption({
      xAxis: {
        data: this.props.calendarDay,
      },
      series: [
        {
          name: "PV (kWh)",
          data: this.props.pvDaily,
        },
        {
          name: "Grid (kWh)",
          data: this.props.gridDaily,
        },
        {
          name: "Building (kWh)",
          data: this.props.bldgDaily,
        },
      ],
    });
  }

  generateGraph() {
    var option = {
      legend: {
        show: false,
        type: "scroll",
        data: ["PV (kWh)", "Grid (kWh)", "Building (kWh)"],
        left: "5%",
        top: "5%",
        orient: "horizontal",
        align: "auto",
        padding: 10,
        textStyle: {
          fontStyle: "normal",
          fontSize: 10,
          fontWeight: "bold",
          color: "grey",
        },
        backgroundColor: "transparent",
        borderColor: "#ffffff",
        borderWidth: 1,
        boarderRadius: 0,
      },
      xAxis: {
        name: "",
        nameLocation: "end",
        nameGap: 50,
        type: "category",
        nameTextStyle: {
          show: true,
          fontStyle: "normal",
          fontSize: 16,
          fontWeight: "bolder",
          color: "green",
        },
        axisLabel: {
          fontStyle: "normal",
          fontSize: 8,
          fontWeight: "normal",
          color: "orange",
        },
        boundaryGap: false,
        data: this.props.date,
      },
      yAxis: {
        show: false,
        name: "Power (Watts)",
        nameLocation: "end",

        boundaryGap: [0, "10%"],
        type: "value",
        nameTextStyle: {
          fontStyle: "normal",
          fontSize: 16,
          fontWeight: "bolder",
          color: "green",
        },
        axisLabel: {
          fontStyle: "normal",
          fontSize: 10,
          fontWeight: "bold",
          color: "green",
        },
      },
      dataZoom: [
        {
          type: "slider",
          show: true,
          xAxisIndex: [0],
          start: 1,
          end: 100,
        },
        {
          type: "inside",
          xAxisIndex: [0],
          start: 1,
          end: 100,
        },
      ],
      series: [
        {
          name: "PV Interval Data (kWh)",
          type: "bar",
          symbol: "none",
          color: "orange",
          areaStyle: {
            normal: {
              color: "orange",
            },
          },
          data: this.props.pvDaily,
        },
        {
          name: "Grid Interval Data (kWh)",
          type: "bar",
          symbol: "none",
          color: "magenta",
          areaStyle: {
            normal: {},
          },
          data: this.props.gridDaily,
        },
        {
          name: "Building Interval Data (kWh)",
          type: "bar",
          symbol: "none",
          color: "green",
          areaStyle: {
            normal: {},
          },
          data: this.props.bldgDaily,
        },
      ],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
            precision: "2",
          },
        },
      },
    };
    this.chart.setOption(option);
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "100%",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "100px",
            marginBottom: "0px",
            paddingTop: "100px",
          }}
        >
          <div id="graph-area-interval" />
        </div>
      </React.Fragment>
    );
  }
}
export default GraphDaily;
