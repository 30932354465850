import React from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";

import Form from "./common/form";
import {
  getSystem,
  getSystemLimits,
  setSystemLimits,
  getSystemIDfromHandle,
} from "../services/systemService";
import BackGroundRectangle from "../components/backgroundRect";

class LimitsForm extends Form {
  state = {
    systemID: "",
    systemName: "",
    getSystem,

    data: {
      inverterType: "0",
      maxSolarInvPower: "240.0",
      minSolarInvPower: "50.0",
      solarMppV: "32.0",
      solarInverterTimeOut: "60000",
      tSenseMaxTemp: "130",
      tSenseMinTemp: "-20",
      heatSinkMaxTemp: "140",
      heatSinkMinTemp: "-20",
      maxCellVoltage: "3.65",
      minCellVoltage: "2.5",
      maxCellDeltaV: "0.1",
      maxPackVoltage: "3.611",
      battNotFullV: "3.333",
      battNotEmptyV: "3.222",
      minPackVoltage: "2.777",
      maxBatteryPower: "300",
      minSOC: "20",
    },

    optionsInverter: [
      { _id: "0", name: "Micro Inverters" },
      { _id: "1", name: "Optimizers" },
      { _id: "2", name: "String Inverter" },
    ],
    optionsVoltageMax: [
      { _id: "0", name: "24 - 40 V" },
      { _id: "1", name: "27 - 44 V" },
      { _id: "2", name: "37 - 54 V" },
    ],
    errors: {},
  };

  schema = {
    inverterType: Joi.string().label("Inverter Type"),
    maxSolarVoltage: Joi.string().label("Select Solar HW Voltage Range:"),
    maxSolarInvPower: Joi.number()
      .required()
      .min(100.0)
      .max(300.0)
      .label("Max Inverter Power (Watts)"),
    minSolarInvPower: Joi.number()
      .required()
      .min(10.0)
      .max(100.0)
      .label("Rely on Inverter MPPT down to this power level (Watts)"),
    solarMppV: Joi.number()
      .required()
      .min(10.0)
      .max(65.0)
      .label("MPPT Voltage of the solar panel (Volts)"),
    solarInverterTimeOut: Joi.number()
      .required()
      .min(5000)
      .max(120000)
      .label(
        "Give up on inverter's ability to set MPPT voltage after this time (mS)"
      ),

    tSenseMaxTemp: Joi.number()
      .required()
      .min(90)
      .max(150)
      .label("Max Battery Temperature (F)"),
    tSenseMinTemp: Joi.number()
      .required()
      .min(-25)
      .max(32)
      .label("Min Battery Temperature (F)"),
    heatSinkMaxTemp: Joi.number()
      .required()
      .min(0)
      .max(200)
      .label("Max Heat Sink Temperature (F)"),
    heatSinkMinTemp: Joi.number()
      .required()
      .min(-25)
      .max(100)
      .label("Min Heat Sink Temperature (F)"),
    maxCellVoltage: Joi.number()
      .required()
      .min(3.1)
      .max(3.6)
      .label("Max Cell Voltage (V)"),
    minCellVoltage: Joi.number()
      .required()
      .min(2.5)
      .max(2.8)
      .label("Min Cell Voltage (V)"),
    maxCellDeltaV: Joi.number()
      .required()
      .min(0.1)
      .max(0.5)
      .label("Max Delta Cell Voltage (V)"),
    maxPackVoltage: Joi.number()
      .required()
      .min(3.3)
      .max(3.4)
      .label("Max Pack Voltage (V)"),

    battNotFullV: Joi.number()
      .required()
      .min(3.1)
      .max(3.35)
      .label(
        "Battery Voltage has to be lower than this to be considered not full (V)"
      ),
    battNotEmptyV: Joi.number()
      .required()
      .min(3.0)
      .max(3.25)
      .label(
        "Battery Voltage has to be higher than this to be considered not empty (V)"
      ),

    minPackVoltage: Joi.number()
      .required()
      .min(3.0)
      .max(3.2)
      .label("Min Pack Voltage (V)"),
    maxBatteryPower: Joi.number()
      .required()
      .min(10.0)
      .max(350.0)
      .label("Max Battery Power (W)"),
    minSOC: Joi.number()
      .required()
      .min(1.0)
      .max(90.0)
      .label("Min State of Charge (%)"),
  };

  async componentDidMount() {
    const handleId = this.props.match.params.systemHandleID;
    const systemID = (await getSystemIDfromHandle(handleId)).data.data
      .system_id;
    const system = (await getSystem(systemID)).data.data[0];
    console.log("System Info", system);
    const systemName = system.systemID.name;
    const systemLimits = (await getSystemLimits(handleId)).data.data.limits;
    if (systemLimits) this.setState({ data: systemLimits });
    this.setState({ systemID });
    this.setState({ systemName });
  }

  doSubmit = async () => {
    await setSystemLimits(
      this.state.systemID,
      this.state.data.inverterType,
      this.state.data.maxSolarVoltage,
      this.state.data.maxSolarInvPower,
      this.state.data.minSolarInvPower,
      this.state.data.solarMppV,
      this.state.data.solarInverterTimeOut,
      this.state.data.tSenseMaxTemp,
      this.state.data.tSenseMinTemp,
      this.state.data.heatSinkMaxTemp,
      this.state.data.heatSinkMinTemp,
      this.state.data.maxCellVoltage,
      this.state.data.minCellVoltage,
      this.state.data.maxCellDeltaV,
      this.state.data.maxPackVoltage,
      this.state.data.battNotFullV,
      this.state.data.battNotEmptyV,
      this.state.data.minPackVoltage,
      this.state.data.maxBatteryPower,
      this.state.data.minSOC
    );
    toast.warning("Changes are saved but must be uploaded to take effect.");
    this.props.history.goBack();
    /* this.props.history.push(
      `/Companies/systems/${this.props.match.params.companyName}`
    );*/
  };

  handleInverterType = ({ currentTarget: input }) => {
    const inverterType = input.value;
    this.setState({ inverterType });
  };
  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "250px",
            zIndex: 1,
          }}
        >
          <p className="basicParagraph">
            Edit System Temperature, Voltage and State-of-charge Limits for:
          </p>

          <p className="basicParagraph">{this.state.systemName}</p>
          <form onSubmit={this.handleSubmit}>
            {this.renderSelect(
              "inverterType",
              "Inverter Type:",
              this.state.optionsInverter
            )}
            {this.renderSelect(
              "maxSolarVoltage",
              "Select Solar HW Voltage Range:",
              this.state.optionsVoltageMax
            )}
            {this.renderInput(
              "maxSolarInvPower",
              "Max Inverter Power (Watts)",
              "number"
            )}
            {this.renderInput(
              "minSolarInvPower",
              "Rely on Inverter MPPT down to this power level (Watts)",
              "number"
            )}
            {this.renderInput(
              "solarMppV",
              "MPPT Voltage of the solar panel (Volts)",
              "number"
            )}
            {this.renderInput(
              "solarInverterTimeOut",
              "Give up on inverter's ability to set MPPT voltage after this time (mS)",
              "number"
            )}

            {this.renderInput("tSenseMaxTemp", "Max Temperature (F)", "number")}
            {this.renderInput("tSenseMinTemp", "Min Temperature (F)", "number")}
            {this.renderInput(
              "heatSinkMaxTemp",
              "Max Heat Sink Temperature (F)",
              "number"
            )}
            {this.renderInput(
              "heatSinkMinTemp",
              "Min Heat Sink Temperature (F)",
              "number"
            )}
            {this.renderInput(
              "maxCellVoltage",
              "Max Cell Voltage (V)",
              "number"
            )}
            {this.renderInput(
              "minCellVoltage",
              "Min Cell Voltage (V)",
              "number"
            )}
            {this.renderInput(
              "maxCellDeltaV",
              "Max Delta Cell Voltage (V)",
              "number"
            )}
            {this.renderInput(
              "maxPackVoltage",
              "Max Pack Voltage (V)",
              "number"
            )}
            {this.renderInput(
              "battNotFullV",
              "Battery Voltage has to be lower than this to be considered not full (V)",
              "number"
            )}
            {this.renderInput(
              "battNotEmptyV",
              "Battery Voltage has to be higher than this to be considered not empty (V)",
              "number"
            )}
            {this.renderInput(
              "minPackVoltage",
              "Min Pack Voltage (V)",
              "number"
            )}
            {this.renderInput(
              "maxBatteryPower",
              "Max Battery Power (W)",
              "number"
            )}
            {this.renderInput("minSOC", "Min State of Charge (%)", "number")}
            {this.renderButton("Save Parameters")}
          </form>
          <h6> </h6>
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default LimitsForm;
