import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import { getUser, updatePassword } from "../services/userService";
import { setJwt } from "../services/httpService";
import BackGroundRectangle from "../components/backgroundRect";

class EditPswd extends Form {
  state = {
    data: {
      password: ""
    },
    errors: {}
  };

  schema = {
    password: Joi.string()
      .min(8)
      .label("Password")
  };

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    console.log("Web Token", jwt);
    setJwt(jwt);
    const userdata = await getUser();
    this.setState({ data: userdata.data.data });
  }

  doSubmit = async () => {
    try {
      const { data } = this.state;
      const jwt = localStorage.getItem("token");
      setJwt(jwt);
      await updatePassword(data.password);
      window.location = "/login";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors: errors });
      }
    }
  };

  render() {
    console.log("User in edit User form", this.props.match.params.id);
    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1
          }}
        >
          <p className="basicParagraph">Update Password:</p>
          <form onSubmit={this.handleSubmit}>
            {this.renderInput("password", "Password", "password")}
            {this.renderButton("Save New Password")}
          </form>
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default EditPswd;
