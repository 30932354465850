import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./dashboard.css";
import "../index.css";
import "react-datepicker/dist/react-datepicker.css";

function MainMenu(props) {
  return (
    <React.Fragment>
      <p className="systemClock" style={{ marginBottom: "0px" }}>
        <FontAwesomeIcon
          style={{ marginLeft: "0", marginRight: "3px" }}
          icon="clock"
          size="lg"
          color="white"
          title="Local System Time"
        />
        {props.systemHumanTime}

        {props.commMode === 0 && (
          <FontAwesomeIcon
            style={{ marginLeft: "20px", marginRight: "3px" }}
            icon="wifi"
            size="lg"
            color="red"
            title="Wifi Signal Strength"
          />
        )}
        {(props.commMode === 1 || props.commMode === 3) && (
          <FontAwesomeIcon
            style={{ marginLeft: "20px", marginRight: "3px" }}
            icon="wifi"
            size="lg"
            color="white"
            title="Wifi Signal Strength"
          />
        )}
        {props.commMode === 2 && (
          <FontAwesomeIcon
            style={{ marginLeft: "20px", marginRight: "3px" }}
            icon="signal"
            size="lg"
            color="white"
            title="Wifi Signal Strength"
          />
        )}
        {props.wifiSignalStrength}

        <FontAwesomeIcon
          style={{ marginLeft: "20px", marginRight: "3px" }}
          icon="square"
          size="lg"
          color="white"
          title="# of SimplBoxes"
        />

        {props.slaveCount}

        {props.loopCount % 2 === 0 && (
          <FontAwesomeIcon
            style={{ marginLeft: "20px", marginRight: "3px" }}
            icon="heartbeat"
            size="lg"
            color="white"
            title="System Heartbeat"
          />
        )}

        {props.loopCount % 2 === 1 && (
          <FontAwesomeIcon
            style={{ marginLeft: "20px", marginRight: "3px" }}
            icon="heartbeat"
            size="lg"
            color="#c5c2c2"
            title="System Heartbeat"
          />
        )}

        {props.outageFlag === 0 &&
          props.loopCount % 2 === 0 &&
          props.acCoupledBackUp === 1 && (
            <FontAwesomeIcon
              style={{ marginLeft: "20px", marginRight: "3px" }}
              icon="charging-station"
              size="lg"
              color="white"
              title="On Grid"
            />
          )}
        {props.outageFlag === 0 &&
          props.loopCount % 2 === 1 &&
          props.acCoupledBackUp === 1 && (
            <FontAwesomeIcon
              style={{ marginLeft: "20px", marginRight: "3px" }}
              icon="charging-station"
              size="lg"
              color="#c5c2c2"
              title="On Grid"
            />
          )}

        {props.outageFlag === 1 && props.acCoupledBackUp === 1 && (
          <FontAwesomeIcon
            style={{ marginLeft: "20px", marginRight: "3px" }}
            icon="charging-station"
            size="lg"
            color="#c5c2c2"
            title="Power Outage"
          />
        )}

        {props.loopCount % 2 === 0 && props.moduleDataValid === 1 && (
          <FontAwesomeIcon
            style={{ marginLeft: "20px", marginRight: "3px" }}
            icon="network-wired"
            size="lg"
            color="white"
            title="Module Connected"
          />
        )}
        {props.loopCount % 2 === 1 && props.moduleDataValid === 1 && (
          <FontAwesomeIcon
            style={{ marginLeft: "20px", marginRight: "3px" }}
            icon="network-wired"
            size="lg"
            color="#c5c2c2"
            title="Module Connected"
          />
        )}
      </p>
      {props.inCompleteData === 1 && (
        <p
          className="systemClock"
          style={{ paddingTop: "2px", fontSize: "9px" }}
        >
          Warning: Missing data points!
        </p>
      )}
    </React.Fragment>
  );
}
export default MainMenu;
