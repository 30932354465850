import React, { Component } from "react";
import { toast } from "react-toastify";
import { addOwner } from "../services/userService";
import {
  getSystems,
  findSystemBySerialNumber,
  getSystem,
  getSystemInfo,
  uninstallSystem,
} from "../services/systemService";
import CommForm from "./sfCommission";
import BackGroundRectangle from "../components/backgroundRect";

class InstallProduct extends Component {
  state = {
    systemId: "",
    serialNumber: "",
    macAddress: "",
    message: "",
    status: "",
    handleId: "",
    systems: [],
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const numOfSystems = (await getSystems()).data.data.length;
    const systems = (await getSystems()).data;
    console.log("Systems in the database", systems);
    console.log("Number of Systems", numOfSystems);
    let i = 0;
    let numOfFoundSystems = 0;
    let foundSystem;
    for (i = 0; i < numOfSystems; i++) {
      if (systems.data[i].systemBasics !== null) {
        if (systems.data[i].systemBasics.productSN !== undefined) {
          if (
            systems.data[i].systemBasics.productSN === this.state.serialNumber
          ) {
            numOfFoundSystems++;
            foundSystem = (await getSystems()).data.data[i];
          }
        }
      }
    }
    console.log("Found System", foundSystem);
    if (numOfFoundSystems === 0) {
      toast.error("Error: Not a Valid PRODUCT serial number.");
      toast.info("Consider registering as a BOARD.");
    }
    if (numOfFoundSystems >= 2)
      toast.error("Error: Duplicate serial numbers in the system.");
    if (numOfFoundSystems === 1) {
      const macAddress = foundSystem.systemMAC;
      const handleId = foundSystem.systemHandleID;
      const serialNumberMaster = foundSystem.systemSN;
      const systemId = (await findSystemBySerialNumber(serialNumberMaster)).data
        .data.system_id;

      const info = await getSystemInfo(handleId);
      console.log("Info", info);
      const status = info.data.data.status;
      this.setState({
        message: "This serial number is valid.",
        status,
        macAddress,
        handleId,
        systemId,
        serialNumberMaster,
      });
    }
  };

  handleAddAdminUser = async (e) => {
    e.preventDefault();
    await addOwner(this.state.userName, this.state.serialNumber);
    window.location = "/Companies/systems/${this.state.userCompany}";
  };

  handleChange = (e) => {
    //  const serialNumber = { ...this.state.serialNumber };
    const serialNumber = e.currentTarget.value;
    this.setState({ serialNumber });
  };

  render() {
    const status = this.state.status;
    if (status === "INSTALLED" || status === "ALIVE") {
      return (
        <React.Fragment>
          <div
            className="form-group"
            style={{
              width: "350px",
              position: "fixed",
              left: "50%",
              transform: "translateX(-50%)",
              marginTop: "150px",
              marginBottom: "150px",
              zIndex: 1,
            }}
          >
            <p className="basicParagraph">This product has been installed.</p>
            <p className="basicParagraph">
              Click here to be added as an Admin User to this system:
            </p>

            <button
              className="btn btn-info btn-sm"
              onClick={this.handleAddAdminUser}
            >
              Add Me As a an Admin
            </button>
          </div>
          <BackGroundRectangle />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div
          className="form-group"
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            zIndex: 1,
          }}
        >
          <form onSubmit={this.handleSubmit}>
            <label className="basicParagraph" htmlFor="">
              Input the master unit's serial number:
            </label>
            <input
              value={this.state.serialNumber}
              onChange={this.handleChange}
              id="serialNumber"
              type="text"
              className="form-control2"
            />

            <button
              className="basicButton"
              style={{ width: "200px", marginTop: "10px" }}
            >
              Search for System
            </button>
          </form>
          <p />
          {this.state.message !== "" && (
            <div>
              <p className="message text-danger">
                {this.state.message} {this.state.macAddress}
              </p>
              <CommForm
                systemId={this.state.systemId}
                serialNumber={this.state.serialNumber}
                macAddress={this.state.macAddress}
              />
            </div>
          )}
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default InstallProduct;
