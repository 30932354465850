import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./dashboard.css";
import "../index.css";
import "react-datepicker/dist/react-datepicker.css";

function BasicMainMenu(props) {
  return (
    <React.Fragment>
      <p className="systemClock" style={{ marginBottom: "0px" }}>
        {props.systemName}
        <FontAwesomeIcon
          style={{ marginLeft: "25px", marginRight: "3px" }}
          icon="clock"
          size="lg"
          color="white"
          title="Local System Time"
        />
        {props.systemHumanTime}
      </p>
    </React.Fragment>
  );
}
export default BasicMainMenu;
