import React from "react";
import Joi from "joi-browser";

import Form from "./common/form";
import {
  getSystem,
  getSystemControls,
  setSystemControls,
  getSystemIDfromHandle,
} from "../services/systemService";
import BackGroundRectangle from "../components/backgroundRect";

class LoadsControlsForm extends Form {
  state = {
    systemID: "",
    systemSN: "",
    systemName: "",
    data: {
      modeOfOperation: "1",
      touStart: "17.0",
      touStop: "21",
      fiveDaysOr7: "1",
      onPeakMonthsStart: "5",
      onPeakMonthStop: "11",
      powerReduction: "3.0",
      maxGridPower: "100.0",
      minGridPower: "0.0",
    },
    optionsModes: [
      { _id: "1", name: "Time-of-Use" },
      { _id: "2", name: "Demand Shaving" },
      { _id: "3", name: "Self-Supply" },
      { _id: "4", name: "PV Smoothing" },
    ],
    optionDays: [
      { _id: "1", name: "Weekdays Only" },
      { _id: "2", name: "Everyday" },
    ],

    optionsMonths: [
      { _id: "1", name: "January" },
      { _id: "2", name: "February" },
      { _id: "3", name: "March" },
      { _id: "4", name: "April" },
      { _id: "5", name: "May" },
      { _id: "6", name: "June" },
      { _id: "7", name: "July" },
      { _id: "8", name: "August" },
      { _id: "9", name: "September" },
      { _id: "10", name: "October" },
      { _id: "11", name: "November" },
      { _id: "12", name: "December" },
    ],
    errors: {},
  };

  schema = {
    modeOfOperation: Joi.string().label("Mode of Operation:  DS, SS, TOU, PVS"),
    touStart: Joi.number()
      .required()
      .label("TOU On-Pk Start Time (24-hr format)"),
    touStop: Joi.number().required().label("TOU On-Pk End Time (24-hr format)"),
    fiveDaysOr7: Joi.string().label("Weekdays or everyday?"),
    onPeakMonthsStart: Joi.string().label("On-peak Start Month"),
    onPeakMonthStop: Joi.string().label("Off-peak Start Month"),
    powerReduction: Joi.number()
      .min(0.0)
      .max(1000.0)
      .label("Demand Shaving Power Reduction in (kW)"),
    maxGridPower: Joi.number()
      .min(0.0)
      .max(1000.0)
      .label("Max Grid Power for PV Smoothing in (kW)"),
    minGridPower: Joi.number()
      .min(0.0)
      .max(1000.0)
      .label("Min Grid Power for Self-Supply in (kW)"),
  };

  async componentDidMount() {
    console.log("In System Loads Controls");
    const handleId = this.props.match.params.systemHandleID;
    const systemID = (await getSystemIDfromHandle(handleId)).data.data
      .system_id;
    const system = (await getSystem(systemID)).data.data[0];
    const systemName = system.systemID.name;
    console.log("System Info", system);
    const systemControls = (await getSystemControls(handleId)).data.data
      .controls;
    if (systemControls) this.setState({ data: systemControls });
    this.setState({ systemID });
    this.setState({ systemName });
  }

  doSubmit = async () => {
    /*await setSystemControls(
      this.state.systemID,
      this.state.data.modeOfOperation,
      this.state.data.touStart,
      this.state.data.touStop,
      this.state.data.fiveDaysOr7,
      this.state.data.onPeakMonthsStart,
      this.state.data.onPeakMonthStop,
      this.state.data.powerReduction,
      this.state.data.maxGridPower,
      this.state.data.minGridPower
    );*/
    //this.props.history.push(
    //  `/Companies/systems/${this.props.match.params.companyName}`
    //);
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "350px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150",
            zIndex: 1,
          }}
        >
          <p className="basicParagraph">
            Edit Load Control Parameters for {this.state.systemName}:
          </p>
          <form onSubmit={this.handleSubmit}>
            {this.renderSelect(
              "modeOfOperation",
              "Mode of Operation",
              this.state.optionsModes
            )}
            {this.renderInput(
              "touStart",
              "TOU On-Pk Start Time (24-hr format)"
            )}
            {this.renderInput("touStop", "TOU On-Pk End Time (24-hr format)")}
            {this.renderSelect(
              "fiveDaysOr7",
              "Five days a week or everyday?",
              this.state.optionDays
            )}
            {this.renderSelect(
              "onPeakMonthsStart",
              "On-peak Start Month",
              this.state.optionsMonths
            )}
            {this.renderSelect(
              "onPeakMonthStop",
              "Off-peak Start Month",
              this.state.optionsMonths
            )}
            {this.renderInput(
              "powerReduction",
              "Demand Shaving Power Reduction in (kW)"
            )}
            {this.renderInput(
              "maxGridPower",
              "Max Grid Power for PV Smoothing in (kW)"
            )}
            {this.renderInput(
              "minGridPower",
              "Min Grid Power for Self-Supply in (kW)"
            )}
            {this.renderButton("Save Parameters")}
          </form>
          <h6> </h6>
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default LoadsControlsForm;
