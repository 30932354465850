import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";

function LoraModal(props) {
  return (
    <Modal
      size="xl"
      style={{
        width: "850px",
        top: "150px",
        left: "300px",
      }}
      //backdrop={false}
      show={props.show}
    >
      <Modal.Body>
        <h6 style={{ color: "#777575", fontWeight: "bold" }}>
          System Sensors:
        </h6>

        <table
          className="table"
          style={{
            marginTop: "5px",
            width: "850px",
            marginRight: "0px",
            paddingRight: "0px",
            //maxWidth: "100% !important",
            //width: "100% !important",
          }}
        >
          <th style={{ margin: "0px", padding: "0px", width: "20px" }}>
            Device Type
          </th>
          <th style={{ marginLeft: "5px", paddingLeft: "5px", width: "70px" }}>
            Serial number
          </th>
          <th style={{ marginLeft: "5px", paddingLeft: "5px", width: "6px" }}>
            Age (sec.)
          </th>
          <th style={{ margin: "5px", padding: "5px", width: "6px" }}>
            MAX Age
          </th>
          <th style={{ marginLeft: "5px", padding: "5px", width: "50px" }}>
            Batt V
          </th>
          <th style={{ marginLeft: "5px", padding: "5px", width: "50px" }}>
            Temp. (F)
          </th>
          <th style={{ marginLeft: "5px", padding: "5px", width: "50px" }}>
            RH (%)
          </th>
          <th style={{ marginLeft: "5px", padding: "5px", width: "50px" }}>
            Lux
          </th>
          <th style={{ marginLeft: "5px", padding: "5px", width: "50px" }}>
            Rth (*F)
          </th>

          <th style={{ marginLeft: "5px", padding: "5px", width: "50px" }}>
            D.O.S.
          </th>
          <th style={{ marginLeft: "5px", padding: "5px", width: "50px" }}>
            RSSI
          </th>
          <th style={{ marginLeft: "5px", padding: "5px", width: "50px" }}>
            MAC
          </th>
          <th style={{ marginLeft: "5px", padding: "5px", width: "50px" }}>
            FW Version
          </th>
          {props.loraDevices.map((loraDevice) => (
            <tr
              key={loraDevice.id}
              style={{ margin: "0px", padding: "0px", height: "10px" }}
            >
              <td style={{ margin: "0px", padding: "0px", height: "8px" }}>
                {loraDevice.loraType}
              </td>

              <td style={{ margin: "0px", padding: "0px", height: "10px" }}>
                {loraDevice.loraSN}
              </td>

              <td style={{ marginLeft: "0px", padding: "0px", height: "6px" }}>
                {loraDevice.loraDataAge}
              </td>

              <td style={{ margin: "0px", padding: "0px", height: "6px" }}>
                {loraDevice.loraMaxDataAge}
              </td>

              <td
                style={{
                  marginLeft: "0px",
                  padding: "0px",
                  width: "20px",
                  height: "10px",
                }}
              >
                --
              </td>

              {loraDevice.loraType === "Temp." && (
                <td
                  style={{
                    margin: "0px",
                    padding: "0px",
                    width: "20px",
                    height: "10px",
                  }}
                >
                  {((loraDevice.loraTemp / 100) * 1.8 + 32).toFixed(1)}
                </td>
              )}
              {loraDevice.loraType === "Temp." && (
                <td
                  style={{
                    margin: "0px",
                    padding: "0px",
                    width: "20px",
                    height: "10px",
                  }}
                >
                  {(loraDevice.loraHumidity / 100).toFixed(1)}
                </td>
              )}
              {loraDevice.loraType !== "Temp." && (
                <td
                  style={{
                    margin: "0px",
                    padding: "0px",
                    width: "20px",
                    height: "10px",
                  }}
                >
                  --
                </td>
              )}
              {loraDevice.loraType !== "Temp." && (
                <td
                  style={{
                    margin: "0px",
                    padding: "0px",
                    width: "20px",
                    height: "10px",
                  }}
                >
                  --
                </td>
              )}
              {loraDevice.loraType === "Temp." && (
                <td
                  style={{
                    margin: "0px",
                    padding: "0px",
                    width: "20px",
                    height: "10px",
                  }}
                >
                  {loraDevice.loraLux}
                </td>
              )}
              {loraDevice.loraType !== "Temp." && (
                <td
                  style={{
                    margin: "0px",
                    padding: "0px",
                    width: "20px",
                    height: "10px",
                  }}
                >
                  --
                </td>
              )}
              {loraDevice.loraType === "Temp." && (
                <td
                  style={{
                    margin: "0px",
                    padding: "0px",
                    width: "20px",
                    height: "10px",
                  }}
                >
                  {(loraDevice.loraRth / 100).toFixed(1)}
                </td>
              )}
              {loraDevice.loraType !== "Temp." && (
                <td
                  style={{
                    margin: "0px",
                    padding: "0px",
                    width: "20px",
                    height: "10px",
                  }}
                >
                  --
                </td>
              )}

              {loraDevice.loraType === "Temp." && (
                <td
                  style={{
                    margin: "0px",
                    padding: "0px",
                    width: "20px",
                    height: "10px",
                  }}
                >
                  {loraDevice.loraDoorOpenCount}
                </td>
              )}
              {loraDevice.loraType !== "Temp." && (
                <td
                  style={{
                    margin: "0px",
                    padding: "0px",
                    width: "20px",
                    height: "10px",
                  }}
                >
                  --
                </td>
              )}

              <td
                style={{
                  margin: "0px",
                  padding: "0px",
                  width: "20px",
                  height: "10px",
                }}
              >
                {loraDevice.loraRSSI}
              </td>

              <td
                style={{
                  margin: "0px",
                  padding: "0px",
                  width: "50px",
                  height: "10px",
                }}
              >
                {loraDevice.loraMAC}
              </td>

              <td
                style={{
                  margin: "0px",
                  padding: "0px",
                  width: "190px",
                  height: "10px",
                }}
              >
                {loraDevice.fwVersion}
              </td>
            </tr>
          ))}
        </table>
      </Modal.Body>
    </Modal>
  );
}
export default LoraModal;
