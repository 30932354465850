import React, { Component } from "react";

import "./dashboard.css";
import "../index.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";

function SimplVuNumbers(props) {
  return (
    <React.Fragment>
      <p
        style={{
          color: "orange",
          fontWeight: "bold",
          fontSize: "9px",
          textAlign: "center",
          paddingTop: "5px",
          marginBottom: "1px",
          position: "fixed",
          transform: "translate(20px, -50px)",
        }}
      >
        Energy Independence
      </p>
      <p
        style={{
          color: "orange",
          fontWeight: "bold",
          fontSize: "16px",
          textAlign: "center",
          position: "fixed",
          transform: "translate(45px, -30px)",
        }}
      >
        {" "}
        {props.energyIndependence}%
      </p>
      {!props.dataTimeInvalid && props.showNumbers && props.bSVU > 0 && (
        <div
          style={{
            position: "fixed",
            transform: "translate(100px, 136px)",
          }}
        >
          <button
            filter="drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: "white",
              borderRadius: "25px",
              borderColor: "orange",
              opacity: "1.0",
              boxShadow: "3px 5px 2px rgb(0 0 0 / 0.4)",
              zIndex: 2,
            }}
          >
            <p
              style={{
                color: "orange",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                paddingTop: "10px",
                marginBottom: "1px",
              }}
            >
              {props.bSVU}
            </p>

            <p
              style={{
                color: "orange",
                fontWeight: "normal",
                fontSize: "9px",
                textAlign: "center",
              }}
            >
              {" "}
              {props.bSVUPowerUnits}
            </p>
          </button>
        </div>
      )}

      {!props.dataTimeInvalid && props.showNumbers && props.batteryPower > 0 && (
        <div
          style={{
            position: "fixed",
            transform: "translate(15px, 45px)",
          }}
        >
          <button
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: "white",
              borderRadius: "25px",
              borderColor: "dodgerblue",
              opacity: "1.0",
              boxShadow: "3px 5px 2px rgb(0 0 0 / 0.4)",
              zIndex: 2,
            }}
          >
            <p
              style={{
                color: "dodgerblue",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                paddingTop: "10px",
                marginBottom: "1px",
              }}
            >
              {props.aSVU}
            </p>

            <p
              style={{
                color: "dodgerblue",
                fontWeight: "normal",
                fontSize: "9px",
                textAlign: "center",
              }}
            >
              {props.aSVUPowerUnits}
            </p>
          </button>
        </div>
      )}

      {!props.dataTimeInvalid && props.showNumbers && props.batteryPower < 0.0 && (
        <div
          style={{
            position: "fixed",
            transform: "translate(4px, 270px)",
            zIndex: 2,
          }}
        >
          <button
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: "white",
              borderRadius: "25px",
              borderColor: "red",
              opacity: "1.0",
              boxShadow: "3px 5px 2px rgb(0 0 0 / 0.4)",
              zIndex: 2,
            }}
          >
            <p
              style={{
                color: "red",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                paddingTop: "10px",
                marginBottom: "1px",
              }}
            >
              {props.dSVU}
            </p>

            <p
              style={{
                color: "red",
                fontWeight: "normal",
                fontSize: "9px",
                textAlign: "center",
              }}
            >
              {props.dSVUPowerUnits}
            </p>
          </button>
        </div>
      )}

      {!props.dataTimeInvalid && props.showNumbers && props.eSVU > 0 && (
        <div
          style={{
            position: "fixed",
            transform: "translate(260px, 275px)",
            zIndex: 2,
          }}
        >
          <button
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: "white",
              borderRadius: "25px",
              borderColor: "magenta",
              opacity: "1.0",
              boxShadow: "3px 5px 2px rgb(0 0 0 / 0.4)",
              zIndex: 2,
            }}
          >
            <p
              style={{
                color: "magenta",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                paddingTop: "10px",
                marginBottom: "1px",
              }}
            >
              {props.eSVU}
            </p>

            <p
              style={{
                color: "magenta",
                fontWeight: "normal",
                fontSize: "9px",
                textAlign: "center",
              }}
            >
              {props.eSVUPowerUnits}
            </p>
          </button>
        </div>
      )}

      {!props.dataTimeInvalid && props.showNumbers && props.cSVU > 0 && (
        <div
          style={{
            position: "fixed",
            transform: "translate(250px, 45px)",
          }}
        >
          <button
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: "white",
              borderRadius: "25px",
              borderColor: "purple",
              opacity: "1.0",
              boxShadow: "3px 5px 2px rgb(0 0 0 / 0.4)",
              zIndex: 2,
            }}
          >
            <p
              style={{
                color: "purple",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                paddingTop: "10px",
                marginBottom: "1px",
              }}
            >
              {props.cSVU}
            </p>

            <p
              style={{
                color: "purple",
                fontWeight: "normal",
                fontSize: "9px",
                textAlign: "center",
              }}
            >
              {props.cSVUPowerUnits}
            </p>
          </button>
        </div>
      )}

      {!props.dataTimeInvalid && props.showNumbers && props.pHouse > 0 && (
        <div
          style={{
            position: "fixed",
            transform: "translate(220px, -15px)",
          }}
        >
          <button
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: "white",
              borderRadius: "25px",
              borderColor: "green",
              opacity: "1.0",
              boxShadow: "3px 5px 2px rgb(0 0 0 / 0.4)",
              zIndex: 2,
            }}
          >
            <p
              style={{
                color: "green",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                paddingTop: "10px",
                marginBottom: "1px",
              }}
            >
              {props.pHouse}
            </p>

            <p
              style={{
                color: "green",
                fontWeight: "normal",
                fontSize: "9px",
                textAlign: "center",
              }}
            >
              {props.pHousePowerUnits}
            </p>
          </button>
        </div>
      )}
    </React.Fragment>
  );
}
export default SimplVuNumbers;
