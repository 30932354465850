import React from "react";

const ReachInSvg = (props) => (
  <svg
    style={{ margin: "0px", padding: "0px", height: "90px", width: "200px" }}
  >
    <svg
      height="200px"
      width="auto"
      id="ICONS"
      version="1.1"
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <g>
        <text
          x="4"
          y="10"
          font-family="Verdana"
          font-size="6"
          font-weight="normal"
          fill={props.textColor}
        >
          Reach-In
        </text>
      </g>
    </svg>
    <svg
      height="70px"
      id="ICONS"
      version="1.1"
      viewBox="0 0 70 70"
      width="auto"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-69, 15)">
        <g>
          <rect height="4" fill="#B3E5FC" width="4" x="50" y="55" />
          <rect height="4" fill="#B3E5FC" width="4" x="10" y="55" />
          <rect height="4" fill="#B3E5FC" width="4" x="30" y="55" />
          <polygon
            points="57,51 57,55 54,55 50,55 34,55 30,55 14,55 10,55 7,55 7,51    "
            fill={props.strokeColor1}
          />
          <polygon
            points="57,47 57,51 7,51 7,47 24,47 40,47    "
            fill="#B3E5FC"
          />
          <polygon
            points="57,5 57,11 40,11 24,11 7,11 7,5    "
            fill="#B3E5FC"
          />
        </g>
        <g>
          <polygon
            points="     57,51 57,55 54,55 50,55 34,55 30,55 14,55 10,55 7,55 7,51 7,47 7,11 7,5 57,5 57,11 57,47    "
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
          />
          <polyline
            points="     54,55 54,59 50,59 50,55    "
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
          />
          <polyline
            points="     34,55 34,59 30,59 30,55    "
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
          />
          <polyline
            points="     14,55 14,59 10,59 10,55 "
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
          />
          <line
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="7"
            x2="57"
            y1="51"
            y2="51"
          />
          <polyline
            points="     7,47 24,47 40,47 57,47    "
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
          />
          <polyline
            points="     7,11 24,11 40,11 57,11    "
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
          />
          <line
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="40"
            x2="40"
            y1="11"
            y2="47"
          />
          <line
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="24"
            x2="24"
            y1="11"
            y2="47"
          />
        </g>
      </g>
    </svg>
  </svg>
);
export default ReachInSvg;
