import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Joi from "joi-browser";

import LineGraph from "./line-graph";
import LineGraph2 from "./line-graph2";
import LinePowerGraph from "./line-power-graph";
import ChartThermostat from "./chartThermostat";
import ChartOnTime from "./chartOnTime";
import ChartBldgPower from "./chartBldgPower";

import SimpleMenu from "./simplDropDownMenu";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactExport from "react-data-export";

import {
  getSystemLatestData,
  ws_getSystemLatestData,
  getSystemRealTimeData,
  getSystemBasics,
  getSystemIntervalData,
  getSystemInfo,
} from "../services/systemService";
import "./dashboard.css";
import "../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "./common/form";
import ProgressBar from "react-bootstrap/ProgressBar";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";

import { webSocketMode } from "../config.json"; //0: http; 1:websocket

class GPLineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      startDate: new Date(),
      stopDate: new Date(),
      //startTimeTR: new Date(),
      //endTimeTR: new Date(),
      companyName: "",
      chartPoints: "5",
      key: null,
      systemID: "",
      handleID: 0,
      pvPowerUnits: "Watts",
      batteryPowerUnits: "Watts",
      play: true,
      chartReadyFlag: true,
      minTemp: 200,
      maxTemp: -200,

      response: {
        deviceLedger: {
          realTime: {
            timestamp: 0,
            pvPower: 0,
            batteryPower: 0,
            inverterPower: 0,
            pvCurrent: 0,
            batteryCurrent: 0,
            inverterCurrent: 0,
            pvVoltage: 0,
            batteryVoltage: 0,
            inverterVoltage: 0,
          },
        },
      },
    };
    this.getSystemData = this.getSystemData.bind(this);

    this.handlePause = () => {
      this.setState({ play: false });
    };

    this.handlePlay = () => {
      this.setState({ play: true });
    };
  }

  async componentDidMount() {
    const modeBox = "Real-time";
    const handleID = this.props.match.params.systemHandleID;
    const companyName = this.props.match.params.companyName;
    const systemInfo = await getSystemInfo(handleID);
    console.log("System Info in Dashboard CDM", systemInfo);
    const systemStatus = systemInfo.data.data.status;
    console.log("system status", systemStatus);
    const nodeName = this.props.match.params.nodeName;
    const fileName = [
      companyName,
      this.props.match.params.systemName,
      nodeName,
    ].join("/");
    console.log("File Name: ", fileName);

    let systemBasics = await getSystemBasics(handleID);
    console.log("System Basics", systemBasics);
    const dataFrequency = parseInt(systemBasics.data.data.basics.dataFrequency);
    console.log("Data Frequency: ", dataFrequency);
    let tickNumber = 1000;
    switch (dataFrequency) {
      case 1:
        tickNumber = 1000;
        break;
      case 2:
        tickNumber = 5000;
        break;
      case 3:
        tickNumber = 15000;
        break;
      case 4:
        tickNumber = 60000;
        break;
      case 5:
        tickNumber = 300000;
        break;
      case 6:
        tickNumber = 900000;
        break;
      case 7:
        tickNumber = 1800000;
        break;
      case 8:
        tickNumber = 3600000;
        break;
      default:
        tickNumber = 1000;
        break;
    }
    this.setState({ tickNumber });
    console.log("Tick Number: ", tickNumber);
    let response = {};
    response = (await getSystemLatestData(handleID)).data.data[0];
    console.log("gpLineChart CDM Response: ", response);
    const commMode = response.data.commMode;
    console.log("Comm Mode", commMode);
    if (commMode === 2 && tickNumber < 15000) tickNumber = 15000;
    console.log("Agg. Graph: Tick Number", tickNumber);

    const pointsOnChart = parseInt(localStorage.getItem("pointsOnChart"));
    console.log("Points on Chart: ", pointsOnChart);
    // const endDate = response.data.timestamp; /*new Date().getTime();*/
    const endDate = new Date().getTime();
    console.log("End Date:", endDate);
    let startDate = endDate - pointsOnChart * tickNumber;
    console.log("Start and End Date:", startDate);

    let systemRealTimeData = (
      await getSystemRealTimeData(handleID, startDate, endDate)
    ).data.data;
    console.log("System RealTime Data", systemRealTimeData);
    const timeZone = systemRealTimeData[0].data.deviceLedger.realTime.TimeZone;

    const dataLength = systemRealTimeData.length;
    if (dataLength < pointsOnChart)
      startDate = startDate - (pointsOnChart - dataLength) * tickNumber;

    this.computeInitialData(tickNumber, startDate, endDate);
    this.timerID = setInterval(() => this.tick(), tickNumber);
    this.setState({
      handleID,
      companyName,
      nodeName,
      systemStatus,
      modeBox,
      fileName,
      timeZone,
    });
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  async computeInitialData(tickNumber, startDate, endDate) {
    let minTemp = this.state.minTemp;
    let maxTemp = this.state.maxTemp;
    const handleID = this.props.match.params.systemHandleID;
    console.log("Initial Start Time: ", startDate);
    console.log("Initial End Time: ", endDate);
    let systemIntervalRaw = (
      await getSystemIntervalData(handleID, startDate, endDate)
    ).data.data;
    console.log("Interval Data for this Date Range: ", systemIntervalRaw);
    let intervalDataLength = systemIntervalRaw.length;
    console.log("Interval Data Length: ", intervalDataLength);
    if (intervalDataLength === 0) {
      const startDateInterval = startDate - 15 * 60 * 1000; //Add 15 minutes
      systemIntervalRaw = (
        await getSystemIntervalData(handleID, startDateInterval, endDate)
      ).data.data;
      intervalDataLength = systemIntervalRaw.length;
    }

    let outsideTemp = [];
    let outsideTempTS = [];
    for (let j = 0; j < intervalDataLength; j++) {
      outsideTemp[j] = systemIntervalRaw[j].data.weatherTemp;
      outsideTempTS[j] = systemIntervalRaw[j].timestamp;
      console.log("Outside Temp Array: ", outsideTempTS[j], outsideTemp[j]);
    }
    const pointsOnChart = parseInt(localStorage.getItem("pointsOnChart"));
    let dataLength = 0;
    let systemRealTimeData = [];
    let newPoints = pointsOnChart;
    let loopCount = 0;
    let newEndDate = endDate;
    let newStartDate = startDate;
    do {
      newPoints = newPoints + 50;
      newEndDate = new Date().getTime();
      newStartDate = endDate - newPoints * 1000;
      systemRealTimeData = (
        await getSystemRealTimeData(handleID, newStartDate, newEndDate)
      ).data.data;
      console.log(
        "System RealTime Data with Start and End",
        systemRealTimeData
      );
      dataLength = systemRealTimeData.length;
      loopCount++;
      if (loopCount > 10) break;
    } while (dataLength < pointsOnChart + 5);
    let node = [];
    let nodes = [];
    let thisNodeInitial = [];

    let aggPower1Initial = [];
    let globalPriorityInitial = [];
    let dataTimeStamp = [];
    systemRealTimeData = systemRealTimeData.slice(-(pointsOnChart + 1));
    console.log("Truncated Data Array: ", systemRealTimeData);
    dataLength = systemRealTimeData.length;
    for (let i = 0; i < dataLength; i++) {
      aggPower1Initial[i] = (
        systemRealTimeData[i].data.deviceLedger.realTime.AggPower1 / 1000.0
      ).toFixed(1);
      globalPriorityInitial[i] =
        systemRealTimeData[i].data.deviceLedger.realTime.globalPriority;
      dataTimeStamp[i] = systemRealTimeData[i].timestamp;
      nodes[i] = systemRealTimeData[i].data.deviceLedger.Appliances;
      node[i] = nodes[i].filter(
        (node) =>
          node.nodeName === this.props.match.params.nodeName &&
          node.loadType === "HVAC w SimplTherm"
      );
      thisNodeInitial[i] = node[i][0];
    }
    console.log("Data Time Stamp:: ", dataTimeStamp);
    console.log("This Node Initial: ", thisNodeInitial);
    console.log("Power|priority", aggPower1Initial, globalPriorityInitial);
    this.setState({
      thisNodeInitial,
      initialStartDate: startDate,
      initialEndDate: endDate,
      aggPower1Initial,
      globalPriorityInitial,
    });
    let dateInitial = [];
    let relaySettingCool1 = [];
    let relaySettingCool2 = [];
    let relaySettingHeat1 = [];
    let relaySettingHeat2 = [];
    let tReturnInitial = [];
    let tSupplyInitial = [];
    let tSetInitial = [];
    let tOutsideInitial = [];
    let tRoomInitial = [];

    let dateForIndex = 0;
    let outsideTempSelected = 0;

    // const pointsOnChart = parseInt(localStorage.getItem("pointsOnChart"));

    if (thisNodeInitial) {
      const initialDataLength = thisNodeInitial.length;
      for (let i = 0; i < initialDataLength; i++) {
        dateForIndex = new Date(startDate + i * tickNumber);
        const dateForIndexTime = dateForIndex.getTime();
        let minTimeDelta = 100000000;
        for (let j = 0; j < intervalDataLength; j++) {
          if (Math.abs(outsideTempTS[j] - dateForIndexTime) < minTimeDelta) {
            minTimeDelta = Math.abs(outsideTempTS[j] - dateForIndexTime);
            outsideTempSelected = outsideTemp[j];
          }
        }
        dateInitial[i] = [
          dateForIndex.getUTCHours(),
          dateForIndex.getUTCMinutes(),
          dateForIndex.getUTCSeconds(),
        ].join(":");
        //console.log("dates: ", dateForIndexTime, dataTimeStamp[i]);
        relaySettingCool1[i] = thisNodeInitial[i].relaySetting & 0x0001;
        relaySettingCool2[i] =
          ((thisNodeInitial[i].relaySetting & 0x0004) >> 2) +
          (thisNodeInitial[i].relaySetting & 0x0001) +
          0.03;
        relaySettingHeat1[i] = (thisNodeInitial[i].relaySetting & 0x0002) >> 1;
        relaySettingHeat2[i] =
          ((thisNodeInitial[i].relaySetting & 0x0008) >> 3) +
          ((thisNodeInitial[i].relaySetting & 0x0002) >> 1) +
          0.03;

        tReturnInitial[i] = (thisNodeInitial[i].tReturn / 100).toFixed(1);
        tSupplyInitial[i] = (thisNodeInitial[i].tSupply / 100).toFixed(1);
        tSetInitial[i] = (thisNodeInitial[i].tSet / 100).toFixed(1);
        tRoomInitial[i] = (thisNodeInitial[i].roomTemp / 100).toFixed(1);
        tOutsideInitial[i] = (
          (outsideTempSelected - 273.15) * 1.8 +
          32.0
        ).toFixed(1);

        minTemp = Math.min(
          tReturnInitial[i],
          tSupplyInitial[i],
          tSetInitial[i],
          tOutsideInitial[i],
          tRoomInitial[i],
          minTemp
        );
        maxTemp = Math.max(
          tReturnInitial[i],
          tSupplyInitial[i],
          tSetInitial[i],
          tOutsideInitial[i],
          tRoomInitial[i],
          maxTemp
        );
      }
      this.setState({
        initialDataLength,
        dateInitial,
        relaySettingCool1,
        relaySettingCool2,
        relaySettingHeat1,
        relaySettingHeat2,
        tReturnInitial,
        tSupplyInitial,
        tSetInitial,
        tOutsideInitial,
        tRoomInitial,
        minTemp,
        maxTemp,
        chartReadyFlag: true,
      });
    }
  }

  async computeInterval(startDate, endDate) {
    let minTemp = this.state.minTemp;
    let maxTemp = this.state.maxTemp;
    console.log("Start Date", startDate);
    console.log("End Date", endDate);
    console.log("handleID", this.state.handleID);
    const systemIntervalDataInterim = await getSystemIntervalData(
      this.state.handleID,
      startDate,
      endDate
    );

    const systemIntervalData = systemIntervalDataInterim.data.data;
    console.log("System Interval Data Interim", systemIntervalDataInterim);
    console.log("System Interval Data", systemIntervalData);
    const recordLength = systemIntervalData.length;
    console.log("Record Length", recordLength);
    let appliances = [];
    let appliance = [];
    let thermostats = [];
    let thermostat = [];
    let dataDate = [];
    let avgReturnT = [];
    let avgSupplyT = [];
    let avgRoomT = [];
    let avgSetT = [];
    let weatherTemp = [];
    let weatherRH = [];

    let timeZone = 0;
    let onTimeCool1 = [];
    let onTimeCool2 = [];
    let onTimeHeat1 = [];
    let onTimeHeat2 = [];
    let onTimeFan = [];

    let onTime = [];
    let onTimeText = [];

    let orCount = [];
    let orCountText = [];

    let meter1Interval = [];
    for (let i = 0; i < recordLength; i++) {
      timeZone = systemIntervalData[0].data.TimeZone;
      console.log("System Interval Data", systemIntervalData[0]);
      weatherTemp[i] = (
        (systemIntervalData[i].data.weatherTemp - 273.15) * 1.8 +
        32.0
      ).toFixed(1);

      weatherRH[i] = systemIntervalData[i].data.weatherRH;
      dataDate[i] = systemIntervalData[i].data.localTimeStamp;
      appliances[i] = systemIntervalData[i].data.Appliances;
      appliance[i] = appliances[i].filter(
        (node) => node.nodeName === this.props.match.params.nodeName
      );
      console.log("Appliance: ", appliance[i]);
      if (appliance[i].length > 0) onTime[i] = appliance[i][0].onTime;
      thermostats[i] = systemIntervalData[i].data.Thermostats;
      thermostat[i] = thermostats[i].filter(
        (node) => node.nodeName === this.props.match.params.nodeName
      );
      avgReturnT[i] = (thermostat[i][0].avgReturnT / 100.0).toFixed(1);
      avgSupplyT[i] = (thermostat[i][0].avgSupplyT / 100.0).toFixed(1);
      avgRoomT[i] = (thermostat[i][0].avgRoomTemp / 100.0).toFixed(1);
      avgSetT[i] = (thermostat[i][0].avgSetTemp / 100.0).toFixed(1);

      minTemp = Math.min(
        weatherTemp[i],
        avgReturnT[i],
        avgSupplyT[i],
        avgRoomT[i],
        avgSetT[i],
        minTemp
      );
      // minTemp -= 5;
      maxTemp = Math.max(
        weatherTemp[i],
        avgReturnT[i],
        avgSupplyT[i],
        avgRoomT[i],
        avgSetT[i],
        maxTemp
      );
      //maxTemp += 5;
      onTimeCool1[i] = thermostat[i][0].onTimeCool1.toFixed(0);
      onTimeCool2[i] = thermostat[i][0].onTimeCool2.toFixed(0);
      onTimeHeat1[i] = thermostat[i][0].onTimeHeat1.toFixed(0);
      onTimeHeat2[i] = thermostat[i][0].onTimeHeat2.toFixed(0);
      onTimeFan[i] = thermostat[i][0].onTimeFan;

      meter1Interval[i] = systemIntervalData[i].data.meter1Interval.toFixed(0);
      orCount[i] = thermostat[i][0].orCount;

      if (onTime[i] === 1) {
        onTime[i] = 0;
        onTimeText[i] = "Yes";
      } else {
        onTime[i] = 1;
        onTimeText[i] = "No";
      }
      if (orCount[i] > 0) orCountText[i] = "Yes";
      else orCountText[i] = "No";
    }

    let excelDataSet = [0];
    let calendarDay = [];
    let timeOfDay = [];
    for (let i = 0; i < recordLength; i++) {
      calendarDay[i] = [
        new Date(dataDate[i] /* - timeZone * 3600*/ * 1000).getUTCMonth() + 1,
        new Date(dataDate[i] /*- timeZone * 3600*/ * 1000).getUTCDate(),
        new Date(dataDate[i] /* - timeZone * 3600*/ * 1000).getUTCFullYear(),
      ].join("/");

      timeOfDay[i] = [
        new Date(dataDate[i] /* - timeZone * 3600*/ * 1000).getUTCHours(),
        new Date(dataDate[i] /*- timeZone * 3600*/ * 1000).getUTCMinutes(),
        new Date(dataDate[i] /*- timeZone * 3600*/ * 1000).getUTCSeconds(),
      ].join(":");

      excelDataSet[i] = JSON.parse(
        JSON.stringify({
          fileName: this.state.fileName,
          Day: calendarDay[i],
          Time: timeOfDay[i],
          Occupied: onTimeText[i],
          ReturnT: parseFloat(avgReturnT[i]),
          SupplyT: parseFloat(avgSupplyT[i]),
          RoomT: parseFloat(avgRoomT[i]),
          SetT: parseFloat(avgSetT[i]),
          OutsideT: parseFloat(weatherTemp[i]),
          OutsideRH: parseFloat(weatherRH[i]),
          Cool1: onTimeCool1[i],
          Cool2: onTimeCool2[i],
          Heat1: onTimeHeat1[i],
          Heat2: onTimeHeat2[i],
          FanOn: onTimeFan[i],
          Power: parseFloat(meter1Interval[i]),
          OverRideCount: orCount[i],
          OverRide: orCountText[i],
        })
      );
    }
    this.setState({
      avgReturnT,
      avgSupplyT,
      avgRoomT,
      avgSetT,
      weatherTemp,
      weatherRH,
      dataDate,
      timeZone,
      maxTemp,
      minTemp,
      onTimeCool1,
      onTimeCool2,
      onTimeHeat1,
      onTimeHeat2,
      onTimeFan,
      meter1Interval,
      onTime,
      excelDataSet,
      chartReadyFlag: true,
    });
  }

  async getSystemData() {
    let minTemp = this.state.minTemp;
    let maxTemp = this.state.maxTemp;
    let response = {};
    console.log("Got to here with webSocketMode :", webSocketMode);
    if (webSocketMode) {
      response = ws_getSystemLatestData(this.props.match.params.systemHandleID);
      console.log("Websocket: Latest Data", response);
    } else {
      response = (
        await getSystemLatestData(this.props.match.params.systemHandleID)
      ).data.data[0];
      console.log("Http: Latest Data", response);
    }
    console.log("Latest Data", response);
    if (response !== undefined && response !== null) {
      const aggPower1 = (
        response.data.deviceLedger.realTime.AggPower1 / 1000.0
      ).toFixed(1);
      const globalPriority = response.data.deviceLedger.realTime.globalPriority;
      console.log("Power|priority", aggPower1, globalPriority);
      const dataTimeStamp = response.timestamp;
      console.log("Get System Data data Time Stamp:", dataTimeStamp);
      const node = response.data.deviceLedger.Appliances.filter(
        (node) => node.nodeName === this.props.match.params.nodeName
      );
      const thisNode = node[0];
      console.log("This Node", thisNode);
      const tReturn = (thisNode.tReturn / 100.0).toFixed(1);
      const tSupply = (thisNode.tSupply / 100.0).toFixed(1);
      const tSet = (thisNode.tSet / 100.0).toFixed(1);
      const tRoom = (thisNode.roomTemp / 100.0).toFixed(1);
      const relaySetting = thisNode.relaySetting;

      const endDate = new Date().getTime();
      const startDate = endDate - 15 * 60 * 1000;
      let systemIntervalRaw = (
        await getSystemIntervalData(
          this.props.match.params.systemHandleID,
          startDate,
          endDate
        )
      ).data.data;
      let outsideTemp = 0;
      if (systemIntervalRaw[0] !== null && systemIntervalRaw[0] !== undefined) {
        outsideTemp = (
          (systemIntervalRaw[0].data.weatherTemp - 273.15) * 1.8 +
          32.0
        ).toFixed(1);
      }

      if (this.state.modeBox === "Real-time") {
        minTemp = Math.min(tReturn, tSupply, tSet, tRoom, outsideTemp, minTemp);
        maxTemp = Math.max(tReturn, tSupply, tSet, tRoom, outsideTemp, maxTemp);
        this.setState({
          thisNode,
          relaySetting,
          tReturn,
          tSupply,
          tSet,
          tRoom,
          outsideTemp,
          minTemp,
          maxTemp,
          aggPower1,
          globalPriority,
          dataTimeStamp,
        });
        console.log(
          "Temps: ",
          tReturn,
          tSupply,
          tSet,
          tRoom,
          outsideTemp,
          minTemp,
          maxTemp,
          relaySetting
        );
      }
    }
  }

  tick() {
    this.setState({
      date: new Date(),
    });
    if (this.state.modeBox === "Real-time") this.getSystemData();
  }
  handleChangeStart = (event) => {
    const startDate = event.setHours(0, 0, 0);
    this.setState({ startDate });
  };

  handleChangeEnd = (event) => {
    const endDate = event.setHours(0, 0, 0);
    this.setState({ endDate });
  };

  handleClickMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
    this.setState({ chartReadyFlag: false, minTemp: 200, maxTemp: -200 });
  };

  handleCloseRT = () => {
    this.setState({ anchorEl: null });
    const timeZone = this.state.timeZone;
    const pointsOnChart = parseInt(localStorage.getItem("pointsOnChart"));
    const endDate = new Date().setUTCHours(0 - timeZone, 0, 0) - 10;
    const startDate = endDate - pointsOnChart * 2.0 /*1.2*/ * 1000;
    this.computeInitialData(this.state.tickNumber, startDate, endDate);
    this.setState({ modeBox: "Real-time" });
  };

  handleCloseInterval = () => {
    this.setState({ anchorEl: null });
    const endDate = new Date().getTime() - 10;
    const startDate =
      endDate - /*this.state.intervalPeriod*/ 3600 * 3 * 1.2 * 1000;
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Last 3 Hours", startDate, endDate });
  };
  handleCloseToday = () => {
    this.setState({ anchorEl: null });
    const timeZone = this.state.timeZone;
    let now_UTC = Date.now();
    const endDate = now_UTC;
    const UTCnumOfHrs = new Date(now_UTC).getUTCHours();
    let startDate = 0;
    if (UTCnumOfHrs < -timeZone) now_UTC = now_UTC - 24 * 3600 * 1000;
    startDate = new Date(now_UTC).setUTCHours(-timeZone, 0, 0);
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Today", startDate, endDate });
  };
  handleCloseYesterday = () => {
    this.setState({ anchorEl: null });
    const timeZone = this.state.timeZone;
    let now_UTC = Date.now();
    const UTCnumOfHrs = new Date(now_UTC).getUTCHours();
    if (UTCnumOfHrs < -timeZone) now_UTC = now_UTC - 24 * 3600 * 1000;
    const endDate = new Date(now_UTC).setUTCHours(-timeZone, 0, 0);
    const startDate = endDate - 24 * 3600 * 1000;
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Yesterday", startDate, endDate });
  };

  handleCloseWeek = () => {
    this.setState({ anchorEl: null });
    const timeZone = this.state.timeZone;
    let myDay = new Date().getUTCDay();
    let startDate = new Date();
    if (myDay !== 0)
      startDate = new Date().setUTCHours(-24 * (myDay - 1) - timeZone, 0, 0);
    else {
      myDay = 7;
      startDate = new Date().setUTCHours(-24 * (myDay - 1) - timeZone, 0, 0);
    }
    const endDate = new Date().setUTCHours(24 - timeZone, 0, 0);
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Week", startDate, endDate });
  };
  handleCloseLastWeek = () => {
    this.setState({ anchorEl: null });
    const timeZone = this.state.timeZone;
    let myDay = new Date().getUTCDay();
    let startDate = new Date();
    if (myDay !== 0)
      startDate = new Date().setUTCHours(-24 * (myDay - 1) - timeZone, 0, 0);
    else {
      myDay = 7;
      startDate = new Date().setUTCHours(-24 * (myDay - 1) - timeZone, 0, 0);
    }
    const endDate = startDate;
    startDate = startDate - 7 * 24 * 3600 * 1000;

    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Last Week", startDate, endDate });
  };
  handleCloseMonth = () => {
    this.setState({ anchorEl: null });
    const myDay = new Date(),
      y = myDay.getFullYear(),
      m = myDay.getMonth();
    const startDate = new Date(y, m, 1).setHours(0, 0, 0);
    const endDate = new Date().getTime();
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "This Month", startDate, endDate });
  };
  handleCloseLastMonth = () => {
    this.setState({ anchorEl: null });
    const myDay = new Date(),
      y = myDay.getFullYear(),
      m = myDay.getMonth();
    const endDate = new Date(y, m, 1).setHours(0, 0, 0);
    let startDate = new Date();
    if (m > 0) startDate = new Date(y, m - 1, 1).setHours(0, 0, 0);
    else startDate = new Date(y - 1, 11, 1).setHours(0, 0, 0);
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Last Month", startDate, endDate });
  };
  handleCloseQuarter = () => {
    this.setState({ anchorEl: null });
    const myDay = new Date();
    const y = myDay.getFullYear();
    let m = myDay.getMonth();
    if (m >= 0 && m <= 2) m = 0;
    else if (m >= 3 && m <= 5) m = 3;
    else if (m >= 6 && m <= 8) m = 6;
    else m = 9;
    const startDate = new Date(y, m, 1).setHours(0, 0, 0);
    const endDate = new Date().getTime();
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "This Quarter", startDate, endDate });
  };
  handleCloseYear = () => {
    this.setState({ anchorEl: null });
    const myDay = new Date();
    const y = myDay.getFullYear();
    const startDate = new Date(y, 0, 1).setHours(0, 0, 0);
    const endDate = new Date().getTime();
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "This Year", startDate, endDate });
  };
  handleChangeStartTR = (event) => {
    const startDateTR = event.setHours(0, 0, 0);
    console.log("Start Date TR", startDateTR);
    this.setState({ startDateTR });
  };
  handleStartTimeTR = (e) => {
    const startTimeTR = e.currentTarget.value;
    console.log("Start Time TR", startTimeTR);
    this.setState({ startTimeTR });
  };
  handleEndTimeTR = (e) => {
    const endTimeTR = e.currentTarget.value;
    console.log("End Time TR", endTimeTR);
    this.setState({ endTimeTR });
  };
  handleCloseTR = () => {
    this.setState({ anchorEl: null });
    let a = 0;
    let mSeconds = 0;
    let startTimeEpochLocal = 0;
    let endTimeEpochLocal = 0;

    if (
      this.state.startTimeTR &&
      this.state.endTimeTR &&
      this.state.startDateTR
    ) {
      a = this.state.startTimeTR.split(":"); // split it at the colons
      mSeconds = (a[0] * 3600 + a[1] * 60) * 1000;
      startTimeEpochLocal = this.state.startDateTR + mSeconds;

      a = 0;
      mSeconds = 0;
      a = this.state.endTimeTR.split(":"); // split it at the colons
      mSeconds = (a[0] * 3600 + a[1] * 60) * 1000;
      endTimeEpochLocal = this.state.startDateTR + mSeconds;
      const timeNow = new Date().getTime();
      if (endTimeEpochLocal > timeNow || startTimeEpochLocal > timeNow) {
        toast.error("Start and End Times must be earlier than now!");
        endTimeEpochLocal = timeNow - 10 * 60 * 1000;
        startTimeEpochLocal = endTimeEpochLocal - 10 * 60 * 1000;
      }
      if (endTimeEpochLocal > startTimeEpochLocal + 2.1 * 3600 * 1000) {
        toast.error("Real-time data must be limited to less than 2 hours.");
        endTimeEpochLocal = startTimeEpochLocal + 2.0 * 3600 * 1000;
      }
      if (endTimeEpochLocal < startTimeEpochLocal) {
        toast.error(
          "Start time must be earlier than end time.  Set to 10 minutes"
        );
        startTimeEpochLocal = endTimeEpochLocal - 10 * 60 * 1000;
      }
      this.computeInitialData(
        this.state.tickNumber,
        startTimeEpochLocal,
        endTimeEpochLocal
      );
    }
    this.setState({ modeBox: "Time Range" });
  };
  handleCloseDR = () => {
    this.setState({ anchorEl: null });
    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
    console.log("Start Date", startDate);
    console.log("End Date", endDate);
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Date Range" });
  };
  handleCloseDaily = () => {
    this.setState({ anchorEl: null });
    console.log("Dashboard Daily");
    this.setState({ modeBox: "Daily" });
    this.props.history.push(
      `/systems/dashboardDaily/${this.state.handleID}/${this.state.systemConfiguration}`
    );
  };
  render() {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    return (
      <React.Fragment>
        <div
          style={{
            width: "100%",
            position: "absolute",
            marginTop: "150px",
            marginBottom: "350px",
          }}
        >
          {this.state.thisNode && (
            <p
              className="basicParagraph"
              style={{
                width: "800px",
                textAlign: "left",
                transform: "translate(110px, -25px)",
              }}
            >
              {this.props.match.params.systemName} |{" "}
              {this.state.thisNode.nodeName}
            </p>
          )}
          {/*  {this.state.dataFrequency && (*/}
          <div>
            <table
              className="table"
              style={{
                //position: "relative",
                width: "280px",
                transform: "translate(100px,-30px)",
              }}
            >
              <td
                style={{
                  width: "140px",
                  padding: "0px",
                  margin: "0px",
                }}
              >
                {this.state.modeBox === "Time Range" && (
                  <div
                    style={{
                      width: "340px",
                      padding: "0px",
                      margin: "0px",
                      paddingTop: "3px",
                    }}
                  >
                    {" "}
                    <DatePicker
                      className="ui-datepicker"
                      selected={this.state.startDateTR}
                      selectsStart
                      startDate={this.state.startDateTR}
                      onChange={this.handleChangeStartTR}
                    />
                    <label
                      className="basicParagraph"
                      style={{ height: "30px", width: "50px", fontSize: "8px" }}
                    >
                      Start Time:
                    </label>
                    <input
                      value={this.state.startTimeTR}
                      onChange={this.handleStartTimeTR}
                      type="time"
                      className="form-control2"
                      style={{
                        float: "center",
                        height: "20px",
                        width: "83px",
                        fontSize: "9px",
                      }}
                    ></input>
                    <label
                      className="basicParagraph"
                      style={{ height: "30px", width: "50px", fontSize: "8px" }}
                    >
                      End Time:
                    </label>
                    <input
                      value={this.state.endTimeTR}
                      onChange={this.handleEndTimeTR}
                      type="time"
                      className="form-control2"
                      style={{
                        float: "center",
                        height: "20px",
                        width: "83px",
                        fontSize: "9px",
                      }}
                    ></input>
                  </div>
                )}
                {this.state.modeBox === "Date Range" && (
                  <div
                    style={{
                      width: "140px",
                      padding: "0px",
                      margin: "0px",
                      paddingTop: "3px",
                    }}
                  >
                    {" "}
                    <DatePicker
                      className="ui-datepicker"
                      selected={this.state.startDate}
                      selectsStart
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      onChange={this.handleChangeStart}
                    />
                    <DatePicker
                      className="ui-datepicker"
                      selected={this.state.endDate}
                      selectsEnd
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      onChange={this.handleChangeEnd}
                    />
                  </div>
                )}
                {this.state.modeBox !== "Date Range" &&
                  this.state.modeBox !== "Time Range" && (
                    <div
                      style={{
                        width: "140px",
                        padding: "0px",
                        margin: "0px",
                      }}
                    >
                      <p></p>
                    </div>
                  )}
              </td>
              <td
                style={{
                  width: "70px",
                  margin: "0px",
                  padding: "0px",
                  marginRight: "5px",
                  paddingRight: "5px",
                  paddingTop: "4px",
                }}
              >
                <p
                  className="indicateParagraph"
                  style={{
                    borderWidth: "0px",
                    width: "70px",
                    margin: "0px",
                    padding: "0px",
                    textAlign: "right",
                  }}
                >
                  {this.state.modeBox}
                </p>
              </td>
              <td
                style={{
                  width: "20px",
                  margin: "0px",
                  padding: "0px",
                  marginRight: "5px",
                  paddingRight: "5px",
                }}
              >
                <SimpleMenu
                  onClickingMenu={this.handleClickMenu}
                  onChoosingRT={this.handleCloseRT}
                  onChoosingInterval={this.handleCloseInterval}
                  onChoosingToday={this.handleCloseToday}
                  onChoosingYesterday={this.handleCloseYesterday}
                  onChoosingWeek={this.handleCloseWeek}
                  onChoosingLastWeek={this.handleCloseLastWeek}
                  onChoosingMonth={this.handleCloseMonth}
                  onChoosingLastMonth={this.handleCloseLastMonth}
                  onChoosingQuarter={this.handleCloseQuarter}
                  onChoosingYear={this.handleCloseYear}
                  onChoosingTimeRange={this.handleCloseTR}
                  onChoosingDR={this.handleCloseDR}
                  onChoosingDaily={this.handleCloseDaily}
                  anchorState={this.state.anchorEl}
                />
              </td>
              {this.state.modeBox === "Real-time" && (
                <td style={{ width: "20px", margin: "0px", padding: "0px" }}>
                  <button
                    className="btn btn-outline-light"
                    style={{
                      margin: "0px",
                      padding: "0px",
                      borderWidth: "0px",
                      marginRight: "5px",
                      paddingRight: "5px",
                    }}
                    onClick={this.handlePlay}
                  >
                    <FontAwesomeIcon
                      icon="play"
                      size="sm"
                      color="green"
                      title="play chart"
                    />
                  </button>
                </td>
              )}
              {this.state.modeBox === "Real-time" && (
                <td style={{ width: "20px", margin: "0px", padding: "0px" }}>
                  <button
                    className="btn btn-outline-light"
                    style={{
                      margin: "0px",
                      padding: "0px",
                      borderWidth: "0px",
                      marginRight: "5px",
                      paddingRight: "5px",
                    }}
                    onClick={this.handlePause}
                  >
                    <FontAwesomeIcon
                      icon="pause"
                      size="sm"
                      color="red"
                      title="pause chart"
                    />
                  </button>
                </td>
              )}

              {this.state.modeBox === "Real-time" && (
                <td style={{ width: "20px", margin: "0px", padding: "0px" }}>
                  <ChartPointsModal chartPoints={this.state.chartPoints} />
                </td>
              )}
              {this.state.modeBox !== "Real-time" &&
                this.state.modeBox !== "Time Range" && (
                  <td style={{ width: "20px", margin: "0px", padding: "0px" }}>
                    <ExcelFile
                      filename={this.state.fileName}
                      element={
                        <button
                          className="btn btn-outline-light"
                          style={{
                            margin: "0px",
                            padding: "0px",
                            borderWidth: "0px",
                            marginRight: "5px",
                            paddingRight: "5px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon="file-excel"
                            size="sm"
                            color="green"
                            title="Export interval data to excel"
                          />
                        </button>
                      }
                    >
                      <ExcelSheet
                        data={this.state.excelDataSet}
                        name="SystemData"
                      >
                        <ExcelColumn label="File Name" value="fileName" />
                        <ExcelColumn label="Date(mo/day/year)" value="Day" />
                        <ExcelColumn label="Time(hr:min:sec)" value="Time" />

                        <ExcelColumn label="Occupied" value="Occupied" />
                        <ExcelColumn label="OverRide" value="OverRide" />
                        <ExcelColumn
                          label="Avg. Return Temp (F)"
                          value="ReturnT"
                        />
                        <ExcelColumn
                          label="Avg. Supply Temp (F)"
                          value="SupplyT"
                        />
                        <ExcelColumn label="Avg. Room Temp (F)" value="RoomT" />
                        <ExcelColumn label="Avg. Set Temp (F)" value="SetT" />
                        <ExcelColumn
                          label="Avg. Outside Temp (F)"
                          value="OutsideT"
                        />
                        <ExcelColumn
                          label="Avg. Outside RH (%)"
                          value="OutsideRH"
                        />
                        <ExcelColumn
                          label="On Time for Fan (%)"
                          value="FanOn"
                        />
                        <ExcelColumn
                          label="On Time for Cool Stage 1 (%)"
                          value="Cool1"
                        />
                        <ExcelColumn
                          label="On Time for Cool Stage 2 (%)"
                          value="Cool2"
                        />
                        <ExcelColumn
                          label="On Time for Heat Stage 1 (%)"
                          value="Heat1"
                        />
                        <ExcelColumn
                          label="On Time for Heat Stage 2 (%)"
                          value="Heat2"
                        />
                        <ExcelColumn
                          label="OverRide Count (Sec)"
                          value="OverRideCount"
                        />
                        <ExcelColumn label="Bldg Power (kW)" value="Power" />
                      </ExcelSheet>
                    </ExcelFile>
                  </td>
                )}
            </table>
            {this.state.thisNodeInitial &&
              this.state.chartReadyFlag &&
              this.state.modeBox === "Time Range" && (
                <LineGraph
                  initialDataLength={this.state.initialDataLength}
                  chartDateInitial={this.state.dateInitial}
                  chartValue1Initial={this.state.tReturnInitial}
                  chartValue2Initial={this.state.tSupplyInitial}
                  chartValue3Initial={this.state.tSetInitial}
                  chartValue4Initial={this.state.tOutsideInitial}
                  chartValue5Initial={this.state.tRoomInitial}
                  chartLegend1="Return T (F)"
                  chartLegend2="Supply T (F)"
                  chartLegend3="Set T (F)"
                  chartLegend4="Outside T (F)"
                  chartLegend5="Room T (F)"
                  playPause={this.state.play}
                  modeBox={this.state.modeBox}
                  minTemp={this.state.minTemp}
                  maxTemp={this.state.maxTemp}
                />
              )}
            {this.state.thisNodeInitial &&
              this.state.thisNode &&
              this.state.chartReadyFlag &&
              this.state.modeBox === "Real-time" && (
                <LineGraph
                  chartDateInitial={this.state.dateInitial}
                  chartValue1Initial={this.state.tReturnInitial}
                  chartValue2Initial={this.state.tSupplyInitial}
                  chartValue3Initial={this.state.tSetInitial}
                  chartValue4Initial={this.state.tOutsideInitial}
                  chartValue5Initial={this.state.tRoomInitial}
                  chartValue1={this.state.tReturn}
                  chartLegend1="Return T (F)"
                  chartValue2={this.state.tSupply}
                  chartLegend2="Supply T (F)"
                  chartValue3={this.state.tSet}
                  chartLegend3="Set T (F)"
                  chartValue4={this.state.outsideTemp}
                  chartLegend4="Outside T (F)"
                  chartValue5={this.state.tRoom}
                  chartLegend5="Room T (F)"
                  dataTimeStamp={this.state.dataTimeStamp}
                  playPause={this.state.play}
                  modeBox={this.state.modeBox}
                  minTemp={this.state.minTemp}
                  maxTemp={this.state.maxTemp}
                />
              )}
            <hr></hr>
            {this.state.thisNode &&
              this.state.chartReadyFlag &&
              this.state.modeBox === "Time Range" && (
                <LineGraph2
                  chartDateInitial={this.state.dateInitial}
                  chartValue1Initial={this.state.relaySettingCool1}
                  chartValue2Initial={this.state.relaySettingCool2}
                  chartValue3Initial={this.state.relaySettingHeat1}
                  chartValue4Initial={this.state.relaySettingHeat2}
                  chartLegend1="Cool Stage 1"
                  chartLegend2="Cool Stage 2"
                  chartLegend3="Heat Stage 1"
                  chartLegend4="Heat Stage 2"
                  playPause={this.state.play}
                  modeBox={this.state.modeBox}
                />
              )}
            {this.state.thisNodeInitial &&
              this.state.thisNode &&
              this.state.chartReadyFlag &&
              this.state.modeBox === "Real-time" && (
                <LineGraph2
                  chartDateInitial={this.state.dateInitial}
                  chartValue1Initial={this.state.relaySettingCool1}
                  chartValue2Initial={this.state.relaySettingCool2}
                  chartValue3Initial={this.state.relaySettingHeat1}
                  chartValue4Initial={this.state.relaySettingHeat2}
                  chartValue1={this.state.thisNode.relaySetting & 0x0001}
                  chartLegend1="Cool Stage 1"
                  chartValue2={
                    ((this.state.relaySetting & 0x0004) >> 2) +
                    (this.state.relaySetting & 0x0001) +
                    0.03
                  }
                  chartLegend2="Cool Stage 2"
                  chartValue3={(this.state.relaySetting & 0x0002) >> 1}
                  chartLegend3="Heat Stage 1"
                  chartValue4={
                    ((this.state.relaySetting & 0x0008) >> 3) +
                    ((this.state.relaySetting & 0x0002) >> 1) +
                    0.03
                  }
                  chartLegend4="Heat Stage 2"
                  playPause={this.state.play}
                  modeBox={this.state.modeBox}
                />
              )}
            {/****************************************************************************************** */}

            {this.state.thisNode &&
              this.state.chartReadyFlag &&
              this.state.modeBox === "Time Range" && (
                <LinePowerGraph
                  chartDateInitial={this.state.dateInitial}
                  chartValue1Initial={this.state.aggPower1Initial}
                  chartLegend1="Building Power (kW)"
                  playPause={this.state.play}
                  modeBox={this.state.modeBox}
                />
              )}
            {this.state.thisNodeInitial &&
              this.state.thisNode &&
              this.state.chartReadyFlag &&
              this.state.modeBox === "Real-time" && (
                <LinePowerGraph
                  chartDateInitial={this.state.dateInitial}
                  chartValue1Initial={this.state.aggPower1Initial}
                  chartValue1={this.state.aggPower1}
                  chartLegend1="Building Power"
                  playPause={this.state.play}
                  modeBox={this.state.modeBox}
                />
              )}

            {/****************************************************************************************** */}

            {this.state.dataDate &&
              this.state.chartReadyFlag &&
              this.state.modeBox !== "Time Range" &&
              this.state.modeBox !== "Real-time" && (
                <ChartThermostat
                  dataDate={this.state.dataDate}
                  avgReturnT={this.state.avgReturnT}
                  avgSupplyT={this.state.avgSupplyT}
                  avgOutsideT={this.state.weatherTemp}
                  avgRoomT={this.state.avgRoomT}
                  avgSetT={this.state.avgSetT}
                  avgOutsideRH={this.state.weatherRH}
                  timeZone={this.state.timeZone}
                  minTemp={this.state.minTemp}
                  maxTemp={this.state.maxTemp}
                  onTime={this.state.onTime}
                />
              )}
            {this.state.dataDate &&
              this.state.chartReadyFlag &&
              this.state.modeBox !== "Time Range" &&
              this.state.modeBox !== "Real-time" && (
                <ChartOnTime
                  dataDate={this.state.dataDate}
                  timeZone={this.state.timeZone}
                  onTimeCool1={this.state.onTimeCool1}
                  onTimeCool2={this.state.onTimeCool2}
                  onTimeHeat1={this.state.onTimeHeat1}
                  onTimeHeat2={this.state.onTimeHeat2}
                  onTimeFan={this.state.onTimeFan}
                />
              )}

            {this.state.dataDate &&
              this.state.chartReadyFlag &&
              this.state.modeBox !== "Time Range" &&
              this.state.modeBox !== "Real-time" && (
                <ChartBldgPower
                  dataDate={this.state.dataDate}
                  bldgIntervalPower={this.state.meter1Interval}
                  timeZone={this.state.timeZone}
                />
              )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default GPLineChart;

class ChartPointsModal extends Form {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: { chartPoints: localStorage.getItem("pointsOnChart") },
      errors: {},
    };

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  schema = {
    chartPoints: Joi.number().required().label("Chart Points"),
  };

  handleChange = ({ currentTarget: input }) => {
    localStorage.setItem("pointsOnChart", input.value);
    this.setState({ chartPoints: input.value });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-light"
          style={{
            width: "20px",
            margin: "0px",
            padding: "0px",
            marginRight: "5px",
            paddingRight: "5px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="arrows-alt-h"
            size="sm"
            color="tomato"
            title="points in chart"
          />
        </button>

        <Modal
          style={{ width: "150px", fontSize: "12px", color: "#2d5c88" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "14px" }}
            >
              Chart Points:
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <h6>Enter number of points on chart:</h6>

            <select
              onChange={this.handleChange}
              value={localStorage.getItem("pointsOnChart")}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
              <option value="2000">2000</option>
              <option value="5000">5000</option>
            </select>
            <h6 />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
