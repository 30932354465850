import React from "react";
import Joi from "joi-browser";

import Form from "./common/form";
import {
  getSystemLatestData,
  ws_getSystemLatestData,
  getSystem,
  getSystemSignals,
  setSystemSignals,
  getSystemIDfromHandle,
  initSystemUpdate,
  getSystemInfo,
  getSystemBasics,
  findIdByMac,
  setSystemBasics,
  updateTimeZone,
} from "../services/systemService";
import BackGroundRectangle from "../components/backgroundRect";
import { CALIBRATE, webSocketMode } from "../config.json";

class SimplModuleForm extends Form {
  state = {
    simplModulesCalibrate: 0,
    fullCal: 0,
    systemID: "",
    systemSN: "",
    systemName: "",
    systemProduct: "",
    systemType: "",
    systemConfiguration: "",
    data: {
      systemDescription: "Write a brief Description of the system here.",
      systemConfiguration: "1",
      systemTimeZone: "1",
      dataVerbosity: "1",
      dataFrequency: "2",
      localLogLength: "1",
      localLogFrequency: "2",
      localLogVerbosity: "1",
      resetDelay: "15",
      paramUpdateRate: "2",
      intervalDataPeriod: "3",

      circuitNameMeter1: "Building",
      ctRatioMeter1: 500,
      ctDirectionMeter1A: "+",
      ctDirectionMeter1B: "+",
      ctDirectionMeter1C: "+",
      voltageWiring1: "3LN",
      ctWiring1: "3CT",
      demandMode1: "Sliding",

      circuitNameMeter2: "Building",
      ctRatioMeter2: 500,
      ctDirectionMeter2A: "+",
      ctDirectionMeter2B: "+",
      ctDirectionMeter2C: "+",
      voltageWiring2: "3LN",
      ctWiring2: "3CT",
      demandMode2: "Sliding",

      CTtype: "Rogowski Rope CT",
      GridFrequency: "60 Hz",
      VmeasGain: "1",
      ImeasGain1: "1",
      ImeasDigitalGain1: "1",
      ImeasGain2: "1",
      ImeasDigitalGain2: "1",

      meter1CTtype: "1",
      meter2CTtype: "1",
    },

    optionsConfiguration: [
      { _id: "0", name: "No SimplMeter" },
      { _id: "1", name: "One-Channel External SimplMeter" },
      { _id: "2", name: "Two-Channel External SimplMeter" },
      { _id: "3", name: "One-Channel Internal SimplMeter" },
      { _id: "4", name: "Two-Channel Internal SimplMeter" },
      { _id: "5", name: "Three-Channel SimplLoads" },
    ],

    optionsTimeZone: [
      { _id: "1", name: "(UTC -08:00) Pacific Time (US & Canada)" },
      { _id: "2", name: "(UTC -07:00) Mountain Time (US & Canada)" },
      { _id: "3", name: "(UTC -06:00) Central Time (US & Canada)" },
      { _id: "4", name: "(UTC -05:00) Eastern Time (US & Canada)" },
      { _id: "5", name: "(UTC -10:00) Hawaii Time (US & Canada)" },
      { _id: "6", name: "(UTC +1:00) Western Europe" },
      { _id: "7", name: "(UTC +2:00) Eastern Europe" },
      { _id: "8", name: "(UTC +3:00) Moscow, Russia" },
      { _id: "9", name: "(UTC +4:00) Abu Dhabi" },
      { _id: "10", name: "(UTC +5:30) New Dehli, India" },
      { _id: "11", name: "(UTC +08:00) Beijing, China & Hong Kong" },
      { _id: "12", name: "(UTC +09:00) Seoul, Korea and Tokyo, Japan" },
      { _id: "13", name: "(UTC +09:30) Adelaide, Australia" },
      { _id: "14", name: "(UTC +10:00) Brisbane, Australia" },
      { _id: "15", name: "(UTC +12:00) Auckland, New Zealand" },
    ],
    optionsDataVerbosity: [
      { _id: "1", name: "Low" },
      { _id: "2", name: "Medium" },
      { _id: "3", name: "High" },
    ],
    optionsDataFreq: [
      { _id: "1", name: "once / Second" },
      { _id: "2", name: "once / 5 Seconds" },
      { _id: "3", name: "once / 15 Seconds" },
      { _id: "4", name: "Once / Minute" },
      { _id: "5", name: "Once / 5 Minutes" },
      { _id: "6", name: "Once / 15 Minutes" },
      { _id: "7", name: "Once / 30 Minutes" },
      { _id: "8", name: "Once / Hour" },
    ],

    optionsLocalLogLength: [
      { _id: "1", name: "Low" },
      { _id: "2", name: "Medium" },
      { _id: "3", name: "High" },
    ],

    optionsLocalLogFreq: [
      { _id: "1", name: "5-min Intervals Data" },
      { _id: "2", name: "15-min Interval Data" },
      { _id: "3", name: "30-minute Interval Data" },
      { _id: "4", name: "One-hour Interval Data" },
    ],

    optionsLocalLogVerbosity: [
      { _id: "1", name: "Low" },
      { _id: "2", name: "Medium" },
      { _id: "3", name: "High" },
    ],

    optionsParamUpdateRate: [
      { _id: "1", name: "One Minute" },
      { _id: "2", name: "5 Minutes" },
      { _id: "3", name: "15 Minutes" },
      { _id: "4", name: "One HOUR" },
      { _id: "5", name: "One Day" },
    ],

    optionsIntervalDataPeriod: [
      { _id: "1", name: "One-Minute Interval Data" },
      { _id: "2", name: "Five-Minute Interval Data" },
      { _id: "3", name: "15-Minute Interval Data" },
      { _id: "4", name: "30-Minute Interval Data" },
      { _id: "5", name: "One-hour Interval Data" },
    ],
    optionsMeasPoint1: [
      { _id: "0", name: "No Solar" },
      { _id: "2", name: "Solar" },
    ],
    optionsMeasPoint0: [
      { _id: "0", name: "Line/Grid" },
      { _id: "1", name: "Load/Building" },
    ],
    optionsCTDirection: [
      { _id: "0", name: "+" },
      { _id: "1", name: "-" },
    ],
    optionsVoltageWiring: [
      { _id: "0", name: "3LN" },
      { _id: "1", name: "1LN" },
      { _id: "2", name: "2LL" },
      { _id: "3", name: "3LL" },
      { _id: "4", name: "1LL" },
    ],
    optionsWiring: [
      { _id: "0", name: "120V/240V Single or Split-phase" },
      { _id: "1", name: "120V/208V Three-phase" },
      { _id: "2", name: "277V/480V Three-phase" },
    ],
    optionsCTWiring: [
      { _id: "0", name: "3CT" },
      { _id: "1", name: "1CT" },
      { _id: "2", name: "2CT" },
    ],
    optionsDemandMode: [
      { _id: "1", name: "Sliding" },
      { _id: "2", name: "Thermal" },
    ],
    optionsCTtype: [
      { _id: "1", name: "Rogowski Rope CT" },
      { _id: "2", name: "Hinged Clamp CT" },
    ],

    optionsGridFreq: [
      { _id: "1", name: "60 Hz" },
      { _id: "2", name: "50 Hz" },
    ],
    optionsVmeasGain: [
      { _id: "1", name: "1" },
      { _id: "2", name: "2" },
      { _id: "3", name: "4" },
    ],
    optionsImeasGain: [
      { _id: "1", name: "1" },
      { _id: "2", name: "2" },
      { _id: "3", name: "4" },
    ],
    optionsIdigitalGain: [
      { _id: "1", name: "1" },
      { _id: "2", name: "2" },
      { _id: "3", name: "4" },
      { _id: "4", name: "8" },
    ],
    optionsRopeCT: [
      { _id: "1", name: "1-in. Diameter, MRC-24" },
      { _id: "2", name: "4-in. Diameter, SRC-100" },
      { _id: "3", name: "8-in. Diameter, NRC-200" },
    ],
    errors: {},
  };

  schema = {
    systemDescription: Joi.string().label("System Description"),
    systemConfiguration: Joi.string().label("System Configuration"),
    systemTimeZone: Joi.string().label(
      "Select the time zone for the location of the system"
    ),
    dataVerbosity: Joi.string().label("Data Verbosity:  Low, Medium or High"),
    dataFrequency: Joi.string().label("Data Frequency:"),
    localLogLength: Joi.string().label(
      "Log length for the device local memory"
    ),
    localLogFrequency: Joi.string().label(
      "Select the frequency of the local logging function"
    ),
    localLogVerbosity: Joi.string().label(
      "Select the verbosity of the local logging function"
    ),
    resetDelay: Joi.number()
      .min(3)
      .max(100)
      .label("Delay in seconds after Power-on Reset"),
    paramUpdateRate: Joi.string().label(
      "Select the update rate of the system parameters"
    ),
    intervalDataPeriod: Joi.string().label("Select the interval data period"),

    circuitNameMeter1: Joi.string().label(
      "Select the frequency of the local logging function"
    ),

    ctRatioMeter1: Joi.number()
      .min(0)
      .max(5000)
      .label("CR Ratio Supplied by the CT Manufacturer"),
    ct2RatioMeter1: Joi.number()
      .min(0)
      .max(5000)
      .label("CR Ratio Supplied by the CT Manufacturer"),
    ctDirectionMeter1A: Joi.string().label(
      "Phase A, CT Direction: Positive or Negative"
    ),
    ctDirectionMeter1B: Joi.string().label(
      "Phase B, CT Direction: Positive or Negative"
    ),
    ctDirectionMeter1C: Joi.string().label(
      "Phase C, CT Direction: Positive or Negative"
    ),
    voltageWiring1: Joi.string().label(
      "Select voltage wiring topology for Meter 1"
    ),
    ctWiring1: Joi.string().label("Select CT wiring topology for Meter 1"),
    demandMode1: Joi.string().label(
      "Select Demand Mode Calculation Methodology for Meter 1"
    ),
    circuitNameMeter2: Joi.string().label(
      "Select the frequency of the local logging function"
    ),

    ctRatioMeter2: Joi.number()
      .min(0)
      .max(5000)
      .label("CR Ratio Supplied by the CT Manufacturer"),
    ct2RatioMeter2: Joi.number()
      .min(0)
      .max(5000)
      .label("CR Ratio Supplied by the CT Manufacturer"),
    ctDirectionMeter2A: Joi.string().label(
      "Phase A, CT Direction: Positive or Negative"
    ),
    ctDirectionMeter2B: Joi.string().label(
      "Phase B, CT Direction: Positive or Negative"
    ),
    ctDirectionMeter2C: Joi.string().label(
      "Phase C, CT Direction: Positive or Negative"
    ),
    voltageWiring2: Joi.string().label(
      "Select voltage wiring topology for Meter 2"
    ),
    ctWiring2: Joi.string().label("Select CT wiring topology for Meter 2"),
    demandMode2: Joi.string().label(
      "Select Demand Mode Calculation Methodology for Meter 2"
    ),
    CTtype: Joi.string().label("Select CT Type"),
    GridFrequency: Joi.string().label("Select CT Type"),
    VmeasGain: Joi.string().label("Select CT Type"),
    ImeasGain1: Joi.string().label("Select CT Type"),
    ImeasDigitalGain1: Joi.string().label("Select CT Type"),
    ImeasGain2: Joi.string().label("Select CT Type"),
    ImeasDigitalGain2: Joi.string().label("Select CT Type"),

    voltageWiring2: Joi.string().label(
      "Select voltage wiring topology for Meter 2"
    ),

    meter1CTtype: Joi.string().label("Select CT Type"),
    meter2CTtype: Joi.string().label("Select CT Type"),
  };

  async componentDidMount() {
    const handleId = this.props.match.params.systemHandleID;

    let response = {};
    if (webSocketMode) {
      response = ws_getSystemLatestData(handleId);
      console.log("Websocket: Latest Data", response);
    } else {
      const response_temp = await getSystemLatestData(handleId);
      console.log("Response Full Info", response_temp);
      response = response_temp.data.data[0];
      console.log("Http: Latest Data", response);
    }
    let commMode = 0;
    if (response !== undefined && response !== null) {
      commMode = response.data.commMode;
      console.log("Comm Mode", commMode);
    }

    const systemID = (await getSystemIDfromHandle(handleId)).data.data
      .system_id;
    const system = (await getSystem(systemID)).data.data[0];
    console.log("System Info", system);
    const systemSN = system.systemID.SN;
    const systemName = system.systemID.name;
    const systemProduct = system.systemProduct;
    const systemType = system.systemType;
    const systemConfiguration = system.systemBasics.systemConfiguration;

    let ctRatioMeter1 = system.systemBasics.ctRatioMeter1;
    if (ctRatioMeter1 === "0.00" || ctRatioMeter1 === "NaN")
      ctRatioMeter1 = 1.0;
    let ct2RatioMeter1 = system.systemBasics.ct2RatioMeter1;
    if (ct2RatioMeter1 === "0.00" || ct2RatioMeter1 === "NaN")
      ct2RatioMeter1 = 1.0;
    let ctRatioMeter2 = system.systemBasics.ctRatioMeter2;
    if (ctRatioMeter2 === "0.00" || ctRatioMeter2 === "NaN")
      ctRatioMeter2 = 1.0;
    let ct2RatioMeter2 = system.systemBasics.ct2RatioMeter2;
    if (ct2RatioMeter2 === "0.00" || ct2RatioMeter2 === "NaN")
      ct2RatioMeter2 = 1.0;

    console.log("System Product", systemProduct);
    console.log("System Type", systemType);
    console.log("system Configuration", systemConfiguration);
    this.setState({ systemSN });

    const systemBasics = (await getSystemBasics(handleId)).data.data.basics;
    console.log("Get Systems Basics", systemBasics);
    const dataFrequency = systemBasics.dataFrequency;
    let sleepTime = 5000;
    if (commMode === 2) sleepTime = 35000;

    /* if (dataFrequency === "1") sleepTime = 5000;
    else if (dataFrequency === "2") sleepTime = 15000;
    else sleepTime = 35000; //15 seconds + 15 seconds + 5 seconds*/

    const productSN = systemBasics.productSN;
    const fourGserialNumber = systemBasics.fourGserialNumber;
    const slaveSN = systemBasics.slaveSN;
    const endProductPN = systemBasics.endProductPN;
    const masterBoardSN = systemBasics.masterBoardSN;
    const CTtype = systemBasics.CTtype;
    const GridFrequency = systemBasics.GridFrequency;
    const VmeasGain = systemBasics.VmeasGain;
    const ImeasGain1 = systemBasics.ImeasGain1;
    const ImeasDigitalGain1 = systemBasics.ImeasDigitalGain1;
    const ImeasGain2 = systemBasics.ImeasGain2;
    const ImeasDigitalGain2 = systemBasics.ImeasDigitalGain2;
    const meter1CTtype = systemBasics.meter1CTtype;
    const meter2CTtype = systemBasics.meter2CTtype;
    if (systemBasics) this.setState({ data: systemBasics });
    this.setState({
      systemID,
      handleId,
      systemName,
      systemType,
      systemProduct,
      systemConfiguration,
      productSN,
      fourGserialNumber,
      slaveSN,
      masterBoardSN,
      endProductPN,
      ctRatioMeter1,
      ct2RatioMeter1,
      ctRatioMeter2,
      ct2RatioMeter2,
      sleepTime,
      meter1CTtype,
      meter2CTtype,
    });
  }
  /* CTtype,
  GridFrequency,
  VmeasGain,
  ImeasGain1,
  ImeasDigitalGain1,
  ImeasGain2,
  ImeasDigitalGain2,*/

  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }
  doSubmit = async () => {
    let response = {};

    if (webSocketMode) {
      response = ws_getSystemLatestData(this.props.match.params.systemHandleID);
      console.log("Websocket: Latest Data", response);
    } else {
      response = (
        await getSystemLatestData(this.props.match.params.systemHandleID)
      ).data.data[0];
      console.log("Http: Latest Data", response);
    }

    ////////////////////////////////////////////////////////////////////////////
    let ctRatioMeter1 = this.state.ctRatioMeter1;
    let ctRatioMeter1b = this.state.ctRatioMeter1;
    let ctRatioMeter1c = this.state.ctRatioMeter1;

    let ct2RatioMeter1 = this.state.ct2RatioMeter1;
    let ct2RatioMeter1b = this.state.ct2RatioMeter1;
    let ct2RatioMeter1c = this.state.ct2RatioMeter1;

    let ctRatioMeter2 = this.state.ctRatioMeter2;
    let ctRatioMeter2b = this.state.ctRatioMeter2;
    let ctRatioMeter2c = this.state.ctRatioMeter2;

    let ct2RatioMeter2 = this.state.ct2RatioMeter2;
    let ct2RatioMeter2b = this.state.ct2RatioMeter2;
    let ct2RatioMeter2c = this.state.ct2RatioMeter2;

    if (
      this.state.systemConfiguration === "2" /* ||
      (this.state.systemConfiguration === "3" &&
        !this.state.simplModulesCalibrate)*/
    ) {
      ctRatioMeter1 = this.state.data.ctRatioMeter1;
      ct2RatioMeter1 = this.state.data.ct2RatioMeter1;
      ctRatioMeter2 = this.state.data.ctRatioMeter2;
      ct2RatioMeter2 = this.state.data.ct2RatioMeter2;
    } else if (
      this.state.systemConfiguration === "3" &&
      response.data.deviceLedger.simplMeter_ii !== undefined &&
      this.state.simplModulesCalibrate
    ) {
      console.log("Calibrate Simpl ATS");
      const currentEntered_1 = this.state.data.ctRatioMeter1;
      const voltageEntered_1 = this.state.data.ct2RatioMeter1;
      const voltageReading_1 = response.data.deviceLedger.simplMeter_ii.sm2VAN1;
      const currentReading_1 = response.data.deviceLedger.simplMeter_ii.sm2IA1;

      const currentEntered_2 = this.state.data.ctRatioMeter2;
      const voltageEntered_2 = this.state.data.ct2RatioMeter2;
      const voltageReading_2 = response.data.deviceLedger.simplMeter_ii.sm2VAN2;
      const currentReading_2 = response.data.deviceLedger.simplMeter_ii.sm2IA2;
      const voltageRatio_1 = voltageReading_1 / voltageEntered_1;
      const currentRatio_1 = currentReading_1 / currentEntered_1;
      const voltageRatio_2 = voltageReading_2 / voltageEntered_2;
      const currentRatio_2 = currentReading_2 / currentEntered_2;
      console.log("ctRatioMeter2", ctRatioMeter2);
      ctRatioMeter1 = (ctRatioMeter1 * currentRatio_1).toFixed(2).toString();
      ct2RatioMeter1 = (ct2RatioMeter1 * voltageRatio_1).toFixed(2).toString();
      ctRatioMeter2 = (ctRatioMeter2 * currentRatio_2).toFixed(2).toString();
      ct2RatioMeter2 = (ct2RatioMeter2 * voltageRatio_2).toFixed(2).toString();
    } else if (
      (this.state.systemConfiguration === "6" ||
        this.state.systemConfiguration === "7" ||
        this.state.systemConfiguration === "8") &&
      response.data.deviceLedger.slaveUnits !== undefined &&
      this.state.simplModulesCalibrate
    ) {
      const slaveUnits = response.data.deviceLedger.slaveUnits;
      console.log("Slave Units", slaveUnits);

      const slaveUnit = slaveUnits.filter(
        (thisSlave) => thisSlave.MAC === this.props.match.params.slaveMAC
      );
      console.log("Slave Unit", slaveUnit);
      const fullCal = this.state.fullCal;
      ctRatioMeter1 = slaveUnit[0].sm2CTr1; //Phase A; left A out for backwards compatibility
      ct2RatioMeter1 = slaveUnit[0].sm2Vr1; //Phase A
      ctRatioMeter2 = slaveUnit[0].sm2CTr2; //Phase A
      ct2RatioMeter2 = slaveUnit[0].sm2Vr2; //Phase A

      const voltageReading_1a = slaveUnit[0].sm2VAN1;
      const voltageReading_1b = slaveUnit[0].sm2VBN1;
      const voltageReading_1c = slaveUnit[0].sm2VCN1;

      const currentReading_1a = slaveUnit[0].sm2IA1;
      const currentReading_1b = slaveUnit[0].sm2IB1;
      const currentReading_1c = slaveUnit[0].sm2IC1;

      const voltageReading_2a = slaveUnit[0].sm2VAN2;
      const voltageReading_2b = slaveUnit[0].sm2VBN2;
      const voltageReading_2c = slaveUnit[0].sm2VCN2;

      const currentReading_2a = slaveUnit[0].sm2IA2;
      const currentReading_2b = slaveUnit[0].sm2IB2;
      const currentReading_2c = slaveUnit[0].sm2IC2;

      const currentEntered_1a = this.state.data.ctRatioMeter1;
      const voltageEntered_1a = this.state.data.ct2RatioMeter1;
      const currentEntered_2a = this.state.data.ctRatioMeter2;
      const voltageEntered_2a = this.state.data.ct2RatioMeter2;

      const voltageRatio_1a = voltageReading_1a / voltageEntered_1a;
      const currentRatio_1a = currentReading_1a / currentEntered_1a;
      const voltageRatio_2a = voltageReading_2a / voltageEntered_2a;
      const currentRatio_2a = currentReading_2a / currentEntered_2a;

      ctRatioMeter1 = (ctRatioMeter1 * currentRatio_1a).toFixed(2).toString();
      ct2RatioMeter1 = (ct2RatioMeter1 * voltageRatio_1a).toFixed(2).toString();
      ctRatioMeter2 = (ctRatioMeter2 * currentRatio_2a).toFixed(2).toString();
      ct2RatioMeter2 = (ct2RatioMeter2 * voltageRatio_2a).toFixed(2).toString();

      if (fullCal === 1) {
        ctRatioMeter1b = slaveUnit[0].sm2CTr1b;
        ctRatioMeter1c = slaveUnit[0].sm2CTr1c;

        ct2RatioMeter1b = slaveUnit[0].sm2Vr1b;
        ct2RatioMeter1c = slaveUnit[0].sm2Vr1c;

        ctRatioMeter2b = slaveUnit[0].sm2CTr2b;
        ctRatioMeter2c = slaveUnit[0].sm2CTr2c;

        ct2RatioMeter2b = slaveUnit[0].sm2Vr2b;
        ct2RatioMeter2c = slaveUnit[0].sm2Vr2c;

        const currentEntered_1b = this.state.data.ctRatioMeter1b;
        const currentEntered_1c = this.state.data.ctRatioMeter1c;

        const voltageEntered_1b = this.state.data.ct2RatioMeter1b;
        const voltageEntered_1c = this.state.data.ct2RatioMeter1c;

        const currentEntered_2b = this.state.data.ctRatioMeter2b;
        const currentEntered_2c = this.state.data.ctRatioMeter2c;

        const voltageEntered_2b = this.state.data.ct2RatioMeter2b;
        const voltageEntered_2c = this.state.data.ct2RatioMeter2c;

        const voltageRatio_1b = voltageReading_1b / voltageEntered_1b;
        const voltageRatio_1c = voltageReading_1c / voltageEntered_1c;

        const currentRatio_1b = currentReading_1b / currentEntered_1b;
        const currentRatio_1c = currentReading_1c / currentEntered_1c;

        const voltageRatio_2b = voltageReading_2b / voltageEntered_2b;
        const voltageRatio_2c = voltageReading_2c / voltageEntered_2c;

        const currentRatio_2b = currentReading_2b / currentEntered_2b;
        const currentRatio_2c = currentReading_2c / currentEntered_2c;

        ctRatioMeter1b = (ctRatioMeter1b * currentRatio_1b)
          .toFixed(2)
          .toString();
        ctRatioMeter1c = (ctRatioMeter1c * currentRatio_1c)
          .toFixed(2)
          .toString();

        ct2RatioMeter1b = (ct2RatioMeter1b * voltageRatio_1b)
          .toFixed(2)
          .toString();
        ct2RatioMeter1c = (ct2RatioMeter1c * voltageRatio_1c)
          .toFixed(2)
          .toString();

        ctRatioMeter2b = (ctRatioMeter2b * currentRatio_2b)
          .toFixed(2)
          .toString();
        ctRatioMeter2c = (ctRatioMeter2c * currentRatio_2c)
          .toFixed(2)
          .toString();

        ct2RatioMeter2b = (ct2RatioMeter2b * voltageRatio_2b)
          .toFixed(2)
          .toString();
        ct2RatioMeter2c = (ct2RatioMeter2c * voltageRatio_2c)
          .toFixed(2)
          .toString();
      } else {
        ctRatioMeter1b = ctRatioMeter1;
        ctRatioMeter1c = ctRatioMeter1;

        ct2RatioMeter1b = ct2RatioMeter1;
        ct2RatioMeter1c = ct2RatioMeter1;

        ctRatioMeter2b = ctRatioMeter2;
        ctRatioMeter2c = ctRatioMeter2;

        ct2RatioMeter2b = ct2RatioMeter2;
        ct2RatioMeter2c = ct2RatioMeter2;
      }

      const slaveMAC = this.props.match.params.slaveMAC;
      console.log("SlaveMAC:", slaveMAC);
      const calParms = {
        ctRatioMeter1: ctRatioMeter1,
        ctRatioMeter1b: ctRatioMeter1b,
        ctRatioMeter1c: ctRatioMeter1c,
        ct2RatioMeter1: ct2RatioMeter1,
        ct2RatioMeter1b: ct2RatioMeter1b,
        ct2RatioMeter1c: ct2RatioMeter1c,
        ctRatioMeter2: ctRatioMeter2,
        ctRatioMeter2b: ctRatioMeter2b,
        ctRatioMeter2c: ctRatioMeter2c,
        ct2RatioMeter2: ct2RatioMeter2,
        ct2RatioMeter2: ct2RatioMeter2,
        ct2RatioMeter2b: ct2RatioMeter2b,
        ct2RatioMeter2c: ct2RatioMeter2c,
        voltageWiring1: this.state.data.voltageWiring1,
        voltageWiring2: this.state.data.voltageWiring2,
        CTtype: this.state.data.CTtype,
        GridFrequency: this.state.data.GridFrequency,
        VmeasGain: this.state.data.VmeasGain,
        ImeasGain1: this.state.data.ImeasGain1,
        ImeasDigitalGain1: this.state.data.ImeasDigitalGain1,
        ImeasGain2: this.state.data.ImeasGain2,
        ImeasDigitalGain2: this.state.data.ImeasDigitalGain2,
      };
      console.log("Calibration Parameters:", calParms);
      if (slaveMAC !== undefined) {
        await setSystemSignals(
          this.state.systemID,
          CALIBRATE,
          "",
          "",
          calParms,
          slaveMAC
        );
        await initSystemUpdate(this.state.handleId);
        const sleepTime = this.state.sleepTime;
        this.sleep(sleepTime);
        await setSystemSignals(
          this.state.systemID,
          "0",
          "",
          "",
          "",
          "FF:FF:FF:FF:FF:FF"
        );
        await initSystemUpdate(this.state.handleId);
      }
    } else if (
      (this.state.systemConfiguration === "6" ||
        this.state.systemConfiguration === "7" ||
        this.state.systemConfiguration === "8") &&
      response.data.deviceLedger.slaveUnits !== undefined &&
      this.state.simplModulesCalibrate === 0
    ) {
      const slaveUnits = response.data.deviceLedger.slaveUnits;
      console.log("Slave Units", slaveUnits);

      const slaveUnit = slaveUnits.filter(
        (thisSlave) => thisSlave.MAC === this.props.match.params.slaveMAC
      );
      console.log("Slave Unit", slaveUnit);
      const slaveMAC = this.props.match.params.slaveMAC;
      console.log("SlaveMAC:", slaveMAC);
      const calParms = {
        voltageWiring1: this.state.data.voltageWiring1,
        GridFrequency: this.state.data.GridFrequency,
        meter1CTtype: this.state.data.meter1CTtype,
        meter2CTtype: this.state.data.meter2CTtype,
        circuitNameMeter2: this.state.data.circuitNameMeter2,
      };
      console.log("Calibration Parameters:", calParms);
      if (slaveMAC !== undefined) {
        await setSystemSignals(
          this.state.systemID,
          CALIBRATE,
          "",
          "",
          calParms, //calParms,
          slaveMAC
        );
        await initSystemUpdate(this.state.handleId);
        const sleepTime = this.state.sleepTime;
        this.sleep(sleepTime);
        await setSystemSignals(
          this.state.systemID,
          "0",
          "",
          "",
          "",
          "FF:FF:FF:FF:FF:FF"
        );
        await initSystemUpdate(this.state.handleId);
      }
    } else if (
      this.state.systemConfiguration === "3" &&
      this.state.simplModulesCalibrate === 0
    ) {
      console.log(
        "SimplMeter II Master unit, simply put the data in the systemBasics..."
      );
    }
    ////////////////////////////////////////////////////////////////////////////////////////
    await setSystemBasics(
      this.state.systemID,
      this.state.data.systemDescription,
      this.state.data.systemConfiguration,
      this.state.systemProduct,
      this.state.systemType,
      this.state.data.systemTimeZone,
      this.state.data.dataVerbosity,
      this.state.data.dataFrequency,
      this.state.data.localLogLength,
      this.state.data.localLogFrequency,
      this.state.data.localLogVerbosity,
      this.state.data.resetDelay,
      this.state.data.paramUpdateRate,
      this.state.data.intervalDataPeriod,
      this.state.data.circuitNameMeter1,

      ctRatioMeter1,
      ct2RatioMeter1,

      this.state.data.ctDirectionMeter1A,
      this.state.data.ctDirectionMeter1B,
      this.state.data.ctDirectionMeter1C,
      this.state.data.voltageWiring1,
      this.state.data.ctWiring1,
      this.state.data.demandMode1,
      this.state.data.circuitNameMeter2,

      ctRatioMeter2,
      ct2RatioMeter2,

      this.state.data.ctDirectionMeter2A,
      this.state.data.ctDirectionMeter2B,
      this.state.data.ctDirectionMeter2C,
      this.state.data.voltageWiring2,
      this.state.data.ctWiring2,
      this.state.data.demandMode2,

      this.state.productSN,
      this.state.slaveSN,
      this.state.fourGserialNumber,

      this.state.endProductPN,
      this.state.masterBoardSN,

      this.state.data.CTtype,
      this.state.data.GridFrequency,
      this.state.data.VmeasGain,
      this.state.data.ImeasGain1,
      this.state.data.ImeasDigitalGain1,
      this.state.data.ImeasGain2,
      this.state.data.ImeasDigitalGain2,

      this.state.data.meter1CTtype,
      this.state.data.meter2CTtype
    );

    const systemBasicsRaw = await getSystemBasics(
      this.props.match.params.systemHandleID
    );
    console.log("System Basics Raw", systemBasicsRaw);
    await updateTimeZone(this.state.systemSN, this.state.data.systemTimeZone);
    this.props.history.goBack();
  };

  handleChangeRadio = (e) => {
    let simplModulesCalibrate = this.state.simplModulesCalibrate;
    if (simplModulesCalibrate === 0) simplModulesCalibrate = 1;
    else simplModulesCalibrate = 0;
    console.log("SimplModule", simplModulesCalibrate);
    this.setState({ simplModulesCalibrate });
  };
  handleFullCal = (e) => {
    let fullCal = this.state.fullCal;
    if (fullCal === 0) fullCal = 1;
    else fullCal = 0;
    console.log("Simpl Calibration? ", fullCal);
    this.setState({ fullCal });
  };
  render() {
    console.log(
      "System Configuration in Render",
      this.state.systemConfiguration
    );
    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "200px",
            paddingBottom: "100px",
            zIndex: 1,
          }}
        >
          <p className="basicParagraph">
            Edit SimplModules parameters for {this.state.systemName}:
          </p>

          <form onSubmit={this.handleSubmit}>
            {(this.state.systemConfiguration === "1" ||
              this.state.systemConfiguration === "2") && (
              <div>
                {this.renderSelect(
                  "circuitNameMeter1",
                  "Specify the location of the measurement CTs for Meter 1:",
                  this.state.optionsMeasPoint0
                )}
                {this.renderInput(
                  "ctRatioMeter1",
                  "Specify CT1 Ratio for Meter 1"
                )}
                {this.renderInput(
                  "ct2RatioMeter1",
                  "Specify CT2 Ratio for Meter 1"
                )}
                {this.renderSelect(
                  "ctDirectionMeter1A",
                  "CT Direction for Meter 1; Phase A:",
                  this.state.optionsCTDirection
                )}
                {this.renderSelect(
                  "ctDirectionMeter1B",
                  "CT Direction for Meter 1; Phase B",
                  this.state.optionsCTDirection
                )}
                {this.renderSelect(
                  "ctDirectionMeter1C",
                  "CT Direction for Meter 1; Phase C",
                  this.state.optionsCTDirection
                )}
                {this.renderSelect(
                  "voltageWiring1",
                  "voltageWiring for Meter 1",
                  this.state.optionsVoltageWiring
                )}
                {this.renderSelect(
                  "ctWiring1",
                  "CT wiring for Meter 1",
                  this.state.optionsCTWiring
                )}
                {this.renderSelect(
                  "demandMode1",
                  "Demand Mode for Meter 1",
                  this.state.optionsDemandMode
                )}

                {this.state.systemConfiguration === "2" && (
                  <div>
                    {this.renderSelect(
                      "circuitNameMeter2",
                      "Specify the location of the measurement CTs for Meter 2:",
                      this.state.optionsMeasPoint1
                    )}

                    {this.renderInput(
                      "ctRatioMeter2",
                      "Specify CT1 Ratio for Meter 2"
                    )}
                    {this.renderInput(
                      "ct2RatioMeter2",
                      "Specify CT2 Ratio for Meter 2"
                    )}
                    {this.renderSelect(
                      "ctDirectionMeter2A",
                      "CT Direction for Meter 2; Phase A:",
                      this.state.optionsCTDirection
                    )}
                    {this.renderSelect(
                      "ctDirectionMeter2B",
                      "CT Direction for Meter 2; Phase B",
                      this.state.optionsCTDirection
                    )}
                    {this.renderSelect(
                      "ctDirectionMeter2C",
                      "CT Direction for Meter 2; Phase C",
                      this.state.optionsCTDirection
                    )}
                    {this.renderSelect(
                      "voltageWiring2",
                      "voltageWiring for Meter 2",
                      this.state.optionsVoltageWiring
                    )}
                    {this.renderSelect(
                      "ctWiring2",
                      "CT wiring for Meter 2",
                      this.state.optionsCTWiring
                    )}
                    {this.renderSelect(
                      "demandMode2",
                      "Demand Mode for Meter 2",
                      this.state.optionsDemandMode
                    )}
                  </div>
                )}
              </div>
            )}
            {/*888888888888888888888888888888888888888888888888888888888888888888888888888888888888888*/}
            <div className="form-check">
              <input
                className="form-check-input"
                style={{ marginTop: "8px" }}
                type="checkbox"
                name="registerReason"
                id="registerReason1"
                onChange={this.handleChangeRadio}
                value="reason1"
                // checked
              ></input>
              <label
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "300px",
                  paddingBottom: "15px",
                }}
              >
                Check here to Calibrate
              </label>
            </div>
            {this.state.simplModulesCalibrate === 1 && (
              <p className="basicParagraph" style={{ color: "red" }}>
                Before you start the calibration process, make sure Data Freq is
                set to once per second in system Basics.
              </p>
            )}
            {this.state.simplModulesCalibrate === 1 &&
              (this.state.systemConfiguration === "6" ||
                this.state.systemConfiguration === "7" ||
                this.state.systemConfiguration === "8") && (
                <div>
                  <input
                    className="form-check-input"
                    style={{ marginLeft: "20px" }}
                    type="checkbox"
                    name="registerReason"
                    id="registerReason1"
                    onChange={this.handleFullCal}
                    value="reason1"
                    // checked
                  ></input>
                  <label
                    className="basicParagraph"
                    style={{
                      marginLeft: "40px",
                      fontSize: "10px",
                      width: "300px",
                      marginBottom: "20px",
                    }}
                  >
                    Full 3-phase, 2-Channel Calibration?
                  </label>
                </div>
              )}
            {/*888888888888888888888888888888888888888888888888888888888888888888888888888888888888888*/}
            {this.state.simplModulesCalibrate !== 1 &&
              (this.state.systemConfiguration === "3" ||
                this.state.systemConfiguration === "6" ||
                this.state.systemConfiguration === "7" ||
                this.state.systemConfiguration === "8") && (
                <div>
                  {this.renderSelect(
                    "circuitNameMeter1",
                    "Specify the location of the measurement CTs for Meter 1:",
                    this.state.optionsMeasPoint0
                  )}
                  {this.renderSelect(
                    "meter1CTtype",
                    "Specify PN for Meter 1 CTs:",
                    this.state.optionsRopeCT
                  )}

                  {this.renderSelect(
                    "circuitNameMeter2",
                    "Specify the location of the measurement CTs for Meter 2:",
                    this.state.optionsMeasPoint1
                  )}
                  {this.renderSelect(
                    "meter2CTtype",
                    "Specify PN for Meter 2 CTs:",
                    this.state.optionsRopeCT
                  )}
                  {this.renderSelect(
                    "voltageWiring1",
                    "Voltage Wiring",
                    this.state.optionsVoltageWiring
                  )}

                  {this.renderSelect(
                    "GridFrequency",
                    "Select Grid Frequency",
                    this.state.optionsGridFreq
                  )}
                </div>
              )}
            {/*888888888888888888888888888888888888888888888888888888888888888888888888888888*/}
            {this.state.simplModulesCalibrate === 1 &&
              (this.state.systemConfiguration === "3" ||
                this.state.systemConfiguration === "6" ||
                this.state.systemConfiguration === "7" ||
                this.state.systemConfiguration === "8") && (
                <div>
                  {this.renderSelect(
                    "circuitNameMeter1",
                    "Specify the location of the measurement CTs for Meter 1:",
                    this.state.optionsMeasPoint0
                  )}
                  {this.renderInput(
                    "ctRatioMeter1",
                    "Enter Meter 1 Phase A Current reading"
                  )}
                  {this.state.fullCal === 1 &&
                    this.renderInput(
                      "ctRatioMeter1b",
                      "Enter Meter 1 Phase B Current reading"
                    )}
                  {this.state.fullCal === 1 &&
                    this.renderInput(
                      "ctRatioMeter1c",
                      "Enter Meter 1 Phase C Current reading"
                    )}

                  {this.renderInput(
                    "ct2RatioMeter1",
                    "Enter Meter 1 Phase A Voltage reading"
                  )}
                  {this.state.fullCal === 1 &&
                    this.renderInput(
                      "ct2RatioMeter1b",
                      "Enter Meter 1 Phase B Voltage reading"
                    )}
                  {this.state.fullCal === 1 &&
                    this.renderInput(
                      "ct2RatioMeter1c",
                      "Enter Meter 1 Phase C Voltage reading"
                    )}

                  {this.renderSelect(
                    "voltageWiring1",
                    "voltageWiring for Meter 1",
                    this.state.optionsVoltageWiring
                  )}
                  {this.renderSelect(
                    "ImeasGain1",
                    "Select Current Input Gain for Meter 1:",
                    this.state.optionsImeasGain
                  )}
                  {this.renderSelect(
                    "ImeasDigitalGain1",
                    "Select Digital Current Input Gain for Meter 1:",
                    this.state.optionsIdigitalGain
                  )}
                  {this.renderSelect(
                    "circuitNameMeter2",
                    "Specify the location of the measurement CTs for Meter 2:",
                    this.state.optionsMeasPoint1
                  )}
                  {this.renderInput(
                    "ctRatioMeter2",
                    "Enter Meter 2 Current reading from DMM"
                  )}
                  {this.state.fullCal === 1 &&
                    this.renderInput(
                      "ctRatioMeter2b",
                      "Enter Meter 2 Phase B Current reading"
                    )}
                  {this.state.fullCal === 1 &&
                    this.renderInput(
                      "ctRatioMeter2c",
                      "Enter Meter 2 Phase C Current reading"
                    )}

                  {this.renderInput(
                    "ct2RatioMeter2",
                    "Enter Meter 2 Phase A Voltage reading"
                  )}
                  {this.state.fullCal === 1 &&
                    this.renderInput(
                      "ct2RatioMeter2b",
                      "Enter Meter 2 Phase B Voltage reading"
                    )}
                  {this.state.fullCal === 1 &&
                    this.renderInput(
                      "ct2RatioMeter2c",
                      "Enter Meter 2 Phase C Voltage reading"
                    )}

                  {this.renderSelect(
                    "voltageWiring2",
                    "voltageWiring for Meter 2",
                    this.state.optionsVoltageWiring
                  )}
                  {this.renderSelect(
                    "ImeasGain2",
                    "Select Current Input Gain for Meter 2:",
                    this.state.optionsImeasGain
                  )}
                  {this.renderSelect(
                    "ImeasDigitalGain2",
                    "Select Digital Current Input Gain for Meter 2:",
                    this.state.optionsIdigitalGain
                  )}
                  {this.renderSelect(
                    "CTtype",
                    "CT or Rogowski",
                    this.state.optionsCTtype
                  )}
                  {this.renderSelect(
                    "VmeasGain",
                    "Select Voltage Input Gain:",
                    this.state.optionsVmeasGain
                  )}
                  {this.renderSelect(
                    "GridFrequency",
                    "Select Grid Frequency",
                    this.state.optionsGridFreq
                  )}
                </div>
              )}
            {/*888888888888888888888888888888888888888888888888888888888888888888888888888888888888888*/}
            {this.state.systemConfiguration === "4" && (
              <p>This is SimpleModule: SimplSwitch</p>
            )}
            {this.state.systemConfiguration === "5" && (
              <p>This is SimplLoads.</p>
            )}
            {this.renderButton("Save Parameters")}
          </form>
          <h6> </h6>
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default SimplModuleForm;
