import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/companies";

export async function getCompanies() {
  return await http.get(apiEndpoint);
}

export async function getCompany(companyName) {
  return await http.post(apiEndpoint + "/getCompany", {
    company: companyName
  });
}

export async function createCompany(companyName) {
  return await http.post(apiEndpoint + "/create", {
    name: companyName,
  });
}

export async function renameCompany(newCompanyName, oldCompanyName) {
  return await http.post(apiEndpoint + "/rename", {
    newCompanyName: newCompanyName,
    oldCompanyName: oldCompanyName,
  });
}

export async function updateCompanyObject(companyName, companyObject) {
  return await http.post(apiEndpoint + "/updateContact", {
    companyName: companyName,
    companyContact: companyObject,
  });
}

export async function updateCompanyContact(
  companyName,
  contactName,
  contactEmail,
  contactPhone,
  companyAddress,
  fleetInfo
) {
  return await http.post(apiEndpoint + "/updateContact", {
    companyName: companyName,
    companyContact: {
      contactName,
      contactEmail,
      contactPhone,
      companyAddress,
      fleetInfo: fleetInfo,
    },
  });
}

export async function removeCompany(name) {
  return await http.post(apiEndpoint + "/remove", {
    name: name,
  });
}

export async function restoreCompany(name) {
  return await http.post(apiEndpoint + "/restore", {
    name: name,
  });
}

export async function getCompanyUsers(companyName) {
  return await http.post(apiEndpoint + "/getUsers", {
    companyName: companyName,
  });
}

export async function getMasterCompanyUsers(companyName) {
  return await http.get(apiEndpoint + "/getUsers", {
    companyName: companyName,
  });
}

export async function getCompanySystems(companyName) {
  return await http.post(apiEndpoint + "/getSystems", {
    companyName: companyName,
  });
}

// NEW ROUTES
export async function controlAccess(transition) {
  return await http.post(apiEndpoint + "/updateCompanyMfa", {
    transition,
  });
}

export async function getUserHistory(companyName) {
  return await http.post(apiEndpoint + "/getCompaniesHistory", {
    companyName
  })
}

export async function getFleetInfo(companyName) {
  return await http.post(apiEndpoint + "/getCompanyFleets", {
    company: companyName
  })
}

export async function setFleetInfo(companyName, newFleet) {
  return await http.post(apiEndpoint + "/setCompanyFleets", {
    company: companyName,
    fleets: newFleet
  })
}

export async function getContactInfo(companyName) {
  return await http.post(apiEndpoint + "/contactInfo", {
    company: companyName
  })
}

// Simpl courses services
export async function getCourses() {
  return await http.get(apiEndpoint + "/getCoursesVideos")
}

export async function saveCourse(courses) {
  return await http.post(apiEndpoint + "/saveVideoCourse", {
    courses
  })
}
