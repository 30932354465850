import React, { Component } from "react";

import "./dashboard.css";
import "../index.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";

function SystemIcons(props) {
  return (
    <React.Fragment>
      <button
        //onClick={this.useMap}
        className="btn btn-outline-light"
        style={{
          transform: "translateX(105px)",
          borderWidth: "0px",
          borderColor: "white",
        }}
      >
        <FontAwesomeIcon
          icon="home"
          size="5x"
          color="green"
          title="Building"
          filter="drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"
        />
      </button>

      <table
        className="table"
        style={{
          backgroundColor: "white",
          transform: "translate(0px,150px)",
          borderColor: "none",
          borderTopColor: "white",
        }}
      >
        <tr>
          {props.systemConfiguration === "0" && (
            <td style={{ borderColor: "none", borderTopColor: "white" }}>
              <Link
                to={`/systems/dashboardWideSlaves/${props.systemHandleID}
                        /${props.companyName}`}
              >
                <FontAwesomeIcon
                  icon="car-battery"
                  size="5x"
                  color="dodgerblue"
                  title="Battery"
                  filter="drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"
                />
              </Link>
            </td>
          )}
          {props.systemConfiguration !== "0" && (
            <td style={{ borderColor: "none", borderTopColor: "white" }}>
              <Link
                to={`/systems/dashboardWideSlaves/${props.systemHandleID}
                        /${props.companyName}`}
              >
                <FontAwesomeIcon
                  icon="car-battery"
                  size="5x"
                  color="dodgerblue"
                  title="Battery"
                  opacity="0.3"
                />
              </Link>
            </td>
          )}

          <td
            style={{
              transform: "translate(-8px,0px)",
              borderColor: "none",
              borderTopColor: "white",
            }}
          >
            <Link to={`/logout`}>
              <FontAwesomeIcon
                icon="solar-panel"
                size="5x"
                color="orange"
                title="Solar"
                filter="drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"
              />
            </Link>
          </td>

          <td
            style={{
              transform: "translate(-8px,0px)",
              borderColor: "none",
              borderTopColor: "white",
            }}
          >
            <Link to={`/logout`}>
              <FontAwesomeIcon
                icon="charging-station"
                size="5x"
                color="purple"
                title="Grid"
                filter="drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"
              />
            </Link>
          </td>
        </tr>
      </table>
    </React.Fragment>
  );
}
export default SystemIcons;
