import React from "react";

const LightsSvg = (props) => (
  <svg
    style={{ margin: "0px", padding: "0px", height: "90px", width: "200px" }}
  >
    <svg
      height="200px"
      width="auto"
      id="ICONS"
      version="1.1"
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <text
          x="32"
          y="30"
          font-family="Verdana"
          font-size="6"
          font-weight="normal"
          fill={props.textColor}
        >
          {props.name}
        </text>
      </g>
    </svg>
    <svg
      version="1.1"
      width="120"
      height="auto"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.375,7.6439819V0.875H9.5c0.2070313,0,0.375-0.1679688,0.375-0.375S9.7070313,0.125,9.5,0.125h-3  c-0.2070313,0-0.375,0.1679688-0.375,0.375S6.2929688,0.875,6.5,0.875h1.125v6.7689819  C4.5606689,7.8389282,2.125,10.3873291,2.125,13.5c0,0.2070313,0.1679688,0.375,0.375,0.375h3.1629028  c0.1813354,1.1308594,1.1559448,2,2.3370972,2s2.1557617-0.8691406,2.3370972-2H13.5c0.2070313,0,0.375-0.1679688,0.375-0.375  C13.875,10.3873291,11.4393311,7.8389282,8.375,7.6439819z M8,8.375c2.6977539,0,4.8912354,2.1015015,5.0870972,4.75H2.9129028  C3.1087646,10.4765015,5.3022461,8.375,8,8.375z M8,15.125c-0.7664185,0-1.4066772-0.5349121-1.5770264-1.25h3.1540527  C9.4066772,14.5900879,8.7664185,15.125,8,15.125z"
        fill={props.strokeColor1}
      />
      <path
        d="M8.375,7.6439819V0.875H9.5c0.2070313,0,0.375-0.1679688,0.375-0.375S9.7070313,0.125,9.5,0.125h-3  c-0.2070313,0-0.375,0.1679688-0.375,0.375S6.2929688,0.875,6.5,0.875h1.125v6.7689819  C4.5606689,7.8389282,2.125,10.3873291,2.125,13.5c0,0.2070313,0.1679688,0.375,0.375,0.375h3.1629028  c0.1813354,1.1308594,1.1559448,2,2.3370972,2s2.1557617-0.8691406,2.3370972-2H13.5c0.2070313,0,0.375-0.1679688,0.375-0.375  C13.875,10.3873291,11.4393311,7.8389282,8.375,7.6439819z M8,8.375c2.6977539,0,4.8912354,2.1015015,5.0870972,4.75H2.9129028  C3.1087646,10.4765015,5.3022461,8.375,8,8.375z M8,15.125c-0.7664185,0-1.4066772-0.5349121-1.5770264-1.25h3.1540527  C9.4066772,14.5900879,8.7664185,15.125,8,15.125z"
        fill={props.strokeColor1}
        transform="translate(15,0)"
      />
      <path
        d="M8.375,7.6439819V0.875H9.5c0.2070313,0,0.375-0.1679688,0.375-0.375S9.7070313,0.125,9.5,0.125h-3  c-0.2070313,0-0.375,0.1679688-0.375,0.375S6.2929688,0.875,6.5,0.875h1.125v6.7689819  C4.5606689,7.8389282,2.125,10.3873291,2.125,13.5c0,0.2070313,0.1679688,0.375,0.375,0.375h3.1629028  c0.1813354,1.1308594,1.1559448,2,2.3370972,2s2.1557617-0.8691406,2.3370972-2H13.5c0.2070313,0,0.375-0.1679688,0.375-0.375  C13.875,10.3873291,11.4393311,7.8389282,8.375,7.6439819z M8,8.375c2.6977539,0,4.8912354,2.1015015,5.0870972,4.75H2.9129028  C3.1087646,10.4765015,5.3022461,8.375,8,8.375z M8,15.125c-0.7664185,0-1.4066772-0.5349121-1.5770264-1.25h3.1540527  C9.4066772,14.5900879,8.7664185,15.125,8,15.125z"
        fill={props.strokeColor1}
        transform="translate(30,0)"
      />
    </svg>
  </svg>
);
export default LightsSvg;
