import React, { Component } from "react";
import * as echarts from "echarts/dist/echarts.js";
import "bootstrap/dist/css/bootstrap.css";
import "./dashboard.css";
import {
  getSystemLatestData,
  ws_getSystemLatestData,
  getSystemInfo,
} from "../services/systemService";
import { zIndex } from "@material-ui/core/styles/zIndex";
import { webSocketMode } from "../config.json"; //0: http; 1:websocket

class GaugeGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pvPower: 0,
      batteryPower: 0,
    };
  }

  async componentDidMount() {
    let response = {};

    if (webSocketMode) {
      response = ws_getSystemLatestData(this.props.handleID);
      console.log("Websocket: Latest Data", response);
    } else {
      response = (await getSystemLatestData(this.props.handleID)).data.data[0];
      console.log("Http: Latest Data", response);
    }

    const systemInfo = await getSystemInfo(this.props.handleID);
    const systemConfiguration = systemInfo.data.data.basics.systemConfiguration;

    const chartStartAngle = 225;
    const chartEndAngle = -45;
    const chartMaxPower = 300;
    const chartMaxExport = 100;
    this.setState({
      systemConfiguration,
      chartStartAngle,
      chartEndAngle,
      chartMaxPower,
      chartMaxExport,
    });
    this.chart = echarts.init(document.getElementById("gauge-area-1"));
    this.timerID = setInterval(() => this.tick(), 1000);
    this.generateGraph();
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  async getSystemData() {
    let response = {};

    if (webSocketMode) {
      response = ws_getSystemLatestData(this.props.handleID);
      console.log("Websocket: Latest Data", response);
    } else {
      response = (await getSystemLatestData(this.props.handleID)).data.data[0];
      console.log("Http: Latest Data", response);
    }
    this.setState({
      realTime: response.data.deviceLedger.realTime,
    });
    this.setState({
      acuvimData: response.data.deviceLedger.acuvim,
    });

    let pvPower = 0;
    let batteryPower = 0;
    let gridPower = 0;
    let bldgPower = 0;
    let overallPower = 0;
    let batterySOC = 0;
    let minPower = this.state.chartMaxExport;
    if (
      this.state.systemConfiguration ===
      "0" /* &&
      this.state.realTime.slaveCount > 0 have to extract slaveCount*/
    ) {
      pvPower = this.state.realTime.pvPower / 100;
      batteryPower = this.state.realTime.batteryPower / 100;
      overallPower = (((pvPower + batteryPower) * 100.00001) / 100).toFixed(0);

      batterySOC = this.state.realTime.battSOC;
    } else if (this.state.systemConfiguration === "2") {
      pvPower = this.state.acuvimData.acuvimPower1;
      gridPower = this.state.acuvimData.acuvimPower0;
      bldgPower = pvPower + gridPower;
      if (gridPower < 0) minPower = Math.abs(gridPower);
      else minPower = this.state.chartMaxExport;
      overallPower = ((bldgPower * 100.0001) / 100).toFixed(0);
    }
    const maxPower = Math.max(
      pvPower,
      batteryPower,
      overallPower,
      gridPower,
      bldgPower
    );

    let chartMaxPower = this.state.chartMaxPower;
    if (this.state.chartMaxPower < maxPower);
    {
      if (maxPower < 1000) chartMaxPower = Math.ceil(maxPower / 100) * 100;
      else if (maxPower < 10000)
        chartMaxPower = Math.ceil(maxPower / 1000) * 1000;
      else if (maxPower < 100000)
        chartMaxPower = Math.ceil(maxPower / 10000) * 10000;
      else if (maxPower < 1000000)
        chartMaxPower = Math.ceil(maxPower / 100000) * 100000;
      else if (maxPower < 10000000)
        chartMaxPower = Math.ceil(maxPower / 1000000) * 1000000;
      console.log("Chart Max Power", chartMaxPower);
      this.setState({ chartMaxPower });
    }

    let chartMaxExport = this.state.chartMaxExport;
    if (minPower < 1000) chartMaxExport = Math.ceil(minPower / 100) * 100;
    else if (minPower < 10000)
      chartMaxExport = Math.ceil(minPower / 1000) * 1000;
    else if (minPower < 100000)
      chartMaxExport = Math.ceil(minPower / 10000) * 10000;
    else if (minPower < 1000000)
      chartMaxExport = Math.ceil(minPower / 100000) * 100000;
    else if (minPower < 10000000)
      chartMaxExport = Math.ceil(minPower / 1000000) * 1000000;
    console.log("Chart Max Export", chartMaxExport);
    this.setState({ chartMaxExport });
    //this.generateGraph();
    this.setState({
      pvPower,
      batteryPower,
      gridPower,
      bldgPower,
      overallPower,
      batterySOC,
    });
  }

  tick() {
    this.getSystemData();
    console.log("PV Power", this.state.pvPower);
    console.log("Battery Power", this.state.batteryPower);
    console.log("Grid Power", this.state.gridPower);
    //Basic Chart Parameters
    const chartStartAngle = this.state.chartStartAngle;
    const chartEndAngle = this.state.chartEndAngle;
    const chartSpanAngle = chartStartAngle - chartEndAngle; //Defines the total angle of the gauge
    const chartMaxPower = this.state.chartMaxPower; //The total angle is this many watts.  1 kW must be adjusted in a real system
    const chartMaxExport = this.state.chartMaxExport; //no more 100 Watts of net export
    const chartMaxSpan = chartMaxPower + chartMaxExport;
    const chartAnglePerWatt = chartSpanAngle / chartMaxSpan;

    const solarStartAngle =
      chartStartAngle - chartMaxExport * chartAnglePerWatt; /*180*/
    let solarAngle = solarStartAngle - this.state.pvPower * chartAnglePerWatt;
    if (solarAngle < chartEndAngle) solarAngle = chartEndAngle;

    const batteryStartAngle = solarAngle;
    let batteryAngle =
      batteryStartAngle - this.state.batteryPower * chartAnglePerWatt;
    let batteryPositiveAngle = 0;
    let batteryNegativeAngle = 0;
    if (this.state.batteryPower > 0) {
      if (batteryAngle < chartEndAngle) batteryAngle = chartEndAngle;
      batteryPositiveAngle = batteryAngle;
      batteryNegativeAngle = batteryStartAngle;
    } else {
      if (batteryAngle > chartStartAngle) batteryAngle = chartStartAngle;
      batteryNegativeAngle = batteryAngle;
      batteryPositiveAngle = batteryStartAngle;
    }

    const gridStartAngle = batteryAngle;
    let gridAngle = gridStartAngle - this.state.gridPower * chartAnglePerWatt;
    let gridPositiveAngle = 0;
    let gridNegativeAngle = 0;
    if (this.state.gridPower > 0) {
      if (gridAngle < chartEndAngle) gridAngle = chartEndAngle;
      gridPositiveAngle = gridAngle;
      gridNegativeAngle = gridStartAngle;
    } else {
      if (gridAngle > chartStartAngle) gridAngle = chartStartAngle;
      gridNegativeAngle = gridAngle;
      gridPositiveAngle = gridStartAngle;
    }

    //const bldgPower =
    //  this.state.pvPower + this.state.batteryPower + this.state.gridPower;

    this.chart.setOption({
      series: [
        {
          startAngle: solarStartAngle,
          endAngle: solarAngle,
          axisLine: {
            lineStyle: {
              color: [[1, "orange"]],
            },
          },
          //  data: [{ value: this.state.pvPower.toFixed(1) }]
        },
        {
          startAngle: batteryStartAngle,
          endAngle: batteryPositiveAngle,
          axisLine: {
            lineStyle: {
              color: [[1, "dodgerblue"]],
            },
          },
        },
        {
          clockwise: false,
          startAngle: batteryStartAngle,
          endAngle: batteryNegativeAngle,
          axisLine: {
            lineStyle: {
              color: [[1, "red"]],
            },
          },
        },
        {
          startAngle: gridStartAngle,
          endAngle: gridPositiveAngle,
          axisLine: {
            lineStyle: {
              color: [[1, "purple"]],
            },
          },
        },
        {
          clockwise: false,
          startAngle: gridStartAngle,
          endAngle: gridNegativeAngle,
          axisLine: {
            lineStyle: {
              color: [[1, "magenta"]],
            },
          },
        },
        {
          min: -1 * this.state.chartMaxExport,
          max: this.state.chartMaxPower,
          data: [{ value: this.state.overallPower }],
        },
        {
          data: [
            {
              value: (
                (this.state.pvPower * 100) /
                this.state.overallPower
              ).toFixed(0),
            },
          ],
        },
        {
          data: [
            {
              value: (
                (this.state.gridPower * 100) /
                this.state.overallPower
              ).toFixed(0),
            },
          ],
        },
        {
          data: [
            {
              value: (
                (this.state.batteryPower * 100) /
                this.state.overallPower
              ).toFixed(0),
            },
          ],
        },
        {
          data: [{ value: ((this.state.batterySOC * 100) / 100).toFixed(0) }],
        },
      ],
    });
  }

  generateGraph() {
    var option = {
      backgroundColor: "#242424" /*#1a1a1a"*/,
      tooltip: {
        formatter: "{a} <br/>{c} {b}",
      },
      /*toolbox: {
        show: true,
        feature: {
          mark: { show: true },
          restore: { show: true },
          saveAsImage: { show: true }
        }
      },*/
      series: [
        {
          name: "Main Chart",
          type: "gauge",
          min: -1 * this.state.chartMaxExport,
          max: this.state.chartMaxPower,
          startAngle: this.state.chartStartAngle,
          endAngle: this.state.chartEndAngle,
          splitNumber: 11,
          center: ["175px", "190px"],
          radius: "140px",
          axisLine: {
            show: false,
            lineStyle: {
              color: [[1, "orange"]],
              width: 10,
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          axisLabel: {
            show: false,
            textStyle: {
              fontWeight: "bolder",
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          axisTick: {
            show: false,
            length: 15,
            lineStyle: {
              color: "auto",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          splitLine: {
            show: false,
            length: 25,
            lineStyle: {
              width: 3,
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          pointer: {
            show: false,
            width: 3,
            shadowColor: "#fff",
            shadowBlur: 5,
          },
          title: {
            show: false,
            textStyle: {
              fontWeight: "bold",
              fontSize: 16,
              fontStyle: "normal",
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 5,
            },
          },
          detail: {
            show: false,
            backgroundColor: "#242424" /*#1a1a1a"*/,
            borderWidth: 0,
            borderColor: "#fff",
            shadowColor: "#fff",
            shadowBlur: 5,
            offsetCenter: [0, "50%"],
            textStyle: {
              fontWeight: "bold",
              color: "#fff",
              fontSize: 16,
            },
          },
          data: [{ value: 40, name: "% Solar" }],
        },
        {
          name: "Batteries",
          type: "gauge",
          center: ["175px", "190px"],
          radius: "140px",
          min: -1 * this.state.chartMaxExport,
          max: this.state.chartMaxPower,
          startAngle: this.state.chartStartAngle,
          endAngle: this.state.chartEndAngle,
          splitNumber: 10,
          axisLine: {
            lineStyle: {
              color: [
                [0.5, "lime"],
                [1, "dodgerblue"],
              ],
              width: 5,
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          axisLabel: {
            show: false,
            textStyle: {
              fontWeight: "bold",
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          axisTick: {
            show: false,
            length: 8,
            lineStyle: {
              color: "auto",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          splitLine: {
            show: false,
            length: 10,
            lineStyle: {
              width: 3,
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          pointer: {
            show: false,
            width: 2,
            shadowColor: "#fff",
            shadowBlur: 5,
          },
          title: {
            show: false,
            offsetCenter: [0, "-30%"],

            textStyle: {
              fontWeight: "bold",
              fontStyle: "normal",
              fontSize: 14,
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },

          detail: {
            show: false,
            borderColor: "#242424" /*#1a1a1a"*/,
            borderWidth: 0,
            borderColor: "#fff",
            shadowColor: "#fff",
            shadowBlur: 5,

            offsetCenter: [0, "40%"],
            textStyle: {
              fontWeight: "bold",
              color: "#fff",
              fontSize: 12,
            },
          },
          data: [{ value: 0, name: "Discharge" }],
        },
        {
          name: "Batteries",
          type: "gauge",
          center: ["175px", "190px"],
          radius: "140px",
          min: -1 * this.state.chartMaxExport,
          max: this.state.chartMaxPower,
          startAngle: this.state.chartStartAngle,
          endAngle: this.state.chartEndAngle,
          splitNumber: 10,
          axisLine: {
            lineStyle: {
              color: [
                [0.5, "lime"],
                [1, "dodgerblue"],
              ],
              width: 5,
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          axisLabel: {
            show: false,
            textStyle: {
              fontWeight: "bold",
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          axisTick: {
            show: false,
            length: 8,
            lineStyle: {
              color: "auto",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          splitLine: {
            show: false,
            length: 10,
            lineStyle: {
              width: 3,
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          pointer: {
            show: false,
            width: 2,
            shadowColor: "#fff",
            shadowBlur: 5,
          },
          title: {
            show: false,
            offsetCenter: [0, "-30%"],

            textStyle: {
              fontWeight: "bold",
              fontStyle: "normal",
              fontSize: 14,
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },

          detail: {
            show: false,
            borderColor: "#242424" /*#1a1a1a"*/,
            borderWidth: 0,
            borderColor: "#fff",
            shadowColor: "#fff",
            shadowBlur: 5,
            offsetCenter: [0, "40%"],
            textStyle: {
              fontWeight: "bold",
              color: "#fff",
              fontSize: 12,
            },
          },
          data: [{ value: 0, name: "Charge" }],
        },

        {
          name: "Grid",
          type: "gauge",
          center: ["175px", "190px"],
          radius: "125px",
          min: -1 * this.state.chartMaxExport,
          max: this.state.chartMaxPower,
          startAngle: this.state.chartStartAngle,
          endAngle: this.state.chartEndAngle,
          splitNumber: 10,
          axisLine: {
            lineStyle: {
              color: [
                [0.5, "lime"],
                [1, "dodgerblue"],
              ],
              width: 5,
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          axisLabel: {
            show: false,
            textStyle: {
              fontWeight: "bold",
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          axisTick: {
            show: false,
            length: 8,
            lineStyle: {
              color: "auto",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          splitLine: {
            show: false,
            length: 10,
            lineStyle: {
              width: 3,
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          pointer: {
            show: false,
            width: 2,
            shadowColor: "#fff",
            shadowBlur: 5,
          },
          title: {
            show: false,
            offsetCenter: [0, "-30%"],

            textStyle: {
              fontWeight: "bold",
              fontStyle: "normal",
              fontSize: 14,
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },

          detail: {
            show: false,
            borderColor: "#242424" /*#1a1a1a"*/,
            borderWidth: 0,
            borderColor: "#fff",
            shadowColor: "#fff",
            shadowBlur: 5,
            offsetCenter: [0, "40%"],
            textStyle: {
              fontWeight: "bold",
              color: "#fff",
              fontSize: 12,
            },
          },
          data: [{ value: 0, name: "Discharge" }],
        },
        {
          name: "Grid",
          type: "gauge",
          center: ["175px", "190px"],
          radius: "125px",
          min: -100,
          max: 100,
          startAngle: 135,
          endAngle: 45,
          splitNumber: 10,
          axisLine: {
            lineStyle: {
              color: [
                [0.5, "lime"],
                [1, "dodgerblue"],
              ],
              width: 5,
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          axisLabel: {
            show: false,
            textStyle: {
              fontWeight: "bold",
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          axisTick: {
            show: false,
            length: 8,
            lineStyle: {
              color: "auto",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          splitLine: {
            show: false,
            length: 10,
            lineStyle: {
              width: 3,
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          pointer: {
            show: false,
            width: 2,
            shadowColor: "#fff",
            shadowBlur: 5,
          },
          title: {
            show: false,
            offsetCenter: [0, "-30%"],
            textStyle: {
              fontWeight: "bold",
              fontStyle: "normal",
              fontSize: 14,
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },

          detail: {
            show: false,
            borderColor: "#242424" /*#1a1a1a"*/,
            borderWidth: 0,
            borderColor: "#fff",
            shadowColor: "#fff",
            shadowBlur: 5,
            // width: 80,
            //height:30,
            offsetCenter: [0, "40%"],
            textStyle: {
              fontWeight: "bold",
              color: "#fff",
              fontSize: 12,
            },
          },
          data: [{ value: 0, name: "Charge" }],
        },

        {
          name:
            "Building overall consumption.  Purple: buying; Magenta: selling.",
          type: "gauge",
          center: ["175px", "190px"],
          radius: "110px",
          min: -1 * this.state.chartMaxExport,
          max: this.state.chartMaxPower,
          startAngle: this.state.chartStartAngle,
          endAngle: this.state.chartEndAngle,
          splitNumber: 10,
          axisLine: {
            lineStyle: {
              color: [[1.0, "lime"]],
              width: 5,
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          axisLabel: {
            show: false,
            textStyle: {
              fontWeight: "normal",
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          axisTick: {
            //show: false,
            length: 8,
            lineStyle: {
              color: "auto",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          splitLine: {
            //show: false,
            length: 10,
            lineStyle: {
              width: 3,
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          pointer: {
            show: true,
            width: 2,
            shadowColor: "#fff",
            shadowBlur: 10,
          },
          title: {
            //show: false,
            offsetCenter: [0, "-30%"],

            textStyle: {
              fontWeight: "bold",
              fontStyle: "normal",
              fontSize: 14,
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          detail: {
            borderColor: "#242424" /*#1a1a1a"*/,
            borderWidth: 0,
            borderColor: "#fff",
            shadowColor: "#fff",
            shadowBlur: 5,
            offsetCenter: [0, "40%"],
            textStyle: {
              fontWeight: "normal",
              color: "#fff",
              fontSize: 12,
            },
          },
          data: [{ value: 1, name: "Charge" }],
        },

        {
          name: "Solar/Building",
          type: "gauge",
          center: ["10px", "50px"],
          radius: "50px",
          min: 100,
          max: 0,
          startAngle: -60,
          endAngle: 60,
          clockwise: false,
          splitNumber: 2,
          axisLine: {
            lineStyle: {
              color: [[1, "orange"]],
              width: 3,
              shadowColor: "#fff",
              shadowBlur: 5,
            },
          },
          axisLabel: {
            //show: false,
            textStyle: {
              fontSize: 8,
              fontWeight: "normal",
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          axisTick: {
            //show: false,
            length: 8,
            lineStyle: {
              color: "auto",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          splitLine: {
            //show: false,
            length: 10,
            lineStyle: {
              width: 3,
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          pointer: {
            //show: false,
            width: 2,
            shadowColor: "#fff",
            shadowBlur: 10,
          },
          title: {
            show: false,
            offsetCenter: [0, "-30%"],

            textStyle: {
              fontWeight: "bold",
              fontStyle: "normal",
              fontSize: 14,
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          detail: {
            show: false,
            borderColor: "#242424" /*#1a1a1a"*/,
            borderWidth: 0,
            borderColor: "#fff",
            shadowColor: "#fff",
            shadowBlur: 5,
            offsetCenter: [0, "40%"],
            textStyle: {
              fontWeight: "normal",
              color: "#fff",
              fontSize: 12,
            },
          },
          data: [{ value: 1 }],
        },

        {
          name: "Grid/Building",
          type: "gauge",
          center: ["10px", "320px"],
          radius: "50px",
          min: 100,
          max: -100,
          startAngle: -60,
          endAngle: 60,
          clockwise: false,
          splitNumber: 2,
          axisLine: {
            lineStyle: {
              color: [
                [0.5, "magenta"],
                [1, "purple"],
              ],
              width: 3,
              shadowColor: "#fff",
              shadowBlur: 5,
            },
          },
          axisLabel: {
            //show: false,
            textStyle: {
              fontSize: 8,
              fontWeight: "normal",
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          axisTick: {
            //show: false,
            length: 8,
            lineStyle: {
              color: "auto",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          splitLine: {
            //show: false,
            length: 10,
            lineStyle: {
              width: 3,
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          pointer: {
            //show: false,
            width: 2,
            shadowColor: "#fff",
            shadowBlur: 10,
          },
          title: {
            show: false,
            offsetCenter: [0, "-30%"],

            textStyle: {
              fontWeight: "bold",
              fontStyle: "normal",
              fontSize: 14,
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          detail: {
            show: false,
            borderColor: "#242424" /*#1a1a1a"*/,
            borderWidth: 0,
            borderColor: "#fff",
            shadowColor: "#fff",
            shadowBlur: 5,
            offsetCenter: [0, "40%"],
            textStyle: {
              fontWeight: "normal",
              color: "#fff",
              fontSize: 12,
            },
          },
          data: [{ value: 1 }],
        },
        {
          name: "battery/Building",
          type: "gauge",
          center: ["340px", "50px"],
          radius: "50px",
          min: -100,
          max: 100,
          startAngle: 240,
          endAngle: 120,
          clockwise: true,
          splitNumber: 2,
          axisLine: {
            lineStyle: {
              color: [
                [0.5, "red"],
                [1, "dodgerblue"],
              ],
              width: 3,
              shadowColor: "#fff",
              shadowBlur: 5,
            },
          },
          axisLabel: {
            //show: false,
            textStyle: {
              fontSize: 8,
              fontWeight: "normal",
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          axisTick: {
            //show: false,
            length: 8,
            lineStyle: {
              color: "auto",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          splitLine: {
            //show: false,
            length: 10,
            lineStyle: {
              width: 3,
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          pointer: {
            //show: false,
            width: 2,
            shadowColor: "#fff",
            shadowBlur: 10,
          },
          title: {
            show: false,
            offsetCenter: [0, "-30%"],

            textStyle: {
              fontWeight: "bold",
              fontStyle: "normal",
              fontSize: 14,
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          detail: {
            show: false,
            borderColor: "#242424" /*#1a1a1a"*/,
            borderWidth: 0,
            borderColor: "#fff",
            shadowColor: "#fff",
            shadowBlur: 5,
            offsetCenter: [0, "40%"],
            textStyle: {
              fontWeight: "normal",
              color: "#fff",
              fontSize: 12,
            },
          },
          data: [{ value: 1 }],
        },
        {
          name:
            "Battery State of Charge (SOC), Range: 0% is empty and 100% is full.",
          type: "gauge",
          center: ["340px", "320px"],
          radius: "50px",
          min: 0,
          max: 100,
          startAngle: 240,
          endAngle: 120,
          clockwise: true,
          splitNumber: 2,
          axisLine: {
            lineStyle: {
              color: [
                [0.2, "red"],
                [1, "lime"],
              ],
              width: 3,
              shadowColor: "#fff",
              shadowBlur: 5,
            },
          },
          axisLabel: {
            //show: false,
            textStyle: {
              fontSize: 8,
              fontWeight: "normal",
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          axisTick: {
            //show: false,
            length: 8,
            lineStyle: {
              color: "auto",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          splitLine: {
            //show: false,
            length: 10,
            lineStyle: {
              width: 3,
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          pointer: {
            //show: false,
            width: 2,
            shadowColor: "#fff",
            shadowBlur: 10,
          },
          title: {
            show: false,
            offsetCenter: [0, "-30%"],

            textStyle: {
              fontWeight: "bold",
              fontStyle: "normal",
              fontSize: 14,
              color: "#fff",
              shadowColor: "#fff",
              shadowBlur: 10,
            },
          },
          detail: {
            show: false,
            borderColor: "#242424" /*#1a1a1a"*/,
            borderWidth: 0,
            borderColor: "#fff",
            shadowColor: "#fff",
            shadowBlur: 5,
            offsetCenter: [0, "40%"],
            textStyle: {
              fontWeight: "normal",
              color: "#fff",
              fontSize: 12,
            },
          },
          data: [{ value: 1 }],
        },
      ],
    };
    this.chart.setOption(option);
  }

  render() {
    return <div id="gauge-area-1" zIndex="1" />;
  }
}
export default GaugeGraph;
