import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/users";

export async function loginUser(username, password) {
  return await http.post(apiEndpoint + "/login", {
    username: username,
    password: password
  });
}

export async function getUser() {
  return await http.get(apiEndpoint);
}

// New routes functions

export async function getVerificationCode(phone){
  return await http.post(apiEndpoint + '/getVerificationCode', {
    phone: phone
  });
}

export async function verifyCode(phone, code){
  return await http.post(apiEndpoint + '/verifyCode', {
    phone,
    code
  })
}

export async function recoverCredentialsByEmail(email){
  return await http.post(apiEndpoint + '/reset', {
    email,
  })
}

export async function getUserByEmail(email){
  return await http.post(apiEndpoint + '/getUserByEmail', {
    email,
  })
}

export async function getUserByUsername(username){
  return await http.post(apiEndpoint + '/getUserByUsername', {
    username,
  })
}

export async function googleLogin(token){
  return await http.post(apiEndpoint + '/googleLogin', {
    token,
  })
}

export async function addSubAdmin(username, companyName){
  return await http.post(apiEndpoint + '/addNewSubAdmin', {
    username,
    companyName
  })
}

export async function revokeSubAdmin(username, companyName){
  return await http.post(apiEndpoint + '/revokeSubAdmin', {
    username,
    companyName
  })
}

// ---

export async function logoutUser(username, password) {
  return await http.post(apiEndpoint + "/logout", {
    username: username,
    password: password
  });
}

export async function unregisterUser(username) {
  return await http.post(apiEndpoint + "/unregister", {
    username: username
  });
}

export async function restoreUser(username) {
  return await http.post(apiEndpoint + "/restore", {
    username: username
  });
}

export async function getUserSystems() {
  return await http.get(apiEndpoint + "/getSystems");
}

export async function registerUser(
  username,
  password,
  firstName,
  lastName,
  emailAddress,
  phoneNumber
) {
  return await http.post(apiEndpoint + "/register", {
    username: username,
    password: password,
    firstName: firstName,
    lastName: lastName,
    emailAddress: emailAddress,
    phoneNumber: phoneNumber
  });
}

export async function updateName(firstName, lastName) {
  return await http.post(apiEndpoint + "/updateName", {
    firstName: firstName,
    lastName: lastName
  });
}

export async function updateEmail(emailAddress) {
  return await http.post(apiEndpoint + "/updateEmail", {
    emailAddress: emailAddress
  });
}

export async function updatePhone(phoneNumber) {
  return await http.post(apiEndpoint + "/updateTelephone", {
    phoneNumber: phoneNumber
  });
}

export async function updatePassword(password) {
  return await http.post(apiEndpoint + "/updatePassword", {
    password: password
  });
}

export async function addOwner(username, serialNumber, companyName) {
  return await http.post(apiEndpoint + "/addSystemAsOwner", {
    username: username,
    serialNumber: serialNumber,
    companyName
  });
}

export async function addInstaller(username, serialNumber, companyName) {
  return await http.post(apiEndpoint + "/addSystemAsInstaller", {
    username: username,
    serialNumber: serialNumber,
    companyName
  });
}

export async function addOperator(username, serialNumber, companyName) {
  return await http.post(apiEndpoint + "/addSystemAsOperator", {
    username: username,
    serialNumber: serialNumber,
    companyName
  });
}

export async function removeOwner(username, serialNumber) {
  return await http.post(apiEndpoint + "/removeSystemAsOwner", {
    username: username,
    serialNumber: serialNumber
  });
}

export async function removeInstaller(username, serialNumber) {
  return await http.post(apiEndpoint + "/removeSystemAsInstaller", {
    username: username,
    serialNumber: serialNumber
  });
}

export async function removeOperator(username, serialNumber) {
  return await http.post(apiEndpoint + "/removeSystemAsOperator", {
    username: username,
    serialNumber: serialNumber
  });
}

export async function addCompanyAdmin(username, company) {
  return await http.post(apiEndpoint + "/addUserAsAdmin", {
    username: username,
    companyName: company
  });
}

export async function revokeCompanyAdmin(username, company) {
  return await http.post(apiEndpoint + "/revokeUserAsAdmin", {
    username: username,
    companyName: company
  });
}

export async function addUserToCompany(username) {
  return await http.post(apiEndpoint + "/updateCompany", {
    username: username
  });
}

export async function getAllUsers() {
  return await http.get(apiEndpoint + "/all");
}

/************* Routes for filters in companySystems page ************/
/**
 * Get user filter json from db
 */
export async function getUserFilters() {
  return await http.get(apiEndpoint + "/userFilters");
}

/**
 * Set user filters json to db
 */
export async function setUserFilters(active, type, value) {
  return await http.post(apiEndpoint + "/setFilters", {
    filters: {
      active,
      type,
      value
    }
  });
}
/*********************************************************************/
