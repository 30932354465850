import React from "react";

const WPerimeterSvg = (props) => (
  <svg
    style={{ margin: "0px", padding: "0px", width: "800px", height: "425px" }}
  >
    <svg
      width="1000px"
      height="1000px"
      id="ICONS"
      version="1.1"
      viewBox="0 0 1000 1000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        stroke={props.rectColor}
        stroke-width="1"
        d="M 50 2 L 50 50, 2 50, 2 370, 50 370, 50 420, 375 420, 375 370, 425 370, 425 420, 750 420, 750 370, 795 370, 795 50, 750 50, 750 2, 425 2, 425 50, 375 50, 375 2Z"
      />
    </svg>
  </svg>
);
export default WPerimeterSvg;
