import { logoutUser } from "../services/userService";
import Form from "./common/form";

class LogoutForm extends Form {
  state = {
    data: { username: "", password: "" },
    errors: {},
  };

  async componentDidMount() {
    try {
      const { data } = this.state;
      await logoutUser(data.username, data.password);
      localStorage.removeItem("token");
      localStorage.removeItem("userValidated");
      //   window.location = "/login";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors: errors });
      }
    }
    window.location = "/";
  }

  render() {
    return null;
  }
}

export default LogoutForm;
