import React from "react";

const NoTempWaterheaterSvg = (props) => (
  <svg
    style={{ margin: "0px", padding: "0px", height: "90px", width: "200px" }}
  >
    <text
      x="4"
      y="10"
      font-family="Verdana"
      font-size="10"
      font-weight="normal"
      fill={props.textColor}
    >
      {props.name}
    </text>
    <svg
      height="200px"
      width="auto"
      id="ICONS"
      version="1.1"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke={props.strokeColor1}
        fill="none"
        stroke-width="2px"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10px"
        transform="translate(0,15)"
      >
        <circle cx="32" cy="42" r="1" fill="none" />
        <circle cx="37" cy="44" r="1" fill="none" />
        <circle cx="27" cy="44" r="1" fill="none" />
        <path d="   M45,54c0,1.104-0.896,2-2,2H21c-1.104,0-2-0.896-2-2V11c0-1.104,0.896-2,2-2h22c1.104,0,2,0.896,2,2V54z" />
        <polyline points="   41,56 41,60 37,60 37,56  " />
        <polyline points="   27,56 27,60 23,60 23,56  " />
        <path d="M42,9   V7c0-1.104-0.896-2-2-2h-8h-8c-1.104,0-2,0.896-2,2v2" />
        <path d="   M23,56V40c0-1.104,0.896-2,2-2h14c1.104,0,2,0.896,2,2v16" />
        <path d="   M35,23c1.916-0.125,2.834-3.542,1-6c0,2.083-2,1.719-2,3c-1.958-1.958,1.063-1.938-2-5c0,3-1.916,2-1.916,5c0,0-1.084-3-3.084-3   c2.063,2.063-1.542,3.458,1,6" />
        <line x1="28" x2="36" y1="27" y2="27" />
        <circle cx="32" cy="49.001" r="3" />
        <line x1="32" x2="32" y1="48" y2="46" />
      </g>
    </svg>
  </svg>
);
export default NoTempWaterheaterSvg;
