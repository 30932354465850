import React, { useEffect, useState, useMemo, Component } from "react";
import {
  loadTypesSchema,
  NO_CONVERSION,
  CONVERT_SEC_TO_MIN,
  CONVERT_C100_TO_F,
  CONVERT_X100_TO_X
} from "../config.json";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

import { getUser } from "../services/userService";
import {
  getSystemLatestData,
  getSystemIDfromHandle,
  getSystem,
  getAllAlarms,
  getAlarm,
  saveSystemAlarms,
  checkConnectionInfo

} from "../services/systemService";

import "./dashboard.css";
import "../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import "react-datepicker/dist/react-datepicker.css";

import { toast } from "react-toastify";

// Table component containing the bar and the table showed with the respective functionalities
function NotificationsTable({nodeName, handleID, systemName, user}) {

  // Elements of the frecuency dropdown
  const frequencyElements = [
    "Every Minute", "Every 5 Minutes", "Every 15 Minutes", "Every Hour", "Every Three Hours",
    "Every Six Hours", "Every Twelve Hours", "Daily", "Weekly", "Monthly"
  ]

  // Main notifications array that changes dinamycally on the user actions
  const [notificationSettings, setNotificationSettings] = useState([])

  // Setting state on system checkbox change
  const [isSystemActive, setSystemActive] = useState(true)

  // Check and set connection for the system
  const [connectionStatus, setConnectionStatus] = useState(null)

  // array of objs to handle the alarms of the user
  const [userAlarms, setUserAlarms] = useState([])

  // State that waits until connection is established to show data 
  const [isMounted, setIsMounted] = useState({systemMount: false, applianceMount: false, alarmsData: false, connectionStatus: false})

  // Info data for the current node appliance
  const [loadTypeObj, switchLoadTypeObj] = useState({})

  // Info data for the current system
  const [systemTypeObj, switchSystemTypeObj] = useState({})

  // Load type name added for every alarm
  const [loadType, setLoadType] = useState("")

  // State that is set according to the last action made by the user
  const [lastAction, setLastAction] = useState("")

  // Parameter that sets if current alarms are going to be activated or not
  // const [activatedAlarms, setActivatedAlarms] = useState(true)

  // State controlling inital declaration of activatedAlarms state
  const [isInitialDeclaration, setIsInitialDeclaration] = useState(true)

  // Counter tracking activated alarms state
  const [counter, setCounter] = useState(0)

  // Function to check valid phone numbers
  function checkPhoneNumer(phoneNumber){
    const regex = /^(\+1)?\s*\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return regex.test(phoneNumber);
  }

  function formatPhoneNumber(phoneNumber){
    let formattedPhoneNumber = phoneNumber
    if (phoneNumber.slice(0, 2) === "+1" ){
      formattedPhoneNumber = phoneNumber.slice(2)
    }

    return ( '+1' + formattedPhoneNumber.replace(/[^0-9]/g, ''))
  }

  // Function to convert the dropdown options in a descriptive way
  function transformToUpper(str){
    const myStr = str.split('_').join(' ')
    const arr = myStr.split(" ")
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");

    return str2
  }

  // Function to get the formula according the loadtype of the alarm
  function getFormula(obj, name){
    const tmp = obj.data.find(element => 
      element.name === name
    )
    const formula = tmp.formula
    let formulaVal = formula

    if (formula === "NO_CONVERSION"){
      formulaVal = NO_CONVERSION
    } else if (formula === "CONVERT_SEC_TO_MIN"){
      formulaVal = CONVERT_SEC_TO_MIN
    } else if (formula === "CONVERT_C100_TO_F"){
      formulaVal = CONVERT_C100_TO_F
    } else if (formula === "CONVERT_X100_TO_X"){
      formulaVal = CONVERT_X100_TO_X
    }

    return formulaVal
  }

  // Function to add a new alarm to the notificationSettings array
  const addNewRow = () => {
    try {
      if (userAlarms.length === 0){
        const emptyRow = {
          id: null,
          checked: true,
          active: true,
          alarmName: "",
          alarmTitle: "",
          formula: "",
          type: !isSystemActive ? "appliance" : "system",
          loadType: !isSystemActive ? loadType : "System",
          connection: connectionStatus,
          range: {
            name:"between",
            limit1: 0,
            limit2: 0,
            limit3: 0
          },
          frequency:{
            name:"",
            cellphone: false,
            email:false
          },
          contactInfo: {
            emailAddress: user.emailAddress,
            phoneNumber: '+1' + user.phoneNumber,
            username: user.username
          }
        }
        setNotificationSettings([...notificationSettings, emptyRow])
      } else {
        toast.warning("You can not add more than one System Connection Alarm")
      }

    } catch (e){
      toast.error("Failed to create a new row")
      console.log(e.message);
    }
  }

  // Function to remove the selected alarm from notificationSettings array
  const deleteRow = (index) => {
    try {
      let newArr = [...notificationSettings]
      newArr.splice(index, 1)
      setNotificationSettings(newArr)
      console.log("Row removed!");
    } catch(e){
      toast.error("Fail to delete row");
      console.log(e.message);
    }
  }

  // Function to select the alarm that will be triggered
  const handleAlarmCheckBox = (event, index) => {
    try {
      const tag = event.target.tagName;
      const check = event.target.checked;
      const value = event.target.value;
      let formula;

      let newArr = [...notificationSettings]

      if (tag === 'SELECT'){
        const handler = event.target.options[event.target.selectedIndex];
        const text = handler.text
        // const shortDescription = handler.title
        newArr[index].alarmTitle = text
        newArr[index].alarmName = value

        if (newArr[index].type === "system"){
          formula = getFormula(systemTypeObj, value)
          newArr[index].formula = formula
        } else {
          formula = getFormula(loadTypeObj, value)
          newArr[index].formula = formula
        }
      } else {
        newArr[index].checked = check
      }
      setNotificationSettings(newArr)
      console.log("Checkbox");
      console.log(notificationSettings);
    } catch (e){
      console.log(e.message);
      toast.error('Fail to set checkbox alarm into obj')
    }
  }


  // Function to handle the frequency of the alarm, the destination options, and if it'll be cheked or not 
  const handleFrequency = (event, index) => {

    try {
      const tag = event.target.tagName;
      const check = event.target.checked;
      const value = event.target.value;
      let name = event.target.name;

      let newArr = [...notificationSettings]

      if (tag === "SELECT"){
        const newVal = value.split(' ').join('_').toLowerCase()
        newArr[index].frequency.name = newVal
      } else {
        if (name === "cellphone"){
          if (!checkPhoneNumer(newArr[index].contactInfo.phoneNumber)){
            newArr[index].frequency.cellphone = false
            toast.error('Please register a valid US Phone Number before selecting this checkbox')
          } else {
            newArr[index].frequency.cellphone = check
            newArr[index].contactInfo.phoneNumber = formatPhoneNumber(newArr[index].contactInfo.phoneNumber)
          }
        } else if (name === "email"){
          newArr[index].frequency.email = check
        }
      }

      if (!newArr[index].frequency.cellphone && !newArr[index].frequency.email) {
        newArr[index].checked = false
      } else {
        newArr[index].checked = true
      }

      setNotificationSettings(newArr)

      console.log("Frecuency");
      console.log(notificationSettings);
    } catch (e){
      console.log(e.message);
      toast.error('Frecuency Error')
    }
  }

  const showToastMessage = (action, notificationSettings) => {
    try {
      if (action === "empty"){
        toast.warning("No alarms are available for this device")
      } else if (action === "remove" && notificationSettings.length === 0){
        toast.success("All modifications have been successfully applied")
        setLastAction("empty")
      } else if (action === "add" || action === "" || action === "activate" || action === "unactivate"){
        if (notificationSettings.length > 0){
          notificationSettings.some((item, index)=> {
            if (item.checked){
              if (action === "activate" || action === "unactivate"){
                toast.success("All modifications have been successfully applied")
              } else {
                toast.success("The new alarm(s) has/have been successfully added and stored")
              }
              return true
            }
            if (!item.checked && index === notificationSettings.length - 1){
              toast.warning("No alarms are available for this device")
              return true
            }
          })
        } else {
          toast.warning("No alarms are available for this device")
        }
      } else if (action === "remove") {
        toast.success("All modifications have been successfully applied")
      } else {
        toast.success("All modifications have been successfully applied")
      }
    } catch (e){
      toast.error("It was not possible to do this")
    }
  }

  // Function to save the alarms in the database
  const saveAlarms = async (notificationSettings) => {
    try {
      let allAlarmsNodeElements = (await getAllAlarms(handleID)).data.data
      let existingAlarm = (await getAlarm(handleID, nodeName)).data.data
      let username = (await getUser()).data.data.username;
      if (!username) {
        username = "Unknown"
      }

      if (Array.isArray(existingAlarm)){
        allAlarmsNodeElements.push({
          Appliance: {
            nodeName,
            systemName,
            alarms: [...notificationSettings],
          }
        })
      }

      let newAlarmsObj = allAlarmsNodeElements.map((item) => {
        if (item.Appliance.nodeName === nodeName){
          return {
            nodeName,
            systemName,
            // active: activatedAlarms,
            alarms: notificationSettings
          }
        }
        return item.Appliance
      })

      newAlarmsObj = newAlarmsObj.filter(element => element.alarms.length > 0)

      console.log("Alarms OBJ that will be stored:");
      console.log(newAlarmsObj);

      const data = (await saveSystemAlarms(handleID, newAlarmsObj, systemName)).data

      console.log("DATA:");
      console.log(data);

      const result = data.alarms.alarms

      const newArr = result.find(element=> element.nodeName === nodeName)
      if (newArr){
        setNotificationSettings([...newArr.alarms])
      } else {
        setNotificationSettings([])
      } 
      showToastMessage(lastAction, notificationSettings)

      console.log(`RESULT`);
      console.log(result);
      console.log("\n");
      return result

    } catch (e){
      console.log("ERROR:");
      console.log(e);
      toast.error("It was not possible to save the alarms")
    }

  }

  // Run hook only once after the render of the NotificationsTable comnponent occurs
  useEffect( () => {

    // Function to list the existing Appliance alarms
    const getAlarmsData = async () => {
      try {
        let alarmNodeElements = await getAlarm(handleID, nodeName)
        alarmNodeElements = alarmNodeElements.data.data

        if (!Array.isArray(alarmNodeElements)){

          const userAlarms = alarmNodeElements.alarms.filter((element)=> {
            return (element.contactInfo.emailAddress === user.emailAddress)
          })

          setNotificationSettings([...alarmNodeElements.alarms])
          setUserAlarms([...userAlarms])
        }



        console.log("Alarm Elements:");
        console.log(alarmNodeElements);

        console.log("User Alarms");
        console.log(userAlarms);

        setIsMounted(isMounted => ({...isMounted, alarmsData: true}))
      } catch(e){
        console.log(e);
      }
    }

    // Function to list the appliance params in the alarms dropdown
    const setApplianceDropdown = async () => {
      try {
        // const data = await getSystemLatestData(handleID)
        // const appliancesArr = data.data.data[0].data.deviceLedger.Appliances
        // const appliance = appliancesArr.find(element => element.nodeName === nodeName)
        // const loadTypeObj = loadTypesSchema.find(element => element.loadType === appliance.loadType)

        const loadTypeObj = loadTypesSchema.find(element => element.loadType === "system")

        // setLoadType(appliance.loadType)
        setLoadType("system")
        switchLoadTypeObj(loadTypeObj)
        setIsMounted(isMounted => ({...isMounted, applianceMount: true}))
      } catch(e){
        console.log(e);
      }
    }

    // Function to list the system params in the alarms dropdown
    const setSystemDropdown = async () => {
      try {
        const systemTypeObj = loadTypesSchema.find(element => element.loadType === "system")

        console.log("System Type Obj:");
        console.log(systemTypeObj);
        switchSystemTypeObj(systemTypeObj)
        setIsMounted(isMounted => ({...isMounted, systemMount: true}))
      } catch (e){
        console.log(e);
      }
    }

    const getSystemConnection = async () => {
      try{

        const myDataTimeInvalid = (await checkConnectionInfo(handleID)).data.data.Connection

        console.log("Status:");
        console.log(myDataTimeInvalid);
  
        setConnectionStatus(myDataTimeInvalid)
        setIsMounted(isMounted => ({...isMounted, connectionStatus: true}))
      } catch(e){
        console.log(e);
      }

    }

    getAlarmsData()
    setApplianceDropdown()
    setSystemDropdown()
    getSystemConnection()
  }, [])

  // Run hook preventing the inital declaration of activatedAlarmas and when activatedAlarms state changes
  useEffect(() => {
    if (!isInitialDeclaration){
      if (counter !== 0 ){
        saveAlarms(notificationSettings)
      }
    } else {
      setIsInitialDeclaration(false)
    }
  }, [isInitialDeclaration])

  useEffect(() => {

    const userAlarms = notificationSettings.filter((element)=> {
      return (element.contactInfo.emailAddress === user.emailAddress)
    })

    setUserAlarms([...userAlarms])

  }, [notificationSettings])

  return (
    <>
      {/* Bar to handle the actions: Coming back on the page, Saving selected alarms, and Adding new alarms(rows) into the Appliance  */}
      <div style={{
        width: "100%",
        display: "flex",
        justifyContent: "center"
      }}>
        <div
          style={{
            height: "25px",
            width: "475px",
            borderRadius: "5px",
            color: "#ffc000",
            backgroundColor: "#777575",
            fontWeight: "bold",
            fontSize: "11px",
            textAlign: "center",
            paddingTop: "5px",
            paddingBottom: "5px;"
          }}
        >
          <button
            className="btn btn-primary shadow-none"
            style={{
              padding: "0px",
              borderWidth: "0px",
              borderColor: "tranparent",
              marginRight: "12px",
              marginTop: "-7px",
              color: "transparent",
              backgroundColor: "transparent",
            }}
            onClick={()=> {
              addNewRow()
              setLastAction("add")
            }}
          >
            <FontAwesomeIcon
              icon="plus-circle"
              size="2x"
              color="#ffc000"
              title="Add a New Node"
            />
          </button>
          <button
            className="btn btn-primary shadow-none pt-1 pr-2"
            style={{
              float: "right",
              padding: "0px",
              borderWidth: "0px",
              marginRight: "12px",
              marginTop: "-7px",
              transform: "translate(10px, -2px)",
              color: "transparent",
              backgroundColor: "transparent",
            }}
            onClick={() => saveAlarms(notificationSettings)}
          >
            <FontAwesomeIcon
              icon="save"
              size="md"
              color="#ffc000"
              title="Save changes"
            />
          </button>
          <button
            className="btn btn-primary shadow-none"
            onClick={()=>{
              window.history.back();
            }}
            style={{
              float: "left",
              padding: "0px",
              borderWidth: "0px",
              marginLeft: "12px",
              marginTop: "-7px",
              color: "transparent",
              backgroundColor: "transparent",
            }}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              size="sm"
              color="#ffc000"
              title="Home"
            />
          </button>
        </div>
      </div>

      {/* bar containing the System Alarm checkbox */}
      <div style={{
          position: "relative",
          overflow: "hidden",
          zIndex: "-1",
          width: "475px",
          height: "30px",
          marginTop: "-5px",
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "#ece9e9",
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "flex-end"
        }}>

          <span>
            <label
              name={"system"}
              style={{
                fontSize: "11px",
                fontWeight: "750",
                color: "#199b14",
                marginTop:"5.5px"
              }}
              className="mr-4"
            >
              System Alarm
            </label>
            <input
              className="form-check-input mt-2"
              type="checkbox"
              name={"system"}
              checked={isSystemActive}
              // onChange={()=> {
              //   if(!isSystemActive){
              //     setSystemActive(true)
              //   } else {
              //     setSystemActive(false)
              //   }
              // }}
              style={{
                marginRight: "85px"
              }}
            ></input>
          </span>
      </div>

      {/* Main table container.. If there are not saved rows it shows a basic title*/}
      {isMounted.systemMount && isMounted.applianceMount && isMounted.alarmsData && isMounted.connectionStatus ? (
        <>
          { (notificationSettings.length > 0 && userAlarms.length > 0) ? (
            <div style={{display:"flex", marginTop:"20px", justifyContent:"center"}}>

              <table
                className="table"
                style={{
                  marginTop: "20px",
                  width: "350px",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <FontAwesomeIcon
                        icon="satellite-dish"
                        size="lg"
                        color="gray"
                        title="Communication Signal"
                      />
                    </th>
                    <th style={{ margin: "0px", paddingButton: "10px", height: "15px" }}>
                      Alert
                    </th>
                    <th style={{ margin: "0px", paddingButton: "10px", height: "15px" }}>
                      Frequency
                    </th>
                    <th style={{ margin: "0px", paddingButton: "10px", height: "15px" }}>
                      Action
                    </th>
                  </tr>
                </thead>

                {notificationSettings.map((item, index, array) =>
                  <>

                    {item.contactInfo.emailAddress === user.emailAddress && (
                      <tbody key={index}>
                        <tr>

                          {/* Range dropdown and range numeric inputs */}
                          <td>
                            <div style={{display: "flex", flexFlow:"row nowrap",
                            justifyContent:"center", alignItems: "center"}}>

                                {(connectionStatus === 2 || connectionStatus === null) && (
                                  <>
                                    <button className="led-red-on mt-3"></button>
                                  </>
                                )}
                                {(connectionStatus === 1) && (
                                  <>
                                    <button className="led-yellow-on mt-3"></button>
                                  </>
                                )}
                                {(connectionStatus === 0) && (
                                  <>
                                    <button className="led-green-on mt-3"></button>
                                  </>
                                )}

                            </div>
                          </td>

                          {/* Title names Dropdown */}
                          <td>
                            <div className="form-check">

                              <div style={{
                                display: "flex",
                                flexFlow: "row nowrap",
                                gap: "10px"
                              }}>
                                <LoadTypeInfoModal item={{...item}} loadTypeObj={{...systemTypeObj}}/>
                                <select
                                  className="form-control2 mr-1"
                                  value={item.alarmName}
                                  name={item.alarmName}
                                  onChange={event => {
                                    handleAlarmCheckBox(event, index)
                                  }}
                                  style={{ fontSize: "12px", width:"110px", fontWeight: "bold", color:"#784feb" }}
                                >
                                  {item.alarmName === "" ? (
                                    <option selected value={item.alarmName}>Choose here</option>
                                  ) : (
                                    <>
                                      <option selected value={item.alarmName}>{item.alarmTitle}</option>
                                    </>
                                  )}

                                  { systemTypeObj.data.map( (element) =>
                                    <>
                                      {(element.name !== item.alarmName && element.shown && element.name === "connection") && (
                                        <option value={element.name}>{element.shortDescription}</option>
                                      )}
                                    </>
                                  )}
                                </select>
                              </div>
                              <p style={{
                                fontSize: "11.5px",
                                fontWeight: "750",
                                color: "#199b14",
                              }}>
                                System Alarm
                              </p>

                            </div>
                          </td>

                          {/* Frecuency dropdown and destination checkboxes */}
                          <td>
                            <div>

                              <select
                                className="form-control2"
                                value={item.frequency.name}
                                name={item.alarmName}
                                onChange={event => handleFrequency(event, index)}
                                style={{ fontSize: "12px", width:"110px", fontWeight: "bold", color:"#784feb" }}
                              >
                                {item.frequency.name === "" ? (
                                    <option selected value={item.frequency.name}>Choose here</option>
                                ) : (
                                  <option selected value={item.frequency.name}>{transformToUpper(item.frequency.name)}</option>
                                )}
                                {frequencyElements.map( (element) =>
                                  <>
                                    {element !== transformToUpper(item.frequency.name) && (
                                      <option value={element}>{element}</option>
                                    )}
                                  </>
                                )}

                              </select>

                              <div style={{display: "flex",
                                  flexFlow:"column wrap",
                                  alignItems:"end"}}>


                                <div className="form-check" style={{
                                  width: "90%", display: "flex"
                                  }} >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name={"cellphone"}
                                    checked={item.frequency.cellphone}
                                    onChange={event=>handleFrequency(event, index)}
                                  ></input>
                                  <label
                                    name={"cellphone"}
                                    style={{
                                      font: "10px arial",
                                      fontWeight: "bold",
                                      marginTop:"5px"
                                    }}
                                  >
                                    Cellphone
                                  </label>
                                </div>


                                <div className="form-check" style={{
                                  width: "90%", display: "flex"
                                  }} >
                                  <input
                                    name={"email"}
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={item.frequency.email}
                                    onChange={event=>handleFrequency(event, index)}
                                  ></input>
                                  <label
                                    name={"email"}
                                    style={{
                                      font: "10px arial",
                                      fontWeight: "bold",
                                      display:"inline-block",
                                      marginTop: "6px"
                                    }}
                                  >
                                    Email
                                  </label>
                                </div>
                              </div>
                            </div>
                          </td>

                          {/* Trash Icon */}
                          <td className="icon p-0">

                              <button
                                className="btn btn-outline-light mt-4 ml-4"
                                style={{
                                  float: "left",
                                  padding: "0px",
                                  borderWidth: "0px",
                                  marginLeft: "12px",
                                  marginTop: "-7px",
                                }}
                                onClick={() => {
                                  setLastAction("remove")
                                  deleteRow(index)
                                }}
                              >
                                <FontAwesomeIcon
                                  icon="trash-alt"
                                  size="sm"
                                  color="red"
                                  title="Delete Alarm"
                                />
                              </button>

                          </td>

                        </tr>
                      </tbody>
                    )}

                  </>

                )}

              </table>

            </div>
          ) : (
            <div>
              <p className="text-center basicParagraph text-center"
                  style={{
                    width: "100%",
                    fontSize: "12px",
                    color: "#2d5c88",
                    marginTop: "20px"
                  }}
                >
                  Note: No Alarm is set for this Gateway
              </p>
            </div>
          )}
        </>
      ): (
        <>
          <div>
              <p className="text-center basicParagraph text-center"
                  style={{
                    width: "100%",
                    fontSize: "12px",
                    color: "#2d5c88",
                    marginTop: "20px"
                  }}
                >
                  Please stand by as connections are being established
              </p>
          </div>
        </>
      )}
    </>
  )
}

// Component containing the pop-up functionality
class LoadTypeInfoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      alarmInfo: {},
      isMounted: false // Initial state is false
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
      setTimeout(() => {this.setState({ isMounted: false })}, 500)
    };
  }

  setAlarmInfo = (item, loadTypeObj) => {
    try {
      const alarmInfo = loadTypeObj.data.find(element => element.name === item.alarmName)
      this.setState({ alarmInfo: alarmInfo })
      this.setState({ isMounted: true })

    } catch(e) {
      console.log(e);
    }

  }

  render() {

    const { isMounted, alarmInfo } = this.state

    return (
      <>
        <button
          onClick={() => {
            this.handleShow()
            this.setAlarmInfo(this.props.item, this.props.loadTypeObj)
          }}
          className="btn btn-outline-light"
          style={{ padding: "0", marginTop: "-5px", borderWidth: "0px" }}
        >
          <FontAwesomeIcon
            icon="info-circle"
            size="sm"
            color="orange"
            title="Alarm Information"
          />
        </button>

        {(isMounted && this.props.item.alarmName !== "") ? (
          <Modal
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {alarmInfo.shortDescription}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <hr className="modalLine" style={{ backgroundColor: "orange" }} />
              <p className="modalTextSmall">
                {alarmInfo.longDescription}
              </p>
            </Modal.Body>

          </Modal>
        ) : (
          <Modal
            show={this.state.show}
            onHide={this.handleHide}
            dialogClassName="modal-50w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                Not found
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <hr className="modalLine" style={{ backgroundColor: "orange" }} />
              <p className="modalTextSmall">
                Please select an option to provide information for the alarm
              </p>
            </Modal.Body>

          </Modal>
        )}

      </>
    );
  }
}

// Main component which calls the NotificationsTable interactive component
class SystemConnectionAlarm extends Component{

  constructor(props) {
    super(props);
    this.state = {
      isMounted: false, // Initial state is false
      systemName: "",
      nodeName: "",
      systemHandleID: 0,
      user: ""
    };
  }

  // Runs componentDidMount after the render occurs
  async componentDidMount(){

    const systemHandleID = parseInt(this.props.match.params.systemHandleID);

    const nodeName = "connection";
    const systemID = (await getSystemIDfromHandle(systemHandleID)).data.data.system_id;
    const system = (await getSystem(systemID)).data.data[0];
    const systemName = system.systemID.name;

    const userObj = await getUser()
    const user = userObj.data.data

    this.setState({nodeName});
    this.setState({systemName});
    this.setState({systemHandleID})
    this.setState({user})
    this.setState({isMounted: true})

  }

  render(){

    const {isMounted, systemName, nodeName, systemHandleID, user} = this.state

    return (
      <React.Fragment>
        <div
          style={{
            width: "1000px",
            minHeight: "400px",
            maxHeight: "auto",
            paddingBottom: "200px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            overflow: "auto",
            zIndex: 1
          }}
        >
          { isMounted ? (
            <>
              <div>
                {/* shows title after the call requests of the system */}
                <p className="text-center basicParagraph text-center"
                    style={{
                      width: "100%",
                      fontSize: "12px",
                      color: "#2d5c88",
                    }}
                  >
                    {systemName} | System Connection
                </p>
              </div>

              <NotificationsTable nodeName={nodeName} handleID={systemHandleID} systemName={systemName} user={user} />
            </>
          ) : (
            <div></div>
          )}

        </div>
      </React.Fragment>
    )
  }
}

export default SystemConnectionAlarm;
