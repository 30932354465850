import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import {
  findIdByMac,
  createSystem,
  getSystems,
  setSystemBasics,
} from "../services/systemService";
import BackGroundRectangle from "../components/backgroundRect";

class ManufactureBoard extends Form {
  state = {
    MAC: "",
    productSN: "",
    productType: "",
    productPN: "",
    fourGserialNumber: "none",
  };

  schema = {
    MAC: Joi.string().required().label("Unit's MAC Address"),
    productSN: Joi.string().required().label("Unit's Serial Number"),
    productType: Joi.string().required().label("Porduct Type"),
    productPN: Joi.string().required().label("Product"),
  };

  doSubmit = async () => {
    await createSystem(
      this.state.MAC,
      this.state.productSN,
      this.state.productPN,
      this.state.productType
    );
    console.log("Got to doSubmit 2");
    await getSystems(); //refresh the local data
    const systemID = (await findIdByMac(this.state.MAC)).data.data.system_id;
    console.log("System ID", systemID);
    //set the default value at the time of manufacture...
    await setSystemBasics(
      systemID,
      "Default Basic Description",
      "0", //system Configuration is always set to 0 at the time of manufacturing
      this.state.productPN, //System Product PN is grabbed from above
      this.state.productType, //system Product Type is grabbed from above
      "1", //System Time zone -8 hours Pacific Time
      "2", //Data Verbosity set to Medium
      "1", //Data Frequency set to once/second
      "2", //Local Log Length
      "1", //Local Log Frequency
      15, //Local Log verbosity
      "2", //Reset Delay
      "1", //Param Update Rate
      "3", //Interval Data Period
      "123",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "456",

      "0",
      "0",

      "1",
      "1",
      "1",
      "0",
      "0",
      "0",

      "1",
      "1",
      "1", //this.state.fourGserialNumber,

      "1",
      "1"
    );
    this.props.history.push("/manufactures");
  };
  handleMAC = ({ currentTarget: input }) => {
    const MAC = input.value;
    this.setState({ MAC });
  };
  handleSN = ({ currentTarget: input }) => {
    const productSN = input.value;
    this.setState({ productSN });
  };
  handleType = ({ currentTarget: input }) => {
    const productType = input.value;
    console.log("Product Type:", productType);
    this.setState({ productType });
  };
  handlePN = ({ currentTarget: input }) => {
    const productPN = input.value;
    this.setState({ productPN });
  };
  handle4G = (e) => {
    const fourGserialNumber = e.currentTarget.value;
    this.setState({ fourGserialNumber });
  };
  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "350px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1,
          }}
        >
          <form onSubmit={this.handleSubmit}>
            <p className="basicParagraph">Manufacture a new board:</p>
            <label className="basicParagraph" htmlFor="">
              Enter Board's MAC Address:
            </label>
            <input
              className="form-control2"
              value={this.state.MAC}
              onChange={this.handleMAC}
              id="macAddress"
              type="text"
            />
            <label className="basicParagraph" htmlFor="">
              Assign A Serial Number:
            </label>
            <input
              className="form-control2"
              value={this.state.productSN}
              onChange={this.handleSN}
              id="productSN"
              type="text"
            />
            <label className="basicParagraph" htmlFor="">
              Select Product Type:
            </label>
            <select
              className="form-control2"
              onChange={this.handleType}
              value={this.state.productType}
              style={{ fontSize: "12px", fontWeight: "bold" }}
            >
              <option value="Choose here">Choose here</option>
              <option value="SimplBox">SimplBox</option>
              <option value="SimplMeter">SimplMeter</option>
              <option value="SimplHub">SimplHub</option>
              <option value="SimplNodes">SimplNodes</option>
            </select>
            {this.state.productType === "SimplBox" && (
              <div>
                <label className="basicParagraph" htmlFor="">
                  Select Product Part Number:
                </label>
                <select
                  className="form-control2"
                  onChange={this.handlePN}
                  value={this.state.productPN}
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  <option value="Choose here">Choose here</option>
                  <option value="24 to 40 V">24 to 40 V</option>
                  <option value="27 to 44 V">27 to 44 V</option>
                  <option value="37 to 54 V">37 to 54 V</option>
                </select>
              </div>
            )}
            {this.state.productType === "SimplMeter" && (
              <div>
                <label className="basicParagraph" htmlFor="">
                  Select Product Part Number:
                </label>
                <select
                  className="form-control2"
                  onChange={this.handlePN}
                  value={this.state.productPN}
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  <option value="Choose here">Choose here</option>
                  <option value="Single Channel">Single Channel</option>
                  <option value="Dual Channel">Dual Channel</option>
                </select>
              </div>
            )}
            {this.state.productType === "SimplHub" && (
              <div>
                <label className="basicParagraph" htmlFor="">
                  Select Product Part Number:
                </label>
                <select
                  className="form-control2"
                  onChange={this.handlePN}
                  value={this.state.productPN}
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  <option value="Choose here">Choose here</option>
                  <option value="SimplHub in SimplBox">
                    SimplHub in SimplBox
                  </option>
                  <option value="SimplHub SimplModule">
                    SimplHub SimplModule
                  </option>
                </select>
              </div>
            )}
            {this.state.productType === "SimplNodes" && (
              <div>
                <label className="basicParagraph" htmlFor="">
                  Select Product Part Number:
                </label>
                <select
                  className="form-control2"
                  onChange={this.handlePN}
                  value={this.state.productPN}
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  <option value="Choose here">Choose here</option>
                  <option value="SimplGateway">SimplGateway</option>
                  <option value="SimplThermostat">SimplThermostat</option>
                  <option value="SimplView">SimplView</option>
                  <option value="SimplTstatVU">SimplTstatVU</option>
                  <option value="SimplExtender">SimplExtender</option>
                </select>
              </div>
            )}

            <button
              className="basicButton"
              style={{
                width: "150px",
                marginLeft: "50px",
                marginTop: "10px",
              }}
            >
              Manufacture Board
            </button>
          </form>
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default ManufactureBoard;
