import React, { Component } from "react";
import QrReader from "react-qr-reader";

class QrContainer extends Component {
  handleError = (err) => {
    console.error(err);
  };
  render() {
    return (
      <div>
        <QrReader
          delay={200}
          resolution={600}
          onError={this.handleError}
          onScan={this.props.onScan}
          style={{ width: "200px", height: "200px" }}
        />
      </div>
    );
  }
}
export default QrContainer;
