import React, { Component } from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { getSystems } from "../services/systemService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BackGroundRectangle from "../components/backgroundRect";
import { getUser } from "../services/userService";
import { setJwt } from "../services/httpService";
import {
  getSystemLatestData,
  ws_getSystemLatestData,
  getSystem,
  getSystemSignals,
  setSystemSignals,
  getSystemIDfromHandle,
  initSystemUpdate,
  getSystemInfo,
  getSystemBasics,
  findIdByMac,
} from "../services/systemService";
import { CALIBRATE, webSocketMode } from "../config.json"; //0: http; 1:websocket

class CalibratePage extends Component {
  state = {
    fullScaleCurrent: 0,
    pvAlpha: "1",
    pvBeta: "0",
    invAlpha: "1",
    invBeta: "0",
    doneZero: false,
    doneFS: false,
    doneCalcs: false,
  };

  async componentDidMount() {
    console.log("Got to Fake Page...");
    const handleID = this.props.match.params.systemHandleID;
    const macAddress = this.props.match.params.macAddress;
    console.log("Handle ID:   |  macAddress: ", handleID, macAddress);
    const systemID = (await getSystemIDfromHandle(handleID)).data.data
      .system_id;
    const slaveId = (await findIdByMac(macAddress)).data.data.system_id;
    const slaveSystem = await getSystem(slaveId);
    const slaveSN = slaveSystem.data.data[0].systemID.SN;

    const systemBasics = await getSystemBasics(handleID);
    const dataFrequency = systemBasics.data.data.basics.dataFrequency;
    let sleepTime = 0;
    if (dataFrequency === "1") sleepTime = 5000;
    else if (dataFrequency === "2") sleepTime = 15000;
    else sleepTime = 35000; //15 seconds + 15 seconds + 5 seconds

    this.setState({ handleID, macAddress, slaveSN, systemID, sleepTime });
    this.timerID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  async getSystemData() {
    let response = {};
    if (webSocketMode) {
      response = ws_getSystemLatestData(this.state.handleID);
      console.log("Websocket: Latest Data", response);
    } else {
      response = (await getSystemLatestData(this.state.handleID)).data.data[0];
      console.log("Http: Latest Data", response);
    }
    const slaveUnits = response.data.deviceLedger.slaveUnits;
    console.log("Slave Units", slaveUnits);
    const slaveUnit = slaveUnits.filter(
      (thisSlave) => thisSlave.MAC === this.state.macAddress
    );
    const fullScaleCurrent = this.state.fullScaleCurrent;
    console.log("Full Scale Current from DMM:", fullScaleCurrent);
    console.log("Slave Unit: ", slaveUnit);

    let pvCalc = 0;
    let a = this.state.pvAlpha;
    let b = this.state.pvBeta;
    //const pvMeas = this.state.pvCurrent;
    const pvMeas = this.state.pvCurrentForCal;
    pvCalc = pvMeas * a + b / 100.0;

    let invCalc = 0;
    a = this.state.invAlpha;
    b = this.state.invBeta;
    const invMeas = this.state.invCurrent;
    invCalc = invMeas * a + b / 100.0;

    this.setState({
      //pvCurrent: slaveUnit[0].pvCurrent,
      pvCurrentForCal: slaveUnit[0].pvCurrentForCal,
      invCurrent: slaveUnit[0].invCurrent,
      voltage: slaveUnit[0].pvVoltage,
      pvCalc,
      invCalc,
    });
  }

  tick() {
    this.setState({
      dateNow: new Date(),
    });

    this.getSystemData();
  }
  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  handleUpload = async (e) => {
    e.preventDefault();
    console.log("Uploading Calibration Parameters...");
    const slaveMAC = this.state.macAddress;
    const calParms = {
      pvAlpha: this.state.pvAlpha,
      pvBeta: this.state.pvBeta,
      invAlpha: this.state.invAlpha,
      invBeta: this.state.invBeta,
    };
    console.log("Calibration Parameters:", calParms);

    if (slaveMAC !== undefined) {
      await setSystemSignals(
        this.state.systemID,
        CALIBRATE,
        "",
        "",
        calParms,
        slaveMAC
      );
      await initSystemUpdate(this.state.handleID);

      const sleepTime = this.state.sleepTime;
      this.sleep(sleepTime); //Wait for reset to take effect for the loop running as slow as 15000 mseconds
      await setSystemSignals(
        this.state.systemID,
        "0",
        "",
        "",
        "",
        "FF:FF:FF:FF:FF:FF" /*slaveMAC*/
      );
      await initSystemUpdate(this.state.handleID);
    }
  };

  // actualCurrent = a * pvCurrent + b
  // actualCurrent = 0.0 => b = -pvZero
  // actulaCurrent = fullScaleCurrent  = a * pvFS - pvZero  => a = (fullScaleCurrent + pvZero) / pvFS
  handleCalcs = (e) => {
    e.preventDefault();
    console.log("Calculating...");

    let pvCalc = 0.0;
    let invCalc = 0.0;

    const pvZero = this.state.pvZero;
    const invZero = this.state.invZero;

    const pvFS = this.state.pvFS;
    const invFS = this.state.invFS;

    const fsCurrent = parseInt(this.state.fullScaleCurrent);

    const pvBeta = -1.0 * pvZero;
    const pvAlpha = (fsCurrent + pvZero) / pvFS;

    const invBeta = -1.0 * invZero;
    const invAlpha = (fsCurrent + invZero) / invFS;
    const doneCalcs = true;
    this.setState({ invAlpha, invBeta, pvAlpha, pvBeta, doneCalcs });
  };

  handleSubmitZero = (e) => {
    e.preventDefault();

    console.log("Got to Submit Zero");
    //const pvZero = this.state.pvCurrent;
    const pvZero = this.state.pvCurrentForCal;
    const invZero = this.state.invCurrent;
    const doneZero = true;
    this.setState({ pvZero, invZero, doneZero });
  };

  handleSubmitFS = (e) => {
    e.preventDefault();
    console.log("Got to Submit FS");
    //const pvFS = this.state.pvCurrent;
    const pvFS = this.state.pvCurrentForCal;
    const invFS = this.state.invCurrent;
    const doneFS = true;
    this.setState({ pvFS, invFS, doneFS });
  };

  handleChange = (e) => {
    const fullScaleCurrent = e.currentTarget.value;
    this.setState({ fullScaleCurrent });
  };

  render() {
    return (
      <div
        style={{
          // width: "250px",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: "120px",

          overflow: "auto",
          height: "inherit",
          display: "block",
          marginBottom: "200px",
          paddingBottom: "100px",
          zIndex: 1,
        }}
      >
        <p className="basicParagraph">Slave SN: {this.state.slaveSN}</p>
        <p className="basicParagraph">MAC Address: {this.state.macAddress}</p>
        <p className="basicParagraph">
          PV/Inv Voltage: {(this.state.voltage / 100).toFixed(2)} Volts
        </p>
        <p className="basicParagraph">
          PV Current: {(this.state.pvCurrentForCal / 100).toFixed(2)} Amps | Inv
          Current: {(this.state.invCurrent / 100).toFixed(2)} Amps
        </p>
        <hr></hr>
        <p className="basicParagraph">
          Calc. PV: {(this.state.pvCalc / 100).toFixed(2)} Amps | Calc. Inv.:{" "}
          {(this.state.invCalc / 100).toFixed(2)} Amps
        </p>
        <hr></hr>
        <form onSubmit={this.handleSubmitZero}>
          <p className="basicParagraph" style={{ color: "red" }}>
            Make sure SimplBox is in Hibernate Mode before you proceed...
          </p>
          <hr></hr>
          <p className="basicParagraph">
            Connect a Power Supply to the solar port
            <p className="basicParagraph">
              Connect an Electronic Load to the inverter port
              <p className="basicParagraph">
                On the Power Supply, hit the ON button
                <p className="basicParagraph">
                  Make sure the DC Load is set to OFF so no Current flows to the
                  DC Load.
                  <p className="basicParagraph">
                    Wait for PV Current and Inv Current to stabilize (4th line,
                    above).
                    <p className="basicParagraph">
                      Click on the button below once the current readings are
                      stable.
                    </p>
                  </p>
                </p>
              </p>
            </p>
          </p>
          <button
            className="basicButton"
            style={{ width: "200px", marginTop: "10px" }}
          >
            Zero Current
          </button>
        </form>
        {this.state.doneZero && (
          <div>
            <hr></hr>
            <form onSubmit={this.handleSubmitFS}>
              <p className="basicParagraph">
                Set Inverter Current to 1.0 to 5.0 Amps
                <p className="basicParagraph">
                  Set Power Supply Current Limit to 5.5 Amps
                  <p className="basicParagraph">
                    Set DC Load current to a desired level between 1.0 Amp to
                    5.0 Amps (e.g. 2A)
                  </p>
                </p>
              </p>
              <p className="basicParagraph">
                Wait for PV Current and Inv Current to reach stable levels (4th
                line, above).
              </p>
              <label className="basicParagraph" htmlFor="">
                Enter Measured Current * 100: (e.g. 200, if 2A was used)
              </label>
              <input
                value={this.state.fullScale}
                onChange={this.handleChange}
                id="fullScaleCurrent"
                type="text"
                className="form-control2"
              />

              <button
                className="basicButton"
                style={{ width: "200px", marginTop: "10px" }}
              >
                Full Scale Current
              </button>
            </form>
          </div>
        )}
        {this.state.doneFS && (
          <div>
            <hr></hr>
            <form onSubmit={this.handleCalcs}>
              <button
                className="basicButton"
                style={{ width: "200px", marginTop: "10px" }}
              >
                Calculate Cal Params
              </button>
            </form>
          </div>
        )}
        {this.state.doneCalcs && (
          <div>
            {" "}
            <hr></hr>
            <p className="basicParagraph">
              pvAlpha :{(this.state.pvAlpha * 1.0).toFixed(3)} | pvBeta:{" "}
              {this.state.pvBeta}
            </p>
            <p className="basicParagraph">
              invAlpha :{(this.state.invAlpha * 1.0).toFixed(3)} | invBeta:{" "}
              {this.state.invBeta}
            </p>
            <form onSubmit={this.handleUpload}>
              <button
                className="basicButton"
                style={{ width: "200px", marginTop: "10px" }}
              >
                Upload Cal Params
              </button>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default CalibratePage;
