import React, { Component } from "react";

import "./dashboard.css";
import "../index.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";

import RectPerimeterSvg from "./icons/RectPerimeterSvg";
import SqPerimeterSvg from "./icons/SqPerimeterSvg";
import HPerimeterSvg from "./icons/HPerimeterSvg";
import LPerimeterSvg from "./icons/LPerimeterSvg";
import UPerimeterSvg from "./icons/UPerimeterSvg";
import WPerimeterSvg from "./icons/WPerimeterSvg";
import XPerimeterSvg from "./icons/XPerimeterSvg";
import YPerimeterSvg from "./icons/YPerimeterSvg";
import ZPerimeterSvg from "./icons/ZPerimeterSvg";
import ConvexPerimeterSvg from "./icons/ConvexPerimeterSvg";

function BldgShape(props) {
  return (
    <React.Fragment>
      <div style={{ top: "80px", left: "300px", position: "absolute" }}>
        {props.perimeterShape === "Rectangle" && (
          <RectPerimeterSvg rectColor={props.perimeterColor} />
        )}
        {props.perimeterShape === "Square" && (
          <SqPerimeterSvg rectColor={props.perimeterColor} />
        )}
        {props.perimeterShape === "Convex" && (
          <ConvexPerimeterSvg rectColor={props.perimeterColor} />
        )}
        {props.perimeterShape === "H-shaped" && (
          <HPerimeterSvg rectColor={props.perimeterColor} />
        )}
        {props.perimeterShape === "L-shaped" && (
          <LPerimeterSvg rectColor={props.perimeterColor} />
        )}
        {props.perimeterShape === "U-shaped" && (
          <UPerimeterSvg rectColor={props.perimeterColor} />
        )}
        {props.perimeterShape === "W-shaped" && (
          <WPerimeterSvg rectColor={props.perimeterColor} />
        )}
        {props.perimeterShape === "X-shaped" && (
          <XPerimeterSvg rectColor={props.perimeterColor} />
        )}
        {props.perimeterShape === "Y-shaped" && (
          <YPerimeterSvg rectColor={props.perimeterColor} />
        )}
        {props.perimeterShape === "Z-shaped" && (
          <ZPerimeterSvg rectColor={props.perimeterColor} />
        )}
      </div>
    </React.Fragment>
  );
}
export default BldgShape;
