import React, { Component } from "react";
import * as echarts from "echarts/dist/echarts.js";
import "bootstrap/dist/css/bootstrap.css";
import "./dashboard.css";
import {
  getSystemRealTimeData,
  getSystemLatestData,
  getSystemBasics,
  ws_getSystemLatestData,
  getSystemInfo,
} from "../services/systemService";

import { webSocketMode } from "../config.json"; //0: http; 1:websocket

class LinePowerGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphLen: 0,
      base: new Date(),
      oneDay: 86400000,
      date: [],
      value1: [0],

      now: new Date(),
    };
  }

  async componentDidMount() {
    if (this.props.chartDateInitial) {
      this.setState({
        date: this.props.chartDateInitial,
        value1: this.props.chartValue1Initial,
      });
    }
    this.chart = echarts.init(document.getElementById("graph-area3"));
    //console.log("Tick Number", tickNumber);
    const tickNumber = 1000;
    this.timerID = setInterval(() => this.tick(), tickNumber);
    this.generateGraph();
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.addData(false);
    this.chart.setOption({
      /*  yAxis: {
        max: 150.0,
      },*/
      xAxis: {
        data: this.state.date,
        axisTick: { show: false },
        splitLine: { show: false },
      },

      series: [
        {
          name: `${this.props.chartLegend1}`,
          data: this.state.value1,
        },
      ],
    });
  }

  addData(shift) {
    this.state.now = [
      this.state.now.getHours(),
      this.state.now.getMinutes(),
      this.state.now.getSeconds(),
    ].join(":");

    if (this.props.playPause) {
      this.state.date.push(this.state.now);

      if (this.props.chartDateInitial) {
        this.state.value1.push(this.props.chartValue1);
      }
    }
    const stringLength = parseInt(this.state.value1.length);
    const pointsOnChart = parseInt(localStorage.getItem("pointsOnChart"));

    const delta = stringLength - pointsOnChart;

    if (delta > 0 && this.props.modeBox === "Real-time") {
      this.state.date.shift();
      this.state.value1.shift();
    }
    this.state.now = new Date();
  }
  generateGraph() {
    var option = {
      grid: {
        top: 0,
        bottom: 20,
        left: "10%",
        right: "10%",
      },
      legend: {
        type: "scroll",
        data: [`${this.props.chartLegend1}`],
        center: "50%",
        top: "0%",
        orient: "horizontal",
        align: "auto",
        padding: 3,
        textStyle: {
          fontStyle: "normal",
          fontSize: 10,
          fontWeight: "bold",
          color: "grey",
        },
        backgroundColor: "transparent",
        borderColor: "#ffffff",
        borderWidth: 1,
        boarderRadius: 0,
      },
      legend: {
        show: false,
      },
      xAxis: {
        name: "",
        nameLocation: "end",
        type: "category",
        nameTextStyle: {
          fontStyle:
            "normal" /*'Lato,"Helvetica Neue",Arial,Helvetica,sans-serif'*/,
          fontSize: 16,
          fontWeight: "bolder",
          color: "green",
        },
        axisLabel: {
          fontStyle: "normal",
          fontSize: 8,
          fontWeight: "normal",
          color: "orange",
        },
        boundaryGap: false,
        data: this.state.date,
      },
      yAxis: {
        show: false,
        name: "Power (Watts)",
        nameLocation: "end",
        boundaryGap: [0, "10%"],
        type: "value",
        nameTextStyle: {
          fontStyle:
            "normal" /*'Lato,"Helvetica Neue",Arial,Helvetica,sans-serif'*/,
          fontSize: 16,
          fontWeight: "bolder",
          color: "green",
        },
        axisLabel: {
          fontStyle: "normal",
          fontSize: 10,
          fontWeight: "bold",
          color: "green",
        },
      },
      /*dataZoom: [
        {
          type: "slider",
          show: true,
          xAxisIndex: [0],
          start: 1,
          end: 100,
        },
        {
          type: "inside",
          xAxisIndex: [0],
          start: 1,
          end: 100,
        },
      ],*/
      series: [
        {
          name: "PV Power",
          type: "line",
          symbol: "none",
          color: "purple",
          smooth: 0.25,
          areaStyle: {
            normal: {
              color: "purple",
              opacity: 0.25,
            },
          },
          data: this.state.value1,
        },
      ],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          animation: false,
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
            precision: "2",
          },
        },
      },
    };

    this.chart.setOption(option);
  }

  render() {
    return <div id="graph-area3" />;
  }
}
export default LinePowerGraph;
