import React, { Component } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
//import simplWS_client from "./wsService";
import {
  getSystemLatestData,
  ws_getSystemLatestData,
  getSystemIntervalData,
  getSystemInfo,
  getSystemBasics,
  getSystemIDfromHandle,
  getSystem,
} from "../services/systemService";
import { getUserSystems } from "../services/userService";
import { masterCompany } from "../config.json";

import { setJwt } from "../services/httpService";
import { getUser } from "../services/userService";
import "./dashboard.css";
import "../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";

class FwUpdate extends Component {
  state = {
    productType: "1",
    slaveProductType: "1",
  };

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    const user = await getUser();
    try {
      if (user.data.data.company === masterCompany && user.data.data.admin) {
        console.log("master company");
      } else throw "wrong user";
    } catch (err) {
      toast.warning("Error: Must be Simpl Global's Admin...");
    }
  }

  componentWillUnmount() {
    console.log("fw Update CWM");
  }

  handleProductType = ({ currentTarget: input }) => {
    const productType = input.value;
    console.log(productType);
    this.setState({ productType });
  };
  handleSlaveProductType = ({ currentTarget: input }) => {
    const slaveProductType = input.value;
    console.log(slaveProductType);
    this.setState({ slaveProductType });
  };
  render() {
    return (
      <div
        style={{
          width: "350px",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: "150px",
          marginBottom: "150px",
          zIndex: 1,
        }}
      >
        <label className="basicParagraph" htmlFor="">
          Select Hub/Master Product Type:
        </label>
        <select
          className="form-control2"
          onChange={this.handleProductType}
          value={this.state.productType}
          style={{ fontSize: "12px", fontWeight: "bold" }}
        >
          <option value="1">Legacy Systems</option>
          <option value="2">SimplHub</option>
          <option value="3">Simpl4G</option>
          <option value="4">SimplMeter II</option>
          <option value="5">SimplGateway Hub</option>
          <option value="6">SimplGateway 4G</option>
          <option value="7">SimplMeter II Socket</option>
          <option value="8">SimplATS</option>
          <option value="9">SimplATS 4G</option>
        </select>
        {this.state.productType === "1" && (
          <form
            action="http://fwupdate.simplapi.net/fw0"
            enctype="multipart/form-data"
            method="post"
          >
            <input
              type="file"
              className="inputTariff"
              style={{ width: "250px", marginRight: "10px" }}
              name="firmware0.bin"
            ></input>
            <input
              type="submit"
              className="inputTariff"
              style={{ marginTop: "10px", width: "150px" }}
              value="Upload New Firmware"
            ></input>
          </form>
        )}
        {this.state.productType === "2" && (
          <form
            action="http://fwupdate.simplapi.net/fw_simplhub"
            enctype="multipart/form-data"
            method="post"
          >
            <input
              type="file"
              className="inputTariff"
              style={{ width: "250px", marginRight: "10px" }}
              name="firmware_simplhub.bin"
            ></input>
            <input
              type="submit"
              className="inputTariff"
              style={{ marginTop: "10px", width: "150px" }}
              value="Upload New Firmware"
            ></input>
          </form>
        )}
        {this.state.productType === "3" && (
          <form
            action="http://fwupdate.simplapi.net/fw_simpl4g"
            enctype="multipart/form-data"
            method="post"
          >
            <input
              type="file"
              className="inputTariff"
              style={{ width: "250px", marginRight: "10px" }}
              name="firmware_simpl4g.bin"
            ></input>
            <input
              type="submit"
              className="inputTariff"
              style={{ marginTop: "10px", width: "150px" }}
              value="Upload New Firmware"
            ></input>
          </form>
        )}
        {this.state.productType === "4" && (
          <form
            action="http://fw-update-dev.us-west-2.elasticbeanstalk.com/fw_sm_ii"
            enctype="multipart/form-data"
            method="post"
          >
            <input
              type="file"
              className="inputTariff"
              style={{ width: "250px", marginRight: "10px" }}
              name="firmware_sm_ii.bin"
            ></input>
            <input
              type="submit"
              className="inputTariff"
              style={{ marginTop: "10px", width: "150px" }}
              value="Upload New Firmware"
            ></input>
          </form>
        )}
        {this.state.productType === "5" && (
          <form
            action="http://fwupdate.simplapi.net/fw_sm_ii_hub"
            enctype="multipart/form-data"
            method="post"
          >
            <input
              type="file"
              className="inputTariff"
              style={{ width: "250px", marginRight: "10px" }}
              name="firmware_sm_ii_hub.bin"
            ></input>
            <input
              type="submit"
              className="inputTariff"
              style={{ marginTop: "10px", width: "150px" }}
              value="Upload New Firmware"
            ></input>
          </form>
        )}
        {this.state.productType === "6" && (
          <form
            action="http://fwupdate.simplapi.net/fw_sm_ii_4g"
            enctype="multipart/form-data"
            method="post"
          >
            <input
              type="file"
              className="inputTariff"
              style={{ width: "250px", marginRight: "10px" }}
              name="firmware_sm_ii_4g.bin"
            ></input>
            <input
              type="submit"
              className="inputTariff"
              style={{ marginTop: "10px", width: "150px" }}
              value="Upload New Firmware"
            ></input>
          </form>
        )}
        {this.state.productType === "7" && (
          <form
            action="http://fwupdate.simplapi.net/fw_sm_socket"
            enctype="multipart/form-data"
            method="post"
          >
            <input
              type="file"
              className="inputTariff"
              style={{ width: "250px", marginRight: "10px" }}
              name="firmware_sm_socket.bin"
            ></input>
            <input
              type="submit"
              className="inputTariff"
              style={{ marginTop: "10px", width: "150px" }}
              value="Upload New Firmware"
            ></input>
          </form>
        )}
        {this.state.productType === "8" && (
          <form
            action="http://fwupdate.simplapi.net/fw_simplats"
            enctype="multipart/form-data"
            method="post"
          >
            <input
              type="file"
              className="inputTariff"
              style={{ width: "250px", marginRight: "10px" }}
              name="firmware_simplats.bin"
            ></input>
            <input
              type="submit"
              className="inputTariff"
              style={{ marginTop: "10px", width: "150px" }}
              value="Upload New Firmware"
            ></input>
          </form>
        )}
        {this.state.productType === "9" && (
          <form
            action="http://fwupdate.simplapi.net/fw_simplats4g"
            enctype="multipart/form-data"
            method="post"
          >
            <input
              type="file"
              className="inputTariff"
              style={{ width: "250px", marginRight: "10px" }}
              name="firmware_simplats4g.bin"
            ></input>
            <input
              type="submit"
              className="inputTariff"
              style={{ marginTop: "10px", width: "150px" }}
              value="Upload New Firmware"
            ></input>
          </form>
        )}

        <p></p>
        <hr></hr>
        <p></p>
        <label className="basicParagraph" style={{ color: "green" }} htmlFor="">
          Select Subsystem/Slave Product Type:
        </label>
        <select
          className="form-control2"
          onChange={this.handleSlaveProductType}
          value={this.state.slaveProductType}
          style={{ fontSize: "12px", fontWeight: "bold" }}
        >
          <option value="1">Legacy Subsystems</option>
          <option value="2">SimplBox</option>
          {/*<option value="3">SimplNode Subsystem</option>*/}
          <option value="4">SimplNode / SimplMeter II Subsystem</option>
          <option value="5">SimplView</option>
        </select>
        {this.state.slaveProductType === "1" && (
          <form
            action="http://fwupdate.simplapi.net/fw1"
            enctype="multipart/form-data"
            method="post"
          >
            <input
              type="file"
              className="inputTariff"
              style={{
                width: "250px",
                marginRight: "10px",
                backgroundColor: "green",
              }}
              name="firmware1.bin"
            ></input>
            <input
              type="submit"
              className="inputTariff"
              style={{
                marginTop: "10px",
                width: "150px",
                backgroundColor: "green",
              }}
              value="Upload New Firmware"
            ></input>
          </form>
        )}
        {this.state.slaveProductType === "2" && (
          <form
            action="http://fwupdate.simplapi.net/fw_sbx"
            enctype="multipart/form-data"
            method="post"
          >
            <input
              type="file"
              className="inputTariff"
              style={{
                width: "250px",
                marginRight: "10px",
                backgroundColor: "green",
              }}
              name="firmware_sbx.bin"
            ></input>
            <input
              type="submit"
              className="inputTariff"
              style={{
                marginTop: "10px",
                width: "150px",
                backgroundColor: "green",
              }}
              value="Upload New Firmware"
            ></input>
          </form>
        )}
        {this.state.slaveProductType === "3" && (
          <form
            action="http://fwupdate.simplapi.net/fw_simplnode"
            enctype="multipart/form-data"
            method="post"
          >
            <input
              type="file"
              className="inputTariff"
              style={{
                width: "250px",
                marginRight: "10px",
                backgroundColor: "green",
              }}
              name="firmware_simplnode.bin"
            ></input>
            <input
              type="submit"
              className="inputTariff"
              style={{
                marginTop: "10px",
                width: "150px",
                backgroundColor: "green",
              }}
              value="Upload New Firmware"
            ></input>
          </form>
        )}
        {this.state.slaveProductType === "4" && (
          <form
            action="http://fwupdate.simplapi.net/fw_sm_slave"
            enctype="multipart/form-data"
            method="post"
          >
            <input
              type="file"
              className="inputTariff"
              style={{
                width: "250px",
                marginRight: "10px",
                backgroundColor: "green",
              }}
              name="firmware_sm_slave.bin"
            ></input>
            <input
              type="submit"
              className="inputTariff"
              style={{
                marginTop: "10px",
                width: "150px",
                backgroundColor: "green",
              }}
              value="Upload New Firmware"
            ></input>
          </form>
        )}
        {this.state.slaveProductType === "5" && (
          <form
            action="http://fwupdate.simplapi.net/fw_svu"
            enctype="multipart/form-data"
            method="post"
          >
            <input
              type="file"
              className="inputTariff"
              style={{
                width: "250px",
                marginRight: "10px",
                backgroundColor: "green",
              }}
              name="firmware_svu.bin"
            ></input>
            <input
              type="submit"
              className="inputTariff"
              style={{
                marginTop: "10px",
                width: "150px",
                backgroundColor: "green",
              }}
              value="Upload New Firmware"
            ></input>
          </form>
        )}
      </div>
    );
  }
}
export default FwUpdate;
