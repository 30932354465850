import React, { Component } from "react";
import HvacSvg from "./icons/HvacSvg";
import WalkInOpenSvg from "./icons/WalkInOpenSvg";
import WalkInClsdSvg from "./icons/WalkInClsdSvg";
import WaterHeaterSvg from "./icons/WaterHeaterSvg";
import NoTempWaterHeaterSvg from "./icons/NoTempWaterHeaterSvg";
import PoleLightSvg from "./icons/PoleLightSvg";
import AshSvg from "./icons/AshSvg";
import OvenSvg from "./icons/OvenSvg";
import HoodSvg from "./icons/HoodSvg";
import LightsSvg from "./icons/LightsSvg";
import HeatLampSvg from "./icons/HeatLampSvg";
import DoorClsdSvg from "./icons/DoorClsdSvg";
import DoorOpenSvg from "./icons/DoorOpenSvg";
import SpaceHeaterSvg from "./icons/SpaceHeaterSvg";
import ThermoSvg from "./icons/ThermoSvg";
import LightSensorSvg from "./icons/LightSensorSvg";
import GatewaySvg from "./icons/GatewaySvg";
import ReachInSvg from "./icons/ReachInSvg";
import CounterReachInSvg from "./icons/CounterReachInSvg";
import ApplianceGeneralSvg from "./icons/ApplianceGeneralSvg";
import EVStationSvg from "./icons/EVStationSvg";
import CompassSvg from "./icons/CompassSvg";
import SwitchOffSvg from "./icons/SwitchOffSvg";
import SwitchOnSvg from "./icons/SwitchOnSvg";
import SwitchOffSensedSvg from "./icons/SwitchOffSensedSvg";
import SwitchOnSensedSvg from "./icons/SwitchOnSensedSvg";
import RectPerimeterSvg from "./icons/RectPerimeterSvg";

function ApplianceIcon(props) {
  const rotation = parseInt(`${props.nodeName}`);
  //console.log("Appliance Icon: ", props.loadType);
  let doorStatus = "open";
  let shedStatus = 0;
  //if (props.nodeDoorStatus !== 0 && props.nodeDoorStatus !== 1) doorStatus = 0;
  //else doorStatus = props.nodeDoorStatus;
  if (props.nodeDoorStatus === 0) doorStatus = "closed";
  else doorStatus = "open";
  if (props.nodeShedStatus !== 0 && props.nodeShedStatus !== 1) shedStatus = 0;
  else shedStatus = props.nodeShedStatus;

  let applianceStatus = props.applianceStatus;

  return (
    <React.Fragment>
      {(props.loadType === "HVAC Unit" ||
        props.loadType === "HVAC w SimplTherm") &&
        shedStatus === 0 && (
          <HvacSvg
            name={props.nodeName}
            rectColor="gray"
            textColor="gray"
            strokeColor1="#029AE4"
            strokeColor2="orange"
            doorColor="red"
          />
        )}
      {(props.loadType === "HVAC Unit" ||
        props.loadType === "HVAC w SimplTherm") &&
        shedStatus === 1 && (
          <HvacSvg
            name={props.nodeName}
            rectColor="gray"
            textColor="gray"
            strokeColor1="gray"
            strokeColor2="gray"
            doorColor="red"
          />
        )}
      {props.loadType === "Space Heater" && shedStatus === 0 && (
        <SpaceHeaterSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="red"
          strokeColor2="orange"
          doorColor="red"
        />
      )}
      {props.loadType === "Space Heater" && shedStatus === 1 && (
        <SpaceHeaterSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="gray"
          strokeColor2="gray"
          doorColor="red"
        />
      )}
      {props.loadType === "Pole Lights" && applianceStatus === 1 && (
        <PoleLightSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="gray"
        />
      )}

      {props.loadType === "Pole Lights" && applianceStatus === 0 && (
        <PoleLightSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="orange"
        />
      )}
      {props.loadType === "Relay Driver" && applianceStatus === 0 && (
        <SwitchOnSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="orange"
        />
      )}

      {props.loadType === "Relay Driver" && applianceStatus === 1 && (
        <SwitchOffSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="orange"
        />
      )}
      {props.loadType === "Relay Driver w Light Sensor" &&
        applianceStatus === 0 && (
          <SwitchOnSensedSvg
            name={props.nodeName}
            rectColor="gray"
            textColor="gray"
            strokeColor1="gray"
          />
        )}

      {props.loadType === "Relay Driver w Light Sensor" &&
        applianceStatus === 1 && (
          <SwitchOffSensedSvg
            name={props.nodeName}
            rectColor="gray"
            textColor="gray"
            strokeColor1="orange"
          />
        )}

      {props.loadType === "Quad 30A Relays" && applianceStatus === 0 && (
        <SwitchOnSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="orange"
        />
      )}

      {props.loadType === "Quad 30A Relays" && applianceStatus === 1 && (
        <SwitchOffSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="orange"
        />
      )}
      {props.loadType === "Quad 30A w Light Sensor" &&
        applianceStatus === 0 && (
          <SwitchOnSensedSvg
            name={props.nodeName}
            rectColor="gray"
            textColor="gray"
            strokeColor1="gray"
          />
        )}

      {props.loadType === "Quad 30A w Light Sensor" &&
        applianceStatus === 1 && (
          <SwitchOffSensedSvg
            name={props.nodeName}
            rectColor="gray"
            textColor="gray"
            strokeColor1="orange"
          />
        )}

      {props.loadType === "Outdoor Sensed Lights" && applianceStatus === 0 && (
        <PoleLightSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="orange"
        />
      )}
      {props.loadType === "Outdoor Sensed Lights" && applianceStatus === 1 && (
        <PoleLightSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="gray"
        />
      )}
      {props.loadType === "Indoor Lights" && applianceStatus === 1 && (
        <LightsSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="gray"
          strokeColor2="gray"
          doorColor="red"
        />
      )}
      {props.loadType === "Indoor Lights" && applianceStatus === 0 && (
        <LightsSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="orange"
          strokeColor2="orange"
          doorColor="red"
        />
      )}
      {props.loadType === "Heat Lamp" && (
        <HeatLampSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="red"
          strokeColor2="orange"
          doorColor="red"
        />
      )}
      {(props.loadType === "Water Heater" ||
        props.loadType === "SimplHeater") &&
        shedStatus === 0 && (
          <WaterHeaterSvg
            name={props.nodeName}
            rectColor="gray"
            textColor="gray"
            strokeColor1="red"
            strokeColor2="orange"
            doorColor="red"
          />
        )}
      {(props.loadType === "Water Heater" ||
        props.loadType === "SimplHeater") &&
        shedStatus === 1 && (
          <WaterHeaterSvg
            name={props.nodeName}
            rectColor="gray"
            textColor="gray"
            strokeColor1="gray"
            strokeColor2="gray"
            doorColor="red"
          />
        )}
      {props.loadType === "Water Heater No Sensor" && shedStatus === 0 && (
        <NoTempWaterHeaterSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="red"
          strokeColor2="orange"
          doorColor="red"
        />
      )}
      {props.loadType === "Water Heater No Sensor" && shedStatus === 1 && (
        <NoTempWaterHeaterSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="gray"
          strokeColor2="gray"
          doorColor="red"
        />
      )}

      {(props.loadType === "Walk-in" || props.loadType === "SimplWalkIn") &&
        doorStatus === "open" &&
        shedStatus === 0 && (
          <WalkInOpenSvg
            name={props.nodeName}
            rectColor="gray"
            textColor="gray"
            strokeColor1="#029AE4"
            strokeColor2="orange"
            doorColor="red"
          />
        )}
      {(props.loadType === "Walk-in" || props.loadType === "SimplWalkIn") &&
        doorStatus === "open" &&
        shedStatus === 1 && (
          <WalkInOpenSvg
            name={props.nodeName}
            rectColor="gray"
            textColor="gray"
            strokeColor1="gray"
            strokeColor2="gray"
            doorColor="red"
          />
        )}
      {(props.loadType === "Walk-in" || props.loadType === "SimplWalkIn") &&
        doorStatus === "closed" &&
        shedStatus === 0 && (
          <WalkInClsdSvg
            name={props.nodeName}
            rectColor="gray"
            textColor="gray"
            strokeColor1="#029AE4"
            strokeColor2="orange"
            doorColor="red"
          />
        )}
      {(props.loadType === "Walk-in" || props.loadType === "SimplWalkIn") &&
        doorStatus === "closed" &&
        shedStatus === 1 && (
          <WalkInClsdSvg
            name={props.nodeName}
            rectColor="gray"
            textColor="gray"
            strokeColor1="gray"
            strokeColor2="gray"
            doorColor="red"
          />
        )}

      {props.loadType === "Reach-in" && shedStatus === 0 && (
        <ReachInSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="#029AE4"
          strokeColor2="#029AE4"
          doorColor="red"
        />
      )}
      {props.loadType === "Reach-in" && shedStatus === 1 && (
        <ReachInSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="gray"
          strokeColor2="gray"
          doorColor="red"
        />
      )}
      {props.loadType === "Counter Reach-in" && shedStatus === 0 && (
        <CounterReachInSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="red"
          strokeColor2="orange"
          doorColor="red"
        />
      )}
      {props.loadType === "Counter Reach-in" && shedStatus === 1 && (
        <CounterReachInSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="gray"
          strokeColor2="gray"
          doorColor="red"
        />
      )}
      {props.loadType === "Anti-sweat Heaters" && shedStatus === 0 && (
        <AshSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="#029AE4"
          strokeColor2="orange"
          doorColor="red"
        />
      )}
      {props.loadType === "Anti-sweat Heaters" && shedStatus === 1 && (
        <AshSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="gray"
          strokeColor2="gray"
          doorColor="red"
        />
      )}
      {props.loadType === "EV Charger" && shedStatus === 0 && (
        <EVStationSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="purple"
          strokeColor2="orange"
          doorColor="red"
        />
      )}
      {props.loadType === "EV Charger" && shedStatus === 1 && (
        <EVStationSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="gray"
          strokeColor2="gray"
          doorColor="red"
        />
      )}
      {props.loadType === "Oven" && (
        <OvenSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="red"
          strokeColor2="orange"
          doorColor="red"
        />
      )}
      {props.loadType === "Hood" && (
        <HoodSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="red"
          strokeColor2="orange"
          doorColor="red"
        />
      )}
      {(props.loadType === "Misc. Appliance" ||
        props.loadType === "Misc. Sheddable") && (
        <ApplianceGeneralSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="orange"
          strokeColor2="orange"
          doorColor="red"
        />
      )}
      {props.loadType === "Ambient Temp Sensor" && (
        <ThermoSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="red"
          strokeColor2="orange"
          doorColor="red"
        />
      )}
      {props.loadType === "External Temp Sensor" && (
        <ThermoSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="red"
          strokeColor2="orange"
          doorColor="red"
        />
      )}
      {props.loadType === "Light Sensor" && (
        <LightSensorSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="red"
          strokeColor2="orange"
          doorColor="red"
        />
      )}
      {props.loadType === "Door Monitor" && doorStatus === "open" && (
        <DoorOpenSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="red"
          strokeColor2="orange"
          doorColor="red"
          rotationAngle={props.nodeDoorOrientation}
        />
      )}
      {props.loadType === "Door Monitor" && doorStatus === "closed" && (
        <DoorClsdSvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="green"
          strokeColor2="orange"
          doorColor="red"
          rotationAngle={props.nodeDoorOrientation}
        />
      )}
      {props.loadType === "Compass Indicator" && (
        <div>
          <CompassSvg
            name={props.nodeName}
            rectColor="gray"
            textColor="gray"
            strokeColor1="green"
            strokeColor2="orange"
            doorColor="red"
            rotationAngle={props.nodeName}
            scaleFactor="scale(0.9, 0.9)"
          />
        </div>
      )}
      {props.loadType === "System Gateway" && (
        <GatewaySvg
          name={props.nodeName}
          rectColor="gray"
          textColor="gray"
          strokeColor1="purple"
          strokeColor2="purple"
          doorColor="red"
        />
      )}
    </React.Fragment>
  );
}
export default ApplianceIcon;
