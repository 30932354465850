import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import {
  findSystemBySerialNumber,
  getSystem,
  getSystemBasics,
  setSystemBasicsObject,
} from "../services/systemService";
import BackGroundRectangle from "../components/backgroundRect";

class ManufactureEdit extends Form {
  state = {
    MAC: "",
    handleID: 0,
    serialNumber: "",
    productType: "",
    productPN: "",
    fourGserialNumber: "",
  };

  schema = {
    MAC: Joi.string().required().label("Unit's MAC Address"),
    productSN: Joi.string().required().label("Unit's Serial Number"),
    productType: Joi.string().required().label("Porduct Type"),
    productPN: Joi.string().required().label("Product"),
  };

  doSubmit = async () => {
    const systemInfo = await findSystemBySerialNumber(this.state.serialNumber);
    console.log("System Info", systemInfo);
    const systemID = systemInfo.data.data.system_id;
    const thisSystem = await getSystem(systemID);
    console.log("This system: ", thisSystem);
    const handleID = thisSystem.data.data[0].systemID.id;
    console.log("Handle ID: ", handleID);
    const systemType = thisSystem.data.data[0].systemBasics.systemType;
    const systemProduct = thisSystem.data.data[0].systemBasics.systemProduct;
    const fourGserialNumber =
      thisSystem.data.data[0].systemBasics.fourGserialNumber;

    this.setState({
      systemID,
      handleID,
      systemType,
      systemProduct,
      fourGserialNumber,
    });
  };

  handleUpdateAttributes = async (e) => {
    e.preventDefault();
    const systemBasics = (await getSystemBasics(this.state.handleID)).data.data
      .basics;
    console.log("System Basics in Handle Update:", systemBasics);
    let updatedSystemBasic = {
      ...systemBasics,
      systemProduct: this.state.productPN,
      systemType: this.state.systemType,
      fourGserialNumber: this.state.fourGserialNumber,
    };
    console.log("Updated System Basics", updatedSystemBasic);
    await setSystemBasicsObject(this.state.systemID, updatedSystemBasic);
  };
  handleSN = ({ currentTarget: input }) => {
    const serialNumber = input.value;
    this.setState({ serialNumber });
  };
  handleType = ({ currentTarget: input }) => {
    const productType = input.value;
    console.log("Product Type:", productType);
    this.setState({ productType });
  };
  handlePN = ({ currentTarget: input }) => {
    const productPN = input.value;
    this.setState({ productPN });
  };
  handle4G = (e) => {
    const fourGserialNumber = e.currentTarget.value;
    this.setState({ fourGserialNumber });
  };
  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "350px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1,
          }}
        >
          <form onSubmit={this.handleSubmit}>
            <label className="basicParagraph" htmlFor="">
              Input Board's Serial Number:
            </label>
            <input
              className="form-control2"
              value={this.state.serialNumber}
              onChange={this.handleSN}
              id="productSN"
              type="text"
            />
            <button
              className="basicButton"
              style={{
                width: "150px",
                marginLeft: "50px",
                marginTop: "10px",
              }}
            >
              Select Board
            </button>
          </form>
          {this.state.handleID !== 0 && (
            <form onSubmit={this.handleUpdateAttributes}>
              <label className="basicParagraph" htmlFor="">
                Select Product Type:
              </label>
              <select
                className="form-control2"
                onChange={this.handleType}
                value={this.state.productType}
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                <option value="Choose here">Choose here</option>
                <option value="SimplBox">SimplBox</option>
                <option value="SimplMeter">SimplMeter</option>
                <option value="SimplHub">SimplHub</option>
                <option value="SimplNodes">SimplNodes</option>
              </select>
              {this.state.productType === "SimplBox" && (
                <div>
                  <label className="basicParagraph" htmlFor="">
                    Select Product Part Number:
                  </label>
                  <select
                    className="form-control2"
                    onChange={this.handlePN}
                    value={this.state.productPN}
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    <option value="Choose here">Choose here</option>
                    <option value="24 to 40 V">24 to 40 V</option>
                    <option value="27 to 44 V">27 to 44 V</option>
                    <option value="37 to 54 V">37 to 54 V</option>
                  </select>
                </div>
              )}
              {this.state.productType === "SimplMeter" && (
                <div>
                  <label className="basicParagraph" htmlFor="">
                    Select Product Part Number:
                  </label>
                  <select
                    className="form-control2"
                    onChange={this.handlePN}
                    value={this.state.productPN}
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    <option value="Choose here">Choose here</option>
                    <option value="Single Channel">Single Channel</option>
                    <option value="Dual Channel">Dual Channel</option>
                  </select>
                </div>
              )}
              {this.state.productType === "SimplHub" && (
                <div>
                  <label className="basicParagraph" htmlFor="">
                    Select Product Part Number:
                  </label>
                  <select
                    className="form-control2"
                    onChange={this.handlePN}
                    value={this.state.productPN}
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    <option value="Choose here">Choose here</option>
                    <option value="SimplHub in SimplBox">
                      SimplHub in SimplBox
                    </option>
                    <option value="SimplHub SimplModule">
                      SimplHub SimplModule
                    </option>
                  </select>
                </div>
              )}
              {this.state.productType === "SimplNodes" && (
                <div>
                  <label className="basicParagraph" htmlFor="">
                    Select Product Part Number:
                  </label>
                  <select
                    className="form-control2"
                    onChange={this.handlePN}
                    value={this.state.productPN}
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    <option value="Choose here">Choose here</option>
                    <option value="SimplGateway">SimplGateway</option>
                    <option value="SimplControl: Quad Relay Driver">
                      SimplControl: Quad Relay Driver
                    </option>
                    <option value="SimplControl: Quad 30A Relays">
                      SimplControl: Quad 30A Relays
                    </option>
                    <option value="SimplSensor: T/RH/D/L/Rth">
                      SimplSensor: T/RH/D/L/Rth
                    </option>
                    <option value="SimplExtender">SimplExtender</option>
                    <option value="SimplThermostat">SimplThermostat</option>
                  </select>
                </div>
              )}
              {this.state.productType === "SimplNodes" &&
                this.state.productPN === "SimplGateway" && (
                  <div>
                    <label className="basicParagraph" htmlFor="">
                      Serial Number for the EMA Module (if installed):
                    </label>
                    <input
                      value={this.state.fourGserialNumber}
                      onChange={this.handle4G}
                      id="serialNumber"
                      type="text"
                      className="form-control2"
                    />
                  </div>
                )}

              <button
                className="basicButton"
                style={{
                  width: "150px",
                  marginLeft: "50px",
                  marginTop: "10px",
                }}
              >
                Update Attributes
              </button>
            </form>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default ManufactureEdit;
