import React from "react";

const SwitchOffSvg = (props) => (
  <svg
    style={{ margin: "0px", padding: "0px", height: "90px", width: "200px" }}
  >
    <svg
      height="100px"
      width="auto"
      id="ICONS"
      version="1.1"
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <g>
        <rect
          x="2"
          y="2"
          width="80"
          height="50"
          fill="none"
          stroke={props.rectColor}
          stroke-width="2"
        ></rect>
        <text
          x="8"
          y="15"
          font-family="Verdana"
          font-size="12px"
          font-weight="normal"
          fill={props.textColor}
        >
          {props.name}
        </text>
      </g>
    </svg>

    <svg
      height="100px"
      width="auto"
      id="ICONS"
      version="1.1"
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="red"
        d="m9 44h17.18c.41 1.16 1.51 2 2.82 2 1.65 0 3-1.35 3-3 0-.12-.02-.24-.04-.36l17.48-8.75c.49-.25.69-.85.45-1.34-.25-.5-.85-.69-1.34-.45l-17.47 8.74c-.54-.52-1.27-.85-2.08-.85-1.3 0-2.4.84-2.82 2h-17.18c-.55 0-1 .45-1 1s.45 1 1 1zm20-2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1z"
      />
      <path
        stroke="red"
        d="m69 42h-17.18c-.41-1.16-1.51-2-2.82-2-1.65 0-3 1.35-3 3s1.35 3 3 3c1.3 0 2.4-.84 2.82-2h17.18c.55 0 1-.45 1-1s-.45-1-1-1zm-20 2c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
      />
    </svg>
  </svg>
);
export default SwitchOffSvg;
