import React, { Component } from "react";
import { Link } from "react-router-dom";
//import simplWS_client from "./wsService";
import {
  getSystemLatestData,
  ws_getSystemLatestData,
  getSystemIntervalData,
  getSystemInfo,
  getSystemBasics,
  getSystemIDfromHandle,
  getSystem,
  ws_get,
  ws_post,
} from "../services/systemService";

import { setJwt, deleteHeaders } from "../services/httpService";
import { getUser } from "../services/userService";
import "./dashboard.css";
import "../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "react-bootstrap/ProgressBar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import BackGroundRectangle from "../components/backgroundRect";

//const simplWS_client = new WebSocket("ws://127.0.0.1:8082");

class WebsocketComp extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const ipAddress = localStorage.getItem("ipAddress");
    console.log("IP Address: ", ipAddress);
    this.timerID = setInterval(() => this.tick(ipAddress), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  async getSystemData(ipAddress) {
    console.log("ESP32 Server IP Address: ", ipAddress);
    deleteHeaders();
    let getData = await ws_get(ipAddress);
    console.log("Get Data from ESP32 Server:", getData);
    const message = "stuff to be sent to the server...";
    let rcvd_data = await ws_post(ipAddress, message);
    console.log("Received data from ESP32 Server:", rcvd_data);
  }

  tick(ipAddress) {
    this.getSystemData(ipAddress);
  }

  render() {
    return (
      <div
        style={{
          width: "350",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: "150px",
          marginBottom: "150px",
          zIndex: 1,
        }}
      >
        <p>In Websocket</p>
      </div>
    );
  }
}
export default WebsocketComp;
