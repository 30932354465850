import React from "react";

const LPerimeterSvg = (props) => (
  <svg
    style={{ margin: "0px", padding: "0px", width: "800px", height: "425px" }}
  >
    <svg
      width="1000px"
      height="1000px"
      id="ICONS"
      version="1.1"
      viewBox="0 0 1000 1000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        stroke={props.rectColor}
        stroke-width="1"
        d="M 2 2 L 2 420, 795 420, 795 220, 400 220, 400 2Z "
      />
    </svg>
  </svg>
);
export default LPerimeterSvg;
