import React from "react";

const ConvexPerimeterSvg = (props) => (
  <svg
    style={{ margin: "0px", padding: "0px", width: "800px", height: "425px" }}
  >
    <svg
      width="1000px"
      height="1000px"
      id="ICONS"
      version="1.1"
      viewBox="0 0 1000 1000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        stroke={props.rectColor}
        stroke-width="1"
        d="M 2 2 L 2 240, 200 240, 200 420, 600 420, 600 240, 795 240, 795 2Z "
      />
    </svg>
  </svg>
);
export default ConvexPerimeterSvg;
