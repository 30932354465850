import React, { Component } from "react";
import { toast } from "react-toastify";
import Form from "./common/form";
import {
  getSystem,
  getSystemControls,
  setSystemControlsObject,
  setSystemLimitsObject,
  getSystemIDfromHandle,
  getSystemLimits,
  getSimplifiedSystemAlarms,
  getSystemLatestData,
  saveSystemAlarms
} from "../services/systemService";
import { setJwt } from "../services/httpService";
import { getUser } from "../services/userService";
import {
  getFleetInfo,
  getContactInfo,
  getCompanySystems,
  setFleetInfo
} from "../services/companyService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Modal from "react-bootstrap/Modal";
// import BackGroundRectangle from "../components/backgroundRect";
// import Clipboard from "react-clipboard.js";
// import ReactExport from "react-data-export";
// import { OutTable, ExcelRenderer } from "react-excel-renderer";
// import "../excel-2007.css";
// import { Empty } from "antd";
// import QrContainer from "./qrContainer";
// import { animateScroll as scroll } from "react-scroll";
// import { log } from "joi-browser";
// import { faCommentsDollar } from "@fortawesome/free-solid-svg-icons";
//import { node } from "react-svg/node_modules/@types/prop-types";

class FleetInfo extends Component {
  constructor() {
    super();
  }
  state = {
    dataLoaded: false,
    message: "",
    systemID: "",
    systemSN: "",
    systemName: "",
    loadType: "Choose here",
    fleetControl: 0,
    overRide: 0,
    fossilHeat: 0,
    OBActive: 0,
    rthOnly: 0,
    auxHeat: 0,
    saveFleet: true,
    nodeSchTemp: "AAF,ABE,ACD,BDC,CEB,DFA,BAF,CBE",
    nodeHealth: "01,05,10,15",
    nodeScheduleSun: "A",
    nodeOnTempSun: "A",
    nodeOffTempSun: "A",
    nodeScheduleMon: "A",
    nodeOnTempMon: "A",
    nodeOffTempMon: "A",
    nodeScheduleTue: "A",
    nodeOnTempTue: "A",
    nodeOffTempTue: "A",
    nodeScheduleWed: "A",
    nodeOnTempWed: "A",
    nodeOffTempWed: "A",
    nodeScheduleThu: "A",
    nodeOnTempThu: "A",
    nodeOffTempThu: "A",
    nodeScheduleFri: "A",
    nodeOnTempFri: "A",
    nodeOffTempFri: "A",
    nodeScheduleSat: "A",
    nodeOnTempSat: "A",
    nodeOffTempSat: "A",
    nodeScheduleHol: "A",
    nodeOnTempHol: "A",
    nodeOffTempHol: "A",

    nodeSensorSN: "none",
    nodeSensorSN2: "none",
    nodeSensorSN3: "none",
    nodeSensorSN4: "none",

    nodeDoorSensorSN: "none",
    nodeRelaySN: "none",
    nodeSM2SN: "none",
    nodeThermostatSN: "none",
    nodeWalkInSN: "none",
    nodeWHSN: "none",

    nodeTempSetHeatOc: "68",
    nodeTempSetCoolOc: "72",

    nodeTempSetHeatUn: "50",
    nodeTempSetCoolUn: "80",

    nodeDeltaTempSet: "2",
    nodeRHSet: "35",
    nodeFanSetting: "Auto",
    nodeMode: "Auto",
    nodeHvacType: "Multi-stage RTU",

    scheduleMode: "Schedule",

    nodeMinToHealth: 1,
    nodeBadTemp: 5,
    nodePoorTemp: 10,
    nodeGoodTemp: 15,

    nodePositionX: 0,
    nodePositionY: 0,

    copyFleetName: "Choose Here",
    username: "unknown",
    companyName: "",

    handleId: "",
    fleetInfo: [],
    companySystems: [],
    isPropertyChecked: [true, true, true],
    isSystemsChecked: [],
    systemsIDs: [],
    saveFleetButton: true,
    copyFleetbutton: true,
    errors: {},
  };

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    // const companyName = (await getUser()).data.data.company;
    const companyName = this.props.match.params.companyName;
    this.setState({ companyName });
    // // console.log("Company Name", companyName);

    const contactInfo = await getContactInfo(companyName);
    // // console.log("Contact Info:", contactInfo);

    /******* Farid commented *******/
    /*const systemControls = (await getSystemControls(this.state.handleId)).data
      .data.controls;
    const systemLimits = (await getSystemLimits(this.state.handleId)).data.data
      .limits;*/
    /******************************/

    let fleetInfo = (await getFleetInfo(companyName)).data.data[0].fleetInfo;
    // // console.log("Fleet Info: ", fleetInfo);
    this.setState({ fleetInfo });
    if (fleetInfo)
      this.setState({ fleetOptions: fleetInfo });
    else
      this.setState({ fleetOptions: [] });
    const companySystems = (await getCompanySystems(companyName)).data.data;
    // // console.log("This is the systems for the company:", companySystems);
    this.setState({companySystems});

    const systemsLength = companySystems.length;
    // // console.log("Systems length", systemsLength);
    let isSystemsChecked = [];
    const systemsIDs = [];
    for (let i = 0; i < systemsLength; i++) {
      const tempHandleId = companySystems[i].systemID.id;
      const systemTempID = (await getSystemIDfromHandle(tempHandleId))
        .data.data.system_id;
      systemsIDs.push(systemTempID);
      // // console.log("System ID", systemTempID);

      isSystemsChecked.push(false);
      // // console.log("Inside for loop systemchecked", isSystemsChecked);
    }
    // // console.log("systemchecked afterwards", isSystemsChecked);
    // // console.log("systems ids afterwards", systemsIDs);

    this.setState({ isSystemsChecked })
    this.setState({ systemsIDs });

    this.setState({
      contactInfo,
    });

    /************************* For fleet info old ****************************/

    // // console.log("CONTACT INFO:"); 
    // // console.log(contactInfo);

    // // console.log("-----");
    
    // // console.log("FLEET INFO:"); 
    // // console.log(fleetInfo);


    const contactName = contactInfo.contactName;
    const contactEmail = contactInfo.contactEmail;
    const contactPhone = contactInfo.contactPhone;
    const companyAddress = contactInfo.companyAddress;
    // // console.log("Fleet Info", fleetInfo);
    this.setState({
      fleetInfo,
      contactName,
      contactEmail,
      contactPhone,
      companyAddress,
      companyName,
    });
    /************************************************************************/

  }

  handleFleetControl = (e) => {
    // // console.log("In Fleet Control");
    let fleetControl = this.state.fleetControl;
    if (fleetControl === 0) fleetControl = 1;
    else fleetControl = 0;
    // // console.log("fleet control:", fleetControl);
    this.setState({ fleetControl });
  };

  handleSaveFleet = (e) => {
    // // console.log("In Fleet Control");
    let saveFleet = this.state.saveFleet;
    const copyFleet = false;
    if (!saveFleet) saveFleet = true;
    else saveFleet = false;
    this.setState({ saveFleet, copyFleet, saveFleetButton: true });
  };
  handleCopyFleet = (e) => {
    // // console.log("In Fleet Control");
    let copyFleet = this.state.copyFleet;
    const saveFleet = false;
    if (!copyFleet) copyFleet = true;
    else copyFleet = false;
    this.setState({ copyFleet, saveFleet, copyFleetbutton: true });
  };

  handleFleetName = async (e) => {
    const fleetName = e.currentTarget.value;
    this.setState({ fleetName });
  };

  handleFleetDescription = async (e) => {
    const fleetDescription = e.currentTarget.value;
    this.setState({ fleetDescription });
  };

  copyFleet = async (e) => {
    e.preventDefault();
    const copyingFromFleetName = this.state.copyFleetName;
    // // console.log("Copying From Fleet: ", copyingFromFleetName);
    const fleet = this.state.fleetOptions.filter(
      (thisFleet) => thisFleet.fleetName === copyingFromFleetName
    );
    const systemControlsNow = (await getSystemControls(this.state.handleId))
      .data.data.controls;
    // // console.log("Handle ID inside copyfleet", this.state.handleId);
    // // console.log("System Controls now:", systemControlsNow);
    let fleetControls = fleet[0].systemControls;
    let foundNode = {};
    fleetControls.map((obj) => {
      foundNode =
        systemControlsNow.find((o) => o.nodeName === obj.nodeName) || obj;
      Object.assign(obj, {
        nodeDoorSensorMAC: foundNode.nodeDoorSensorMAC,
        nodeDoorSensorSN: foundNode.nodeDoorSensorSN,
        nodeRelayMAC: foundNode.nodeRelayMAC,
        nodeSM2MAC: foundNode.nodeSM2MAC,
        nodeRelaySN: foundNode.nodeRelaySN,
        nodeSM2SN: foundNode.nodeSM2SN,
        nodeSensorMAC: foundNode.nodeSensorMAC,
        nodeThermostatMAC: foundNode.nodeThermostatMAC,
        nodeSensorSN: foundNode.nodeSensorSN,
        nodeSensorSN2: foundNode.nodeSensorSN2,
        nodeSensorSN3: foundNode.nodeSensorSN3,
        nodeSensorSN4: foundNode.nodeSensorSN4,
        nodeThermostatSN: foundNode.nodeThermostatSN,
      });
    });
    // // console.log("System Controls afterwards:", fleet[0].systemControls);
    const systemControls = JSON.stringify(fleet[0].systemControls);

    this.state.isSystemsChecked.forEach(async (system, index) => {
      if (system) {
        // // console.log("system id", this.state.systemsIDs[index]);
        // // console.log(`system handle id ${this.state.companySystems[index].systemID.id}`);
        // // console.log(`system name ${this.state.companySystems[index].systemID.name}`);
        // // console.log("systemschecked", system);

        if (this.state.isPropertyChecked[0]) {
          const newControls = await setSystemControlsObject(this.state.systemsIDs[index], systemControls);
          if (newControls.data.status === "success") {
            toast.success(`Controls copied from fleet "${copyingFromFleetName}" 
              to system "${this.state.companySystems[index].systemID.name}"`);
          } else {
            toast.error("Something went wrong copying controls. Please, try again later.");
          }
          // // console.log("new controls: ", newControls);
        }
        localStorage.setItem("systemControlsLocal", systemControls);
        const systemLimits = JSON.stringify(fleet[0].systemLimits);
  
        if (this.state.isPropertyChecked[1]) {
          const newLimits = await setSystemLimitsObject(this.state.systemsIDs[index], systemLimits);
          if (newLimits.data.status === "success") {
            toast.success(`Limits copied from fleet "${copyingFromFleetName}" 
              to system "${this.state.companySystems[index].systemID.name}"`);
          } else {
            toast.error("Something went wrong copying limits. Please, try again later.");
          }
          // // console.log("Newlimits: ", newLimits);
        }

        // Copy alarms
        console.log("-----------------------------");

        if (this.state.isPropertyChecked[2]) {
          // const systemAlarms = (await getSimplifiedSystemAlarms(this.state.companySystems[index].systemID.id)).data.alarms
          const data = await getSystemLatestData(this.state.companySystems[index].systemID.id)

          try {
            if (data.data.data[0].data.deviceLedger.Appliances && fleet){

              const fleetAlarms = fleet[0].systemAlarms;
              let newArr = []
              console.log("Fleet Alarms:");
              console.log(fleetAlarms);

              const appliancesNames = data.data.data[0].data.deviceLedger.Appliances.map(element => {
                return element.nodeName
              })

              appliancesNames.push("connection")

              if (fleetAlarms.length > 0){
                newArr = fleetAlarms.filter(element => {
                    return appliancesNames.includes(element.nodeName)
                })
              }

              const result = (await saveSystemAlarms(
                this.state.companySystems[index].systemID.id,
                newArr,
                this.state.companySystems[index].systemID.name,
                true
              )).data

              console.log("Appliances: " + this.state.companySystems[index].systemID.id);
              console.log(appliancesNames);
              console.log("newArr:");
              console.log(newArr);

              console.log("Result:");
              console.log(result);

            }

          } catch (e){
            toast.error("It was not possible to paste the alarms")
          }
        }
      }

    })

    /************** Prototype for getting alarms fleet ***************/
    // const alarms = JSON.stringify(fleet[0].alarms);
    // // // console.log("This is the alarms", alarms);
    // // // console.log("This is the username", this.state.username);
    // const resAlarms = await saveSystemAlarms(this.state.handleId, alarms);
    // // // console.log("This is the response from alarms", resAlarms);
    /*****************************************************************/

    let isSystemsChecked = []
    this.state.isSystemsChecked.forEach(async (system, index) => {
      isSystemsChecked[index] = false;
    });

    // // console.log("this state system ID", this.state.systemID);
    const copyFleet = false;
    this.setState({
      copyFleet,
      systemControls: fleet[0].systemControls,
      saveFleet: true,
      isPropertyChecked: [true, true, true],
      isSystemsChecked,
    });
  };

  handleSelectCopyFleet = (e) => {
    const copyFleetName = e.currentTarget.value;
    const isSystemsChecked = this.state.isSystemsChecked;
    let systemsChecked = false;
    let copyFleetbutton;

    for (let i = 0; i < isSystemsChecked.length; i++) {
      if (isSystemsChecked[i]) {
        systemsChecked = true;
        break;
      }
    }

    // copyFleetbutton = copyFleetName !== "Select A Fleet" ? false : true;
    if (systemsChecked && copyFleetName !== "Select A Fleet") {
      copyFleetbutton = false;
    } else {
      copyFleetbutton = true;
    }

    // // console.log("Copy Fleet Name:", copyFleetName);
    const fleetOptions = this.state.fleetOptions;
    // // console.log("Fleet Options in Select Fleet Name", fleetOptions);
    this.setState({ copyFleetName, copyFleetbutton });
  };

  handleFleets = async (e) => {
    e.preventDefault();

    let newFleetInfo = [];
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    const companyName = (await getUser()).data.data.company;
    this.setState({ companyName });

    const contactInfo = this.state.contactInfo;
    // // console.log("Contact Info:", contactInfo);
    let newContactObject = {};

    const systemControls = (await getSystemControls(this.state.handleId)).data
      .data.controls;
    const systemLimits = (await getSystemLimits(this.state.handleId)).data.data
      .limits;
    const systemAlarms = (await getSimplifiedSystemAlarms(this.state.handleId)).data.alarms
    
    /************** Proptotype for alarms **********/
    // const alarms = (await getAllAlarms(this.state.handleId)).data.data;
    // // console.log("Alarms:", alarms)
    /***********************************************/

    // console.log("System Controls: ", systemControls);
    // console.log("System Limits: ", systemLimits);
    let newFleet = {
      fleetName: this.state.fleetName,
      fleetDescription: this.state.fleetDescription,
      fleetOriginHandle: this.state.handleId,
      systemControls: systemControls,
      systemLimits: systemLimits,
      systemAlarms: systemAlarms
      // alarms: alarms
    };
    // console.log("New Fleet", newFleet);

    let fleetInfo = this.state.fleetOptions;
    let sameNameFlag = false;
    // console.log("Fleet Info", fleetInfo);

    if (fleetInfo) {
      for (let i = 0; i < fleetInfo.length; i++) {
        if (newFleet.fleetName === fleetInfo[i].fleetName)
          sameNameFlag = true
        newFleetInfo.push(fleetInfo[i]);
      }
    }
    // console.log("New Fleet Info:", newFleetInfo);
    if (newFleetInfo.length === fleetInfo.length) 
      newFleetInfo.push(newFleet);
    // console.log("Fleet Info after push:", newFleetInfo);
    let newCompanyContact = {};
    Object.assign(
      newCompanyContact,
      { contactName: contactInfo.contactName },
      { contactEmail: contactInfo.contactEmail },
      { contactPhone: contactInfo.contactPhone },
      { companyAddress: contactInfo.companyAddress },
      { fleetInfo: newFleetInfo }
    );
    // console.log("New Company Contact:", newCompanyContact);
    const updateObject = JSON.stringify(fleetInfo);
    // console.log("Updated object", updateObject);

    // console.log("New fleet info:", fleetInfo);
    // console.log("New Contact Object:", newCompanyContact);

    
    if (sameNameFlag) {
      toast.warn(`Fleet Name "${newFleet.fleetName}" already exist. Change it and try again`);
    } else {
      const savedFleets = await setFleetInfo(companyName, newFleetInfo);
      console.log("Saved fleets:");
      console.log(savedFleets);
      // console.log("Saved fleets:", savedFleets);
      const status = savedFleets.data.status.toLowerCase();
      if (status === "success") {
        toast.success(`Fleet "${newFleet.fleetName}" Saved`);
      } else {
        toast.error("Something went wrong creating fleet. Please, try again later");
      }
      const fleetOptions = (await getFleetInfo(companyName)).data.data[0].fleetInfo;
      this.setState({ fleetOptions, fleetInfo: fleetOptions });
      const saveFleet = false;
      this.setState({ saveFleet, copyFleet: true, saveFleetButton: true });
    }
  };

  handleOnChange = (position) => {
    let isPropertyChecked = this.state.isPropertyChecked;
    isPropertyChecked[position] = !isPropertyChecked[position];

    this.setState({ isPropertyChecked });
    // console.log("property set", this.state.isPropertyChecked);
  }

  /****************** Handler of copy fleet formm ********************/
  handleCheckedSystems = (index, e) => {
    // console.log("Index", index);
    // console.log("Event", e);
    // console.log("Event value", e.currentTarget.value);
    // console.log("system ids", this.state.systemsIDs[index]);

    const handleId = e.currentTarget.value;
    let systemsChecked = false;
    const copyFleetName = this.state.copyFleetName;
    let copyFleetbutton;

    /********** For checking systemID is the same as the currentTarget ********************/
    // const systemID = (await getSystemIDfromHandle(e.currentTarget.value)).data.data
    //   .system_id;
    // // console.log("System ID", systemID);
    // copyFleetbutton = copyFleetName !== "Select A Fleet" ? false : true;
    /***********************************************************************************/

    let isSystemsChecked = this.state.isSystemsChecked;
    isSystemsChecked[index] = !isSystemsChecked[index];
    for (let i = 0; i < isSystemsChecked.length; i++) {
      if (isSystemsChecked[i]) {
        systemsChecked = true;
        break;
      }
    }
    if ((copyFleetName !== "Select A Fleet" && copyFleetName !== "Choose Here") && systemsChecked) {
      copyFleetbutton = false;
    } else {
      copyFleetbutton = true;
    }

    // console.log("copy fleet name", copyFleetName);
    // console.log("copy fleet name boolean", copyFleetName === "Choose Here")
    this.setState({ isSystemsChecked, handleId, copyFleetbutton });
    // console.log("Systems checked", this.state.isSystemsChecked);
  }

  /******************* Handler of save fleet form ***********/
  handleSystemSelected = (e) => {
    // console.log("target", e.currentTarget.value);
    const handleId = e.currentTarget.value;
    const saveFleetButton = handleId !== "Select a system" ? false : true;
    this.setState({ handleId, saveFleetButton });
  }

  /*************************** For fleet Info old  *****************************/
  handleGoToSystem = async (fleetOriginHandle) => {
    // console.log("System Origin Handle ID:", fleetOriginHandle);
    const system_id = (await getSystemIDfromHandle(fleetOriginHandle)).data.data
      .system_id;
    const systemID = (await getSystem(system_id)).data.data[0].systemID;
    this.props.history.push(
      `/systems/simplNodesVuWide/${fleetOriginHandle}/${this.state.companyName}/${systemID.SN}/${systemID.name}/companyAdmin`
    );
  };

  handleDeleteFleet = async (fleetName) => {
    let fleetList = [];
    let newCompanyContact = {};
    // console.log("Fleet Name to be deleted:", fleetName);
    const fleetInfo = this.state.fleetInfo;
    // console.log("Fleet Info:", fleetInfo);
    for (let i = 0; i < fleetInfo.length; i++) {
      if (fleetInfo[i].fleetName !== fleetName) fleetList.push(fleetInfo[i]);
    }
    // console.log("Fleet Info after Delete:", fleetList);
    Object.assign(
      newCompanyContact,
      { contactName: this.state.contactName },
      { contactEmail: this.state.contactEmail },
      { contactPhone: this.state.contactPhone },
      { companyAddress: this.state.companyAddress },
      { fleetInfo: fleetList }
    );
    const updateObject = JSON.stringify(newCompanyContact);
    // console.log("New Contact Object:", newCompanyContact);
    // await updateCompanyObject(this.state.companyName, updateObject);
    const fleetRes = await setFleetInfo(this.state.companyName, fleetList);
    if (fleetRes.data.status.toLowerCase() === "success") {
      toast.success(`Fleet "${fleetName}" Deleted`);
    } else {
      toast.error("Something went wrong deleting fleet. Please, try again later");
    }
    this.setState({ fleetInfo: fleetList, fleetOptions: fleetList });
  };
  /*****************************************************************************/

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "360px",
            height: "2000px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "120px",
            marginBottom: "250px",
            zIndex: 1,
          }}
        >
          {this.state.companySystems !== undefined && (
            <div>
              <p
                className="basicParagraph"
                style={{
                  width: "350px",
                  fontSize: "12px",
                  color: "#777575",
                  textAlign: "center",
                }}
                >
                  Fleet Controls
              </p>

              <hr />
              {/* <div
                className="systemClock"
                style={{
                  height: "25px",
                  width: "400px",
                  transform: "translateX(-200px)",
                }}
              >
              </div> */}

              <button
                // className="btn btn-primary shadow-none"
                className="btn btn-outline-warning btn-sm"
                style={{
                  float: "right",
                  // borderWidth: "0px",
                  // color: "transparent",
                  // backgroundColor: "transparent",
                }}
                onClick={this.handleSaveFleet}
                disabled={this.state.saveFleet}
              >
                Create a New Fleet
                {/* <FontAwesomeIcon
                  icon="save"
                  size="lg"
                  color="#777575"
                  title="Create Fleet"
                /> */}
              </button>

              <button
                // className="btn btn-primary shadow-none"
                className="btn btn-outline-warning btn-sm"
                style={{
                  // borderWidth: "0px",
                  transform: "translate(5px, -2px)",
                  // color: "transparent",
                  // backgroundColor: "transparent",
                }}
                onClick={this.handleCopyFleet}
                disabled={this.state.copyFleet}
              >
                Copy a Fleet
                {/* <FontAwesomeIcon
                  icon="copy"
                  size="lg"
                  color="#777575"
                  title="Copy Fleet"
                /> */}
              </button>

              {/*------------------------------------------------------------------------------------*/}
              {this.state.saveFleet && (
              <div>
                <p
                  className="basicParagraph"
                  style={{
                    width: "350px",
                    fontSize: "12px",
                    color: "#777575",
                    textAlign: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  Create a fleet from a system
                </p>
                <form
                  onSubmit={this.handleFleets}
                  style={{ marginTop: "10px", marginBottom: "15px" }}
                >
                  <label
                    className="basicParagraph"
                    style={{ height: "30px", width: "160px" }}
                  >
                    Select the system:
                  </label>
                  <select
                    name="WTF3"
                    onChange={this.handleSystemSelected}
                    className="form-control2"
                    // value={this.state.handleId}
                    id="WTF2"
                    required
                  >
                    <option value="Select a system" />
                    {this.state.companySystems.map((system) => (
                      <option key={system.systemID.name} value={system.systemID.id}>
                        {system.systemID.name}
                      </option>
                    ))}
                  </select>

                  {this.state.saveFleetButton && (
                    <p
                    className="basicParagraph"
                    style={{
                      width: "350px",
                      fontSize: "12px",
                      color: "#777575",
                      textAlign: "center",
                    }}
                    >
                      Select a system to save fleet.
                    </p>
                  )}

                  <label
                    className="basicParagraph"
                    style={{ height: "30px", width: "150px" }}
                  >
                    Enter Fleet Name:
                  </label>
                  <input
                    onChange={this.handleFleetName}
                    id="serialNumber"
                    type="text"
                    className="form-control2"
                    style={{
                      float: "right",
                      height: "30px",
                      width: "200px",
                    }}
                    required
                  />
                  <label
                    className="basicParagraph"
                    style={{ height: "30px", width: "150px" }}
                  >
                    Description:
                  </label>
                  <input
                    onChange={this.handleFleetDescription}
                    id="serialNumber"
                    type="text"
                    className="form-control2"
                    style={{
                      float: "right",
                      height: "30px",
                      width: "200px",
                    }}
                    required
                  />
                  <button
                    className="basicButton"
                    style={{
                      width: "150px",
                      transform: "translateX(75px)",
                      marginTop: "10px",
                      marginLeft: "35px"
                    }}
                    disabled={this.state.saveFleetButton}
                  >
                    Save Fleet
                  </button>
                </form>

                <hr />

                {(!this.state.fleetInfo || this.state.fleetInfo.length === 0) && (
                  <p
                  className="basicParagraph"
                  style={{
                    width: "350px",
                    fontSize: "12px",
                    color: "#777575",
                    textAlign: "center",
                  }}
                  >
                    There are no fleets in "{this.state.companyName}" Company
                  </p>
                )}
                {(this.state.fleetInfo && this.state.fleetInfo.length !== 0) && (
                  <div>

                    <p
                      className="basicParagraph"
                      style={{
                        width: "350px",
                        fontSize: "12px",
                        color: "#777575",
                        textAlign: "center",
                      }}
                    >
                      Fleet Information for: {this.state.companyName}
                    </p>
                    <table
                      className="table"
                      style={{
                        marginTop: "20px",
                        width: "350px",
                        transform: "translateX(0px)",
                      }}
                    >
                      <th>Fleet Name</th>
                      <th>Description</th>
                      <th>System of Origin</th>
                      <th></th>
                      <th></th>
                      {this.state.fleetInfo &&
                        this.state.fleetInfo.map((fleet) => (
                          <tr key={fleet.id}>
                            <td style={{ margin: "0px", padding: "0px", height: "25px" }}>
                              {fleet.fleetName}
                            </td>
                            <td style={{ margin: "0px", padding: "0px", height: "25px" }}>
                              {fleet.fleetDescription}
                            </td>
                            <td style={{ margin: "0px", padding: "0px", height: "25px" }}>
                              {fleet.fleetOriginHandle}
                            </td>
                            <td className="icon">
                              <button
                                className="btn btn-outline-light"
                                style={{
                                  float: "left",
                                  padding: "0px",
                                  borderWidth: "0px",
                                  marginLeft: "12px",
                                  marginTop: "-7px",
                                }}
                                onClick={() =>
                                  this.handleGoToSystem(`${fleet.fleetOriginHandle}`)
                                }
                              >
                                <FontAwesomeIcon
                                  icon="external-link-alt"
                                  size="sm"
                                  color="green"
                                  title="Go to the system of origin"
                                />
                              </button>
                            </td>
                            <td className="icon">
                              <button
                                className="btn btn-outline-light"
                                style={{
                                  float: "left",
                                  padding: "0px",
                                  borderWidth: "0px",
                                  marginLeft: "12px",
                                  marginTop: "-7px",
                                }}
                                onClick={() =>
                                  this.handleDeleteFleet(`${fleet.fleetName}`)
                                }
                              >
                                <FontAwesomeIcon
                                  icon="trash-alt"
                                  size="sm"
                                  color="red"
                                  title="Delete Fleet"
                                />
                              </button>
                            </td>
                          </tr>
                        ))}
                    </table>
                  </div>
                )}
              </div>
              )}

              {/************************************************************************* */}

              {this.state.copyFleet && (
              <div 
              style={{ marginTop: "20px" }}
              >
                <p
                  className="basicParagraph"
                  style={{
                    width: "350px",
                    fontSize: "12px",
                    color: "#777575",
                    textAlign: "center",
                    marginBottom: "20px"
                  }}
                >
                  Copy fleet to system(s) selected:
                </p>
                <form
                  onSubmit={this.copyFleet}
                  style={{ marginTop: "10px", marginBottom: "15px" }}
                >
                  <div className="d-flex flex-row">
                    <label
                      className="basicParagraph"
                      style={{ height: "30px", width: "160px" }}
                    >
                      Fleet to be copied:
                    </label>
                    <select
                      name="WTF3"
                      onChange={this.handleSelectCopyFleet}
                      className="form-control2"
                      id="WTF2"
                    >
                      <option value="Select A Fleet" />
                      {this.state.fleetOptions.map((option) => (
                        <option key={option._id} value={option.fleetName}>
                          {option.fleetName}
                        </option>
                      ))}
                    </select>
                  </div>

                  {this.state.copyFleetbutton && (
                    <p
                    className="basicParagraph"
                    style={{
                      width: "350px",
                      fontSize: "12px",
                      color: "#777575",
                      textAlign: "center",
                    }}
                    >
                      Select a fleet and at least one system.
                    </p>
                  )}
                  <div className="d-flex justify-content-center">

                      <input
                          type="checkbox"
                          name="property"
                          id="property"
                          checked={this.state.isPropertyChecked[0]}
                          onChange={() => this.handleOnChange(0)}
                          value="controls"
                      ></input>
                      <label
                        className="basicParagraph"
                        style={{ paddingLeft: "5px", paddingTop: "8px" }}
                      >
                        Controls
                      </label>

                      <input
                          type="checkbox"
                          name="property"
                          id="property"
                          checked={this.state.isPropertyChecked[1]}
                          onChange={() => this.handleOnChange(1)}
                          value="limits"
                      ></input>
                      <label
                        className="basicParagraph"
                        style={{ paddingLeft: "5px", paddingTop: "8px" }}
                      >
                        Limits
                      </label>

                      <input
                          type="checkbox"
                          name="property"
                          id="property"
                          checked={this.state.isPropertyChecked[2]}
                          onChange={() => this.handleOnChange(2)}
                          value="alarms"
                      ></input>
                      <label
                      className="basicParagraph"
                      style={{ paddingLeft: "5px", paddingTop: "8px" }}
                    >
                      Alarms
                    </label>

                  </div>

                <table
                  className="table"
                  style={{
                    marginTop: "20px",
                    width: "350px",
                    transform: "translateX(20px)",
                  }}
                >
                  <th style={{ margin: "0px", padding: "0px", height: "15px" }}>

                  </th>
                  <th style={{ margin: "0px", padding: "0px", height: "15px" }}>
                    System Name
                  </th>
                  <th style={{ margin: "0px", padding: "0px", height: "15px" }}>
                    Status
                  </th>

                  {this.state.companySystems &&
                    this.state.companySystems.map((system, index) => (
                      <tr key={system.systemID.id}>
                        <td
                          style={{
                            margin: "0px",
                            padding: "0px",
                            width: "50px",
                            height: "25px",
                          }}
                        >
                          <input
                          type="checkbox"
                          name="system"
                          id="system"
                          checked={this.state.isSystemsChecked[index]}
                          onChange={(e) => this.handleCheckedSystems(index, e)}
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                            margin: "5px"
                          }}
                          value={system.systemID.id}
                      ></input>
                        </td>
                        <td
                          style={{
                            margin: "0px",
                            padding: "0px",
                            width: "150px",
                            height: "25px",
                            paddingTop: "5px",
                          }}
                        >
                          {system.systemID.name}
                        </td>
                        <td
                          style={{
                            margin: "0px",
                            padding: "0px",
                            height: "25px",
                            paddingTop: "5px",
                            width: "150px"
                          }}
                        >
                          {system.systemStatus}
                        </td>
                      </tr>
                    ))}
                </table>
                <button
                    className="basicButton"
                    style={{
                      width: "150px",
                      transform: "translateX(75px)",
                      marginTop: "10px",
                      marginLeft: "25px"
                    }}
                    disabled={this.state.copyFleetbutton}
                  >
                    Copy Fleet
                </button>
                </form>
              </div>
              )}

              {/************************************************************************* */}
            </div>
          )}

        </div>
      </React.Fragment>
    );
  }
}
export default FleetInfo;
