import React from "react";
import Joi from "joi-browser";
import {
  setJwt,
  deleteHeaders,
  setOpenWeatherHeader,
} from "../services/httpService";
import Form from "./common/form";
import {
  getSystemIDfromHandle,
  editSystemLocation,
  changeSystemName,
  getSystem,
  getSystemWeather,
} from "../services/systemService";
import BackGroundRectangle from "../components/backgroundRect";
import { getUser } from "../services/userService";

// New stuff for filtering
import { getCompanyUsers } from "../services/companyService";


class AddressForm extends Form {
  state = {
    data: {
      systemName: "",
      streetAddress: "",
      cityAddress: "",
      stateAddress: "",
      zipAddress: "",
      countryAddress: "",

      vicePresident: "",
      regionalManager: "",
      regionalStaffI: "",
      regionalStaffII: "",
      costCenter: "",
      region: "",
    },
    optionsCompanyUsers: [],
    optionsRegions: [],

    username: "",
    serialNumber: "",
    systemName: "",
    errors: {},
  };

  schema = {
    systemName: Joi.string().required().label("System Name"),
    streetAddress: Joi.string().required().label("streetAddress"),
    cityAddress: Joi.string().required().label("City"),
    stateAddress: Joi.string().required().label("State"),
    zipAddress: Joi.string().required().label("Zip Code"),
    contactPhone: Joi.string().required().label("Contact Phone Number"),
    countryAddress: Joi.string().required().label("Country"),

    // New Stuff for filtering
    vicePresident: Joi.string().required().label(
      "Select the vicepresident's username"
    ),
    regionalManager: Joi.string().required().label(
      "Select the regional manager's username"
    ),
    regionalStaffI: Joi.string().required().label(
      "Select the staff I username"
    ),
    regionalStaffII: Joi.string().required().label(
      "Select the staff II username"
    ),

    costCenter: Joi.string().required().label("Cost Center"),
    region: Joi.string().required().label(
      "Select the region"
    ),
  };

  async componentDidMount() {
    const handleId = this.props.match.params.systemHandleID;
    const systemId = (await getSystemIDfromHandle(handleId)).data.data
      .system_id;
    const systemFullInfo = await getSystem(systemId);
    console.log("Full System Info", systemFullInfo);
    const system = systemFullInfo.data.data[0];
    const systemName = system.systemID.name;
    const serialNumber = system.systemID.SN;

    // New Stuff for filtering
    let optionsCompanyUsers = [];
    let optionsRegions = [
      {name: "US Middle West"},
      {name: "US Middle East"},
      {name: "US Northeast"},
      {name: "US Northwest"},
      {name: "US Southeast"},
      {name: "US Southwest"},
    ];
    const companyUsers = (await getCompanyUsers(this.props.match.params.companyName)).data.data;
    companyUsers.forEach(user => {
      optionsCompanyUsers.push({name: user.username});
    });

    this.setState({ optionsCompanyUsers, optionsRegions });

    let newState = Object.assign({}, this.state.data);
    newState.systemName = system.systemID.name;
    newState.streetAddress = system.systemAddress.street;
    newState.cityAddress = system.systemAddress.city;
    newState.stateAddress = system.systemAddress.state;
    newState.zipAddress = system.systemAddress.zip;
    newState.countryAddress = system.systemAddress.country;

    /* New Stuff for filtering */
    newState.vicePresident = system.systemAddress.vicePresident;
    newState.regionalManager = system.systemAddress.regionalManager;
    newState.regionalStaffI = system.systemAddress.regionalStaffI;
    newState.regionalStaffII = system.systemAddress.regionalStaffII;
    newState.costCenter = system.systemAddress.costCenter;
    newState.region = system.systemAddress.region;

    const user = await getUser();
    this.setState({ username: user.data.data.username });
    this.setState({ data: newState });
    this.setState({ systemName });
    this.setState({ serialNumber, systemId });

    console.log("Data: ", this.state.data);
    console.log("newState: ", newState);
  }

  doSubmit = async () => {
    deleteHeaders();
    const systemWeather = await getSystemWeather("Dunkirk", "MD", "US");
    console.log("System Weather", systemWeather);
    const jwt = localStorage.getItem("token");
    setJwt(jwt);

    const locationEdited = await editSystemLocation(
      this.state.serialNumber,
      this.state.data.streetAddress,
      this.state.data.cityAddress,
      this.state.data.stateAddress,
      this.state.data.zipAddress,
      this.state.data.countryAddress,

      this.state.username,

      // New Stuff for filtering

      this.state.data.vicePresident,
      this.state.data.regionalManager,
      this.state.data.regionalStaffI,
      this.state.data.regionalStaffII,
      this.state.data.costCenter,
      this.state.data.region,
    );

    console.log("Address basic data: ", this.state.data);
    console.log("Response of update address: ", locationEdited);
    await changeSystemName(this.state.systemId, this.state.data.systemName);
    this.props.history.goBack();
    /* this.props.history.push(
      `/Companies/systems/${this.props.match.params.companyName}`
    );*/
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150",
            zIndex: 1,
          }}
        >
          <p className="basicParagraph">
            Edit the location information for {this.state.systemName}:
          </p>
          <form onSubmit={this.handleSubmit}>
            {this.renderInput("systemName", "System Name")}
            {this.renderInput("streetAddress", "Street")}
            {this.renderInput("cityAddress", "City")}
            {this.renderInput("stateAddress", "State")}
            {this.renderInput("zipAddress", "Zip Code")}
            {this.renderInput("countryAddress", "Country")}

            <hr></hr>

            {/* New Stuff for filtering */}
            {this.renderSelect(
              "vicePresident",
              "Vice President",
              this.state.optionsCompanyUsers
            )}
            {this.renderSelect(
              "regionalManager",
              "Regional Manager",
              this.state.optionsCompanyUsers
            )}
            {this.renderSelect(
              "regionalStaffI",
              "Staff I",
              this.state.optionsCompanyUsers
            )}
            {this.renderSelect(
              "regionalStaffII",
              "Staff II",
              this.state.optionsCompanyUsers
            )}

            <hr></hr>

            {this.renderInput("costCenter", "Cost Center")}
            {this.renderSelect(
              "region",
              "Region",
              this.state.optionsRegions
            )}

            {this.renderButton("System Information")}
          </form>
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default AddressForm;
