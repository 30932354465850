import axios from "axios";
import logger from "./logService";
import { toast } from "react-toastify";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
   error.response.status >= 400 &&
    error.response.status < 500;
   console.log("Server Error: ", error.response.status);
  if (!expectedError) {
    logger.log(error);
    console.log("Server Error 2: ", error.response.status);
    toast.error("An unexpected error occurrred.");
  }
  console.log("Server Error: ", error);
  return /*Promise.reject*/(error/*.response.status*/);
});

export function setJwt(jwt) {
  axios.defaults.headers.common["Authorization"] = jwt;
}
export function deleteHeaders() {
  delete axios.defaults.headers.common["Authorization"];
}

export function setEmaToken(jwt) {
  axios.defaults.headers.common["Authorization"] = jwt.data.token;
}
export function setEmaApiKey(jwt) {
  axios.defaults.headers.common["x-api-key"] = jwt.data.apiKey;
}
export function setOpenWeatherHeader() {
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.common["Access-Control-Allow-Headers"] =
    "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length";
  axios.defaults.headers.common["Access-Control-Allow-Credentials"] = true;
  axios.defaults.headers.common["Access-Control-Allow-Methods"] = "GET, POST";
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
  deleteHeaders,
  setOpenWeatherHeader,
};
