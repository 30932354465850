import React, { Component } from "react";

import "./dashboard.css";
import "../index.css";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "react-datepicker/dist/react-datepicker.css";

class TstatSettingsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      //newTset: this.props.tSet,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }
  handleChange = (e) => {
    const tSet = e.currentTarget.value;
    this.setState({ tSet });
  };
  handleDuration = (e) => {
    const durationTime = e.currentTarget.value;
    this.setState({ durationTime });
  };

  render() {
    return (
      <>
        {this.props.orCount !== 0 && (
          <button
            className="btn btn-primary shadow-none"
            style={{
              width: "60px",
              height: "15px",
              borderWidth: "none",
              borderColor: "transparent",
              color: "orange",
              fontweight: "bold",
              fontSize: "11px",
              backgroundColor: "transparent",
              padding: "0px",
              margin: "0px",
              textDecoration: "underline",
            }}
            onClick={this.handleShow}
          >
            OVR {(this.props.tSet / 100.0).toFixed(0)}&#176;F
          </button>
        )}
        {/*
        {this.props.simplThermCool === 1 && this.props.orCount === 0 && (
          <button
            className="btn btn-primary shadow-none"
            style={{
              width: "60px",
              height: "15px",
              borderWidth: "none",
              borderColor: "transparent",
              color: "dodgerblue",
              fontweight: "bold",
              fontSize: "11px",
              backgroundColor: "transparent",
              padding: "0px",
              margin: "0px",
              textDecoration: "underline",
            }}
            onClick={this.handleShow}
          >
            {(this.props.tSet / 100.0).toFixed(0)}&#176;F |{" "}
            {this.props.rhSet.toFixed(0)}%
          </button>
          )}*/}
        {(this.props.simplThermHeat === 1 || this.props.simplThermCool === 1) &&
          this.props.orCount === 0 && (
            <button
              className="btn btn-primary shadow-none"
              style={{
                width: "60px",
                height: "15px",
                borderWidth: "none",
                borderColor: "transparent",
                color: "red",
                fontweight: "bold",
                fontSize: "11px",
                backgroundColor: "transparent",
                padding: "0px",
                margin: "0px",
              }}
              onClick={this.handleShow}
            >
              <span style={{ color: "red" }}>
                {" "}
                {this.props.tSetHeat}&#176;F
              </span>
              <span style={{ color: "black" }}> |</span>
              <span style={{ color: "dodgerblue" }}>
                {" "}
                {this.props.tSetCool}&#176;F
              </span>
            </button>
          )}
        {this.props.simplThermCool !== 1 &&
          this.props.simplThermHeat !== 1 &&
          this.props.orCount === 0 && (
            <button
              className="btn btn-primary shadow-none"
              style={{
                width: "60px",
                height: "15px",
                borderWidth: "none",
                borderColor: "transparent",
                color: "black",
                fontweight: "bold",
                fontSize: "11px",
                backgroundColor: "transparent",
                padding: "0px",
                margin: "0px",
                textDecoration: "underline",
              }}
              onClick={this.handleShow}
            >
              {this.props.tSetHeat}&#176;F | {this.props.tSetCool}&#176;F
            </button>
          )}
        <Modal
          style={{
            top: "30%",
            left: "30%",
            width: "250px",
            height: "800px",
            alignContent: "center",
          }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body>
            <label className="basicParagraph">{this.props.nodeName}</label>
            <p className="basicParagraph" style={{ color: "orange" }}>
              Over-ride Seconds Remaining: {this.props.orCount}
            </p>
            <label className="basicParagraph">
              Enter Temp. Setting in &#176;F for:
            </label>
            <input
              value={this.state.tSet}
              onChange={this.handleChange}
              id="fullScaleCurrent"
              type="text"
              className="form-control2"
            />
            <label className="basicParagraph">Enter Duration in Minutes:</label>
            <input
              value={this.state.duartionTime}
              onChange={this.handleDuration}
              id="fullScaleCurrent"
              type="text"
              className="form-control2"
            />

            <button
              className="basicButton"
              style={{
                width: "90px",
                height: "30px",
                marginTop: "10px",
                fontSize: "9px",
                backgroundColor: "green",
              }}
              onClick={() => {
                this.handleHide();
                this.props.changeThermostatSetting(
                  `${this.state.tSet}`,
                  `${this.state.durationTime}`,
                  `${this.props.nodeName}`
                );
              }}
            >
              Update OverRide
            </button>
            <button
              className="basicButton"
              style={{
                width: "90px",
                height: "30px",
                marginTop: "10px",
                fontSize: "9px",
                marginLeft: "10px",
                backgroundColor: "tomato",
              }}
              onClick={() => {
                this.handleHide();
                this.props.cancelOverRide(`${this.props.nodeName}`);
              }}
            >
              End OverRide
            </button>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default TstatSettingsModal;
