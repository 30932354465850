import React from "react";
import Form from "./common/form";
import Joi from "joi-browser";
import BackGroundRectangle from "../components/backgroundRect";

import { revokeCompanyAdmin, addCompanyAdmin } from "../services/userService";
import { getCompanies, getCompanyUsers } from "../services/companyService";
import { setJwt } from "../services/httpService";
import { getUser } from "../services/userService";
import { isNullOrUndefined } from "util";

class CompanyAdminForm extends Form {
  state = {
    data: { username: "" },
    companyAdmin: "",
    errors: {},
  };

  schema = {
    username: Joi.string().label("Username"),
  };

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    const companies = (await getCompanies()).data.data;
    const loggedInUser = (await getUser()).data.data;
    //console.log("Companies", companies);
    const company = companies.filter(
      (thisCompany) =>
        thisCompany.companyName === this.props.match.params.companyName
    );
    const companyAdmin = company[0].companyAdmin;
    this.setState({ companyAdmin });
  }

  doSubmit = async () => {
    //try {
    const companyName = this.props.match.params.companyName;
    console.log("Company Name:", companyName);
    const { data } = this.state;
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    console.log("JWT code", jwt);
    const loggedInUser = (await getUser()).data.data;

    if (this.state.companyAdmin) {
      await revokeCompanyAdmin(
        this.state.companyAdmin.username,
        this.props.match.params.companyName
      );
      console.log("Successfully revoked Admin");
    }

    await addCompanyAdmin(data.username, this.props.match.params.companyName);
    console.log("Successfully Added Admin");
    window.location = "/companies";
    //} catch (ex) {
    /* if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors: errors });
     }*/
    //}
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1,
          }}
        >
          <div>
            {this.state.companyAdmin && (
              <div>
                <p className="basicParagraph">
                  {this.props.match.params.companyName} has an assigned Admin.{" "}
                </p>
                <p className="basicParagraph">
                  Username: {this.state.companyAdmin.username}
                </p>
                <p className="basicParagraph">
                  Name: {this.state.companyAdmin.firstName}{" "}
                  {this.state.companyAdmin.lastName}
                </p>
                <p className="basicParagraph">
                  Please proceed if you's like to change the Admin.
                </p>
              </div>
            )}

            <div>
              <form onSubmit={this.handleSubmit}>
                {this.renderInput("username", "Username")}
                {this.renderButton("Assign as Admin")}
              </form>
            </div>
          </div>
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default CompanyAdminForm;
