import React, { Component } from "react";

import * as echarts from "echarts/dist/echarts.js";
//import ReactECharts from "echarts-for-react";
//import * as echarts from "echarts/core";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactExport from "react-data-export";
import "./dashboard.css";
import {
  getSystemIntervalData,
  getSystemBasics,
} from "../services/systemService";
import zIndex from "@material-ui/core/styles/zIndex";
import {
  faCommentsDollar,
  faTemperatureLow,
} from "@fortawesome/free-solid-svg-icons";

class DemandGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphLen: 0,
      base: new Date(),
      oneDay: 86400000,
      date: [],
      calendarDay: [],
      pvPower: [0],
      batteryPower: [0],
      now: new Date(),
      pvInterval: [],
      battInterval: [],
    };
  }

  async componentDidMount() {
    const startDate = this.props.startDate;
    const endDate = this.props.endDate;
    console.log("DemandGraph Start Date: ", startDate);
    console.log("DemandGraph End Date: ", endDate);

    const systemBasics = await getSystemBasics(this.props.handleID);
    console.log("System Basics", systemBasics);
    console.log("Demand Limit: ", this.props.demandLimit);
    let circuitNameMeter1 = systemBasics.data.data.basics.circuitNameMeter1;
    if (circuitNameMeter1 !== "0" && circuitNameMeter1 !== "1")
      circuitNameMeter1 = "0";
    this.setState({ circuitNameMeter1 });

    this.computeIntervalChart(startDate, endDate);
    this.chart = echarts.init(document.getElementById("demand-graph"));

    this.timerID = setInterval(() => this.tick(), 800);
    this.generateGraph();
  }

  async computeIntervalChart(startDate, endDate) {
    const systemIntervalRaw = await getSystemIntervalData(
      this.props.handleID,
      startDate,
      endDate
    );
    console.log("System Inteval Data", systemIntervalRaw);
    const systemIntervalData = systemIntervalRaw.data.data;

    console.log("System Inteval Data", systemIntervalData);

    let recordLength = systemIntervalData.length;
    //if (recordLength < 96) recordLength = 96;
    let pvInterval = [0];
    let battInterval = [0];
    let gridInterval = [0];
    let bldgInterval = [0];
    let pvEnergyValue = [0];
    let pvDemandValue = [0];
    let battEnergyValue = [0];
    let battDemandValue = [0];
    let gridEnergyValue = [0];
    let gridDemandValue = [0];
    let bldgEnergyValue = [0];
    let bldgDemandValue = [0];
    let date = [0];
    let calendarDay = [0];
    let i = 0;
    let dataCount = 0;
    let maxGlobalPriority = [0];
    for (i = 0; i < recordLength; i++) {
      if (
        systemIntervalData[i].data.intervalDataFreq ===
        systemIntervalData[recordLength - 1].data.intervalDataFreq
      ) {
        if (parseInt(this.props.systemConfiguration) === 0) {
          pvInterval[dataCount] = (
            systemIntervalData[i].data.pvInterval /
            (1000 * 3600)
          ).toFixed(3);
          battInterval[dataCount] = (
            systemIntervalData[i].data.batteryInterval /
            (1000 * 3600)
          ).toFixed(3);
          pvEnergyValue[dataCount] =
            systemIntervalData[i].data.pvEnergyValue.toFixed(3);
          pvDemandValue[dataCount] =
            systemIntervalData[i].data.pvDemandValue.toFixed(3);
          battEnergyValue[dataCount] =
            systemIntervalData[i].data.batteryEnergyValue.toFixed(3);
          battDemandValue[dataCount] =
            systemIntervalData[i].data.batteryDemandValue.toFixed(3);
        } else if (parseInt(this.props.systemConfiguration) === 2) {
          pvInterval[dataCount] =
            systemIntervalData[i].data.acuvimInterval_1 / (4 * 1000);
          pvEnergyValue[dataCount] =
            systemIntervalData[i].data.acuvimEnergyValue_1;
          pvDemandValue[dataCount] =
            systemIntervalData[i].data.acuvimDemandValue_1;

          if (this.state.circuitNameMeter1 === "0") {
            gridInterval[dataCount] =
              systemIntervalData[i].data.acuvimInterval_0 / (4 * 1000);
            gridEnergyValue[dataCount] =
              systemIntervalData[i].data.acuvimEnergyValue_0;
            gridDemandValue[dataCount] =
              systemIntervalData[i].data.acuvimDemandValue_0;

            bldgInterval[dataCount] =
              pvInterval[dataCount] + gridInterval[dataCount];
            bldgEnergyValue[dataCount] =
              pvEnergyValue[dataCount] + gridEnergyValue[dataCount];
            bldgDemandValue[dataCount] =
              pvDemandValue[dataCount] + gridDemandValue[dataCount];
          } else if (this.state.circuitNameMeter1 === "1") {
            bldgInterval[dataCount] =
              systemIntervalData[i].data.acuvimInterval_0 / (4 * 1000);
            bldgEnergyValue[dataCount] =
              systemIntervalData[i].data.acuvimEnergyValue_0;
            bldgDemandValue[dataCount] =
              systemIntervalData[i].data.acuvimDemandValue_0;

            gridInterval[dataCount] =
              bldgInterval[dataCount] - pvInterval[dataCount];
            gridEnergyValue[dataCount] =
              bldgEnergyValue[dataCount] - pvEnergyValue[dataCount];
            gridDemandValue[dataCount] =
              bldgDemandValue[dataCount] - pvDemandValue[dataCount];
          } else {
          }
        } else if (parseInt(this.props.systemConfiguration) === 3) {
          pvInterval[dataCount] = systemIntervalData[i].data.Interval_1;
          pvEnergyValue[dataCount] = systemIntervalData[i].data.EnergyValue_1;
          pvDemandValue[dataCount] = systemIntervalData[i].data.DemandValue_1;

          if (this.state.circuitNameMeter1 === "0") {
            gridInterval[dataCount] = systemIntervalData[i].data.Interval_0;
            gridEnergyValue[dataCount] =
              systemIntervalData[i].data.EnergyValue_0;
            gridDemandValue[dataCount] =
              systemIntervalData[i].data.DemandValue_0;

            bldgInterval[dataCount] =
              pvInterval[dataCount] + gridInterval[dataCount];
            bldgEnergyValue[dataCount] =
              pvEnergyValue[dataCount] + gridEnergyValue[dataCount];
            bldgDemandValue[dataCount] =
              pvDemandValue[dataCount] + gridDemandValue[dataCount];
          } else if (this.state.circuitNameMeter1 === "1") {
            bldgInterval[dataCount] = systemIntervalData[i].data.Interval_0;
            bldgEnergyValue[dataCount] =
              systemIntervalData[i].data.EnergyValue_0;
            bldgDemandValue[dataCount] =
              systemIntervalData[i].data.DemandValue_0;

            gridInterval[dataCount] =
              bldgInterval[dataCount] - pvInterval[dataCount];
            gridEnergyValue[dataCount] =
              bldgEnergyValue[dataCount] - pvEnergyValue[dataCount];
            gridDemandValue[dataCount] =
              bldgDemandValue[dataCount] - pvDemandValue[dataCount];
          } else {
          }
        }
        ////////////////////////////////////////////////////////////////////
        else if (parseInt(this.props.systemConfiguration) === 6) {
          let multiplier = 1.0;
          pvInterval[dataCount] =
            (multiplier * systemIntervalData[i].data.meter2Interval) / 4;
          pvEnergyValue[dataCount] =
            multiplier * systemIntervalData[i].data.meter2EnergyValue;
          pvDemandValue[dataCount] =
            multiplier * systemIntervalData[i].data.meter2DemandValue;

          if (this.state.circuitNameMeter1 === "0") {
            gridInterval[dataCount] = (
              (multiplier * systemIntervalData[i].data.meter1Interval) /
              4
            ).toFixed(1);
            gridEnergyValue[dataCount] =
              multiplier * systemIntervalData[i].data.meter1EnergyValue;
            gridDemandValue[dataCount] =
              multiplier * systemIntervalData[i].data.meter1DemandValue;

            bldgInterval[dataCount] =
              multiplier * pvInterval[dataCount] + gridInterval[dataCount];
            bldgEnergyValue[dataCount] =
              multiplier * pvEnergyValue[dataCount] +
              gridEnergyValue[dataCount];
            bldgDemandValue[dataCount] =
              multiplier * pvDemandValue[dataCount] +
              gridDemandValue[dataCount];
          } else if (this.state.circuitNameMeter1 === "1") {
            bldgInterval[dataCount] =
              (multiplier * systemIntervalData[i].data.meter1Interval) / 4;
            bldgEnergyValue[dataCount] =
              multiplier * systemIntervalData[i].data.meter1EnergyValue;
            bldgDemandValue[dataCount] =
              multiplier * systemIntervalData[i].data.meter1DemandValue;

            gridInterval[dataCount] = (
              bldgInterval[dataCount] - pvInterval[dataCount]
            ).toFixed(1);
            gridEnergyValue[dataCount] =
              bldgEnergyValue[dataCount] - pvEnergyValue[dataCount];
            gridDemandValue[dataCount] =
              bldgDemandValue[dataCount] - pvDemandValue[dataCount];
          } else {
          }
        } else if (parseInt(this.props.systemConfiguration) === 8) {
          if (systemIntervalData[i].data.maxGlobalPriority === undefined)
            maxGlobalPriority[dataCount] = 0;
          else
            maxGlobalPriority[dataCount] =
              systemIntervalData[i].data.maxGlobalPriority.toFixed(0);
          console.log("Max Global Priority:", maxGlobalPriority[dataCount]);
          //const multiplier = 1.0;
          let multiplier = 1.0;
          /*if (parseInt(this.props.systemConfiguration) === 8) multiplier = 10.0;*/
          pvInterval[dataCount] =
            multiplier * systemIntervalData[i].data.meter2Interval;
          pvEnergyValue[dataCount] =
            multiplier * systemIntervalData[i].data.meter2EnergyValue;
          pvDemandValue[dataCount] =
            multiplier * systemIntervalData[i].data.meter2DemandValue;

          if (this.state.circuitNameMeter1 === "0") {
            gridInterval[dataCount] = (
              multiplier * systemIntervalData[i].data.meter1Interval
            ).toFixed(1);
            gridEnergyValue[dataCount] =
              multiplier * systemIntervalData[i].data.meter1EnergyValue;
            gridDemandValue[dataCount] =
              multiplier * systemIntervalData[i].data.meter1DemandValue;

            bldgInterval[dataCount] =
              multiplier * pvInterval[dataCount] + gridInterval[dataCount];
            bldgEnergyValue[dataCount] =
              multiplier * pvEnergyValue[dataCount] +
              gridEnergyValue[dataCount];
            bldgDemandValue[dataCount] =
              multiplier * pvDemandValue[dataCount] +
              gridDemandValue[dataCount];
          } else if (this.state.circuitNameMeter1 === "1") {
            bldgInterval[dataCount] =
              multiplier * systemIntervalData[i].data.meter1Interval;
            bldgEnergyValue[dataCount] =
              multiplier * systemIntervalData[i].data.meter1EnergyValue;
            bldgDemandValue[dataCount] =
              multiplier * systemIntervalData[i].data.meter1DemandValue;

            gridInterval[dataCount] = (
              bldgInterval[dataCount] - pvInterval[dataCount]
            ).toFixed(1);
            gridEnergyValue[dataCount] =
              bldgEnergyValue[dataCount] - pvEnergyValue[dataCount];
            gridDemandValue[dataCount] =
              bldgDemandValue[dataCount] - pvDemandValue[dataCount];
          } else {
          }
        }
        //////////////////////////////////////////////////////////////////
        date[dataCount] = systemIntervalData[i].data.localTimeStamp;
        dataCount++;

        console.log("Data Count:", dataCount);
      }
    }
    console.log("Max Global Priority:", maxGlobalPriority);
    const timeZone = systemIntervalData[recordLength - 1].data.TimeZone;
    console.log("Demand Interval Time Zone:", timeZone);
    calendarDay = date.map((x) =>
      [
        new Date(x /*+ timeZone * 3600*/ * 1000).getUTCMonth() + 1,
        new Date(x /*+ timeZone * 3600*/ * 1000).getUTCDate(),
        new Date(x /*+ timeZone * 3600*/ * 1000).getUTCFullYear(),
      ].join("/")
    );

    date = date.map((x) =>
      [
        new Date(x /*+ timeZone * 3600*/ * 1000).getUTCHours(),
        new Date(x /*+ timeZone * 3600*/ * 1000).getUTCMinutes(),
        new Date(x /*+ timeZone * 3600*/ * 1000).getUTCSeconds(),
      ].join(":")
    );
    let excelDataSet = [0];
    for (i = 0; i < dataCount; i++) {
      excelDataSet[i] = JSON.parse(
        JSON.stringify({
          Date: calendarDay[i],
          Time: date[i],

          PV: parseFloat(pvInterval[i]),
          PVEnergy: parseFloat(pvEnergyValue[i]),
          PVDemand: parseFloat(pvDemandValue[i]),

          Battery: parseFloat(battInterval[i]),
          BattEnergy: parseFloat(battEnergyValue[i]),
          BattDemand: parseFloat(battDemandValue[i]),

          Grid: parseFloat(gridInterval[i]),
          GridEnergy: parseFloat(gridEnergyValue[i]),
          GridDemand: parseFloat(gridDemandValue[i]),

          Bldg: parseFloat(bldgInterval[i]),
          BldgEnergy: parseFloat(bldgEnergyValue[i]),
          BldgDemand: parseFloat(bldgDemandValue[i]),
        })
      );
    }
    //console.log("Excel Data JSON", excelDataSet);
    this.setState({
      pvInterval,
      pvEnergyValue,
      pvDemandValue,
      battInterval,
      battEnergyValue,
      battDemandValue,
      gridInterval,
      gridEnergyValue,
      gridDemandValue,
      bldgInterval,
      bldgEnergyValue,
      bldgDemandValue,
      dataCount,
      calendarDay,
      date,
      excelDataSet,
      maxGlobalPriority,
    });
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.chart.setOption({
      xAxis: {
        data: this.state.date,
      },
      series: [
        {
          name: "Grid (kWh)",
          data: this.state.gridInterval,
        },
        {
          name: "Priority Level",
          data: this.state.maxGlobalPriority,
        },
      ],
    });
  }

  generateGraph() {
    var option = {
      legend: {
        show: true,
        type: "scroll",
        data: ["Building Demand (kW)"],
        center: "50%",
        top: "5%",
        orient: "horizontal",
        align: "auto",
        padding: 10,
        textStyle: {
          fontStyle:
            "normal" /*'Lato,"Helvetica Neue",Arial,Helvetica,sans-serif'*/,
          fontSize: 10,
          fontWeight: "bold",
          color: "grey",
        },
        backgroundColor: "transparent",
        borderColor: "#ffffff",
        borderWidth: 1,
        boarderRadius: 0,
      },
      xAxis: {
        name: "",
        nameLocation: "end",
        nameGap: 50,
        type: "category",
        nameTextStyle: {
          fontStyle:
            "normal" /*'Lato,"Helvetica Neue",Arial,Helvetica,sans-serif'*/,
          fontSize: 16,
          fontWeight: "bolder",
          color: "green",
        },
        axisLabel: {
          fontStyle: "normal",
          fontSize: 8,
          fontWeight: "normal",
          color: "orange",
        },
        boundaryGap: false,
        data: this.state.date,
      },
      yAxis: [
        {
          show: true,
          name: "Power (kW)",
          position: "left",
          nameLocation: "end",
          splitLine: {
            show: false,
          },
          boundaryGap: [0, "10%"],
          type: "value",
          nameTextStyle: {
            fontStyle: "normal",
            fontSize: 16,
            fontWeight: "bolder",
            color: "purple",
          },
          axisLabel: {
            fontStyle: "normal",
            fontSize: 10,
            fontWeight: "bold",
            color: "purple",
          },
        },
        {
          show: true,
          name: "Priority Level",
          position: "right",
          nameLocation: "end",
          splitLine: {
            show: false,
          },
          boundaryGap: [0, "10%"],
          type: "value",
          nameTextStyle: {
            fontStyle: "normal",
            fontSize: 16,
            fontWeight: "bolder",
            color: "orange",
          },
          axisLabel: {
            fontStyle: "normal",
            fontSize: 10,
            fontWeight: "bold",
            color: "orange",
          },
        },
      ],
      series: [
        {
          type: "bar",
          markLine: {
            label: { fontSize: 16, fontWeight: "bold" },
            lineStyle: {
              color: "tomato",
              width: 3,
              type: "solid",
              shadowBlur: 10,
              opacity: 0.5,
            },
            data: [
              {
                name: "Horizontal line with Y value at 0.5",
                yAxis: parseFloat(this.props.demandLimit),
              },
            ],
          },
          name: "Building Interval Data (kWh)",
          type: "bar",
          symbol: "none",
          barWidth: "100%",
          itemStyle: {
            borderColor: "gray",
            color: "purple",
          },
          areaStyle: {
            normal: {},
          },
          data: this.state.gridInterval,
        },

        {
          name: "Priority Level",
          type: "line",
          step: "middle",
          yAxisIndex: 1,
          symbol: "none",
          color: "orange",
          label: {
            show: true,
            position: "top",
          },
          data: this.state.maxGlobalPriority,
        },
      ],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
            precision: "2",
          },
        },
      },
    };
    this.chart.setOption(option);
  }

  render() {
    const intervalLength = this.state.calendarDay.length - 1;

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    return (
      <React.Fragment>
        <table
          className="table2"
          style={{
            position: "relative",
            width: "100%",
            transform: "translateX(-50%)",
            left: "50%",
            marginRight: "0px",
            marginBottom: "15px",
          }}
        >
          <td>
            <ExcelFile
              filename="Simpl Global 15-min Interval Data"
              element={
                <button
                  className="btn btn-outline-light"
                  style={{
                    padding: "0px",
                    float: "right",
                    marginRight: 150,
                    marginTop: "00px",
                  }}
                >
                  <FontAwesomeIcon
                    icon="file-excel"
                    size="lg"
                    color="green"
                    title="Export interval data to excel"
                  />
                </button>
              }
            >
              <ExcelSheet data={this.state.excelDataSet} name="SystemData">
                <ExcelColumn label="Date(mo/day/year)" value="Date" />
                <ExcelColumn label="Time(hr:min:sec)" value="Time" />
                <ExcelColumn label="Solar (kWh)" value="PV" />
                <ExcelColumn label="Solar Energy Value ($)" value="PVEnergy" />
                <ExcelColumn label="Solar Demand Value ($)" value="PVDemand" />
                <ExcelColumn label="Battery (kWh)" value="Battery" />
                <ExcelColumn
                  label="Battery Energy Value ($)"
                  value="BattEnergy"
                />
                <ExcelColumn
                  label="Battery Demand Value ($)"
                  value="BattDemand"
                />
                <ExcelColumn label="Grid (kWh)" value="Grid" />
                <ExcelColumn label="Grid Energy Value ($)" value="GridEnergy" />
                <ExcelColumn label="Grid Demand Value ($)" value="GridDemand" />
                <ExcelColumn label="Building (kWh)" value="Bldg" />
                <ExcelColumn
                  label="Building Energy Value ($)"
                  value="BldgEnergy"
                />
                <ExcelColumn
                  label="Battery Demand Value ($)"
                  value="BldgDemand"
                />
              </ExcelSheet>
            </ExcelFile>
          </td>
          <td
            style={{
              fontFamily: "arial",
              fontWeight: "550",
              color: "#2d5c88",
              fontSize: "12px",
              paddingTop: "4px",
              float: "right",
              marginRight: "5px",
              position: "relative",
              right: "0px",
            }}
          >
            To: {this.state.calendarDay[intervalLength]} at{" "}
            {this.state.date[intervalLength]}
          </td>
          <td
            style={{
              fontFamily: "arial",
              fontWeight: "550",
              color: "#2d5c88",
              fontSize: "12px",
              paddingTop: "4px",
              float: "right",
              marginRight: "5px",
              position: "relative",
              right: "0px",
            }}
          >
            From: {this.state.calendarDay[0]} at {this.state.date[0]}
          </td>
        </table>

        <div id="demand-graph" />
      </React.Fragment>
    );
  }
}
export default DemandGraph;
