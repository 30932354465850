
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getUser, getAllUsers } from "../services/userService";
import { controlAccess, getCompany } from "../services/companyService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import jwt_decode from "jwt-decode"
import {
    apiUrl,
    clientGoogleID
} from "../config.json";
import BackGroundRectangle from "./backgroundRect";


function UserAccessControl({companyName}) {
    const [checked, changeCheck] = useState(false)
    const [company, setCompanyName] = useState("")
    const [unableUsers, setUnabledUsers] = useState([])

    const getUnableUsers = async () => {
        const pattern = /^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/gm
        let users = await getAllUsers()
        users = users.data.data
        let companyUsers = users.filter( ele => {
            return ele.company === company
            }
        )
        const notAbleUsers = companyUsers.filter(ele => {
            return !ele.phoneNumber.match(pattern)
        })

        setUnabledUsers(notAbleUsers)

        return notAbleUsers
    }

    const updateMfa = async () => {
        try {
            if (checked){
                await controlAccess(checked)
                toast.success("The Multi-Factor Authentication is on for all your users")
            } else {
                await controlAccess(checked)
                toast.success("The Multi-Factor Authentication is off for all your users")
            }
        } catch (e){
            toast.error("You are not able to complete this operation")
        }
    }

    getUnableUsers()

    try {
        useEffect( () => {
            const runBeforeLoading = async () => {
                // let user = await getUser()    
                // const companyName = user.data.data.company
                // const companyName = this.props.companyName;
                setCompanyName(companyName)

                const company = (await getCompany(companyName)).data.data;

                // let company = companies.data.data.filter( arr => {
                //         return arr.companyName === companyName 
                //     }
                // )
                // company = company[0]
    
                if (company.MFA === true){
                    changeCheck(true)
                } else if (company.MFA === false){
                    console.log("entra false");
                    changeCheck(false)
                }
            }
            runBeforeLoading()
        }, checked)

    } catch (e){
        toast.error(e.message)
    }

    return (
        <React.Fragment>
        <div
          style={{
            width: "400px",
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "10px",
            marginBottom: "150px",
            zIndex: 1,
          }}
        >
            <p
            className="basicParagraph"
            style={{ width: "100%", textAlign: "center", fontSize: "12px" }}
            >
                User Access Control 
            </p>
            {checked === true && (
                <div class="custom-control custom-switch">
                <input
                type="checkbox"
                class="custom-control-input"
                id="customSwitches"
                checked
                onChange={()=>{
                    changeCheck(false)
                }}
                ></input>
                <label class="custom-control-label" for="customSwitches">
                    <p
                    className="basicParagraph"
                    style={{ fontSize: "10px", paddingTop: "5px" }}
                    >
                        Multi-factor Authentication is on. Switch to turn it off.
                    </p>
                </label>
                </div>
            )}
            {checked === false && (
                <div class="custom-control custom-switch">
                <input
                type="checkbox"
                class="custom-control-input"
                id="customSwitches"
                onChange={()=>{
                    changeCheck(true)
                }}
                ></input>
                <label class="custom-control-label" for="customSwitches">
                    <p
                    className="basicParagraph"
                    style={{ fontSize: "10px", paddingTop: "5px" }}
                    >
                        Multi-factor Authentication is off. Switch to turn it on.
                    </p>
                </label>
            </div>
            )}
            {(unableUsers.length > 0 && checked === true) && (
                <div>
                    <p
                    className="basicParagraph"
                    style={{ fontSize: "10px", paddingTop: "5px" }}
                    >
                        The following users must specify a valid number to log in using Multi-Factor Authentication
                    </p>
                    <ul>
                        {unableUsers.map((item)=>
                            <li style={{font: "11px arial"}}
                                className="text-danger"
                            >{item.username}</li>
                        )}
                    </ul>
                </div>
            )

            }
            <button onClick={()=>{
                updateMfa()
            }}
            type="button"
            class="btn btn-outline-success btn-sm mt-4">
              Update
            </button>
            

        </div>
        </React.Fragment>
    );

}


export default UserAccessControl
