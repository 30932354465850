import React, { Component } from "react";
import { toast } from "react-toastify";
import { setJwt } from "../services/httpService";
import { getUser } from "../services/userService";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "./common/form";
import Modal from "react-bootstrap/Modal";
import BackGroundRectangle from "../components/backgroundRect";
import GitInfo from "react-git-info/macro";
import Zoom from "react-reveal/Zoom";
import Flash from "react-reveal/Flash";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import { BasisCurve } from "react-svg-curve";

class AccountInfo extends Component {
  state = {
    user: {},
    adminUsername: "",
    mapState: true,
  };

  async componentDidMount() {
    try {
      const solarDuration = "20s";

      const jwt = localStorage.getItem("token");
      setJwt(jwt);

      console.log("Application Name: ", process.env.REACT_APP_NAME);
      console.log("SimplWeb Version: ", process.env.REACT_APP_VERSION);

      const gitInfo = GitInfo();
      console.log(gitInfo.branch);
      console.log(gitInfo.tags);
      console.log(gitInfo.commit.date);
      console.log(gitInfo.commit.hash);
      console.log(gitInfo.commit.message);
      console.log(gitInfo.commit.shortHash);

      const mapBoolean = JSON.parse(localStorage.getItem("mapBoolean"));
      console.log("Map Boolean", mapBoolean);
      const user = await getUser();
      console.log("User", user);
      this.setState({ solarDuration });

      this.setState({ user: user.data.data });
      this.setState({ mapState: mapBoolean });
      this.setState({ appName: process.env.REACT_APP_NAME });
      this.setState({ appVersion: process.env.REACT_APP_VERSION });
      this.setState({
        gitBranch: gitInfo.branch,
        gitTime: gitInfo.commit.date,
        gitHash: gitInfo.commit.shortHash,
      });
    } catch (ex) {}
  }
  useMap = (e) => {
    e.preventDefault();
    let mapState = this.state.mapState;
    mapState = !mapState;
    const mapBoolean = mapState;
    localStorage.setItem("mapBoolean", JSON.stringify(mapBoolean));
    console.log("Map State", mapState);
    this.setState({ mapState });
  };
  render() {
    console.log("User Info", this.state.user);

    return (
      <Zoom>
        {" "}
        <React.Fragment>
          <div
            style={{
              width: "350px",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              marginTop: "250px",
              marginBottom: "150px",
              zIndex: 1,
            }}
          >
            <button
              onClick={this.useMap}
              className="btn btn-outline-light"
              style={{
                transform: "translateX(105px)",
                borderWidth: "0px",
                borderColor: "white",
              }}
            >
              <FontAwesomeIcon
                icon="home"
                size="5x"
                color="dodgerblue"
                title="Toggle Map/List"
              />
            </button>
            <table
              className="table"
              style={{
                transform: "translate(0px,150px)",
                borderColor: "none",
                borderTopColor: "white",
              }}
            >
              <tr>
                <td style={{ borderColor: "none", borderTopColor: "white" }}>
                  <Link to={`/logout`}>
                    <FontAwesomeIcon
                      icon="car-battery"
                      size="5x"
                      color="green"
                      title="Log out"
                    />
                  </Link>
                </td>

                <td
                  style={{
                    transform: "translate(-8px,0px)",
                    borderColor: "none",
                    borderTopColor: "white",
                  }}
                >
                  <Link to={`/logout`}>
                    <FontAwesomeIcon
                      icon="solar-panel"
                      size="5x"
                      color="orange"
                      title="Solar"
                    />
                  </Link>
                </td>

                <td
                  style={{
                    transform: "translate(-8px,0px)",
                    borderColor: "none",
                    borderTopColor: "white",
                  }}
                >
                  <Link to={`/logout`}>
                    <FontAwesomeIcon
                      icon="charging-station"
                      size="5x"
                      color="tomato"
                      title="Log out"
                    />
                  </Link>
                </td>
              </tr>
            </table>
            <svg
              width="65"
              height="148"
              viewBox="0 0 36 82"
              style={{
                border: "1px",
                borderStyle: "solid",
                borderColor: "#cccccc",
                transform: "translate(45px,-90px)",
              }}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeWidth="1.8"
                fill="none"
                stroke="green"
                d="M 5 80 C 5 25, 14 25, 34 5"
              />

              <circle r="2.5" fill="green">
                <animateMotion
                  dur="20s"
                  repeatCount="indefinite"
                  path="M 5 80 C 5 25, 14 25, 34 5"
                />
              </circle>
            </svg>
            <svg
              width="65"
              height="148"
              viewBox="0 0 36 82"
              style={{
                border: "1px",
                borderStyle: "solid",
                borderColor: "#cccccc",
                transform: "translate(65px,-90px)",
              }}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeWidth="1.8"
                fill="none"
                stroke="orange"
                d="M 18 80 L 18 5"
              />

              <circle r="2.5" fill="orange">
                <animateMotion
                  dur="20s"
                  repeatCount="indefinite"
                  path="M 18 80 L 18 5"
                />
              </circle>
            </svg>
            <svg
              width="65"
              height="148"
              viewBox="0 0 36 82"
              style={{
                border: "1px",
                borderStyle: "solid",
                borderColor: "#cccccc",
                transform: "translate(85px,-90px)",
              }}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeWidth="1.8"
                fill="none"
                stroke="tomato"
                d="M 31 80 C 31 25, 22 25, 2 5"
              />

              <circle r="2.5" fill="tomato">
                <animateMotion
                  dur="20s"
                  repeatCount="indefinite"
                  path="M 31 80 C 31 25, 22 25, 2 5"
                />
              </circle>
            </svg>
            <svg
              width="90"
              height="30"
              viewBox="0 0 90 30"
              style={{
                border: "1px",
                borderStyle: "solid",
                borderColor: "#cccccc",
                transform: "translate(-20px,70px)",
              }}
            >
              <path
                strokeWidth="2.5"
                fill="none"
                stroke="orange"
                d="M 89 1 C 89 25, 1 25, 1 1"
              />

              <circle r="3.5" fill="orange">
                <animateMotion
                  dur="20s"
                  repeatCount="indefinite"
                  path="M 1 1 C 1 25, 89 25,89 1 "
                />
              </circle>
            </svg>
            <svg
              width="90"
              height="30"
              viewBox="0 0 90 30"
              style={{
                border: "1px",
                borderStyle: "solid",
                borderColor: "#cccccc",
                transform: "translate(58px,-20px)",
              }}
            >
              <path
                strokeWidth="2.5"
                fill="none"
                stroke="blue"
                d="M 89 1 C 89 25, 1 25, 1 1"
              />

              <circle r="3.5" fill="blue">
                <animateMotion
                  dur="20s"
                  repeatCount="indefinite"
                  path="M 89 1 C 89 25, 1 25, 1 1"
                />
              </circle>
            </svg>
          </div>
        </React.Fragment>
      </Zoom>
    );
  }
}

export default AccountInfo;

{
  /*<svg
              width="50"
              height="50"
              viewBox="0 0 100 100"
              style={{
                border: "1px",
                borderStyle: "solid",
                borderColor: "#cccccc",
              }}
            >
              <circle cx="25" cy="25" r="25" />
            </svg>
            <svg
              width="50"
              height="50"
              viewBox="0 0 100 100"
              style={{
                transform: "translate(50px, 50px)",
                border: "1px",
                borderStyle: "solid",
                borderColor: "#cccccc",
              }}
            >
              <circle cx="25" cy="25" r="25" />
            </svg>
            */
}
{
  /*<path
                id="linePath"
                fill="transparent"
                stroke="#fff"
                strokeWidth="4.5"
                strokeDasharray="420"
                d="M38,29c4.7-11.9"
              >
                <animate from="420" to="0" dur="2s" fill="freeze" />
</path>*/
}
{
  /*}
<svg width="350px" height="150px" style={{}}>
              <BasisCurve
                data={[
                  [125, 10],
                  [125, 50],
                  [50, 50],
                  [50, 150],
                ]}
                showPoints={false}
                strokeWidth={2}
                stroke="green"
              />
            </svg>
            <svg
              style={{
                transform: "translate(40px,-150px)",
              }}
            >
              <BasisCurve
                data={[
                  [160, 10],
                  [160, 50],
                  [235, 50],
                  [235, 150],
                ]}
                showPoints={false}
                strokeWidth={2}
                stroke="tomato"
              />
            </svg>
            <svg
              style={{
                transform: "translate(3px,-300px)",
              }}
            >
              <BasisCurve
                data={[
                  [160, 10],
                  [160, 150],
                ]}
                showPoints={false}
                strokeWidth={2}
                stroke="orange"
              />
            </svg>
              */
}

class AppVersionModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <>
        <button
          className="btn btn-outline-light"
          style={{
            borderWidth: "0px",
            padding: "0px",
            float: "right",
            marginRight: "5px",
            position: "relative",
            right: "0px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="info-circle"
            size="lg"
            color="dodgerblue"
            title="App Version"
          />
        </button>

        <Modal
          style={{ width: "250px", alignContent: "center" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.appName}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.appVersion}
            </p>

            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.gitBranch}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.gitTime}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.gitHash}
            </p>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
