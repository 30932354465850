import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { logoutUser, getVerificationCode, verifyCode } from "../services/userService";
import { useEffect, useState } from "react";
///////////////////////////
import { getUser } from "../services/userService";
/////////////////////////////

import Joi from "joi-browser";
import Form from "./common/form";
import {
  masterCompany,
  footerLogoSource,
  manufacturingCompany,
} from "../config.json";
import BackGroundRectangle from "./backgroundRect";

const linkStyle = {
  margin: "0px 0px 8px",
  textDecoration: "none",
  font: "10.5px arial",
}

class VerifyLogin extends Form {
  state = {
    data: {
      code: "",
      phone: "",
      verified: false,
    },
    errors: {},
  };
  schema = {
    code: Joi.string().required().label("Code"),
  };

  componentDidMount = async () => {
    try {
      const { data } = this.state
      let user = null
      user = await getUser();
      const phone = '+1' + user.data.data.phoneNumber
      this.state.data.phone = phone

      await getVerificationCode(data.phone)

    } catch (e){
      console.log(`Error: ${e}`);
    }
  }

  doSubmit = async (e) => {
    try {
      const { data } = this.state

      const responseCode = await verifyCode(data.phone, data.code)
      const status = responseCode.data.status

      if (status == "approved"){
        this.state.data.verified = true
        toast.success("Valid code")
        window.location = "/";
      } else if (status == "pending"){
        this.state.data.verified = false
        toast.error("The code is invalid")
      } else {
        this.state.data.verified = false
        toast.error("The code is invalid")
      }
      localStorage.setItem('verified', this.state.data.verified)      

    } catch (ex) {
      if (ex.response && ex.response.status === 401) {
        console.log(ex.message)
        toast.error("Something went wrong");
      }
    }
  };

  render() {
    console.log("code", this.state.data.code);
    return (
      <React.Fragment>
        <div
          style={{
            width: "400px",
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1,
          }}
        >
          <p style={{
            margin: "0px 0px 8px",
            textDecoration: "none",
            font: "11px arial",
            fontWeight: "bold",
            marginBottom: "10px"
          }} >
            We have sent you a verification code
          </p>
          <div style={{
            margin: "15px 0px 15px 0px"
          }}>
            <form onSubmit={this.handleSubmit}>
              {this.renderInput("code", "Code")}
              <a
                className="text-danger"
                href="javascript:void(0)"
                onClick={()=>{
                  console.log("works!")
                  this.componentDidMount()
                }}
                style={linkStyle}>Resend a new code</a> <br /> <br />
              <div style={{
                marginTop: 20,
                display: "flex",
                flexFlow: "column wrap",
                alignItems: "center"
              }}>
                {this.renderButton("Login")}
                <button
                  className="btn btn-outline-warning btn-sm"
                  style={{ width: "220px", marginTop: "10px" }}
                  type="button"
                  onClick={async ()=>{
                    await logoutUser("user", "pass")
                    window.location.href = "/"
                  }}
                >
                  Back to menu 
                </button>
              </div>
            </form>
          </div>
          
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default VerifyLogin;


