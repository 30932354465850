import React from "react";

const WaterheaterSvg = (props) => (
  <svg
    style={{ margin: "0px", padding: "0px", height: "90px", width: "200px" }}
  >
    <svg
      height="200px"
      width="auto"
      id="ICONS"
      version="1.1"
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <text
          x="6"
          y="26"
          font-family="Verdana"
          font-size="6"
          font-weight="normal"
          fill={props.textColor}
        >
          ASH
        </text>
      </g>
    </svg>
    <svg
      height="48"
      viewBox="0 5 60 60"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M0 0h48v48h-48zm0 0h48v48h-48zm0 0h48v48h-48z" fill="none" />
        <g fill={props.strokeColor1}>
          <path d="M36 28c0-8-12-21.6-12-21.6s-2.66 3.02-5.47 7.04l17.17 17.17c.19-.84.3-1.71.3-2.61zm-1.76 6.24l-9.24-9.24-14.46-14.46-2.54 2.55 6.64 6.64c-1.53 2.92-2.64 5.85-2.64 8.27 0 6.63 5.37 12 12 12 3.04 0 5.8-1.14 7.91-3l5.27 5.27 2.54-2.55-5.48-5.48z" />
        </g>
      </g>
    </svg>
  </svg>
);
export default WaterheaterSvg;
