import React from "react";

const HoodSvg = (props) => (
  <svg
    style={{ margin: "0px", padding: "0px", height: "90px", width: "200px" }}
  >
    <svg
      height="200px"
      width="auto"
      id="ICONS"
      version="1.1"
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <text
          x="7"
          y="48"
          font-family="Verdana"
          font-size="6"
          font-weight="normal"
          fill={props.textColor}
        >
          Hood
        </text>
      </g>
    </svg>
    <svg
      version="1.1"
      width="50"
      height="auto"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke-width="0px"
        stroke={props.strokeColor1}
        fill={props.strokeColor1}
        fill-rule="evenodd"
      >
        <path d="M8.5,1c0,-0.276142 0.22386,-0.5 0.5,-0.5h6c0.2761,0 0.5,0.223858 0.5,0.5v6.70984l6.7481,3.85606c0.1558,0.089 0.2519,0.2547 0.2519,0.4341v4c0,0.2761 -0.2239,0.5 -0.5,0.5h-20c-0.27614,0 -0.5,-0.2239 -0.5,-0.5v-4c0,-0.1794 0.09614,-0.3451 0.25193,-0.4341l6.74807,-3.85606v-6.70984Zm1,0.5v6.5c0,0.17943 -0.09614,0.3451 -0.25193,0.43412l-6.74807,3.85608v3.2098h19v-3.2098l-6.7481,-3.85608c-0.1558,-0.08902 -0.2519,-0.25469 -0.2519,-0.43412v-6.5h-5Z" />
        <path d="M13.2137,17.6676c0.1943,0.1916 0.1927,0.5081 -0.0035,0.707l-0.5984,0.6064c-0.1375,0.1394 -0.1401,0.3607 -0.0058,0.4968c0.514,0.5211 0.5042,1.3682 -0.022,1.9014l-0.5984,0.6065c-0.1963,0.1988 -0.5128,0.2047 -0.7071,0.013c-0.1944,-0.1916 -0.1928,-0.5081 0.0034,-0.7069l0.5984,-0.6065c0.1375,-0.1393 0.1401,-0.3607 0.0058,-0.4968c-0.514,-0.521 -0.5042,-1.3682 0.022,-1.9014l0.5985,-0.6065c0.1962,-0.1988 0.5127,-0.2046 0.7071,-0.013Z" />
        <path d="M9.57987,17.6402c0.19432,0.1917 0.19278,0.5082 -0.00342,0.707l-0.59847,0.6065c-0.13748,0.1393 -0.14004,0.3607 -0.00575,0.4968c0.51397,0.521 0.50416,1.3682 -0.022,1.9014l-0.59847,0.6065c-0.1962,0.1988 -0.51278,0.2046 -0.7071,0.013c-0.19431,-0.1916 -0.19278,-0.5082 0.00343,-0.707l0.59847,-0.6065c0.13748,-0.1393 0.14004,-0.3606 0.00575,-0.4968c-0.51397,-0.521 -0.50417,-1.3681 0.022,-1.9013l0.59847,-0.6065c0.1962,-0.1989 0.51278,-0.2047 0.70709,-0.0131Z" />
        <path d="M16.8472,17.6402c0.1943,0.1917 0.1928,0.5082 -0.0034,0.707l-0.5985,0.6065c-0.1375,0.1393 -0.14,0.3607 -0.0057,0.4968c0.5139,0.521 0.5041,1.3682 -0.022,1.9014l-0.5985,0.6065c-0.1962,0.1988 -0.5128,0.2046 -0.7071,0.013c-0.1943,-0.1916 -0.1928,-0.5082 0.0034,-0.707l0.5985,-0.6065c0.1375,-0.1393 0.14,-0.3606 0.0057,-0.4968c-0.5139,-0.521 -0.5041,-1.3681 0.022,-1.9013l0.5985,-0.6065c0.1962,-0.1989 0.5128,-0.2047 0.7071,-0.0131Z" />
      </g>
    </svg>
  </svg>
);
export default HoodSvg;
