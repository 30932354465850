import React from "react";
import Joi from "joi-browser";

import Form from "./common/form";
import {
  getSystem,
  getSystems,
  setSystemControls
} from "../services/systemService";

class CalibrateSystem extends Form {
  state = {
    systems: getSystems(),
    data: {
      modeOfOperation: "Time-of-Use",
      touStart: "17.0",
      touStop: "21.5",
      powerReduction: "3.0",
      maxGridPower: "100.0",
      minGridPower: "0.0"
    },
    optionsModes: [
      { _id: "1", name: "Time-of-Use" },
      { _id: "2", name: "Demand Shaving" },
      { _id: "3", name: "Self-Supply" },
      { _id: "5", name: "PV Smoothing" }
    ],
    errors: {}
  };

  schema = {
    modeOfOperation: Joi.string().label("Mode of Operation:  DS, SS, TOU, PVS"),
    touStart: Joi.number()
      .required()
      .label("TOU On-Pk Start Time (24-hr format)"),
    touStop: Joi.number()
      .required()
      .label("TOU On-Pk End Time (24-hr format)"),
    powerReduction: Joi.number()
      .min(0.0)
      .max(1000.0)
      .label("Demand Shaving Power Reduction in (kW)"),
    maxGridPower: Joi.number()
      .min(0.0)
      .max(1000.0)
      .label("Max Grid Power for PV Smoothing in (kW)"),
    minGridPower: Joi.number()
      .min(0.0)
      .max(1000.0)
      .label("Min Grid Power for Self-Supply in (kW)")
  };

  async componentDidMount() {
    const systemId = this.props.match.params.id;
    const system = await getSystem(systemId);
    const systemName = system.data.data.systemID.name;
    this.setState({ systemName: systemName });
    const systemControls = system.data.data.systemControls;
    this.setState({ data: systemControls });
  }

  doSubmit = async () => {
    //   Vactual = a * Vmeas + b
    //  a = (Vactual_1 - Vactual_2) / (Vmeas_1 - Vmeas_2)
    //  b = Vactual_1 - (a * Vmeas_1)
    /*   const aCh1 = (Ch1_Vactual_1 - Ch1_Vactual_2) / (Ch1_Vmeas_1 - Ch1_Vmeas_2);
    const aCh2 = (Ch2_Vactual_1 - Ch2_Vactual_2) / (Ch2_Vmeas_1 - Ch2_Vmeas_2);
    const aCh3 = (Ch3_Vactual_1 - Ch3_Vactual_2) / (Ch3_Vmeas_1 - Ch3_Vmeas_2);
    const aCh4 = (Ch4_Vactual_1 - Ch4_Vactual_2) / (Ch4_Vmeas_1 - Ch4_Vmeas_2);
    const bCh1 = Ch1_Vactual_1 - aCh1 * Ch1_Vmeas_1;
    const bCh2 = Ch2_Vactual_1 - aCh2 * Ch2_Vmeas_1;
    const bCh3 = Ch3_Vactual_1 - aCh3 * Ch3_Vmeas_1;
    const bCh4 = Ch4_Vactual_1 - aCh4 * Ch4_Vmeas_1;*/
    //aCh1 to 4 and bCh1 to 4 must get sent to the slave units through the master*/
    /*   await setSystemControls(
      this.props.match.params.id,
      this.state.data.modeOfOperation,
      this.state.data.touStart,
      this.state.data.touStop,
      this.state.data.powerReduction,
      this.state.data.maxGridPower,
      this.state.data.minGridPower
    );*/
    this.props.history.push("/systems");
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150"
          }}
        >
          <h6>4-Channel, Two-point Voltage or Current System Calibration:</h6>

          <h3>{this.state.systemName}</h3>
          <h4> Channel 1:</h4>
          <form onSubmit={this.handleSubmit}>
            <p> First Data Point:</p>
            {this.renderInput(
              "Ch1_Vactual_1",
              "Voltage 1 as measured by a calibrated voltmeter"
            )}
            {this.renderInput("Ch1_Vmeas_1", "Voltage 1 as measured by Simpl")}
            <p> Second Data Point:</p>
            {this.renderInput(
              "Ch1_Vactual_2",
              "Voltage 2 as measured by a calibrated voltmeter"
            )}
            {this.renderInput("Ch1_Vmeas_2", "Voltage 2 as measured by Simpl")}
            <h4> Channel 2:</h4>
            <p> First Data Point:</p>
            {this.renderInput(
              "Ch2_Vactual_1",
              "Voltage 1 as measured by a calibrated voltmeter"
            )}
            {this.renderInput("Ch2_Vmeas_1", "Voltage 1 as measured by Simpl")}
            <p> Second Data Point:</p>
            {this.renderInput(
              "Ch2_Vactual_2",
              "Voltage 2 as measured by a calibrated voltmeter"
            )}
            {this.renderInput("Ch2_Vmeas_2", "Voltage 2 as measured by Simpl")}
            <h4> Channel 3:</h4>
            <p> First Data Point:</p>
            {this.renderInput(
              "Ch3_Vactual_1",
              "Voltage 1 as measured by a calibrated voltmeter"
            )}
            {this.renderInput("Ch3_Vmeas_1", "Voltage 1 as measured by Simpl")}
            <p> Second Data Point:</p>
            {this.renderInput(
              "Ch3_Vactual_2",
              "Voltage 2 as measured by a calibrated voltmeter"
            )}
            {this.renderInput("Ch3_Vmeas_2", "Voltage 2 as measured by Simpl")}
            <h4> Channel 4:</h4>
            <p> First Data Point:</p>
            {this.renderInput(
              "Ch4_Vactual_1",
              "Voltage 1 as measured by a calibrated voltmeter"
            )}
            {this.renderInput("Ch4_Vmeas_1", "Voltage 1 as measured by Simpl")}
            <p> Second Data Point:</p>
            {this.renderInput(
              "Ch4_Vactual_2",
              "Voltage 2 as measured by a calibrated voltmeter"
            )}
            {this.renderInput("Ch4_Vmeas_2", "Voltage 2 as measured by Simpl")}
            {this.renderButton("Save Measurements")}
          </form>
          <h6> </h6>
        </div>
      </React.Fragment>
    );
  }
}

export default CalibrateSystem;
