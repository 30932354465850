import React, { Component } from "react";

import { removeInstaller } from "../services/userService";
import { setJwt } from "../services/httpService";

class UserRemoveInstaller extends Component {
  state = {
    errors: {},
  };

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    console.log("jwt", jwt);
    console.log("Props", this.props);
    const username = this.props.match.params.username;
    const serialNumber = this.props.match.params.serialNumber;
    const handleID = this.props.match.params.handleID;
    await removeInstaller(username, serialNumber);
    this.props.history.push(`/systems/users/${handleID}/${this.props.match.params.companyName}`);
    // this.props.history.push(`/systems/webSocketComp`);
  }

  render() {
    return null;
  }
}

export default UserRemoveInstaller;
