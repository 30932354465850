import React, { Component } from "react";

import "./dashboard.css";
import "../index.css";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUser } from "../services/userService";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "react-datepicker/dist/react-datepicker.css";
import { masterCompany } from "../config.json";

class SimpleMenu extends React.Component {
  state = {
    user: {}
  }

  async componentDidMount(){
    const user = await getUser()

    this.setState({user: user.data.data})

    console.log("Userr:");
    console.log(user);
  }

  render() {
    const anchorEl = this.props.anchorState;

    return (
      <div>
        <button
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={this.props.onClickingMenu}
          className="btn btn-outline-light"
          style={{
            borderWidth: "0px",
            padding: "0px",
            margin: "0px",
            //float: "right",
            marginRight: "5px",
            //position: "relative",
            right: "0px",
          }}
        >
          <FontAwesomeIcon
            icon="clock"
            size="sm"
            color="magenta"
            title="show numbers"
          />
        </button>
        <Menu
          style={{
            borderWidth: "0px",
          }}
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.props.onChoosingToday}
        >
          <MenuItem
            style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
            onClick={this.props.onChoosingRT}
          >
            Real-time
          </MenuItem>
          <MenuItem
            style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
            onClick={this.props.onChoosingInterval}
          >
            Last 3 Hours
          </MenuItem>
          <MenuItem
            style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
            onClick={this.props.onChoosingToday}
          >
            Today
          </MenuItem>
          <MenuItem
            style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
            onClick={this.props.onChoosingYesterday}
          >
            Yesterday
          </MenuItem>
          <MenuItem
            style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
            onClick={this.props.onChoosingWeek}
          >
            This Week
          </MenuItem>
          <MenuItem
            style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
            onClick={this.props.onChoosingLastWeek}
          >
            Last Week
          </MenuItem>

          {(this.state.user.company === masterCompany && this.state.user.admin) && (
            <> 
              <MenuItem
                style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
                onClick={this.props.onChoosingMonth}
              >
                This Month
              </MenuItem>
              <MenuItem
                style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
                onClick={this.props.onChoosingLastMonth}
              >
                Last Month
              </MenuItem>
              <MenuItem
                style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
                onClick={this.props.onChoosingQuarter}
              >
                This Quarter
              </MenuItem>
              <MenuItem
                style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
                onClick={this.props.onChoosingYear}
              >
                This Year
              </MenuItem>
              <MenuItem
                style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
                onClick={this.props.onChoosingTimeRange}
              >
                Time Range
              </MenuItem>
              <MenuItem
                style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
                onClick={this.props.onChoosingDR}
              >
                Date Range
              </MenuItem>
              <hr></hr>
              <MenuItem
                style={{
                  fontSize: "12px",
                  margin: "1px",
                  padding: "1px",
                  color: "magenta",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  marginLeft: "13px",
                }}
                onClick={this.props.onChoosingDaily}
              >
                Daily
              </MenuItem>
            </>
          )}
        </Menu>
      </div>
    );
  }
}

export default SimpleMenu;
