import React, { Component } from "react";
import { toast } from "react-toastify";
import { setJwt } from "../services/httpService";
import { getUser } from "../services/userService";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "./common/form";
import Rectangle from "react-rectangle";
import "../index.css";

class BackGroundRectangle extends Component {
  state = {};

  async componentDidMount() {
    const backgroundImageNumber = parseInt(Math.random() * 10) + 1;
    console.log("Image Number", backgroundImageNumber);
    const bgx = "bg" + backgroundImageNumber;
    this.setState({ bgx });
  }

  Rectangle(props) {
    return (
      <div styles={{ width: props.width }}>
        <div
          styles={{
            // width: "100%",
            //height: 0,
            height: "inherit",
            //paddingBottom: "-300px",
            background: "#000000",
          }}
        ></div>
      </div>
    );
  }

  render() {
    //console.log("User Info", this.state.user);
    const bg = this.state.bgx;
    return (
      <div className={bg}>
        <Rectangle aspectRatio={[5, 3]}>
          <div
            style={{
              opacity: "80%",
              background: "#ffffff",
              width: "450px",
              height: "100%",
              position: "fixed",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 0,
            }}
          />
        </Rectangle>
      </div>
    );
  }
}

export default BackGroundRectangle;
