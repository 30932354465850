import React, { Component } from "react";

import { removeOperator } from "../services/userService";
import { setJwt } from "../services/httpService";

class UserRemoveOperator extends Component {
  state = {
    errors: {},
  };

  async componentDidMount() {
    console.log("Do yo make it here");
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    console.log("jwt", jwt);
    console.log("Props", this.props);
    const username = this.props.match.params.username;
    const serialNumber = this.props.match.params.serialNumber;
    const handleID = this.props.match.params.handleID;
    const companyName = this.props.match.params.companyName;
    await removeOperator(username, serialNumber);
    this.props.history.push(`/systems/users/${handleID}/${companyName}`);
    // this.props.history.push(`/systems/webSocketComp`);
  }

  render() {
    return null;
  }
}

export default UserRemoveOperator;
