import React, { Component } from "react";
import * as echarts from "echarts/dist/echarts.js";
import "bootstrap/dist/css/bootstrap.css";
import "./dashboard.css";
import {
  getSystemRealTimeData,
  getSystemLatestData,
  getSystemBasics,
  ws_getSystemLatestData,
  getSystemInfo,
} from "../services/systemService";

import { webSocketMode } from "../config.json"; //0: http; 1:websocket

class ChartBldgPower extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphLen: 0,
      base: new Date(),
      oneDay: 86400000,
      date: [],
      value1: [0],
      now: new Date(),
    };
  }

  async componentDidMount() {
    this.chart = echarts.init(document.getElementById("bldgPower-chart"));
    const tickNumber = 1000;
    this.timerID = setInterval(() => this.tick(), tickNumber);

    const thisTime = this.props.dataDate;
    console.log("This Time: ", thisTime);
    const dateLength = thisTime.length;
    let thisHumanTime = [];
    let thisHumanDate = 0;
    let d = 0;
    let timeZone = this.props.timeZone;
    for (let i = 0; i < dateLength; i++) {
      d = new Date(thisTime[i] /* - timeZone * 3600*/ * 1000);
      thisHumanDate = thisHumanTime[i] = [
        d.getUTCMonth() + 1,
        d.getUTCDate(),
        d.getUTCFullYear(),
      ].join("/");
      thisHumanTime[i] = [d.getUTCHours(), d.getUTCMinutes()].join(":");
      thisHumanTime[i] = [thisHumanDate, thisHumanTime[i]].join(" @ ");
    }
    console.log("This Human Time: ", thisHumanTime);
    this.setState({ thisHumanTime });
    this.generateGraph();
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.chart.setOption({
      /*yAxis: {
        max: this.props.maxTemp + 5.0,
        min: this.props.minTemp - 5.0,
      },*/
      xAxis: {
        data: this.state.thisHumanTime,
        axisTick: { show: false },
        splitLine: { show: false },
      },
      series: [
        {
          name: "Bldg Power (kW)",
          data: this.props.bldgIntervalPower,
        },
      ],
    });
  }

  generateGraph() {
    var option = {
      /* legend: {
        type: "scroll",
        data: ["Bldg Power (kW)"],
        center: "50%",
        top: "0%",
        orient: "horizontal",
        align: "auto",
        padding: 3,
        textStyle: {
          fontStyle:
            "normal" ,
          fontSize: 10,
          fontWeight: "bold",
          color: "grey",
        },
        backgroundColor: "transparent",
        borderColor: "#ffffff",
        borderWidth: 1,
        boarderRadius: 0,
      },*/
      xAxis: {
        name: "",
        nameLocation: "end",
        type: "category",
        nameTextStyle: {
          fontStyle:
            "normal" /*'Lato,"Helvetica Neue",Arial,Helvetica,sans-serif'*/,
          fontSize: 16,
          fontWeight: "bolder",
          color: "green",
        },
        axisLabel: {
          fontStyle: "normal",
          fontSize: 8,
          fontWeight: "normal",
          color: "orange",
        },
        boundaryGap: false,
        data: this.state.thisHumanTime,
      },
      yAxis: {
        show: false,
        name: "Power (Watts)",
        nameLocation: "end",
        boundaryGap: [0, "10%"],
        type: "value",
        nameTextStyle: {
          fontStyle:
            "normal" /*'Lato,"Helvetica Neue",Arial,Helvetica,sans-serif'*/,
          fontSize: 16,
          fontWeight: "bolder",
          color: "green",
        },
        axisLabel: {
          fontStyle: "normal",
          fontSize: 10,
          fontWeight: "bold",
          color: "green",
        },
      },
      /*   dataZoom: [
        {
          type: "slider",
          show: true,
          xAxisIndex: [0],
          start: 1,
          end: 100,
        },
        {
          type: "inside",
          xAxisIndex: [0],
          start: 1,
          end: 100,
        },
      ],*/
      series: [
        {
          name: "Return T (F)",
          type: "line",
          symbol: "none",
          color: "purple",
          //smooth: 0.25,
          areaStyle: {
            normal: {
              color: "purple",
              opacity: 0.25,
            },
          },
          data: this.props.bldgIntervalPower,
        },
      ],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          animation: false,
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
            precision: "2",
          },
        },
      },
    };

    this.chart.setOption(option);
  }

  render() {
    return <div id="bldgPower-chart" />;
  }
}
export default ChartBldgPower;
