import React, { Component } from "react";
import { addOwner } from "../services/userService";
import { setJwt } from "../services/httpService";

class UserAddOwner extends Component {
  state = {
    errors: {}
  };

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    console.log("Props", this.props);
    const username = this.props.match.params.username;
    const serialNumber = this.props.match.params.serialNumber;
    const handleID = this.props.match.params.handleID;
    const companyName = this.props.match.params.companyName;
    await addOwner(username, serialNumber, companyName);
    // window.location = `/systems/users/${handleID}`;
    this.props.history.push(`/systems/users/${handleID}/${companyName}`);
    // this.props.history.push(`/systems/webSocketComp`);
  }
  render() {
    return null;
  }
}

export default UserAddOwner;
