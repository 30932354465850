import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  Route,
  Router,
  Redirect,
  Switch,
  BrowserRouter,
  Link,
} from "react-router-dom";
//import { BrowserRouter as Router, Link } from "react-router-dom";
import { getCompanySystems } from "../services/companyService";
import { getUser } from "../services/userService";
import {
  getSystems,
  getSystemIDfromHandle,
  getSystem,
} from "../services/systemService";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setJwt } from "../services/httpService";
import { masterCompany } from "../config.json";
import Geocoder from "react-native-geocoding";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";

const mapStyles = {
  width: "100%",
  height: "90%",
};
let mapCenter = { lat: 38.891565, lng: -121.293 };

export class SimplMap extends Component {
  state = {
    showingInfoWindow: false, //Hides or the shows the infoWindow
    activeMarker: {}, //Shows the active marker upon click
    selectedPlace: {}, //Shows the infoWindow to the selected place upon a marker
    mapCenter: {},
    systemAddresses: [],
    systemPosition: [],
    arrayLength: 0,
    isActive: true,
  };

  async componentDidMount() {
    Geocoder.init("AIzaSyDQp-tg4N9bURo6KjB4q_5tE8ekZSZ03ms");
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    console.log("User JWT", jwt);
    const userCompany = (await getUser()).data.data.company;
    this.setState({ userCompany });
    const companySystems = (await getCompanySystems(userCompany)).data.data;
    const activeCompanySystems = companySystems.filter(
      (thisSystem) => thisSystem.active === this.state.isActive
    );
    console.log("Active Systems", activeCompanySystems);
    const systemAddresses = activeCompanySystems.map((system) =>
      system.systemAddress.street.concat(
        " ",
        system.systemAddress.city,
        " ",
        system.systemAddress.state,
        " ",
        system.systemAddress.zip,
        " ",
        system.systemAddress.country
      )
    );
    console.log("System Addresses", systemAddresses);

    let i = 0;
    let latMin = 180;
    let latMax = -180;
    let lngMin = 180;
    let lngMax = -180;

    let systemPos = [];
    let position = {};
    for (i = 0; i < systemAddresses.length; i++) {
      await Geocoder.from(systemAddresses[i]).then((json) => {
        position = json.results[0].geometry.location;
        if (position.lat < latMin) latMin = position.lat;
        else if (position.lat > latMax) latMax = position.lat;
        if (position.lng < lngMin) lngMin = position.lng;
        else if (position.lng > lngMax) lngMax = position.lng;

        systemPos.push({
          position: position,
          address: systemAddresses[i],
          name: activeCompanySystems[i].systemID.name,
          handleID: activeCompanySystems[i].systemID.id,
          systemType: activeCompanySystems[i].systemType,
          SN: activeCompanySystems[i].systemID.SN,
        });

        this.setState({
          systemPosition: systemPos,
          arrayLength: systemAddresses.length,
        });
      });
    }
    latMax += 0.5;
    console.log("latMax", latMax);
    console.log("latMin", latMin);
    console.log("lngMax", lngMax);
    console.log("lngMin", lngMin);
    let points = [
      { lat: latMin, lng: lngMin },
      { lat: latMin, lng: lngMax },
      { lat: latMax, lng: lngMin },
      { lat: latMax, lng: lngMax },
    ];
    let bounds = new this.props.google.maps.LatLngBounds();
    for (let j = 0; j < points.length; j++) {
      bounds.extend(points[j]);
    }
    this.setState({ systemAddresses, bounds });
  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  onClose = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  handleClick = () => {
    console.log("HERE!");
  };

  onInfoWindowOpen(props, e) {
    ReactDOM.render(
      <BrowserRouter>
        <React.Fragment>
          <p className="basicParagraphMap">
            {this.state.selectedPlace.systemName}
          </p>
          <p className="basicParagraphMap">
            {this.state.selectedPlace.address}
          </p>
          <p className="basicParagraphMap">
            {this.state.selectedPlace.systemSN}
          </p>
          <button
            className="btn btn-outline-light"
            onClick={(e) => {
              localStorage.setItem("systemMapButton", "users");
              localStorage.setItem(
                "HandleId",
                this.state.selectedPlace.handleID
              );
              window.location = "/";
              //window.location = `/systems/users/${this.state.selectedPlace.handleID}`;
            }}
          >
            <FontAwesomeIcon
              icon="user-friends"
              size="sm"
              color="Tomato"
              title="users"
            />
          </button>
          <button
            className="btn btn-outline-light"
            onClick={(e) => {
              localStorage.setItem("systemMapButton", "settings");
              localStorage.setItem(
                "HandleId",
                this.state.selectedPlace.handleID
              );
              localStorage.setItem("userCompany", this.state.userCompany);
              localStorage.setItem(
                "systemSN",
                this.state.selectedPlace.systemSN
              );
              localStorage.setItem(
                "systemName",
                this.state.selectedPlace.systemName
              );
              window.location = "/";
              //window.location = `/systems/settings/${this.state.selectedPlace.handleID}/${this.state.userCompany}/${this.state.selectedPlace.systemSN}/${this.state.selectedPlace.systemName}/companyAdmin`;
            }}
          >
            <FontAwesomeIcon
              icon="cog"
              size="sm"
              color="magenta"
              title="Settings"
            />
          </button>
          <button
            className="btn btn-outline-light"
            onClick={(e) => {
              localStorage.setItem("systemMapButton", "dashboard");
              localStorage.setItem(
                "HandleId",
                this.state.selectedPlace.handleID
              );
              localStorage.setItem("userCompany", this.state.userCompany);
              window.location = "/";
              //window.location = `/systems/dashboard/${this.state.selectedPlace.handleID}/${this.state.userCompany}/companyAdmin`;
            }}
          >
            <FontAwesomeIcon
              icon="tachometer-alt"
              size="sm"
              color="green"
              title="monitor"
            />
          </button>
        </React.Fragment>
      </BrowserRouter>,
      document.getElementById("iwc")
    );
  }

  render() {
    let arrayLength = this.state.systemPosition.length;
    return (
      <div style={{ zIndex: 1 }}>
        {arrayLength !== this.state.arrayLength && (
          <div
            style={{
              width: "350",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              marginTop: "150px",
              marginBottom: "150px",
            }}
          >
            <div class="d-flex justify-content-center">
              <div class="spinner-border text-primary m-2" role="status"></div>
            </div>
          </div>
        )}
        {arrayLength === this.state.arrayLength && arrayLength > 0 && (
          <Map
            google={this.props.google}
            zoom={3}
            style={mapStyles}
            initialCenter={this.state.systemPosition[0].position}
            bounds={this.state.bounds}
          >
            {this.state.systemPosition.map((system) => (
              <Marker
                onClick={this.onMarkerClick}
                position={system.position}
                address={system.address}
                id={system.id}
                systemName={system.name}
                handleID={system.handleID}
                systemType={system.systemType}
                systemSN={system.SN}
              />
            ))}
            <InfoWindow
              marker={this.state.activeMarker}
              visible={this.state.showingInfoWindow}
              onClose={this.onClose}
              onOpen={(e) => {
                this.onInfoWindowOpen(this.props, e);
              }}
            >
              <div id="iwc" />
            </InfoWindow>
          </Map>
        )}
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyDQp-tg4N9bURo6KjB4q_5tE8ekZSZ03ms",
})(SimplMap);

{
  /*<p
style={{
  width: "350",
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
  marginTop: "150px",
  marginBottom: "150px",
}}
>
Loading Map...
</p>*/
}
