import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ApplianceModalSmall(props) {
  let topPosition = props.topPosition;
  let leftPosition = props.leftPosition;

  topPosition += 95;
  leftPosition -= 125;

  let startTime = "";
  let endTime = "";
  let tempLo = 0;
  let tempHi = 0;
  let temp = 0;
  let onTime = 0;
  let rh = 0;
  let rth = 0;
  let lux = 0;
  let ratedPower = 0;
  let dataAge = 0;
  let shedStatus = 0;
  let sheddable = 0;
  let doorOpenCount = 0;

  let loadType = "";

  loadType = props.applianceInfo[props.applianceIndex].loadType;
  startTime = props.applianceInfo[props.applianceIndex].startTime;
  endTime = props.applianceInfo[props.applianceIndex].endTime;
  onTime = props.applianceInfo[props.applianceIndex].onTime;
  tempLo = props.applianceInfo[props.applianceIndex].tempLo;
  tempHi = props.applianceInfo[props.applianceIndex].tempHi;
  temp = (
    (props.applianceInfo[props.applianceIndex].tempReal / 100) * 1.8 +
    32
  ).toFixed(1);
  rh = (props.applianceInfo[props.applianceIndex].rhReal / 100).toFixed(1);
  rth = props.applianceInfo[props.applianceIndex].rthReal / 100;
  lux = props.applianceInfo[props.applianceIndex].luxReal;
  ratedPower = props.applianceInfo[props.applianceIndex].powerRating;
  dataAge = props.applianceInfo[props.applianceIndex].sensorDataAge;
  shedStatus = props.applianceInfo[props.applianceIndex].shedStatus;
  sheddable = props.applianceInfo[props.applianceIndex].sheddable;
  doorOpenCount = props.applianceInfo[props.applianceIndex].doorOpenCount;

  return (
    <Modal
      style={{
        borderWidth: "0px",
        width: "220px",
        height: "300px",
        alignContent: "center",
        position: "fixed",
        top: "30%",
        left: "50%",
        transform: "translate(-25%, 0%)",
        // top: `${topPosition}px`,
        // left: `${leftPosition}px`,
      }}
      backdrop={false}
      show={props.show}
    >
      <Modal.Body>
        <button
          className="btn btn-outline-light"
          style={{
            backgroundColor: "red",
            borderColor: "none",
            borderWidth: "0px",
            outline: "none",
            opacity: "1.0",
            width: "25px",
            height: "25px",
            borderRadius: "3px",
            marginBottom: "5px",
          }}
          onClick={props.handleOverRide}
        >
          <p
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "12px",
              textAlign: "center",
              margin: "0px",
              padding: "0px",
              display: "table-cell",
              verticalAlign: "middle",
              width: "10px",
              height: "10px",
              transform: "translate(-4px, -3px)",
            }}
          >
            O
          </p>
        </button>
        <button
          className="btn btn-outline-light"
          style={{
            backgroundColor: "green",
            borderColor: "none",
            borderWidth: "0px",
            outline: "none",
            opacity: "1.0",
            width: "25px",
            height: "25px",
            borderRadius: "3px",
            marginBottom: "5px",
            transform: "translate(120px, 0px)",
          }}
        >
          <p
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "12px",
              textAlign: "center",
              margin: "0px",
              padding: "0px",
              display: "table-cell",
              verticalAlign: "middle",
              width: "10px",
              height: "10px",
              transform: "translate(-4px, -3px)",
            }}
          >
            X
          </p>
        </button>
        {(loadType === "HVAC Unit" ||
          loadType === "Space Heater" ||
          loadType === "Pole Lights" ||
          loadType === "Outdoor Sensed Lights" ||
          loadType === "Relay Driver" ||
          loadType === "Relay Driver w Light Sensor" ||
          loadType === "Quad 30A Relays" ||
          loadType === "Quad 30A w Light Sensor" ||
          loadType === "Indoor Lights" ||
          loadType === "Heat Lamp" ||
          loadType === "Water Heater" ||
          loadType === "Water Heater No Sensor" ||
          loadType === "Walk-in" ||
          loadType === "Reach-in" ||
          loadType === "Counter Reach-in" ||
          loadType === "Anti-sweat Heaters" ||
          loadType === "EV Charger" ||
          loadType === "Oven" ||
          loadType === "Hood" ||
          loadType === "Misc. Appliance" ||
          loadType === "Misc. Sheddable" ||
          loadType === "Ambient Temp Sensor" ||
          loadType === "External Temp Sensor" ||
          loadType === "Light Sensor" ||
          loadType === "Door Monitor" ||
          loadType === "Perimeter Outline" ||
          loadType === "Compass Indicator" ||
          loadType === "System Gateway") && (
          <div>
            <p className="basicParagraph" style={{ fontSize: "9px" }}>
              Name: {props.nodeInfo.nodeName}
              <p>Type: {props.nodeInfo.loadType}</p>
            </p>
          </div>
        )}

        {(loadType === "HVAC Unit" ||
          loadType === "Space Heater" ||
          loadType === "Ambient Temp Sensor") && (
          <div>
            <p className="basicParagraph" style={{ fontSize: "9px" }}>
              Temperature: {temp} *F | RH: {rh}%
            </p>
          </div>
        )}
        {(loadType === "Water Heater" ||
          loadType === "Walk-in" ||
          loadType === "Reach-in" ||
          loadType === "Counter Reach-in" ||
          loadType === "External Temp Sensor") && (
          <div>
            <p className="basicParagraph" style={{ fontSize: "9px" }}>
              Temperature: {rth} *F
            </p>
          </div>
        )}

        {(loadType === "HVAC Unit" ||
          loadType === "Space Heater" ||
          loadType === "Pole Lights" ||
          loadType === "Outdoor Sensed Lights" ||
          loadType === "Relay Driver" ||
          loadType === "Relay Driver w Light Sensor" ||
          loadType === "Quad 30A Relays" ||
          loadType === "Quad 30A w Light Sensor" ||
          loadType === "Indoor Lights" ||
          loadType === "Heat Lamp" ||
          loadType === "Water Heater" ||
          loadType === "Water Heater No Sensor" ||
          loadType === "Walk-in" ||
          loadType === "Reach-in" ||
          loadType === "Counter Reach-in" ||
          loadType === "Anti-sweat Heaters" ||
          loadType === "EV Charger" ||
          loadType === "Oven" ||
          loadType === "Hood" ||
          loadType === "Misc. Appliance" ||
          loadType === "Misc. Sheddable") && (
          <div>
            <p className="basicParagraph" style={{ fontSize: "9px" }}>
              Qualified to shed: {sheddable}
              <p>Shed Status: {shedStatus}</p>
            </p>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
export default ApplianceModalSmall;
