import React, { Component } from "react";
import * as echarts from "echarts/dist/echarts.js";
import "bootstrap/dist/css/bootstrap.css";
import "./dashboard.css";
import {
  getSystemRealTimeData,
  getSystemLatestData,
  getSystemBasics,
  ws_getSystemLatestData,
  getSystemInfo,
} from "../services/systemService";

import { webSocketMode } from "../config.json"; //0: http; 1:websocket

class ChartWalkIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphLen: 0,
      base: new Date(),
      oneDay: 86400000,
      date: [],
      value1: [0],
      value2: [0],
      value3: [0],
      value4: [0],
      now: new Date(),
    };
  }

  async componentDidMount() {
    this.chart = echarts.init(document.getElementById("walkin-chart"));
    const tickNumber = 1000;
    this.timerID = setInterval(() => this.tick(), tickNumber);

    const thisTime = this.props.dataDate;
    console.log("This Time: ", thisTime);
    const dateLength = thisTime.length;
    let thisHumanTime = [];
    let thisHumanDate = 0;
    let d = 0;
    let timeZone = this.props.timeZone;
    for (let i = 0; i < dateLength; i++) {
      d = new Date((thisTime[i] - timeZone * 3600) * 1000);
      thisHumanDate = thisHumanTime[i] = [
        d.getMonth() + 1,
        d.getDate(),
        d.getFullYear(),
      ].join("/");
      thisHumanTime[i] = [d.getHours(), d.getMinutes()].join(":");
      thisHumanTime[i] = [thisHumanDate, thisHumanTime[i]].join(" @ ");
    }
    console.log("This Human Time: ", thisHumanTime);
    this.setState({ thisHumanTime });
    this.generateGraph();
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.chart.setOption({
      yAxis: {
        max: this.props.maxTemp + 5,
        min: this.props.minTemp - 5,
      },
      xAxis: {
        data: this.state.thisHumanTime,
        axisTick: { show: true },
        splitLine: { show: true },
      },
      series: [
        {
          name: "Temperature (F)",
          data: this.props.avgRthReal,
        },
        {
          name: "Outside T (F)",
          data: this.props.avgOutsideT,
        },

        // {
        //   name: "onTime",
        //   data: this.props.onTime,
        // },
      ],
    });
  }

  generateGraph() {
    var option = {
      legend: {
        type: "scroll",
        data: [
          "Temperature (F)",
          "Outside T (F)",
          /* "onTime",*/
        ],
        center: "50%",
        top: "0%",
        orient: "horizontal",
        align: "auto",
        padding: 3,
        textStyle: {
          fontStyle:
            "normal" /*'Lato,"Helvetica Neue",Arial,Helvetica,sans-serif'*/,
          fontSize: 10,
          fontWeight: "bold",
          color: "grey",
        },
        backgroundColor: "transparent",
        borderColor: "#ffffff",
        borderWidth: 1,
        boarderRadius: 0,
      },
      xAxis: {
        name: "",
        nameLocation: "end",
        type: "category",
        nameTextStyle: {
          fontStyle:
            "normal" /*'Lato,"Helvetica Neue",Arial,Helvetica,sans-serif'*/,
          fontSize: 16,
          fontWeight: "bolder",
          color: "green",
        },
        axisLabel: {
          fontStyle: "normal",
          fontSize: 8,
          fontWeight: "normal",
          color: "orange",
        },
        boundaryGap: false,
        data: this.state.thisHumanTime,
      },
      yAxis: [
        {
          show: false, //true,
          name: "Power (Watts)",
          nameLocation: "end",
          boundaryGap: [0, "10%"],
          type: "value",
          nameTextStyle: {
            fontStyle: "normal",
            fontSize: 16,
            fontWeight: "bolder",
            color: "green",
          },
          axisLabel: {
            fontStyle: "normal",
            fontSize: 10,
            fontWeight: "bold",
            color: "green",
          },
        },
        {
          show: false, //true,
          name: "Priority Level",
          position: "right",
          nameLocation: "end",
          splitLine: {
            show: false,
          },
          //boundaryGap: [0, "10%"],
          type: "value",
          nameTextStyle: {
            fontStyle: "normal",
            fontSize: 16,
            fontWeight: "bolder",
            color: "orange",
          },
          axisLabel: {
            fontStyle: "normal",
            fontSize: 10,
            fontWeight: "bold",
            color: "orange",
          },
        },
      ],
      dataZoom: [
        {
          type: "slider",
          show: true,
          xAxisIndex: [0],
          start: 1,
          end: 100,
        },
        {
          type: "inside",
          xAxisIndex: [0],
          start: 1,
          end: 100,
        },
      ],
      series: [
        {
          name: "Temperature (F)",
          type: "line",
          symbol: "none",
          color: "blue",
          smooth: 0.25,
          data: this.props.avgRthReal,
        },
        {
          name: "Outside T (F)",
          type: "line",
          symbol: "none",
          color: "green",
          smooth: 0.25,
          data: this.props.avgOutsideT,
        },

        // {
        //   name: "onTime",
        //   type: "line",
        //   step: "middle",
        //   yAxisIndex: 1,
        //   symbol: "none",
        //   color: "none",
        //   areaStyle: {
        //     normal: {
        //       color: "grey",
        //       opacity: "0.25",
        //     },
        //   },
        //   label: {
        //     show: true,
        //     position: "top",
        //   },
        //   data: this.props.onTime,
        // },
      ],
      tooltip: {
        trigger: "axis",
        hideDelay: 10000,
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
            precision: "1",
          },
        },
      },

      axisPointer: {
        link: { xAxisIndex: "all", yAxisName: "Return T (F)" },
        label: {
          backgroundColor: "#777",
        },
      },
      /* tooltip: {
        trigger: "axis",
        axisPointer: {
          animation: false,
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
            precision: "2",
          },
        },
      },*/
    };

    this.chart.setOption(option);
  }

  render() {
    return <div id="walkin-chart" />;
  }
}
export default ChartWalkIn;
