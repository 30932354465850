import React from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import Form from "./common/form";
import {
  registerUser,
  loginUser,
  getUser,
  unregisterUser,
  restoreUser,
} from "../services/userService";
import { setJwt } from "../services/httpService";
import emailjs from "emailjs-com";
import BackGroundRectangle from "../components/backgroundRect";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class RegisterForm extends Form {
  state = {
    data: {
      firstName: "",
      lastName: "",
      emailAddress: "",
      username: "",
      password: "",
      phoneNumber: "",
      reasonRegister: "1",
      adminEmail: "",
      companyName: "",
    },
    diyRegistered: false,
    errors: {},
    shownPass: false
  };

  schema = {
    firstName: Joi.string().required().label("First Name"),
    lastName: Joi.string().required().label("Last Name"),
    emailAddress: Joi.string().required().email().label("email Address"),
    username: Joi.string().required().label("Username"),
    password: Joi.string().required().min(8).label("Password"),
    phoneNumber: Joi.string().required().label("Phone Number"),
    adminEmail: Joi.string().email().label("email Address"),
    companyName: Joi.string().label("Company Name"),
  };
  handleSubmitlogin = async () => {
    const { data } = this.state;
    const logindata = await loginUser(data.username, data.password);
    const token = logindata.data.token;
    localStorage.setItem("token", token);
    window.location = "/";
  };

  hideOrShowPass = (event) => {
    event.preventDefault();
    const element = document.getElementById("password");

    if (this.state.shownPass){
      this.setState({shownPass: false})
      element.type = "password"
    } else {
      this.setState({shownPass: true})
      element.type = "text"
    }
  }

  doSubmit = async () => {
    try {
      const { data } = this.state;
      await registerUser(
        data.username,
        data.password,
        data.firstName,
        data.lastName,
        data.emailAddress,
        data.phoneNumber
      );
      let verificationSent = false;
      let diyRegistered = false;
      if (this.state.registerReason === "reason2") diyRegistered = true;
      this.setState({ diyRegistered });

      console.log("User in Reg form", this.props);
      console.log("Username", data.username);
      console.log("Password", data.password);
      const logindata = await loginUser(data.username, data.password);
      const token = logindata.data.token;
      localStorage.setItem("token", token);
      //////////////////////////////////////////////////////////////////////
      var templateParams = {
        from_name: "Simpl Global Registration",
        to_name: this.state.data.username,
        reply_to: "No-replyWhoever",
        email_address: this.state.data.emailAddress,
        message: "Thank you for registering!",
        simplweb_link: "https://share.hsforms.com/1OZFZr7j2R26jidzPo212pA2yrd2",
        bcc_Simpl: "info@simplglobal.com",
      };
      const serviceID = "default_service"; //'service_yxkpiig'
      const templateID = "template_fxv46c8";
      const userID = "user_DDkUASlekeJF72JmRf3LQ";
      emailjs.init(userID);
      const sentEmail = await emailjs.send(
        serviceID,
        templateID,
        templateParams
      );
      if (sentEmail.status === 200)
        toast.success("Verification email sent to your email address.");
      else toast.error("Something went wrong...");
      console.log("Sent email:", sentEmail.status);
      verificationSent = true;
      this.setState({ verificationSent });
      /////////////////////////////////////////////////////////////////////
    } catch (ex) {
      if (ex.response && ex.response.status === 400)
        toast.error("Email address or Username already exists.");
    }
  };

  handleChangeRadio = (e) => {
    const { name, value } = e.target;
    // console.log("e.target.name", e.target.name);
    //console.log("e.target.value", e.target.value);
    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1,
          }}
        >
          {/*<p
            className="basicParagraph"
            style={{
              width: "350px",
              fontSize: "14px",
              color: "dodgerblue",
              textAlign: "left",
            }}
          >
            Which of the following best fits you:
          </p>

          <fieldset className="form-group">
            <div className="row">
              <div className="col-sm-10">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="registerReason"
                    id="registerReason1"
                    onChange={this.handleChangeRadio}
                    value="reason1"
                    // checked
                  ></input>
                  <label
                    className="basicParagraph"
                    style={{
                      fontSize: "10px",
                      width: "300px",
                      paddingLeft: "15px",
                      paddingTop: "3px",
                    }}
                  >
                    I'd like to view the demo system and use the design tools.
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="registerReason"
                    id="registerReason2"
                    onChange={this.handleChangeRadio}
                    value="reason2"
                  ></input>
                  <label
                    className="basicParagraph"
                    style={{
                      fontSize: "10px",
                      width: "300px",
                      paddingLeft: "15px",
                      paddingTop: "3px",
                    }}
                  >
                    DIY project: I am installing my own system.
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="registerReason"
                    id="registerReason3"
                    onChange={this.handleChangeRadio}
                    value="reason3"
                  ></input>
                  <label
                    className="basicParagraph"
                    style={{
                      fontSize: "10px",
                      width: "300px",
                      paddingLeft: "15px",
                      paddingTop: "3px",
                    }}
                  >
                    Installation company has installed the system and I would
                    like to register as a user for the system. Important Note:
                    You will be asked to provide the email address for the
                    installation company's ADMIN.
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="registerReason"
                    id="registerReason4"
                    onChange={this.handleChangeRadio}
                    value="reason4"
                  ></input>
                  <label
                    className="basicParagraph"
                    style={{
                      fontSize: "10px",
                      width: "300px",
                      paddingLeft: "15px",
                      paddingTop: "3px",
                    }}
                  >
                    I'd like to register my installation company with Simpl
                    Global and register to become the ADMIN. Important Note: You
                    will be asked to provide the exact name for your
                    installation company.
                  </label>
                </div>
              </div>
            </div>
                  </fieldset>*/}

          {/* {this.state.registerReason && (*/}
          <div>
            <form onSubmit={this.handleSubmit}>
              {this.renderInput("firstName", "First Name")}
              {this.renderInput("lastName", "Last Name")}
              {this.renderInput("emailAddress", "email Address")}
              {this.renderInput("username", "Username")}
              <div className="d-flex justify-content-end">
                <button
                  className="position-absolute mr-1 mt-1" style={{
                    padding: "0px",
                    borderWidth: "0px",
                    color: "transparent",
                    backgroundColor: "transparent",
                    border: "none",
                    outline: "none"
                  }}
                  onClick={event=>this.hideOrShowPass(event)}
                >
                <FontAwesomeIcon
                  className="pr-1"
                  icon={this.state.shownPass ? faEye : faEyeSlash}
                  size="lg"
                  color="#2d5c88"
                  title={this.state.shownPass ? "Hide Password" : "Show Password"}
                />
                </button>
              </div>
              {this.renderInput("password", "Password", "password")}
              {this.renderInput("phoneNumber", "PhoneNumber")}

              {/*  {this.state.registerReason === "reason3" && (
                  <div>
                    <hr></hr>
                    {this.renderInput(
                      "adminEmail",
                      "Please provide the email address for the installation company's ADMIN:"
                    )}
                  </div>
                )}
                {this.state.registerReason === "reason4" && (
                  <div>
                    <hr></hr>
                    {this.renderInput(
                      "companyName",
                      "Please provide the exact name for your installation company:"
                    )}
                  </div>
                    )}*/}
              {this.renderButton("Register")}
            </form>
            {/* {this.state.registerReason === "reason2" &&
                this.state.diyRegistered && (
                  <div>
                    <p className="basicParagraph">
                      {this.state.data.firstName}'s Solar Company was
                      automatically registered by Simpl Global for you.
                    </p>
                    <p className="basicParagraph">
                      {this.state.data.username} (your username) is the ADMIN.
                    </p>
                    <p className="basicParagraph">
                      Please be cautious. Changing system settings as an ADMIN
                      could dramatically change the behavior of the system.
                    </p>
                  </div>
                )}*/}
          </div>
          {this.state.verificationSent && (
            <div
              className="basicParagraph"
              style={{ marginTop: "30px", textAlign: "center" }}
            >
              <p style={{ fontSize: "15px", fontWeight: "600" }}>
                Please follow these three steps:
              </p>
              <p style={{ fontSize: "12px", fontWeight: "600" }}>
                Check your email and click on the verification link.
              </p>
              <p style={{ fontSize: "12px", fontWeight: "600" }}>
                Enter the required information.
              </p>
              <p style={{ fontSize: "12px", fontWeight: "600" }}>
                Then log into the system using your new credentials.
              </p>
              <form onSubmit={this.handleSubmitlogin}>
                {this.renderButton("Login")}
              </form>
            </div>
          )}
          {/* )}*/}
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default RegisterForm;
