import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getAllUsers } from "../services/userService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setJwt } from "../services/httpService";
import BackGroundRectangle from "../components/backgroundRect";

class Users extends Component {
  state = {
    userId: "",
    users: []
  };

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    const users = await getAllUsers();
    this.setState({ users: users.data.data });
  }

  render() {
    const userCount = this.state.users.length;
    if (userCount === 0)
      return (
        <React.Fragment>
          <div
            style={{
              width: "350",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              marginTop: "150px",
              marginBottom: "150px",
              zIndex: 1
            }}
          >
            <p> There are no users in the database.</p>
            <Link
              to="/users/new"
              className="btn btn-outline-info btn-sm"
              style={{ marginBottom: 20 }}
            >
              New User
            </Link>
          </div>
          <BackGroundRectangle />
        </React.Fragment>
      );

    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1
          }}
        >
          <p />
          <Link
            to="/users/updateCompany"
            className="btn btn-outline-info btn-sm"
            style={{ marginBottom: 20 }}
          >
            Add User to Company
          </Link>
          <table className="table">
            <thead>
              <tr>
                <th>Username</th>
                <th>Name</th>
                <th>Company</th>
                <th>email</th>
                <th>Admin</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {this.state.users.map(user => (
                <tr key={user.username}>
                  <td>{user.username}</td>
                  <td>
                    {user.firstName} {user.lastName}
                  </td>
                  <td>{user.company}</td>
                  <td>{user.emailAddress}</td>
                  <td>{user.admin && "Yes"}</td>
                  <td>
                    <Link to={`/users/monitor/${user.user_id}`}>
                      <FontAwesomeIcon
                        icon="tachometer-alt"
                        size="lg"
                        color="green"
                      />
                    </Link>
                  </td>
                  <td>
                    <Link to={`/users/basics/${user.user_id}`}>
                      <FontAwesomeIcon icon="edit" size="lg" color="brown" />
                    </Link>
                  </td>
                  <td>
                    <Link to={`/users/controls/${user.user_id}`}>
                      <FontAwesomeIcon
                        icon="sliders-h"
                        size="lg"
                        color="magenta"
                      />
                    </Link>
                  </td>
                  <td>
                    <Link to={`/users/limits/${user.user_id}`}>
                      <FontAwesomeIcon
                        icon="arrows-alt-v"
                        size="lg"
                        color="red"
                      />
                    </Link>
                  </td>
                  <td>
                    <Link to={`/users/user/${user.user_id}`}>
                      <FontAwesomeIcon
                        icon="user-friends"
                        size="lg"
                        color="Tomato"
                      />
                    </Link>
                  </td>
                  <td>
                    <Link to={`/users/location/${user.user_id}`}>
                      <FontAwesomeIcon
                        icon="map-marker-alt"
                        size="lg"
                        color="dodgerblue"
                      />
                    </Link>
                  </td>
                  <td>
                    <Link to={`/users/delete/${user.user_id}`}>
                      <FontAwesomeIcon
                        icon="trash-alt"
                        size="lg"
                        color="grey"
                      />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default Users;
