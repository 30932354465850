import React from "react";
import { toast } from "react-toastify";
import { getUserByEmail, recoverCredentialsByEmail } from "../services/userService";
///////////////////////////
import { getCompanies, getCompanyUsers } from "../services/companyService";
import { setJwt } from "../services/httpService";
import { getUser } from "../services/userService";
/////////////////////////////

import { Link } from "react-router-dom";
import Joi, { log } from "joi-browser";
import Form from "./common/form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  masterCompany,
  footerLogoSource,
  manufacturingCompany,
} from "../config.json";
import emailjs from "emailjs-com";
import BackGroundRectangle from "./backgroundRect";


class ResetCredentialsByEmail extends Form {
  state = {
    data: { email: "" },
    errors: {}
  };

  schema = {
    email: Joi.string().required().email().label("Email"),
  };

  doSubmit = async (e) => {
    try {
      const { data } = this.state;
      const recoverPassword = await recoverCredentialsByEmail(data.email)
      console.log(recoverPassword);
      let verificationSent = false
      
      if (recoverPassword.status == 200){
        verificationSent = true;
        this.setState({ verificationSent });
        toast.success("Success Request")
      } else {
        toast.error("Something Went Wrong")
      }

    } catch (ex) {
      if(ex.response && ex.response.status){
        toast.error("User not found")
      }
    }
  };


  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "400px",
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1,
          }}
        >
          <div>
            <p style={{
              margin: "0px 0px 8px",
              textDecoration: "none",
              font: "11px arial",
              fontWeight: "bold",
              marginBottom: "10px"
            }}>
              Please provide a valid email to recover your password
            </p>
            <form style={{
              margin: "15px 0px 15px 0px"
            }} 
            onSubmit={this.handleSubmit}>
            
            {this.renderInput("email", "Email")}
             
             <div style={{
              display: "flex",
              flexFlow: "column wrap",
              alignItems: "center",
              marginTop: "10px"
             }}>
              {this.renderButton("Send")}
              <Link
              to="/login"
              className="btn btn-outline-warning btn-sm"
              style={{ marginTop: 10, width: "220px" }}
              >
                Back to login
              </Link>
             </div>
            </form>

          </div>

          {this.state.verificationSent && (
            <div
              className="basicParagraph"
              style={{ marginTop: "30px", textAlign: "center" }}
            >
              <p style={{ fontSize: "15px", fontWeight: "600" }}>
                Your new credentials have been sent to your email
              </p>
              <p style={{ fontSize: "12px", fontWeight: "600" }}>
                Check your inbox.
              </p>
              <p style={{ fontSize: "12px", fontWeight: "600" }}>
                Then log into the system using your new credentials.
              </p>
              <form onSubmit={this.handleSubmitlogin}>
                {this.renderButton("Login")}
              </form>
            </div>
          )}
          
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default ResetCredentialsByEmail;
