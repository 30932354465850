import React from "react";
import Form from "./common/form";
import { removeCompany } from "../services/companyService";
import BackGroundRectangle from "../components/backgroundRect";

class CompanyDeleteForm extends Form {
  state = {
    errors: {}
  };

  handleDelete = async e => {
    e.preventDefault();
    await removeCompany(this.props.match.params.id);
    this.props.history.push("/companies");
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1
          }}
        >
          <p className="basicParagraph">
            You are about to delete {this.props.match.params.id}.
          </p>
          <p className="basicParagraph">Please confirm:</p>
          <button className="btn btn-danger btn-sm" onClick={this.handleDelete}>
            Confirm
          </button>
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default CompanyDeleteForm;
