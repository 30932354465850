import React, { Component } from "react";

import * as echarts from "echarts/dist/echarts.js";
import "./dashboard.css";

class BillsPieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base: new Date(),
      oneDay: 24 * 3600 * 1000,
      date: [],
      pvData: [],
      batteryData: [],
      renewableData: [],
      now: new Date()
    };
  }

  componentDidMount() {
    this.chart = echarts.init(document.getElementById("bills-pie-chart"));
    this.generateGraph();
  }

  componentWillUnmount() {}

  generateGraph() {
    var option = {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)"
      },
      // legend: {
      //     orient: 'horizontal',
      //     bottom: '0%',
      //     data:['On-Peak kW','Mid-Peak kW','Off-Peak kW','On-Peak kWh','Mid-Peak kWh','Off-Peak kWh', 'Meter Cost', 'Taxes']
      // },
      series: [
        {
          name: "Utility Costs",
          type: "pie",
          label: false,
          selectedMode: "single",
          radius: [0, "30%"],
          center: ["50%", "43%"],
          data: [
            {
              value: 98.6,
              name: "Demand",
              itemStyle: { color: "CornflowerBlue" }
            },
            {
              value: 159.93,
              name: "Energy",
              itemStyle: { color: "DarkOrange" }
            },
            { value: 29.48, name: "Fixed", itemStyle: { color: "DimGrey" } }
          ]
        },
        {
          name: "Detail",
          type: "pie",
          radius: ["38%", "55%"],
          center: ["50%", "43%"],
          data: [
            {
              value: 54,
              name: "On-Peak kW",
              itemStyle: { color: "DodgerBlue" }
            },
            {
              value: 31,
              name: "Mid-Peak kW",
              itemStyle: { color: "CornflowerBlue" }
            },
            {
              value: 13.6,
              name: "Off-Peak kW",
              itemStyle: { color: "RoyalBlue" }
            },
            {
              value: 15.12,
              name: "On-Peak kWh",
              itemStyle: { color: "Orange" }
            },
            {
              value: 39.36,
              name: "Mid-Peak kWh",
              itemStyle: { color: "DarkOrange" }
            },
            {
              value: 105.45,
              name: "Off-Peak kWh",
              itemStyle: { color: "OrangeRed" }
            },
            { value: 10.12, name: "Meter Cost", itemStyle: { color: "Grey" } },
            { value: 19.36, name: "Taxes", itemStyle: { color: "DimGrey" } }
          ]
        }
      ]
    };

    // use configuration item and data specified to show chart
    this.chart.setOption(option);
  }

  render() {
    return <div id="bills-pie-chart" />;
  }
}
export default BillsPieChart;
