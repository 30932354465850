import React from "react";

const ApplianceGeneralSvg = (props) => (
  <svg
    style={{ margin: "0px", padding: "0px", height: "90px", width: "200px" }}
  >
    <svg
      height="200px"
      width="auto"
      id="ICONS"
      version="1.1"
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <g>
        <text
          x="6"
          y="10"
          font-family="Verdana"
          font-size="6"
          font-weight="normal"
          fill={props.textColor}
        >
          GP Kitchen
        </text>
      </g>
    </svg>

    <svg
      height="85px"
      version="1.1"
      viewBox="0 0 85 85"
      width="85px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(0,4)">
        <g transform="scale(0.8,0.8)">
          <line
            fill="none"
            stroke={props.strokeColor1}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="2.5"
            x1="15.857"
            x2="20.363"
            y1="67.082"
            y2="67.082"
          />
          <line
            fill="none"
            stroke={props.strokeColor1}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="2.5"
            x1="64.639"
            x2="69.143"
            y1="67.082"
            y2="67.082"
          />
          <path
            d="M7.269,30.415v25.327  c0,3.118,2.552,5.67,5.669,5.67h59.125c3.117,0,5.668-2.552,5.668-5.67V23.587c0-3.118-2.551-5.669-5.668-5.669H12.938  c-3.117,0-5.669,2.551-5.669,5.669v1.953h45.966"
            fill="none"
            stroke={props.strokeColor1}
            stroke-miterlimit="10"
            stroke-width="2.5"
          />
          <line
            fill="none"
            stroke={props.strokeColor1}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="2.5"
            x1="53.773"
            x2="53.773"
            y1="60.451"
            y2="18.42"
          />
          <line
            fill="none"
            stroke={props.strokeColor1}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="2.5"
            x1="7.547"
            x2="53.484"
            y1="54.215"
            y2="54.215"
          />
          <path
            d="  M53.148,46.426"
            fill="none"
            stroke={props.strokeColor1}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="2.5"
          />
          <path
            d="  M53.086,32.662"
            fill="none"
            stroke={props.strokeColor1}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="2.5"
          />
          <line
            fill="none"
            stroke={props.strokeColor1}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="2.5"
            x1="47.062"
            x2="47.062"
            y1="32.938"
            y2="46.934"
          />
          <g>
            <line
              fill="none"
              stroke={props.strokeColor1}
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2.5"
              x1="58.769"
              x2="64.352"
              y1="37.714"
              y2="37.714"
            />
            <line
              fill="none"
              stroke={props.strokeColor1}
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2.5"
              x1="66.912"
              x2="72.495"
              y1="37.714"
              y2="37.714"
            />
          </g>
          <g>
            <path
              d="M65.632,47.428c1.729,0,3.137,1.407,3.137,3.137c0,1.728-1.407,3.134-3.137,3.134s-3.137-1.406-3.137-3.134   C62.495,48.835,63.902,47.428,65.632,47.428 M65.632,44.928c-3.112,0-5.637,2.524-5.637,5.637c0,3.107,2.524,5.634,5.637,5.634   c3.114,0,5.637-2.526,5.637-5.634C71.269,47.452,68.746,44.928,65.632,44.928L65.632,44.928z"
              fill={props.strokeColor1}
            />
          </g>
          <g>
            <rect
              fill="none"
              height="7.162"
              stroke={props.strokeColor1}
              stroke-miterlimit="10"
              stroke-width="2.5"
              width="11.305"
              x="59.979"
              y="25.152"
            />
          </g>
        </g>
      </g>
    </svg>
  </svg>
);
export default ApplianceGeneralSvg;
