import React from "react";

const WaterheaterSvg = (props) => (
  <svg
    style={{ margin: "0px", padding: "0px", height: "90px", width: "200px" }}
  >
    <svg
      height="200px"
      width="auto"
      id="ICONS"
      version="1.1"
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <g>
        <rect
          x="2"
          y="2"
          width="65"
          height="50"
          fill="none"
          stroke={props.rectColor}
          stroke-width="1"
        ></rect>
        <text
          x="4"
          y="10"
          font-family="Verdana"
          font-size="6"
          font-weight="normal"
          fill={props.textColor}
        >
          {props.name}
        </text>
      </g>
    </svg>
    <svg
      height="200px"
      width="auto"
      id="ICONS"
      version="1.1"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke={props.strokeColor1}
        fill="none"
        stroke-width="2px"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10px"
        transform="translate(0,15)"
      >
        <circle cx="32" cy="42" r="1" fill="none" />
        <circle cx="37" cy="44" r="1" fill="none" />
        <circle cx="27" cy="44" r="1" fill="none" />
        <path d="   M45,54c0,1.104-0.896,2-2,2H21c-1.104,0-2-0.896-2-2V11c0-1.104,0.896-2,2-2h22c1.104,0,2,0.896,2,2V54z" />
        <polyline points="   41,56 41,60 37,60 37,56  " />
        <polyline points="   27,56 27,60 23,60 23,56  " />
        <path d="M42,9   V7c0-1.104-0.896-2-2-2h-8h-8c-1.104,0-2,0.896-2,2v2" />
        <path d="   M23,56V40c0-1.104,0.896-2,2-2h14c1.104,0,2,0.896,2,2v16" />
        <path d="   M35,23c1.916-0.125,2.834-3.542,1-6c0,2.083-2,1.719-2,3c-1.958-1.958,1.063-1.938-2-5c0,3-1.916,2-1.916,5c0,0-1.084-3-3.084-3   c2.063,2.063-1.542,3.458,1,6" />
        <line x1="28" x2="36" y1="27" y2="27" />
        <circle cx="32" cy="49.001" r="3" />
        <line x1="32" x2="32" y1="48" y2="46" />
      </g>
    </svg>
    <svg
      height="auto"
      version="1.1"
      viewBox="0 0 60 60"
      width="300px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title />
      <desc />
      <defs />
      <g
        fill="none"
        fill-rule="evenodd"
        id="Thermometer-Hot"
        stroke="none"
        stroke-width="1"
        transform="translate(-35,15)"
      >
        <g
          id="Group"
          stroke="#979797"
          stroke-width="2"
          transform="translate(7, 2)"
        >
          <path
            d="M3,16.8026932 L3,3 C3,1.34314575 4.34314575,3.04359188e-16 6,0 C7.65685425,-3.04359188e-16 9,1.34314575 9,3 L9,16.8026932 C10.7934041,17.8401214 12,19.7791529 12,22 C12,25.3137085 9.3137085,28 6,28 C2.6862915,28 0,25.3137085 0,22 C0,19.7791529 1.20659589,17.8401214 3,16.8026932 Z"
            id="Combined-Shape"
          />
          <path d="M13,5 L18,5" id="Path-19" stroke-linecap="round" />
          <path d="M13,9 L18,9" id="Path-20" stroke-linecap="round" />
          <path d="M13,13 L18,13" id="Path-21" stroke-linecap="round" />
        </g>
        <g fill="red" id="Group-2" transform="translate(10.000000, 12.000000)">
          <circle cx="3" cy="12" id="Oval" r="3" />
          <rect height="11" id="Rectangle-2" rx="1" width="2" x="2" y="0" />
        </g>
      </g>
    </svg>
  </svg>
);
export default WaterheaterSvg;
