import React from "react";

const YPerimeterSvg = (props) => (
  <svg
    style={{ margin: "0px", padding: "0px", width: "800px", height: "425px" }}
  >
    <svg
      width="1000px"
      height="1000px"
      id="ICONS"
      version="1.1"
      viewBox="0 0 1000 1000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        stroke={props.rectColor}
        stroke-width="1"
        d="M 2 2 L 2 300, 200 300, 200 420, 695 420, 695 300, 795 300, 795 2, 550 2, 550 70, 250 70, 250 2Z"
      />
    </svg>
  </svg>
);
export default YPerimeterSvg;
