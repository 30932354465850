import React from "react";

const HvacSvg = (props) => (
  <svg
    style={{ margin: "0px", padding: "0px", height: "90px", width: "200px" }}
  >
    <svg
      height="200px"
      width="auto"
      id="ICONS"
      version="1.1"
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect
          x="2"
          y="2"
          width="105"
          height="50"
          fill="none"
          stroke={props.rectColor}
          stroke-width="1"
        ></rect>
        <text
          x="4"
          y="10"
          font-family="Verdana"
          font-size="6"
          font-weight="normal"
          fill={props.textColor}
        >
          {props.name}
        </text>
      </g>
      <g
        fill="none"
        fill-rule="evenodd"
        id="Snow"
        stroke="none"
        stroke-width="1"
      >
        <g
          id="Group"
          stroke={props.strokeColor1}
          transform="translate(7.000000, 15.000000)"
        >
          <g>
            <path
              d="M9.28571429,0.928571429 L13,3.71428571 L16.7142857,0.928571429 M25.0714286,9.28571429 L22.2857143,13 L25.0714286,16.7142857 M16.7142857,25.0714286 L13,22.2857143 L9.28571429,25.0714286 M0.928571429,16.7142857 L3.71428571,13 L0.928571429,9.28571429"
              id="Path-12"
              stroke-linecap="round"
              stroke-width="2"
            />
            <circle cx="13" cy="13" fill="#979797" id="Oval-9" r="1.85714286" />
            <path
              d="M13,0 L13,26"
              id="Path-13"
              stroke-linecap="round"
              stroke-width="2"
            />
            <path
              d="M0,13 L26,13"
              id="Path-14"
              stroke-linecap="round"
              stroke-width="2"
            />
            <path
              d="M3.71428571,3.71428571 L22.2857143,22.2857143"
              id="Path-16"
              stroke-linecap="round"
              stroke-width="2"
            />
            <path
              d="M3.71428571,22.2857143 L22.2857143,3.71428571"
              id="Path-17"
              stroke-linecap="round"
              stroke-width="2"
            />
          </g>
        </g>
      </g>
    </svg>
    <svg
      height="auto"
      width="300px"
      id="ICONS"
      version="1.1"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Group"
        fill="red"
        stroke={props.strokeColor2}
        transform="translate(-18.000000, 8.000000)"
      >
        <path
          class="cls-1"
          d="M14,21a1,1,0,0,1-1-1V4a1,1,0,0,1,2,0V20A1,1,0,0,1,14,21Z"
        />
        <path
          class="cls-1"
          d="M10,21a1,1,0,0,1-1-1V4a1,1,0,0,1,2,0V20A1,1,0,0,1,10,21Z"
        />
        <path
          class="cls-1"
          d="M6,21a1,1,0,0,1-1-1V4A1,1,0,0,1,7,4V20A1,1,0,0,1,6,21Z"
        />
        <path
          class="cls-1"
          d="M18,21a1,1,0,0,1-1-1V4a1,1,0,0,1,2,0V20A1,1,0,0,1,18,21Z"
        />
        <path class="cls-1" d="M20,9H18a1,1,0,0,1,0-2h2a1,1,0,0,1,0,2Z" />
        <path class="cls-1" d="M6,9H4A1,1,0,0,1,4,7H6A1,1,0,0,1,6,9Z" />
        <path class="cls-1" d="M6,17H4a1,1,0,0,1,0-2H6a1,1,0,0,1,0,2Z" />
        <path class="cls-1" d="M20,13H18a1,1,0,0,1,0-2h2a1,1,0,0,1,0,2Z" />
        <path class="cls-1" d="M20,17H18a1,1,0,0,1,0-2h2a1,1,0,0,1,0,2Z" />
      </g>
    </svg>
    <svg
      height="auto"
      version="1.1"
      viewBox="0 0 60 60"
      width="300px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title />
      <desc />
      <defs />
      <g
        fill="none"
        fill-rule="evenodd"
        id="Thermometer-Hot"
        stroke="none"
        stroke-width="1"
        transform="translate(10,13)"
      >
        <g
          id="Group"
          stroke="#979797"
          stroke-width="2"
          transform="translate(7.000000, 2.000000)"
        >
          <path
            d="M3,16.8026932 L3,3 C3,1.34314575 4.34314575,3.04359188e-16 6,0 C7.65685425,-3.04359188e-16 9,1.34314575 9,3 L9,16.8026932 C10.7934041,17.8401214 12,19.7791529 12,22 C12,25.3137085 9.3137085,28 6,28 C2.6862915,28 0,25.3137085 0,22 C0,19.7791529 1.20659589,17.8401214 3,16.8026932 Z"
            id="Combined-Shape"
          />
          <path d="M13,5 L18,5" id="Path-19" stroke-linecap="round" />
          <path d="M13,9 L18,9" id="Path-20" stroke-linecap="round" />
          <path d="M13,13 L18,13" id="Path-21" stroke-linecap="round" />
        </g>
        <g fill="red" id="Group-2" transform="translate(10.000000, 12.000000)">
          <circle cx="3" cy="12" id="Oval" r="3" />
          <rect height="11" id="Rectangle-2" rx="1" width="2" x="2" y="0" />
        </g>
      </g>
    </svg>
  </svg>
);
export default HvacSvg;
