import React, { Component } from "react";

import { setJwt } from "./services/httpService";
import {
  masterCompany,
  footerLogoSource,
  manufacturingCompany,
} from "./config.json";
import jwtDecode from "jwt-decode";

import { getUser, getUserSystems, logoutUser } from "./services/userService";
import { getCompany } from "./services/companyService";

import Users from "./components/users";
import Companies from "./components/company";
import NewCompanyForm from "./components/companyForm";
import FleetInfo from "./components/fleetInfo";
import CompanyDeleteForm from "./components/deleteCompany";
import CompanyRestoreForm from "./components/restoreCompany";
import CompanyAdminForm from "./components/adminCompany";
import CompanySystems from "./components/companySystems";
import CompanyUsers from "./components/companyUsers";
import EditCompany from "./components/companyEdit";
import UserAccessControl from "./components/userAccessControl";

import Systems from "./components/systems";
import Manufactures from "./components/manufactures";
import SystemsUsers from "./components/systemsUsers";

import NavBar from "./components/navbar";
import LoginForm from "./components/loginForm";
import LogoutForm from "./components/logout";

import RecoverCredentialsByEmail from "./components/recoverCredentialsByEmail";
import VerifyAccount from "./components/verifyAccount";

import RegisterForm from "./components/registerForm";
import AccountInfo from "./components/accountInfo";
import EditUserForm from "./components/editUser";
import EditPswd from "./components/editPswd";
import UnregUser from "./components/unregUser";
import SimplSizing from "./components/simplSizing";
import SimplCourses from "./components/simplCourses";
import UserSystems from "./components/userSystems";
import AddUserToCompany from "./components/userCompany";
import UserAddOwner from "./components/userAddOwner";
import UserRemoveOwner from "./components/userRemoveOwner";
import UserAddInstaller from "./components/userAddInstaller";
import UserRemoveInstaller from "./components/userRemoveInstaller";
import UserAddOperator from "./components/userAddOperator";
import UserRemoveOperator from "./components/userRemoveOperator";
import UserAddSub from "./components/userAddSub";
import UserRemoveSub from "./components/userRemoveSub";
import VerifyUser from "./components/userVerify";

import ManufactureSearch from "./components/manufactureSearch";
import ManufactureBoard from "./components/manufactureBoard";
import ManufactureEdit from "./components/manufactureEdit";

import ManufactureProduct from "./components/manufactureProduct";
import ManufactureForm from "./components/manufactureForm";
import snForm from "./components/snForm";
import snProductForm from "./components/snProductForm";
import snBoardForm from "./components/snBoardForm";
import UninstallBoard from "./components/snUnregBoard"
import sfCommForm from "./components/sfCommission";
import AddressForm from "./components/systemAddress";
import SignalsForm from "./components/systemSignals";
import LimitsForm from "./components/systemLimits";
import SystemAlive from "./components/systemAlive";
import ControlsForm from "./components/systemControls";
import SimplNodesControls from "./components/simplNodesControls";
import SimplNodesLimits from "./components/simplNodesLimits";

import LoadsControlsForm from "./components/systemLoadsControls";
import BasicsForm from "./components/systemBasics";
import SimplModuleForm from "./components/systemModules";
import CalibratePage from "./components/calibratePage";

import HistoryBarChart from "./components/history-bar-chart";
import BillsPieChart from "./components/bills-pie-chart";
import AggregateGraph from "./components/aggregate-graph";
import Dashboard from "./components/dashboard";
import IntervalGraphDaily from "./components/interval-graphDaily";
import DashboardChart from "./components/dashboardChart";
import GPLineChart from "./components/gpLineChart";
import SlaveTable from "./components/systemSlaveTables";
import SlaveWideTable from "./components/systemWideSlaveTables";
import ListView from "./components/systemListView";
import SystemGauges from "./components/systemGauges";
import DeleteSystem from "./components/deleteSystem";
import RestoreSystem from "./components/restoreSystem";
import SetChartPoints from "./components/setChartPoints";
import CalibrateSystem from "./components/systemCalibration";
import BasicTestSystem from "./components/systemBasicTest";
import SystemSettings from "./components/systemSettings";
import SimplMap from "./components/simplMaps";
import BackGroundRectangle from "./components/backgroundRect";
import Tariff from "./components/tariffTable";
import WebSocketComp from "./components/websocket_client";
import FwUpdate from "./components/fwUpdate";
import SimplVu from "./components/simplVu";
import SimplNodesVu from "./components/simplNodesVu";
import SimplNodesVuBasic from "./components/simplNodesVuBasic";
import UserHistory from "./components/userHistory";
import ManageUsers from "./components/manageUsers";
import SimplThermostatTabs from "./components/simplThermostatTabs";

import SimplNodesVuWide from "./components/simplNodesVuWide";
import AnimationTwo from "./components/animationTwo";
import SignalStrength from "./components/systemSignalStrength";

import ThermostatLocalClient from "./components/thermostatLocalClient";
import NonThermostatLocalClient from "./components/nonThermostatLocalClient";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./index.css";

import { MemoryRouter, Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { library } from "@fortawesome/fontawesome-svg-core";

//import NoInternetConnection from "./NoInternetConnection";
import NetworkDetector from "./Hoc/NetworkDetector";

import SimplEquipment from "./components/simplEquipment";
import Alarms from "./components/alarms";
import SystemConnectionAlarm from "./components/systemAlarm";
import SystemHistory from "./components/systemHistory";
import GPWalkInChart from "./components/gpWalkInChart";

import {
  faEdit,
  faTrashAlt,
  faIdBadge,
  faSlidersH,
  faUserFriends,
  faMapMarkerAlt,
  faArrowsAltV,
  faTachometerAlt,
  faWifi,
  faEye,
  faChartBar,
  faChartLine,
  faChartPie,
  faUserLock,
  faAddressCard,
  faKey,
  faUnderline,
  faCog,
  faExternalLinkAlt,
  faPause,
  faPlay,
  faArrowsAltH,
  faRulerCombined,
  faSatelliteDish,
  faUsersCog,
  faCogs,
  faMinusSquare,
  faPlusSquare,
  faAt,
  faInfoCircle,
  faPlusCircle,
  faSearchDollar,
  faCloudUploadAlt,
  faCheck,
  faTrashRestore,
  faDollarSign,
  faSignOutAlt,
  faSignInAlt,
  faHome,
  faClock,
  faTh,
  faFileExcel,
  faThLarge,
  faCalculator,
  faArrowAltCircleLeft,
  faSearch,
  faPowerOff,
  faCrosshairs,
  faHistory,
  faRetweet,
  faExclamationTriangle,
  faBalanceScale,
  faHeartbeat,
  faDesktop,
  faSquare,
  faSignal,
  faGlobeAmericas,
  faBars,
  faDownload,
  faStop,
  faChargingStation,
  faCarBattery,
  faSolarPanel,
  faHandPointDown,
  faNetworkWired,
  faBolt,
  faSave,
  faTruck,
  faCopy,
  faMobileAlt,
  faTemperatureLow,
  faListUl,
  faBell,
  faFile,
  faBullseye,
  faAngleUp,
  faAngleDoubleUp,
  faChalkboardTeacher,
} from "@fortawesome/free-solid-svg-icons";
import zIndex from "@material-ui/core/styles/zIndex";

library.add(
  faIdBadge,
  faEdit,
  faTrashAlt,
  faSlidersH,
  faUserFriends,
  faMapMarkerAlt,
  faArrowsAltV,
  faTachometerAlt,
  faWifi,
  faEye,
  faChartBar,
  faChartLine,
  faChartPie,
  faUserLock,
  faAddressCard,
  faKey,
  faUnderline,
  faCog,
  faExternalLinkAlt,
  faPause,
  faPlay,
  faArrowsAltH,
  faRulerCombined,
  faSatelliteDish,
  faUsersCog,
  faCogs,
  faMinusSquare,
  faPlusSquare,
  faAt,
  faInfoCircle,
  faPlusCircle,
  faSearchDollar,
  faCloudUploadAlt,
  faCheck,
  faTrashRestore,
  faDollarSign,
  faSignOutAlt,
  faSignInAlt,
  faHome,
  faClock,
  faTh,
  faThLarge,
  faChartLine,
  faFileExcel,
  faCalculator,
  faArrowAltCircleLeft,
  faSearch,
  faPowerOff,
  faCrosshairs,
  faHistory,
  faRetweet,
  faExclamationTriangle,
  faBalanceScale,
  faHeartbeat,
  faDesktop,
  faSquare,
  faSignal,
  faGlobeAmericas,
  faBars,
  faDownload,
  faStop,
  faChargingStation,
  faCarBattery,
  faSolarPanel,
  faHandPointDown,
  faNetworkWired,
  faBolt,
  faSave,
  faTruck,
  faCopy,
  faMobileAlt,
  faTemperatureLow,
  faListUl,
  faBell,
  faFile,
  faBullseye,
  faAngleDoubleUp,
  faChalkboardTeacher
);

class App extends Component {
  state = {
    user: "",
    firstName: "",
    lastName: "",
    companyName: { masterCompany }.masterCompany,
    mfa: false,
    isAdmin: false,
    userSystems: "",
    systemFlag: false,
    initialized: true,
    mustLoginAgain: false,
    verified: false,
    isOnline: true,
    isConnected: true,
  };

  async checkCompanyMfa() {
    const companyName = this.state.companyName;
    let mfa = this.state.mfa;
    const company = (await getCompany(companyName)).data.data;

    if (company.MFA === true) {
      mfa = true;
    } else {
      mfa = false;
    }
    this.setState({ mfa });

    return mfa;
  }

  async componentDidMount() {
    const pointsOnChart = parseInt(localStorage.getItem("pointsOnChart"));
    console.log("Points On Chart from local storage", pointsOnChart);
    if (!pointsOnChart) {
      console.log("Points On Chart not define if statement", pointsOnChart);
      localStorage.setItem("pointsOnChart", 20);
    }
    localStorage.setItem("connectedNess", true);

    let user = null;
    const jwt = localStorage.getItem("token");

    let verified = this.state.verified;
    let isVerified = localStorage.getItem("verified");
    isVerified = eval(isVerified);

    const decodedJWT = jwtDecode(jwt).exp * 1000;
    console.log("Decoded JWT", decodedJWT);
    let date = new Date(Date.now());
    console.log("EPOCH Now:", date);
    let mustLoginAgain = this.state.mustLoginAgain;
    if (date >= decodedJWT) {
      console.log("Go to login page");
      mustLoginAgain = true;
      localStorage.setItem("verified", false);
      await logoutUser("username", "pass");
    } else {
      mustLoginAgain = false;
    }

    if (isVerified === true) {
      verified = true;
    } else {
      verified = false;
      console.log("must verify");
    }
    setJwt(jwt);
    console.log("JWT Token", jwt);

    user = await getUser();

    if (user.status === 200) {
      console.log("User in CDM in APP.JS", user);
      const userSystems = (await getUserSystems()).data.data;
      console.log("Logged in User", user.data.data.username);
      console.log("Company Name", user.data.data.company);
      console.log("User Systems", userSystems);

      //Added this to get it to go to the map when map option is on in accountInfo
      const mapState = JSON.parse(localStorage.getItem("mapBoolean"));
      console.log("Map State in APP CDM", mapState);
      this.setState({ mapState });
      //////////////////////////////////////////////////////////////////////
      let systemFlag = false;
      if (
        !userSystems.installerOf &&
        !userSystems.operatorOf &&
        !userSystems.ownerOf
      )
        systemFlag = false;
      else systemFlag = true;

      this.setState({ systemFlag, mustLoginAgain });
      this.setState({ verified });
      this.setState({ user: user.data.data.username });
      this.setState({ firstName: user.data.data.firstName });
      this.setState({ lastName: user.data.data.lastName });
      this.setState({ companyName: user.data.data.company });
      this.setState({ isAdmin: user.data.data.admin });

      this.checkCompanyMfa(user);
    } else {
      console.log("Not Good");
    }
  }
  handleInit = () => {
    console.log("Initialized!");
    const init = true;
    this.setState({ initialized: init });
    console.log("Init Status", this.state.initialized);
  };

  render() {
    const HandleId = parseInt(localStorage.getItem("HandleId"));
    const userCompany = localStorage.getItem("userCompany");
    const systemSN = localStorage.getItem("systemSN");
    const systemName = localStorage.getItem("systemName");
    const connectedNess = localStorage.getItem("connectedNess");
    const userValidated = localStorage.getItem("userValidated");
    //if (connectedNess === "true") console.log("Main loop connected...");
    //else console.log("Main loop disconnected...");

    //console.log("....  Looping  ....");

    return (
      <React.Fragment>
        <ToastContainer />

        <NavBar
          user={this.state.user}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          companyName={this.state.companyName}
          isAdmin={this.state.isAdmin}
          systemFlag={this.state.systemFlag}
          mapState={JSON.parse(localStorage.getItem("mapBoolean"))}
          verified={this.state.verified}
          mfa={this.state.mfa}
        />

        <main className="container">
          {(!this.state.user || this.state.mustLoginAgain) && (
            <Redirect from="/" exact to="/login" />
          )}

          {this.state.user && (
            <div>
              {this.state.companyName === manufacturingCompany && (
                <Redirect from="/" exact to="/manufactures" />
              )}

              {this.state.companyName === masterCompany &&
                this.state.isAdmin &&
                localStorage.getItem("systemMapButton") === "noButton" && (
                  <Redirect from="/" exact to="/Companies" />
                )}

              {this.state.companyName !== masterCompany &&
                this.state.companyName !== manufacturingCompany &&
                this.state.isAdmin &&
                !this.state.mapState && (
                  <Redirect
                    from="/"
                    exact
                    to={`/Companies/systems/${this.state.companyName}`}
                  />
                )}

              {this.state.companyName !== masterCompany &&
                this.state.companyName !== manufacturingCompany &&
                this.state.isAdmin &&
                this.state.mapState &&
                localStorage.getItem("systemMapButton") === "noButton" && (
                  <Redirect from="/" exact to={`/systems/simplMap`} />
                )}

              {this.state.companyName !== manufacturingCompany &&
                this.state.isAdmin &&
                localStorage.getItem("systemMapButton") === "users" && (
                  <Redirect from="/" exact to={`/systems/users/${HandleId}`} />
                )}
              {this.state.companyName !== manufacturingCompany &&
                this.state.isAdmin &&
                localStorage.getItem("systemMapButton") === "settings" && (
                  <Redirect
                    from="/"
                    exact
                    to={`/systems/settings/${HandleId}/${userCompany}/${systemSN}/${systemName}/companyAdmin`}
                  />
                )}
              {this.state.companyName !== manufacturingCompany &&
                this.state.isAdmin &&
                localStorage.getItem("systemMapButton") === "dashboard" && (
                  <Redirect
                    from="/"
                    exact
                    to={`/systems/dashboard/${HandleId}/${userCompany}/companyAdmin`}
                  />
                )}

              {this.state.companyName !== masterCompany &&
                this.state.companyName !== manufacturingCompany &&
                !this.state.isAdmin &&
                this.state.systemFlag && (
                  <Redirect
                    from="/"
                    exact
                    to={`/users/systems/${this.state.user}`}
                  />
                )}
              {this.state.companyName !== masterCompany &&
                this.state.companyName !== manufacturingCompany &&
                !this.state.isAdmin &&
                !this.state.systemFlag && (
                  <Redirect from="/" exact to="/accountInfo" />
                )}

              {this.state.companyName === masterCompany &&
                !this.state.isAdmin &&
                this.state.systemFlag && (
                  <Redirect
                    from="/"
                    exact
                    to={`/users/systems/${this.state.user}`}
                  />
                )}
              {this.state.companyName === masterCompany &&
                !this.state.isAdmin &&
                !this.state.systemFlag && (
                  <Redirect from="/" exact to="/accountInfo" />
                )}
              {!this.state.verified &&
                this.state.mfa === true &&
                (this.state.isAdmin || !this.state.isAdmin) &&
                (this.state.systemFlag || !this.state.systemFlag) && (
                  <Redirect from="/" exact to={`/verify`} />
                )}
            </div>
          )}

          <Switch>
            <Route path="/login" component={LoginForm} />
            <Route path="/logout" component={LogoutForm} />
            <Route path="/reset" component={RecoverCredentialsByEmail} />
            <Route path="/verify" component={VerifyAccount} />
            <Route
              path="/users/userAccessControl"
              component={UserAccessControl}
            />
            <Route
              path={`/users/userHistory/:companyName`}
              component={UserHistory}
            />
            <Route
              path={`/users/manageUsers/:companyName`}
              component={ManageUsers}
            />
            <Route path="/editUser" component={EditUserForm} />
            <Route path="/editPswd" component={EditPswd} />
            <Route path="/unregUser/:id" component={UnregUser} />
            <Route path="/users/new" component={RegisterForm} />
            <Route path="/accountinfo" component={AccountInfo} />
            <Route path="/s2p2/:username" component={SimplSizing} />
            <Route path="/courses/:username" component={SimplCourses} />
            <Route path="/users/updateCompany" component={AddUserToCompany} />
            <Route path="/users/systems/:id" component={UserSystems} />

            <Route path="/users/verifyuser/:username" component={VerifyUser} />
            <Route
              path="/users/addowner/:username/:serialNumber/:handleID/:companyName"
              component={UserAddOwner}
            />
            <Route
              path="/users/removeowner/:username/:serialNumber/:handleID/:companyName"
              component={UserRemoveOwner}
            />
            <Route
              path="/users/addinstaller/:username/:serialNumber/:handleID/:companyName"
              component={UserAddInstaller}
            />
            <Route
              path="/users/removeinstaller/:username/:serialNumber/:handleID/:companyName"
              component={UserRemoveInstaller}
            />
            <Route
              path="/users/addoperator/:username/:serialNumber/:handleID/:companyName"
              component={UserAddOperator}
            />
            <Route
              path="/users/removeoperator/:username/:serialNumber/:handleID/:companyName"
              component={UserRemoveOperator}
            />

            {/* New route for adding and revoke sub admin */}
            <Route
              path="/users/addsubadmin/:username/:companyName/:handleID"
              component={UserAddSub}
            />
            <Route
              path="/users/removesubadmin/:username/:companyName/:handleID"
              component={UserRemoveSub}
            />

            <Route path="/users" component={Users} />

            <Route path="/Companies/users/:id" component={CompanyUsers} />
            <Route path="/Companies/systems/:id" component={CompanySystems} />
            <Route
              path="/Companies/admin/:companyName"
              component={CompanyAdminForm}
            />
            <Route
              path="/Companies/restore/:id"
              component={CompanyRestoreForm}
            />
            <Route path="/Companies/delete/:id" component={CompanyDeleteForm} />
            <Route path="/Companies/new" component={NewCompanyForm} />
            <Route
              path="/Companies/fleetInfo/:companyName"
              component={FleetInfo}
            />
            <Route
              path="/Companies/edit/:companyName"
              component={EditCompany}
            />
            <Route path="/Companies" component={Companies} />
            <Route
              path="/systems/dashboardSlaves/:systemHandleID/:companyName"
              component={SlaveTable}
            />
            <Route
              path="/systems/dashboardWideSlaves/:systemHandleID/:companyName"
              component={SlaveWideTable}
            />
            <Route
              path="/systems/listView/:systemHandleID/:companyName/:userRole?"
              component={ListView}
            />
            <Route
              path="/systems/simplEquipment/:systemHandleID/:nodeName"
              component={SimplEquipment}
            />

            <Route
              path="/systems/simplAlarms/:systemHandleID/:nodeName"
              component={Alarms}
            />

            <Route
              path="/systems/systemConnection/:systemHandleID"
              component={SystemConnectionAlarm}
            />

            <Route
              path="/systems/systemHistory/:systemHandleID/:companyName"
              component={SystemHistory}
            />

            <Route
              path="/systems/signalStrength/:systemHandleID/:companyName"
              component={SignalStrength}
            />

            <Route
              path="/systems/dashboardGauges/:systemHandleID/:companyName"
              component={SystemGauges}
            />
            <Route
              path="/systems/calibratePage/:systemHandleID/:macAddress"
              component={CalibratePage}
            />

            <Route path="/systems/animation2" component={AnimationTwo} />
            <Route path="/systems/commission" component={sfCommForm} />
            <Route path="/systems/newProduct" component={snProductForm} />
            <Route path="/systems/newBoard/:companyName" component={snBoardForm} />
            <Route path="/systems/uninstallBoard" component={UninstallBoard} />

            <Route path="/systems/new" component={snForm} />

            <Route
              path="/systems/users/:id/:companyName/:userRole?"
              component={SystemsUsers}
            />

            <Route
              path="/systems/user/:id/:userRole?"
              component={AddressForm}
            />
            <Route
              path="/systems/location/:systemHandleID/:companyName/:userRole?"
              component={AddressForm}
            />

            <Route
              path="/systems/basics/:systemHandleID/:companyName/:userRole?"
              component={BasicsForm}
            />
            <Route
              path="/systems/systemmodules/:systemHandleID/:companyName/:slaveMAC"
              component={SimplModuleForm}
            />
            <Route
              path="/systems/controls/:systemHandleID/:companyName/:userRole?"
              component={ControlsForm}
            />
            <Route
              path="/systems/simplNodesControls/:systemHandleID/:companyName/:userRole?/:displayWidth/:nodenamePassed?"
              component={SimplNodesControls}
            />
            <Route
              path="/systems/loadscontrols/:systemHandleID"
              component={LoadsControlsForm}
            />
            <Route
              path="/systems/limits/:systemHandleID/:companyName/:userRole?"
              component={LimitsForm}
            />
            <Route
              path="/systems/simplNodesLimits/:systemHandleID/:companyName/:userRole?"
              component={SimplNodesLimits}
            />
            <Route
              path="/systems/statusAlive/:systemHandleID/:companyName"
              component={SystemAlive}
            />
            <Route
              path="/systems/signals/:systemHandleID/:companyName/:userRole?/:signalsFunction"
              component={SignalsForm}
            />

            <Route path="/systems/webSocketComp" component={WebSocketComp} />
            <Route path="/systems/fwUpload" component={FwUpdate} />
            <Route
              path="/systems/dashboardChart/:systemHandleID/:companyName/:startDate/:endDate/:userRole"
              component={DashboardChart}
            />
            <Route
              path="/systems/gpLineChart/:systemHandleID/:companyName/:systemName/:nodeName"
              component={GPLineChart}
            />
            <Route
              path="/systems/gpWalkinChart/:systemHandleID/:companyName/:systemName/:nodeName"
              component={GPWalkInChart}
            />
            <Route
              path="/systems/dashboardDaily/:systemHandleID/:systemConfiguration"
              component={IntervalGraphDaily}
            />
            <Route
              path="/systems/simplVu/:systemHandleID/:companyName/:systemSN/:systemName/:userRole?"
              component={SimplVu}
            />
            <Route
              path="/systems/simplNodesVu/:systemHandleID/:companyName/:systemSN/:systemName/:userRole?"
              component={SimplNodesVu}
            />
            <Route
              path="/systems/simplNodesVuBasic/:systemHandleID/:companyName/:systemSN/:systemName/:userRole?"
              component={SimplNodesVuBasic}
            />
            <Route
              path="/systems/thermostatLocalClient/:systemHandleID"
              component={ThermostatLocalClient}
            />
            <Route
              path="/systems/nonThermostatLocalClient/:systemHandleID/:loadType"
              component={NonThermostatLocalClient}
            />
            <Route
              path="/systems/simplNodesVuWide/:systemHandleID/:companyName/:systemSN/:systemName/:userRole?"
              component={SimplNodesVuWide}
            />
            <Route
              path="/systems/dashboard/:systemHandleID/:companyName/:userRole?"
              component={Dashboard}
            />
            <Route path="/systems/monitorPie" component={BillsPieChart} />
            <Route path="/systems/monitorGraph" component={AggregateGraph} />
            <Route path="/systems/monitorBar" component={HistoryBarChart} />
            <Route path="/systems/chartpoints/:id" component={SetChartPoints} />
            <Route
              path="/systems/calibrate/:systemHandleID"
              component={CalibrateSystem}
            />
            <Route
              path="/systems/basicTest/:systemHandleID"
              component={BasicTestSystem}
            />
            <Route
              path="/systems/delete/:systemHandleID/:companyName?"
              component={DeleteSystem}
            />
            <Route
              path="/systems/restore/:systemHandleID/:companyName"
              component={RestoreSystem}
            />
            <Route
              path="/systems/settings/:systemHandleID/:companyName/:systemSN/:systemName/:userRole?"
              component={SystemSettings}
            />

            <Route path="/systems/simplMap" component={SimplMap} />
            <Route path="/systems" component={Systems} />

            <Route path="/manufactures/newBoard" component={ManufactureBoard} />
            <Route path="/manufactures/editBoard" component={ManufactureEdit} />
            <Route path="/manufactures/search" component={ManufactureSearch} />
            <Route
              path="/manufactures/newProduct"
              component={ManufactureProduct}
            />
            <Route path="/manufactures/new" component={ManufactureForm} />
            <Route path="/manufactures" component={Manufactures} />

            <Route
              path="/tariffs/:systemSN/:companyName/:userRole?"
              component={Tariff}
            />

            {/* <Route
              path="/systems/simplEquipment"
              component={SimplEquipment}
            /> */}
          </Switch>

          <div className="footer">
            <img
              className="simplImage"
              src={footerLogoSource}
              width="60"
              height="33"
              alt=""
            />
            <p>&#169; {masterCompany}</p>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
//export default NetworkDetector(App);
export default App;
/*88888888888888888888888888888888888888888888888888888888888888888888888}
{connectedNess === "true" && (
  88888888888888888888888888888888888888888888888888888888888888888888888*/

/*88888888888888888888888888888888888888888888888888888888888888888888888
  )}
        
  {connectedNess === "false" && userValidated === "valid" && (
    <WebSocketComp />
  )}
88888888888888888888888888888888888888888888888888888888888888888888888*/
