import React, { Component } from "react";

import "./dashboard.css";
import "../index.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import "react-datepicker/dist/react-datepicker.css";

function ClearProgress(props) {
  return (
    <React.Fragment>
      <div
        className="progress-bar"
        style={{
          height: `${props.value}`,
          backgroundColor: `${props.color}`,
          borderTop: "1px solid #BBBBBB",
        }}
      >
        <p
          classname="basicParagraph"
          style={{
            fontSize: "10px",
            fontWeight: "normal",
            color: "#595959",
            padding: "0px",
            margin: "0px",
            transform: "translateY(-50px)",
          }}
        >
          {props.Number}
        </p>
        <p
          classname="basicParagraph"
          style={{
            fontSize: "10px",
            color: "#595959",
            padding: "0px",
            margin: "0px",
            transform: "translateY(-50px)",
          }}
        >
          {props.unit}
        </p>
      </div>
    </React.Fragment>
  );
}
export default ClearProgress;
