import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getCompanySystems } from "../services/companyService";
import { getUser } from "../services/userService";
import {
  getSystems,
  getSystemIDfromHandle,
  getSystem,
} from "../services/systemService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setJwt } from "../services/httpService";
import { masterCompany } from "../config.json";
import BackGroundRectangle from "../components/backgroundRect";

class SystemSettings extends Component {
  state = {
    allSystems: [],
    companySystems: [],
    activeCompanySystems: [],
    userCompany: "",
    systems: [],
    isActive: true,
  };

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    setJwt(jwt);

    const userRole = this.props.match.params.userRole;
    console.log("User Role", userRole);

    //const loggedInUser = (await getUser()).data.data;
    //console.log("Logged in User", loggedInUser);
    //const userIsAdmin = loggedInUser.admin;
    //console.log("Is User Admin", userIsAdmin);

    const handleId = this.props.match.params.systemHandleID;
    const systemId = (await getSystemIDfromHandle(handleId)).data.data
      .system_id;
    const isActive = (await getSystem(systemId)).data.data[0].active;
    this.setState({ isActive });
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            // width: "350px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1,
          }}
        >
          <p
            className="basicParagraph"
            style={{
              color: "magenta",
              fontSize: "18px",
              width: "300px",
              textAlign: "center",
            }}
          >
            <FontAwesomeIcon
              icon="cog"
              size="2x"
              color="magenta"
              title="Settings"
              style={{ paddingRight: "10px" }}
            />
            Settings for {this.props.match.params.systemName}
          </p>
          <table className="table">
            <thead>{this.props.match.params.id}</thead>
          </table>

          <table className="table" style={{ width: "300px", margin: "0px" }}>
            <tbody>
              <td className="icon">
                <Link
                  to={`/systems/dashboard/${this.props.match.params.systemHandleID}/${this.props.match.params.companyName}/${this.props.match.params.userRole}`}
                >
                  <FontAwesomeIcon
                    icon="tachometer-alt"
                    size="lg"
                    color="green"
                    title="Monitor the System"
                  />
                </Link>
              </td>
              <td className="icon">
                <Link
                  to={`/systems/signals/${
                    this.props.match.params.systemHandleID
                  }/${this.props.match.params.companyName}/${"initiateUpdate"}`}
                >
                  {/*}}/${this.props.match.params.companyName}/${"signalOnly"}`}*/}
                  <FontAwesomeIcon
                    icon="satellite-dish"
                    size="lg"
                    color="black"
                    title="Set System Signals"
                  />
                </Link>
              </td>
              <td className="icon">
                <Link
                  to={`/systems/signals/${
                    this.props.match.params.systemHandleID
                  }/${this.props.match.params.companyName}/${"initiateUpdate"}`}
                >
                  <FontAwesomeIcon
                    icon="cloud-upload-alt"
                    size="lg"
                    color="dodgerblue"
                    title="System Update"
                  />
                </Link>
              </td>
              <td className="icon">
                <Link
                  to={`/tariffs/${this.props.match.params.systemSN}/${this.props.match.params.companyName}`}
                >
                  <FontAwesomeIcon
                    icon="dollar-sign"
                    size="lg"
                    color="forestgreen"
                    title="Update Tariff Data"
                  />
                </Link>
              </td>
              <td className="icon">
                <Link
                  to={`/systems/basics/${this.props.match.params.systemHandleID}/${this.props.match.params.companyName}`}
                >
                  <FontAwesomeIcon
                    icon="edit"
                    size="lg"
                    color="brown"
                    title="Set System Basic Parameters"
                  />
                </Link>
              </td>
            </tbody>
          </table>
          <table className="table" style={{ width: "300px", margin: "0px" }}>
            <tbody>
              <td className="icon">
                <Link
                  to={`/systems/controls/${this.props.match.params.systemHandleID}/${this.props.match.params.companyName}`}
                >
                  <FontAwesomeIcon
                    icon="sliders-h"
                    size="lg"
                    color="magenta"
                    title="Adjust System Controls"
                  />
                </Link>
              </td>
              <td className="icon">
                <Link
                  to={`/systems/limits/${this.props.match.params.systemHandleID}/${this.props.match.params.companyName}`}
                >
                  <FontAwesomeIcon
                    icon="arrows-alt-v"
                    size="lg"
                    color="red"
                    title="Change System Limits"
                  />
                </Link>
              </td>
              <td className="icon">
                <Link
                  to={`/systems/statusAlive/${this.props.match.params.systemHandleID}/${this.props.match.params.companyName}`}
                >
                  <FontAwesomeIcon
                    icon="heartbeat"
                    size="lg"
                    color="purple"
                    title="Set system Status to ALIVE"
                  />
                </Link>
              </td>
              <td className="icon">
                <Link
                  to={`/systems/location/${this.props.match.params.systemHandleID}/${this.props.match.params.companyName}`}
                >
                  <FontAwesomeIcon
                    icon="map-marker-alt"
                    size="lg"
                    color="dodgerblue"
                    title="Change System Location"
                  />
                </Link>
              </td>
              <td style={{ width: "50px" }}>
                {this.state.isActive && (
                  <Link
                    to={`/systems/delete/${this.props.match.params.systemHandleID}/${this.props.match.params.companyName}`}
                  >
                    <FontAwesomeIcon
                      icon="trash-alt"
                      size="lg"
                      color="red"
                      title="Remove System"
                    />
                  </Link>
                )}

                {!this.state.isActive && (
                  <Link
                    to={`/systems/restore/${this.props.match.params.systemHandleID}/${this.props.match.params.companyName}`}
                  >
                    <FontAwesomeIcon
                      icon="trash-restore"
                      size="lg"
                      color="Green"
                      title="Restore System"
                    />
                  </Link>
                )}
              </td>
            </tbody>
          </table>
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default SystemSettings;
