import React from "react";

const ReachInSvg = (props) => (
  <svg
    style={{ margin: "0px", padding: "0px", height: "90px", width: "200px" }}
  >
    <svg
      height="200px"
      width="auto"
      id="ICONS"
      version="1.1"
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <g>
        <text
          x="1"
          y="10"
          font-family="Verdana"
          font-size="6"
          font-weight="normal"
          fill={props.textColor}
        >
          Counter Reach-In
        </text>
      </g>
    </svg>
    <svg
      height="69px"
      id="ICONS"
      version="1.1"
      viewBox="0 0 76 76"
      width="auto"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-55, 17)">
        <g>
          <rect height="4" fill="#B3E5FC" width="6" x="42" y="55" />
          <rect height="4" fill="#B3E5FC" width="6" x="16" y="55" />
          <polygon
            points="15,13 15,21 13,21 12,21 12,13 13,13    "
            fill="#B3E5FC"
          />
          <polygon
            points="15,39 15,47 13,47 12,47 12,39 13,39    "
            fill="#B3E5FC"
          />
          <path
            d="M26,36v-4c0-0.55-0.45-1-1-1s-1,0.45-1,1v4h1c-1.1,0-2,0.9-2,2v6h4v-6c0-1.1-0.9-2-2-2H26z M26,17     v-4c0-0.55-0.45-1-1-1s-1,0.45-1,1v4h1c-1.1,0-2,0.9-2,2v6h4v-6c0-1.1-0.9-2-2-2H26z M34,36v-4c0-0.55-0.45-1-1-1s-1,0.45-1,1v4     h1c-1.1,0-2,0.9-2,2v6h4v-6c0-1.1-0.9-2-2-2H34z M34,17v-4c0-0.55-0.45-1-1-1s-1,0.45-1,1v4h1c-1.1,0-2,0.9-2,2v6h4v-6     c0-1.1-0.9-2-2-2H34z M42,36v-4c0-0.55-0.45-1-1-1s-1,0.45-1,1v4h1c-1.1,0-2,0.9-2,2v6h4v-6c0-1.1-0.9-2-2-2H42z M42,17v-4     c0-0.55-0.45-1-1-1s-1,0.45-1,1v4h1c-1.1,0-2,0.9-2,2v6h4v-6c0-1.1-0.9-2-2-2H42z M47,11v38c0,1.1-0.9,2-2,2H21c-1.1,0-2-0.9-2-2     V11c0-1.1,0.9-2,2-2h24C46.1,9,47,9.9,47,11z"
            fill="#B3E5FC"
          />
          <path
            d="M42,32v4h-1h-1v-4c0-0.55,0.45-1,1-1S42,31.45,42,32z"
            fill="#029AE4"
          />
          <path
            d="M42,13v4h-1h-1v-4c0-0.55,0.45-1,1-1S42,12.45,42,13z"
            fill="#029AE4"
          />
          <path
            d="M34,32v4h-1h-1v-4c0-0.55,0.45-1,1-1S34,31.45,34,32z"
            fill="#029AE4"
          />
          <path
            d="M34,13v4h-1h-1v-4c0-0.55,0.45-1,1-1S34,12.45,34,13z"
            fill="#029AE4"
          />
          <path
            d="M26,32v4h-1h-1v-4c0-0.55,0.45-1,1-1S26,31.45,26,32z"
            fill="#029AE4"
          />
          <path
            d="M26,13v4h-1h-1v-4c0-0.55,0.45-1,1-1S26,12.45,26,13z"
            fill="#029AE4"
          />
        </g>
        <g>
          <polyline
            points="     13,13 13,5 51,5 51,55 48,55 42,55 22,55 16,55 13,55 13,47    "
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
          />
          <line
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="13"
            x2="13"
            y1="39"
            y2="21"
          />
          <polyline
            points="     48,55 48,59 42,59 42,55    "
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
          />
          <polyline
            points="     22,55 22,59 16,59 16,55    "
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
          />
          <path
            d="     M47,49c0,1.1-0.9,2-2,2H21c-1.1,0-2-0.9-2-2V11c0-1.1,0.9-2,2-2h24c1.1,0,2,0.9,2,2V49z"
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
          />
          <path
            d="     M27,19c0-1.1-0.9-2-2-2s-2,0.9-2,2v6h4V19z"
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
          />
          <path
            d="     M35,19c0-1.1-0.9-2-2-2s-2,0.9-2,2v6h4V19z"
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
          />
          <path
            d="     M43,19c0-1.1-0.9-2-2-2s-2,0.9-2,2v6h4V19z"
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
          />
          <path
            d="     M27,38c0-1.1-0.9-2-2-2s-2,0.9-2,2v6h4V38z"
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
          />
          <path
            d="     M35,38c0-1.1-0.9-2-2-2s-2,0.9-2,2v6h4V38z"
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
          />
          <path
            d="     M43,38c0-1.1-0.9-2-2-2s-2,0.9-2,2v6h4V38z"
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
          />
          <line
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="22"
            x2="44"
            y1="28"
            y2="28"
          />
          <line
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="22"
            x2="44"
            y1="47"
            y2="47"
          />
          <polygon
            points="     13,13 15,13 15,21 13,21 12,21 12,13    "
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
          />
          <polygon
            points="     13,39 15,39 15,47 13,47 12,47 12,39    "
            fill="none"
            stroke="#029AE4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
          />
        </g>
      </g>
    </svg>
  </svg>
);
export default ReachInSvg;
