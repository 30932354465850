import React, { Component } from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import AggregateGraph from "./aggregate-graph";
import IntervalGraph from "./interval-graph";
import MoneyGraph from "./money-graph";
import DemandGraph from "./demand_intervals";

import {
  getSystemLatestData,
  ws_getSystemLatestData,
  getSystemBasics,
  getSystemIntervalData,
  getSystemInfo,
  getSystemIDfromHandle,
  getSystem,
} from "../services/systemService";
import "./dashboard.css";
import "../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "./common/form";
import ProgressBar from "react-bootstrap/ProgressBar";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";

import { webSocketMode } from "../config.json"; //0: http; 1:websocket

class DashboardChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      startDate: new Date(),
      stopDate: new Date(),
      companyName: "",
      chartPoints: "5",
      key: null,
      systemID: "",
      handleID: 0,
      pvPowerUnits: "Watts",
      batteryPowerUnits: "Watts",
      play: true,

      response: {
        deviceLedger: {
          realTime: {
            timestamp: 0,
            pvPower: 0,
            batteryPower: 0,
            inverterPower: 0,
            pvCurrent: 0,
            batteryCurrent: 0,
            inverterCurrent: 0,
            pvVoltage: 0,
            batteryVoltage: 0,
            inverterVoltage: 0,
          },
        },
      },
    };
    this.getSystemData = this.getSystemData.bind(this);

    this.handlePause = () => {
      this.setState({ play: false });
    };

    this.handlePlay = () => {
      this.setState({ play: true });
    };
  }

  async componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
    const handleID = this.props.match.params.systemHandleID;
    const companyName = this.props.match.params.companyName;
    const systemInfo = await getSystemInfo(handleID);
    console.log("System Info in Dashboard CDM", systemInfo);
    const systemStatus = systemInfo.data.data.status;

    const attachedSimplModuleSN = systemInfo.data.data.controls.moduleSN;
    const attachedSimplModuleHandle =
      systemInfo.data.data.controls.moduleHandleId;
    console.log("Module Handle ID:", attachedSimplModuleHandle);

    const moduleDataValid = systemInfo.data.data.controls.AttachedModuleValid;

    console.log("system status", systemStatus);
    const systemBasics =
      systemInfo.data.data
        .basics; /*(await getSystemBasics(handleID)).data.data.basics;*/
    console.log("System Basics", systemBasics);
    let circuitNameMeter1 = systemBasics.circuitNameMeter1;
    if (circuitNameMeter1 !== "0" && circuitNameMeter1 !== "1")
      circuitNameMeter1 = "0";
    const systemConfiguration = systemBasics.systemConfiguration;
    const systemProduct = systemBasics.systemProduct;
    const systemType = systemBasics.systemType;
    const paramUpdateRate = systemBasics.paramUpdateRate;
    const dataVerbosity = systemBasics.dataVerbosity;
    const dataFrequency = systemBasics.dataFrequency;

    const systemID = (await getSystemIDfromHandle(handleID)).data.data
      .system_id;
    console.log("System ID:", systemID);
    const nodeSystem = await getSystem(systemID);
    console.log("Node System", nodeSystem);
    const systemData = nodeSystem.data.data[0].systemID;
    const serialNumber = systemData.SN;
    const macAddress = systemData.MAC;
    const systemName = systemData.name;

    this.setState({
      handleID,
      companyName,
      systemStatus,
      systemConfiguration,
      systemProduct,
      systemType,
      paramUpdateRate,
      dataVerbosity,
      dataFrequency,
      circuitNameMeter1,
      attachedSimplModuleSN,
      attachedSimplModuleHandle,
      moduleDataValid,
      systemName,
      serialNumber,
    });
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  async getSystemData() {
    let response = {};
    console.log("Got to here with webSocketMode :", webSocketMode);
    if (webSocketMode) {
      response = ws_getSystemLatestData(this.state.handleID);
      console.log("Websocket: Latest Data", response);
    } else {
      response = (await getSystemLatestData(this.state.handleID)).data.data[0];
      console.log("Http: Latest Data", response);
    }

    console.log("Latest Data", response);
    if (response !== undefined && response !== null) {
      const commMode = response.data.commMode;
      console.log("Comm Mode", commMode);
      const moduleDataValid = response.data.AttachedModuleValid;

      let smMeter1 = 0;
      let smMeter2 = 0;
      // let responseAttachedModule = 0;
      /////////////////////////////////////////////////////////////////////////
      if (moduleDataValid === 1 && this.state.attachedSimplModuleHandle !== 0) {
        const responseAttachedModule = await getSystemLatestData(
          this.state.attachedSimplModuleHandle
        );
        console.log("Attached Module Response:", responseAttachedModule);
        smMeter1 =
          responseAttachedModule.data.data[0].data.deviceLedger.simplMeter_ii
            .sm2Ptotal1;
        smMeter2 =
          responseAttachedModule.data.data[0].data.deviceLedger.simplMeter_ii
            .sm2Ptotal2;
        console.log("Meter 1: ", smMeter1);
        console.log("Meter 2: ", smMeter2);
      } else {
        // toast.error("No SimplMeter Module is Attached...");
      }

      ///////////////////////////////////////////////////////////////////////////////

      const MeshId = response.data.MeshId;
      console.log("Mesh ID", MeshId);
      const systemTime = parseInt(
        response.data.deviceLedger.realTime.SystemLocalTimeSeconds
      );
      console.log("System Time:", systemTime);
      const loopCount = response.data.deviceLedger.realTime.loopCount;
      console.log("Loop Count ", loopCount);
      const systemTimeZone = parseInt(
        response.data.deviceLedger.realTime.TimeZone
      );
      console.log("System Time Zone:", systemTimeZone);
      const timeStampLatestData = parseInt(response.timestamp / 1000);
      console.log("Latest Data's Time Stamp", timeStampLatestData);
      const epochLocalTime = Math.round(new Date().getTime() / 1000);
      const timeDelta = Math.abs(timeStampLatestData - epochLocalTime);
      let dataFrequency_delta = 0;
      if (this.state.dataFrequency === "1") dataFrequency_delta = 1;
      //"1", name: "once / Second"
      else if (this.state.dataFrequency === "2") dataFrequency_delta = 5;
      //"2", name: "once / 5 Seconds"
      else if (this.state.dataFrequency === "3") dataFrequency_delta = 15;
      //"3", name: "once / 15 Seconds"
      else if (this.state.dataFrequency === "4") dataFrequency_delta = 60;
      //"4", name: "Once / Minute"
      else if (this.state.dataFrequency === "5") dataFrequency_delta = 300;
      //"5", name: "Once / 5 Minutes"
      else if (this.state.dataFrequency === "6") dataFrequency_delta = 900;
      //"6", name: "Once / 15 Minutes"
      else if (this.state.dataFrequency === "7") dataFrequency_delta = 1800;
      //"7", name: "Once / 30 Minutes"
      else if (this.state.dataFrequency === "8") dataFrequency_delta = 3600; //"8", name: "Once / Hour"

      if (commMode === 2 && dataFrequency_delta < 15) dataFrequency_delta = 15; //If 4G, should not go any faster than 15 seconds
      console.log("dataFreqency_delta", dataFrequency_delta);
      let dataTimeInvalid = false;
      if (timeDelta > 10 + dataFrequency_delta) {
        dataTimeInvalid = true;
      } else dataTimeInvalid = false;
      console.log("Delta Time :", timeDelta);
      console.log("Data Time Invalid", dataTimeInvalid);
      let sysT = new Date((systemTime - systemTimeZone * 3600) * 1000);
      const systemTimeOffset = systemTimeZone * 60 * 60 * 1000;
      const userTimeOffset = sysT.getTimezoneOffset() * 60 * 1000;
      sysT = new Date(sysT.getTime() + userTimeOffset + systemTimeOffset);
      let hourTime = sysT.getHours();
      if (hourTime < 10) hourTime = "0" + hourTime;
      let minuteTime = sysT.getMinutes();
      if (minuteTime < 10) minuteTime = "0" + minuteTime;
      let secTime = sysT.getSeconds();
      if (secTime < 10) secTime = "0" + secTime;
      const systemHumanTime = [hourTime, minuteTime, secTime].join(":");
      console.log("System Human Time:", systemHumanTime);
      this.setState({ systemHumanTime });

      console.log("Real Time Data", response.data.deviceLedger.realTime);
      console.log("Acuvim Data", response.data.deviceLedger.acuvim);
      this.setState({
        realTime: response.data.deviceLedger.realTime,
      });
      this.setState({
        acuvimData: response.data.deviceLedger.acuvim,
      });
      if (this.state.systemConfiguration === "3")
        this.setState({
          simplMeter_iiData: response.data.deviceLedger.simplMeter_ii,
        });
      if (
        this.state.systemConfiguration === "6" ||
        this.state.systemConfiguration === "8"
      ) {
        const demandLimit = response.data.deviceLedger.realTime.peakPower;
        console.log("Demand Limit in Dashboard Chart: ", demandLimit);
        this.setState({
          simplMeter_slave: response.data.deviceLedger.realTime,
          demandLimit,
        });
      }

      let pvPower = 0;
      let pvPowerRaw = 0;
      let pvPowerUnits = this.state.pvPowerUnits;
      let batteryPower = 0;
      let batteryPowerRaw = 0;
      let batteryPowerUnits = this.state.batteryPowerUnits;
      let gridPower = 0;
      let gridPowerRaw = 0;
      let gridPowerUnits = this.state.gridPowerUnits;
      let bldgPower = 0;
      let bldgPowerRaw = 0;
      let bldgPowerUnits = this.state.bldgPowerUnits;

      if (
        this.state.systemConfiguration === "0" &&
        this.state.realTime.slaveCount > 0 &&
        moduleDataValid === 0
      ) {
        pvPower = this.state.realTime.pvPower / 100;
        batteryPower = this.state.realTime.batteryPower / 100;
        gridPower = (-1.0 * this.state.realTime.inverterPower) / 100.0;
        bldgPower = this.state.realTime.buildingPower / 100; //0.0;
      }
      /////////////////////////////////////////////////////////////////////////
      else if (
        this.state.systemConfiguration === "0" &&
        this.state.realTime.slaveCount > 0 &&
        moduleDataValid === 1
      ) {
        pvPower = this.state.realTime.pvPower / 100;
        batteryPower = this.state.realTime.batteryPower / 100;
        gridPower = (-1.0 * this.state.realTime.inverterPower) / 100.0;
        bldgPower = this.state.realTime.buildingPower / 100; //0.0;
        if (batteryPower > 0.0) {
          //Discharging
          if (smMeter1 > 0) {
            //buying
            pvPower = smMeter2 - batteryPower;
            gridPower = smMeter1;
            bldgPower = smMeter1 + smMeter2;
          } else {
            //selling
            pvPower = smMeter2 + smMeter1 - batteryPower;
            gridPower = smMeter1;
            bldgPower = smMeter1 + smMeter2;
          }
        } else {
          //Charging

          if (smMeter1 > 0) {
            //buying
            pvPower = smMeter2;
            gridPower = smMeter1;
            bldgPower = smMeter1 + smMeter2;
          } else {
            //selling
            pvPower = smMeter1 + smMeter2;
            gridPower = smMeter1;
            bldgPower = smMeter1 + smMeter2;
          }
        }
      } else if (this.state.systemConfiguration === "1") {
        gridPower = this.state.acuvimData.acuvimPower0;
        batteryPower = 0.0;
      } else if (this.state.systemConfiguration === "2") {
        pvPower = this.state.acuvimData.acuvimPower1;
        batteryPower = 0.0;
        if (this.state.circuitNameMeter1 === "0") {
          console.log("Meter on Grid");
          gridPower = this.state.acuvimData.acuvimPower0;
          bldgPower = gridPower + this.state.acuvimData.acuvimPower1;
        } else if (this.state.circuitNameMeter1 === "1") {
          console.log("Meter on Bldg");
          bldgPower = this.state.acuvimData.acuvimPower0;
          gridPower = bldgPower - this.state.acuvimData.acuvimPower1;
        } else {
          gridPower = 0.0;
          bldgPower = 0.0;
        }
      }
      ////////////////////////////////////////////////////////////////////////
      else if (this.state.systemConfiguration === "3") {
        pvPower = this.state.simplMeter_iiData.sm2Ptotal2;
        if (this.state.circuitNameMeter1 === "0") {
          console.log("Meter on Grid");
          gridPower = this.state.simplMeter_iiData.sm2Ptotal1;
          bldgPower = gridPower + this.state.simplMeter_iiData.sm2Ptotal2;
        } else if (this.state.circuitNameMeter1 === "1") {
          console.log("Meter on Bldg");
          bldgPower = this.state.simplMeter_iiData.sm2Ptotal1;
          gridPower = bldgPower - this.state.simplMeter_iiData.sm2Ptotal2;
        } else {
          gridPower = 0.0;
          bldgPower = 0.0;
        }
      } else if (
        this.state.systemConfiguration === "6" ||
        this.state.systemConfiguration === "8"
      ) {
        pvPower = this.state.simplMeter_slave.AggPower2;
        if (this.state.circuitNameMeter1 === "0") {
          console.log("Meter on Grid");
          gridPower = this.state.simplMeter_slave.AggPower1;
          bldgPower = gridPower + this.state.simplMeter_slave.AggPower2;
        } else if (this.state.circuitNameMeter1 === "1") {
          console.log("Meter on Bldg");
          bldgPower = this.state.simplMeter_slave.AggPower1;
          gridPower = bldgPower - this.state.simplMeter_slave.AggPower2;
        } else {
          gridPower = 0.0;
          bldgPower = 0.0;
        }
      }

      ///////////////////////////////////////////////////////////////////////
      else {
        gridPower = 0.0;
        bldgPower = 0.0;
      }
      console.log("pvPowerRaw = ", pvPower);
      pvPowerRaw = pvPower.toFixed(0);
      this.powerNunits(pvPower);
      pvPower = this.state.power;
      pvPowerUnits = this.state.powerUnits;

      gridPowerRaw = gridPower.toFixed(0);
      this.powerNunits(gridPower);
      gridPower = this.state.power;
      gridPowerUnits = this.state.powerUnits;

      bldgPowerRaw = bldgPower.toFixed(0);
      this.powerNunits(bldgPower);
      bldgPower = this.state.power;
      bldgPowerUnits = this.state.powerUnits;

      batteryPowerRaw = batteryPower.toFixed(0);
      this.powerNunits(batteryPower);
      batteryPower = this.state.power;
      batteryPowerUnits = this.state.powerUnits;

      if (dataTimeInvalid) {
        pvPower = 0;
        pvPowerRaw = 0;
      }
      this.setState({ pvPower, pvPowerUnits, pvPowerRaw });
      this.setState({ gridPower, gridPowerUnits, gridPowerRaw });
      this.setState({ bldgPower, bldgPowerUnits, bldgPowerRaw });
      this.setState({ batteryPower, batteryPowerUnits, batteryPowerRaw });

      let solarProgressBar = 0;
      let batteryProgressBar = 0;
      if (this.state.bldgPower > 0) {
        solarProgressBar = (
          (this.state.pvPower * 100) /
          this.state.bldgPower
        ).toFixed(1);
        batteryProgressBar = (
          (this.state.batteryPower * 100) /
          this.state.bldgPower
        ).toFixed(1);
      } else {
        if (this.state.batteryPower > 0) {
          //if discharging
          solarProgressBar = (
            (parseFloat(this.state.pvPower) * 100) /
            (parseFloat(this.state.pvPower) +
              parseFloat(this.state.batteryPower))
          ).toFixed(1);
          batteryProgressBar = (
            (parseFloat(this.state.batteryPower) * 100) /
            (parseFloat(this.state.pvPower) +
              parseFloat(this.state.batteryPower))
          ).toFixed(1);
        } else {
          //if charging
          solarProgressBar = 100.0;
          batteryProgressBar = 0.0;
        }
      }

      this.setState({ solarProgressBar, batteryProgressBar });

      let pvEnergy = 0;
      let batteryEnergy = 0;
      if (pvEnergy && batteryEnergy) {
        pvEnergy = this.state.Interval.pvEnergy / 100;
        batteryEnergy = this.state.Interval.batteryEnergy / 100;
      }

      this.setState({
        pvEnergy,
        batteryEnergy,
      });

      this.setState({
        soc: (
          (this.state.realTime.batteryPower / this.state.realTime.pvPower) *
          100
        ).toFixed(1),
      });
      this.setState({ loopCount, MeshId, moduleDataValid });
      this.setState({ slaveCount: this.state.realTime.slaveCount });
      this.setState({ smMeter1, smMeter2 });
    }
  }

  sigFigures(powerDollar) {
    let sigNumber = 0;
    if (powerDollar < 100) sigNumber = powerDollar.toFixed(2);
    else if (powerDollar < 1000) sigNumber = powerDollar.toFixed(1);
    else
      sigNumber = powerDollar
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.setState({ sigNumber });
  }

  powerNunits(power) {
    let powerUnits = "Watts";
    let powerAbs = Math.abs(power);
    if (powerAbs < 100) {
      if (power >= 0) power = power.toFixed(2);
      else power = power.toFixed(1);
      powerUnits = "Watts";
    } else if (powerAbs < 1000) {
      if (power >= 0) power = power.toFixed(1);
      else power = power.toFixed(0);
      powerUnits = "Watts";
    } else if (powerAbs < 10000) {
      if (power >= 0) power = (power / 1000).toFixed(3);
      else power = (power / 1000).toFixed(2);
      powerUnits = "kW";
    } else if (powerAbs < 100000) {
      if (power >= 0) power = (power / 1000).toFixed(2);
      else power = (power / 1000).toFixed(1);
      powerUnits = "kW";
    } else if (powerAbs < 1000000) {
      if (power >= 0) power = (power / 1000).toFixed(1);
      else power = (power / 1000).toFixed(0);
      powerUnits = "kW";
    } else if (powerAbs < 10000000) {
      if (power >= 0) power = (power / 1000000).toFixed(3);
      else power = (power / 1000000).toFixed(2);
      powerUnits = "MW";
    } else if (powerAbs < 100000000) {
      if (power >= 0) power = (power / 1000000).toFixed(2);
      else power = (power / 1000000).toFixed(1);
      powerUnits = "MW";
    } else if (powerAbs < 1000000000) {
      if (power >= 0) power = (power / 1000000).toFixed(1);
      else power = (power / 1000000).toFixed(0);
      powerUnits = "MW";
    } else if (powerAbs < 10000000000) {
      if (power >= 0) power = (power / 1000000000).toFixed(3);
      else power = (power / 1000000000).toFixed(2);
      powerUnits = "GW";
    }
    this.setState({ power, powerUnits });
  }
  tick() {
    this.setState({
      date: new Date(),
    });
    this.getSystemData();
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "100%",
            position: "absolute",
            marginTop: "150px",
            marginBottom: "350px",
          }}
        >
          {this.state.dataFrequency && (
            <div>
              <table
                className="table2"
                style={{
                  position: "relative",
                  width: "200px",
                  transform: "translateX(-50%)",
                  left: "50%",
                  marginRight: "0px",
                  marginBottom: "15px",
                }}
              >
                <td>
                  <Link
                    to={`/systems/simplNodesVu/${this.state.handleID}/${this.state.companyName}/
                    ${this.state.systemName}/${this.state.serialNumber}/${this.props.match.params.userRole}`}
                  >
                    <FontAwesomeIcon
                      icon="arrow-alt-circle-left"
                      size="sm"
                      color="magenta"
                      title="Simpl dashboard"
                    />
                  </Link>
                </td>
                <td>
                  <button
                    className="btn btn-outline-light"
                    style={{
                      padding: "0px",
                      float: "right",
                      marginRight: "5px",
                      position: "relative",
                      right: "0px",
                    }}
                    onClick={this.handlePlay}
                  >
                    <FontAwesomeIcon
                      icon="play"
                      size="sm"
                      color="green"
                      title="play chart"
                    />
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-outline-light"
                    style={{
                      padding: "0px",
                      float: "right",
                      marginRight: "5px",
                      position: "relative",
                      right: "0px",
                    }}
                    onClick={this.handlePause}
                  >
                    <FontAwesomeIcon
                      icon="pause"
                      size="sm"
                      color="red"
                      title="pause chart"
                    />
                  </button>
                </td>

                <td>
                  <SummaryModal
                    pvPower={this.state.pvPower}
                    batteryPower={this.state.batteryPower}
                    pvPowerUnits={this.state.pvPowerUnits}
                    batteryPowerUnits={this.state.batteryPowerUnits}
                  />
                </td>
                <td>
                  <ChartPointsModal chartPoints={this.state.chartPoints} />
                </td>
                <td>
                  <SystemInfoModal
                    loopCount={this.state.loopCount}
                    slaveCount={this.state.slaveCount}
                    companyName={this.state.companyName}
                  />
                </td>
              </table>
              {this.state.demandLimit &&
                this.state.systemConfiguration === "8" && (
                  <DemandGraph
                    handleID={this.state.handleID}
                    systemConfiguration={this.state.systemConfiguration}
                    demandLimit={this.state.demandLimit}
                    startDate={this.props.match.params.startDate}
                    endDate={this.props.match.params.endDate}
                  />
                )}
              <AggregateGraph
                handleID={this.state.handleID}
                systemConfiguration={this.state.systemConfiguration}
                pvPower={this.state.pvPowerRaw}
                batteryPower={this.state.batteryPowerRaw}
                bldgPower={this.state.bldgPowerRaw}
                gridPower={this.state.gridPowerRaw}
                dataFrequency={this.state.dataFrequency}
                playPause={this.state.play}
              />
              {(this.state.slaveCount !== 0 ||
                this.state.systemConfiguration === "3") && (
                <IntervalGraph
                  handleID={this.state.handleID}
                  systemConfiguration={this.state.systemConfiguration}
                  startDate={this.props.match.params.startDate}
                  endDate={this.props.match.params.endDate}
                  slaveCount={this.state.slaveCount}
                  attachedSimplModuleSN={this.state.attachedSimplModuleSN}
                  attachedSimplModuleHandle={
                    this.state.attachedSimplModuleHandle
                  }
                  moduleDataValid={this.state.moduleDataValid}
                />
              )}
              {(this.state.slaveCount !== 0 ||
                this.state.systemConfiguration === "3") && (
                <MoneyGraph
                  handleID={this.state.handleID}
                  systemConfiguration={this.state.systemConfiguration}
                  startDate={this.props.match.params.startDate}
                  endDate={this.props.match.params.endDate}
                />
              )}{" "}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default DashboardChart;

class SystemInfoModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <>
        <button
          className="btn btn-outline-light"
          style={{
            padding: "0px",
            float: "right",
            marginRight: "5px",
            position: "relative",
            right: "0px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="info-circle"
            size="sm"
            color="dodgerblue"
            title="System Information"
          />
        </button>

        <Modal
          style={{ width: "200px", alignContent: "center" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "14px" }}
            >
              System at a glance:
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Loop count since restart:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.loopCount}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Number of Mesh Nodes:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {" "}
              {this.props.slaveCount}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Company Name:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {" "}
              {this.props.companyName}
            </p>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
//this.props.chartPoints
class ChartPointsModal extends Form {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: { chartPoints: localStorage.getItem("pointsOnChart") },
      errors: {},
    };

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  schema = {
    chartPoints: Joi.number().required().label("Chart Points"),
  };

  handleChange = ({ currentTarget: input }) => {
    localStorage.setItem("pointsOnChart", input.value);
    this.setState({ chartPoints: input.value });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-light"
          style={{
            padding: "0px",
            float: "right",
            marginRight: "5px",
            position: "relative",
            right: "0px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="arrows-alt-h"
            size="sm"
            color="tomato"
            title="points in chart"
          />
        </button>

        <Modal
          style={{ width: "150px", fontSize: "12px", color: "#2d5c88" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "14px" }}
            >
              Chart Points:
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <h6>Enter number of points on chart:</h6>

            <select
              onChange={this.handleChange}
              value={localStorage.getItem("pointsOnChart")}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
              <option value="2000">2000</option>
              <option value="5000">5000</option>
            </select>
            <h6 />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

class SummaryModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <>
        <button
          className="btn btn-outline-light"
          style={{
            padding: "0px",
            float: "right",
            marginRight: "5px",
            position: "relative",
            right: "0px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="th-large"
            size="sm"
            color="brown"
            title="System Summary Data"
          />
        </button>

        <Modal
          style={{ width: "0px" }}
          show={this.state.show}
          onHide={this.handleHide}
          //dialogClassName="modal-50w"
          //aria-labelledby="example-custom-modal-styling-title"
        >
          {/*<Modal.Header style={{ width: "0px", height: "0px" }}>
            <Modal.Title id="example-custom-modal-styling-title" />
          </Modal.Header>*/}

          <Modal.Body
            style={{
              width: "325px",
              position: "fixed",
              left: "50%",
              transform: "translateX(-50%)",
              height: "500px",
            }}
          >
            <div className="containerSimplView">
              <div className="mainMenuContainer">
                <button
                  className="mainMenu"
                  style={{ backgroundColor: "orange", borderColor: "orange" }}
                >
                  <p
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "10px",
                      textAlign: "left",
                      marginBottom: "1px",
                    }}
                  >
                    Solar Power:
                  </p>
                  <p>
                    {this.props.pvPower}
                    <span
                      style={{
                        color: "white",
                        fontWeight: "normal",
                        fontSize: "10px",
                        textAlign: "right",
                        marginBottom: "2px",
                      }}
                    >
                      {" "}
                      {this.props.pvPowerUnits}{" "}
                    </span>
                  </p>{" "}
                </button>

                <button
                  className="mainMenu"
                  style={{
                    backgroundColor: "dodgerblue",
                    borderColor: "dodgerblue",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "10px",
                      textAlign: "left",
                      marginBottom: "1px",
                    }}
                  >
                    Battery Power:
                  </p>
                  <p>
                    {this.props.batteryPower}
                    <span
                      style={{
                        color: "white",
                        fontWeight: "normal",
                        fontSize: "10px",
                        textAlign: "right",
                        marginBottom: "2px",
                      }}
                    >
                      {" "}
                      {this.props.batteryPowerUnits}{" "}
                    </span>
                  </p>{" "}
                </button>
                <button
                  className="mainMenu"
                  style={{ backgroundColor: "gray", borderColor: "gray" }}
                >
                  <p
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "10px",
                      textAlign: "left",
                      marginBottom: "1px",
                    }}
                  >
                    Grid Power:
                  </p>
                  <p>
                    {`${(this.props.pvPower * 1.7).toFixed(0)}`}
                    <span
                      style={{
                        color: "white",
                        fontWeight: "normal",
                        fontSize: "10px",
                        textAlign: "right",
                        marginBottom: "2px",
                      }}
                    >
                      {" "}
                      Watts
                    </span>
                  </p>{" "}
                </button>
                <button
                  className="mainMenu"
                  style={{ backgroundColor: "green", borderColor: "green" }}
                >
                  <p
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "10px",
                      textAlign: "left",
                      marginBottom: "1px",
                    }}
                  >
                    Building Power:
                  </p>
                  <p>
                    {`${(this.props.pvPower * 1.7).toFixed(0)}`}
                    <span
                      style={{
                        color: "white",
                        fontWeight: "normal",
                        fontSize: "10px",
                        textAlign: "right",
                        marginBottom: "2px",
                      }}
                    >
                      {" "}
                      Watts
                    </span>
                  </p>{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
