import React from "react";

const GatewaySvg = (props) => (
  <svg
    style={{ margin: "0px", padding: "0px", height: "90px", width: "200px" }}
  >
    <svg
      height="200px"
      width="auto"
      id="ICONS"
      version="1.1"
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <g>
        <text
          x="1"
          y="10"
          font-family="Verdana"
          font-size="6"
          font-weight="normal"
          fill={props.textColor}
        >
          Gateway
        </text>
      </g>
    </svg>
    <svg
      height="auto"
      width="50"
      version="1.1"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      stroke={props.strokeColor1}
      fill={props.strokeColor1}
    >
      <g>
        <rect height="3" width="9" x="6.2" y="44.5" />
        <rect height="3" width="9" x="32.9" y="44.5" />
        <path d="M13,11.5c0-0.8-0.7-1.5-1.5-1.5S10,10.7,10,11.5v11.7H0.5v20h47v-20H13V11.5z M44.5,40.3h-41v-14h41V40.3z" />
        <path d="M11.5,5C8,5,5,8,5,11.5C5,12.4,5.7,13,6.5,13S8,12.4,8,11.5C8,9.6,9.6,8,11.5,8S15,9.6,15,11.5c0,0.8,0.7,1.5,1.5,1.5   s1.5-0.7,1.5-1.5C18,8,15.1,5,11.5,5z" />
        <path d="M21.1,13c0.8,0,1.5-0.7,1.5-1.5c0-6.1-5-11-11-11s-11,5-11,11C0.5,12.4,1.2,13,2,13s1.5-0.7,1.5-1.5c0-4.4,3.6-8,8-8   s8,3.6,8,8C19.6,12.4,20.3,13,21.1,13z" />
        <rect height="3" width="4.1" x="8.4" y="31.8" />
        <path d="M36,38.4c2.8,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2s-5.2,2.3-5.2,5.2S33.2,38.4,36,38.4z M36,31.1c1.2,0,2.2,1,2.2,2.2   s-1,2.2-2.2,2.2s-2.2-1-2.2-2.2S34.9,31.1,36,31.1z" />
      </g>
    </svg>
  </svg>
);
export default GatewaySvg;
