import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { getUser, getUserSystems } from "../services/userService";
import { setJwt } from "../services/httpService";
import { getCompanySystems } from "../services/companyService";
import {
  getSystemIntervalData,
  getSystemLatestData,
  ws_getSystemLatestData,
  getSystem,
  getSystemSignals,
  setSystemSignals,
  getSystemIDfromHandle,
  initSystemUpdate,
  getSystemInfo,
  getBellColors,
  checkConnectionInfo,
  getSystemTasks,
  getSystemBasics,
  findIdByMac,
} from "../services/systemService";

import "./dashboard.css";
import "../index.css";
import {
  DEACTIVATE_SLAVE,
  REACTIVATE_SLAVE,
  LOCATOR_ON,
  LOCATOR_OFF,
  SLAVE_RESET,
  SLAVE_FACTORY_RESET,
  SLAVE_HIBERNATE,
  CALIBRATE,
  UPLOAD_FW,
} from "../config.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import BackGroundRectangle from "../components/backgroundRect";
import { webSocketMode } from "../config.json"; //0: http; 1:websocket
import jwtDecode from "jwt-decode";
import { isNullOrUndefined } from "joi-browser";
import { animateScroll as scroll } from "react-scroll";
import { ScrollView } from "@cantonjs/react-scroll-view";

import TstatSettingsModal from "./TstatSettingsModal";
import MainMenuWide from "./mainMenuWide";
import SimplFooterWide from "./simplFooterWide";
import { duration } from "@material-ui/core/styles/transitions";

class ListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slaves: [],
      acuvimNumbers: {},
      realTime: {},
      companyName: "",
      systemID: "",
      selectedHandleID: 0,
      activeState: true,
      locatorEnabled: false,
      systemStatus: "",
      dataVerbosity: 1,
      systemConfiguration: "0",
      openTstatSettingsModal: [],
      username: "unknown",
      connection: 0,
      closedTasks: true,
    };
    //this.getSystemData = this.getSystemData.bind(this);
  }

  async componentDidMount() {
    const companyName = this.props.match.params.companyName;
    console.log("Company Name", companyName);
    const userRole = this.props.match.params.userRole;
    console.log("User Role: ", userRole);
    let allRelevantystems = [];
    ///////////////////////////////////////////////////////////////////////////
    if (userRole === "ownerOf") {
      allRelevantystems = (await getUserSystems()).data.data.ownerOf;
    } else {
      allRelevantystems = (await getCompanySystems(companyName)).data.data;
    }
    const activeCompanySystems = allRelevantystems.filter(
      (thisSystem) =>
        thisSystem.active === true &&
        thisSystem.systemBasics.systemConfiguration == "8"
    );
    console.log("List View Company Systems: ", activeCompanySystems);
    let selectedHandleID = this.props.match.params.systemHandleID;
    console.log(
      "Selected Handle ID from Company systems:........................",
      selectedHandleID
    );
    if (selectedHandleID === "0") {
      selectedHandleID = activeCompanySystems[0].systemID.id;
      // selectedSystemName = activeCompanySystems[0].systemID.name;
    }

    console.log("Handle ID in CDM", selectedHandleID);

    const systemID = (await getSystemIDfromHandle(selectedHandleID)).data.data
      .system_id;
    const system = (await getSystem(systemID)).data.data[0];
    const systemName = system.systemID.name;
    console.log("............................   System Name : ", systemName);
    const systemSignals = (await getSystemSignals(selectedHandleID)).data.data
      .signals;
    if (systemSignals) this.setState({ data: systemSignals });
    const systemInfo = await getSystemInfo(selectedHandleID);
    console.log("System Info: ", systemInfo);
    const systemConfiguration = systemInfo.data.data.basics.systemConfiguration;
    console.log("System Configuration", systemConfiguration);
    const dataVerbosity = systemInfo.data.data.basics.dataVerbosity;
    console.log("System dataVerbosity", dataVerbosity);

    let i = 0;
    let openTstatSettingsModal = this.state.openTstatSettingsModal;
    for (i = 0; i < 50; i++) openTstatSettingsModal[i] = false;
    const displayWidth = "Wide";
    this.setState({
      systemID,
      systemName,
      companyName,
      systemConfiguration,
      dataVerbosity,
      openTstatSettingsModal,
      activeCompanySystems,
      selectedHandleID,
      displayWidth,
      userRole,
    });
    this.timerID = setInterval(() => this.tick(), 1000);

    let bellsColorsObj = {};
    const username = await getUser();

    if (username) {
      this.setState({ username: username.data.data.username });
      bellsColorsObj = (
        await getBellColors(selectedHandleID, username.data.data.emailAddress)
      ).data.data;
    }

    this.setState({ bellsColorsObj });

    scroll.scrollToTop();
    const pageWidth =
      (document.body.scrollWidth - document.body.clientWidth) / 2;
    scroll.scrollTo(pageWidth, { horizontal: true });
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  async getSystemData() {
    let responseRaw = {};
    console.log("Selected Handle ID: ", this.state.selectedHandleID);
    const systemID = (await getSystemIDfromHandle(this.state.selectedHandleID))
      .data.data.system_id;
    const system = (await getSystem(systemID)).data.data[0];
    console.log("System: ", system);
    const systemSN = system.systemID.SN;
    const systemName = system.systemID.name;
    console.log("System Name : ", systemName);

    const dataTimeInvalid = (
      await checkConnectionInfo(this.state.selectedHandleID)
    ).data.data.Connection;
    let connection = this.state.connection;
    if (
      dataTimeInvalid === null ||
      dataTimeInvalid === 2 ||
      dataTimeInvalid === 1
    ) {
      connection = 2;
    } else if (dataTimeInvalid === 0) {
      connection = 0;
    }
    this.setState({ connection });
    this.setState({ systemName, systemSN });

    const tasks = (await getSystemTasks(this.state.selectedHandleID)).data
      .data[0].tasks;

    console.log("Tasks:");
    console.log(tasks);

    const closedTasks = this.searchClosedTasks(tasks, this.state.username);

    this.setState({ closedTasks });

    if (webSocketMode) {
      responseRaw = ws_getSystemLatestData(
        this.state.selectedHandleID
        /*this.props.match.params.systemHandleID*/
      );
      console.log("Websocket: Latest Data", responseRaw);
    } else {
      responseRaw = await getSystemLatestData(
        this.state.selectedHandleID
        /*this.props.match.params.systemHandleID*/
      );
      console.log("Http: Latest Data", responseRaw);
    }
    const systemInfo = await getSystemInfo(this.state.selectedHandleID);
    const systemStatus = systemInfo.data.data.status;
    this.setState({ systemStatus });
    const response = responseRaw.data.data[0];
    console.log("System Response Data", response);
    console.log("System Config before if:", this.state.systemConfiguration);
    /////////////////////////////////////////////////////////////////////////////////////////////
    const realTimePower = response.data.deviceLedger.realTime.AggPower1;
    const intervalPower = response.data.deviceLedger.Interval.thisInterval_1;
    const globalPriority = response.data.deviceLedger.realTime.globalPriority;
    const peakPower = response.data.deviceLedger.realTime.peakPower;
    this.setState({ realTimePower, intervalPower, globalPriority, peakPower });
    ////////////////////////////////////////////////////////////////////////////////////////
    const systemTimeZone = parseInt(
      response.data.deviceLedger.realTime.TimeZone
    );
    const timeStampLatestData = parseInt(response.timestamp / 1000);
    console.log("Latest Data's Time Stamp", timeStampLatestData);
    const epochLocalTime = Math.round(new Date().getTime() / 1000);
    console.log("Epoch Time", epochLocalTime);
    const systemTime = parseInt(
      response.data.deviceLedger.realTime.SystemLocalTimeSeconds
    );
    console.log("System Time:", systemTime);
    console.log("System Time Zone:", systemTimeZone);
    let sysT = new Date((systemTime - systemTimeZone * 3600) * 1000);
    const systemTimeOffset = systemTimeZone * 60 * 60 * 1000;
    const userTimeOffset = sysT.getTimezoneOffset() * 60 * 1000;
    sysT = new Date(sysT.getTime() + userTimeOffset + systemTimeOffset);

    let hourTime = sysT.getHours();
    if (hourTime < 10) hourTime = "0" + hourTime;
    let minuteTime = sysT.getMinutes();
    if (minuteTime < 10) minuteTime = "0" + minuteTime;
    let secTime = sysT.getSeconds();
    let dayTime = sysT.getDay();
    if (secTime < 10) secTime = "0" + secTime;
    const systemHumanTime = [hourTime, minuteTime, secTime].join(":");
    console.log("System Human Time:", systemHumanTime);
    this.setState({ dayTime, systemHumanTime });
    if (response !== undefined && response !== null) {
      const commMode = response.data.commMode;
      console.log("Comm Mode", commMode);
      const wifiSignalStrength = response.data.deviceLedger.realTime.RSSI;
      //console.log("Wifi Signal Strength", wifiSignalStrength);

      const slaveCount = response.data.deviceLedger.realTime.slaveCount;
      this.setState({ commMode, wifiSignalStrength, slaveCount });
    }
    ///////////////////////////////////////////////////////////////////////////////
    const endDate = new Date().getTime() - 10;
    const startDate = endDate - 900 /*this.state.intervalPeriod*/ * 1.2 * 1000;
    const systemIntervalDataInterim = await getSystemIntervalData(
      this.state.selectedHandleID,
      startDate,
      endDate
    );
    //////////////////////////////////////////////////////////////////////////////////////
    if (
      systemIntervalDataInterim !== null &&
      systemIntervalDataInterim !== undefined
    ) {
      const dataLength = systemIntervalDataInterim.data.data.length;
      if (dataLength > 0) {
        const weatherIcon =
          systemIntervalDataInterim.data.data[0].data.weatherIcon;
        console.log("Weather Icon: ", weatherIcon);
        const weatherTemp =
          systemIntervalDataInterim.data.data[0].data.weatherTemp;
        const weatherRH = systemIntervalDataInterim.data.data[0].data.weatherRH;
        this.setState({ weatherIcon, weatherTemp, weatherRH });
      }
    }
    ////////////////////////////////////////////////////////////////////////////////////////
    if (response !== null && response !== undefined) {
      console.log("Response in the if:", response);
      const appliances = response.data.deviceLedger.Appliances;
      console.log("Appliances: ", appliances);
      this.setState({ appliances });

      const applianceCount = appliances.length;
      console.log("Appliance Count", applianceCount);
      for (let i = 0; i < applianceCount; i++) {
        if (
          appliances[i].loadType === "HVAC w SimplTherm" &&
          (appliances[i].roomRH === undefined || appliances[i].roomRH === null)
        ) {
          appliances[i].roomRH = appliances[i].rhReturn;
        }
      }
      this.setState({ applianceCount });
    }
  }

  tick() {
    this.setState({
      dateNow: new Date(),
    });
    this.getSystemData();
  }
  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }
  cancelOverRide = async (nodeName) => {
    console.log(
      "************************  Cancel Override. Node Name: ",
      nodeName
    );
    const thisSystemID = (
      await getSystemIDfromHandle(this.state.selectedHandleID)
    ).data.data.system_id;
    console.log("This System ID: ", thisSystemID);
    toast.warning("Canceling Override...wait...");
    await setSystemSignals(thisSystemID, "81", "TBD", "0", nodeName, "40");

    await initSystemUpdate(this.state.selectedHandleID);

    this.sleep(10000);
    toast.success("Override was canceled...");
  };
  changeThermostatSetting = async (tSet, durationTime, nodeName) => {
    const thisSystemID = (
      await getSystemIDfromHandle(this.state.selectedHandleID)
    ).data.data.system_id;
    let durationTimeInt = parseInt(durationTime);
    if (durationTimeInt < 5) durationTimeInt = 5;
    const durationTimeStr = durationTimeInt.toString();
    console.log("This System ID: ", thisSystemID);
    toast.warning("Sending settings to the system...wait...");
    let c = await setSystemSignals(
      thisSystemID,
      "81",
      "TBD",
      durationTimeStr,
      nodeName,
      tSet,
      this.state.username
    );
    console.log("This is tSet value: ", tSet);
    console.log("Response of chaning in thermostat signals: ", c);
    await initSystemUpdate(this.state.selectedHandleID);

    this.sleep(10000);
    await setSystemSignals(thisSystemID, "81", "TBD", "0", nodeName, tSet);
    await initSystemUpdate(this.state.selectedHandleID);
    this.sleep(15000);
    toast.success("Settings were updated...");
  };
  handleSystemChange = async (e) => {
    const selectedHandleID = e.currentTarget.value;
    console.log(
      "..........................Selected Handle ID:",
      selectedHandleID
    );
    const systemID = (await getSystemIDfromHandle(selectedHandleID)).data.data
      .system_id;
    const system = (await getSystem(systemID)).data.data[0];
    const systemSN = system.systemID.SN;
    const systemName = system.systemID.name;
    //this.props.history.push(`/systems/simplNodesVuWide/${selectedHandleID}/${this.props.match.params.companyName}/${systemSN}/${systemName}/${this.props.match.params.userRole}`);
    this.props.history.replace(
      `/systems/listView/${selectedHandleID}/${this.props.match.params.companyName}/${this.props.match.params.userRole}`
    );

    this.setState({ selectedHandleID });
  };

  handleNodeClick = (nodeName) => {
    let nodePassed = nodeName;
    console.log("This is the Node Name: ", nodeName);

    if (nodeName === "Canopy Lights South/Stars")
      nodePassed = nodeName.replace("/", "-");

    // console.log("Node Passed", nodePassed);

    // str = `/systems/simplNodesControls/${props.handleID}/${props.companyName}/${props.userRole}/${props.displayWidth}`
    this.props.history.push(
      `/systems/simplNodesControls/${this.state.selectedHandleID}/${this.props.match.params.companyName}/${this.state.userRole}/${this.state.displayWidth}/${nodePassed}`
    );
  };

  searchClosedTasks = (tasks, username) => {
    try {
      if (!tasks || tasks.length === 0) {
        return true;
      }
      const userTasks = [];

      for (const element of tasks) {
        const userReply = element.replies.find((reply) => {
          return reply.assignee === username;
        });

        if (element.assignee === username || userReply) {
          userTasks.push(element);
        }
      }

      for (const task of userTasks) {
        const repliesFiltered = task.replies.filter((reply) => {
          return reply.status === "Closed";
        });

        if (task.status !== "Closed" && repliesFiltered.length === 0) {
          return false;
        }
      }

      return true;
    } catch (e) {
      console.log("Error:");
      console.log(e);

      return false;
    }
  };

  render() {
    return (
      <React.Fragment>
        <ScrollView
          style={{
            height: "200vh",
            width: "190vh",
          }}
          horizontal={true}
        >
          <div
            style={{
              width: "1325px",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              marginTop: "120px",
              marginBottom: "150px",
              paddingBottom: "100px",
              overflowY: "scrollY",
              zIndex: 1,
            }}
          >
            {this.state.activeCompanySystems !== null &&
              this.state.activeCompanySystems !== undefined && (
                <div>
                  <select
                    className="form-control2"
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      padding: "0px",
                      width: "180px",
                      height: "30px",
                    }}
                    value={this.state.selectedHandleID}
                    onChange={this.handleSystemChange}
                    selected={this.state.selectedHandleID}
                  >
                    <option value={this.state.selectedHandleID}>
                      {this.state.systemName}
                    </option>
                    {this.state.activeCompanySystems.map((option) => (
                      <option value={option.systemID.id}>
                        {option.systemID.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            <div style={{ transform: "translate(160px, -42px)" }}>
              <MainMenuWide
                systemName={this.state.systemName}
                systemHumanTime={this.state.systemHumanTime}
                commMode={this.state.commMode}
                wifiSignalStrength={this.state.wifiSignalStrength}
                slaveCount={this.state.slaveCount}
                loopCount={this.state.loopCount}
                outageFlag={this.state.outageFlag}
                acCoupledBackUp={this.state.acCoupledBackUp}
                moduleDataValid={this.state.moduleDataValid}
                systemConfiguration={this.state.systemConfiguration}
                inCompleteData={this.state.inCompleteData}
                weatherIcon={this.state.weatherIcon}
                weatherTemp={this.state.weatherTemp}
                weatherRH={this.state.weatherRH}
                realTimePower={this.state.realTimePower}
                intervalPower={this.state.intervalPower}
                globalPriority={this.state.globalPriority}
                peakPower={this.state.peakPower}
              />
            </div>

            {(this.state.systemConfiguration === null ||
              this.state.systemConfiguration === undefined ||
              this.state.applianceCount === null ||
              this.state.applianceCount === undefined ||
              this.state.dataVerbosity === null ||
              this.state.dataVerbosity === undefined) && (
              <div>
                <p
                  className="basicParagraph"
                  style={{ width: "1325px", textAlign: "center" }}
                >
                  {" "}
                  No Active subsystem is detected in the system...
                </p>
                <p
                  className="basicParagraph"
                  style={{ width: "1325px", textAlign: "center" }}
                >
                  {" "}
                  Please stand by as connections are being established.
                </p>
              </div>
            )}
            {this.state.dataVerbosity === "3" &&
              this.state.systemConfiguration === "8" &&
              parseInt(this.state.applianceCount) > 0 && (
                <div>
                  <table
                    className="tableThermostat"
                    style={{
                      width: "1325px",
                      position: "absolute",
                      left: "50%",
                      top: "30px",
                      marginTop: "10px",
                      transform: "translateX(-50%)",
                    }}
                  >
                    <div>
                      <thead>
                        <tr
                          style={{
                            backgroundColor: "#777575",
                            marginTop: "50px",
                          }}
                        >
                          <th
                            colSpan="2"
                            style={{
                              color: "#ffc000",
                              borderRight: "2px",
                              borderRightColor: "#c2bfbf",
                              borderRightStyle: "solid",
                              borderLeft: "none",
                              fontSize: "15px",
                            }}
                          >
                            System
                          </th>
                          <th
                            colSpan="4"
                            style={{
                              color: "#ffc000",
                              borderRight: "2px",
                              borderRightColor: "#c2bfbf",
                              borderRightStyle: "solid",
                              fontSize: "15px",
                            }}
                          >
                            Settings
                          </th>
                          <th
                            colSpan="6"
                            style={{
                              color: "#ffc000",
                              borderRight: "2px",
                              borderRightColor: "#c2bfbf",
                              borderRightStyle: "solid",
                              fontSize: "15px",
                            }}
                          >
                            Measurements
                          </th>

                          <th
                            colSpan="6"
                            style={{
                              color: "#ffc000",
                              borderRight: "2px",
                              borderRightColor: "#c2bfbf",
                              borderRightStyle: "solid",
                              fontSize: "15px",
                            }}
                          >
                            Operation
                          </th>
                          <th
                            colSpan="5"
                            style={{
                              color: "#ffc000",
                              fontSize: "15px",
                              borderRight: "none",
                            }}
                          >
                            Peak Power Management
                          </th>
                        </tr>
                        <tr
                          style={{
                            backgroundColor: "#ece9e9",
                            marginTop: "50px",
                          }}
                        >
                          <th
                            style={{
                              width: "105px",
                              borderRight: "none",
                              borderLeft: "none",
                            }}
                          >
                            Name
                          </th>
                          <th
                            style={{
                              width: "50px",
                              borderLeft: "none",
                            }}
                          >
                            Info
                          </th>
                          <th width="30px" style={{ borderRight: "none" }}>
                            Occupd
                          </th>
                          <th
                            width="70px"
                            style={{ borderRight: "none", borderLeft: "none" }}
                          >
                            Set Temp.
                          </th>
                          <th
                            width="35px"
                            style={{ borderRight: "none", borderLeft: "none" }}
                          >
                            Fan
                          </th>
                          <th
                            style={{
                              borderLeft: "none",
                              width: "35px",
                            }}
                          >
                            Mode
                          </th>
                          <th
                            style={{
                              width: "60px",
                              textAlign: "center",
                              borderRight: "none",
                              marginLeft: "5px",
                              paddingLeft: "5px",
                            }}
                          >
                            Return
                          </th>
                          <th
                            style={{
                              width: "60px",
                              textAlign: "center",
                              borderRight: "none",
                              borderLeft: "none",
                            }}
                          >
                            Supply
                          </th>
                          <th
                            style={{
                              width: "60px",
                              textAlign: "center",
                              borderRight: "none",
                              borderLeft: "none",
                            }}
                          >
                            Economizer{" "}
                          </th>
                          <th
                            style={{
                              width: "60px",
                              textAlign: "center",
                              borderRight: "none",
                              borderLeft: "none",
                            }}
                          >
                            Outside
                          </th>
                          <th
                            style={{
                              width: "90px",
                              textAlign: "center",
                              borderRight: "none",
                              borderLeft: "none",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                          >
                            <p>
                              Door
                              <hr
                                style={{ margin: "1px", padding: "1px" }}
                              ></hr>
                              External Temp
                            </p>
                          </th>
                          <th
                            style={{
                              width: "90px",
                              textAlign: "center",
                              borderLeft: "none",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                          >
                            <p>
                              Light
                              <hr
                                style={{ margin: "1px", padding: "1px" }}
                              ></hr>
                              Room Temp
                            </p>
                          </th>

                          <th
                            style={{
                              width: "40px",
                              textAlign: "center",
                              borderRight: "none",
                              margin: "10px",
                              padding: "10px",
                            }}
                          >
                            Cool1 Rly1
                          </th>
                          <th
                            style={{
                              width: "40px",
                              textAlign: "center",
                              margin: "10px",
                              padding: "10px",
                            }}
                          >
                            Heat1 Rly2
                          </th>
                          <th
                            style={{
                              width: "40px",
                              textAlign: "center",
                              borderRight: "none",
                              borderLeft: "none",
                              margin: "10px",
                              padding: "10px",
                            }}
                          >
                            Cool2 Rly3
                          </th>
                          <th
                            style={{
                              width: "40px",
                              textAlign: "center",
                              borderRight: "none",
                              borderLeft: "none",
                              margin: "10px",
                              padding: "10px",
                            }}
                          >
                            Heat2 Rly4
                          </th>
                          <th
                            style={{
                              width: "40px",
                              textAlign: "center",
                              borderRight: "none",
                              borderLeft: "none",
                              margin: "10px",
                              padding: "10px",
                            }}
                          >
                            Fan Rly5
                          </th>
                          <th
                            style={{
                              width: "55px",
                              textAlign: "center",

                              borderLeft: "none",
                            }}
                          >
                            Health
                          </th>
                          <th
                            style={{
                              width: "85px",
                              textAlign: "center",
                              borderRight: "none",
                            }}
                          >
                            Min/Max
                          </th>
                          <th
                            style={{
                              width: "45px",
                              textAlign: "center",
                              borderRight: "none",
                              borderLeft: "none",
                            }}
                          >
                            Power
                          </th>
                          <th
                            style={{
                              width: "45px",
                              textAlign: "center",
                              borderRight: "none",
                              borderLeft: "none",
                            }}
                          >
                            Priority
                          </th>
                          <th
                            style={{
                              width: "60px",
                              textAlign: "center",
                              borderRight: "none",
                              borderLeft: "none",
                            }}
                          >
                            Sheddable
                          </th>
                          <th
                            style={{
                              width: "45px",
                              textAlign: "center",
                              borderRight: "none",
                              borderLeft: "none",
                            }}
                          >
                            Shed Status
                          </th>
                        </tr>
                      </thead>
                    </div>
                    <ScrollView
                      horizontal={true}
                      style={{
                        height: "200vh",
                        overflowY: "scrollY",
                        maxHeight: "400px",
                      }}
                    >
                      {this.state.appliances.map((appliance, index) => (
                        <div className="thermostatRow">
                          <tr
                            key={appliance.id}
                            style={{
                              margin: "0px",
                              padding: "0px",
                              height: "15px",
                              width: "1350px",
                              //backgroundColor: "#393939",
                            }}
                          >
                            <td
                              style={{
                                width: "110px",
                                textAlign: "left",
                                position: "relative",
                                left: "5px",
                                height: "15px",
                                textDecoration: "underline",
                                cursor: "pointer",
                                color: appliance.meshOK === 1 ? "black" : "red",
                              }}
                              onClick={() =>
                                this.handleNodeClick(`${appliance.nodeName}`)
                              }
                            >
                              {appliance.nodeName}
                            </td>
                            <td
                              style={{
                                width: "60px",
                                textAlign: "left",
                                position: "relative",
                                marginLeft: "0px",
                                paddingLeft: "0px",
                                //marginRight: "3px",
                                paddingRight: "0px",
                                left: "0px",
                                height: "15px",
                              }}
                            >
                              <button
                                className="btn btn-primary shadow-none"
                                style={{
                                  borderWidth: "0px",
                                  padding: "0px",
                                  //marginRight: "5px",
                                  paddingRight: "5px",
                                  color: "transparent",
                                  backgroundColor: "transparent",
                                  right: "0px",
                                }}
                              >
                                <Link
                                  to={{
                                    pathname: `/systems/simplAlarms/${this.state.selectedHandleID}/${appliance.nodeName}`,
                                  }}
                                >
                                  {this.state.bellsColorsObj[
                                    appliance.nodeName
                                  ] ? (
                                    <FontAwesomeIcon
                                      icon="bell"
                                      size="sm"
                                      color={
                                        this.state.bellsColorsObj[
                                          appliance.nodeName
                                        ]
                                      }
                                      title="Alarms"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon="bell"
                                      size="sm"
                                      color="grey"
                                      title="Alarms"
                                    />
                                  )}
                                </Link>
                              </button>
                              {appliance.loadType === "HVAC w SimplTherm" && (
                                <button
                                  className="btn btn-primary shadow-none"
                                  style={{
                                    borderWidth: "0px",
                                    padding: "0px",
                                    paddingRight: "5px",
                                    color: "transparent",
                                    backgroundColor: "transparent",
                                    right: "0px",
                                  }}
                                >
                                  <Link
                                    to={`/systems/gpLineChart/${this.state.selectedHandleID}/${this.props.match.params.companyName}/${this.state.systemName}/${appliance.nodeName}`}
                                  >
                                    <FontAwesomeIcon
                                      icon="chart-line"
                                      size="sm"
                                      color="orange"
                                      title="System Information"
                                    />
                                  </Link>
                                </button>
                              )}

                              {(appliance.loadType === "HVAC w SimplTherm" ||
                                appliance.loadType === "Relay Driver" ||
                                appliance.loadType === "Quad 30A Relays" ||
                                appliance.loadType === "Pole Lights" ||
                                appliance.loadType ===
                                  "Relay Driver w Light Sensor" ||
                                appliance.loadType ===
                                  "Quad 30A w Light Sensor" ||
                                appliance.loadType ===
                                  "Outdoor Sensed Lights" ||
                                appliance.loadType === "Indoor Lights" ||
                                appliance.loadType === "Heat Lamp" ||
                                appliance.loadType === "Space Heater" ||
                                appliance.loadType === "Water Heater" ||
                                appliance.loadType ===
                                  "Water Heater No Sensor" ||
                                appliance.loadType === "Reach-in" ||
                                appliance.loadType === "Counter Reach-in" ||
                                appliance.loadType === "Anti-sweat Heaters" ||
                                appliance.loadType === "EV Charger" ||
                                appliance.loadType === "Oven" ||
                                appliance.loadType === "Hood" ||
                                appliance.loadType === "Misc. Appliance" ||
                                appliance.loadType === "Misc. Sheddable") &&
                                appliance.VUattachedFlag === 1 && (
                                  <button
                                    className="btn btn-primary shadow-none"
                                    style={{
                                      borderWidth: "0px",
                                      padding: "0px",
                                      paddingRight: "5px",
                                      color: "transparent",
                                      backgroundColor: "transparent",
                                      right: "0px",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon="desktop"
                                      size="sm"
                                      color="dodgerblue"
                                      title="VU Attached"
                                    />
                                  </button>
                                )}

                              {/* For simplwalkins */}
                              {appliance.loadType === "SimplWalkIn" && (
                                <button
                                  className="btn btn-primary shadow-none"
                                  style={{
                                    borderWidth: "0px",
                                    padding: "0px",
                                    paddingRight: "5px",
                                    color: "transparent",
                                    backgroundColor: "transparent",
                                    right: "0px",
                                  }}
                                >
                                  <Link
                                    to={`/systems/gpWalkinChart/${this.state.selectedHandleID}/${this.props.match.params.companyName}/${this.state.systemName}/${appliance.nodeName}`}
                                  >
                                    <FontAwesomeIcon
                                      icon="chart-line"
                                      size="sm"
                                      color="orange"
                                      title="System Information"
                                    />
                                  </Link>
                                </button>
                              )}
                              {appliance.loadType !== "HVAC w SimplTherm" &&
                                appliance.loadType !== "SimplWalkIn" && (
                                  <button
                                    className="btn btn-primary shadow-none"
                                    style={{
                                      borderWidth: "0px",
                                      padding: "0px",
                                      paddingRight: "5px",
                                      color: "transparent",
                                      backgroundColor: "transparent",
                                      right: "0px",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon="chart-line"
                                      size="sm"
                                      color="gray"
                                      title="System Information"
                                    />
                                  </button>
                                )}
                            </td>
                            {appliance.onTime === 1 && (
                              <td
                                style={{
                                  width: "5px",
                                  textAlign: "center",

                                  position: "relative",
                                  left: "0px",
                                  height: "15px",
                                }}
                              >
                                Yes
                              </td>
                            )}
                            {appliance.onTime === 0 && (
                              <td
                                style={{
                                  width: "5px",
                                  textAlign: "center",
                                  position: "relative",
                                  left: "2px",
                                  height: "15px",
                                }}
                              >
                                No
                              </td>
                            )}
                            {/*88888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888*/}
                            {appliance.loadType === "HVAC w SimplTherm" && (
                              <td
                                style={{
                                  width: "70px",
                                  textAlign: "center",
                                  marginLeft: "10px",
                                  paddingLeft: "10px",
                                  position: "relative",
                                  left: "5px",
                                  height: "15px",
                                  color: "dodgerblue",
                                }}
                              >
                                <TstatSettingsModal
                                  applianceIndex={index}
                                  nodeName={appliance.nodeName}
                                  tSet={appliance.tSet}
                                  tSetHeat={appliance.tSetHeat}
                                  tSetCool={appliance.tSetCool}
                                  rhSet={appliance.rhSet}
                                  orCount={appliance.orCount}
                                  simplThermCool={
                                    appliance.relaySetting & 0x0001
                                  }
                                  simplThermHeat={
                                    (appliance.relaySetting & 0x0002) >> 1
                                  }
                                  changeThermostatSetting={
                                    this.changeThermostatSetting
                                  }
                                  cancelOverRide={this.cancelOverRide}
                                />
                              </td>
                            )}
                            {appliance.loadType === "Space Heater" &&
                              appliance.relayNum === "Relay1" &&
                              (appliance.relaySetting & 0x0001) !== 1 && (
                                <td
                                  style={{
                                    width: "70px",
                                    textAlign: "center",
                                    marginLeft: "10px",
                                    paddingLeft: "10px",
                                    position: "relative",
                                    left: "5px",
                                    height: "15px",
                                    color: "red",
                                  }}
                                >
                                  {appliance.tSetHeat} &#176;F
                                </td>
                              )}
                            {appliance.loadType === "Space Heater" &&
                              appliance.relayNum === "Relay1" &&
                              (appliance.relaySetting & 0x0001) === 1 && (
                                <td
                                  style={{
                                    width: "70px",
                                    textAlign: "center",
                                    marginLeft: "10px",
                                    paddingLeft: "10px",
                                    position: "relative",
                                    left: "5px",
                                    height: "15px",
                                    color: "black",
                                  }}
                                >
                                  {appliance.tSetHeat} &#176;F
                                </td>
                              )}
                            {appliance.loadType === "Space Heater" &&
                              appliance.relayNum === "Relay2" &&
                              (appliance.relaySetting & 0x0002) >> 1 !== 1 && (
                                <td
                                  style={{
                                    width: "70px",
                                    textAlign: "center",
                                    marginLeft: "10px",
                                    paddingLeft: "10px",
                                    position: "relative",
                                    left: "5px",
                                    height: "15px",
                                    color: "red",
                                  }}
                                >
                                  {appliance.tSetHeat} &#176;F
                                </td>
                              )}
                            {appliance.loadType === "Space Heater" &&
                              appliance.relayNum === "Relay2" &&
                              (appliance.relaySetting & 0x0002) >> 1 === 1 && (
                                <td
                                  style={{
                                    width: "70px",
                                    textAlign: "center",
                                    marginLeft: "10px",
                                    paddingLeft: "10px",
                                    position: "relative",
                                    left: "5px",
                                    height: "15px",
                                    color: "black",
                                  }}
                                >
                                  {appliance.tSetHeat} &#176;F
                                </td>
                              )}
                            {appliance.loadType === "Space Heater" &&
                              appliance.relayNum === "Relay3" &&
                              (appliance.relaySetting & 0x0004) >> 2 !== 1 && (
                                <td
                                  style={{
                                    width: "70px",
                                    textAlign: "center",
                                    marginLeft: "10px",
                                    paddingLeft: "10px",
                                    position: "relative",
                                    left: "5px",
                                    height: "15px",
                                    color: "red",
                                  }}
                                >
                                  {appliance.tSetHeat} &#176;F
                                </td>
                              )}
                            {appliance.loadType === "Space Heater" &&
                              appliance.relayNum === "Relay3" &&
                              (appliance.relaySetting & 0x0004) >> 2 === 1 && (
                                <td
                                  style={{
                                    width: "70px",
                                    textAlign: "center",
                                    marginLeft: "10px",
                                    paddingLeft: "10px",
                                    position: "relative",
                                    left: "5px",
                                    height: "15px",
                                    color: "black",
                                  }}
                                >
                                  {appliance.tSetHeat} &#176;F
                                </td>
                              )}
                            {appliance.loadType === "Space Heater" &&
                              appliance.relayNum === "Relay4" &&
                              (appliance.relaySetting & 0x0008) >> 3 !== 1 && (
                                <td
                                  style={{
                                    width: "70px",
                                    textAlign: "center",
                                    marginLeft: "10px",
                                    paddingLeft: "10px",
                                    position: "relative",
                                    left: "5px",
                                    height: "15px",
                                    color: "red",
                                  }}
                                >
                                  {appliance.tSetHeat} &#176;F
                                </td>
                              )}
                            {appliance.loadType === "Space Heater" &&
                              appliance.relayNum === "Relay4" &&
                              (appliance.relaySetting & 0x0008) >> 3 === 1 && (
                                <td
                                  style={{
                                    width: "70px",
                                    textAlign: "center",
                                    marginLeft: "10px",
                                    paddingLeft: "10px",
                                    position: "relative",
                                    left: "5px",
                                    height: "15px",
                                    color: "black",
                                  }}
                                >
                                  {appliance.tSetHeat} &#176;F
                                </td>
                              )}
                            {/*} 88888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888*/}
                            {appliance.loadType !== "HVAC w SimplTherm" &&
                              appliance.loadType !== "Space Heater" && (
                                <td
                                  style={{
                                    width: "70px",
                                    textAlign: "center",

                                    position: "relative",
                                    left: "12px",
                                    height: "15px",
                                  }}
                                >
                                  --
                                </td>
                              )}
                            {/*88888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888*/}
                            {appliance.loadType === "HVAC w SimplTherm" && (
                              <td
                                style={{
                                  width: "25px",
                                  textAlign: "center",
                                  position: "relative",
                                  left: "15px",
                                  height: "15px",
                                }}
                              >
                                {appliance.fanSet}{" "}
                              </td>
                            )}

                            {appliance.loadType !== "HVAC w SimplTherm" && (
                              <td
                                style={{
                                  width: "25px",
                                  textAlign: "center",
                                  position: "relative",
                                  left: "15px",
                                  height: "15px",
                                }}
                              >
                                --
                              </td>
                            )}
                            {/*88888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888*/}
                            {appliance.loadType === "HVAC w SimplTherm" &&
                              (appliance.mode === "Heat" ||
                                appliance.mode === "Heat On" ||
                                appliance.mode === "Heat On 2") && (
                                <td
                                  style={{
                                    width: "25px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "15px",
                                    height: "15px",
                                    color: "red",
                                  }}
                                >
                                  {appliance.mode}
                                </td>
                              )}

                            {appliance.loadType === "HVAC w SimplTherm" &&
                              (appliance.mode === "Cool" ||
                                appliance.mode === "Cool On" ||
                                appliance.mode === "Cool On 2") && (
                                <td
                                  style={{
                                    width: "25px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "15px",
                                    height: "15px",
                                    color: "dodgerblue",
                                  }}
                                >
                                  {appliance.mode}
                                </td>
                              )}
                            {appliance.loadType === "HVAC w SimplTherm" &&
                              appliance.mode === "Auto" && (
                                <td
                                  style={{
                                    width: "25px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "15px",
                                    height: "15px",
                                    color: "black",
                                  }}
                                >
                                  {appliance.mode}
                                </td>
                              )}
                            {appliance.loadType !== "HVAC w SimplTherm" && (
                              <td
                                style={{
                                  width: "25px",
                                  textAlign: "center",
                                  position: "relative",
                                  left: "15px",
                                  height: "15px",
                                }}
                              >
                                --
                              </td>
                            )}
                            {/*88888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888*/}
                            {appliance.loadType === "HVAC w SimplTherm" && (
                              <td
                                style={{
                                  width: "60px",
                                  textAlign: "center",

                                  position: "relative",
                                  left: "25px",
                                  height: "15px",
                                }}
                              >
                                {" "}
                                {(appliance.tReturn / 100.0).toFixed(1)} &#176;F
                                {/*888888 | {(appliance.rhReturn / 100.0).toFixed(1)}%********/}
                              </td>
                            )}
                            {appliance.loadType === "HVAC Unit" && (
                              <td
                                style={{
                                  width: "60px",
                                  textAlign: "center",

                                  position: "relative",
                                  left: "25px",
                                  height: "15px",
                                }}
                              >
                                {" "}
                                {(
                                  (appliance.tempReal / 100.0) * 1.8 +
                                  32.0
                                ).toFixed(1)}{" "}
                                &#176;F |{" "}
                                {(appliance.rhReal / 100.0).toFixed(1)}%
                              </td>
                            )}
                            {appliance.loadType !== "HVAC w SimplTherm" &&
                              appliance.loadType !== "HVAC Unit" && (
                                <td
                                  style={{
                                    width: "60px",
                                    textAlign: "center",

                                    position: "relative",
                                    left: "25px",
                                    height: "15px",
                                  }}
                                >
                                  --
                                </td>
                              )}
                            {/*
                            {(appliance.loadType === "Space Heater" ||
                              appliance.loadType === "Ambient Temp Sensor") && (
                              <td
                                style={{
                                  width: "60px",
                                  textAlign: "center",
                                  position: "relative",
                                  left: "25px",
                                  height: "15px",
                                }}
                              >
                                {" "}
                                {(
                                  (appliance.tempReal / 100.0) * 1.8 +
                                  32.0
                                ).toFixed(1)}{" "}
                                &#176;F |{" "}
                                {(appliance.rhReal / 100.0).toFixed(1)}%
                              </td>
                            )}
                            {(appliance.loadType === "SimplWalkIn" ||
                              appliance.loadType === "Water Heater" ||
                              appliance.loadType === "SimplHeater" ||
                              appliance.loadType === "External Temp Sensor" ||
                              appliance.loadType === "Reach-in" ||
                              appliance.loadType === "Counter Reach-in") && (
                              <td
                                style={{
                                  width: "60px",
                                  textAlign: "center",
                                  position: "relative",
                                  left: "25px",
                                  height: "15px",
                                }}
                              >
                                {" "}
                                {(appliance.rthReal / 100.0).toFixed(1)} &#176;F
                              </td>
                            )}
                            {appliance.loadType !== "HVAC w SimplTherm" &&
                              appliance.loadType !== "HVAC Unit" &&
                              appliance.loadType !== "SimplWalkIn" &&
                              appliance.loadType !== "SimplHeater" &&
                              appliance.loadType !== "Space Heater" &&
                              appliance.loadType !== "Ambient Temp Sensor" &&
                              appliance.loadType !== "Water Heater" &&
                              appliance.loadType !== "External Temp Sensor" &&
                              appliance.loadType !== "Reach-in" &&
                              appliance.loadType !== "Counter Reach-in" && (
                                <td
                                  style={{
                                    width: "60px",
                                    textAlign: "center",
                                    marginLeft: "0px",
                                    paddingLeft: "0px",
                                    marginRight: "0px",
                                    paddingRight: "0px",
                                    position: "relative",
                                    left: "25px",
                                    height: "15px",
                                  }}
                                >
                                  --
                                </td>
                              )}
                                */}
                            {/*88888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888*/}
                            {appliance.loadType === "HVAC w SimplTherm" && (
                              <td
                                style={{
                                  width: "60px",
                                  textAlign: "center",
                                  marginLeft: "0px",
                                  paddingLeft: "0px",
                                  marginRight: "0px",
                                  paddingRight: "0px",
                                  position: "relative",
                                  left: "25px",
                                  height: "15px",
                                }}
                              >
                                {" "}
                                {(appliance.tSupply / 100.0).toFixed(1)} &#176;F
                                {/*888888 | {(appliance.rhSupply / 100.0).toFixed(1)}%***************/}
                              </td>
                            )}
                            {appliance.loadType !== "HVAC w SimplTherm" && (
                              <td
                                style={{
                                  width: "60px",
                                  textAlign: "center",
                                  marginLeft: "0px",
                                  paddingLeft: "0px",
                                  marginRight: "0px",
                                  paddingRight: "0px",
                                  position: "relative",
                                  left: "25px",
                                  height: "15px",
                                }}
                              >
                                --
                              </td>
                            )}
                            {/*88888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888*/}

                            <td
                              style={{
                                width: "60px",
                                textAlign: "center",
                                marginLeft: "0px",
                                paddingLeft: "0px",
                                marginRight: "0px",
                                paddingRight: "0px",
                                position: "relative",
                                left: "20px",
                                height: "15px",
                              }}
                            >
                              --
                            </td>

                            {/*88888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888*/}
                            <td
                              style={{
                                width: "60px",
                                textAlign: "center",
                                marginLeft: "0px",
                                paddingLeft: "0px",
                                marginRight: "0px",
                                paddingRight: "0px",
                                position: "relative",
                                left: "20px",
                                height: "15px",
                              }}
                            >
                              --
                            </td>

                            {/*88888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888*/}
                            {(appliance.loadType === "SimplWalkIn" ||
                              appliance.loadType === "Door Monitor") && (
                              <td
                                style={{
                                  width: "40px",
                                  textAlign: "center",

                                  position: "relative",
                                  left: "45px",
                                  height: "15px",
                                }}
                              >
                                {appliance.loraDoorOpenTime}
                              </td>
                            )}
                            {appliance.loadType === "Avg. Ambient Temp" && (
                              <td
                                style={{
                                  width: "40px",
                                  textAlign: "center",

                                  position: "relative",
                                  left: "45px",
                                  height: "15px",
                                }}
                              >
                                {(
                                  (appliance.avgExtTemp / 100.0) * 1.8 +
                                  32
                                ).toFixed(1)}
                                &#176;F
                              </td>
                            )}
                            {appliance.loadType === "HVAC w SimplTherm" &&
                              appliance.extTemp !== 15000 && (
                                <td
                                  style={{
                                    width: "40px",
                                    textAlign: "center",

                                    position: "relative",
                                    left: "45px",
                                    height: "15px",
                                  }}
                                >
                                  {(
                                    (appliance.extTemp / 100.0) * 1.8 +
                                    32
                                  ).toFixed(1)}
                                  &#176;F
                                </td>
                              )}
                            {appliance.loadType === "HVAC w SimplTherm" &&
                              appliance.extTemp === 15000 && (
                                <td
                                  style={{
                                    width: "40px",
                                    textAlign: "center",

                                    position: "relative",
                                    left: "45px",
                                    height: "15px",
                                  }}
                                >
                                  --
                                </td>
                              )}
                            {appliance.loadType !== "SimplWalkIn" &&
                              appliance.loadType !== "HVAC w SimplTherm" &&
                              appliance.loadType !== "Door Monitor" &&
                              appliance.loadType !== "Avg. Ambient Temp" && (
                                <td
                                  style={{
                                    width: "40px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "45px",
                                    height: "15px",
                                  }}
                                >
                                  --
                                </td>
                              )}
                            {/*88888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888*/}
                            {(appliance.loadType === "Outdoor Sensed Lights" ||
                              appliance.loadType ===
                                "Relay Driver w Light Sensor" ||
                              appliance.loadType ===
                                "Quad 30A w Light Sensor" ||
                              appliance.loadType === "Light Sensor") && (
                              <td
                                style={{
                                  width: "75px",
                                  textAlign: "center",
                                  position: "relative",
                                  left: "85px",
                                  height: "15px",
                                }}
                              >
                                {appliance.luxReal}
                              </td>
                            )}
                            {appliance.loadType === "HVAC w SimplTherm" &&
                              appliance.extTemp !== 15000 && (
                                <td
                                  style={{
                                    width: "75px",
                                    textAlign: "center",

                                    position: "relative",
                                    left: "85px",
                                    height: "15px",
                                  }}
                                >
                                  --
                                </td>
                              )}
                            {appliance.loadType === "HVAC w SimplTherm" &&
                              appliance.extTemp === 15000 && (
                                <td
                                  style={{
                                    width: "75px",
                                    textAlign: "center",

                                    position: "relative",
                                    left: "85px",
                                    height: "15px",
                                  }}
                                >
                                  {(appliance.roomTemp / 100.0).toFixed(1)}{" "}
                                  &#176;F |{" "}
                                  {(appliance.roomRH / 100.0).toFixed(0)}%
                                </td>
                              )}
                            {/********************************************************************************************** */}
                            {(appliance.loadType === "Space Heater" ||
                              appliance.loadType === "Ambient Temp Sensor") && (
                              <td
                                style={{
                                  width: "75px",
                                  textAlign: "center",
                                  position: "relative",
                                  left: "85px",
                                  height: "15px",
                                }}
                              >
                                {" "}
                                {(
                                  (appliance.tempReal / 100.0) * 1.8 +
                                  32.0
                                ).toFixed(1)}{" "}
                                &#176;F |{" "}
                                {(appliance.rhReal / 100.0).toFixed(0)}%
                              </td>
                            )}
                            {(appliance.loadType === "SimplWalkIn" ||
                              appliance.loadType === "Water Heater" ||
                              appliance.loadType === "SimplHeater" ||
                              appliance.loadType === "External Temp Sensor" ||
                              appliance.loadType === "Reach-in" ||
                              appliance.loadType === "Counter Reach-in") && (
                              <td
                                style={{
                                  width: "75px",
                                  textAlign: "center",
                                  position: "relative",
                                  left: "85px",
                                  height: "15px",
                                }}
                              >
                                {" "}
                                {(appliance.rthReal / 100.0).toFixed(1)} &#176;F
                              </td>
                            )}
                            {appliance.loadType !== "HVAC w SimplTherm" &&
                              appliance.loadType !== "HVAC Unit" &&
                              appliance.loadType !== "SimplWalkIn" &&
                              appliance.loadType !== "SimplHeater" &&
                              appliance.loadType !== "Space Heater" &&
                              appliance.loadType !== "Ambient Temp Sensor" &&
                              appliance.loadType !== "Water Heater" &&
                              appliance.loadType !== "External Temp Sensor" &&
                              appliance.loadType !== "Reach-in" &&
                              appliance.loadType !== "Counter Reach-in" &&
                              appliance.loadType !== "HVAC w SimplTherm" &&
                              appliance.loadType !== "Outdoor Sensed Lights" &&
                              appliance.loadType !==
                                "Relay Driver w Light Sensor" &&
                              appliance.loadType !==
                                "Quad 30A w Light Sensor" &&
                              appliance.loadType !== "Light Sensor" && (
                                <td
                                  style={{
                                    width: "75px",
                                    textAlign: "center",
                                    marginLeft: "0px",
                                    paddingLeft: "0px",
                                    marginRight: "0px",
                                    paddingRight: "0px",
                                    position: "relative",
                                    left: "85px",
                                    height: "15px",
                                  }}
                                >
                                  --
                                </td>
                              )}

                            {/******************************************************************************************** 
                            {appliance.loadType !== "HVAC w SimplTherm" &&
                              appliance.loadType !== "Outdoor Sensed Lights" &&
                              appliance.loadType !==
                                "Relay Driver w Light Sensor" &&
                              appliance.loadType !==
                                "Quad 30A w Light Sensor" &&
                              appliance.loadType !== "Light Sensor" && (
                                <td
                                  style={{
                                    width: "75px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "85px",
                                    height: "15px",
                                  }}
                                >
                                  --
                                </td>
                              )}
                           88888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888*/}
                            {appliance.loadType === "HVAC w SimplTherm" && (
                              <div>
                                {(appliance.relaySetting & 0x0001) === 1 && (
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "90px",
                                      height: "15px",
                                      color: "dodgerblue",
                                    }}
                                  >
                                    ON
                                  </td>
                                )}
                                {(appliance.relaySetting & 0x0001) !== 1 && (
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "90px",
                                      height: "15px",
                                    }}
                                  >
                                    OFF{" "}
                                  </td>
                                )}

                                {(appliance.relaySetting & 0x0002) >> 1 ===
                                  1 && (
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "99px",
                                      height: "15px",
                                      color: "red",
                                    }}
                                  >
                                    ON
                                  </td>
                                )}
                                {(appliance.relaySetting & 0x0002) >> 1 !==
                                  1 && (
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "99px",
                                      height: "15px",
                                    }}
                                  >
                                    OFF
                                  </td>
                                )}

                                {(appliance.relaySetting & 0x0004) >> 2 ===
                                  1 && (
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "105px",
                                      height: "15px",
                                      color: "dodgerblue",
                                    }}
                                  >
                                    ON
                                  </td>
                                )}
                                {(appliance.relaySetting & 0x0004) >> 2 !==
                                  1 && (
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "105px",
                                      height: "15px",
                                    }}
                                  >
                                    OFF
                                  </td>
                                )}

                                {(appliance.relaySetting & 0x0008) >> 3 ===
                                  1 && (
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "115px",
                                      height: "15px",
                                      color: "red",
                                    }}
                                  >
                                    ON
                                  </td>
                                )}
                                {(appliance.relaySetting & 0x0008) >> 3 !==
                                  1 && (
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "115px",
                                      height: "15px",
                                    }}
                                  >
                                    OFF
                                  </td>
                                )}
                                {(appliance.relaySetting & 0x0010) >> 4 ===
                                  1 && (
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "120px",
                                      height: "15px",
                                      color: "lime",
                                    }}
                                  >
                                    ON
                                  </td>
                                )}
                                {(appliance.relaySetting & 0x0010) >> 4 !==
                                  1 && (
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "120px",
                                      height: "15px",
                                    }}
                                  >
                                    OFF
                                  </td>
                                )}
                                {appliance.health === 0 &&
                                  (appliance.relaySetting & 0x0010) >> 4 ===
                                    1 && (
                                    <td
                                      style={{
                                        width: "55px",
                                        textAlign: "center",
                                        position: "relative",
                                        left: "120px",
                                        height: "15px",
                                        color: "red",
                                      }}
                                    >
                                      Bad
                                    </td>
                                  )}
                                {appliance.health === 1 &&
                                  (appliance.relaySetting & 0x0010) >> 4 ===
                                    1 && (
                                    <td
                                      style={{
                                        width: "55px",
                                        textAlign: "center",
                                        position: "relative",
                                        left: "120px",
                                        height: "15px",
                                        color: "orange",
                                      }}
                                    >
                                      Poor
                                    </td>
                                  )}
                                {appliance.health === 2 &&
                                  (appliance.relaySetting & 0x0010) >> 4 ===
                                    1 && (
                                    <td
                                      style={{
                                        width: "55px",
                                        textAlign: "center",
                                        position: "relative",
                                        left: "120px",
                                        height: "15px",
                                        color: "dodgerblue",
                                      }}
                                    >
                                      Good
                                    </td>
                                  )}
                                {appliance.health === 3 &&
                                  (appliance.relaySetting & 0x0010) >> 4 ===
                                    1 && (
                                    <td
                                      style={{
                                        width: "55px",
                                        textAlign: "center",
                                        position: "relative",
                                        left: "120px",
                                        height: "15px",
                                        color: "lime",
                                      }}
                                    >
                                      Best
                                    </td>
                                  )}
                                {((appliance.health !== 0 &&
                                  appliance.health !== 1 &&
                                  appliance.health !== 2 &&
                                  appliance.health !== 3) ||
                                  (appliance.relaySetting & 0x0010) >> 4 !==
                                    1) && (
                                  <td
                                    style={{
                                      width: "55px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "120px",
                                      height: "15px",
                                    }}
                                  >
                                    ----
                                  </td>
                                )}
                              </div>
                            )}
                            {appliance.loadType === "HVAC Unit" && (
                              <div>
                                <td
                                  style={{
                                    width: "40px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "90px",
                                    height: "15px",
                                  }}
                                >
                                  {" "}
                                  {(appliance.relaySetting & 0x0001) === 1 &&
                                    "Open"}
                                  {(appliance.relaySetting & 0x0001) !== 1 &&
                                    "Clsd"}
                                </td>

                                <td
                                  style={{
                                    width: "40px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "100px",
                                    height: "15px",
                                  }}
                                >
                                  {(appliance.relaySetting & 0x0002) >> 1 ===
                                    1 && "Open"}
                                  {(appliance.relaySetting & 0x0002) >> 1 !==
                                    1 && "Clsd"}
                                </td>
                                <td
                                  style={{
                                    width: "40px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "110px",
                                    height: "15px",
                                  }}
                                >
                                  {(appliance.relaySetting & 0x0004) >> 2 ===
                                    1 && "Open"}
                                  {(appliance.relaySetting & 0x0004) >> 2 !==
                                    1 && "Clsd"}
                                </td>
                                <td
                                  style={{
                                    width: "40px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "115px",
                                    height: "15px",
                                  }}
                                >
                                  {(appliance.relaySetting & 0x0008) >> 3 ===
                                    1 && "Open"}
                                  {(appliance.relaySetting & 0x0008) >> 3 !==
                                    1 && "Clsd"}
                                </td>

                                <td
                                  style={{
                                    width: "40px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "120px",
                                    height: "15px",
                                  }}
                                >
                                  {""}
                                </td>
                                <td
                                  style={{
                                    width: "55px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "120px",
                                    height: "15px",
                                  }}
                                >
                                  {""}
                                </td>
                              </div>
                            )}
                            {(appliance.loadType === "SimplWalkIn" ||
                              appliance.loadType === "SimplHeater") && (
                              <div>
                                <td
                                  style={{
                                    width: "40px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "95px",
                                    height: "15px",
                                  }}
                                >
                                  {" "}
                                  {(appliance.relaySetting & 0x0001) === 1 &&
                                    "Open"}
                                  {(appliance.relaySetting & 0x0001) !== 1 &&
                                    "Clsd"}
                                </td>

                                <td
                                  style={{
                                    width: "40px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "120px",
                                    height: "15px",
                                  }}
                                >
                                  {""}
                                </td>
                                <td
                                  style={{
                                    width: "40px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "140px",
                                    height: "15px",
                                  }}
                                >
                                  {""}
                                </td>
                                <td
                                  style={{
                                    width: "40px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "155px",
                                    height: "15px",
                                  }}
                                >
                                  {""}
                                </td>

                                <td
                                  style={{
                                    width: "40px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "160px",
                                    height: "15px",
                                  }}
                                >
                                  {""}
                                </td>
                                <td
                                  style={{
                                    width: "55px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "190px",
                                    height: "15px",
                                  }}
                                >
                                  {""}
                                </td>
                              </div>
                            )}
                            {appliance.loadType !== "HVAC w SimplTherm" &&
                              appliance.loadType !== "HVAC Unit" &&
                              appliance.loadType !== "SimplWalkIn" &&
                              appliance.loadType !== "SimplHeater" &&
                              appliance.relayNum === "Relay1" && (
                                <div>
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "95px",
                                      height: "15px",
                                    }}
                                  >
                                    {" "}
                                    {(appliance.relaySetting & 0x0001) === 1 &&
                                      "Open"}
                                    {(appliance.relaySetting & 0x0001) !== 1 &&
                                      "Clsd"}
                                  </td>

                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "120px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "110px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "115px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "120px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                  <td
                                    style={{
                                      width: "55px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "120px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                </div>
                              )}
                            {appliance.loadType !== "HVAC w SimplTherm" &&
                              appliance.loadType !== "HVAC Unit" &&
                              appliance.loadType !== "SimplWalkIn" &&
                              appliance.loadType !== "SimplHeater" &&
                              appliance.relayNum === "Relay2" && (
                                <div>
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "90px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>

                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "100px",
                                      height: "15px",
                                    }}
                                  >
                                    {(appliance.relaySetting & 0x0002) >> 1 ===
                                      1 && "Open"}
                                    {(appliance.relaySetting & 0x0002) >> 1 !==
                                      1 && "Clsd"}
                                  </td>
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "110px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "115px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "120px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                  <td
                                    style={{
                                      width: "55px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "120px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                </div>
                              )}
                            {appliance.loadType !== "HVAC w SimplTherm" &&
                              appliance.loadType !== "HVAC Unit" &&
                              appliance.loadType !== "SimplWalkIn" &&
                              appliance.loadType !== "SimplHeater" &&
                              appliance.relayNum === "Relay3" && (
                                <div>
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "90px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>

                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "100px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "115px",
                                      height: "15px",
                                    }}
                                  >
                                    {(appliance.relaySetting & 0x0004) >> 2 ===
                                      1 && "Open"}
                                    {(appliance.relaySetting & 0x0004) >> 2 !==
                                      1 && "Clsd"}
                                  </td>
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "115px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "120px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                  <td
                                    style={{
                                      width: "55px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "120px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                </div>
                              )}
                            {appliance.loadType !== "HVAC w SimplTherm" &&
                              appliance.loadType !== "HVAC Unit" &&
                              appliance.loadType !== "SimplWalkIn" &&
                              appliance.loadType !== "SimplHeater" &&
                              appliance.relayNum === "Relay4" && (
                                <div>
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "90px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>

                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "100px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "110px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "120px",
                                      height: "15px",
                                    }}
                                  >
                                    {(appliance.relaySetting & 0x0008) >> 3 ===
                                      1 && "Open"}
                                    {(appliance.relaySetting & 0x0008) >> 3 !==
                                      1 && "Clsd"}
                                  </td>
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "120px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                  <td
                                    style={{
                                      width: "55px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "120px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                </div>
                              )}
                            {appliance.loadType !== "HVAC w SimplTherm" &&
                              appliance.loadType !== "HVAC Unit" &&
                              appliance.loadType !== "SimplWalkIn" &&
                              appliance.loadType !== "SimplHeater" &&
                              appliance.relayNum === "" && (
                                <div>
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "90px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>

                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "100px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "110px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "115px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                  <td
                                    style={{
                                      width: "40px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "120px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                  <td
                                    style={{
                                      width: "55px",
                                      textAlign: "center",
                                      position: "relative",
                                      left: "120px",
                                      height: "15px",
                                    }}
                                  >
                                    {""}
                                  </td>
                                </div>
                              )}
                            {appliance.loadType !== "HVAC w SimplTherm" &&
                              appliance.loadType !== "HVAC Unit" &&
                              appliance.loadType !== "SimplWalkIn" &&
                              appliance.loadType !== "SimplHeater" &&
                              appliance.loadType !== "Space Heater" &&
                              appliance.loadType !== "Water Heater" &&
                              appliance.loadType !== "Reach-in" &&
                              appliance.loadType !== "Counter Reach-in" &&
                              appliance.loadType !== "External Temp Sensor" && (
                                <td
                                  style={{
                                    width: "75px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "130px",
                                    height: "15px",
                                  }}
                                >
                                  {""}
                                </td>
                              )}
                            {(appliance.loadType === "HVAC w SimplTherm" ||
                              appliance.loadType === "HVAC Unit" ||
                              appliance.loadType === "SimplWalkIn" ||
                              appliance.loadType === "SimplHeater" ||
                              appliance.loadType === "Space Heater" ||
                              appliance.loadType === "Water Heater" ||
                              appliance.loadType === "Reach-in" ||
                              appliance.loadType === "Counter Reach-in" ||
                              appliance.loadType ===
                                "External Temp Sensor") && (
                              <td
                                style={{
                                  width: "75px",
                                  textAlign: "center",
                                  margin: "0px",
                                  padding: "0px",
                                  position: "relative",
                                  left: "125px",
                                  height: "15px",
                                }}
                              >
                                {appliance.tempLo} &#176;F | {appliance.tempHi}{" "}
                                &#176;F
                              </td>
                            )}
                            {appliance.loadType !== "Ambient Temp Sensor" &&
                              appliance.loadType !== "Door Monitor" && (
                                <td
                                  style={{
                                    width: "45px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "130px",
                                    height: "15px",
                                  }}
                                >
                                  {appliance.powerRating.toFixed(2)}
                                </td>
                              )}

                            {appliance.priority === 0 &&
                              appliance.loadType !== "Ambient Temp Sensor" &&
                              appliance.loadType !== "Door Monitor" && (
                                <td
                                  style={{
                                    width: "45px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "130px",
                                    height: "15px",
                                  }}
                                >
                                  {""}
                                </td>
                              )}
                            {appliance.priority !== 0 &&
                              appliance.loadType !== "Ambient Temp Sensor" &&
                              appliance.loadType !== "Door Monitor" && (
                                <td
                                  style={{
                                    width: "45px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "135px",
                                    height: "15px",
                                  }}
                                >
                                  {appliance.priority}
                                </td>
                              )}
                            {appliance.sheddable === 1 &&
                              appliance.loadType !== "Ambient Temp Sensor" &&
                              appliance.loadType !== "Door Monitor" && (
                                <td
                                  style={{
                                    width: "60px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "125px",
                                    height: "15px",
                                  }}
                                >
                                  Yes
                                </td>
                              )}
                            {appliance.sheddable === 0 &&
                              appliance.loadType !== "Ambient Temp Sensor" &&
                              appliance.loadType !== "Door Monitor" && (
                                <td
                                  style={{
                                    width: "60px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "127px",
                                    height: "15px",
                                  }}
                                >
                                  No
                                </td>
                              )}
                            {appliance.shedStatus === 1 &&
                              appliance.loadType !== "Ambient Temp Sensor" &&
                              appliance.loadType !== "Door Monitor" && (
                                <td
                                  style={{
                                    width: "35px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "120px",
                                    height: "15px",
                                    color: "red",
                                  }}
                                >
                                  Shed
                                </td>
                              )}
                            {appliance.shedStatus === 0 &&
                              appliance.loadType !== "Ambient Temp Sensor" &&
                              appliance.loadType !== "Door Monitor" && (
                                <td
                                  style={{
                                    width: "35px",
                                    textAlign: "center",
                                    position: "relative",
                                    left: "120px",
                                    height: "15px",
                                    color: "dodgerblue",
                                  }}
                                >
                                  Run
                                </td>
                              )}
                          </tr>
                          <hr
                            style={{
                              margin: "0px",
                              padding: "0px",
                            }}
                          ></hr>
                        </div>
                      ))}
                    </ScrollView>
                  </table>
                  <div
                    className="mainMenuNodesIcons"
                    style={{
                      paddingBottom: "10px",
                      transform: "translate(60px, 450px)",
                    }}
                  >
                    <SimplFooterWide
                      handleID={this.state.selectedHandleID}
                      systemConfiguration={this.state.systemConfiguration}
                      companyName={this.props.match.params.companyName}
                      //userRole="companyAdmin"
                      userRole={this.state.userRole}
                      systemSN={this.state.systemSN}
                      displayWidth={this.state.displayWidth}
                      connection={this.state.connection}
                      systemStatus={this.state.systemStatus}
                      closedTasks={this.state.closedTasks}
                    />
                  </div>
                </div>
              )}

            <hr></hr>
          </div>
        </ScrollView>
      </React.Fragment>
    );
  }
}
export default ListView;
