import React, { Component } from "react";

import "./dashboard.css";
import "../index.css";
import VerticalProgress from "./verticalProgress";
import ClearProgress from "./clearProgress";
import "react-datepicker/dist/react-datepicker.css";

function NodeProgBars(props) {
  const demandLimitPercent = parseFloat(props.demandLimitPercent) / 100.0;
  const powerPercent =
    ((demandLimitPercent * props.realTimeNumber) / props.demandLimit) * 100.0;

  let progChartType = "NORMAL";
  if (powerPercent < 25) progChartType = "OUTSIDE_DN";
  else if (powerPercent < 120) progChartType = "NORMAL";
  else progChartType = "OUTSIDE_UP";
  const realTimePowerPercent = powerPercent.toString();

  const intervalPercent =
    ((demandLimitPercent * props.intervalNumber) / props.demandLimit) * 100.0;
  let intervalChartType = "NORMAL";
  if (intervalPercent < 25) intervalChartType = "OUTSIDE_DN";
  else if (intervalPercent < 120) intervalChartType = "NORMAL";
  else intervalChartType = "OUTSIDE_UP";
  const intervalPowerPercent = intervalPercent.toString();

  const rollingPercent =
    ((demandLimitPercent * props.rollingIntervalNumber) / props.demandLimit) *
    100.0;
  let rollingChartType = "NORMAL";
  if (rollingPercent < 25) rollingChartType = "OUTSIDE_DN";
  else if (rollingPercent < 120) rollingChartType = "NORMAL";
  else rollingChartType = "OUTSIDE_UP";
  const rollingPowerPercent = rollingPercent.toString();

  return (
    <React.Fragment>
      <div>
        <div
          className="progress vertical"
          style={{ position: "absolute", top: "260px", left: "50px" }}
        >
          <VerticalProgress
            value={`${realTimePowerPercent}%`}
            color={props.realTimeColor}
            Number={props.realTimeNumber}
            unit={props.powerUnit}
            chartType={`${progChartType}`}
          />
        </div>
        <div
          className="progress vertical"
          style={{
            position: "absolute",
            top: "260px",
            left: "50px",
            backgroundColor: "transparent",
          }}
        >
          <ClearProgress
            value={props.demandLimitPercent}
            color="transparent"
            Number={props.demandLimit}
            unit={props.powerUnit}
          />
        </div>
        <div
          className="progress vertical"
          style={{ position: "absolute", top: "260px", left: "140px" }}
        >
          <VerticalProgress
            value={`${intervalPowerPercent}%`}
            color={props.intervalColor}
            Number={props.intervalNumber}
            unit={props.powerUnit}
            chartType={`${intervalChartType}`}
          />
        </div>
        <div
          className="progress vertical"
          style={{
            position: "absolute",
            top: "260px",
            left: "140px",
            backgroundColor: "transparent",
          }}
        >
          <ClearProgress
            value={props.demandLimitPercent}
            color="transparent"
            Number={props.demandLimit}
            unit={props.powerUnit}
          />
        </div>
        <div
          className="progress vertical"
          style={{ position: "absolute", top: "260px", left: "230px" }}
        >
          <VerticalProgress
            value={`${rollingPowerPercent}%`}
            color={props.rollingIntervalColor}
            Number={props.rollingIntervalNumber}
            unit={props.powerUnit}
            chartType={`${rollingChartType}`}
          />
        </div>
        <div
          className="progress vertical"
          style={{
            position: "absolute",
            top: "260px",
            left: "230px",
            backgroundColor: "transparent",
          }}
        >
          <ClearProgress
            value={props.demandLimitPercent}
            color="transparent"
            Number={props.demandLimit}
            unit={props.powerUnit}
          />
        </div>
        <p
          className="basicParagraph"
          style={{
            position: "absolute",
            top: "410px",
            left: "40px",
            width: "50px",
            textAlign: "center",
            fontSize: "10px",
            color: "#595959",
          }}
        >
          Realtime
          <p>Power</p>
        </p>
        <p
          className="basicParagraph"
          style={{
            position: "absolute",
            top: "410px",
            left: "115px",
            width: "80px",
            textAlign: "center",
            fontSize: "10px",
            color: "#595959",
          }}
        >
          Demand:
          <p>This Interval</p>
        </p>
        <p
          className="basicParagraph"
          style={{
            position: "absolute",
            top: "410px",
            left: "218px",
            width: "50px",
            textAlign: "center",
            fontSize: "10px",
            color: "#595959",
          }}
        >
          Demand:
          <p>Rolling</p>
        </p>
      </div>
    </React.Fragment>
  );
}
export default NodeProgBars;
