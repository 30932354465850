import React, { Component } from "react";

import "./dashboard.css";
import "../index.css";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "react-datepicker/dist/react-datepicker.css";

class TstatSimplModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      tSet: this.props.tSet / 100.0,
      durationTime: 0,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.props.closeThermostatModal();
      this.setState({ show: false });
    };
  }
  handleChange = (e) => {
    const tSet = e.currentTarget.value;
    this.setState({ tSet });
  };
  handleDuration = (e) => {
    let durationTimeStr = e.currentTarget.value;
    console.log("Duration Time String: ", durationTimeStr);
    let durationTime = 0;
    if (durationTimeStr === "Choose here") durationTime = 0;
    else if (durationTimeStr === "Five minutes") durationTime = 5;
    else if (durationTimeStr === "30 minutes") durationTime = 30;
    else if (durationTimeStr === "One Hour") durationTime = 60;
    else if (durationTimeStr === "Two Hours") durationTime = 120;
    else if (durationTimeStr === "Three Hours") durationTime = 180;
    else durationTime = 0;
    console.log("Duration Time Number: ", durationTime);
    this.setState({ durationTime });
  };

  render() {
    return (
      <>
        <Modal
          style={{
            top: "30%",
            left: "30%",
            width: "250px",
            height: "800px",
            alignContent: "center",
          }}
          show={true} //{this.props.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body>
            <label className="basicParagraph">{this.props.nodeName}</label>
            <p className="basicParagraph" style={{ color: "orange" }}>
              Over-ride Seconds Remaining: {this.props.orCount}
            </p>
            {/*<form
              onSubmit={(e) => {
                this.handleHide();
                this.props.closeThermostatModal(
                  `${this.state.tSet}`,
                  `${this.state.durationTime}`,
                  `${this.props.nodeName}`
                );
                //this.props.closeThermostatModal();
                e.preventDefault();
              }}
            >*/}
            <label className="basicParagraph">
              Enter Temp. Setting in &#176;F for:
            </label>
            <input
              value={this.state.tSet}
              onChange={this.handleChange}
              id="fullScaleCurrent"
              type="text"
              className="form-control2"
            />
            <label className="basicParagraph">Select Duration:</label>

            <select
              className="form-control2"
              value={this.state.duartionTime}
              onChange={this.handleDuration}
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                border: "none",
                borderRadius: "0px",
                borderBottom: "2px solid lightgrey",
              }}
            >
              <option value="Choose here">Choose here</option>
              <option value="Five minutes">Five minutes</option>
              <option value="30 minutes">30 minutes</option>
              <option value="One Hour">One Hour</option>
              <option value="Two Hours">Two Hours</option>
              <option value="Three Hours">Three Hours</option>
            </select>
            <button
              className="basicButton"
              style={{
                width: "90px",
                height: "30px",
                marginTop: "10px",
                fontSize: "9px",
                backgroundColor: "green",
              }}
              onClick={() => {
                this.handleHide();
                this.props.closeThermostatModal(
                  `${this.state.tSet}`,
                  `${this.state.durationTime}`,
                  `${this.props.nodeName}`
                );
              }}
            >
              Update OverRide
            </button>
            <button
              className="basicButton"
              style={{
                width: "90px",
                height: "30px",
                marginTop: "10px",
                fontSize: "9px",
                marginLeft: "10px",
                backgroundColor: "tomato",
              }}
              onClick={() => {
                this.handleHide();
                this.props.cancelOverRide(`${this.props.nodeName}`);
              }}
            >
              End OverRide
            </button>
            {/*</form>*/}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default TstatSimplModal;
