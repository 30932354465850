import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  getSystemLatestData,
  ws_getSystemLatestData,
  getSystemIntervalData,
  getSystemInfo,
  getSystemBasics,
  getSystemIDfromHandle,
  getSystem,
} from "../services/systemService";
import { setJwt } from "../services/httpService";
import { getUser } from "../services/userService";
import "./dashboard.css";
import "../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "react-bootstrap/ProgressBar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import BackGroundRectangle from "../components/backgroundRect";
import { webSocketMode } from "../config.json"; //0: http; 1:websocket

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: [],
      dateNow: new Date(),

      endDate: new Date().getTime(),
      startDate: new Date().getTime() - 10000000,
      modeBox: "Real-time",
      anchorEl: null,
      companyName: "",
      key: null,
      systemID: "",
      handleID: 0,
      pvPowerUnits: "Watts",
      batteryPowerUnits: "Watts",
      intervalPeriod: 900,
      dataCount: 0,
      pvIntervalTotal: 0,
      battIntervalTotal: 0,
      pvInterval: [],
      response: {
        deviceLedger: {
          realTime: {
            timestamp: 0,
            pvPower: 0,
            batteryPower: 0,
          },
          Interval: {
            pvEnergy: 0,
            batteryEnergy: 0,
          },
        },
      },
    };
    this.getSystemData = this.getSystemData.bind(this);
  }

  async componentDidMount() {
    const userRole = this.props.match.params.userRole;
    console.log("User Role", userRole);
    this.timerID = setInterval(() => this.tick(), 1000);
    const handleID = this.props.match.params.systemHandleID;
    const companyName = this.props.match.params.companyName;
    const systemInfo = await getSystemInfo(handleID);
    console.log("System Info in Dashboard CDM", systemInfo);
    const attachedSimplModuleSN = systemInfo.data.data.controls.moduleSN;
    const attachedSimplModuleHandle =
      systemInfo.data.data.controls.moduleHandleId;
    console.log("Module Handle ID:", attachedSimplModuleHandle);

    const moduleDataValid = systemInfo.data.data.controls.AttachedModuleValid;

    const systemID = (await getSystemIDfromHandle(handleID)).data.data
      .system_id;
    console.log("System ID:", systemID);
    const systemData = (await getSystem(systemID)).data.data[0].systemID;
    const serialNumber = systemData.SN;
    const macAddress = systemData.MAC;
    const systemName = systemData.name;
    this.setState({ serialNumber, macAddress, systemName });

    console.log("Serial Number", serialNumber);
    console.log("MAC Address:", macAddress);
    console.log("System Name", systemName);

    const systemStatus = systemInfo.data.data.status;
    console.log("system status", systemStatus);

    const backUpMode = parseInt(systemInfo.data.data.controls.backUpMode);
    console.log("BackUp Mode:", backUpMode);
    let acCoupledBackUp = 0;
    if (backUpMode === 3 || backUpMode === 4) acCoupledBackUp = 1;

    const systemBasics = systemInfo.data.data.basics;
    console.log("System Basics", systemBasics);
    const systemDescription = systemBasics.systemDescription;
    const systemConfiguration = systemBasics.systemConfiguration;
    const productSN = systemBasics.productSN;
    const emaSN = systemBasics.fourGserialNumber;
    const systemProduct = systemBasics.systemProduct;
    const systemType = systemBasics.systemType;
    const paramUpdateRate = systemBasics.paramUpdateRate;
    const dataVerbosity = systemBasics.dataVerbosity;
    const dataFrequency = systemBasics.dataFrequency;

    let measPoint1 = "";
    let measPoint2 = "";
    if (systemConfiguration === "1") {
      const circuitName = systemBasics.circuitNameMeter1;
      if (circuitName === "0") measPoint1 = "Line/Grid";
      else if (circuitName === "1") measPoint1 = "Load/Building";
      else if (circuitName === "2") measPoint1 = "Solar";
      else measPoint1 = "Line/Grid";
      this.setState({ measPoint1 });
    } else if (
      systemConfiguration === "2" ||
      systemConfiguration === "3" ||
      systemConfiguration === "6"
    ) {
      let circuitName = systemBasics.circuitNameMeter1;
      if (circuitName === "0") measPoint1 = "Line/Grid";
      else if (circuitName === "1") measPoint1 = "Load/Building";
      else if (circuitName === "2") measPoint1 = "Solar";
      else measPoint1 = "Line/Grid";
      this.setState({ measPoint1 });
      circuitName = systemBasics.circuitNameMeter2;
      if (circuitName === "0") measPoint2 = "Line/Grid";
      else if (circuitName === "1") measPoint2 = "Load/Building";
      else if (circuitName === "2") measPoint2 = "Solar";
      else measPoint2 = "Line/Grid";
      this.setState({ measPoint1, measPoint2 });
    }
    console.log("Measurement Point for Acuvim 1", this.state.measPoint1);
    console.log("Measurement Point for Acuvim 2", this.state.measPoint2);

    localStorage.setItem("systemMapButton", "noButton");

    this.setState({
      userRole,
      handleID,
      companyName,
      systemStatus,
      systemDescription,
      systemConfiguration,
      systemProduct,
      systemType,
      paramUpdateRate,
      dataVerbosity,
      dataFrequency,
      productSN,
      emaSN,
      attachedSimplModuleSN,
      attachedSimplModuleHandle,
      moduleDataValid,
      acCoupledBackUp,
    });
    const endDate = new Date().getTime();
    const startDate = endDate - 10000000;
    this.computeInterval(startDate, endDate);
  }

  async computeInterval(startDate, endDate) {
    console.log("Start Date", startDate);
    console.log("End Date", endDate);
    console.log("handleID", this.props.match.params.systemHandleID);
    const systemIntervalDataInterim = await getSystemIntervalData(
      this.props.match.params.systemHandleID,
      startDate,
      endDate
    );
    const systemIntervalData = systemIntervalDataInterim.data.data;
    console.log("System Interval Data Interim", systemIntervalDataInterim);
    console.log("System Inteval Data", systemIntervalData);
    const recordLength = systemIntervalData.length;
    console.log("Record Length", recordLength);
    let intervalPeriod = 0;

    let pvIntervalTotal = 0;
    let battIntervalTotal = 0;
    let gridIntervalTotal = 0;
    let bldgIntervalTotal = 0;

    let pvEnergyValue = 0;
    let pvDemandValue = 0;
    let battEnergyValue = 0;
    let battDemandValue = 0;
    let gridEnergyValue = 0;
    let gridDemandValue = 0;
    let bldgEnergyValue = 0;
    let bldgDemandValue = 0;
    let bldgDemandValueNew = 0;
    let savingsIntervalTotal = 0;
    let savingsEnergyValue = 0;
    let bldgMaxDemand = 0;
    let gridMaxDemand = 0;
    let bldgMaxKW = 0;
    let gridMaxKW = 0;

    let demandRate = 0;
    let gridDemand = 0;
    let bldgDemand = 0;

    let pvInterval = [0];
    let date = [0];
    let dataCount = 0;
    let inCompleteData = 0;

    if (recordLength <= 0) intervalPeriod = 900;
    else {
      intervalPeriod =
        systemIntervalData[recordLength - 1].data.intervalDataFreq;
      console.log("Interval Raw Period", intervalPeriod);
      if (intervalPeriod === "oneMinute") intervalPeriod = 60;
      if (intervalPeriod === "fiveMinute") intervalPeriod = 300;
      if (intervalPeriod === "fifteenMinute") intervalPeriod = 900;
      if (intervalPeriod === "thirtyMinute") intervalPeriod = 1800;
      if (intervalPeriod === "sixtyMinute") intervalPeriod = 3600;
      console.log("Interval Calculated", intervalPeriod);
      console.log("Record Length", recordLength);
      console.log("Slave Count in Compute Interval", this.state.slaveCount);

      let i = 0;
      for (i = 0; i < recordLength; i++) {
        if (
          systemIntervalData[i].data.intervalDataFreq ===
          systemIntervalData[recordLength - 1].data.intervalDataFreq
        ) {
          if (
            this.state.systemConfiguration === "0" &&
            this.state.slaveCount > 0
          ) {
            //no acuvim ...only slave units
            if (
              !systemIntervalData[i].data.pvInterval ||
              !systemIntervalData[i].data.batteryInterval
            ) {
              inCompleteData = 1;
              pvIntervalTotal += 0;
              battIntervalTotal += 0;
              pvEnergyValue += 0;
              pvDemandValue = 0;
              battEnergyValue += 0;
              battDemandValue = 0;
            } else {
              pvIntervalTotal +=
                systemIntervalData[i].data.pvInterval / (100 * 3600 * 1000);
              battIntervalTotal +=
                systemIntervalData[i].data.batteryInterval /
                (100 * 3600 * 1000);
              pvEnergyValue += systemIntervalData[i].data.pvEnergyValue / 100;
              pvDemandValue = systemIntervalData[i].data.pvDemandValue / 100;
              battEnergyValue +=
                systemIntervalData[i].data.batteryEnergyValue / 100;
              battDemandValue =
                systemIntervalData[i].data.batteryDemandValue / 100;
            }
          } else if (
            this.state.systemConfiguration === "2" &&
            this.state.slaveCount < 1
          ) {
            //Two-ch acuvim and no slaves
            //convert to 15-min energy by dividing by 4
            demandRate = systemIntervalData[i].data.DemandRate;

            if (this.state.circuitNameMeter1 === "0") {
              console.log("Meter on Grid");
              gridDemand =
                (systemIntervalData[i].data.acuvimInterval_0 * demandRate) /
                1000;
              bldgDemand =
                ((systemIntervalData[i].data.acuvimInterval_0 +
                  systemIntervalData[i].data.acuvimInterval_1) *
                  demandRate) /
                1000;
            } else if (this.state.circuitNameMeter1 === "1") {
              console.log("Meter on Bldg");
              bldgDemand =
                (systemIntervalData[i].data.acuvimInterval_0 * demandRate) /
                1000;
              gridDemand =
                ((systemIntervalData[i].data.acuvimInterval_0 -
                  systemIntervalData[i].data.acuvimInterval_1) *
                  demandRate) /
                1000;
            } else {
              gridDemand = 0.0;
              bldgDemand = 0.0;
            }

            if (gridDemand > gridMaxDemand) {
              gridMaxDemand = gridDemand;
              gridMaxKW = gridDemand / demandRate;
            }
            if (bldgDemand > bldgMaxDemand) {
              bldgMaxDemand = bldgDemand;
              bldgMaxKW = bldgDemand / demandRate;
            }

            pvIntervalTotal +=
              systemIntervalData[i].data.acuvimInterval_1 / (4 * 1000);
            pvDemandValue = systemIntervalData[i].data.acuvimDemandValue_1;
            pvEnergyValue += systemIntervalData[i].data.acuvimEnergyValue_1;

            if (this.state.circuitNameMeter1 === "0") {
              console.log("Meter on Grid");
              gridIntervalTotal +=
                systemIntervalData[i].data.acuvimInterval_0 / (4 * 1000);
              gridDemandValue = systemIntervalData[i].data.acuvimDemandValue_0;
              gridEnergyValue += systemIntervalData[i].data.acuvimEnergyValue_0;

              bldgIntervalTotal +=
                (systemIntervalData[i].data.acuvimInterval_0 +
                  systemIntervalData[i].data.acuvimInterval_1) /
                (4 * 1000);

              bldgDemandValueNew =
                systemIntervalData[i].data.acuvimDemandValue_0 +
                systemIntervalData[i].data.acuvimDemandValue_1;
              if (bldgDemandValueNew > bldgDemandValue)
                bldgDemandValue = bldgDemandValueNew;

              bldgEnergyValue +=
                systemIntervalData[i].data.acuvimEnergyValue_0 +
                systemIntervalData[i].data.acuvimEnergyValue_1;
            } else if (this.state.circuitNameMeter1 === "1") {
              console.log("Meter on Bldg");
              bldgIntervalTotal +=
                systemIntervalData[i].data.acuvimInterval_0 / (4 * 1000);
              bldgDemandValueNew =
                systemIntervalData[i].data.acuvimDemandValue_0;
              if (bldgDemandValueNew > bldgDemandValue)
                bldgDemandValue = bldgDemandValueNew;
              bldgEnergyValue += systemIntervalData[i].data.acuvimEnergyValue_0;

              gridIntervalTotal +=
                (systemIntervalData[i].data.acuvimInterval_0 -
                  systemIntervalData[i].data.acuvimInterval_1) /
                (4 * 1000);
              gridDemandValue =
                systemIntervalData[i].data.acuvimDemandValue_0 -
                systemIntervalData[i].data.acuvimDemandValue_1;
              gridEnergyValue +=
                systemIntervalData[i].data.acuvimEnergyValue_0 -
                systemIntervalData[i].data.acuvimEnergyValue_1;
            } else {
              gridIntervalTotal = 0.0;
              bldgIntervalTotal = 0.0;
            }
          }

          //////////////////////////////////////////////////////////////////////////////////////
          else if (
            this.state.systemConfiguration === "3" &&
            this.state.slaveCount < 1
          ) {
            //Two-ch ATM power meters used in SimplModule SimplMeter II
            //convert to 15-min energy by dividing by 4
            demandRate = systemIntervalData[i].data.DemandRate; //$/kW

            if (this.state.circuitNameMeter1 === "0") {
              console.log("Meter on Grid");
              //All Demand information is in units of kW
              gridDemand = systemIntervalData[i].data.Interval_0 * demandRate; //kW * $/kW
              bldgDemand =
                (systemIntervalData[i].data.Interval_0 +
                  systemIntervalData[i].data.Interval_1) *
                demandRate;
            } else if (this.state.circuitNameMeter1 === "1") {
              console.log("Meter on Bldg");
              bldgDemand = systemIntervalData[i].data.Interval_0 * demandRate;
              gridDemand =
                (systemIntervalData[i].data.Interval_0 -
                  systemIntervalData[i].data.Interval_1) *
                demandRate;
            } else {
              gridDemand = 0.0;
              bldgDemand = 0.0;
            }

            if (gridDemand > gridMaxDemand) {
              gridMaxDemand = gridDemand;
              gridMaxKW = gridDemand / demandRate;
            }
            if (bldgDemand > bldgMaxDemand) {
              bldgMaxDemand = bldgDemand;
              bldgMaxKW = bldgDemand / demandRate;
            }
            //All Energy is in units of kWh
            pvIntervalTotal += systemIntervalData[i].data.Interval_1 / 4;
            pvDemandValue = systemIntervalData[i].data.DemandValue_1;
            pvEnergyValue += systemIntervalData[i].data.EnergyValue_1;

            if (this.state.circuitNameMeter1 === "0") {
              console.log("Meter on Grid");
              gridIntervalTotal += systemIntervalData[i].data.Interval_0 / 4;
              gridDemandValue = systemIntervalData[i].data.DemandValue_0;
              gridEnergyValue += systemIntervalData[i].data.EnergyValue_0;

              bldgIntervalTotal +=
                (systemIntervalData[i].data.Interval_0 +
                  systemIntervalData[i].data.Interval_1) /
                4;

              bldgDemandValueNew =
                systemIntervalData[i].data.DemandValue_0 +
                systemIntervalData[i].data.DemandValue_1;

              if (bldgDemandValueNew > bldgDemandValue)
                bldgDemandValue = bldgDemandValueNew;

              bldgEnergyValue +=
                systemIntervalData[i].data.EnergyValue_0 +
                systemIntervalData[i].data.EnergyValue_1;
            } else if (this.state.circuitNameMeter1 === "1") {
              console.log("Meter on Bldg");
              bldgIntervalTotal += systemIntervalData[i].data.Interval_0 / 4;
              bldgDemandValueNew = systemIntervalData[i].data.DemandValue_0;
              if (bldgDemandValueNew > bldgDemandValue)
                bldgDemandValue = bldgDemandValueNew;
              bldgEnergyValue += systemIntervalData[i].data.EnergyValue_0;

              gridIntervalTotal +=
                (systemIntervalData[i].data.Interval_0 -
                  systemIntervalData[i].data.Interval_1) /
                4;
              gridDemandValue =
                systemIntervalData[i].data.DemandValue_0 -
                systemIntervalData[i].data.DemandValue_1;
              gridEnergyValue +=
                systemIntervalData[i].data.EnergyValue_0 -
                systemIntervalData[i].data.EnergyValue_1;
            } else {
              gridIntervalTotal = 0.0;
              bldgIntervalTotal = 0.0;
            }
          }
          ////////////////////////////////////////////////////////////////////////////////////
          else if (this.state.systemConfiguration === "6") {
            //Tw0-channel SimplMeter master/slave configuration
            //convert to 15-min energy by dividing by 4
            demandRate = systemIntervalData[i].data.DemandRate; //$/kW

            if (this.state.circuitNameMeter1 === "0") {
              console.log("Meter on Grid");
              //All Demand information is in units of kW
              gridDemand =
                systemIntervalData[i].data.meter1Interval * demandRate; //kW * $/kW
              bldgDemand =
                (systemIntervalData[i].data.meter1Interval +
                  systemIntervalData[i].data.meter2Interval) *
                demandRate;
            } else if (this.state.circuitNameMeter1 === "1") {
              console.log("Meter on Bldg");
              bldgDemand =
                systemIntervalData[i].data.meter1Interval * demandRate;
              gridDemand =
                (systemIntervalData[i].data.meter1Interval -
                  systemIntervalData[i].data.meter2Interval) *
                demandRate;
            } else {
              gridDemand = 0.0;
              bldgDemand = 0.0;
            }

            if (gridDemand > gridMaxDemand) {
              gridMaxDemand = gridDemand;
              gridMaxKW = gridDemand / demandRate;
            }
            if (bldgDemand > bldgMaxDemand) {
              bldgMaxDemand = bldgDemand;
              bldgMaxKW = bldgDemand / demandRate;
            }
            //All Energy is in units of kWh
            pvIntervalTotal += systemIntervalData[i].data.meter2Interval / 4;
            pvDemandValue = systemIntervalData[i].data.meter2DemandValue;
            pvEnergyValue += systemIntervalData[i].data.meter2EnergyValue;

            if (this.state.circuitNameMeter1 === "0") {
              console.log("Meter on Grid");
              gridIntervalTotal +=
                systemIntervalData[i].data.meter1Interval / 4;
              gridDemandValue = systemIntervalData[i].data.meter1DemandValue;
              gridEnergyValue += systemIntervalData[i].data.meter1EnergyValue;

              bldgIntervalTotal +=
                (systemIntervalData[i].data.meter1Interval +
                  systemIntervalData[i].data.meter2Interval) /
                4;

              bldgDemandValueNew =
                systemIntervalData[i].data.meter1DemandValue +
                systemIntervalData[i].data.meter2DemandValue;

              if (bldgDemandValueNew > bldgDemandValue)
                bldgDemandValue = bldgDemandValueNew;

              bldgEnergyValue +=
                systemIntervalData[i].data.meter1EnergyValue +
                systemIntervalData[i].data.meter2EnergyValue;
            } else if (this.state.circuitNameMeter1 === "1") {
              console.log("Meter on Bldg");
              bldgIntervalTotal +=
                systemIntervalData[i].data.meter1Interval / 4;
              bldgDemandValueNew = systemIntervalData[i].data.meter1DemandValue;
              if (bldgDemandValueNew > bldgDemandValue)
                bldgDemandValue = bldgDemandValueNew;
              bldgEnergyValue += systemIntervalData[i].data.meter1EnergyValue;

              gridIntervalTotal +=
                (systemIntervalData[i].data.meter1Interval -
                  systemIntervalData[i].data.meter2Interval) /
                4;
              gridDemandValue =
                systemIntervalData[i].data.meter1DemandValue -
                systemIntervalData[i].data.meter2DemandValue;
              gridEnergyValue +=
                systemIntervalData[i].data.meter1EnergyValue -
                systemIntervalData[i].data.meter2EnergyValue;
            } else {
              gridIntervalTotal = 0.0;
              bldgIntervalTotal = 0.0;
            }
          }
          //////////////////////////////////////////////////////////////////////////////////////////

          dataCount++;
        }
      }
    }
    savingsIntervalTotal = pvIntervalTotal + battIntervalTotal;
    savingsEnergyValue = pvEnergyValue + battEnergyValue;

    let demandSavings = bldgMaxDemand - gridMaxDemand;
    let totalSavings = demandSavings + savingsEnergyValue;

    let totalUsage = bldgMaxDemand + bldgEnergyValue;
    let netCosts = totalUsage - totalSavings;
    let percentEnergySavings = (
      (savingsEnergyValue / bldgEnergyValue) *
      100
    ).toFixed(0);
    let percentDemandSavings = ((demandSavings / bldgMaxDemand) * 100).toFixed(
      0
    );
    let percentTotalSavings = ((totalSavings / totalUsage) * 100).toFixed(0);

    gridMaxDemand = gridMaxDemand
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    bldgMaxDemand = bldgMaxDemand
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    demandSavings = demandSavings
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    totalSavings = totalSavings
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    gridMaxKW = gridMaxKW
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    bldgMaxKW = bldgMaxKW
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    totalUsage = totalUsage
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    netCosts = netCosts
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    this.sigFigures(gridIntervalTotal);
    gridIntervalTotal = this.state.sigNumber;
    this.sigFigures(pvIntervalTotal);
    pvIntervalTotal = this.state.sigNumber;
    this.sigFigures(bldgIntervalTotal);
    bldgIntervalTotal = this.state.sigNumber;
    this.sigFigures(battIntervalTotal);
    battIntervalTotal = this.state.sigNumber;
    this.sigFigures(savingsIntervalTotal);
    savingsIntervalTotal = this.state.sigNumber;

    this.sigFigures(gridDemandValue);
    gridDemandValue = this.state.sigNumber;
    this.sigFigures(pvDemandValue);
    pvDemandValue = this.state.sigNumber;
    this.sigFigures(bldgDemandValue);
    bldgDemandValue = this.state.sigNumber;
    this.sigFigures(battDemandValue);
    battDemandValue = this.state.sigNumber;

    //this.sigFigures(gridEnergyValue);
    //gridEnergyValue = this.state.sigNumber;
    //this.sigFigures(pvEnergyValue);
    //pvEnergyValue = this.state.sigNumber;
    //this.sigFigures(bldgEnergyValue);
    // bldgEnergyValue = this.state.sigNumber;
    this.sigFigures(battEnergyValue);
    battEnergyValue = this.state.sigNumber;
    //this.sigFigures(savingsEnergyValue);
    // savingsEnergyValue = this.state.sigNumber;

    bldgEnergyValue = bldgEnergyValue
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    pvEnergyValue = pvEnergyValue
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    savingsEnergyValue = savingsEnergyValue
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    gridEnergyValue = gridEnergyValue
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    this.setState({
      inCompleteData,

      intervalPeriod,
      pvInterval,

      pvIntervalTotal,
      pvEnergyValue,
      pvDemandValue,

      battIntervalTotal,
      battEnergyValue,
      battDemandValue,

      gridIntervalTotal,
      gridEnergyValue,
      gridDemandValue,

      bldgIntervalTotal,
      bldgEnergyValue,
      bldgDemandValue,

      savingsIntervalTotal,
      savingsEnergyValue,

      gridMaxDemand,
      bldgMaxDemand,
      demandSavings,
      totalSavings,

      gridMaxKW,
      bldgMaxKW,

      totalUsage,
      netCosts,

      percentEnergySavings,
      percentDemandSavings,
      percentTotalSavings,

      dataCount,
      date,
    });
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  async getSystemData() {
    let response = {};
    console.log(
      "dasjboard.js: getSystemData()  Got to here with webSocketMode :",
      webSocketMode
    );
    if (webSocketMode) {
      response = ws_getSystemLatestData(this.state.handleID);
      console.log("Websocket: Latest Data", response);
    } else {
      const response_temp = await getSystemLatestData(this.state.handleID);
      console.log("Response Full Info", response_temp);
      response = response_temp.data.data[0];
      console.log("Http: Latest Data", response);
    }

    if (response !== undefined && response !== null) {
      const commMode = response.data.commMode;
      console.log("Comm Mode", commMode);
      const moduleDataValid = response.data.AttachedModuleValid;
      const commProvider = response.data.commProvider;
      console.log("Comm Provider", commProvider);
      const MeshId = response.data.MeshId;
      console.log("Mesh ID", MeshId);
      const wifiSSID = response.data.deviceLedger.SSID;
      let loadOne = false;
      let loadTwo = false;
      let loadThree = false;
      if (this.state.systemConfiguration === "5") {
        const loadsStatus = response.data.loadsStatus;

        if ((loadsStatus & 0x01) !== 0) loadOne = true;
        if ((loadsStatus & 0x02) !== 0) loadTwo = true;
        if ((loadsStatus & 0x04) !== 0) loadThree = true;
      }

      console.log("Load One", loadOne);
      console.log("Load Two", loadTwo);
      console.log("Load Three", loadThree);

      const realtimeEnergyRate = response.data.deviceLedger.realTime.energyRate;
      console.log("Real Time Energy Rate:", realtimeEnergyRate);

      const outageFlag = parseInt(
        response.data.deviceLedger.realTime.outageFlag
      );
      const outageCount =
        parseInt(response.data.deviceLedger.realTime.outageCount) * 10; //Count increase once every 10 second in the timer task in the master unit

      const systemTimeZone = parseInt(
        response.data.deviceLedger.realTime.TimeZone
      );
      const timeStampLatestData = parseInt(response.timestamp / 1000);
      console.log("Latest Data's Time Stamp", timeStampLatestData);
      const epochLocalTime = Math.round(new Date().getTime() / 1000);
      console.log("Epoch Time", epochLocalTime);
      const systemTime = parseInt(
        response.data.deviceLedger.realTime.SystemLocalTimeSeconds
      );
      console.log("System Time:", systemTime);

      //Deal with Old Data when reading info from latestData route
      const timeDelta = Math.abs(timeStampLatestData - epochLocalTime);
      let dataTimeInvalid = false;
      //calculate maxDelta = dataFrequency + 10 seconds
      //If wifi dataFrequncy = whatever is set in systemBasics
      //If 4G dataFrequency = dataFrequency from data plan
      const systemBasics = await getSystemBasics(this.state.handleID);
      console.log("System Basics", systemBasics);
      const circuitNameMeter1 = systemBasics.data.data.basics.circuitNameMeter1;
      console.log("Grid or Bldg: ", circuitNameMeter1);
      const dataFrequency = systemBasics.data.data.basics.dataFrequency;
      console.log("Data Frequency", dataFrequency);
      let dataFrequency_delta = 0;
      if (dataFrequency === "1") dataFrequency_delta = 1;
      //"1", name: "once / Second"
      else if (dataFrequency === "2") dataFrequency_delta = 5;
      //"2", name: "once / 5 Seconds"
      else if (dataFrequency === "3") dataFrequency_delta = 15;
      //"3", name: "once / 15 Seconds"
      else if (dataFrequency === "4") dataFrequency_delta = 60;
      //"4", name: "Once / Minute"
      else if (dataFrequency === "5") dataFrequency_delta = 300;
      //"5", name: "Once / 5 Minutes"
      else if (dataFrequency === "6") dataFrequency_delta = 900;
      //"6", name: "Once / 15 Minutes"
      else if (dataFrequency === "7") dataFrequency_delta = 1800;
      //"7", name: "Once / 30 Minutes"
      else if (dataFrequency === "8") dataFrequency_delta = 3600; //"8", name: "Once / Hour"

      if (commMode === 2 && dataFrequency_delta < 15) dataFrequency_delta = 15; //If 4G, should not go any faster than 15 seconds
      console.log("dataFreqency_delta", dataFrequency_delta);
      if (timeDelta > 15 + dataFrequency_delta) {
        dataTimeInvalid = true;
      } else dataTimeInvalid = false;
      console.log("Delta Time :", timeDelta);
      console.log("Data Time Invalid", dataTimeInvalid);

      const loopCount = response.data.deviceLedger.realTime.loopCount;
      console.log("Loop Count ", loopCount);

      const fwVersionMaster = response.data.deviceLedger.fwVersion;
      const fwAppName = response.data.deviceLedger.appName;
      //console.log("Master Firmware Version", fwVersionMaster);

      const wifiSignalStrength = response.data.deviceLedger.realTime.RSSI;
      //console.log("Wifi Signal Strength", wifiSignalStrength);

      const slaveCount = response.data.deviceLedger.realTime.slaveCount;

      console.log("System Time Zone:", systemTimeZone);
      let sysT = new Date((systemTime - systemTimeZone * 3600) * 1000);
      const systemTimeOffset = systemTimeZone * 60 * 60 * 1000;
      const userTimeOffset = sysT.getTimezoneOffset() * 60 * 1000;
      sysT = new Date(sysT.getTime() + userTimeOffset + systemTimeOffset);
      let hourTime = sysT.getHours();
      if (hourTime < 10) hourTime = "0" + hourTime;
      let minuteTime = sysT.getMinutes();
      if (minuteTime < 10) minuteTime = "0" + minuteTime;
      let secTime = sysT.getSeconds();
      if (secTime < 10) secTime = "0" + secTime;
      const systemHumanTime = [hourTime, minuteTime, secTime].join(":");
      console.log("System Human Time:", systemHumanTime);
      this.setState({ systemHumanTime });

      const endDate = new Date().getTime() - 10;
      const startDate = endDate - this.state.intervalPeriod * 1.2 * 1000;
      const systemIntervalDataInterim = await getSystemIntervalData(
        this.props.match.params.systemHandleID,
        startDate,
        endDate
      );
      console.log("Interval Start Time: ", startDate);
      console.log("Interval End Date: ", endDate);
      console.log("Interval Data", systemIntervalDataInterim);
      const systemIntervalData = systemIntervalDataInterim.data.data;
      const recordLength = systemIntervalData.length;
      console.log("System Interval Data Interim", systemIntervalDataInterim);
      let energyRate = 0;
      let demandRate = 0;
      let onPeakEnergy = 0;
      let onPeakDemand = 0;
      if (recordLength >= 1) {
        energyRate = systemIntervalData[recordLength - 1].data.EnergyRate;
        demandRate = systemIntervalData[
          recordLength - 1
        ].data.DemandRate.toFixed(2);
        onPeakEnergy = systemIntervalData[recordLength - 1].data.OnPeakEnergy;
        onPeakDemand = systemIntervalData[recordLength - 1].data.OnPeakDemand;
      }

      //console.log("Energy Rate", energyRate);
      this.setState({ energyRate, demandRate, onPeakEnergy, onPeakDemand });

      this.setState({
        realTime: response.data.deviceLedger.realTime,
      });
      this.setState({
        acuvimData: response.data.deviceLedger.acuvim,
      });
      /////////////////////////////////////////////////////////////////////
      if (this.state.systemConfiguration === "3")
        this.setState({
          simplMeter_iiData: response.data.deviceLedger.simplMeter_ii,
        });
      if (this.state.systemConfiguration === "6")
        this.setState({
          simplMeter_slave: response.data.deviceLedger.realTime,
        });
      /////////////////////////////////////////////////////////////////
      this.setState({
        Interval: response.data.deviceLedger.Interval,
      });

      let pvPower = 0;
      let pvPowerUnits = this.state.pvPowerUnits;
      let batteryPower = 0;
      let batteryPowerUnits = this.state.batteryPowerUnits;
      let gridPower = 0;
      let bldgPower = 0;
      let gridPowerUnits = this.state.gridPowerUnits;
      let bldgPowerUnits = this.state.bldgPowerUnits;
      if (
        this.state.systemConfiguration === "0" &&
        this.state.realTime.slaveCount > 0
      ) {
        pvPower = this.state.realTime.pvPower / 100;
        batteryPower = this.state.realTime.batteryPower / 100;
        gridPower = (-1.0 * this.state.realTime.inverterPower) / 100.0;
        bldgPower = this.state.realTime.buildingPower / 100; //0.0;
      } else if (
        this.state.systemConfiguration === "1" &&
        response.data.deviceLedger.acuvim !== undefined
      ) {
        gridPower = this.state.acuvimData.acuvimPower0;
        pvPower = 0.0;
        batteryPower = 0.0;
        bldgPower = 0.0;
      } else if (
        this.state.systemConfiguration === "2" &&
        response.data.deviceLedger.acuvim !== undefined
      ) {
        pvPower = this.state.acuvimData.acuvimPower1;
        if (circuitNameMeter1 === "0") {
          console.log("Meter on Grid");
          gridPower = this.state.acuvimData.acuvimPower0;
          bldgPower = gridPower + this.state.acuvimData.acuvimPower1;
        } else if (circuitNameMeter1 === "1") {
          console.log("Meter on Bldg");
          bldgPower = this.state.acuvimData.acuvimPower0;
          gridPower = bldgPower - this.state.acuvimData.acuvimPower1;
        } else {
          gridPower = 0.0;
          bldgPower = 0.0;
        }
        batteryPower = 0.0;
      }

      ///////////////////////////////////////////////////////////////////////////
      else if (
        this.state.systemConfiguration === "3" &&
        response.data.deviceLedger.simplMeter_ii !== undefined
      ) {
        if (this.state.simplMeter_iiData.sm2Ptotal2 !== null)
          pvPower = this.state.simplMeter_iiData.sm2Ptotal2;
        else pvPower = 0;
        if (circuitNameMeter1 === "0") {
          console.log("Meter on Grid");
          gridPower = this.state.simplMeter_iiData.sm2Ptotal1;
          bldgPower = gridPower + this.state.simplMeter_iiData.sm2Ptotal2;
        } else if (circuitNameMeter1 === "1") {
          console.log("Meter on Bldg");
          bldgPower = this.state.simplMeter_iiData.sm2Ptotal1;
          gridPower = bldgPower - this.state.simplMeter_iiData.sm2Ptotal2;
        } else {
          gridPower = 0.0;
          bldgPower = 0.0;
        }
        batteryPower = 0.0;
      } else if (
        this.state.systemConfiguration === "6" &&
        response.data.deviceLedger.slaveUnits !== undefined
      ) {
        if (this.state.simplMeter_slave.AggPower2 !== null)
          pvPower = this.state.simplMeter_slave.AggPower2;
        else pvPower = 0;
        if (circuitNameMeter1 === "0") {
          console.log("Meter on Grid");
          gridPower = this.state.simplMeter_slave.AggPower1;
          bldgPower = gridPower + this.state.simplMeter_slave.AggPower2;
        } else if (circuitNameMeter1 === "1") {
          console.log("Meter on Bldg");
          bldgPower = this.state.simplMeter_slave.AggPower1;
          gridPower = bldgPower - this.state.simplMeter_slave.AggPower2;
        } else {
          gridPower = 0.0;
          bldgPower = 0.0;
        }
        batteryPower = 0.0;
      }
      /////////////////////////////////////////////////////////////////////////////
      /*
    this.powerNunits(gridPower);
    gridPower = this.state.power;
    gridPowerUnits = this.state.powerUnits;
    this.setState({ gridPower, gridPowerUnits });

    this.powerNunits(bldgPower);
    bldgPower = this.state.power;
    bldgPowerUnits = this.state.powerUnits;
    this.setState({ bldgPower, bldgPowerUnits });

    this.powerNunits(pvPower);
    pvPower = this.state.power;
    pvPowerUnits = this.state.powerUnits;
    this.setState({ pvPower, pvPowerUnits });

    this.powerNunits(batteryPower);
    batteryPower = this.state.power;
    batteryPowerUnits = this.state.powerUnits;
    this.setState({ batteryPower, batteryPowerUnits });*/

      /////////////////////////////////////
      let solarProgressBar = 0;
      let batteryProgressBar = 0;
      let gridProgressBar = 0;

      /*  if (this.state.bldgPower > 0) {
      console.log("In here Bldg Power > 0");
      solarProgressBar = (
        (this.state.pvPower * 100) /
        this.state.bldgPower
      ).toFixed(1);
      batteryProgressBar = (
        (this.state.batteryPower * 100) /
        this.state.bldgPower
      ).toFixed(1);
      gridProgressBar = (
        (this.state.gridPower * 100) /
        this.state.bldgPower
      ).toFixed(1);
    } else {
      gridProgressBar = 100.0;
      if (this.state.batteryPower > 0) {
        //if discharging
        solarProgressBar = (
          (parseFloat(this.state.pvPower) * 100) /
          (parseFloat(this.state.pvPower) + parseFloat(this.state.batteryPower))
        ).toFixed(1);
        batteryProgressBar = (
          (parseFloat(this.state.batteryPower) * 100) /
          (parseFloat(this.state.pvPower) + parseFloat(this.state.batteryPower))
        ).toFixed(1);
      } else {
        //if charging
        solarProgressBar = 100.0;
        batteryProgressBar = 0.0;
      }
    }*/
      if (bldgPower > 0) {
        console.log("In here Bldg Power > 0");
        solarProgressBar = ((pvPower * 100) / bldgPower).toFixed(1);
        batteryProgressBar = ((batteryPower * 100) / bldgPower).toFixed(1);
        gridProgressBar = ((gridPower * 100) / bldgPower).toFixed(1);
      } else {
        gridProgressBar = 100.0;
        if (batteryPower > 0) {
          //if discharging
          solarProgressBar = (
            (parseFloat(pvPower) * 100) /
            (parseFloat(pvPower) + parseFloat(batteryPower))
          ).toFixed(1);
          batteryProgressBar = (
            (parseFloat(batteryPower) * 100) /
            (parseFloat(pvPower) + parseFloat(batteryPower))
          ).toFixed(1);
        } else {
          //if charging
          solarProgressBar = 100.0;
          batteryProgressBar = 0.0;
        }
      }
      /////////////////////////////////////////////
      this.setState({ solarProgressBar, batteryProgressBar, gridProgressBar });
      /////////////////////////////////////////////////////////

      /////////////////////////////////////////////////////////////////////////////
      console.log("Dashboard 1");
      this.powerNunits(gridPower);
      gridPower = this.state.power;
      gridPowerUnits = this.state.powerUnits;
      this.setState({ gridPower, gridPowerUnits });
      console.log("Dashboard 2");
      this.powerNunits(bldgPower);
      bldgPower = this.state.power;
      bldgPowerUnits = this.state.powerUnits;
      this.setState({ bldgPower, bldgPowerUnits });
      console.log("Dashboard 3: PV Power:", pvPower);
      this.powerNunits(pvPower);
      pvPower = this.state.power;
      pvPowerUnits = this.state.powerUnits;
      this.setState({ pvPower, pvPowerUnits });
      console.log("Dashboard 4");
      this.powerNunits(batteryPower);
      batteryPower = this.state.power;
      batteryPowerUnits = this.state.powerUnits;
      this.setState({ batteryPower, batteryPowerUnits });
      console.log("Dashboard 5");
      /////////////////////////////////////

      let pvEnergy = 0;
      let batteryEnergy = 0;
      if (pvEnergy && batteryEnergy) {
        pvEnergy = this.state.Interval.pvEnergy / 100;
        batteryEnergy = this.state.Interval.batteryEnergy / 100;
      }

      this.setState({
        pvEnergy,
        batteryEnergy,
      });

      this.setState({
        soc: (
          (this.state.realTime.batteryPower / this.state.realTime.pvPower) *
          100
        ).toFixed(1),
      });
      this.setState({
        loopCount,
        fwAppName,
        fwVersionMaster,
        commMode,
        commProvider,
        MeshId,
        moduleDataValid,
        wifiSignalStrength,
        wifiSSID,
        slaveCount,
        dataTimeInvalid,
        loadOne,
        loadTwo,
        loadThree,
        circuitNameMeter1,
        outageFlag,
        outageCount,
      });
      this.setState({ slaveCount: this.state.realTime.slaveCount });
    }
  }

  sigFigures(powerDollar) {
    let sigNumber = 0;
    if (powerDollar < 100) sigNumber = powerDollar.toFixed(2);
    else if (powerDollar < 1000) sigNumber = powerDollar.toFixed(1);
    else
      sigNumber = powerDollar
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.setState({ sigNumber });
  }

  powerNunits(power) {
    let powerUnits = "Watts";
    let powerAbs = Math.abs(power);
    if (powerAbs < 100) {
      if (power >= 0) power = power.toFixed(2);
      else power = power.toFixed(1);
      powerUnits = "Watts";
    } else if (powerAbs < 1000) {
      if (power >= 0) power = power.toFixed(1);
      else power = power.toFixed(0);
      powerUnits = "Watts";
    } else if (powerAbs < 10000) {
      if (power >= 0) power = (power / 1000).toFixed(3);
      else power = (power / 1000).toFixed(2);
      powerUnits = "kW";
    } else if (powerAbs < 100000) {
      if (power >= 0) power = (power / 1000).toFixed(2);
      else power = (power / 1000).toFixed(1);
      powerUnits = "kW";
    } else if (powerAbs < 1000000) {
      if (power >= 0) power = (power / 1000).toFixed(1);
      else power = (power / 1000).toFixed(0);
      powerUnits = "kW";
    } else if (powerAbs < 10000000) {
      if (power >= 0) power = (power / 1000000).toFixed(3);
      else power = (power / 1000000).toFixed(2);
      powerUnits = "MW";
    } else if (powerAbs < 100000000) {
      if (power >= 0) power = (power / 1000000).toFixed(2);
      else power = (power / 1000000).toFixed(1);
      powerUnits = "MW";
    } else if (powerAbs < 1000000000) {
      if (power >= 0) power = (power / 1000000).toFixed(1);
      else power = (power / 1000000).toFixed(0);
      powerUnits = "MW";
    } else if (powerAbs < 10000000000) {
      if (power >= 0) power = (power / 1000000000).toFixed(3);
      else power = (power / 1000000000).toFixed(2);
      powerUnits = "GW";
    }
    this.setState({ power, powerUnits });
  }

  tick() {
    this.setState({
      dateNow: new Date(),
    });

    this.getSystemData();
  }

  handleChangeStart = (event) => {
    const startDate = event.setHours(0, 0, 0);
    this.setState({ startDate });
  };

  handleChangeEnd = (event) => {
    const endDate = event.setHours(0, 0, 0);
    this.setState({ endDate });
  };

  handleClickMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseRT = () => {
    this.setState({ anchorEl: null });
    const endDate = new Date().getTime() - 10;
    const startDate = endDate - this.state.intervalPeriod * 1.2 * 1000;
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Real-time", startDate, endDate });
  };

  handleCloseInterval = () => {
    this.setState({ anchorEl: null });
    const endDate = new Date().getTime() - 10;
    const startDate = endDate - this.state.intervalPeriod * 1.2 * 1000;
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Last Interval", startDate, endDate });
  };
  handleCloseToday = () => {
    this.setState({ anchorEl: null });
    const startDate = new Date().setHours(0, 0, 0);
    const endDate = new Date().getTime();
    //console.log("CloseToday StartDate:", startDate);
    //console.log("CloseToday EndDate:", endDate);
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Today", startDate, endDate });
  };
  handleCloseYesterday = () => {
    this.setState({ anchorEl: null });
    const startDate = new Date().setHours(-24, 0, 0);
    const endDate = new Date().setHours(0, 0, 0);
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Yesterday", startDate, endDate });
  };
  handleCloseWeek = () => {
    this.setState({ anchorEl: null });
    let myDay = new Date().getDay();
    let startDate = new Date();
    console.log("Day of the week", myDay);
    if (myDay !== 0) startDate = new Date().setHours(-24 * (myDay - 1), 0, 0);
    else {
      myDay = 7;
      startDate = new Date().setHours(-24 * (myDay - 1), 0, 0);
    }
    const endDate = new Date().getTime();
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Week", startDate, endDate });
  };
  handleCloseLastWeek = () => {
    this.setState({ anchorEl: null });
    let myDay = new Date().getDay();
    let startDate = new Date();
    console.log("Day of the week", myDay);
    if (myDay !== 0) startDate = new Date().setHours(-24 * (myDay - 1), 0, 0);
    else {
      myDay = 7;
      startDate = new Date().setHours(-24 * (myDay - 1), 0, 0);
    }
    const endDate = startDate;
    startDate = startDate - 7 * 24 * 3600 * 1000;
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Last Week", startDate, endDate });
  };
  handleCloseMonth = () => {
    this.setState({ anchorEl: null });
    const myDay = new Date(),
      y = myDay.getFullYear(),
      m = myDay.getMonth();
    const startDate = new Date(y, m, 1).setHours(0, 0, 0);
    const endDate = new Date().getTime();
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Month", startDate, endDate });
  };
  handleCloseLastMonth = () => {
    this.setState({ anchorEl: null });
    const myDay = new Date(),
      y = myDay.getFullYear(),
      m = myDay.getMonth();
    const endDate = new Date(y, m, 1).setHours(0, 0, 0);
    let startDate = new Date();
    if (m > 0) startDate = new Date(y, m - 1, 1).setHours(0, 0, 0);
    else startDate = new Date(y - 1, 11, 1).setHours(0, 0, 0);
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Last Month", startDate, endDate });
  };
  handleCloseQuarter = () => {
    this.setState({ anchorEl: null });
    const myDay = new Date();
    const y = myDay.getFullYear();
    let m = myDay.getMonth();
    if (m >= 0 && m <= 2) m = 0;
    else if (m >= 3 && m <= 5) m = 3;
    else if (m >= 6 && m <= 8) m = 6;
    else m = 9;
    const startDate = new Date(y, m, 1).setHours(0, 0, 0);
    const endDate = new Date().getTime();
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Quarter", startDate, endDate });
  };
  handleCloseYear = () => {
    this.setState({ anchorEl: null });
    const myDay = new Date();
    const y = myDay.getFullYear();
    const startDate = new Date(y, 0, 1).setHours(0, 0, 0);
    const endDate = new Date().getTime();
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Year", startDate, endDate });
  };
  handleCloseDR = () => {
    this.setState({ anchorEl: null });
    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
    console.log("Start Date", startDate);
    console.log("End Date", endDate);
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Date Range" });
  };
  handleCloseDaily = () => {
    this.setState({ anchorEl: null });
    console.log("Dashboard Daily");
    this.setState({ modeBox: "Daily" });
    this.props.history.push(
      `/systems/dashboardDaily/${this.state.handleID}/${this.state.systemConfiguration}`
    );
  };
  render() {
    //console.log("User Role", this.state.userRole);
    //  console.log("End Date", this.state.endDate);
    return (
      <React.Fragment>
        <div
          className="containerSimplView"
          style={{ marginTop: "120px", marginBottom: "150px", zIndex: 1 }}
        >
          <p
            className="basicParagraph"
            style={{
              width: "350px",
              fontSize: "12px",
              color: "#777575",
              textAlign: "center",
              paddingRight: "25px",
            }}
          >
            {this.state.systemName}
          </p>
          <div className="mainMenuIcons">
            <p className="systemClock" style={{ marginBottom: "0px" }}>
              <FontAwesomeIcon
                style={{ marginLeft: "0", marginRight: "3px" }}
                icon="clock"
                size="lg"
                color="white"
                title="Local System Time"
              />
              {this.state.systemHumanTime}

              {this.state.commMode === 0 && (
                <FontAwesomeIcon
                  style={{ marginLeft: "20px", marginRight: "3px" }}
                  icon="wifi"
                  size="lg"
                  color="red"
                  title="Wifi Signal Strength"
                />
              )}
              {(this.state.commMode === 1 || this.state.commMode === 3) && (
                <FontAwesomeIcon
                  style={{ marginLeft: "20px", marginRight: "3px" }}
                  icon="wifi"
                  size="lg"
                  color="white"
                  title="Wifi Signal Strength"
                />
              )}
              {this.state.commMode === 2 && (
                <FontAwesomeIcon
                  style={{ marginLeft: "20px", marginRight: "3px" }}
                  icon="signal"
                  size="lg"
                  color="white"
                  title="Wifi Signal Strength"
                />
              )}
              {this.state.wifiSignalStrength}

              <FontAwesomeIcon
                style={{ marginLeft: "20px", marginRight: "3px" }}
                icon="square"
                size="lg"
                color="white"
                title="# of SimplBoxes"
              />

              {this.state.slaveCount}

              {this.state.loopCount % 2 === 0 && (
                <FontAwesomeIcon
                  style={{ marginLeft: "20px", marginRight: "3px" }}
                  icon="heartbeat"
                  size="lg"
                  color="white"
                  title="System Heartbeat"
                />
              )}

              {this.state.loopCount % 2 === 1 && (
                <FontAwesomeIcon
                  style={{ marginLeft: "20px", marginRight: "3px" }}
                  icon="heartbeat"
                  size="lg"
                  color="#c5c2c2"
                  title="System Heartbeat"
                />
              )}

              {this.state.outageFlag === 0 &&
                this.state.loopCount % 2 === 0 &&
                this.state.acCoupledBackUp === 1 && (
                  <FontAwesomeIcon
                    style={{ marginLeft: "20px", marginRight: "3px" }}
                    icon="charging-station"
                    size="lg"
                    color="white"
                    title="On Grid"
                  />
                )}
              {this.state.outageFlag === 0 &&
                this.state.loopCount % 2 === 1 &&
                this.state.acCoupledBackUp === 1 && (
                  <FontAwesomeIcon
                    style={{ marginLeft: "20px", marginRight: "3px" }}
                    icon="charging-station"
                    size="lg"
                    color="#c5c2c2"
                    title="On Grid"
                  />
                )}

              {this.state.outageFlag === 1 &&
                this.state.acCoupledBackUp === 1 && (
                  <FontAwesomeIcon
                    style={{ marginLeft: "20px", marginRight: "3px" }}
                    icon="charging-station"
                    size="lg"
                    color="#c5c2c2"
                    title="Power Outage"
                  />
                )}

              {this.state.systemConfiguration === "5" && this.state.loadOne && (
                <button
                  className="led-green-on"
                  style={{ marginLeft: "15px", marginBottom: "7px" }}
                ></button>
              )}
              {this.state.systemConfiguration === "5" &&
                !this.state.loadOne && (
                  <button
                    className="led-red-on"
                    style={{ marginLeft: "15px", marginBottom: "7px" }}
                  ></button>
                )}
              {this.state.systemConfiguration === "5" && this.state.loadTwo && (
                <button
                  className="led-green-on"
                  style={{ marginLeft: "15px", marginBottom: "7px" }}
                ></button>
              )}
              {this.state.systemConfiguration === "5" &&
                !this.state.loadTwo && (
                  <button
                    className="led-red-on"
                    style={{ marginLeft: "15px", marginBottom: "7px" }}
                  ></button>
                )}
              {this.state.systemConfiguration === "5" &&
                this.state.loadThree && (
                  <button
                    className="led-green-on"
                    style={{ marginLeft: "15px", marginBottom: "7px" }}
                  ></button>
                )}
              {this.state.systemConfiguration === "5" &&
                !this.state.loadThree && (
                  <button
                    className="led-red-on"
                    style={{ marginLeft: "15px", marginBottom: "7px" }}
                  ></button>
                )}
            </p>
            {this.state.inCompleteData === 1 && (
              <p
                className="systemClock"
                style={{ paddingTop: "2px", fontSize: "9px" }}
              >
                Warning: Missing data points!
              </p>
            )}

            <table>
              <tr style={{ height: "40px" }}>
                {(this.state.userRole === "operatorOf" ||
                  this.state.userRole === "companyAdmin") && (
                  <td style={{ width: "140px", padding: "0", margin: "0" }}>
                    {this.state.modeBox === "Date Range" && (
                      <div>
                        {" "}
                        <DatePicker
                          className="ui-datepicker"
                          selected={this.state.startDate}
                          selectsStart
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          onChange={this.handleChangeStart}
                        />
                        <DatePicker
                          className="ui-datepicker"
                          selected={this.state.endDate}
                          selectsEnd
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          onChange={this.handleChangeEnd}
                        />
                      </div>
                    )}
                  </td>
                )}

                {(this.state.userRole === "operatorOf" ||
                  this.state.userRole === "companyAdmin") && (
                  <td>
                    <p
                      className="indicateParagraph"
                      style={{
                        position: "relative",
                        marginRight: "0px",
                        borderWidth: "0px",
                      }}
                    >
                      {" "}
                      {this.state.modeBox}
                    </p>
                  </td>
                )}

                {(this.state.userRole === "operatorOf" ||
                  this.state.userRole === "companyAdmin") && (
                  <td style={{ position: "relative", marginRight: "0px" }}>
                    <SimpleMenu
                      onClickingMenu={this.handleClickMenu}
                      onChoosingRT={this.handleCloseRT}
                      onChoosingInterval={this.handleCloseInterval}
                      onChoosingToday={this.handleCloseToday}
                      onChoosingYesterday={this.handleCloseYesterday}
                      onChoosingWeek={this.handleCloseWeek}
                      onChoosingLastWeek={this.handleCloseLastWeek}
                      onChoosingMonth={this.handleCloseMonth}
                      onChoosingLastMonth={this.handleCloseLastMonth}
                      onChoosingQuarter={this.handleCloseQuarter}
                      onChoosingYear={this.handleCloseYear}
                      onChoosingDR={this.handleCloseDR}
                      onChoosingDaily={this.handleCloseDaily}
                      anchorState={this.state.anchorEl}
                    />
                  </td>
                )}

                {(this.state.userRole === "operatorOf" ||
                  this.state.userRole === "companyAdmin") && (
                  <td style={{ position: "relative", marginRight: "0px" }}>
                    <button
                      className="btn btn-outline-light"
                      style={{
                        borderWidth: "0px",
                        padding: "0px",
                        float: "right",
                        marginRight: "5px",
                        position: "relative",
                        right: "0px",
                      }}
                    >
                      <Link
                        to={`/systems/dashboardChart/${this.props.match.params.systemHandleID}
                        /${this.props.match.params.companyName}
                        /${this.state.startDate}
                        /${this.state.endDate}
                        /${this.props.match.params.userRole}`}
                      >
                        <FontAwesomeIcon
                          icon="chart-line"
                          size="sm"
                          color="orange"
                          title="System Information"
                        />
                      </Link>
                    </button>
                  </td>
                )}

                {(this.state.userRole === "operatorOf" ||
                  this.state.userRole === "companyAdmin") && (
                  <td style={{ position: "relative", marginRight: "0px" }}>
                    <CostNSavingsModal
                      pvIntervalTotal={this.state.pvIntervalTotal}
                      pvEnergyValue={this.state.pvEnergyValue}
                      pvDemandValue={this.state.pvDemandValue}
                      battIntervalTotal={this.state.battIntervalTotal}
                      battEnergyValue={this.state.battEnergyValue}
                      battDemandValue={this.state.battDemandValue}
                      gridIntervalTotal={this.state.gridIntervalTotal}
                      gridEnergyValue={this.state.gridEnergyValue}
                      gridDemandValue={this.state.gridDemandValue}
                      bldgIntervalTotal={this.state.bldgIntervalTotal}
                      bldgEnergyValue={this.state.bldgEnergyValue}
                      bldgDemandValue={this.state.bldgDemandValue}
                      savingsIntervalTotal={this.state.savingsIntervalTotal}
                      savingsEnergyValue={this.state.savingsEnergyValue}
                      gridMaxDemand={this.state.gridMaxDemand}
                      bldgMaxDemand={this.state.bldgMaxDemand}
                      demandSavings={this.state.demandSavings}
                      totalSavings={this.state.totalSavings}
                      gridMaxKW={this.state.gridMaxKW}
                      bldgMaxKW={this.state.bldgMaxKW}
                      totalUsage={this.state.totalUsage}
                      netCosts={this.state.netCosts}
                      percentEnergySavings={this.state.percentEnergySavings}
                      percentDemandSavings={this.state.percentDemandSavings}
                      percentTotalSavings={this.state.percentTotalSavings}
                    />
                  </td>
                )}

                {(this.state.userRole === "operatorOf" ||
                  this.state.userRole === "companyAdmin") && (
                  <td style={{ position: "relative", marginRight: "0px" }}>
                    <button
                      className="btn btn-outline-light"
                      style={{
                        borderWidth: "0px",
                        padding: "0px",
                        float: "right",
                        marginRight: "5px",
                        position: "relative",
                        right: "0px",
                      }}
                    >
                      <Link
                        to={`/systems/dashboardGauges/${this.props.match.params.systemHandleID}
                        /${this.props.match.params.companyName}`}
                      >
                        <FontAwesomeIcon
                          icon="tachometer-alt"
                          size="sm"
                          color="red"
                          title="Indicators"
                        />
                      </Link>
                    </button>
                  </td>
                )}

                {this.state.userRole !== "ownerOf" && (
                  <td
                    style={{
                      position: "fixed",
                      right: "22px",
                      marginTop: "7px",
                    }}
                  >
                    <button
                      className="btn btn-outline-light"
                      style={{
                        borderWidth: "0px",
                        padding: "0px",
                        float: "right",
                        marginRight: "5px",
                        position: "relative",
                        right: "0px",
                      }}
                    >
                      <Link
                        to={`/systems/dashboardWideSlaves/${this.props.match.params.systemHandleID}
                        /${this.props.match.params.companyName}`}
                      >
                        <FontAwesomeIcon
                          icon="search"
                          size="sm"
                          color="brown"
                          title="Subsystems"
                        />
                      </Link>
                    </button>
                  </td>
                )}

                <td
                  style={{ position: "fixed", right: "3px", marginTop: "7px" }}
                >
                  <SystemInfoModal
                    loopCount={this.state.loopCount}
                    fwVersionMaster={this.state.fwVersionMaster}
                    fwAppName={this.state.fwAppName}
                    slaveCount={this.state.slaveCount}
                    companyName={this.state.companyName}
                    energyRate={this.state.energyRate}
                    demandRate={this.state.demandRate}
                    onPeakEnergy={this.state.onPeakEnergy}
                    onPeakDemand={this.state.onPeakDemand}
                    systemStatus={this.state.systemStatus}
                    HandleID={this.props.match.params.systemHandleID}
                    systemDescription={this.state.systemDescription}
                    systemConfiguration={this.state.systemConfiguration}
                    systemProduct={this.state.systemProduct}
                    systemType={this.state.systemType}
                    paramUpdateRate={this.state.paramUpdateRate}
                    dataVerbosity={this.state.dataVerbosity}
                    dataFrequency={this.state.dataFrequency}
                    serialNumber={this.state.serialNumber}
                    macAddress={this.state.macAddress}
                    systemName={this.state.systemName}
                    commProvider={this.state.commProvider}
                    productSN={this.state.productSN}
                    emaSN={this.state.emaSN}
                    attachedSimplModuleSN={this.state.attachedSimplModuleSN}
                    attachedSimplModuleHandle={
                      this.state.attachedSimplModuleHandle
                    }
                    moduleDataValid={this.state.moduleDataValid}
                    ssId={this.state.wifiSSID}
                    MeshId={this.state.MeshId}
                    outageFlag={this.state.outageFlag}
                    outageCount={this.state.outageCount}
                    acCoupledBackUp={this.state.acCoupledBackUp}
                  />
                </td>
              </tr>
            </table>
          </div>

          <div className="mainMenuContainer">
            <button
              className="mainMenu"
              style={{
                backgroundColor: "orange",
                borderColor: "orange",
                opacity: "1.0",
              }}
            >
              <p
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "10px",
                  textAlign: "left",
                  marginBottom: "1px",
                }}
              >
                Generating Solar Power:
              </p>
              {!this.state.dataTimeInvalid && (
                <p>
                  {this.state.pvPower}
                  <span
                    style={{
                      color: "white",
                      fontWeight: "normal",
                      fontSize: "10px",
                      textAlign: "right",
                      marginBottom: "2px",
                    }}
                  >
                    {" "}
                    {this.state.pvPowerUnits}
                  </span>
                </p>
              )}
              {this.state.dataTimeInvalid && <p>--NA--</p>}

              {this.state.modeBox !== "Real-time" && (
                <div>
                  <p
                    style={{
                      color: "white",
                      fontWeight: "normal",
                      fontSize: "9px",
                      textAlign: "center",
                      marginBottom: "2px",
                      padding: "0px",
                      transform: "translateY(-12px)",
                    }}
                  >
                    <span>{"Using "}</span>
                    {(this.state.intervalPeriod / 60).toFixed(0)}
                    <span>{"-minute Interval Data:"}</span>
                  </p>

                  <p
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "11px",
                      textAlign: "center",
                      marginBottom: "2px",
                      padding: "0px",
                      transform: "translateY(-12px)",
                    }}
                  >
                    {this.state.pvIntervalTotal}
                    <span>{" kWh"}</span>
                  </p>

                  <p
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "11px",
                      textAlign: "center",
                      marginBottom: "2px",
                      padding: "0px",
                      transform: "translateY(-12px)",
                    }}
                  >
                    Energy Value: ${this.state.pvEnergyValue}{" "}
                  </p>
                  <p
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "11px",
                      textAlign: "center",
                      marginBottom: "2px",
                      padding: "0px",
                      transform: "translateY(-12px)",
                    }}
                  >
                    Demand Value: ${this.state.pvDemandValue}
                  </p>
                </div>
              )}

              <p className="percentParagraph">{this.state.solarProgressBar}%</p>
              <ProgressBar
                className="progressBarBox"
                variant="warning"
                style={{ height: "4px" }}
                now={this.state.solarProgressBar}
              />
            </button>
            {this.state.batteryPower > 0 && (
              <button
                className="mainMenu"
                style={{
                  backgroundColor: "dodgerblue",
                  borderColor: "dodgerblue",
                }}
              >
                <p
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "10px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Discharging Battery Power:
                </p>
                {!this.state.dataTimeInvalid && (
                  <p>
                    {this.state.batteryPower}
                    <span
                      style={{
                        color: "white",
                        fontWeight: "normal",
                        fontSize: "10px",
                        textAlign: "right",
                        marginBottom: "2px",
                      }}
                    >
                      {" "}
                      {this.state.batteryPowerUnits}
                    </span>
                  </p>
                )}
                {this.state.dataTimeInvalid && <p>--NA--</p>}
                {this.state.modeBox !== "Real-time" && (
                  <div>
                    <p
                      style={{
                        color: "white",
                        fontWeight: "normal",
                        fontSize: "9px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      <span>{"Using "}</span>
                      {(this.state.intervalPeriod / 60).toFixed(0)}
                      <span>{"-minute Interval Data:"}</span>
                    </p>

                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      {this.state.battIntervalTotal}
                      <span>{" kWh"}</span>
                    </p>
                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      Energy Value: ${this.state.battEnergyValue}{" "}
                    </p>
                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      Demand Value: ${this.state.battDemandValue}
                    </p>
                  </div>
                )}
                <p className="percentParagraph">
                  {this.state.batteryProgressBar}%
                </p>
                <ProgressBar
                  className="progressBarBox"
                  variant="primary"
                  style={{ height: "4px" }}
                  now={this.state.batteryProgressBar}
                />
              </button>
            )}

            {this.state.batteryPower < 0 && (
              <button
                className="mainMenu"
                style={{
                  backgroundColor: "red",
                  borderColor: "red",
                }}
              >
                <p
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "10px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Charging Battery Power:
                </p>
                {!this.state.dataTimeInvalid && (
                  <p>
                    {this.state.batteryPower}
                    <span
                      style={{
                        color: "white",
                        fontWeight: "normal",
                        fontSize: "10px",
                        textAlign: "right",
                        marginBottom: "2px",
                      }}
                    >
                      {" "}
                      {this.state.batteryPowerUnits}
                    </span>
                  </p>
                )}
                {this.state.dataTimeInvalid && <p>--NA--</p>}
                {this.state.modeBox !== "Real-time" && (
                  <div>
                    <p
                      style={{
                        color: "white",
                        fontWeight: "normal",
                        fontSize: "9px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      <span>{"Using "}</span>
                      {(this.state.intervalPeriod / 60).toFixed(0)}
                      <span>{"-minute Interval Data:"}</span>
                    </p>

                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      {this.state.battIntervalTotal}
                      <span>{" kWh"}</span>
                    </p>
                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      Energy Value: ${this.state.battEnergyValue}{" "}
                    </p>
                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      Demand Value: ${this.state.battDemandValue}
                    </p>
                  </div>
                )}
                <p className="percentParagraph">
                  {this.state.batteryProgressBar}%
                </p>
                <ProgressBar
                  className="progressBarBox"
                  variant="danger"
                  style={{ height: "4px" }}
                  now={this.state.batteryProgressBar}
                />
              </button>
            )}

            {this.state.batteryPower == 0 && (
              <button
                className="mainMenu"
                style={{
                  backgroundColor: "dodgerblue",
                  borderColor: "dodgerblue",
                  opacity: "0.3",
                }}
              >
                <p
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "10px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  No Battery Power:
                </p>
                {!this.state.dataTimeInvalid && (
                  <p>
                    {this.state.batteryPower}
                    <span
                      style={{
                        color: "white",
                        fontWeight: "normal",
                        fontSize: "10px",
                        textAlign: "right",
                        marginBottom: "2px",
                      }}
                    >
                      {" "}
                      {this.state.batteryPowerUnits}
                    </span>
                  </p>
                )}
                {this.state.dataTimeInvalid && <p>--NA--</p>}
                {this.state.modeBox !== "Real-time" && (
                  <div>
                    <p
                      style={{
                        color: "white",
                        fontWeight: "normal",
                        fontSize: "9px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      <span>{"Using "}</span>
                      {(this.state.intervalPeriod / 60).toFixed(0)}
                      <span>{"-minute Interval Data:"}</span>
                    </p>

                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      {this.state.battIntervalTotal}
                      <span>{" kWh"}</span>
                    </p>
                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      Energy Value: ${this.state.battEnergyValue}{" "}
                    </p>
                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      Demand Value: ${this.state.battDemandValue}
                    </p>
                  </div>
                )}
                <p className="percentParagraph">
                  {this.state.batteryProgressBar}%
                </p>
                <ProgressBar
                  className="progressBarBox"
                  variant="primary"
                  style={{ height: "4px" }}
                  now={this.state.batteryProgressBar}
                />
              </button>
            )}

            {this.state.gridPower < 0 && (
              <button
                className="mainMenu"
                style={{ backgroundColor: "magenta", borderColor: "magenta" }}
              >
                <p
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "10px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Selling Excess Power:
                </p>
                {!this.state.dataTimeInvalid && (
                  <p>
                    {this.state.gridPower}
                    <span
                      style={{
                        color: "white",
                        fontWeight: "normal",
                        fontSize: "10px",
                        textAlign: "right",
                        marginBottom: "2px",
                      }}
                    >
                      {" "}
                      {this.state.gridPowerUnits}
                    </span>
                  </p>
                )}
                {this.state.dataTimeInvalid && <p>--NA--</p>}

                {this.state.modeBox !== "Real-time" && (
                  <div>
                    <p
                      style={{
                        color: "white",
                        fontWeight: "normal",
                        fontSize: "9px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      <span>{"Using "}</span>
                      {(this.state.intervalPeriod / 60).toFixed(0)}
                      <span>{"-minute Interval Data:"}</span>
                    </p>

                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      {this.state.gridIntervalTotal}
                      <span>{" kWh"}</span>
                    </p>

                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      Energy Value: ${this.state.gridEnergyValue}{" "}
                    </p>
                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      Demand Value: ${this.state.gridDemandValue}
                    </p>
                  </div>
                )}
                <p className="percentParagraph">
                  {this.state.gridProgressBar}%
                </p>
                <ProgressBar
                  className="progressBarBoxPurple"
                  variant="danger"
                  style={{ height: "4px" }}
                  now={this.state.gridProgressBar}
                />
              </button>
            )}

            {this.state.gridPower > 0 && (
              <button
                className="mainMenu"
                style={{ backgroundColor: "purple", borderColor: "purple" }}
              >
                <p
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "10px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Purchasing Grid Power:
                </p>
                {!this.state.dataTimeInvalid && (
                  <p>
                    {this.state.gridPower}
                    <span
                      style={{
                        color: "white",
                        fontWeight: "normal",
                        fontSize: "10px",
                        textAlign: "right",
                        marginBottom: "2px",
                      }}
                    >
                      {" "}
                      {this.state.gridPowerUnits}
                    </span>
                  </p>
                )}
                {this.state.dataTimeInvalid && <p>--NA--</p>}

                {this.state.modeBox !== "Real-time" && (
                  <div>
                    <p
                      style={{
                        color: "white",
                        fontWeight: "normal",
                        fontSize: "9px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      <span>{"Using "}</span>
                      {(this.state.intervalPeriod / 60).toFixed(0)}
                      <span>{"-minute Interval Data:"}</span>
                    </p>

                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      {this.state.gridIntervalTotal}
                      <span>{" kWh"}</span>
                    </p>

                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      Energy Value: ${this.state.gridEnergyValue}{" "}
                    </p>
                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      Demand Value: ${this.state.gridDemandValue}
                    </p>
                  </div>
                )}
                <p className="percentParagraph">
                  {this.state.gridProgressBar}%
                </p>
                <ProgressBar
                  className="progressBarBoxPurple"
                  variant="danger"
                  style={{ height: "4px" }}
                  now={this.state.gridProgressBar}
                />
              </button>
            )}

            {this.state.gridPower == 0 && (
              <button
                className="mainMenu"
                style={{
                  backgroundColor: "purple",
                  borderColor: "purple",
                  opacity: "0.3",
                }}
              >
                <p
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "10px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Purchasing Grid Power:
                </p>
                {!this.state.dataTimeInvalid && (
                  <p>
                    {this.state.gridPower}
                    <span
                      style={{
                        color: "white",
                        fontWeight: "normal",
                        fontSize: "10px",
                        textAlign: "right",
                        marginBottom: "2px",
                      }}
                    >
                      {" "}
                      {this.state.gridPowerUnits}
                    </span>
                  </p>
                )}
                {this.state.dataTimeInvalid && <p>--NA--</p>}
                <p className="percentParagraph">
                  {this.state.gridProgressBar}%
                </p>
                <ProgressBar
                  className="progressBarBoxPurple"
                  variant="danger"
                  style={{ height: "4px" }}
                  now={this.state.gridProgressBar}
                />
              </button>
            )}
            {this.state.bldgPower > 0 && (
              <button
                className="mainMenu"
                style={{ backgroundColor: "green", borderColor: "green" }}
              >
                <p
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "10px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Building Power:
                </p>
                {!this.state.dataTimeInvalid && (
                  <p>
                    {this.state.bldgPower}
                    <span
                      style={{
                        color: "white",
                        fontWeight: "normal",
                        fontSize: "10px",
                        textAlign: "right",
                        marginBottom: "2px",
                      }}
                    >
                      {" "}
                      {this.state.bldgPowerUnits}
                    </span>
                  </p>
                )}
                {this.state.dataTimeInvalid && <p>--NA--</p>}
                {this.state.modeBox !== "Real-time" && (
                  <div>
                    <p
                      style={{
                        color: "white",
                        fontWeight: "normal",
                        fontSize: "9px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      <span>{"Using "}</span>
                      {(this.state.intervalPeriod / 60).toFixed(0)}
                      <span>{"-minute Interval Data:"}</span>
                    </p>

                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      {this.state.bldgIntervalTotal}
                      <span>{" kWh"}</span>
                    </p>

                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      Energy Value: ${this.state.bldgEnergyValue}{" "}
                    </p>
                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "center",
                        marginBottom: "2px",
                        padding: "0px",
                        transform: "translateY(-12px)",
                      }}
                    >
                      Demand Value: ${this.state.bldgDemandValue}
                    </p>
                  </div>
                )}
                <p className="percentParagraph">{100}%</p>
                <ProgressBar
                  className="progressBarBox"
                  color="orange"
                  variant="success"
                  style={{ height: "4px" }}
                  now={100}
                />
              </button>
            )}
            {this.state.bldgPower <= 0 && (
              <button
                className="mainMenu"
                style={{
                  backgroundColor: "green",
                  borderColor: "green",
                  opacity: "0.3",
                }}
              >
                <p
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "10px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Building Power:
                </p>
                {!this.state.dataTimeInvalid && (
                  <p>
                    {this.state.bldgPower}
                    <span
                      style={{
                        color: "white",
                        fontWeight: "normal",
                        fontSize: "10px",
                        textAlign: "right",
                        marginBottom: "2px",
                      }}
                    >
                      {" "}
                      {this.state.bldgPowerUnits}
                    </span>
                  </p>
                )}
                {this.state.dataTimeInvalid && <p>--NA--</p>}
                <p className="percentParagraph">{100}%</p>
                <ProgressBar
                  className="progressBarBox"
                  color="orange"
                  variant="success"
                  style={{ height: "4px" }}
                  now={100}
                />
              </button>
            )}
          </div>
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}
export default Dashboard;

class SimpleMenu extends React.Component {
  render() {
    const anchorEl = this.props.anchorState;

    return (
      <div>
        <button
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={this.props.onClickingMenu}
          className="btn btn-outline-light"
          style={{
            borderWidth: "0px",
            padding: "0px",
            float: "right",
            marginRight: "5px",
            position: "relative",
            right: "0px",
          }}
        >
          <FontAwesomeIcon
            icon="clock"
            size="sm"
            color="magenta"
            title="show numbers"
          />
        </button>
        <Menu
          style={{
            borderWidth: "0px",
          }}
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.props.onChoosingToday}
        >
          <MenuItem
            style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
            onClick={this.props.onChoosingRT}
          >
            Real-time
          </MenuItem>
          <MenuItem
            style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
            onClick={this.props.onChoosingInterval}
          >
            Last Interval
          </MenuItem>
          <MenuItem
            style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
            onClick={this.props.onChoosingToday}
          >
            Today
          </MenuItem>
          <MenuItem
            style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
            onClick={this.props.onChoosingYesterday}
          >
            Yesterday
          </MenuItem>
          <MenuItem
            style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
            onClick={this.props.onChoosingWeek}
          >
            This Week
          </MenuItem>
          <MenuItem
            style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
            onClick={this.props.onChoosingLastWeek}
          >
            Last Week
          </MenuItem>
          <MenuItem
            style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
            onClick={this.props.onChoosingMonth}
          >
            This Month
          </MenuItem>
          <MenuItem
            style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
            onClick={this.props.onChoosingLastMonth}
          >
            Last Month
          </MenuItem>
          <MenuItem
            style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
            onClick={this.props.onChoosingQuarter}
          >
            This Quarter
          </MenuItem>
          <MenuItem
            style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
            onClick={this.props.onChoosingYear}
          >
            This Year
          </MenuItem>
          <MenuItem
            style={{ fontSize: "10px", margin: "1px", padding: "1px" }}
            onClick={this.props.onChoosingDR}
          >
            Date Range
          </MenuItem>
          <hr></hr>
          <MenuItem
            style={{
              fontSize: "12px",
              margin: "1px",
              padding: "1px",
              color: "magenta",
              fontWeight: "bold",
              textDecoration: "underline",
              marginLeft: "13px",
            }}
            onClick={this.props.onChoosingDaily}
          >
            Daily
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

class CostNSavingsModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <>
        <button
          className="btn btn-outline-light"
          style={{
            borderWidth: "0px",
            padding: "0px",
            float: "right",
            marginRight: "5px",
            position: "relative",
            right: "0px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="dollar-sign"
            size="sm"
            color="forestgreen"
            title="Tariff Data"
          />
        </button>

        <Modal
          style={{ width: "400px", alignContent: "center" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "14px", fontWeight: "600" }}
            >
              Usage, Savings and Net Costs for the Selected Period:
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th>Usage</th>
                  <th>Savings $ (%)</th>
                  <th>Net Costs</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ color: "tomato", lineHeight: "3px" }}>
                  <td style={{ textAlign: "left" }}>Energy:</td>
                  <td>${this.props.bldgEnergyValue}</td>
                  <td>
                    ${this.props.savingsEnergyValue}{" "}
                    <span
                      style={{
                        fontSize: "9px",
                        textAlign: "left",
                      }}
                    >
                      (%{this.props.percentEnergySavings})
                    </span>
                  </td>
                  <td>${this.props.gridEnergyValue}</td>
                </tr>
                <tr style={{ color: "orange", lineHeight: "3px" }}>
                  <td style={{ textAlign: "left" }}>Demand:</td>
                  <td>${this.props.bldgMaxDemand}</td>
                  <td>
                    ${this.props.demandSavings}{" "}
                    <span
                      style={{
                        fontSize: "9px",
                        textAlign: "left",
                      }}
                    >
                      (%{this.props.percentDemandSavings})
                    </span>
                  </td>
                  <td>${this.props.gridMaxDemand}</td>
                </tr>
                <tr style={{ color: "magenta", lineHeight: "3px" }}>
                  <td style={{ textAlign: "left" }}>Totals:</td>
                  <td>${this.props.totalUsage}</td>
                  <td>
                    ${this.props.totalSavings}{" "}
                    <span
                      style={{
                        fontSize: "9px",
                        textAlign: "left",
                      }}
                    >
                      (%{this.props.percentTotalSavings})
                    </span>
                  </td>
                  <td>${this.props.netCosts}</td>
                </tr>
              </tbody>
            </table>
            <hr></hr>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "left",
                marginBottom: "2px",
              }}
            >
              Energy Summary for This Period:
            </p>
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th>kWh</th>
                  <th>$</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ color: "green", lineHeight: "3px" }}>
                  <td style={{ textAlign: "left" }}>Building Consumption</td>
                  <td>{this.props.bldgIntervalTotal}</td>
                  <td>${this.props.bldgEnergyValue}</td>
                </tr>
                <tr style={{ color: "orange", lineHeight: "3px" }}>
                  <td style={{ textAlign: "right" }}>Solar Generation</td>
                  <td>{this.props.pvIntervalTotal}</td>
                  <td>${this.props.pvEnergyValue}</td>
                </tr>
                <tr style={{ color: "dodgerblue", lineHeight: "3px" }}>
                  <td style={{ textAlign: "right" }}>Battery Contribution</td>
                  <td>{this.props.battIntervalTotal}</td>
                  <td>${this.props.battEnergyValue}</td>
                </tr>
                <tr style={{ color: "purple", lineHeight: "3px" }}>
                  <td style={{ textAlign: "right" }}>Total Energy Savings</td>
                  <td>{this.props.savingsIntervalTotal}</td>
                  <td>${this.props.savingsEnergyValue}</td>
                </tr>
                <tr style={{ color: "magenta", lineHeight: "3px" }}>
                  <td style={{ textAlign: "left" }}>
                    Net Energy: Grid Consumption
                  </td>
                  <td>{this.props.gridIntervalTotal}</td>
                  <td>${this.props.gridEnergyValue}</td>
                </tr>
              </tbody>
            </table>
            <hr></hr>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "left",
                marginBottom: "2px",
              }}
            >
              Demand Summary for This Period:
            </p>
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th>kW</th>
                  <th>$</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style={{
                    color: "green",
                    lineHeight: "3px",
                  }}
                >
                  <td style={{ textAlign: "left" }}> Building Max Demand</td>
                  <td>{this.props.bldgMaxKW}</td>
                  <td>${this.props.bldgMaxDemand}</td>
                </tr>

                <tr
                  style={{
                    color: "purple",
                    lineHeight: "3px",
                  }}
                >
                  <td style={{ textAlign: "right" }}> Net Demand Savings:</td>
                  <td></td>
                  <td>${this.props.demandSavings}</td>
                </tr>
                <tr
                  style={{
                    color: "magenta",
                    lineHeight: "3px",
                  }}
                >
                  <td style={{ textAlign: "left" }}> Grid Max Demand</td>
                  <td>{this.props.gridMaxKW}</td>
                  <td>${this.props.gridMaxDemand}</td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

class SystemInfoModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <>
        <button
          className="btn btn-outline-light"
          style={{
            borderWidth: "0px",
            padding: "0px",
            float: "right",
            marginRight: "5px",
            position: "relative",
            right: "0px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="info-circle"
            size="sm"
            color="dodgerblue"
            title="System Information"
          />
        </button>

        <Modal
          style={{ width: "250px", height: "800px", alignContent: "center" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "14px" }}
            >
              System Essentials:
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "10px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              System Name:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.systemName}
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.systemDescription}
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "10px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Communication Provider:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.commProvider}:{this.props.ssId}
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              FW Application Name:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.fwAppName}
            </p>
            <p></p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Master Firmware Version:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.fwVersionMaster}
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Number of SimplBox Nodes:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {" "}
              {this.props.slaveCount}
            </p>

            <hr></hr>
            {this.props.acCoupledBackUp === 1 && this.props.outageFlag === 1 && (
              <p
                style={{
                  color: "#2d5c88",
                  fontWeight: "bold",
                  fontSize: "9px",
                  textAlign: "center",
                  marginBottom: "1px",
                }}
              >
                Outage Count: {this.props.outageCount}
              </p>
            )}
            {this.props.acCoupledBackUp === 1 && this.props.outageFlag === 0 && (
              <p
                style={{
                  color: "#2d5c88",
                  fontWeight: "bold",
                  fontSize: "9px",
                  textAlign: "center",
                  marginBottom: "1px",
                }}
              >
                System in on Grid
              </p>
            )}

            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Company Name:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {" "}
              {this.props.companyName}
            </p>
            <p />
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              {" "}
              Electric Utility Rates:
            </p>
            {this.props.onPeakEnergy === 1 && (
              <p
                style={{
                  color: "#2d5c88",
                  fontWeight: "bold",
                  fontSize: "9px",
                  textAlign: "center",
                  marginBottom: "1px",
                }}
              >
                Energy costs: &cent;
                {(this.props.energyRate * 100).toFixed(2)}/kWh | On-Peak hours
              </p>
            )}
            {this.props.onPeakEnergy === 0 && (
              <p
                style={{
                  color: "#2d5c88",
                  fontWeight: "bold",
                  fontSize: "9px",
                  textAlign: "center",
                  marginBottom: "1px",
                }}
              >
                Energy costs: &cent;
                {(this.props.energyRate * 100).toFixed(2)}/kWh | Off-Peak hours
              </p>
            )}
            {this.props.onPeakDemand === 1 && (
              <p
                style={{
                  color: "#2d5c88",
                  fontWeight: "bold",
                  fontSize: "9px",
                  textAlign: "center",
                  marginBottom: "5px",
                }}
              >
                Demand costs: ${this.props.demandRate}
                /kW | On-Peak hours
              </p>
            )}
            {this.props.onPeakDemand === 0 && (
              <p
                style={{
                  color: "#2d5c88",
                  fontWeight: "bold",
                  fontSize: "9px",
                  textAlign: "center",
                  marginBottom: "5px",
                }}
              >
                Demand costs: ${this.props.demandRate}
                /kW | Off-Peak hours
              </p>
            )}
            <p></p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              WiFi Mesh ID: {this.props.MeshId}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              System Handle ID: {this.props.HandleID}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              System Status: {this.props.systemStatus}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              System Configuration: {this.props.systemConfiguration}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              System Product: {this.props.systemProduct}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              System Type: {this.props.systemType}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Parameter Update Rate: {this.props.paramUpdateRate}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Data Verbosity: {this.props.dataVerbosity}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Data Frequency: {this.props.dataFrequency}
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Attached SimplModule SN: {this.props.attachedSimplModuleSN}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              {" "}
              Attached SimplModule Handle ID:{" "}
              {this.props.attachedSimplModuleHandle}
            </p>
            {this.props.moduleDataValid !== 0 && (
              <p
                style={{
                  color: "#2d5c88",
                  fontWeight: "bold",
                  fontSize: "9px",
                  textAlign: "center",
                  marginBottom: "1px",
                }}
              >
                Valid SimplModule Data
              </p>
            )}
            {this.props.moduleDataValid == 0 && (
              <p
                style={{
                  color: "#2d5c88",
                  fontWeight: "bold",
                  fontSize: "9px",
                  textAlign: "center",
                  marginBottom: "1px",
                }}
              >
                Invalid SimplModule Data
              </p>
            )}
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Loop count since restart:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.loopCount}
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Master Serial Number:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.serialNumber}
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Product Serial Number:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.productSN}
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              4G Module Serial Number:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.emaSN}
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Master MAC Address:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.macAddress}
            </p>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
