import React from "react";
import { toast } from "react-toastify";
import { loginUser } from "../services/userService";
///////////////////////////
import { getCompanies, getCompanyUsers } from "../services/companyService";
import { setJwt } from "../services/httpService";
import { getUser } from "../services/userService";
/////////////////////////////

import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Form from "./common/form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  masterCompany,
  footerLogoSource,
  manufacturingCompany,
} from "../config.json";
import BackGroundRectangle from "../components/backgroundRect";
import emailjs from "emailjs-com";

class VerifyUser extends Form {
  state = {
    data: { username: "", password: "" },
    errors: {},
  };

  async componentDidMount() {
    const userName = this.props.match.params.username;
    console.log("Verify User: ", userName);
  }

  render() {
    console.log("username", this.state.data.username);
    return (
      <React.Fragment>
        <p>User verified</p>
      </React.Fragment>
    );
  }
}

export default VerifyUser;

{
  /*
render() {
    console.log("username", this.state.data.username);
    return (
      <div
        style={{
          width: "400px",
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: "150px",
          marginBottom: "150px"
        }}
      >
        <div>
          <form onSubmit={this.handleSubmit}>
            {this.renderInput("username", "Username")}
            {this.renderInput("password", "Password", "password")}
            {this.renderButton("Login")}
          </form>
        </div>

        <Link
          to="/users/new"
          className="btn btn-outline-warning btn-sm"
          style={{ marginTop: 10, width: "150px" }}
        >
          Register
        </Link>
      </div>
    );
  }
}







   <section style={{ backgroundImage: "footerLogoSource" }}></section>
  
  
  
  <img
          src={footerLogoSource}
          style={{ backgroundImage: "footerLogoSource" }}
          width="300"
          height="150"
          alt=""
/>*/
}
