import React from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Form from "./common/form";
import {
  updateTariff,
  findSystemBySerialNumber,
  getSystem,
} from "../services/systemService";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import "../excel-2007.css";

class Tariff extends Form {
  state = {
    dataLoaded: false,

    errors: {},
  };

  async componentDidMount() {
    console.log(" Doing Tariff Stuff");
    const userRole = this.props.match.params.userRole;
    console.log("User Role", userRole);
    const systemID = (
      await findSystemBySerialNumber(this.props.match.params.systemSN)
    ).data.data.system_id;
    console.log("SystemID", systemID);
    const system = await getSystem(systemID);
    console.log("System", system);
    const systemName = system.data.data[0].systemID.name;
    console.log("System Name", systemName);
    const systemTariff = system.data.data[0].systemTariff;
    console.log("System Tariff", systemTariff);
    if (systemTariff)
      this.setState({
        systemName,
        tariffJson: systemTariff,
        cols: systemTariff.cols,
        rows: systemTariff.rows,
      });
    else
      this.setState({
        systemName,
        userRole,
        tariffJson: systemTariff,
      });
  }

  fileHandler = (event) => {
    let fileObj = event.target.files[0];
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        this.setState({
          tariffJson: resp,
          cols: resp.cols,
          rows: resp.rows,
          dataLoaded: true,
        });
      }
    });
  };

  handleTariff = async (e) => {
    e.preventDefault();
    console.log("System SN", this.props.match.params.systemSN);
    if (this.props.match.params.userRole === "companyAdmin")
      await updateTariff(
        this.props.match.params.systemSN,
        this.state.tariffJson
      );
    else
      toast.error(
        "You must be your company's Admin to approve the tariff structure."
      );

    this.props.history.goBack();
    /* this.props.history.push(
      `/Companies/systems/${this.props.match.params.companyName}`
    );*/
  };

  render() {
    console.log("Columns", this.state.cols);
    console.log("Rows", this.state.rows);
    /*  if (this.state.dataLoaded) {
      console.log("First Row", this.state.rows[3][2]);
    }*/
    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            paddingBottom: "100px",
          }}
        >
          {this.state.tariffJson && (
            <React.Fragment>
              <p className="basicParagraph">
                Review the system Tariff structure for {this.state.systemName}.
              </p>
              <p className="basicParagraph">
                {" "}
                To approve the tariff structure click here:
              </p>
              <button className="basicButton" onClick={this.handleTariff}>
                Approve Tariff
              </button>
              <OutTable
                data={this.state.rows}
                columns={this.state.cols}
                tableClassName="ExcelTable2007"
                tableHeaderRowClass="heading"
              />
              <p className="basicParagraph" />
              <p className="basicParagraph">
                To upload a new Tariff click below and select a Tariff file:
              </p>{" "}
              <input
                type="file"
                onChange={this.fileHandler.bind(this)}
                className="inputTariff"
                style={{ padding: "10px" }}
              />
            </React.Fragment>
          )}

          {!this.state.tariffJson && (
            <React.Fragment>
              <p className="basicParagraph">
                Follow these steps to select, view and approve the tariff
                structure for {this.state.systemName}:
              </p>
              <p className="basicParagraph">
                {" "}
                Step 1: Select an excel spreadsheet with tariff structure. Enter
                its file name here:
              </p>
              <input
                type="file"
                onChange={this.fileHandler.bind(this)}
                className="inputTariff"
                style={{ padding: "10px" }}
              />

              {this.state.dataLoaded && (
                <React.Fragment>
                  <p className="basicParagraph">
                    {" "}
                    Step 2: Review the tariff structure:
                  </p>
                  <OutTable
                    data={this.state.rows}
                    columns={this.state.cols}
                    tableClassName="ExcelTable2007"
                    tableHeaderRowClass="heading"
                  />

                  <p className="basicParagraph">
                    {" "}
                    Step 3: Approve the tariff structure:
                  </p>
                  <button className="basicButton" onClick={this.handleTariff}>
                    Approve Tariff
                  </button>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Tariff;

/*{this.state.dataLoaded && (
            <table>
              {this.state.respJson.rows.map(dataRow => (
                <tr key={dataRow}>
                  <td style={{ backgroundColor: "blue" }}> Row Info</td>
                </tr>
              ))}
            </table>
              )}*/
