import React, { Component } from "react";
import { Link } from "react-router-dom";
//import simplWS_client from "./wsService";
import { toast } from "react-toastify";
import {
  getSystemLatestData,
  ws_getSystemLatestData,
  getSystemIntervalData,
  getSystemInfo,
  getSystemBasics,
  getSystemIDfromHandle,
  getSystem,
  setSystemSignals,
  initSystemUpdate,
  ws_get,
  ws_post,
} from "../services/systemService";

import { setJwt, deleteHeaders } from "../services/httpService";
import { getUser } from "../services/userService";
import "./dashboard.css";
import "../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "react-bootstrap/ProgressBar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import BackGroundRectangle from "../components/backgroundRect";

import TstatSettingsModal from "./TstatSettingsModal";

//const simplWS_client = new WebSocket("ws://127.0.0.1:8082");

class ThermoStatLocalClient extends Component {
  constructor(props) {
    super(props);
  }
  state = { systemControlsLocalLength: 0, setOffLine: 0 };

  async componentDidMount() {
    const handleID = this.props.match.params.systemHandleID;
    console.log("System Handle ID: ", handleID);

    const systemIDRaw = await getSystemIDfromHandle(handleID);
    console.log("SystemID Raw:", systemIDRaw);
    const systemID = systemIDRaw.data.data.system_id;

    const systemName = (await getSystem(systemID)).data.data[0].systemID.name;
    console.log("System Name: ", systemName);

    //const systemID = localStorage.getItem("systemID");
    this.setState({ handleID, systemID, systemName });

    /* const ipAddress = localStorage.getItem("ipAddress");
    console.log("IP Address: ", ipAddress);
    const systemControlsLocal = JSON.parse(
      localStorage.getItem("systemControlsLocal")
    );
    console.log("System Controls Local: ", systemControlsLocal);
    let systemControlsLocalLength = 0;
    if (!systemControlsLocal) {
      console.log("System Controls is undefined.........");
      systemControlsLocal = {};
      systemControlsLocalLength = 0;
    } else systemControlsLocalLength = systemControlsLocal.length;
    console.log("System Controls Local Length: ", systemControlsLocalLength);
    this.setState({
      systemControlsLocal,
      systemControlsLocalLength,
      ipAddress,
    });*/
    this.timerID = setInterval(() => this.tick(/*ipAddress*/), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  /*async getSystemDataOffline(ipAddress) {
    console.log("ESP32 Server IP Address: ", ipAddress);
    deleteHeaders();
    const response = await ws_get(ipAddress);
    console.log("Get Data from ESP32 Server:", response);

    console.log("Build Data Response: ", response);
    let applianceData = [];
    if (response !== undefined && response !== null) {
      applianceData = response.data.deviceLedger.Appliances;
      let thermostats = [];
      if (applianceData)
        thermostats = applianceData.filter(
          (thermostat) => thermostat.loadType === "HVAC w SimplTherm"
        );
      console.log("Appliances", applianceData);
      console.log("Thermostats", thermostats);

      let thermostatsLength = 0;
      if (!thermostats) {
        thermostats = {};
        thermostatsLength = 0;
      } else thermostatsLength = thermostats.length;
      console.log("Thermostats Length: ", thermostatsLength);
      this.setState({ thermostats, thermostatsLength });
    }
  }*/
  async getSystemDataOnline() {
    const response_temp = await getSystemLatestData(
      this.props.match.params.systemHandleID
    );
    const response = response_temp.data.data[0];

    console.log("Build Data Response: ", response);
    let applianceData = [];
    if (response) {
      applianceData = response.data.deviceLedger.Appliances;
      let thermostats = [];
      if (applianceData)
        thermostats = applianceData.filter(
          (thermostat) => thermostat.loadType === "HVAC w SimplTherm"
        );
      console.log("Appliances", applianceData);
      console.log("Thermostats", thermostats);

      let thermostatsLength = 0;
      if (!thermostats) {
        thermostats = {};
        thermostatsLength = 0;
      } else thermostatsLength = thermostats.length;
      console.log("Thermostats Length: ", thermostatsLength);
      this.setState({ thermostats, thermostatsLength });
    }
  }
  tick(/*ipAddress*/) {
    this.setState({
      dateNow: new Date(),
    });
    /*const setOffLine = this.state.setOffLine;
    if (setOffLine === 1) this.getSystemDataOffline(ipAddress);
    else */ this.getSystemDataOnline();
  }
  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  cancelOverRide = async (nodeName) => {
    console.log(
      "************************  Cancel Override. Node Name: ",
      nodeName
    );
    const thisSystemID = (
      await getSystemIDfromHandle(this.props.match.params.systemHandleID)
    ).data.data.system_id;
    console.log("This System ID: ", thisSystemID);
    toast.warning("Canceling Override...wait...");
    await setSystemSignals(thisSystemID, "81", "TBD", "0", nodeName, "40");

    await initSystemUpdate(this.props.match.params.systemHandleID);

    this.sleep(10000);
    toast.success("Override was canceled...");
  };

  changeThermostatSetting = async (tSet, durationTime, nodeName) => {
    const ipAddress = this.state.ipAddress;
    const setOffLine = this.state.setOffLine;
    if (setOffLine === 1) {
      let postMessage = "";
      postMessage = JSON.stringify({
        nodename: String(nodeName),
        ThermostatTSet: tSet,
      });
      ///8888888888888  ToDo: Add durationTime to the offline mode
      let rcvd_data = await ws_post(ipAddress, postMessage);
      console.log("Received data from ESP32 Server:", rcvd_data);
    } else {
      let durationTimeInt = parseInt(durationTime);
      if (durationTimeInt < 5) durationTimeInt = 5;
      const durationTimeStr = durationTimeInt.toString();
      toast.warning("Sending settings to the system...wait...");
      await setSystemSignals(
        this.state.systemID,
        "81",
        "TBD",
        durationTimeStr,
        nodeName,
        tSet
      );

      await initSystemUpdate(this.props.match.params.systemHandleID);
      this.sleep(10000);
      await setSystemSignals(
        this.state.systemID,
        "81",
        "TBD",
        "0",
        nodeName,
        tSet
      );

      await initSystemUpdate(this.props.match.params.systemHandleID);
      this.sleep(15000);
      toast.success("Settings were updated...");
    }
  };
  handleOffLine = (e) => {
    console.log("In OffLine Selection");
    let setOffLine = this.state.setOffLine;
    if (setOffLine === 0) setOffLine = 1;
    else setOffLine = 0;
    this.setState({ setOffLine });
  };
  render() {
    return (
      <div
        style={{
          width: "350px",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: "150px",
          marginBottom: "150px",
          zIndex: 1,
        }}
      >
        <p
          className="basicParagraph"
          style={{ width: "100%", textAlign: "center", fontSize: "12px" }}
        >
          {this.state.systemName} Thermostats
        </p>
        {/*******************************************************************************************
        {this.state.setOffLine === 1 && (
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customSwitches"
              checked
              onChange={this.handleOffLine}
            ></input>
            <label class="custom-control-label" for="customSwitches">
              <p
                className="basicParagraph"
                style={{ fontSize: "10px", paddingTop: "5px" }}
              >
                System in Offline; Switch to go Online
              </p>
            </label>
          </div>
        )}
        {this.state.setOffLine === 0 && (
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customSwitches"
              onChange={this.handleOffLine}
            ></input>
            <label class="custom-control-label" for="customSwitches">
              <p
                className="basicParagraph"
                style={{ fontSize: "10px", paddingTop: "5px" }}
              >
                System in Online; Switch to go Offline
              </p>
            </label>
          </div>
        )}
        ///////////////////////////////////////////////////////////////////*/}
        <table
          className="table"
          style={{
            marginTop: "20px",
            width: "350px",
            //transform: "translateX(20px)",
          }}
        >
          <th
            style={{
              width: "100px",
              margin: "0px",
              padding: "0px",
              fontsize: "10px",
              height: "25px",
              verticalAlign: "middle",
            }}
          >
            Node Name
          </th>

          <th
            style={{
              width: "80px",
              margin: "0px",
              padding: "0px",
              fontsize: "10px",
              height: "25px",
              verticalAlign: "middle",
            }}
          >
            SNS. T
          </th>
          <th
            style={{
              width: "80px",
              margin: "0px",
              padding: "0px",
              fontsize: "10px",
              height: "25px",
              verticalAlign: "middle",
            }}
          >
            Cal. T
          </th>
          <th
            style={{
              width: "80px",
              margin: "0px",
              padding: "0px",
              fontsize: "10px",
              height: "25px",
              verticalAlign: "middle",
            }}
          >
            Settings
          </th>
          {this.state.thermostats &&
            this.state.thermostatsLength &&
            this.state.thermostats.map((thermostat) => (
              <tr key={thermostat.id}>
                <td
                  style={{
                    margin: "0px",
                    padding: "0px",
                    width: "90px",
                    height: "20px",
                    verticalAlign: "middle",
                  }}
                >
                  {thermostat.nodeName}
                </td>
                {thermostat.extTemp !== 15000 && (
                  <td
                    style={{
                      width: "40px",
                      textAlign: "center",

                      position: "relative",
                      left: "0px",
                      height: "15px",
                    }}
                  >
                    {((thermostat.extTemp / 100.0) * 1.8 + 32).toFixed(1)}
                    &#176;F
                  </td>
                )}
                {thermostat.extTemp === 15000 && (
                  <td
                    style={{
                      width: "40px",
                      textAlign: "center",

                      position: "relative",
                      left: "0px",
                      height: "15px",
                    }}
                  >
                    --
                  </td>
                )}

                {thermostat.extTemp !== 15000 && (
                  <td
                    style={{
                      width: "40px",
                      textAlign: "center",

                      position: "relative",
                      left: "0px",
                      height: "15px",
                    }}
                  >
                    --
                  </td>
                )}
                {thermostat.extTemp === 15000 && (
                  <td
                    style={{
                      width: "40px",
                      textAlign: "center",

                      position: "relative",
                      left: "0px",
                      height: "15px",
                    }}
                  >
                    {(thermostat.roomTemp / 100.0).toFixed(1)} &#176;F
                  </td>
                )}

                {/********************************************************************************** */}
                <td
                  style={{
                    margin: "0px",
                    padding: "0px",
                    height: "20px",
                    verticalAlign: "middle",
                  }}
                >
                  <TstatSettingsModal
                    nodeName={thermostat.nodeName}
                    tSet={thermostat.tSet}
                    tSetHeat={thermostat.tSetHeat}
                    tSetCool={thermostat.tSetCool}
                    rhSet={thermostat.rhSet}
                    orCount={thermostat.orCount}
                    simplThermCool={thermostat.relaySetting & 0x0001}
                    simplThermHeat={(thermostat.relaySetting & 0x0002) >> 1}
                    changeThermostatSetting={this.changeThermostatSetting}
                    cancelOverRide={this.cancelOverRide}
                  />
                </td>
              </tr>
            ))}
        </table>
      </div>
    );
  }
}
export default ThermoStatLocalClient;
