import React, { Component } from "react";
import { Link } from "react-router-dom";

import { getUserSystems, getUser } from "../services/userService";
import {
  getSystems,
  getSystemLatestData,
  ws_getSystemLatestData,
  checkConnectionInfo,
  getSystemBasics,
} from "../services/systemService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setJwt } from "../services/httpService";
import BackGroundRectangle from "../components/backgroundRect";
import { webSocketMode } from "../config.json"; //0: http; 1:websocket

class UserSystems extends Component {
  state = {
    systemId: "",
    systems: [],
    installerOf: [],
    ownerOf: [],
    operatorOf: [],
  };

  async componentDidMount() {
    console.log("System username in userSystems", this.props.match.params.id);
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    const user = await getUser();
    const companyName = user.data.data.company;
    this.setState({ companyName });
    const systems = await getUserSystems();
    console.log("System Info on User Systems", systems);
    console.log("Owner of Systems", systems.data.data.ownerOf);
    console.log("Operator of Systems", systems.data.data.operatorOf);
    console.log("Installer of Systems", systems.data.data.installerOf);
    this.setState({ installerOf: systems.data.data.installerOf });
    this.setState({ ownerOf: systems.data.data.ownerOf });
    this.setState({ operatorOf: systems.data.data.operatorOf });
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  tick() {
    this.setState({
      dateNow: new Date(),
    });
    this.getSystemData();
  }
  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  async calSignalAvailablity(systems) {
    const systemCount = systems.length;
    let i = 0;
    let dataTimeInvalid = [0];
    let newSystem = [0];

    for (i = 0; i < systemCount; i++) {

      const myDataTimeInvalid = (await checkConnectionInfo(systems[i].systemID.id)).data.data.Connection

      if (myDataTimeInvalid === null){
        dataTimeInvalid[i] = 2
      } else {
        dataTimeInvalid[i] = myDataTimeInvalid
      }

      console.log("Data Time Invalid", dataTimeInvalid[i]);
      newSystem[i] = Object.assign(systems[i], {
        systemConnections: dataTimeInvalid[i],
      });
      systems[i] = newSystem[i];
      console.log("New System", systems[i]);
    }
    this.setState({ systems });
  }

  async getSystemData() {
    clearInterval(this.timerID);
    this.timerID = setInterval(() => this.tick(), 30000);

    let systems = this.state.operatorOf;
    if (systems) {
      this.calSignalAvailablity(systems);
      this.setState({ operatorOf: systems });
    }

    systems = this.state.installerOf;
    if (systems) {
      this.calSignalAvailablity(systems);
      this.setState({ installerOf: systems });
    }

    systems = this.state.ownerOf;
    if (systems) {
      this.calSignalAvailablity(systems);
      this.setState({ ownerOf: systems });
    }
  }
  handleRowClick = (
    systemConfig,
    handleID,
    companyName,
    systemSN,
    systemName,
    userRole,
    action
  ) => {
    console.log(
      "......User Systems Handle Row Click.............System Config:",
      systemConfig
    );
    console.log("User Role:", userRole);


    if ( action && action === "search" ){
      this.props.history.push(
        `/systems/dashboardWideSlaves/${handleID}/${companyName}`
      );
    }

    else {

      if (systemConfig === "8" && userRole === "operatorOf")
        this.props.history.push(
          `/systems/simplNodesVuBasic/${handleID}/${companyName}/${systemSN}/${systemName}/operatorOf`
        );
      else if (systemConfig === "8" && userRole === "ownerOf")
        this.props.history.push(
          `/systems/simplNodesVu/${handleID}/${companyName}/${systemSN}/${systemName}/ownerOf`
        );
      else
        this.props.history.push(
          `/systems/simplVu/${handleID}/${companyName}/${systemSN}/${systemName}/${userRole}`
        );

    }

  };
  render() {
    let systemOwnerCount = 0;
    let systemInstallerCount = 0;
    let systemOperatorCount = 0;
    if (this.state.installerOf !== null)
      systemInstallerCount = this.state.installerOf.length;
    if (this.state.ownerOf !== null)
      systemOwnerCount = this.state.ownerOf.length;
    if (this.state.operatorOf !== null)
      systemOperatorCount = this.state.operatorOf.length;
    if (
      systemOwnerCount === 0 &&
      systemInstallerCount === 0 &&
      systemOperatorCount === 0
    )
      return (
        <React.Fragment>
          <div
            style={{
              width: "350",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              marginTop: "150px",
              marginBottom: "150px",
              zIndex: 1,
            }}
          >
            <p className="basicParagraph">
              {" "}
              This system does not have any users.
            </p>
            <p className="basicParagraph">
              {" "}
              Please contact your company's Admin to be added as a user.
            </p>
          </div>
          <BackGroundRectangle />
        </React.Fragment>
      );

    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1,
          }}
        >
          {/*  <table className="table">
          <thead>
            <tr>
              <td>
                {this.props.match.params.id}
                <Link style={{ marginLeft: "20px" }} to={`/users/systems`}>
                  <FontAwesomeIcon
                    icon="info-circle"
                    size="lg"
                    color="orange"
                  />
                </Link>
              </td>
            </tr>
            <tr>
              <td>
                {" "}
                Register a new system
                <Link style={{ marginLeft: "20px" }} to="/systems/new">
                  <FontAwesomeIcon
                    icon="plus-circle"
                    size="lg"
                    color="blue"
                    title="Register a new system"
                  />
                </Link>
              </td>
          </tr>
          </thead>
        </table>*/}

          {systemOwnerCount !== 0 && (
            <div>
              <p className="basicParagraph"></p>
              <p />
              <table className="table" style={{ width: "350px" }}>
                <thead>
                  <tr>
                    <th className="col-1">
                      <FontAwesomeIcon
                        icon="satellite-dish"
                        size="lg"
                        color="gray"
                        title="Communication Signal"
                      />
                    </th>
                    <th className="col-3">System</th>
                    {/* <th>Type</th> */}
                    <th className="col-3">Status</th>
                    <th className="col-5" ></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.ownerOf.map((system) => (
                    <tr
                      key={system.systemID}
                      style={{ height: "10px" }}
                      className=""
                      onClick={() =>
                        this.handleRowClick(
                          `${system.systemBasics.systemConfiguration}`,
                          `${system.systemID.id}`,
                          `${this.state.companyName}`,
                          `${system.systemID.SN}`,
                          `${system.systemID.name}`,
                          "ownerOf"
                        )
                      }
                    >
                      <td>
                        {(system.systemConnections === null || system.systemConnections === 2) && (
                          <button className="led-red-on"></button>
                        )}
                        {system.systemConnections === 1 && (
                          <button className="led-yellow-on"></button>
                        )}
                        {system.systemConnections === 0 && (
                          <button className="led-green-on"></button>
                        )}
                      </td>
                      <td>{system.systemID.name}</td>
                      {/* <td>{system.systemProduct}</td> */}
                      <td>{system.systemStatus}</td>

                      <td
                        className="icon"
                        style={{ paddingLeft: "2px", paddingRight: "2px" }}
                      >

                        <div
                          className="d-flex justify-content-around">

                          <a onClick={(e)=> {
                            e.stopPropagation()
                            this.handleRowClick(
                              `${system.systemBasics.systemConfiguration}`,
                              `${system.systemID.id}`,
                              `${this.state.companyName}`,
                              `${system.systemID.SN}`,
                              `${system.systemID.name}`,
                              "ownerOf",
                              "search"
                            )
                            
                          }}>
                            <FontAwesomeIcon
                              icon="search"
                              size="lg"
                              color="brown"
                              title="Search"
                            />
                          </a>

                          {system.systemBasics.systemConfiguration === "8" && (
                            <FontAwesomeIcon
                              icon="bolt"
                              size="lg"
                              color="green"
                              title="Monitor SimplNodes"
                            />
                          )}
                          {system.systemBasics.systemConfiguration !== "8" && (
                            <FontAwesomeIcon
                              icon="tachometer-alt"
                              size="lg"
                              color="green"
                              title="monitor"
                            />
                          )}

                        </div>

                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {systemInstallerCount !== 0 && (
            <div>
              <p className="basicParagraph"></p>
              <p />
              <table className="table" style={{ width: "350px" }}>
                <thead>
                  <tr>
                    <th className="col-2">
                      <FontAwesomeIcon
                        icon="satellite-dish"
                        size="lg"
                        color="gray"
                        title="Communication Signal"
                      />
                    </th>
                    <th className="col-3">System</th>
                    {/* <th>Type</th> */}
                    <th className="col-3">Status</th>
                    <th className="col-4" ></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.installerOf.map((system) => (
                    <tr
                      key={system.systemID}
                      onClick={() =>
                        this.handleRowClick(
                          `${system.systemBasics.systemConfiguration}`,
                          `${system.systemID.id}`,
                          `${this.state.companyName}`,
                          `${system.systemID.SN}`,
                          `${system.systemID.name}`,
                          "installerOf"
                        )
                      }
                    >
                      <td>
                        {(system.systemConnections === null || system.systemConnections === 2) && (
                          <button className="led-red-on"></button>
                        )}
                        {system.systemConnections === 1 && (
                          <button className="led-yellow-on"></button>
                        )}
                        {system.systemConnections === 0 && (
                          <button className="led-green-on"></button>
                        )}
                      </td>
                      <td>{system.systemID.name}</td>
                      {/* <td>{system.systemProduct}</td> */}
                      <td>{system.systemStatus}</td>
                      <td className="icon">
                        {system.systemBasics.systemConfiguration !== "8" && (
                          <FontAwesomeIcon
                            icon="tachometer-alt"
                            size="lg"
                            color="green"
                            title="monitor"
                          />
                        )}
                        {system.systemBasics.systemConfiguration === "8" && (
                          <FontAwesomeIcon
                            icon="bolt"
                            size="lg"
                            color="green"
                            title="Monitor SimplNodes"
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {systemOperatorCount !== 0 && (
            <div>
              <p className="basicParagraph"></p>
              <p />
              <table className="table" style={{ width: "350px" }}>
                <thead>
                  <tr>
                    <th className="col-2">
                      <FontAwesomeIcon
                        icon="satellite-dish"
                        size="lg"
                        color="gray"
                        title="Communication Signal"
                      />
                    </th>
                    <th className="col-3">System</th>
                    {/* <th>Type</th> */}
                    <th className="col-3">Status</th>
                    <th className="col-4"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.operatorOf.map((system) => (
                    <tr
                      key={system.systemID}
                      onClick={() =>
                        this.handleRowClick(
                          `${system.systemBasics.systemConfiguration}`,
                          `${system.systemID.id}`,
                          `${this.state.companyName}`,
                          `${system.systemID.SN}`,
                          `${system.systemID.name}`,
                          "operatorOf"
                        )
                      }
                    >
                      <td>
                        {(system.systemConnections === null || system.systemConnections === 2) && (
                          <button className="led-red-on"></button>
                        )}
                        {system.systemConnections === 1 && (
                          <button className="led-yellow-on"></button>
                        )}
                        {system.systemConnections === 0 && (
                          <button className="led-green-on"></button>
                        )}
                      </td>
                      <td>{system.systemID.name}</td>
                      {/* <td>{system.systemProduct}</td> */}
                      <td>{system.systemStatus}</td>
                      <td className="icon">
                        {system.systemBasics.systemConfiguration !== "8" && (
                          <FontAwesomeIcon
                            icon="tachometer-alt"
                            size="lg"
                            color="green"
                            title="monitor"
                          />
                        )}
                        {system.systemBasics.systemConfiguration === "8" && (
                          <FontAwesomeIcon
                            icon="bolt"
                            size="lg"
                            color="green"
                            title="Monitor SimplNodes"
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default UserSystems;
