import React from "react";
import { toast } from "react-toastify";
import Form from "./common/form";
import {
  getSystem,
  getSystemControls,
  setSystemControls,
  getSystemIDfromHandle,
  findSystemBySerialNumber,
  getSystems,
  getSystemInfo,
} from "../services/systemService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import BackGroundRectangle from "../components/backgroundRect";
import Clipboard from "react-clipboard.js";

class ControlsForm extends Form {
  constructor() {
    super();

    this.onSuccess = this.onSuccess.bind(this);
    this.getText = this.getText.bind(this);
  }
  state = {
    message: "",
    systemID: "",
    systemSN: "",
    systemName: "",
    loadNumber: "1",
    modeOfOperation: "1",
    minGridPower: "0.0",
    maxGridPower: "100.0",
    methodOfUse: "1",
    backUpMode: "1",
    disChargeSyncTime: "1",
    data: {
      load1: {},
      load2: {},
      load3: {},

      touStart: "17.0",
      touStop: "21",
      fiveDaysOr7: "1",
      onPeakMonthsStart: "5",
      onPeakMonthStop: "11",
      powerReduction: "3.0",
    },

    errors: {},
  };

  async componentDidMount() {
    const handleId = this.props.match.params.systemHandleID;
    const systemID = (await getSystemIDfromHandle(handleId)).data.data
      .system_id;

    const system = (await getSystem(systemID)).data.data[0];
    const systemName = system.systemID.name;
    console.log("System Info", system);
    const systemControls = (await getSystemControls(handleId)).data.data
      .controls;
    const modeOfOperation = systemControls.modeOfOperation;
    const minGridPower = systemControls.minGridPower;
    const maxGridPower = systemControls.maxGridPower;
    const moduleSN = systemControls.moduleSN;
    let methodOfUse = systemControls.methodOfUse;
    if (methodOfUse === undefined || methodOfUse === null) methodOfUse = "1";
    const sm2Multiplier = systemControls.sm2Multiplier;
    const disChargeSyncTime = systemControls.disChargeSyncTime;
    const socketName = systemControls.socketName;
    const socketConfig = systemControls.socketConfig;
    const backUpMode = systemControls.backUpMode;
    const dcVout = systemControls.dcVout;

    if (systemControls) this.setState({ data: systemControls });
    console.log("System Controls", systemControls);
    this.setState({
      systemID,
      systemName,
      modeOfOperation,
      minGridPower,
      maxGridPower,
      moduleSN,
      methodOfUse,
      sm2Multiplier,
      disChargeSyncTime,
      socketName,
      socketConfig,
      backUpMode,
      dcVout,
    });
  }

  handleSave = async (e) => {
    e.preventDefault();
    const moduleSN = this.state.moduleSN; //This is specified on the interface as the PRODUCT (not board) serial number
    let moduleHandleId = 0;
    console.log("Controls Module SN: ", moduleSN);
    const numOfSystems = (await getSystems()).data.data.length;
    let systems = (await getSystems()).data.data;
    console.log("Systems:", systems);
    console.log("Number of Systems", numOfSystems);
    let filteredSystem = [];
    //First search for boards
    filteredSystem = systems.filter(
      (thisSystem) => thisSystem.systemSN === moduleSN
    );
    console.log("Filtered System", filteredSystem);

    const modeOfOperation = parseInt(this.state.modeOfOperation);
    const backUpMode = parseInt(this.state.backUpMode);
    console.log("Mode of Operation: ", modeOfOperation);
    /*  if (
      modeOfOperation === 1 ||
      modeOfOperation === 2 ||
      modeOfOperation === 3 ||
      modeOfOperation === 4 ||
      modeOfOperation === 5 ||
      modeOfOperation === 6 ||
      modeOfOperation === 7 ||
      modeOfOperation === 8 ||
      modeOfOperation === 9 ||
      modeOfOperation === 10 ||
      backUpMode === 3 ||
      backUpMode === 4
    ) {*/
    console.log("Filtered System Length: ", filteredSystem.length);
    if (filteredSystem.length === 0) {
      //This is not a board so let's search for it as a Product
      systems = (await getSystems()).data;
      let i = 0;
      let numOfFoundSystems = 0;
      let foundSystem;
      for (i = 0; i < numOfSystems; i++) {
        if (systems.data[i].systemBasics !== null) {
          if (systems.data[i].systemBasics.productSN !== undefined) {
            if (systems.data[i].systemBasics.productSN === moduleSN) {
              numOfFoundSystems++;
              foundSystem = (await getSystems()).data.data[i];
            }
          }
        }
      }
      moduleHandleId = 0;
      console.log("Number of Found Systems: ", numOfFoundSystems);
      if (numOfFoundSystems === 0) {
        this.setState({ message: "Error: Invalid serial number." });
        toast.warning("Error: Invalid serial number.");
      } else if (numOfFoundSystems >= 2)
        toast.warning("Error: Duplicate serial numbers in the system.");
      else {
        this.setState({ message: "This serial number is a Product." });
        //toast.success("Success:  This serial number is a product.");

        moduleHandleId = foundSystem.systemHandleID;
        console.log("HANDLE id OF THE ATTACHED MODULE:", moduleHandleId);
      }
    } else if (filteredSystem.length === 1) {
      this.setState({ message: "This serial number is a Board." });
      toast.warning(
        "Error:  This serial number is a Board.  Enter a PRODUCT serial number"
      );

      moduleHandleId = 0;
    } else {
      toast.warning("Error: Duplicate serial numbers in the system.");
      moduleHandleId = 0;
    }
    /*  }*/
    console.log("Get System:", moduleHandleId);
    const methodOfUse = this.state.methodOfUse;
    console.log("Method Of Use: ", methodOfUse);

    await setSystemControls(
      this.state.systemID,
      this.state.modeOfOperation,
      this.state.data.touStart,
      this.state.data.touStop,
      this.state.data.fiveDaysOr7,
      this.state.data.onPeakMonthsStart,
      this.state.data.onPeakMonthStop,
      this.state.data.powerReduction,
      this.state.maxGridPower,
      this.state.minGridPower,
      this.state.data.load1,
      this.state.data.load2,
      this.state.data.load3,
      this.state.moduleSN,
      moduleHandleId,
      this.state.methodOfUse,
      this.state.sm2Multiplier,
      this.state.disChargeSyncTime,
      this.state.socketName,
      this.state.socketConfig,
      this.state.backUpMode,
      this.state.dcVout
    );
    toast.warning("Changes are saved but must be uploaded to take effect.");
    this.props.history.goBack();
    /*this.props.history.push(
      `/Companies/systems/${this.props.match.params.companyName}`
    );*/
  };

  handleBackUpChange = ({ currentTarget: input }) => {
    const backUpMode = input.value;
    this.setState({ backUpMode });
  };
  handlesocketName = ({ currentTarget: input }) => {
    const socketName = input.value;
    this.setState({ socketName });
  };
  handlesocketConfig = ({ currentTarget: input }) => {
    const socketConfig = input.value;
    this.setState({ socketConfig });
  };
  handleModuleSN = ({ currentTarget: input }) => {
    const moduleSN = input.value;
    this.setState({ moduleSN });
  };

  handleMultiplier = ({ currentTarget: input }) => {
    const sm2Multiplier = input.value;
    this.setState({ sm2Multiplier });
  };

  handlePVSmooth = ({ currentTarget: input }) => {
    const maxGridPower = input.value;
    this.setState({ maxGridPower });
  };
  handleSelfSupply = ({ currentTarget: input }) => {
    const minGridPower = input.value;
    this.setState({ minGridPower });
  };

  handleSetVoltage = ({ currentTarget: input }) => {
    const dcVout = input.value;
    this.setState({ dcVout });
  };
  handleModeChange = ({ currentTarget: input }) => {
    const modeOfOperation = input.value;
    this.setState({ modeOfOperation });
  };

  handleMethodChange = ({ currentTarget: input }) => {
    const methodOfUse = input.value;
    this.setState({ methodOfUse });
  };

  handleSyncTime = ({ currentTarget: input }) => {
    const disChargeSyncTime = input.value;
    this.setState({ disChargeSyncTime });
  };

  handleLoadChange = ({ currentTarget: input }) => {
    const loadNumber = input.value;
    this.setState({ loadNumber });
  };

  handlePriorityChange = ({ currentTarget: input }) => {
    const loadNumber = this.state.loadNumber;
    console.log("Load Number", loadNumber);
    let data = this.state.data;
    let dataLoad = this.state.data.load1;
    if (loadNumber === "1") {
      dataLoad = this.state.data.load1;
      dataLoad = { ...dataLoad, loadPriority: input.value };
      data = { ...data, load1: dataLoad };
    }
    if (loadNumber === "2") {
      dataLoad = this.state.data.load2;
      dataLoad = { ...dataLoad, loadPriority: input.value };
      data = { ...data, load2: dataLoad };
    }
    if (loadNumber === "3") {
      dataLoad = this.state.data.load3;
      dataLoad = { ...dataLoad, loadPriority: input.value };
      data = { ...data, load3: dataLoad };
    }
    this.setState({ data });
  };

  handleChange = ({ currentTarget: input }) => {
    const loadNumber = this.state.loadNumber;
    console.log("Load Number", loadNumber);
    let data = this.state.data;
    let dataLoad = this.state.data.load1;
    if (loadNumber === "1") {
      dataLoad = this.state.data.load1;
      dataLoad = { ...dataLoad, [input.id]: input.value };
      data = { ...data, load1: dataLoad };
    }
    if (loadNumber === "2") {
      dataLoad = this.state.data.load2;
      dataLoad = { ...dataLoad, [input.id]: input.value };
      data = { ...data, load2: dataLoad };
    }
    if (loadNumber === "3") {
      dataLoad = this.state.data.load3;
      dataLoad = { ...dataLoad, [input.id]: input.value };
      data = { ...data, load3: dataLoad };
    }
    this.setState({ data });
  };
  onSuccess() {
    toast.success("Copied socket server's name for use on the client side!");
  }

  getText() {
    const socketServerName = "simplAts3454";
    this.setState({ socketServerName });
    return this.state.socketServerName;
  }
  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
    this.setState({ copySuccess: "Copied!" });
  };

  render() {
    let loadData = this.state.data.load1;
    if (this.state.loadNumber === "1") loadData = this.state.data.load1;
    if (this.state.loadNumber === "2") loadData = this.state.data.load2;
    if (this.state.loadNumber === "3") loadData = this.state.data.load3;

    return (
      <React.Fragment>
        <div
          style={{
            width: "350px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150",
            zIndex: 1,
          }}
        >
          <p className="basicParagraph">
            Edit System Control Settings for {this.state.systemName}:
          </p>
          <p></p>
          <label className="basicParagraph" htmlFor="">
            Socket Configuration:
          </label>
          <select
            className="form-control2"
            onChange={this.handlesocketConfig}
            value={this.state.socketConfig}
            style={{ fontSize: "12px", fontWeight: "bold" }}
          >
            <option value="1">HTTP Only ; no Socket</option>
            <option value="2">HTTP and Socket Server</option>
            <option value="3">HTTP and Socket Client</option>
            <option value="4">Socket Client Only; no HTTP</option>
            <option value="5">Socket Server Only; no HTTP</option>
          </select>
          <p></p>
          {(this.state.socketConfig === "3" ||
            this.state.socketConfig === "4") && (
            <p>
              <label className="basicParagraph" htmlFor="">
                Enter Socket Server's Name:
              </label>
              <input
                className="form-control2"
                value={this.state.socketName}
                onChange={this.handlesocketName}
                id="socketName"
                type="text"
              />
            </p>
          )}
          {(this.state.socketConfig === "2" ||
            this.state.socketConfig === "5") && (
            <p>
              <label className="basicParagraph" htmlFor="">
                Socket server's name: {this.state.socketServerName}
              </label>
              <Clipboard option-text={this.getText} onSuccess={this.onSuccess}>
                copy to clipboard
              </Clipboard>
            </p>
          )}

          <label className="basicParagraph" htmlFor="">
            If a SimplMeter or a SimplSwitch module is used as part of the
            system, enter its PRODUCT serial number here:
          </label>
          <input
            className="form-control2"
            value={this.state.moduleSN}
            onChange={this.handleModuleSN}
            id="moduleSN"
            type="text"
          />
          <p></p>
          <label className="basicParagraph" htmlFor="">
            Select SimplMeter II Channel and Method:
          </label>
          <select
            className="form-control2"
            onChange={this.handleMethodChange}
            value={this.state.methodOfUse}
            style={{ fontSize: "12px", fontWeight: "bold" }}
          >
            <option value="1">Channel 1</option>
            <option value="2">Channel 2</option>
            <option value="3">Channel 1 - Channel 2</option>
            <option value="4">Channel 2 - Channel 1</option>
            <option value="5">Channel 1 + Channel 2</option>
          </select>
          <label className="basicParagraph" htmlFor="">
            Select SimplMeter Power Multiplier Value (CAUTION: for development
            use only):
          </label>
          <input
            className="form-control2"
            value={this.state.sm2Multiplier}
            onChange={this.handleMultiplier}
            id="moduleMultiplier"
            type="text"
          />
          <label className="basicParagraph" htmlFor="">
            Select Discharge Synchronization Period in seconds (must be between
            1 and 300):
          </label>
          <input
            className="form-control2"
            value={this.state.disChargeSyncTime}
            onChange={this.handleSyncTime}
            id="moduleSyncTime"
            type="text"
          />
          <label className="basicParagraph" htmlFor="">
            Select Backup Topology:
          </label>
          <select
            className="form-control2"
            onChange={this.handleBackUpChange}
            value={this.state.backUpMode}
            style={{ fontSize: "12px", fontWeight: "bold" }}
          >
            <option value="1">No BackUp</option>
            <option value="2">SimplBackUp</option>
            <option value="3">AC-Coupled Backup</option>
            <option value="4">SimplBackUp & AC-Coupled</option>
          </select>
          <h6> </h6>

          {this.state.backUpMode !== "1" && (
            <div>
              <BackUpModal backUpMode={this.state.backUpMode} />
              <p
                className="basicParagraph"
                style={{
                  color: "green",
                  marginLeft: "15px",
                  paddingLeft: "15px",
                }}
              >
                Click here for more Information
              </p>
            </div>
          )}

          <label className="basicParagraph" htmlFor="">
            Select energy storage algorithm:
          </label>
          <select
            className="form-control2"
            onChange={this.handleModeChange}
            value={this.state.modeOfOperation}
            style={{ fontSize: "12px", fontWeight: "bold" }}
          >
            <option value="1">Time-of-Use</option>
            <option value="2">Demand Shaving</option>
            <option value="3">Self-Supply</option>
            <option value="4">Self-Supply | No Export</option>
            <option value="5">PV Smoothing</option>
            <option value="6">Load Control</option>
            <option value="7">Force On-Peak / Discharge</option>
            <option value="8">Force Off-Peak / Charge</option>
            <option value="9">Force Standby</option>
            <option value="10">Set Inverter Power</option>
            <option value="11">Off-Grid DC Load</option>
          </select>
          <h6> </h6>
          {this.state.modeOfOperation === "5" && <ControlsModal />}
          <div>
            {(this.state.modeOfOperation === "1" ||
              this.state.modeOfOperation === "7" ||
              this.state.modeOfOperation === "8" ||
              this.state.modeOfOperation === "9") && (
              <div>
                <p className="basicParagraph">
                  Time of use parameters must be set in the tariff structure.
                </p>
                <form onSubmit={this.handleSave}>
                  <button
                    className="basicButton"
                    style={{
                      width: "150px",
                      marginLeft: "50px",
                      marginTop: "10px",
                    }}
                  >
                    Save Control Mode
                  </button>
                </form>
              </div>
            )}
            {this.state.modeOfOperation === "2" && (
              <div>
                <p className="basicParagraph">
                  Max Power for each month is set in the tariff structure.
                </p>
                <form onSubmit={this.handleSave}>
                  <button
                    className="basicButton"
                    style={{
                      width: "150px",
                      marginLeft: "50px",
                      marginTop: "10px",
                    }}
                  >
                    Save Control Mode
                  </button>
                </form>
              </div>
            )}
            {(this.state.modeOfOperation === "3" ||
              this.state.modeOfOperation === "4") && (
              <form onSubmit={this.handleSave}>
                <label className="basicParagraph" htmlFor="">
                  Set minimum grid power in kW:
                </label>
                <input
                  className="form-control2"
                  value={this.state.minGridPower}
                  onChange={this.handleSelfSupply}
                  id="minGridPower"
                  type="text"
                  style={{ width: "75px" }}
                />
                <button
                  className="basicButton"
                  style={{
                    width: "150px",
                    marginLeft: "50px",
                    marginTop: "10px",
                  }}
                >
                  Save Parameters
                </button>
              </form>
            )}
            {this.state.modeOfOperation === "5" && (
              <form onSubmit={this.handleSave}>
                <label className="basicParagraph" htmlFor="">
                  Set max export Power in kW:
                </label>
                <input
                  className="form-control2"
                  value={this.state.maxGridPower}
                  onChange={this.handlePVSmooth}
                  id="maxGridPower"
                  type="text"
                  style={{ width: "75px" }}
                />
                <button
                  className="basicButton"
                  style={{
                    width: "150px",
                    marginLeft: "50px",
                    marginTop: "10px",
                  }}
                >
                  Set Max Export to Grid
                </button>
              </form>
            )}
            {this.state.modeOfOperation === "6" && (
              <div>
                {" "}
                <select
                  className="form-control2"
                  onChange={this.handleLoadChange}
                  value={this.state.loadNumber}
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  <option value="1">Load 1</option>
                  <option value="2">Load 2</option>
                  <option value="3">Load 3</option>
                </select>
                <form onSubmit={this.handleSave} style={{ marginLeft: "20px" }}>
                  <label className="formLabel">Load Name:</label>
                  <input
                    className="form-control2"
                    value={loadData.loadName}
                    onChange={this.handleChange}
                    id="loadName"
                    type="text"
                    style={{ marginTop: "0px", width: "150px" }}
                  />

                  <label className="formLabel">Name Plate Ratng (kW):</label>
                  <input
                    className="form-control2"
                    value={loadData.namePlatePower}
                    onChange={this.handleChange}
                    id="namePlatePower"
                    type="text"
                    style={{ marginTop: "0px", width: "75px" }}
                  />

                  <label className="formLabel">Load Priority:</label>
                  <select
                    className="form-control2"
                    onChange={this.handlePriorityChange}
                    value={loadData.loadPriority}
                    style={{ marginTop: "0px", width: "75px" }}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>

                  <label className="formLabel">Turn Off Threshold (kW):</label>
                  <input
                    className="form-control2"
                    value={loadData.loadOffThresh}
                    onChange={this.handleChange}
                    id="loadOffThresh"
                    type="text"
                    style={{ marginTop: "0px", width: "75px" }}
                  />

                  <label className="formLabel">Turn On Threshold (kW):</label>
                  <input
                    className="form-control2"
                    value={loadData.loadOnThresh}
                    onChange={this.handleChange}
                    id="loadOnThresh"
                    type="text"
                    style={{ marginTop: "0px", width: "75px" }}
                  />

                  <label className="formLabel">Turn On Duration (Sec.):</label>
                  <input
                    className="form-control2"
                    value={loadData.loadOnSeconds}
                    onChange={this.handleChange}
                    id="loadOnSeconds"
                    type="text"
                    style={{ marginTop: "0px", width: "75px" }}
                  />

                  <label className="formLabel">Max Time Off (Min.):</label>
                  <input
                    className="form-control2"
                    value={loadData.maxTimeOffMins}
                    onChange={this.handleChange}
                    id="maxTimeOffMins"
                    type="text"
                    style={{ marginTop: "0px", width: "75px" }}
                  />

                  <label className="formLabel">
                    Min Time Before Restart (Min.):
                  </label>
                  <input
                    className="form-control2"
                    value={loadData.minTimeRestart}
                    onChange={this.handleChange}
                    id="minTimeRestart"
                    type="text"
                    style={{ marginTop: "0px", width: "75px" }}
                  />

                  <button
                    className="basicButton"
                    style={{ width: "150px", marginTop: "10px" }}
                  >
                    Save Load Parameters
                  </button>
                </form>
              </div>
            )}
            {this.state.modeOfOperation === "10" && (
              <form onSubmit={this.handleSave}>
                <label className="basicParagraph" htmlFor="">
                  Set System Inverter Power (kW):
                </label>
                <input
                  className="form-control2"
                  value={this.state.minGridPower}
                  onChange={this.handleSelfSupply}
                  id="minGridPower"
                  type="text"
                  style={{ width: "75px" }}
                />
                <button
                  className="basicButton"
                  style={{
                    width: "150px",
                    marginLeft: "50px",
                    marginTop: "10px",
                  }}
                >
                  Save Parameters
                </button>
              </form>
            )}
            {this.state.modeOfOperation === "11" && (
              <form onSubmit={this.handleSave}>
                <label className="basicParagraph" htmlFor="">
                  Set Output DC Voltage (V):
                </label>
                <input
                  className="form-control2"
                  value={this.state.dcVout}
                  onChange={this.handleSetVoltage}
                  id="dcVout"
                  type="text"
                  style={{ width: "75px" }}
                />
                <button
                  className="basicButton"
                  style={{
                    width: "150px",
                    marginLeft: "50px",
                    marginTop: "10px",
                  }}
                >
                  Save Parameters
                </button>
              </form>
            )}
          </div>
          <h6> </h6>
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default ControlsForm;

class BackUpModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <>
        <button
          className="btn btn-outline-light"
          style={{
            borderWidth: "0px",
            padding: "0px",
            float: "left",
            marginRight: "10px",
            position: "relative",
            left: "10px",
            bottom: "5px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="info-circle"
            size="sm"
            color="dodgerblue"
            title="System Information"
          />
        </button>

        <Modal
          style={{ width: "250px", alignContent: "center" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "12px" }}
            ></Modal.Title>
            Setup Requirements:
          </Modal.Header>

          <Modal.Body>
            {this.props.backUpMode === "2" && (
              <p
                style={{
                  color: "#2d5c88",
                  fontWeight: "bold",
                  fontSize: "9px",
                  textAlign: "left",
                  marginBottom: "1px",
                }}
              >
                When using SimplBox with the SimplBackUp (SBU) option, SimplBox
                Units automatically discover if they are equipped with backup
                capabilities. Output Voltage and other parameters may have to be
                specified.
              </p>
            )}
            {this.props.backUpMode === "3" && (
              <div>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  For an AC-coupled system, a SimplMeter must be attached to the
                  system by specifying its Serial Number above.
                </p>
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Important: All three voltages lines are used. Phase A and
                  Phase B lines on the SimplMeter must be connected to H1 and H2
                  of the AC-coupled backup system. Phase C voltage line must be
                  connected to the H1 of the grid input.
                </p>
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Meter 1 rope CTs must be connected to the output of the
                  external AC-coupled backup system.
                </p>{" "}
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Meter 2 rope CTs must be connected to the output of the solar
                  system feeding into the AC-coupled backup system.
                </p>{" "}
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  All CT arrows must point to the breakers for the solar and
                  AC-coupled systems.
                </p>
              </div>
            )}

            {this.props.backUpMode === "4" && (
              <div>
                {" "}
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  When using SimplBox with the SimplBackUp (SBU) option,
                  SimplBox Units automatically discover if they are equipped
                  with backup capabilities. Output Voltage and other parameters
                  may have to be specified.
                </p>
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  When adding an AC-coupled system, a SimplMeter must be
                  attached to the system by specifying its Serial Number above.
                </p>
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Meter 1 must be connected to the external AC-coupled backup
                  system.
                </p>{" "}
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Meter 2 must be connected to the solar system feeding into the
                  AC-coupled backup system.
                </p>{" "}
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  All SimplCT arrows must point to the breakers for the solar
                  and AC-coupled systems.
                </p>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

class ControlsModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <>
        <button
          className="btn btn-outline-light"
          style={{
            borderWidth: "0px",
            padding: "0px",
            float: "right",
            marginRight: "5px",
            position: "relative",
            right: "0px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="info-circle"
            size="sm"
            color="dodgerblue"
            title="System Information"
          />
        </button>

        <Modal
          style={{ width: "250px", alignContent: "center" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "14px" }}
            ></Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "10px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Description of Control Parameters
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Up to three load controllers may be programmed for each system.
              The Load controller capabilities may be expanded by installing
              additional SimplLoads units.
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "11px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Name Plate Rating:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Nominal power rating for the load in kW.
            </p>
            <hr></hr>

            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "11px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Load Priority: Range: 0 to 4
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              The lower the load priority level the earlier the load will be
              disconnected from power in a power shedding event. 0: Never
              Connected 4: Always Connected
            </p>
            <hr></hr>

            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "11px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Turn Off Threshold
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              The load is qualified for being disconnected once grid power level
              reaches this value in kW.
            </p>
            <hr></hr>

            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "11px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Turn On Threshold
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              The load is not turned back on until power level is below this
              level in kW. Along with Turn On Threshold, these two parameters
              provide a windown or a range in which the load is kept off once it
              is disconnected from the grid.
            </p>
            <hr></hr>

            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "11px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Turn On Duration
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              When a load is turned on, it might take several seconds before its
              power level reaches steady state. Power measurements must be
              ignored in this so-called turn-on stage. Different loads have
              different settling or turn-on duration times. This parameters
              helps the system measure power after it had stabilized.
            </p>
            <hr></hr>

            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "11px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Max Time Off
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Once a load is disconnected, in some cases such as refrigeration
              systems, etc. it is important the the load does not stay
              disconnected for too long. This parameter sets the max time for
              each load in Mins.
            </p>
            <hr></hr>

            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "11px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Turn Time Before Restart
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              If a load is turned off, this parameters is designed to make sure
              the load stays off for a minimum period of time.
            </p>
            <hr></hr>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
