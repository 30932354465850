import React, { Component } from "react";

import "./dashboard.css";
import "../index.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import "react-datepicker/dist/react-datepicker.css";

function PriorityProgress(props) {
  return (
    <React.Fragment>
      <div
        className="progress-bar"
        style={{
          position: "absolute",
          top: `${props.divTop}px`,
          left: "270px",
          height: `${props.divHeight}px`,
          backgroundColor: "green",
          width: "15px",
          borderRadius: "3px",
        }}
      ></div>
      <div
        className="progress-bar"
        style={{
          position: "absolute",
          top: "90px",
          left: "270px",
          height: "120px",
          backgroundColor: "transparent",
          width: "15px",
          borderRadius: "3px",
        }}
      >
        <p
          classname="basicParagraph"
          style={{
            fontSize: "11px",
            fontWeight: "bold",
            height: "7px",
            color: "#D9D9D9",
            padding: "0px",
            margin: "0px",
            transform: "translateY(-57px)",
          }}
        >
          7
          <p>
            6
            <p>
              5
              <p>
                4
                <p>
                  3
                  <p>
                    2
                    <p>
                      1<p>0</p>
                    </p>
                  </p>
                </p>
              </p>
            </p>
          </p>
        </p>
      </div>
      <div
        style={{
          position: "absolute",
          top: "225px",
          left: "225px",
        }}
      >
        <p
          classname="basicParagraph"
          style={{
            fontSize: "11px",
            color: "#595959",
            fontWeight: "bold",
            textAlign: "right",
            padding: "0px",
            margin: "0px",
            transform: "translateY(-50px)",
          }}
        >
          Priority<p>Level</p>
        </p>
      </div>
    </React.Fragment>
  );
}
export default PriorityProgress;
