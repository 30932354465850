
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {getUserByEmail, registerUser, loginUser, googleLogin } from "../services/userService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import jwt_decode from "jwt-decode"
import {
    apiUrl,
    clientGoogleID
} from "../config.json";
import BackGroundRectangle from "./backgroundRect";

function GoogleLogin() {

    const [googleAvailable, setGoogleAvailable] = useState(false);
    let google

    async function getGoogleCredentials(response){
        const googleToken = response.credential
        try {
            const user = await googleLogin(googleToken)
            if (user){
                toast.success("Valid google login")
            }
            const username = user.data.user.username
            const password = user.data.user.password
            const verified = false;

            const logindata = await loginUser(username, password)

            console.log("logindata", logindata);
            const token = logindata.data.token;
            console.log("User Token", token);
            localStorage.setItem("token", token);
            console.log("Got the user"); 
            localStorage.setItem("verified", verified);
            localStorage.setItem("systemMapButton", "noButton");
            console.log(
                "System Button in Login",
                localStorage.getItem("systemMapButton")
            );
            window.location = "/";
        } catch (e){
            toast.error('Unable to find the user')
        }
    }

    const buttonStyle = {
        marginTop: "10px"
    }

    try {
        useEffect( () => {
            if (window.google && window.google.accounts && window.google.accounts.id) {
                setGoogleAvailable(true);
                google = window.google
            } else {
                // If not available, wait for the 'load' event
                window.addEventListener('load', () => {
                  if (window.google && window.google.accounts && window.google.accounts.id) {
                    setGoogleAvailable(true);
                    google = window.google
                  }
                });
            }

            if (googleAvailable){
                google.accounts.id.initialize({
                    client_id: clientGoogleID,
                    cancel_on_tap_outside: true,
                    callback: getGoogleCredentials,
                })
        
                google.accounts.id.renderButton(
                    document.getElementById('sigInDiv'),
                    { theme: "outline", size: "large" }
                )
            }
        
            return () => {
                window.removeEventListener('load', () => {});
            };

        }, [googleAvailable])
    } catch (e){
        toast.error(e.message)
    }

    return (
      <React.Fragment>
        {googleAvailable ? (
            <div id="sigInDiv" style={buttonStyle}>
            </div>
        ): (
            <div></div>
        )}

        </React.Fragment>
    );

}


export default GoogleLogin
