import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./dashboard.css";
import "../index.css";
import "react-datepicker/dist/react-datepicker.css";
import clearSky_01dLogo from "./icons/clear sky 01d.png";
import fewClouds_02dLogo from "./icons/few clouds 02d.png";
import scatteredClouds_03dLogo from "./icons/scattered clouds 03d.png";
import brokenClouds_04dLogo from "./icons/broken clouds 04d.png";
import shower_09dLogo from "./icons/shower rain 09d.png";
import rain_10dLogo from "./icons/rain 10d.png";
import thunderstorm_11dLogo from "./icons/thunderstorm 11d.png";
import snow_13dLogo from "./icons/snow 13d.png";
import mist_50dLogo from "./icons/mist 50d.png";

function MainMenuWide(props) {
  const weatherTemp = (
    (parseFloat(props.weatherTemp) - 273.15) * 1.8 +
    32.0
  ).toFixed(0);
  return (
    <React.Fragment>
      <p
        className="systemClockNode"
        style={{ width: "1140px", marginBottom: "0px" }}
      >
        <FontAwesomeIcon
          style={{ marginLeft: "20px", marginRight: "3px" }}
          icon="cog"
          size="lg"
          color="white"
          title="Local System Time"
        />
        {props.systemName}
        <FontAwesomeIcon
          style={{ marginLeft: "20px", marginRight: "3px" }}
          icon="angle-double-up"
          size="lg"
          color="white"
          title="Peak Power Setting"
        />
        Peak Set: {parseFloat(props.peakPower).toFixed(1)} kW
        <FontAwesomeIcon
          style={{ marginLeft: "20px", marginRight: "3px" }}
          icon="calculator"
          size="lg"
          color="white"
          title="Real-time Power"
        />
        Power: {(parseFloat(props.realTimePower) / 1000.0).toFixed(1)} kW
        <FontAwesomeIcon
          style={{ marginLeft: "20px", marginRight: "3px" }}
          icon="chart-bar"
          size="lg"
          color="white"
          title="15-minute Interval"
        />
        Interval: {parseFloat(props.intervalPower).toFixed(1)} kW
        <FontAwesomeIcon
          style={{ marginLeft: "20px", marginRight: "3px" }}
          icon="bullseye"
          size="lg"
          color="white"
          title="Global Priority"
        />
        Priority: {props.globalPriority}
        <FontAwesomeIcon
          style={{ marginLeft: "20px", marginRight: "3px" }}
          icon="clock"
          size="lg"
          color="white"
          title="Local System Time"
        />
        {props.systemHumanTime}
        {props.commMode === 0 && (
          <FontAwesomeIcon
            style={{ marginLeft: "20px", marginRight: "3px" }}
            icon="wifi"
            size="lg"
            color="red"
            title="Wifi Signal Strength"
          />
        )}
        {(props.commMode === 1 || props.commMode === 3) && (
          <FontAwesomeIcon
            style={{ marginLeft: "20px", marginRight: "3px" }}
            icon="wifi"
            size="lg"
            color="white"
            title="Wifi Signal Strength"
          />
        )}
        {props.commMode === 2 && (
          <FontAwesomeIcon
            style={{ marginLeft: "20px", marginRight: "3px" }}
            icon="signal"
            size="lg"
            color="white"
            title="Wifi Signal Strength"
          />
        )}
        {props.wifiSignalStrength}
        <FontAwesomeIcon
          style={{ marginLeft: "20px", marginRight: "3px" }}
          icon="square"
          size="lg"
          color="white"
          title="# of SimplBoxes"
        />
        {props.slaveCount}
        {(props.weatherIcon === "01d" || props.weatherIcon === "01n") && (
          <img
            src={clearSky_01dLogo}
            style={{ marginLeft: "15px", marginRight: "5px", width: "25px" }}
          />
        )}
        {(props.weatherIcon === "02d" || props.weatherIcon === "02n") && (
          <img
            src={fewClouds_02dLogo}
            style={{ marginLeft: "15px", marginRight: "5px", width: "25px" }}
          />
        )}
        {(props.weatherIcon === "03d" || props.weatherIcon === "03n") && (
          <img
            src={scatteredClouds_03dLogo}
            style={{ marginLeft: "15px", marginRight: "5px", width: "25px" }}
          />
        )}
        {(props.weatherIcon === "04d" || props.weatherIcon === "04n") && (
          <img
            src={brokenClouds_04dLogo}
            style={{ marginLeft: "15px", marginRight: "5px", width: "25px" }}
          />
        )}
        {(props.weatherIcon === "09d" || props.weatherIcon === "09n") && (
          <img
            src={shower_09dLogo}
            style={{ marginLeft: "15px", marginRight: "5px", width: "25px" }}
          />
        )}
        {(props.weatherIcon === "10d" || props.weatherIcon === "10n") && (
          <img
            src={rain_10dLogo}
            style={{ marginLeft: "15px", marginRight: "5px", width: "25px" }}
          />
        )}
        {(props.weatherIcon === "11d" || props.weatherIcon === "11n") && (
          <img
            src={thunderstorm_11dLogo}
            style={{ marginLeft: "15px", marginRight: "5px", width: "25px" }}
          />
        )}
        {(props.weatherIcon === "13d" || props.weatherIcon === "13n") && (
          <img
            src={snow_13dLogo}
            style={{ marginLeft: "15px", marginRight: "5px", width: "25px" }}
          />
        )}
        {(props.weatherIcon === "50d" || props.weatherIcon === "50n") && (
          <img
            src={mist_50dLogo}
            style={{ marginLeft: "15px", marginRight: "5px", width: "25px" }}
          />
        )}
        {weatherTemp}&#176;F | {props.weatherRH}%
        {props.loopCount % 2 === 0 && (
          <FontAwesomeIcon
            style={{ marginLeft: "20px", marginRight: "3px" }}
            icon="heartbeat"
            size="lg"
            color="white"
            title="System Heartbeat"
          />
        )}
        {props.loopCount % 2 === 1 && (
          <FontAwesomeIcon
            style={{ marginLeft: "20px", marginRight: "3px" }}
            icon="heartbeat"
            size="lg"
            color="#c5c2c2"
            title="System Heartbeat"
          />
        )}
        {props.outageFlag === 0 &&
          props.loopCount % 2 === 0 &&
          props.acCoupledBackUp === 1 && (
            <FontAwesomeIcon
              style={{ marginLeft: "20px", marginRight: "3px" }}
              icon="charging-station"
              size="lg"
              color="white"
              title="On Grid"
            />
          )}
        {props.outageFlag === 0 &&
          props.loopCount % 2 === 1 &&
          props.acCoupledBackUp === 1 && (
            <FontAwesomeIcon
              style={{ marginLeft: "20px", marginRight: "3px" }}
              icon="charging-station"
              size="lg"
              color="#c5c2c2"
              title="On Grid"
            />
          )}
        {props.outageFlag === 1 && props.acCoupledBackUp === 1 && (
          <FontAwesomeIcon
            style={{ marginLeft: "20px", marginRight: "3px" }}
            icon="charging-station"
            size="lg"
            color="#c5c2c2"
            title="Power Outage"
          />
        )}
        {props.loopCount % 2 === 0 && props.moduleDataValid === 1 && (
          <FontAwesomeIcon
            style={{ marginLeft: "20px", marginRight: "3px" }}
            icon="network-wired"
            size="lg"
            color="white"
            title="Module Connected"
          />
        )}
        {props.loopCount % 2 === 1 && props.moduleDataValid === 1 && (
          <FontAwesomeIcon
            style={{ marginLeft: "20px", marginRight: "3px" }}
            icon="network-wired"
            size="lg"
            color="#c5c2c2"
            title="Module Connected"
          />
        )}
      </p>
      {props.inCompleteData === 1 && (
        <p
          className="systemClock"
          style={{ paddingTop: "2px", fontSize: "9px" }}
        >
          Warning: Missing data points!
        </p>
      )}
    </React.Fragment>
  );
}
export default MainMenuWide;
