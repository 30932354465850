import React from "react";

const LoraSvg = (props) => (
  <svg style={{ margin: "0px", padding: "0px", height: "30px", width: "25px" }}>
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <title />
      <path
        d="M15.54,14.54a1,1,0,0,1-.71-.3,1,1,0,0,1,0-1.41,4,4,0,0,0,0-5.66,1,1,0,0,1,1.41-1.41,6,6,0,0,1,0,8.48A1,1,0,0,1,15.54,14.54Z"
        fill="#464646"
      />
      <path
        d="M18.36,17.36a1,1,0,0,1-.7-.29,1,1,0,0,1,0-1.41,8,8,0,0,0,0-11.32,1,1,0,0,1,1.41-1.41,10,10,0,0,1,0,14.14A1,1,0,0,1,18.36,17.36Z"
        fill="#464646"
      />
      <path
        d="M8.46,14.54a1,1,0,0,1-.7-.3,6,6,0,0,1,0-8.48A1,1,0,0,1,9.17,7.17a4,4,0,0,0,0,5.66,1,1,0,0,1,0,1.41A1,1,0,0,1,8.46,14.54Z"
        fill="#464646"
      />
      <path
        d="M5.64,17.36a1,1,0,0,1-.71-.29,10,10,0,0,1,0-14.14A1,1,0,1,1,6.34,4.34a8,8,0,0,0,0,11.32,1,1,0,0,1,0,1.41A1,1,0,0,1,5.64,17.36Z"
        fill="#464646"
      />
      <path
        d="M14,10a2,2,0,0,0-4,0,2,2,0,0,0,1,1.72V20a1,1,0,0,0,2,0V11.72A2,2,0,0,0,14,10Z"
        fill="#464646"
      />
    </svg>
  </svg>
);
export default LoraSvg;
