import React, { Component } from "react";
import * as echarts from "echarts/dist/echarts.js";
import "bootstrap/dist/css/bootstrap.css";
import "./dashboard.css";
import {
  getSystemRealTimeData,
  getSystemLatestData,
  getSystemBasics,
  ws_getSystemLatestData,
  getSystemInfo,
} from "../services/systemService";

import { webSocketMode } from "../config.json"; //0: http; 1:websocket

class AggregateGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphLen: 0,
      base: new Date(),
      oneDay: 86400000,
      date: [],
      pvPower: [0],
      batteryPower: [0],
      gridPower: [0],
      bldgPower: [0],
      //now: new Date(),
    };
  }

  async componentDidMount() {
    const dataFrequency = parseInt(this.props.dataFrequency);

    let tickNumber = 1000;
    switch (dataFrequency) {
      case 1:
        tickNumber = 1000;
        break;
      case 2:
        tickNumber = 5000;
        break;
      case 3:
        tickNumber = 15000;
        break;
      case 4:
        tickNumber = 60000;
        break;
      case 5:
        tickNumber = 300000;
        break;
      case 6:
        tickNumber = 900000;
        break;
      case 7:
        tickNumber = 1800000;
        break;
      case 8:
        tickNumber = 3600000;
        break;
      default:
        tickNumber = 1000;
        break;
    }

    const handleID = this.props.handleID;
    console.log("HandleID in graph:", handleID);
    let response = {};

    if (webSocketMode) {
      response = ws_getSystemLatestData(handleID);
      console.log("Websocket: Latest Data", response);
    } else {
      response = (await getSystemLatestData(handleID)).data.data[0];
      console.log("Http: Latest Data", response);
    }
    const slaveCount = response.data.deviceLedger.realTime.slaveCount;
    const commMode = response.data.commMode;
    console.log("Comm Mode", commMode);
    if (commMode === 2 && tickNumber < 15000) tickNumber = 15000;
    console.log("Agg. Graph: Tick Number", tickNumber);

    const pointsOnChart = parseInt(localStorage.getItem("pointsOnChart"));
    const endDate = new Date().getTime();
    const startDate = endDate - pointsOnChart * tickNumber;
    const systemRealTimeData = (
      await getSystemRealTimeData(handleID, startDate, endDate)
    ).data.data;
    const simplBoxLength = systemRealTimeData.length;
    console.log("System RealTime Data with Start and End", systemRealTimeData);
    console.log("System Configuration", this.props.systemConfiguration);
    console.log("Bldg Power units", this.props.bldgPowerUnits);
    let systemBasics = await getSystemBasics(handleID);
    console.log("System Basics", systemBasics);
    let circuitNameMeter1 = systemBasics.data.data.basics.circuitNameMeter1;
    if (circuitNameMeter1 !== "0" && circuitNameMeter1 !== "1")
      circuitNameMeter1 = "0";
    console.log("Grid or Bldg: ", circuitNameMeter1);
    ///////////////////////////////////////////////////////////////////////////////
    const systemInfo = await getSystemInfo(handleID);
    console.log("System Info in Dashboard CDM", systemInfo);
    const attachedSimplModuleSN = systemInfo.data.data.controls.moduleSN;
    const attachedSimplModuleHandle =
      systemInfo.data.data.controls.moduleHandleId;
    console.log("Module Handle ID:", attachedSimplModuleHandle);
    const moduleDataValid = response.data.AttachedModuleValid;

    let realTimeDataAttachedModule;

    if (moduleDataValid === 1 && attachedSimplModuleHandle !== 0) {
      realTimeDataAttachedModule = (
        await getSystemRealTimeData(
          attachedSimplModuleHandle,
          startDate,
          endDate
        )
      ).data.data;
      console.log("Realtime Attached Module Data", realTimeDataAttachedModule);
    }
    let simplMeterLength = 0;
    if (
      realTimeDataAttachedModule !== null &&
      realTimeDataAttachedModule !== undefined
    ) {
      simplMeterLength = realTimeDataAttachedModule.length;
    }
    let arrayLength = simplBoxLength;
    if (simplMeterLength < simplBoxLength) arrayLength = simplMeterLength;
    let pvPower = [0];
    let batteryPower = [0];
    let gridPower = [0];
    let bldgPower = [0];
    let smMeter1 = [0];
    let smMeter2 = [0];

    if (
      parseInt(this.props.systemConfiguration) === 0 &&
      slaveCount > 0 &&
      moduleDataValid === 0
    ) {
      pvPower = systemRealTimeData.map(
        (x) => x.data.deviceLedger.realTime.pvPower / 100
      );
      batteryPower = systemRealTimeData.map(
        (x) => x.data.deviceLedger.realTime.batteryPower / 100
      );
      gridPower = systemRealTimeData.map((x) =>
        (
          (x.data.deviceLedger.realTime.batteryPower +
            x.data.deviceLedger.realTime.pvPower) /
          100
        ).toFixed(0)
      );
      bldgPower = 0.0 /*systemRealTimeData.map((x) => 0)*/;
    } else if (
      parseInt(this.props.systemConfiguration) === 0 &&
      slaveCount > 0 &&
      moduleDataValid === 1
    ) {
      let i = 0;
      for (i = 0; i < arrayLength; i++) {
        smMeter1[i] =
          realTimeDataAttachedModule[
            i
          ].data.deviceLedger.simplMeter_ii.sm2Ptotal1;
        smMeter2[i] =
          realTimeDataAttachedModule[
            i
          ].data.deviceLedger.simplMeter_ii.sm2Ptotal2;

        pvPower[i] =
          systemRealTimeData[i].data.deviceLedger.realTime.pvPower / 100;
        batteryPower[i] =
          systemRealTimeData[i].data.deviceLedger.realTime.batteryPower / 100;

        if (batteryPower[i] > 0.0) {
          //Discharging
          if (smMeter1[i] > 0) {
            //buying
            pvPower[i] = smMeter2[i] - batteryPower[i];
            gridPower[i] = smMeter1[i];
            bldgPower[i] = smMeter1[i] + smMeter2[i];
          } else {
            //selling
            pvPower[i] = smMeter2[i] + smMeter1[i] - batteryPower[i];
            gridPower[i] = smMeter1[i];
            bldgPower[i] = smMeter1[i] + smMeter2[i];
          }
        } else {
          //Charging

          if (smMeter1[i] > 0) {
            //buying
            pvPower[i] = smMeter2[i];
            gridPower[i] = smMeter1[i];
            bldgPower[i] = smMeter1[i] + smMeter2[i];
          } else {
            //selling
            pvPower[i] = smMeter1[i] + smMeter2[i];
            gridPower[i] = smMeter1[i];
            bldgPower[i] = smMeter1[i] + smMeter2[i];
          }
        }
      }
    }
    ///////////////////////////////////////////////////////////////////////////////
    else if (parseInt(this.props.systemConfiguration) === 2) {
      pvPower = systemRealTimeData.map((x) =>
        x.data.deviceLedger.acuvim.acuvimPower1.toFixed(0)
      );
      if (circuitNameMeter1 === "0") {
        gridPower = systemRealTimeData.map((x) =>
          x.data.deviceLedger.acuvim.acuvimPower0.toFixed(0)
        );
        bldgPower = systemRealTimeData.map((x) =>
          (
            x.data.deviceLedger.acuvim.acuvimPower0 +
            x.data.deviceLedger.acuvim.acuvimPower1
          ).toFixed(0)
        );
      } else if (circuitNameMeter1 === "1") {
        bldgPower = systemRealTimeData.map((x) =>
          x.data.deviceLedger.acuvim.acuvimPower0.toFixed(0)
        );
        gridPower = systemRealTimeData.map((x) =>
          (
            x.data.deviceLedger.acuvim.acuvimPower0 -
            x.data.deviceLedger.acuvim.acuvimPower1
          ).toFixed(0)
        );
      } else {
        gridPower = 0;
        bldgPower = 0;
      }
      batteryPower = systemRealTimeData.map((x) => 0);
    }
    /////////////////////////////////////////////////////////////////////////////////
    else if (parseInt(this.props.systemConfiguration) === 3) {
      pvPower = systemRealTimeData.map((x) =>
        x.data.deviceLedger.simplMeter_ii.sm2Ptotal2.toFixed(0)
      );
      if (circuitNameMeter1 === "0") {
        gridPower = systemRealTimeData.map((x) =>
          x.data.deviceLedger.simplMeter_ii.sm2Ptotal1.toFixed(0)
        );
        bldgPower = systemRealTimeData.map((x) =>
          (
            x.data.deviceLedger.simplMeter_ii.sm2Ptotal1 +
            x.data.deviceLedger.simplMeter_ii.sm2Ptotal2
          ).toFixed(0)
        );
      } else if (circuitNameMeter1 === "1") {
        bldgPower = systemRealTimeData.map((x) =>
          x.data.deviceLedger.simplMeter_ii.sm2Ptotal1.toFixed(0)
        );
        gridPower = systemRealTimeData.map((x) =>
          (
            x.data.deviceLedger.simplMeter_ii.sm2Ptotal1 -
            x.data.deviceLedger.simplMeter_ii.sm2Ptotal2
          ).toFixed(0)
        );
      } else {
        gridPower = 0;
        bldgPower = 0;
      }
      batteryPower = systemRealTimeData.map((x) => 0);
    } else if (
      parseInt(this.props.systemConfiguration) === 6 ||
      parseInt(this.props.systemConfiguration) === 8
    ) {
      pvPower = systemRealTimeData.map((x) =>
        x.data.deviceLedger.realTime.AggPower2.toFixed(0)
      );
      if (circuitNameMeter1 === "0") {
        gridPower = systemRealTimeData.map((x) =>
          x.data.deviceLedger.realTime.AggPower1.toFixed(0)
        );
        bldgPower = systemRealTimeData.map((x) =>
          (
            x.data.deviceLedger.realTime.AggPower1 +
            x.data.deviceLedger.realTime.AggPower2
          ).toFixed(0)
        );
      } else if (circuitNameMeter1 === "1") {
        bldgPower = systemRealTimeData.map((x) =>
          x.data.deviceLedger.realTime.AggPower1.toFixed(0)
        );
        gridPower = systemRealTimeData.map((x) =>
          (
            x.data.deviceLedger.realTime.AggPower1 -
            x.data.deviceLedger.realTime.AggPower2
          ).toFixed(0)
        );
      } else {
        gridPower = 0;
        bldgPower = 0;
      }
      batteryPower = systemRealTimeData.map((x) => 0);
    }
    ////////////////////////////////////////////////////////////////////////////////
    const timeZone = systemRealTimeData[0].data.deviceLedger.realTime.TimeZone;

    let date = new Date();
    let now_UTC = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
    let now_LOCAL = now_UTC + timeZone * 3600 * 1000;
    let now = new Date(now_LOCAL);

    date = systemRealTimeData.map((x) =>
      [
        new Date(
          x.data.deviceLedger.realTime.SystemLocalTimeSeconds * 1000
        ).getUTCHours(),
        new Date(
          x.data.deviceLedger.realTime.SystemLocalTimeSeconds * 1000
        ).getUTCMinutes(),
        new Date(
          x.data.deviceLedger.realTime.SystemLocalTimeSeconds * 1000
        ).getUTCSeconds(),
      ].join(":")
    );

    this.setState({
      pvPower,
      batteryPower,
      gridPower,
      bldgPower,
      date,
      timeZone,
      now,
    });
    this.chart = echarts.init(document.getElementById("graph-area"));
    //console.log("Tick Number", tickNumber);
    this.timerID = setInterval(() => this.tick(), tickNumber);
    this.generateGraph();
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.addData(false);
    this.chart.setOption({
      xAxis: {
        data: this.state.date,
      },
      series: [
        {
          name: "PV (W)",
          data: this.state.pvPower,
        },
        {
          name: "Battery (W)",
          data: this.state.batteryPower,
        },
        {
          name: "Grid (W)",
          data: this.state.gridPower,
        },
        {
          name: "Building (W)",
          data: this.state.bldgPower,
        },
      ],
    });
  }

  addData(shift) {
    const timeZone = this.state.timeZone;
    this.state.now = [
      this.state.now.getUTCHours(),
      this.state.now.getUTCMinutes(),
      this.state.now.getUTCSeconds(),
    ].join(":");
    console.log("Bldg Power State: ", this.state.bldgPower);
    console.log("Bldg Power Props: ", this.props.bldgPower);

    if (this.props.playPause) this.state.date.push(this.state.now);
    const stringLength = parseInt(this.state.pvPower.length);
    const pointsOnChart = parseInt(localStorage.getItem("pointsOnChart"));

    if (this.props.pvPower) {
      this.state.pvPower.push(this.props.pvPower);
      this.state.batteryPower.push(this.props.batteryPower);
      this.state.gridPower.push(this.props.gridPower);
      this.state.bldgPower.push(this.props.bldgPower);
    }

    const delta = stringLength - pointsOnChart;

    if (delta === 1) {
      this.state.date.shift();
      this.state.pvPower.shift();
      this.state.batteryPower.shift();
      this.state.gridPower.shift();
      this.state.bldgPower.shift();
    } else if (delta >= 1) {
      for (let i = 0; i < delta; i++) {
        this.state.date.shift();
        this.state.pvPower.shift();
        this.state.batteryPower.shift();
        this.state.gridPower.shift();
        this.state.bldgPower.shift();
      }
    }

    let date = new Date();
    let now_UTC = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
    let now_LOCAL = now_UTC + timeZone * 3600 * 1000;
    this.state.now = new Date(now_LOCAL);
  }

  generateGraph() {
    var option = {
      legend: {
        type: "scroll",
        data: ["PV (W)", "Battery (W)", "Grid (W)", "Building (W)"],
        center: "50%",
        top: "0%",
        orient: "horizontal",
        align: "auto",
        padding: 3,
        textStyle: {
          fontStyle:
            "normal" /*'Lato,"Helvetica Neue",Arial,Helvetica,sans-serif'*/,
          fontSize: 10,
          fontWeight: "bold",
          color: "grey",
        },
        backgroundColor: "transparent",
        borderColor: "#ffffff",
        borderWidth: 1,
        boarderRadius: 0,
      },
      xAxis: {
        name: "",
        nameLocation: "end",
        type: "category",
        nameTextStyle: {
          fontStyle:
            "normal" /*'Lato,"Helvetica Neue",Arial,Helvetica,sans-serif'*/,
          fontSize: 16,
          fontWeight: "bolder",
          color: "green",
        },
        axisLabel: {
          fontStyle: "normal",
          fontSize: 8,
          fontWeight: "normal",
          color: "orange",
        },
        boundaryGap: false,
        data: this.state.date,
      },
      yAxis: {
        show: false,
        name: "Power (Watts)",
        nameLocation: "end",
        boundaryGap: [0, "10%"],
        type: "value",
        nameTextStyle: {
          fontStyle:
            "normal" /*'Lato,"Helvetica Neue",Arial,Helvetica,sans-serif'*/,
          fontSize: 16,
          fontWeight: "bolder",
          color: "green",
        },
        axisLabel: {
          fontStyle: "normal",
          fontSize: 10,
          fontWeight: "bold",
          color: "green",
        },
      },
      dataZoom: [
        {
          type: "slider",
          show: true,
          xAxisIndex: [0],
          start: 1,
          end: 100,
        },
        {
          type: "inside",
          xAxisIndex: [0],
          start: 1,
          end: 100,
        },
      ],
      series: [
        {
          name: "PV Power",
          type: "line",
          symbol: "none",
          color: "orange",
          smooth: 0.25,
          areaStyle: {
            normal: {
              color: "orange",
            },
          },
          data: this.state.pvPower,
        },
        {
          name: "Battery Power",
          type: "line",
          symbol: "none",
          color: "DodgerBlue",
          smooth: 0.25,
          areaStyle: {
            normal: { color: "DodgerBlue" },
          },
          data: this.state.batteryPower,
        },
        {
          name: "Grid Power",
          type: "line",
          symbol: "none",
          color: "magenta",
          smooth: 0.25,
          data: this.state.gridPower,
        },
        {
          name: "Building Power",
          type: "line",
          symbol: "none",
          color: "green",
          smooth: 0.25,
          data: this.state.bldgPower,
          /*label: {
            show: true,
            value: "kW"
          }*/
        },
      ],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          animation: false,
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
            precision: "2",
          },
        },
      },
    };

    this.chart.setOption(option);
  }

  render() {
    return <div id="graph-area" />;
  }
}
export default AggregateGraph;
