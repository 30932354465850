import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import {
  getUser,
  updateName,
  updateEmail,
  updatePhone
} from "../services/userService";
import { changeUserEmailAlarms } from "../services/systemService";
import { setJwt } from "../services/httpService";
import BackGroundRectangle from "../components/backgroundRect";

class EditUserForm extends Form {
  state = {
    data: {
      firstName: "",
      lastName: "",
      emailAddress: "",
      phoneNumber: ""
    },

    errors: {}
  };

  schema = {
    firstName: Joi.string().label("First Name"),
    lastName: Joi.string().label("Last Name"),
    emailAddress: Joi.string().label("email Address"),
    phoneNumber: Joi.string().label("Phone Number")
  };

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    console.log("Web Token", jwt);
    setJwt(jwt);
    const userdata = await getUser();
    this.setState({ data: userdata.data.data });
  }

  formatPhoneNumber(phoneNumber){
    let formattedPhoneNumber = phoneNumber
    if (phoneNumber.slice(0, 2) === "+1" ){
      formattedPhoneNumber = phoneNumber.slice(2)
    }

    return ( '+1' + formattedPhoneNumber.replace(/[^0-9]/g, ''))
  }

  doSubmit = async () => {
    try {
      const currentUser = (await getUser()).data.data;
      const { data } = this.state;
      const jwt = localStorage.getItem("token");
      setJwt(jwt);

      await updateName(data.firstName, data.lastName);
      await updateEmail(data.emailAddress);
      await updatePhone(data.phoneNumber);

      const userPhoneNumber = this.formatPhoneNumber(data.phoneNumber)

      const userAlarms = await changeUserEmailAlarms(
        currentUser.emailAddress,  data.emailAddress, userPhoneNumber, data.companyName
      )

      console.log("User Alarms:");
      console.log(userAlarms);
      // window.location = "/systems";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors: errors });
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1
          }}
        >
          <p className="basicParagraph">Edit User Contact Information:</p>
          <form onSubmit={this.handleSubmit}>
            {this.renderInput("firstName", "First Name")}
            {this.renderInput("lastName", "Last Name")}
            {this.renderInput("emailAddress", "email Address")}
            {this.renderInput("phoneNumber", "Phone Number")}
            {this.renderButton("Save Contact Info")}
          </form>
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default EditUserForm;
