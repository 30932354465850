import React, { Component } from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import GaugeGraph from "./gauge-graph";
import IntervalGraph from "./interval-graph";
import {
  getSystemLatestData,
  ws_getSystemLatestData,
  getSystemBasics,
} from "../services/systemService";
import "./dashboard.css";
import "../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "./common/form";
import ProgressBar from "react-bootstrap/ProgressBar";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import { relative } from "path";
import { webSocketMode } from "../config.json"; //0: http; 1:websocket

class SystemGauges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      startDate: new Date(),
      stopDate: new Date(),
      companyName: "",
      chartPoints: "5",
      key: null,
      systemID: "",
      handleID: 0,
      pvPowerUnits: "Watts",
      batteryPowerUnits: "Watts",
      play: true,
      response: {
        deviceLedger: {
          realTime: {
            timestamp: 0,
            pvPower: 0,
            batteryPower: 0,
            inverterPower: 0,
            pvCurrent: 0,
            batteryCurrent: 0,
            inverterCurrent: 0,
            pvVoltage: 0,
            batteryVoltage: 0,
            inverterVoltage: 0,
          },
        },
      },
    };
    this.getSystemData = this.getSystemData.bind(this);

    this.handlePause = () => {
      this.setState({ play: false });
    };

    this.handlePlay = () => {
      this.setState({ play: true });
    };
  }

  async componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
    console.log("Props in DashboardChart.js", this.props);
    const handleID = this.props.match.params.systemHandleID;
    const companyName = this.props.match.params.companyName;
    const chartPoints = localStorage.getItem("pointsOnChart");
    console.log("Points", chartPoints);
    localStorage.setItem("pointsOnChart", chartPoints);
    this.setState({ chartPoints });
    console.log("Points on Charts from Dashboard", this.state.chartPoints);
    this.setState({ handleID, companyName });
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  async getSystemData() {
    let response = {};

    if (webSocketMode) {
      response = ws_getSystemLatestData(this.state.handleID);
      console.log("Websocket: Latest Data", response);
    } else {
      response = (await getSystemLatestData(this.state.handleID)).data.data[0];
      console.log("Http: Latest Data", response);
    }

    const systemTime = parseInt(
      response.data.deviceLedger.realTime.SystemLocalTimeSeconds
    );
    console.log("System Time:", systemTime);
    const systemTimeZone = parseInt(
      response.data.deviceLedger.realTime.TimeZone
    );
    console.log("System Time Zone:", systemTimeZone);
    let sysT = new Date((systemTime - systemTimeZone * 3600) * 1000);
    const systemTimeOffset = systemTimeZone * 60 * 60 * 1000;
    const userTimeOffset = sysT.getTimezoneOffset() * 60 * 1000;
    sysT = new Date(sysT.getTime() + userTimeOffset + systemTimeOffset);
    let hourTime = sysT.getHours();
    if (hourTime < 10) hourTime = "0" + hourTime;
    let minuteTime = sysT.getMinutes();
    if (minuteTime < 10) minuteTime = "0" + minuteTime;
    let secTime = sysT.getSeconds();
    if (secTime < 10) secTime = "0" + secTime;
    const systemHumanTime = [hourTime, minuteTime, secTime].join(":");
    console.log("System Human Time:", systemHumanTime);
    this.setState({ systemHumanTime });

    const realTimeGet = response.data.deviceLedger.realTime;

    const DataFrequency = (await getSystemBasics(this.state.handleID)).data.data
      .basics.dataFrequency;

    this.setState({ DataFrequency });
    this.setState({ realTime: realTimeGet });
    let pvPower = this.state.realTime.pvPower / 100;
    let batteryPower = this.state.realTime.batteryPower / 100;
    let pvPowerUnits = this.state.pvPowerUnits;
    let batteryPowerUnits = this.state.batteryPowerUnits;

    if (pvPower < 100) {
      pvPower = pvPower.toFixed(2);
      pvPowerUnits = "Watts";
    } else if (pvPower < 1000) {
      pvPower = pvPower.toFixed(1);
      pvPowerUnits = "Watts";
    } else if (pvPower < 10000) {
      pvPower = (pvPower / 1000).toFixed(3);
      pvPowerUnits = "kW";
    } else if (pvPower < 100000) {
      pvPower = (pvPower / 1000).toFixed(2);
      pvPowerUnits = "kW";
    } else if (pvPower < 1000000) {
      pvPower = (pvPower / 1000).toFixed(1);
      pvPowerUnits = "kW";
    } else if (pvPower < 10000000) {
      pvPower = (pvPower / 1000000).toFixed(3);
      pvPowerUnits = "MW";
    } else if (pvPower < 100000000) {
      pvPower = (pvPower / 1000000).toFixed(2);
      pvPowerUnits = "MW";
    } else if (pvPower < 1000000000) {
      pvPower = (pvPower / 1000000).toFixed(1);
      pvPowerUnits = "MW";
    } else if (pvPower < 10000000000) {
      pvPower = (pvPower / 1000000000).toFixed(3);
      pvPowerUnits = "GW";
    }

    if (batteryPower < 100) {
      batteryPower = batteryPower.toFixed(2);
      batteryPowerUnits = "Watts";
    } else if (batteryPower < 1000) {
      batteryPower = batteryPower.toFixed(1);
      batteryPowerUnits = "Watts";
    } else if (batteryPower < 10000) {
      batteryPower = (batteryPower / 1000).toFixed(3);
      batteryPowerUnits = "kW";
    } else if (batteryPower < 100000) {
      batteryPower = (batteryPower / 1000).toFixed(2);
      batteryPowerUnits = "kW";
    } else if (batteryPower < 1000000) {
      batteryPower = (batteryPower / 1000).toFixed(1);
      batteryPowerUnits = "kW";
    } else if (batteryPower < 10000000) {
      batteryPower = (batteryPower / 1000000).toFixed(3);
      batteryPowerUnits = "MW";
    } else if (batteryPower < 100000000) {
      batteryPower = (batteryPower / 1000000).toFixed(2);
      batteryPowerUnits = "MW";
    } else if (batteryPower < 1000000000) {
      batteryPower = (batteryPower / 1000000).toFixed(1);
      batteryPowerUnits = "MW";
    } else if (batteryPower < 10000000000) {
      batteryPower = (batteryPower / 1000000000).toFixed(3);
      batteryPowerUnits = "GW";
    }
    this.setState({ pvPower, pvPowerUnits, batteryPower, batteryPowerUnits });

    this.setState({
      soc: (
        (this.state.realTime.batteryPower / this.state.realTime.pvPower) *
        100
      ).toFixed(1),
    });
    this.setState({ loopCount: this.state.realTime.loopCount });
    this.setState({ slaveCount: this.state.realTime.slaveCount });
  }

  tick() {
    this.setState({
      date: new Date(),
    });
    this.getSystemData();
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "100%",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
          }}
        >
          {this.state.DataFrequency && (
            <div>
              <p className="systemClock">
                System Local Time: {this.state.systemHumanTime}
              </p>
              <table className="table3">
                <td
                  style={{
                    float: "right",
                    marginRight: "10px",
                    width: "10px",
                  }}
                >
                  <Link
                    to={`/systems/dashboard/${this.state.handleID}/${this.state.companyName}`}
                  >
                    <FontAwesomeIcon
                      icon="arrow-alt-circle-left"
                      size="sm"
                      color="magenta"
                      title="Simpl dashboard"
                    />
                  </Link>
                </td>
                <td
                  style={{
                    width: "10px",
                  }}
                >
                  <SystemInfoModal
                    loopCount={this.state.loopCount}
                    slaveCount={this.state.slaveCount}
                    companyName={this.state.companyName}
                  />
                </td>
              </table>

              <GaugeGraph handleID={this.state.handleID} />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default SystemGauges;

class SystemInfoModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <>
        <button
          className="btn btn-outline-light"
          onClick={this.handleShow}
          style={{ paddingBottom: "25px" }}
        >
          <FontAwesomeIcon
            icon="info-circle"
            size="sm"
            color="dodgerblue"
            title="System Information"
          />
        </button>

        <Modal
          style={{ width: "200px", alignContent: "center" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "14px" }}
            >
              System at a glance:
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Loop count since restart:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.loopCount}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Number of Mesh Nodes:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {" "}
              {this.props.slaveCount}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Company Name:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {" "}
              {this.props.companyName}
            </p>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
