import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getUser } from "../services/userService";
import { setJwt } from "../services/httpService";
import {
  getSystemLatestData,
  ws_getSystemLatestData,
  getSystem,
  getSystemSignals,
  setSystemSignals,
  getSystemIDfromHandle,
  initSystemUpdate,
  getSystemInfo,
  getSystemBasics,
  findIdByMac,
} from "../services/systemService";

import "./dashboard.css";
import "../index.css";
import {
  DEACTIVATE_SLAVE,
  REACTIVATE_SLAVE,
  LOCATOR_ON,
  LOCATOR_OFF,
  SLAVE_RESET,
  SLAVE_FACTORY_RESET,
  SLAVE_HIBERNATE,
} from "../config.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import BackGroundRectangle from "../components/backgroundRect";
import { webSocketMode } from "../config.json"; //0: http; 1:websocket

class SlaveTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slaves: [],
      acuvimNumbers: {},
      realTime: {},
      companyName: "",
      systemID: "",
      handleID: 0,
      activeState: true,
      locatorEnabled: false,
      systemStatus: "",
      dataVerbosity: 1,
      systemConfiguration: "0",
    };
    //this.getSystemData = this.getSystemData.bind(this);
  }

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    const user = await getUser();
    console.log("This User", user);
    const isUserAdmin = user.data.data.admin;
    console.log("Is User Admin", isUserAdmin);

    const handleID = this.props.match.params.systemHandleID;
    const systemID = (await getSystemIDfromHandle(handleID)).data.data
      .system_id;
    const system = (await getSystem(systemID)).data.data[0];
    const systemName = system.systemID.name;
    const systemSignals = (await getSystemSignals(handleID)).data.data.signals;
    if (systemSignals) this.setState({ data: systemSignals });
    const systemInfo = await getSystemInfo(handleID);
    const systemConfiguration = systemInfo.data.data.basics.systemConfiguration;
    console.log("System Configuration", systemConfiguration);
    const companyName = this.props.match.params.companyName;
    this.setState({
      handleID,
      systemID,
      systemName,
      companyName,
      systemConfiguration,
      isUserAdmin,
    });
    this.getSystemData(); //First got to mount it and then run this.  Must take this out
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  async getSystemData() {
    let response = {};

    if (webSocketMode) {
      response = ws_getSystemLatestData(this.state.handleID);
      console.log("Websocket: Latest Data", response);
    } else {
      response = (await getSystemLatestData(this.state.handleID)).data.data[0];
      console.log("Http: Latest Data", response);
    }
    console.log("System Response Data", response);

    if (
      this.state.systemConfiguration === "0" &&
      response !== null &&
      response !== undefined
    ) {
      const slaves = response.data.deviceLedger.slaveUnits;
      const slaveCount = response.data.deviceLedger.realTime.slaveCount;
      const numOfSlaves = slaves.length;
      let i = 0;
      let slaveSN = [];
      let newSlaves = [];
      for (i = 0; i < numOfSlaves; i++) {
        let slaveMAC = slaves[i].MAC;
        let slaveId = (await findIdByMac(slaveMAC)).data.data.system_id;
        let system = await getSystem(slaveId);
        slaveSN[i] = system.data.data[0].systemID.SN;
        newSlaves[i] = Object.assign(slaves[i], { SN: slaveSN[i] });
      }
      this.setState({ slaves: newSlaves, slaveCount });
    } else if (
      this.state.systemConfiguration === "2" &&
      response !== null &&
      response !== undefined
    ) {
      const acuvimNumbers = response.data.data[0].data.deviceLedger.acuvim;
      console.log("Acuvim Numbers", acuvimNumbers);

      console.log(" System Configuration", this.state.systemConfiguration);
      this.setState({ acuvimNumbers });
    } else if (
      this.state.systemConfiguration === "3" &&
      response !== null &&
      response !== undefined
    ) {
      const simplMeter_ii_Numbers = response.data.deviceLedger.simplMeter_ii;
      console.log("SimplMeter II Numbers", simplMeter_ii_Numbers);

      console.log(" System Configuration", this.state.systemConfiguration);
      this.setState({ simplMeter_ii_Numbers });
    }
    const systemBasics = await getSystemBasics(this.state.handleID);
    const dataFrequency = systemBasics.data.data.basics.dataFrequency;
    let sleepTime = 0;
    if (dataFrequency === "1") sleepTime = 5000;
    //mSec
    //"1", name: "once / Second"
    else if (dataFrequency === "2") sleepTime = 15000;
    //5seconds + 5 seconds + 5 seconds
    //"2", name: "once / 5 Seconds"
    else sleepTime = 35000; //15 seconds + 15 seconds + 5 seconds
    const systemInfo = await getSystemInfo(this.state.handleID);
    const systemStatus = systemInfo.data.data.status;
    const dataVerbosity = systemInfo.data.data.basics.dataVerbosity;
    console.log("Data Verbosity", dataVerbosity);
    this.setState({ systemStatus, dataVerbosity, sleepTime });
  }

  tick() {
    this.setState({
      dateNow: new Date(),
    });
    this.getSystemData();
  }

  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  handleSlave = async (performFunction, slaveMAC) => {
    await setSystemSignals(
      this.state.systemID,
      performFunction,
      "",
      "",
      "",
      slaveMAC
    );

    if (
      !this.state.isUserAdmin &&
      (performFunction === SLAVE_RESET ||
        performFunction === SLAVE_FACTORY_RESET ||
        performFunction === DEACTIVATE_SLAVE ||
        performFunction === SLAVE_HIBERNATE)
    )
      toast.error("Must be an ADMIN to use this function!");
    else {
      await initSystemUpdate(this.props.match.params.systemHandleID);
      if (
        performFunction === SLAVE_RESET ||
        performFunction === SLAVE_FACTORY_RESET ||
        performFunction === DEACTIVATE_SLAVE ||
        performFunction === SLAVE_HIBERNATE ||
        performFunction === LOCATOR_ON ||
        performFunction === LOCATOR_OFF
      ) {
        const sleepTime = this.state.sleepTime;
        console.log("sleepTime = ", sleepTime);
        this.sleep(sleepTime); //Wait for reset to take effect for the loop running as slow as 15000 mseconds
        await setSystemSignals(
          this.state.systemID,
          "0",
          "",
          "",
          "",
          "FF:FF:FF:FF:FF:FF" /*slaveMAC*/
        );
        await initSystemUpdate(this.props.match.params.systemHandleID);
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "350px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            paddingBottom: "100px",
            zIndex: 1,
          }}
        >
          {this.state.dataVerbosity === "3" && (
            <div>
              {" "}
              {this.state.systemConfiguration === "2" && (
                <div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Grid Power Meter</th>
                        <th>Voltage (LL)</th>
                        <th>Current (A)</th>
                        <th>Power (W)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Phase AB</td>
                        <td>
                          {this.state.acuvimNumbers.acuvimVAB0.toFixed(1)}
                        </td>
                        <td>{this.state.acuvimNumbers.acuvimIA0.toFixed(1)}</td>
                        <td>
                          {this.state.acuvimNumbers.acuvimPA0
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                      </tr>
                      <tr>
                        <td>Phase BC</td>
                        <td>
                          {this.state.acuvimNumbers.acuvimVBC0.toFixed(1)}
                        </td>
                        <td>{this.state.acuvimNumbers.acuvimIB0.toFixed(1)}</td>
                        <td>
                          {this.state.acuvimNumbers.acuvimPB0
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                      </tr>
                      <tr>
                        <td>Phase CA</td>
                        <td>
                          {this.state.acuvimNumbers.acuvimVCA0.toFixed(1)}
                        </td>
                        <td>{this.state.acuvimNumbers.acuvimIC0.toFixed(1)}</td>
                        <td>
                          {this.state.acuvimNumbers.acuvimPC0
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                      </tr>
                      <tr>
                        <td>Total Power (W)</td>
                        <td></td>
                        <td></td>
                        <td>
                          {this.state.acuvimNumbers.acuvimPower0
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Solar Power Meter</th>
                        <th>Voltage (LL)</th>
                        <th>Current (A)</th>
                        <th>Power (W)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Phase AB</td>
                        <td>
                          {this.state.acuvimNumbers.acuvimVAB1.toFixed(1)}
                        </td>
                        <td>{this.state.acuvimNumbers.acuvimIA1.toFixed(1)}</td>
                        <td>
                          {this.state.acuvimNumbers.acuvimPA1
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                      </tr>
                      <tr>
                        <td>Phase BC</td>
                        <td>
                          {this.state.acuvimNumbers.acuvimVBC1.toFixed(1)}
                        </td>
                        <td>{this.state.acuvimNumbers.acuvimIB1.toFixed(1)}</td>
                        <td>
                          {this.state.acuvimNumbers.acuvimPB1
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                      </tr>
                      <tr>
                        <td>Phase CA</td>
                        <td>
                          {this.state.acuvimNumbers.acuvimVCA1.toFixed(1)}
                        </td>
                        <td>{this.state.acuvimNumbers.acuvimIC1.toFixed(1)}</td>
                        <td>
                          {this.state.acuvimNumbers.acuvimPC1
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                      </tr>
                      <tr>
                        <td>Total Power(W)</td>
                        <td></td>
                        <td></td>
                        <td>
                          {this.state.acuvimNumbers.acuvimPower1
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              {/*///////////////////////////////////////////////////////////////////////////////////*/}
              {this.state.systemConfiguration === "3" && (
                <div>
                  <table className="table">
                    <tr>
                      <td>Temp.:</td>
                      <td>
                        {this.state.simplMeter_ii_Numbers.sm2temp1} &#8451;
                      </td>
                    </tr>
                    <thead>
                      <tr>
                        <th>Meter 1</th>
                        <th>Voltage (LN)</th>
                        <th>Current (A)</th>
                        <th>Power (W)</th>
                        <th>PF</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Phase AB</td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2VAN1.toFixed(1)}
                        </td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2IA1.toFixed(1)}
                        </td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2PA1
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2PFa1.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td>Phase BC</td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2VBN1.toFixed(1)}
                        </td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2IB1.toFixed(1)}
                        </td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2PB1
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2PFb1.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td>Phase CA</td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2VCN1.toFixed(1)}
                        </td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2IC1.toFixed(1)}
                        </td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2PC1
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2PFc1.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td>Total Power (W)</td>
                        <td></td>
                        <td></td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2Ptotal1
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="table">
                    <tr>
                      <td>Temp.:</td>
                      <td>
                        {this.state.simplMeter_ii_Numbers.sm2temp2} &#8451;
                      </td>
                    </tr>
                    <thead>
                      <tr>
                        <th>Meter 2</th>
                        <th>Voltage (LN)</th>
                        <th>Current (A)</th>
                        <th>Power (W)</th>
                        <th>PF</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Phase AB</td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2VAN2.toFixed(1)}
                        </td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2IA2.toFixed(1)}
                        </td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2PA2
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2PFa2.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td>Phase BC</td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2VBN2.toFixed(1)}
                        </td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2IB2.toFixed(1)}
                        </td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2PB2
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2PFb2.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td>Phase CA</td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2VCN2.toFixed(1)}
                        </td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2IC2.toFixed(1)}
                        </td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2PC2
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2PFc2.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td>Total Power(W)</td>
                        <td></td>
                        <td></td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2Ptotal2
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              {/*///////////////////////////////////////////////////////////////////////////////////*/}
              {this.state.systemConfiguration === "0" &&
                this.state.slaveCount == 0 && (
                  <div>
                    <p className="basicParagraph">
                      {" "}
                      No Active subsystems are detected in the system...
                    </p>
                    <p className="basicParagraph">
                      {" "}
                      Please stand by as connections are being established.
                    </p>
                  </div>
                )}
              {this.state.systemConfiguration === "0" &&
                this.state.slaveCount != 0 && (
                  <div>
                    <table className="table" style={{ width: "350px" }}>
                      <tbody>
                        {this.state.slaves.map((slave) => (
                          <div>
                            <tr
                              style={{
                                backgroundColor: "#ece9e9",
                                marginTop: "50px",
                              }}
                            >
                              <th style={{ color: "magenta" }}>Status</th>
                              <th style={{ color: "magenta" }}>Unit</th>
                              <th style={{ color: "magenta" }}>Serial #</th>
                              <th>
                                <FontAwesomeIcon
                                  style={{
                                    marginLeft: "0px",
                                    marginRight: "0px",
                                  }}
                                  icon="wifi"
                                  size="lg"
                                  color="green"
                                  title="Signal Strength"
                                />
                                {slave.deviceRSSI}
                              </th>
                            </tr>
                            <tr key={slave.id}>
                              {slave.deviceAlarm === 0 && (
                                <td>
                                  <SlaveAlarmModal
                                    unitNumber={
                                      this.state.slaves.indexOf(slave) + 1
                                    }
                                    tempData={slave.tempData}
                                    voltageData={slave.voltageData}
                                    dataVerbosity={this.state.dataVerbosity}
                                  />
                                </td>
                              )}
                              {slave.deviceAlarm === 1 && (
                                <td>
                                  <FontAwesomeIcon
                                    icon="check"
                                    size="sm"
                                    color="green"
                                    title="OK"
                                  />
                                </td>
                              )}
                              <td>{this.state.slaves.indexOf(slave) + 1}</td>
                              <td>{slave.SN}</td>

                              <td
                                style={{ float: "right", marginRight: "10px" }}
                              >
                                <SlaveInfoModal
                                  unitNumber={
                                    this.state.slaves.indexOf(slave) + 1
                                  }
                                  macAddress={slave.MAC}
                                  fwVersionSlave={slave.fwVersionSlave}
                                  slaveState={slave.slaveState}
                                  battMode={slave.battMode}
                                  pvVolts={slave.pvVoltage}
                                  invConnected={slave.inverter_connected}
                                  loopCount={slave.slave_loopCount}
                                  batteryCells={slave.batteryCells}
                                  tempData={slave.tempData}
                                  voltageData={slave.voltageData}
                                />
                              </td>
                            </tr>

                            <tr>
                              <th>SOC</th>
                              <th>cRatio</th>
                              <th>packV</th>
                              <th>CellDV</th>
                            </tr>
                            <tr>
                              <td>{slave.SOC.toFixed(1)}</td>
                              <td>{slave.cRatio.toFixed(2)}</td>
                              <td>{(slave.packV / 100).toFixed(2)}</td>
                              <td>{(slave.cellDeltaV / 10000).toFixed(4)}</td>
                            </tr>

                            <tr>
                              <th>Solar Power (W)</th>
                              <th>Solar Interval (kWh)</th>
                              <th>Battery Power (W)</th>
                              <th>Battery Interval (kWh)</th>
                            </tr>
                            <tr>
                              <td>{(slave.pvPower / 100).toFixed(2)}</td>
                              <td>
                                {(
                                  slave.pvInterval /
                                  (100 * 1000 * 900)
                                ).toFixed(3)}
                              </td>
                              <td>{(slave.battPower / 100).toFixed(2)}</td>
                              <td>
                                {(
                                  slave.battInterval /
                                  (100 * 1000 * 900)
                                ).toFixed(3)}
                              </td>
                            </tr>

                            <table>
                              <tr>
                                <table
                                  className="table"
                                  style={{ width: "350px", marginLeft: "20px" }}
                                >
                                  <tbody>
                                    <div>
                                      <tr key={slave.id}></tr>

                                      <tr>
                                        <th>Reset</th>
                                        <th>Factory</th>
                                        <th>Turn Off</th>
                                        <th>Hibernate</th>
                                        <th>Locator</th>
                                      </tr>
                                      <tr>
                                        {this.state.systemStatus ===
                                          "UPDATING" && <td>Updating...</td>}
                                        {this.state.systemStatus ===
                                          "ALIVE" && (
                                          <td>
                                            <button
                                              className="btn btn-outline-light"
                                              style={{
                                                padding: "0px",
                                                borderWidth: "0px",
                                                marginRight: "2px",
                                                marginTop: "-7px",
                                                padding: "0px",
                                                position: "relative",
                                                right: "0px",
                                                boxShadow:
                                                  "5px 5 px 5px 5px #888888",
                                              }}
                                              onClick={() =>
                                                this.handleSlave(
                                                  SLAVE_RESET,
                                                  `${slave.MAC}`
                                                )
                                              }
                                            >
                                              <FontAwesomeIcon
                                                icon="retweet"
                                                size="sm"
                                                color="orange"
                                                title="Reset Subsystems"
                                              />
                                            </button>
                                          </td>
                                        )}
                                        {this.state.systemStatus ===
                                          "ALIVE" && (
                                          <td>
                                            <button
                                              className="btn btn-outline-light"
                                              style={{
                                                padding: "0px",
                                                borderWidth: "0px",
                                                marginRight: "2px",
                                                marginTop: "-7px",
                                                padding: "0px",
                                                position: "relative",
                                                right: "0px",
                                                boxShadow:
                                                  "5px 5 px 5px 5px #888888",
                                              }}
                                              onClick={() =>
                                                this.handleSlave(
                                                  SLAVE_FACTORY_RESET,
                                                  `${slave.MAC}`
                                                )
                                              }
                                            >
                                              <FontAwesomeIcon
                                                icon="history"
                                                size="sm"
                                                color="magenta"
                                                title="Factory Reset Subsystems"
                                              />
                                            </button>
                                          </td>
                                        )}
                                        {this.state.systemStatus ===
                                          "ALIVE" && (
                                          <td style={{ color: "white" }}>
                                            <button
                                              className="btn btn-outline-light"
                                              style={{
                                                padding: "0px",
                                                borderWidth: "0px",
                                                marginRight: "2px",
                                                marginTop: "-7px",
                                                padding: "0px",
                                                position: "relative",
                                                right: "0px",
                                                boxShadow:
                                                  "5px 5 px 5px 5px #888888",
                                              }}
                                              onClick={() =>
                                                this.handleSlave(
                                                  DEACTIVATE_SLAVE,
                                                  `${slave.MAC}`
                                                )
                                              }
                                            >
                                              <FontAwesomeIcon
                                                icon="power-off"
                                                size="sm"
                                                color="green"
                                                title="PV Must Be Shorted"
                                              />
                                            </button>
                                          </td>
                                        )}
                                        {this.state.systemStatus ===
                                          "ALIVE" && (
                                          <td>
                                            <button
                                              className="btn btn-outline-light"
                                              style={{
                                                padding: "0px",
                                                borderWidth: "0px",
                                                marginRight: "2px",
                                                marginTop: "-7px",
                                                padding: "0px",
                                                position: "relative",
                                                right: "0px",
                                                boxShadow:
                                                  "5px 5 px 5px 5px #888888",
                                              }}
                                              onClick={() =>
                                                this.handleSlave(
                                                  SLAVE_HIBERNATE,
                                                  `${slave.MAC}`
                                                )
                                              }
                                            >
                                              <FontAwesomeIcon
                                                icon="stop"
                                                size="sm"
                                                color="tomato"
                                                title="Disable Batteries"
                                              />
                                            </button>
                                          </td>
                                        )}
                                        {this.state.systemStatus ===
                                          "ALIVE" && (
                                          <td style={{ color: "white" }}>
                                            {slave.BTStatus && (
                                              <button
                                                className="btn btn-outline-light"
                                                style={{
                                                  padding: "0px",
                                                  borderWidth: "0px",
                                                  marginRight: "2px",
                                                  marginTop: "-7px",
                                                  padding: "0px",
                                                  position: "relative",
                                                  right: "0px",
                                                }}
                                                onClick={() =>
                                                  this.handleSlave(
                                                    LOCATOR_OFF,
                                                    `${slave.MAC}`
                                                  )
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  icon="crosshairs"
                                                  size="sm"
                                                  color="red"
                                                  title="Locate installed subsystem"
                                                />
                                              </button>
                                            )}

                                            {!slave.BTStatus && (
                                              <button
                                                className="btn btn-outline-light"
                                                style={{
                                                  padding: "0px",
                                                  borderWidth: "0px",
                                                  marginRight: "2px",
                                                  marginTop: "-7px",
                                                  padding: "0px",
                                                  position: "relative",
                                                  right: "0px",
                                                }}
                                                onClick={() =>
                                                  this.handleSlave(
                                                    LOCATOR_ON,
                                                    `${slave.MAC}`
                                                  )
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  icon="crosshairs"
                                                  size="sm"
                                                  color="grey"
                                                  title="Locate installed subsystem"
                                                />
                                              </button>
                                            )}
                                          </td>
                                        )}
                                      </tr>
                                    </div>
                                  </tbody>
                                </table>
                              </tr>
                            </table>
                          </div>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              <hr></hr>
            </div>
          )}
          {this.state.dataVerbosity === "2" &&
            this.state.slaveCount != 0 &&
            this.state.systemConfiguration === "0" && (
              <table className="table" style={{ width: "350px" }}>
                <tbody>
                  {this.state.slaves.map((slave) => (
                    <div>
                      <tr style={{ backgroundColor: "#ece9e9" }}>
                        <th style={{ color: "magenta" }}>Status</th>
                        <th style={{ color: "magenta" }}>Unit</th>
                        <th style={{ color: "magenta" }}>Serial #</th>
                        <th>
                          {" "}
                          <FontAwesomeIcon
                            style={{ marginLeft: "0px", marginRight: "0px" }}
                            icon="wifi"
                            size="lg"
                            color="green"
                            title="Signal Strength"
                          />
                          {slave.deviceRSSI}
                        </th>
                      </tr>
                      <tr key={slave.id}>
                        {slave.deviceAlarm === 0 && (
                          <td>
                            <FontAwesomeIcon
                              icon="exclamation-triangle"
                              size="lg"
                              color="red"
                              title="Subsystem Alarms"
                            />
                          </td>
                        )}
                        {slave.deviceAlarm === 1 && (
                          <td>
                            <FontAwesomeIcon
                              icon="check"
                              size="sm"
                              color="green"
                              title="OK"
                            />
                          </td>
                        )}
                        <td>{this.state.slaves.indexOf(slave) + 1}</td>
                        <td>{slave.SN}</td>

                        <td style={{ float: "right", marginRight: "10px" }}>
                          <SlaveInfoModalMedium
                            unitNumber={this.state.slaves.indexOf(slave) + 1}
                            slaveState={slave.slaveState}
                            battMode={slave.battMode}
                          />
                        </td>
                      </tr>

                      <tr>
                        <th>SOC</th>
                        <th>cRatio</th>
                        <th>packV</th>
                        <th>CellDV</th>
                      </tr>
                      <tr>
                        <td>{slave.SOC.toFixed(1)}</td>
                        <td>{slave.cRatio.toFixed(2)}</td>
                        <td>{(slave.packV / 100).toFixed(2)}</td>
                        <td>{(slave.cellDeltaV / 10000).toFixed(4)}</td>
                      </tr>

                      <tr>
                        <th>Solar Power (W)</th>
                        <th>Solar Interval (kWh)</th>
                        <th>Battery Power (W)</th>
                        <th>Battery Interval (kWh)</th>
                      </tr>
                      <tr>
                        <td>{(slave.pvPower / 100).toFixed(2)}</td>
                        <td>
                          {(slave.pvInterval / (100 * 1000 * 900)).toFixed(3)}
                        </td>
                        <td>{(slave.battPower / 100).toFixed(2)}</td>
                        <td>
                          {(slave.battInterval / (100 * 1000 * 900)).toFixed(3)}
                        </td>
                      </tr>

                      <table>
                        <tr>
                          <table
                            className="table"
                            style={{ width: "350px", marginLeft: "20px" }}
                          >
                            <tbody>
                              <div>
                                <tr key={slave.id}></tr>

                                <tr>
                                  <th>Reset</th>
                                  <th>Factory</th>
                                  <th>Turn Off</th>
                                  <th>Hibernate</th>
                                  <th>Locator</th>
                                </tr>
                                <tr>
                                  {this.state.systemStatus === "UPDATING" && (
                                    <td>Updating...</td>
                                  )}
                                  {this.state.systemStatus === "ALIVE" && (
                                    <td>
                                      <button
                                        className="btn btn-outline-light"
                                        style={{
                                          padding: "0px",
                                          borderWidth: "0px",
                                          marginRight: "2px",
                                          marginTop: "-7px",
                                          padding: "0px",
                                          position: "relative",
                                          right: "0px",
                                          boxShadow: "5px 5 px 5px 5px #888888",
                                        }}
                                        onClick={() =>
                                          this.handleSlave(
                                            SLAVE_RESET,
                                            `${slave.MAC}`
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon="retweet"
                                          size="sm"
                                          color="orange"
                                          title="Reset Subsystems"
                                        />
                                      </button>
                                    </td>
                                  )}
                                  {this.state.systemStatus === "ALIVE" && (
                                    <td>
                                      <button
                                        className="btn btn-outline-light"
                                        style={{
                                          padding: "0px",
                                          borderWidth: "0px",
                                          marginRight: "2px",
                                          marginTop: "-7px",
                                          padding: "0px",
                                          position: "relative",
                                          right: "0px",
                                          boxShadow: "5px 5 px 5px 5px #888888",
                                        }}
                                        onClick={() =>
                                          this.handleSlave(
                                            SLAVE_FACTORY_RESET,
                                            `${slave.MAC}`
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon="history"
                                          size="sm"
                                          color="magenta"
                                          title="Factory Reset Subsystems"
                                        />
                                      </button>
                                    </td>
                                  )}
                                  {this.state.systemStatus === "ALIVE" && (
                                    <td style={{ color: "white" }}>
                                      <button
                                        className="btn btn-outline-light"
                                        style={{
                                          padding: "0px",
                                          borderWidth: "0px",
                                          marginRight: "2px",
                                          marginTop: "-7px",
                                          padding: "0px",
                                          position: "relative",
                                          right: "0px",
                                          boxShadow: "5px 5 px 5px 5px #888888",
                                        }}
                                        onClick={() =>
                                          this.handleSlave(
                                            DEACTIVATE_SLAVE,
                                            `${slave.MAC}`
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon="power-off"
                                          size="sm"
                                          color="green"
                                          title="PV Must Be Shorted"
                                        />
                                      </button>
                                    </td>
                                  )}
                                  {this.state.systemStatus === "ALIVE" && (
                                    <td>
                                      <button
                                        className="btn btn-outline-light"
                                        style={{
                                          padding: "0px",
                                          borderWidth: "0px",
                                          marginRight: "2px",
                                          marginTop: "-7px",
                                          padding: "0px",
                                          position: "relative",
                                          right: "0px",
                                          boxShadow: "5px 5 px 5px 5px #888888",
                                        }}
                                        onClick={() =>
                                          this.handleSlave(
                                            SLAVE_HIBERNATE,
                                            `${slave.MAC}`
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon="stop"
                                          size="sm"
                                          color="tomato"
                                          title="Disable Batteries"
                                        />
                                      </button>
                                    </td>
                                  )}
                                  {this.state.systemStatus === "ALIVE" && (
                                    <td style={{ color: "white" }}>
                                      {slave.BTStatus && (
                                        <button
                                          className="btn btn-outline-light"
                                          style={{
                                            padding: "0px",
                                            borderWidth: "0px",
                                            marginRight: "2px",
                                            marginTop: "-7px",
                                            padding: "0px",
                                            position: "relative",
                                            right: "0px",
                                          }}
                                          onClick={() =>
                                            this.handleSlave(
                                              LOCATOR_OFF,
                                              `${slave.MAC}`
                                            )
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon="crosshairs"
                                            size="sm"
                                            color="red"
                                            title="Locate installed subsystem"
                                          />
                                        </button>
                                      )}

                                      {!slave.BTStatus && (
                                        <button
                                          className="btn btn-outline-light"
                                          style={{
                                            padding: "0px",
                                            borderWidth: "0px",
                                            marginRight: "2px",
                                            marginTop: "-7px",
                                            padding: "0px",
                                            position: "relative",
                                            right: "0px",
                                          }}
                                          onClick={() =>
                                            this.handleSlave(
                                              LOCATOR_ON,
                                              `${slave.MAC}`
                                            )
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon="crosshairs"
                                            size="sm"
                                            color="grey"
                                            title="Locate installed subsystem"
                                          />
                                        </button>
                                      )}
                                    </td>
                                  )}
                                </tr>
                              </div>
                            </tbody>
                          </table>
                        </tr>
                      </table>
                    </div>
                  ))}
                </tbody>
              </table>
            )}

          {this.state.dataVerbosity === "1" &&
            this.state.slaveCount != 0 &&
            this.state.systemConfiguration === "0" && (
              <table className="table" style={{ width: "350px" }}>
                <tbody>
                  {this.state.slaves.map((slave) => (
                    <div>
                      <tr style={{ backgroundColor: "#ece9e9" }}>
                        <th style={{ color: "magenta" }}>Status</th>
                        <th style={{ color: "magenta" }}>Unit</th>
                        <th style={{ color: "magenta" }}>Serial #</th>
                        <th style={{ color: "magenta" }}>SOC</th>
                        <th>
                          <FontAwesomeIcon
                            style={{ marginLeft: "0px", marginRight: "0px" }}
                            icon="wifi"
                            size="lg"
                            color="green"
                            title="Signal Strength"
                          />
                          {slave.deviceRSSI}
                        </th>
                      </tr>
                      <tr key={slave.id}>
                        {slave.deviceAlarm === 0 && (
                          <td>
                            <FontAwesomeIcon
                              icon="exclamation-triangle"
                              size="lg"
                              color="red"
                              title="Subsystem Alarms"
                            />
                          </td>
                        )}
                        {slave.deviceAlarm === 1 && (
                          <td>
                            <FontAwesomeIcon
                              icon="check"
                              size="sm"
                              color="green"
                              title="OK"
                            />
                          </td>
                        )}
                        <td>{this.state.slaves.indexOf(slave) + 1}</td>
                        <td>{slave.SN}</td>

                        <td>{slave.SOC.toFixed(1)}</td>
                        <td style={{ float: "right", marginRight: "10px" }}>
                          <SlaveInfoModalMedium
                            unitNumber={this.state.slaves.indexOf(slave) + 1}
                            slaveState={slave.slaveState}
                            battMode={slave.battMode}
                          />
                        </td>
                      </tr>

                      <table>
                        <tr>
                          <table
                            className="table"
                            style={{ width: "350px", marginLeft: "20px" }}
                          >
                            <tbody>
                              <div>
                                <tr key={slave.id}></tr>

                                <tr>
                                  <th>Reset</th>
                                  <th>Factory</th>
                                  <th>Turn Off</th>
                                  <th>Hibernate</th>
                                  <th>Locator</th>
                                </tr>
                                <tr>
                                  {this.state.systemStatus === "UPDATING" && (
                                    <td>Updating...</td>
                                  )}
                                  {this.state.systemStatus === "ALIVE" && (
                                    <td>
                                      <button
                                        className="btn btn-outline-light"
                                        style={{
                                          padding: "0px",
                                          borderWidth: "0px",
                                          marginRight: "2px",
                                          marginTop: "-7px",
                                          padding: "0px",
                                          position: "relative",
                                          right: "0px",
                                          boxShadow: "5px 5 px 5px 5px #888888",
                                        }}
                                        onClick={() =>
                                          this.handleSlave(
                                            SLAVE_RESET,
                                            `${slave.MAC}`
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon="retweet"
                                          size="sm"
                                          color="orange"
                                          title="Reset Subsystems"
                                        />
                                      </button>
                                    </td>
                                  )}
                                  {this.state.systemStatus === "ALIVE" && (
                                    <td>
                                      <button
                                        className="btn btn-outline-light"
                                        style={{
                                          padding: "0px",
                                          borderWidth: "0px",
                                          marginRight: "2px",
                                          marginTop: "-7px",
                                          padding: "0px",
                                          position: "relative",
                                          right: "0px",
                                          boxShadow: "5px 5 px 5px 5px #888888",
                                        }}
                                        onClick={() =>
                                          this.handleSlave(
                                            SLAVE_FACTORY_RESET,
                                            `${slave.MAC}`
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon="history"
                                          size="sm"
                                          color="magenta"
                                          title="Factory Reset Subsystems"
                                        />
                                      </button>
                                    </td>
                                  )}
                                  {this.state.systemStatus === "ALIVE" && (
                                    <td style={{ color: "white" }}>
                                      <button
                                        className="btn btn-outline-light"
                                        style={{
                                          padding: "0px",
                                          borderWidth: "0px",
                                          marginRight: "2px",
                                          marginTop: "-7px",
                                          padding: "0px",
                                          position: "relative",
                                          right: "0px",
                                          boxShadow: "5px 5 px 5px 5px #888888",
                                        }}
                                        onClick={() =>
                                          this.handleSlave(
                                            DEACTIVATE_SLAVE,
                                            `${slave.MAC}`
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon="power-off"
                                          size="sm"
                                          color="green"
                                          title="PV Must Be Shorted"
                                        />
                                      </button>
                                    </td>
                                  )}
                                  {this.state.systemStatus === "ALIVE" && (
                                    <td>
                                      <button
                                        className="btn btn-outline-light"
                                        style={{
                                          padding: "0px",
                                          borderWidth: "0px",
                                          marginRight: "2px",
                                          marginTop: "-7px",
                                          padding: "0px",
                                          position: "relative",
                                          right: "0px",
                                          boxShadow: "5px 5 px 5px 5px #888888",
                                        }}
                                        onClick={() =>
                                          this.handleSlave(
                                            SLAVE_HIBERNATE,
                                            `${slave.MAC}`
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon="stop"
                                          size="sm"
                                          color="tomato"
                                          title="Disable Batteries"
                                        />
                                      </button>
                                    </td>
                                  )}
                                  {this.state.systemStatus === "ALIVE" && (
                                    <td style={{ color: "white" }}>
                                      {slave.BTStatus && (
                                        <button
                                          className="btn btn-outline-light"
                                          style={{
                                            padding: "0px",
                                            borderWidth: "0px",
                                            marginRight: "2px",
                                            marginTop: "-7px",
                                            padding: "0px",
                                            position: "relative",
                                            right: "0px",
                                          }}
                                          onClick={() =>
                                            this.handleSlave(
                                              LOCATOR_OFF,
                                              `${slave.MAC}`
                                            )
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon="crosshairs"
                                            size="sm"
                                            color="red"
                                            title="Locate installed subsystem"
                                          />
                                        </button>
                                      )}

                                      {!slave.BTStatus && (
                                        <button
                                          className="btn btn-outline-light"
                                          style={{
                                            padding: "0px",
                                            borderWidth: "0px",
                                            marginRight: "2px",
                                            marginTop: "-7px",
                                            padding: "0px",
                                            position: "relative",
                                            right: "0px",
                                          }}
                                          onClick={() =>
                                            this.handleSlave(
                                              LOCATOR_ON,
                                              `${slave.MAC}`
                                            )
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon="crosshairs"
                                            size="sm"
                                            color="grey"
                                            title="Locate installed subsystem"
                                          />
                                        </button>
                                      )}
                                    </td>
                                  )}
                                </tr>
                              </div>
                            </tbody>
                          </table>
                        </tr>
                      </table>
                    </div>
                  ))}
                </tbody>
              </table>
            )}
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}
export default SlaveTable;

class SlaveInfoModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <React.Fragment>
        <button
          className="btn btn-outline-light"
          style={{
            padding: "0px",
            borderWidth: "0px",
            float: "right",
            marginRight: "2px",
            marginTop: "-7px",
            padding: "0px",
            position: "relative",
            right: "0px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="external-link-alt"
            size="sm"
            color="dodgerblue"
            title="More Info on subsystems"
          />
        </button>

        <Modal
          style={{ width: "300px", alignContent: "center" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "14px", fontWeight: "600" }}
            >
              SimplBox #{this.props.unitNumber}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {this.props.battMode == 0 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery is Fully Charged.
              </p>
            )}
            {this.props.battMode == 1 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery is OK.
              </p>
            )}
            {this.props.battMode == 2 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery is OK.
              </p>
            )}
            {this.props.battMode == 3 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery is Empty.
              </p>
            )}

            {this.props.slaveState == 0 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Off-peak: Standby...
              </p>
            )}
            {this.props.slaveState == 1 && (
              <p className="basicParagraph" style={{ fontSize: "10px" }}>
                Off-peak: Subsystem stopped absorb charging.
              </p>
            )}
            {this.props.slaveState == 2 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Off-peak: Absorb charging (CV)
              </p>
            )}
            {this.props.slaveState == 3 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Off-peak: Bulk charging (CC)
              </p>
            )}
            {this.props.slaveState == 4 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                On-peak: Discharging
              </p>
            )}
            {this.props.slaveState == 5 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                On-peak: All solar going to inverter
              </p>
            )}

            {this.props.slaveState == 6 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Low Power Flow: Check Connections
              </p>
            )}

            {this.props.slaveState == 7 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Low Power Flow: System OK
              </p>
            )}
            {this.props.slaveState == 8 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery or heat sink Temp is out of range.
              </p>
            )}
            <p />
            {this.props.slaveState == 9 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery Hibernation Mode.
              </p>
            )}
            <p />

            <p
              className="basicParagraph"
              style={{
                fontSize: "10px",
                width: "225px",
                textAlign: "center",
              }}
            >
              PV and Inverter Voltage: {this.props.pvVolts / 100.0}
            </p>
            {this.props.invConnected === "1" && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Inverter is Connected.
              </p>
            )}
            {this.props.invConnected === "0" && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                No inverter is Connected.
              </p>
            )}

            <p
              className="basicParagraph"
              style={{
                fontSize: "10px",
                width: "225px",
                textAlign: "center",
              }}
            >
              Loop Count: {this.props.loopCount}
            </p>
            <p
              className="basicParagraph"
              style={{
                fontSize: "10px",
                width: "225px",
                textAlign: "center",
              }}
            >
              {this.props.fwVersionSlave}
            </p>
            <p
              className="basicParagraph"
              style={{
                fontSize: "10px",
                width: "225px",
                textAlign: "center",
              }}
            >
              {this.props.macAddress}
            </p>

            <table className="table">
              <thead>
                <tr>
                  <th>Temp. Sensor</th>
                  <th>(&#176;F)</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ lineHeight: "3px" }}>
                  <td>Heat Sink</td>
                  <td>{this.props.tempData.HeatSinkTemp / 100}</td>
                </tr>
                <tr style={{ lineHeight: "3px" }}>
                  <td>Battery</td>
                  <td>{this.props.tempData.TempSensorA / 100}</td>
                </tr>
                {/*<tr style={{ lineHeight: "3px" }}>
                  <td>Battery Temp B</td>
                  <td>{this.props.tempData.TempSensorB / 100}</td>
                </tr>
                <tr style={{ lineHeight: "3px" }}>
                  <td>Battery Temp C</td>
                  <td>{this.props.tempData.TempSensorC / 100}</td>
              </tr>*/}
              </tbody>
            </table>

            <table className="table">
              <thead>
                <tr>
                  <th>Alarms</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr style={{ lineHeight: "3px" }}>
                  <td>Heat Sink Over-Temp</td>
                  {this.props.tempData.HeatSinkOverTemp === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.tempData.HeatSinkOverTemp === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Heat Sink Under-Temp</td>
                  {this.props.tempData.HeatSinkUnderTemp === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.tempData.HeatSinkUnderTemp === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>
                <tr style={{ lineHeight: "3px" }}>
                  <td>Battery Over-Temp</td>
                  {this.props.tempData.TempSensorOverTemp === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.tempData.TempSensorOverTemp === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Battery Under-Temp</td>
                  {this.props.tempData.TempSensorUnderTemp === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.tempData.TempSensorUnderTemp === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Cell Over-Voltage</td>
                  {this.props.voltageData.CellOverVoltage === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.CellOverVoltage === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Cell Under-Voltage</td>
                  {this.props.voltageData.CellUnderVoltage === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.CellUnderVoltage === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Pack Over-Voltage</td>
                  {this.props.voltageData.PackOverVoltage === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.PackOverVoltage === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Pack Under-Voltage</td>
                  {this.props.voltageData.PackUnderVoltage === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.PackUnderVoltage === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Cell Delta Over-Voltage</td>
                  {this.props.voltageData.CellDeltaOverVoltage === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.CellDeltaOverVoltage === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>State-of-charge</td>
                  {this.props.voltageData.LowStateofCharge === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.LowStateofCharge === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>
              </tbody>
            </table>

            <table className="table">
              <thead>
                <tr>
                  <th>Cell</th>
                  <th>Battery Cell Voltage</th>
                </tr>
              </thead>
              <tbody>
                {this.props.batteryCells.map((cell) => (
                  <tr key={cell.id} style={{ lineHeight: "1px" }}>
                    <td>{this.props.batteryCells.indexOf(cell) + 1}</td>
                    <td>{(cell.cellV / 1000).toFixed(3)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

class SlaveInfoModalMedium extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <React.Fragment>
        <button
          className="btn btn-outline-light"
          style={{
            padding: "0px",
            borderWidth: "0px",
            float: "right",
            marginRight: "2px",
            marginTop: "-7px",
            padding: "0px",
            position: "relative",
            right: "0px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="external-link-alt"
            size="sm"
            color="dodgerblue"
            title="More Info on subsystems"
          />
        </button>

        <Modal
          style={{ width: "300px", alignContent: "center" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "14px", fontWeight: "600" }}
            >
              Subsystem Temperatures, Alarms and Cell Voltages for Unit #
              {this.props.unitNumber}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p
              className="basicParagraph"
              style={{
                fontSize: "12px",
                width: "225px",
                textAlign: "center",
                textDecoration: "underline",
              }}
            >
              State of Subsystem:
            </p>
            {this.props.slaveState == 0 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Off-peak: Subsystem is idling.
              </p>
            )}
            {this.props.slaveState == 1 && (
              <p className="basicParagraph" style={{ fontSize: "10px" }}>
                Off-peak: Subsystem stopped absorb charging.
              </p>
            )}
            {this.props.slaveState == 2 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Off-peak: Subsystem absorb charging (CV charge).
              </p>
            )}
            {this.props.slaveState == 3 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Off-peak: Subsystem bulk charging (CC charge).
              </p>
            )}
            {this.props.slaveState == 4 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                On-peak: Subsystem is discharging.
              </p>
            )}
            {this.props.slaveState == 5 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                On-peak: Standby with all available solar going to inverter.
              </p>
            )}

            {this.props.slaveState == 6 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Low Power Flow: Check PV and Inverter connectedness.
              </p>
            )}

            {this.props.slaveState == 7 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Low power flow is not due to PV or inverter not being connected.
              </p>
            )}
            {this.props.slaveState == 8 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery or heat sink temperature is out of range.
              </p>
            )}

            <p />

            <p
              className="basicParagraph"
              style={{
                fontSize: "12px",
                width: "225px",
                textAlign: "center",
                textDecoration: "underline",
              }}
            >
              State of Subsystem Battery:
            </p>
            {this.props.battMode == 0 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery is Fully Charged.
              </p>
            )}
            {this.props.battMode == 1 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery is OK.
              </p>
            )}
            {this.props.battMode == 2 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery is OK.
              </p>
            )}
            {this.props.battMode == 3 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery is Empty.
              </p>
            )}
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

class SlaveAlarmModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <React.Fragment>
        <button
          className="btn btn-outline-light"
          style={{
            borderWidth: "0px",
            marginTop: "-7px",
            marginLeft: "0px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="exclamation-triangle"
            size="sm"
            color="red"
            title="Subsystem Alarms"
          />
        </button>

        <Modal
          style={{ width: "300px", alignContent: "center" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "14px", fontWeight: "600" }}
            >
              Subsystem Alarms for Unit #{this.props.unitNumber}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <table className="table">
              <thead>
                <tr>
                  <th>Alarms</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr style={{ lineHeight: "3px" }}>
                  <td>Heat Sink Over-Temp</td>
                  {this.props.tempData.HeatSinkOverTemp === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.tempData.HeatSinkOverTemp === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Heat Sink Under-Temp</td>
                  {this.props.tempData.HeatSinkUnderTemp === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.tempData.HeatSinkUnderTemp === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>
                <tr style={{ lineHeight: "3px" }}>
                  <td>Battery Over-Temp</td>
                  {this.props.tempData.TempSensorOverTemp === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.tempData.TempSensorOverTemp === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Battery Under-Temp</td>
                  {this.props.tempData.TempSensorUnderTemp === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.tempData.TempSensorUnderTemp === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Cell Over-Voltage</td>
                  {this.props.voltageData.CellOverVoltage === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.CellOverVoltage === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Cell Under-Voltage</td>
                  {this.props.voltageData.CellUnderVoltage === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.CellUnderVoltage === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Pack Over-Voltage</td>
                  {this.props.voltageData.PackOverVoltage === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.PackOverVoltage === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Pack Under-Voltage</td>
                  {this.props.voltageData.PackUnderVoltage === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.PackUnderVoltage === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Cell Delta Over-Voltage</td>
                  {this.props.voltageData.CellDeltaOverVoltage === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.CellDeltaOverVoltage === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>State-of-charge</td>
                  {this.props.voltageData.LowStateofCharge === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.LowStateofCharge === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>
                {/*<tr style={{ lineHeight: "3px" }}>
                  <td>cRatio</td>
                  {this.props.voltageData.LowSlaveContribution === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.LowSlaveContribution === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                  </tr>*/}
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
