import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { getSystem, getSystemIDfromHandle } from "../services/systemService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setJwt } from "../services/httpService";
import { getUser, addSubAdmin, revokeSubAdmin } from "../services/userService";
import { getCompanyUsers } from "../services/companyService";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { masterCompany } from "../config.json";
import BackGroundRectangle from "../components/backgroundRect";
import { animateScroll as scroll } from "react-scroll";
import { ScrollView } from "@cantonjs/react-scroll-view";
import Password from "antd/lib/input/Password";
import { faSortAmountDown } from '@fortawesome/free-solid-svg-icons'
import { toast } from "react-toastify";

class SystemsUsers extends Component {
    state = {
      systemUsers: [],
      installers: [],
      owners: [],
      operators: [],
      systemName: "",
      userIsAdmin: false,
      userCompany: { masterCompany }.masterCompany,
      allUsers: [],
      serialNumber: "",
      handleID: "",
      filteredUsers: [],
      filterBy: [
        "Username",
        "First Name",
        "Last Name"
      ],
      showFilter: false
    };
  
    async componentDidMount() {
      const jwt = localStorage.getItem("token");
      setJwt(jwt);
      const loggedInUser = (await getUser()).data.data;

      const userIsAdmin = loggedInUser.admin;
      const userCompany = loggedInUser.company;
      console.log("user company", userCompany);
      const systemID = await getSystemIDfromHandle(this.props.match.params.id);
      console.log("props", this.props);
      const systemUsers = await getSystem(systemID.data.data.system_id);
      console.log("System Users:", systemUsers);
      const serialNumber = systemUsers.data.data[0].systemID.SN;
      const handleID = systemUsers.data.data[0].systemID.id;
      /********* changes *****/
      let allUsers = [];
      /****************/
      if (userIsAdmin) {
        // allUsers = (await getCompanyUsers(this.props.match.params.companyName)).data.data;
        allUsers = (await getCompanyUsers(this.props.match.params.companyName)).data.data;
        // allUsers = systemUsers;
        console.log("All Users", allUsers);
        // this.setState({ allUsers });
      }
      /************************* CHANGES *************************************************/
      const adminUser = [];
      const subadmin = [];
      let selectedIndex;
      allUsers.forEach((element, index) => {
        if (element.admin === true && !element.subadmin) {
          adminUser.push(element);
        } else if (element.subadmin) {
          subadmin.push(element);
        }
        if(adminUser.length !== 0) {
          if (element.username === adminUser[0].username) {
            selectedIndex = index;
            console.log("Index and username of admin", element.username, index);
          }
        }
      });

      if(adminUser.length !== 0) {
        console.log("index inside if", selectedIndex);
        const x = allUsers.splice(selectedIndex, 1);
        console.log("removed items", x);
      }

      console.log("Subadmins after loop", subadmin);
      this.setState({ adminUser, subadmin });
      console.log("user.username", adminUser[0].username);

      allUsers.forEach((user, index) => {
        
        if (systemUsers.data.data[0].systemPeople.installer) {
          
          systemUsers.data.data[0].systemPeople.installer.forEach(installer => {
            if (user.username === installer.username)
            user.installer = true;
          })
        }

        if (systemUsers.data.data[0].systemPeople.owner) {
          
          systemUsers.data.data[0].systemPeople.owner.forEach(owner => {
            if (user.username === owner.username)
            user.owner = true;
          })
        }

        if (systemUsers.data.data[0].systemPeople.operator) {
          
          systemUsers.data.data[0].systemPeople.operator.forEach(operator => {
            if (user.username === operator.username)
            user.operator = true;
          })
        }
      })

      this.setState({ allUsers, filteredUsers: allUsers });
      /*********************************************************************/
      this.setState({
        installers: systemUsers.data.data[0].systemPeople.installer,
      });
      this.setState({ owners: systemUsers.data.data[0].systemPeople.owner });
      this.setState({
        operators: systemUsers.data.data[0].systemPeople.operator,
      });
      this.setState({ systemName: systemUsers.data.data[0].systemID.name });
      this.setState({ userIsAdmin: loggedInUser });
      this.setState({ userIsAdmin });
      this.setState({ userCompany });

      this.setState({ serialNumber });
      this.setState({ handleID });

      scroll.scrollToTop();
    }

    manageInstaller = (e, username) => {
      if (this.state.userCompany !== this.props.match.params.companyName) {
        toast.error("User should be from that company to change roles.");
      } else if (e.target.checked === true) {
        this.props.history.push(`/users/addinstaller/${username}/${this.state.serialNumber}/${this.state.handleID}/${this.props.match.params.companyName}`);
      } else {
        this.props.history.push(`/users/removeinstaller/${username}/${this.state.serialNumber}/${this.state.handleID}/${this.props.match.params.companyName}`);
      }
    }

    manageOwner = (e, username) => {
      if (this.state.userCompany !== this.props.match.params.companyName) {
        toast.error("User should be from that company to change roles.");
      } else if (e.target.checked === true) {
        this.props.history.push(`/users/addowner/${username}/${this.state.serialNumber}/${this.state.handleID}/${this.props.match.params.companyName}`);
      } else {
        this.props.history.push(`/users/removeowner/${username}/${this.state.serialNumber}/${this.state.handleID}/${this.props.match.params.companyName}`);
      }
    }

    manageOperator = (e, username) => {
      if (this.state.userCompany !== this.props.match.params.companyName) {
        toast.error("User should be from that company to change roles.");
      } else if (e.target.checked === true) {
        this.props.history.push(`/users/addoperator/${username}/${this.state.serialNumber}/${this.state.handleID}/${this.props.match.params.companyName}`);
      } else {
        this.props.history.push(`/users/removeoperator/${username}/${this.state.serialNumber}/${this.state.handleID}/${this.props.match.params.companyName}`);
      }
    }

    manageSub = async (e, username) => {
      if (this.state.userCompany !== this.props.match.params.companyName) {
        toast.error("User should be from that company to change roles.");
      } else if (e.target.checked === true) {
        this.props.history.push(`/users/addsubadmin/${username}/${this.props.match.params.companyName}/${this.state.handleID}`);
      } else {
        this.props.history.push(`/users/removesubadmin/${username}/${this.props.match.params.companyName}/${this.state.handleID}`);
      }
    }

    handleUsernameSearch = (e) => {
      console.log("value", e.target.value);
      const value = (e.target.value).toLowerCase();
      const filteredUsers = this.state.allUsers.filter(user => user.username.toLowerCase().includes(value));
      console.log("Filtered users", filteredUsers);
      this.setState({ filteredUsers });
    }

    handleFilters = (e) => {
      console.log("value", e.target.value);
      const value = e.target.value;
      const allUsers = this.state.allUsers;

      this.setState({ filteredUsers: allUsers });
      if (value === "Username") {
        this.setState({ selectedFilter: "username" });
      } else if (value === "First Name") {
        this.setState({ selectedFilter: "firstname" });
      } else if (value === "Last Name") {
        this.setState({ selectedFilter: "lastname" });
      } else {
        this.setState({ selectedFilter: "disabled" });
      }
    }

    searchFirstName = (e) => {
      console.log("Value", e.target.value);
      const value = (e.target.value).toLowerCase();
      const filteredUsers = this.state.allUsers.filter(user => user.firstName.toLowerCase().includes(value));
      console.log("Filtered users", filteredUsers);
      this.setState({ filteredUsers });
    }

    searchLastName = (e) => {
      console.log("Value", e.target.value);
      const value = (e.target.value).toLowerCase();
      const filteredUsers = this.state.allUsers.filter(user => user.lastName.toLowerCase().includes(value));
      console.log("Filtered users", filteredUsers);
      this.setState({ filteredUsers });
    }

    showFilter = () => {
      const filterValue = !this.state.showFilter;
      if (!filterValue) this.setState({filteredUsers: this.state.allUsers, selectedFilter: "disabled" })
      this.setState({ showFilter: filterValue });
    }
  
    render() {
      let systemOwnerCount = 0;
      let systemInstallerCount = 0;
      let systemOperatorCount = 0;
      if (this.state.installers !== null)
        systemInstallerCount = this.state.installers.length;
      if (this.state.owners !== null) systemOwnerCount = this.state.owners.length;
      if (this.state.operators !== null)
        systemOperatorCount = this.state.operators.length;
  
      if (
        systemOwnerCount === 0 &&
        systemInstallerCount === 0 &&
        systemOperatorCount === 0
      )
        return (
          <div
            style={{
              width: "350",
              position: "fixed",
              left: "50%",
              transform: "translateX(-50%)",
              marginTop: "10px",
              padding: "0",
              marginBottom: "250px",
              zIndex: 1,
            }}
          >
            <p className="basicParagraph">
              {" "}
              There are no installers, owners or operators assigned to this
              system.
            </p>
          </div>
        );
  
      return (
        <React.Fragment>
          <ScrollView style={{ height: "500vh" }}>
            <div
              style={{
                width: "500px",
                // height: "1000px",
                //position: "absolute",
                position: "relative",
                left: "50%",
                transform: "translateX(-50%)",
                marginTop: "150px",
                marginBottom: "150px",
                overflowY: "scrollY",
                //overflow: "auto",
                zIndex: 1,
              }}
            >
              <div style={{ textAlign: "center", marginLeft: "70px" }}>
                <p className="basicParagraph">
                  {this.props.match.params.companyName}
                </p>
                <p className="basicParagraph">
                  System Name: {this.state.systemName}
                </p>
              </div>
                {this.state.userIsAdmin && (
                <div>
                  {/* <hr /> */}
                  <hr />
                  <p className="basicParagraph">
                    Admin user for {this.props.match.params.companyName}:
                  </p>
  
                  {/****************************************************************/}
  
                  <table className="table" style={{ width: "500px" }}>
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Name</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.adminUser.map((user) => (
                        <tr key={user.username}>
                          <td>{user.username}</td>
                          <td>
                            {user.firstName} {user.lastName}
                          </td>
                          <td>
                            <UserInfoModal
                              username={user.username}
                              firstName={user.firstName}
                              lastName={user.lastName}
                              emailAddress={user.emailAddress}
                              phoneNumber={user.phoneNumber}
                              admin={user.admin}
                              verified={user.verified}
                              created_at={user.created_at}
                              updated_at={user.updated_at}
                            />
                          </td>
                          <td>
                            <Link to={`/unregUser/${user.username}`}>
                              <FontAwesomeIcon
                                icon="trash-alt"
                                size="lg"
                                color="red"
                              />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/****************************************************************/}
                </div>
              )}

              {this.state.userIsAdmin && (
                <div>
                  {/* <hr /> */}
                  <hr />
                  <p className="basicParagraph">
                    Sub-Admin users for {this.props.match.params.companyName}:
                  </p>
  
                  {/****************************************************************/}
  
                  <table className="table" style={{ width: "500px" }}>
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Name</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.subadmin.map((user) => (
                        <tr key={user.username}>
                          <td>{user.username}</td>
                          <td>
                            {user.firstName} {user.lastName}
                          </td>
                          <td>
                            <UserInfoModal
                              username={user.username}
                              firstName={user.firstName}
                              lastName={user.lastName}
                              emailAddress={user.emailAddress}
                              phoneNumber={user.phoneNumber}
                              subadmin={user.admin}
                              verified={user.verified}
                              created_at={user.created_at}
                              updated_at={user.updated_at}
                            />
                          </td>
                          <td>
                            <Link to={`/unregUser/${user.username}`}>
                              <FontAwesomeIcon
                                icon="trash-alt"
                                size="lg"
                                color="red"
                              />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/****************************************************************/}
                </div>
              )}

              {this.state.userIsAdmin && (
                <div>
                  <div>
                    <hr />
                    <div className="d-flex flex-row justify-content-between mb-2">
                      <p className="basicParagraph">
                        All registered users for {this.props.match.params.companyName}:
                      </p>
                      <button 
                        onClick={this.showFilter}
                        style={{borderColor: "transparent", backgroundColor: "transparent"}}
                      >
                        <FontAwesomeIcon 
                          icon={faSortAmountDown}
                          color={this.state.showFilter ? "blue" : "grey" }
                        />

                      </button>

                    </div>
                    {this.state.showFilter && (
                      <div className="d-flex justify-content-center">
                        <select
                          name="WTF3"
                          onChange={e => this.handleFilters(e)}
                          className="form-control2"
                          // defaultValue={this.state.value}
                          id="WTF2"
                          style={{
                            width: "200px",
                            marginBottom: "19px",
                            marginRight: "20px"
                          }}
                          >
                          <option key="default" value="Filter by">Select to filter by ...</option>
                          {this.state.filterBy.map((filter) => (
                            <option key={filter} value={filter}>
                              {filter}
                            </option>
                          ))}
                        </select>
                        {this.state.selectedFilter === "username" && (
                          <input 
                            style={{
                              width: "200px",
                              fontFamily: "arial",
                              fontWeight: "550",
                              color: "#784feb",
                              fontSize: "12px",
                              border: "2px solid rgb(206, 204, 204)",
                              borderRadius: "4px",
                              height: "30px",
                              paddingBottom: "0px",
                              marginBottom: "19px",
                            }} 
                            type="text"
                            name="username" 
                            onChange={(e) => this.handleUsernameSearch(e)} 
                            placeholder="Filter by username"
                          />

                        )}
                        {this.state.selectedFilter === "firstname" && (
                          <input 
                            style={{
                              width: "200px",
                              fontFamily: "arial",
                              fontWeight: "550",
                              color: "#784feb",
                              fontSize: "12px",
                              border: "2px solid rgb(206, 204, 204)",
                              borderRadius: "4px",
                              height: "30px",
                              paddingBottom: "0px",
                              marginBottom: "19px",
                            }} 
                            type="text"
                            name="firstname" 
                            onChange={(e) => this.searchFirstName(e)}
                            placeholder="Filter by first name"
                          />

                        )}
                        {this.state.selectedFilter === "lastname" && (
                          <input 
                            style={{
                              width: "200px",
                              fontFamily: "arial",
                              fontWeight: "550",
                              color: "#784feb",
                              fontSize: "12px",
                              border: "2px solid rgb(206, 204, 204)",
                              borderRadius: "4px",
                              height: "30px",
                              paddingBottom: "0px",
                              marginBottom: "19px",
                            }} 
                            type="text"
                            name="lastname" 
                            onChange={(e) => this.searchLastName(e)}
                            placeholder="Filter by last name"
                          />

                        )}

                      </div>

                    )}
                  </div>
  
                  {/****************************************************************/}
  
                  <table className="table" style={{ width: "500px" }}>
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Name</th>
                        <th>Sub-Admin</th>
                        <th>Owner</th>
                        <th>Installer</th>
                        <th>Operator</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.filteredUsers.map((user) => (
                        <tr key={user.username}>
                          <td>{user.username}</td>
                          <td>
                            {user.firstName} {user.lastName}
                          </td>
                          <td style={{ borderRight: "solid #eeeeee", borderLeft: "solid #eeeeee" }}>
                              <input 
                                type="checkbox" 
                                defaultChecked={user.subadmin} 
                                disabled={user.admin && !user.subadmin ? true : false}
                                onChange={e => this.manageSub(e, user.username)}
                              />
                          </td>
                          <td>
                              <input 
                                type="checkbox" 
                                defaultChecked={user.owner} 
                                // disabled={true}
                                onChange={e => this.manageOwner(e, user.username)}
                              />
                          </td>
                          <td>
                              <input 
                                type="checkbox" 
                                defaultChecked={user.installer} 
                                // disabled={user.subadmin ? true : false || user.owner ? true : false}
                                onChange={e => this.manageInstaller(e, user.username)}
                              />
                          </td>
                          <td>
                              <input 
                                type="checkbox" 
                                defaultChecked={user.operator} 
                                // disabled={user.subadmin ? true : false || user.owner ? true : false} 
                                onChange={e => this.manageOperator(e, user.username)}
                              />
                          </td>
                          
                          <td>
                            <UserInfoModal
                              username={user.username}
                              firstName={user.firstName}
                              lastName={user.lastName}
                              emailAddress={user.emailAddress}
                              phoneNumber={user.phoneNumber}
                              admin={user.admin}
                              verified={user.verified}
                              created_at={user.created_at}
                              updated_at={user.updated_at}
                            />
                          </td>
                          <td>
                            <Link to={`/unregUser/${user.username}`}>
                              <FontAwesomeIcon
                                icon="trash-alt"
                                size="lg"
                                color="red"
                              />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/****************************************************************/}
                </div>
              )}
            </div>
            {/*<BackGroundRectangle />*/}
          </ScrollView>
        </React.Fragment>
      );
    }
  }
export default SystemsUsers;

class UserInfoModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <>
        <button
          onClick={this.handleShow}
          className="btn btn-outline-light"
          style={{ padding: "0", marginTop: "-5px" }}
        >
          <FontAwesomeIcon
            icon="info-circle"
            size="sm"
            color="dodgerblue"
            title="User Information"
          />
        </button>

        <Modal
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              User Information:
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">User Name: {this.props.username}</p>

            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">
              Name: {this.props.firstName} {this.props.lastName}
            </p>

            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">{this.props.emailAddress}</p>
            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">{this.props.phoneNumber}</p>
            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">{this.props.admin && "Admin: Yes"}</p>
            <p className="modalTextSmall">{!this.props.admin && "Admin: No"}</p>
            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">
              {this.props.verified && "Verified: Yes"}
            </p>
            <p className="modalTextSmall">
              {!this.props.verified && "Verified: No"}
            </p>
            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">Created: {this.props.created_at}</p>
            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">Updated: {this.props.updated_at}</p>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
