import React, { Component } from "react";
import * as echarts from "echarts/dist/echarts.js";
import "bootstrap/dist/css/bootstrap.css";
import "./dashboard.css";
import {
  getSystemRealTimeData,
  getSystemLatestData,
  getSystemBasics,
  ws_getSystemLatestData,
  getSystemInfo,
} from "../services/systemService";

import { webSocketMode } from "../config.json"; //0: http; 1:websocket

class WalkInLineGraph2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphLen: 0,
      base: new Date(),
      oneDay: 86400000,
      date: [],
      value1: [0],
      value2: [0],
      value3: [0],
      value4: [0],
      now: new Date(),
    };
  }

  async componentDidMount() {
    if (this.props.chartDateInitial) {
      this.setState({
        date: this.props.chartDateInitial,
        value1: this.props.chartValue1Initial,
        // value2: this.props.chartValue2Initial,
        // value3: this.props.chartValue3Initial,
        // value4: this.props.chartValue4Initial,
      });
    }
    this.chart = echarts.init(document.getElementById("walkin-graph-area2"));
    //console.log("Tick Number", tickNumber);
    const tickNumber = 1000;
    this.timerID = setInterval(() => this.tick(), tickNumber);
    this.generateGraph();
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.addData(false);
    this.chart.setOption({
      yAxis: {
        max: 2.1,
      },
      xAxis: {
        data: this.state.date,
        axisTick: { show: false },
        splitLine: { show: false },
      },

      series: [
        {
          name: `${this.props.chartLegend1}`,
          data: this.state.value1,
        },
        // {
        //   name: `${this.props.chartLegend2}`,
        //   data: this.state.value2,
        // },
        // {
        //   name: `${this.props.chartLegend3}`,
        //   data: this.state.value3,
        // },
        // {
        //   name: `${this.props.chartLegend4}`,
        //   data: this.state.value4,
        // },
      ],
    });
  }

  addData(shift) {
    this.state.now = [
      this.state.now.getHours(),
      this.state.now.getMinutes(),
      this.state.now.getSeconds(),
    ].join(":");

    if (this.props.playPause) {
      this.state.date.push(this.state.now);

      if (this.props.chartDateInitial) {
        this.state.value1.push(this.props.chartValue1);
        // this.state.value2.push(this.props.chartValue2);
        // this.state.value3.push(this.props.chartValue3);
        // this.state.value4.push(this.props.chartValue4);
      }
    }
    const stringLength = parseInt(this.state.value1.length);
    const pointsOnChart = parseInt(localStorage.getItem("pointsOnChart"));

    const delta = stringLength - pointsOnChart;

    if (delta > 0 && this.props.modeBox === "Real-time") {
      this.state.date.shift();
      this.state.value1.shift();
      this.state.value2.shift();
      this.state.value3.shift();
      this.state.value4.shift();
    }
    this.state.now = new Date();
  }
  generateGraph() {
    var option = {
      grid: {
        top: 0,
        bottom: 20,
        left: "10%",
        right: "10%",
      },
      /*  legend: {
        type: "scroll",
        data: [
          `${this.props.chartLegend1}`,
          `${this.props.chartLegend2}`,
          `${this.props.chartLegend3}`,
          `${this.props.chartLegend4}`,
        ],
        center: "50%",
        top: "0%",
        orient: "horizontal",
        align: "auto",
        padding: 3,
        textStyle: {
          fontStyle:
            "normal" ,
          fontSize: 10,
          fontWeight: "bold",
          color: "grey",
        },
        backgroundColor: "transparent",
        borderColor: "#ffffff",
        borderWidth: 1,
        boarderRadius: 0,
      },*/
      legend: {
        show: false,
      },
      xAxis: {
        name: "",
        nameLocation: "end",
        type: "category",
        nameTextStyle: {
          fontStyle:
            "normal" /*'Lato,"Helvetica Neue",Arial,Helvetica,sans-serif'*/,
          fontSize: 16,
          fontWeight: "bolder",
          color: "green",
        },
        axisLabel: {
          fontStyle: "normal",
          fontSize: 8,
          fontWeight: "normal",
          color: "orange",
        },
        boundaryGap: false,
        data: this.state.date,
      },
      yAxis: {
        show: false,
        name: "Power (Watts)",
        nameLocation: "end",
        boundaryGap: [0, "10%"],
        type: "value",
        nameTextStyle: {
          fontStyle:
            "normal" /*'Lato,"Helvetica Neue",Arial,Helvetica,sans-serif'*/,
          fontSize: 16,
          fontWeight: "bolder",
          color: "green",
        },
        axisLabel: {
          fontStyle: "normal",
          fontSize: 10,
          fontWeight: "bold",
          color: "green",
        },
      },
      /* dataZoom: [
        {
          type: "slider",
          show: true,
          xAxisIndex: [0],
          start: 1,
          end: 100,
        },
        {
          type: "inside",
          xAxisIndex: [0],
          start: 1,
          end: 100,
        },
      ],*/
      series: [
        {
          name: "PV Power",
          type: "line",
          symbol: "none",
          color: "dodgerblue",
          smooth: 0.25,
          areaStyle: {
            normal: {
              color: "dodgerblue",
            },
          },
          data: this.state.value1,
        },
        // {
        //   name: "Battery Power",
        //   type: "line",
        //   symbol: "none",
        //   color: "blue",
        //   smooth: 0.25,
        //   /*areaStyle: {
        //     normal: { color: "DodgerBlue" },
        //   },*/
        //   data: this.state.value2,
        // },
        // {
        //   name: "Grid Power",
        //   type: "line",
        //   symbol: "none",
        //   color: "tomato",
        //   smooth: 0.25,
        //   areaStyle: {
        //     normal: {
        //       color: "tomato",
        //     },
        //   },
        //   data: this.state.value3,
        // },
        // {
        //   name: "Building Power",
        //   type: "line",
        //   symbol: "none",
        //   color: "red",
        //   smooth: 0.25,
        //   data: this.state.value4,
        //   label: {
        //     show: true,
        //     value: "kW",
        //   },
        // },
      ],
      /*tooltip: {
        trigger: "axis",
        axisPointer: {
          animation: false,
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
            precision: "2",
          },
        },
      },*/
    };

    this.chart.setOption(option);
  }

  render() {
    return <div id="walkin-graph-area2" />;
  }
}
export default WalkInLineGraph2;
