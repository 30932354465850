import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import {
  findIdByMac,
  createSystem,
  getSystems,
  setSystemBasics
} from "../services/systemService";
import BackGroundRectangle from "../components/backgroundRect";

class ManufactureForm extends Form {
  state = {
    data: {
      MAC: "",
      productSN: "",
      productType: "",
      productPN: ""
    },
    optionsType: [
      { _id: "SimplBox", name: "SimplBox" },
      { _id: "SimplMeter", name: "SimplMeter" },
      { _id: "SimplRepeater", name: "SimplRepeater" }
    ],
    optionsProduct: [
      { _id: "SimplBox-05", name: "SimplBox-05" },
      { _id: "SimplBox-10", name: "SimplBox-10" },
      { _id: "SimplBox-15", name: "SimplBox-15" },
      { _id: "SimplBox-05-Wifi", name: "SimplBox-05-Wifi" },
      { _id: "SimplBox-10-Wifi", name: "SimplBox-10-Wifi" },
      { _id: "SimplBox-15-Wifi", name: "SimplBox-15-Wifi" },
      { _id: "SimplBox-05-4G", name: "SimplBox-05-4G" },
      { _id: "SimplBox-10-4G", name: "SimplBox-10-4G" },
      { _id: "SimplBox-15-4G", name: "SimplBox-15-4G" },
      { _id: "SimplMeter-R", name: "SimplMeter-R" },
      { _id: "SimplMeter-R-Wifi", name: "SimplMeter-R-Wifi" },
      { _id: "SimplMeter-R-4G", name: "SimplMeter-R-4G" },
      { _id: "SimplMeter-C", name: "SimplMeter-C" },
      { _id: "SimplMeter-C-Wifi", name: "SimplMeter-C-Wifi" },
      { _id: "SimplMeter-C-4G", name: "SimplMeter-C-4G" },
      { _id: "SimplRepeater", name: "SimplRepeater" },
      { _id: "SimplRepeater-Wifi", name: "SimplRepeater-Wifi" },
      { _id: "SimplRepeater-4G", name: "SimplRepeater-4G" }
    ],

    errors: {}
  };

  schema = {
    MAC: Joi.string()
      .required()
      .label("Unit's MAC Address"),
    productSN: Joi.string()
      .required()
      .label("Unit's Serial Number"),
    productType: Joi.string()
      .required()
      .label("Porduct Type"),
    productPN: Joi.string()
      .required()
      .label("Product")
  };

  doSubmit = async () => {
    await createSystem(
      this.state.data.MAC,
      this.state.data.productSN,
      this.state.data.productPN,
      this.state.data.productType
    );
    await getSystems(); //refresh the local data
    const systemID = (await findIdByMac(this.state.data.MAC)).data.data
      .system_id;
    console.log("System ID", systemID);
    //set the default value at the time of manufacture...
    await setSystemBasics(
      systemID,
      "Default Basic Description",
      "0", //system Configuration is always set to 0 at the time of manufacturing
      this.state.data.productPN, //System Product PN is grabbed from above
      this.state.data.productType, //system Product Type is grabbed from above
      "1", //System Time zone -8 hours Pacific Time
      "2", //Data Verbosity set to Medium
      "1", //Data Frequency set to once/second
      "2", //Local Log Length
      "1", //Local Log Frequency
      15, //Local Log verbosity
      "2", //Reset Delay
      "1", //Param Update Rate
      "3", //Interval Data Period
      "123",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "456",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    );
    this.props.history.push("/manufactures");
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1
          }}
        >
          <p className="basicParagraph">Manufacture a new unit:</p>
          <form onSubmit={this.handleSubmit}>
            {this.renderInput("MAC", "Unit's MAC Address")}
            {this.renderInput("productSN", "Unit's Serial Number")}
            {this.renderSelect(
              "productType",
              "Product Type",
              this.state.optionsType
            )}
            {this.renderSelect(
              "productPN",
              "Product Part Number",
              this.state.optionsProduct
            )}

            {this.renderButton("Manufacture Unit")}
          </form>
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default ManufactureForm;
