import React, { Component, useState, useEffect } from "react";
//import simplWS_client from "./wsService";
import {
  getSystemIDfromHandle,
  getSystem,
  getSystemChangesHistory,
  getSystemTasks,
  setSystemTasks,
  setSystemTask,
  setSystemReply
} from "../services/systemService";
import { getUser } from "../services/userService";
import { getCompanyUsers, getUserHistory } from "../services/companyService";
import "./dashboard.css";
import "../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faReply, faCaretUp, faCaretDown, faSortAmountDown } from '@fortawesome/free-solid-svg-icons'
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";

class SystemHistory extends Component {
  state = {
    systemHandleID: "",
    systemName: "",
    isEmpty: false,
    systemChangesHistory: [],
    allActions: [
      "Add/update the system tariff option uploaded to the system hardware",
      "Address Updated",
      "Basic info updated",
      "Do not perform any function option in upload",
      "Limits Updated",
      "Login",
      "Reset System option uploaded to the system hardware",
      "System Control Added",
      "System Control Deleted",
      "System Control Edited",
      "Temperature overrode",
      "Update System Basics option uploaded to the system hardware",
      "Update System Controls option uploaded to the system hardware",
      "Update System Limits option uploaded to the system hardware",
      "Alarms modified"
    ],
    companyUsers: [],
    historyFiltered: [],
    user: "All Users",
    action: "All Actions",
    showHistory: false,
    showToDoList: true
  };

  async componentDidMount() {
    const handleId = this.props.match.params.systemHandleID;
    const systemID = (await getSystemIDfromHandle(handleId)).data.data.system_id;
    const system = (await getSystem(systemID)).data.data[0];
    const systemName = system.systemID.name;
    this.setState({systemName});

    let userHistory = []
    let fullHistory = []

    // Get system history and set it to state
    const systemChangesHistory = (await getSystemChangesHistory(handleId)).data.systemHistory;
    console.log("system history", systemChangesHistory);
    if (!systemChangesHistory) {
      return this.setState({ isEmpty: true})
    }
    this.setState({ systemChangesHistory: systemChangesHistory });

    // Get user history, change timezone and saving it in fullHistory
    const objUserHistory = (await getUserHistory(this.props.match.params.companyName)).data.history.user_history;
    if (objUserHistory) {
      userHistory = Object.values(objUserHistory)

      userHistory.forEach(history => {
        let d = new Date(history.date)
        d = d.toLocaleString('en-US', { timeZone: systemChangesHistory[0].timezone });
        history.date = d
      });

      userHistory.forEach(element => {
        fullHistory.push(element)
      })
    }

    // Saving system history into fullHistory. Now have both user and system history
    systemChangesHistory.forEach(history => {
      fullHistory.push(history)
    })

    const orderedHistory = fullHistory.sort((date1, date2) => new Date(date2.date) - new Date(date1.date))
    this.setState({ orderedHistory })

    // Get users attached to the system
    const companyUsers = (await getCompanyUsers(this.props.match.params.companyName)).data.data;
    this.setState({ companyUsers });

    // Setting default date filters (from a week ago to today)
    const lastWeek = this.getLastWeeksDate();
    this.setState({ lastWeek })

    let currentDate = new Date()
    currentDate = this.formatDate(currentDate);
    this.setState({ currentDate })

    const endDateHtml = this.dateHtml(currentDate);
    const startDateHtml = this.dateHtml(lastWeek);
    this.setState({
        endDateHtml,
        startDateHtml
    });

    const filteredDates = orderedHistory.filter(history => startDateHtml <= this.dateHtml(history.date) && endDateHtml >= this.dateHtml(history.date));
    this.setState({historyFiltered: filteredDates})
  }

  // Change date format into HTML date format for filters
  dateHtml(date) {
    let format = new Date(date)
    let day = format.getDate();
    let month = format.getMonth() + 1;
    let year = format.getFullYear();

    format = `${year}-${month > 9 ? month : "0" + month}-${day > 9 ? day : "0" + day}`;
    return format;
  }

  // Get the last week date for initial date filter
  getLastWeeksDate() {
    const now = new Date();

    const unformatted = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);

    let formatted = this.formatDate(unformatted)
    return formatted;
  }

  // Format date in the style that is being saved in the db
  formatDate(unformatted) {
    let day = unformatted.getDate();
    let month = unformatted.getMonth() + 1;
    let year = unformatted.getFullYear();

    let formattedDate = `${month}/${day}/${year}`;
    return formattedDate
  }

  // Handler for action filter
  handleAction(e) {
    const action = e.target.value;
    this.handleFilters("Action", action);
  }

  // Handler for user filter
  handleUser(e) {
    const user = e.target.value
    this.handleFilters("User", user);
  }

  // Handler for start date filter
  handleStartDate(e) {
    let date = new Date(e.target.value);
    date = this.dateHtml(date)

    this.handleFilters("Start Date", date);
  }

  // Handler for end date filter
  handleEndDate(e) {
    let date = new Date(e.target.value);
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
    date = this.dateHtml(date);
    this.handleFilters("End Date", date);
  }

  // Handler for make all filters work together
  handleFilters(opFilter, filterValue) {
    let endDateHtml = this.state.endDateHtml;
    let startDateHtml = this.state.startDateHtml;
    let user = this.state.user;
    let action = this.state.action;

    if (opFilter === "End Date") {
        endDateHtml = filterValue;
        this.setState({ endDateHtml });


    } else if (opFilter === "Start Date") {
        startDateHtml = filterValue;
        this.setState({ startDateHtml });
    }

    let historyFiltered = this.state.orderedHistory.filter(history =>
        startDateHtml < this.dateHtml(history.date) && endDateHtml >= this.dateHtml(history.date)
    );

    if (opFilter === "User") {
        user = filterValue;
        this.setState({ user });
    }

    if (user !== "All Users") {
        historyFiltered = historyFiltered.filter(history => history.username === user);
    }

    if (opFilter === "Action") {
        action = filterValue;
        this.setState({ action });
    }

    if (action !== "All Actions") {
        if (action === "Temperature overrode") {
          historyFiltered = historyFiltered.filter(history => history.action.includes(action));
        } else if (action === "Alarms modified") {
          historyFiltered = historyFiltered.filter(history => history.action.includes(action));
        } else if (action === "System Control Edited") {
          historyFiltered = historyFiltered.filter(history => history.action.includes("Edited"));
        } else if (action === "System Control Deleted") {
          historyFiltered = historyFiltered.filter(history => history.action.includes("Deleted"));
        } else if (action === "System Control Added") {
          historyFiltered = historyFiltered.filter(history => history.action.includes("Added"));
        } else {
          historyFiltered = historyFiltered.filter(history => history.action === action);
        }
    }

    this.setState({ historyFiltered });
  }
  

  render() {
    return (
      <div
        style={{
          width: "1000px",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: "150px",
          marginBottom: "150px",
          zIndex: 1,
          paddingBottom: "100px",
        }}>

          <div className="d-flex justify-content-center mb-2">
            <p
              className="basicParagraph text-center"
              style={{
                  width: "1000px",
                  fontSize: "12px",
                  color: "#2D5C88"
              }}> System history for {this.state.systemName}
            </p>
          </div>


          <div  style={{
            display: "flex",
            flexFlow: "column wrap",
            alignItems: "center"
          }}>



            <p
              className="basicParagraph"
              style={{
                width: "350px",
                fontSize: "12px",
                color: "#777575",
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              Please select an option
            </p>

            <div style={{
              display: "flex",
              gap: "10px"
            }}>
              <button
                // className="btn btn-primary shadow-none"
                className="btn btn-outline-warning btn-sm"
                style={{
                }}
                onClick={()=> {
                  this.setState({ showHistory: false, showToDoList: true })
                }}
                disabled={this.state.showToDoList ? true : false}
              >
                Tasks
              </button>

              <button
                // className="btn btn-primary shadow-none"
                className="btn btn-outline-warning btn-sm"
                style={{
                }}
                onClick={()=> {
                  this.setState({ showHistory: true, showToDoList: false })
                }}
                disabled={this.state.showHistory ? true : false}
              >
                History

              </button>
            </div>

          </div>

          <hr />

          {this.state.showHistory && (
          <>
            <div className="d-flex justify-content-center pb-2 mb-3">
              <p
              className="basicParagraph text-center"
              style={{
                width: "1000px",
                fontSize: "12px",
                color: "#2D5C88"
              }}> History
              </p>
            </div>
            <div style={{
            width: "100%",
            display: "flex",
            justifyContent: "center"
            }}>
              <div
                style={{
                  height: "25px",
                  width: "1000px",
                  borderRadius: "5px",
                  color: "#ffc000",
                  backgroundColor: "#777575",
                  fontWeight: "bold",
                  fontSize: "11px",
                  textAlign: "center",
                  paddingTop: "5px",
                  paddingBottom: "5px;"
                }}
              >
              </div>
            </div>
      {/* Bar to handle the filter Bars */}
      <div style={{
        position: "relative",
        overflow: "hidden",
        zIndex: "-1",
        width: "1000px",
        minHeight: "45px",
        // maxHeight: `${filterOption === "Description" ? "auto" : "45px"} !important`,
        backgroundColor: "#ece9e9",
        display: "flex",
        // flexFlow: "row nowrap",
        justifyContent: "center",
        alignItems: "center"
        // paddingBottom: `${filterOption == "Description" ? "10px" : "0px"}`
      }}>
    
        {/* <div style={{
          display: "flex",
          gap: "8px",
          height: "100%"
        }}> */}
    
          {/* <div style={{
              paddingTop:"10px",
              display: "flex",
              flexFlow: "row nowrap",
              gap: "5px",
            }
          }>
    
          </div> */}
    
          <div
          className="d-flex justify-content-center flex-row" 
          style={{
            marginTop: "10px"
          }}>
            {/* <button
              className="btn btn-primary shadow-none"
              style={{
                padding: "0px",
                borderWidth: "0px",
                borderColor: "tranparent",
                marginRight: "12px",
                // marginTop: "5px",
                color: "transparent",
                backgroundColor: "transparent",
              }}
              // onClick={()=> {
              //   if (filterMode){
              //     setFilterMode(false)
              //   } else {
              //     setFilterMode(true)
              //   }
              // }}
            >
              <FontAwesomeIcon
                icon={faSortAmountDown}
                size="1.2x"
                // color={ filterMode ? "blue" : "grey" }
                title="Filters"
              />
            </button> */}
            <div className="d-flex justify-content-center flex-row align-items-center mr-3" >
                    <label className="basicParagraph" style={{width: "90px"}}>Filter by:</label>
                    <select
                        style={{width: "150px", padding: "1px"}}
                        name="actionFilter"
                        className="form-control2 mr-2"
                        onChange={e => this.handleAction(e)}>
                        <option value="All Actions">All Actions</option>
                        {this.state.allActions.map((action) => (
                          <option value={action}>{action}</option>
                        ))}
                    </select>
                    <select
                      style={{width: "150px", padding: "1px"}}
                      name="userFilter"
                      className="form-control2 mr-2"
                      onChange={e => this.handleUser(e)}>
                        <option value="All Users">All Users</option>
                        {this.state.companyUsers.map((user) => (
                          <option value={user.username}>{user.username}</option>
                          ))}
                    </select>
                  </div>

                  <div className="d-flex justify-content-center flex-row align-items-center ml-3">
                    <label className="basicParagraph" style={{width: "100px"}}>Date Range:</label>
                    <input
                      style={{width: "150px"}}
                      name="startDate"
                      type="date"
                      className="form-control2 mr-2 p-1"
                      defaultValue={this.state.startDateHtml}
                      onChange={e => this.handleStartDate(e)}
                    />

                    <input
                      style={{width: "150px"}}
                      name="endDate"
                      type="date"
                      className="form-control2 mr-2 p-1"
                      defaultValue={this.state.endDateHtml}
                      onChange={e => this.handleEndDate(e)}
                    />
                  </div>
          </div>
        {/* </div> */}
      </div>

      {this.state.isEmpty && (
        <div className="mt-3">
          <p
            className="basicParagraph text-center"
            style={{
              width: "1000px",
              fontSize: "12px",
              color: "#2D5C88"
            }}
          >There is no system history</p>
        </div>

      )}

              {!this.state.isEmpty && (
                <div>
                  {/* <div className="d-flex justify-content-center flex-row">
                    <label className="basicParagraph" style={{width: "100px"}}>Filter by:</label>
                    <select
                        style={{width: "150px", padding: "1px"}}
                        name="actionFilter"
                        className="form-control2 mr-2"
                        onChange={e => this.handleAction(e)}>
                        <option value="All Actions">All Actions</option>
                        {this.state.allActions.map((action) => (
                          <option value={action}>{action}</option>
                        ))}
                    </select>
                    <select
                      style={{width: "150px", padding: "1px"}}
                      name="userFilter"
                      className="form-control2 mr-2"
                      onChange={e => this.handleUser(e)}>
                        <option value="All Users">All Users</option>
                        {this.state.companyUsers.map((user) => (
                          <option value={user.username}>{user.username}</option>
                          ))}
                    </select>
                  </div>

                  <div className="d-flex justify-content-center flex-row">
                    <label className="basicParagraph" style={{width: "100px"}}>Date Range:</label>
                    <input
                      style={{width: "150px"}}
                      name="startDate"
                      type="date"
                      className="form-control2 mr-2 p-1"
                      defaultValue={this.state.startDateHtml}
                      onChange={e => this.handleStartDate(e)}
                    />

                    <input
                      style={{width: "150px"}}
                      name="endDate"
                      type="date"
                      className="form-control2 mr-2 p-1"
                      defaultValue={this.state.endDateHtml}
                      onChange={e => this.handleEndDate(e)}
                    />
                  </div> */}
                  <table className="table" style={{ width: "1000px" }}>
                    <thead>
                      <tr>
                        <th>
                          Date
                        </th>
                        <th
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          User
                        </th>
                        <th
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    {this.state.historyFiltered.map((history) => (
                      <tbody>
                        <tr
                          // style={{ height: "500px" }}
                        >
                          <td>
                            {history.date}
                          </td>
                          <td
                            style={{
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                          >
                            {history.username}
                          </td>
                          <td style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                            {history.action}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div>
              )}
            </>
          )}

          {this.state.showToDoList && (
            <ToDoListComponent companyName={this.props.match.params.companyName} systemHandleID={this.props.match.params.systemHandleID} systemName={this.state.systemName} />
          )}


      </div>
    )
  }
}


function ToDoListComponent({companyName, systemHandleID, systemName}) {

  const [toDoList, setToDoList] = useState([])
  const [storedTasks, setStoredTasks] = useState([])
  const [users, setUsers] = useState([])
  const [editableTasks, setEditableTasks] = useState([])

  // filter variables
  const [filterList, setFilterList] = useState([])
  const [filterMode, setFilterMode] = useState(false)
  const [filterOption, selectFilterOption] = useState("")
  const [query, setQuery] = useState("")

  const [currentUser, setCurrentUser] = useState({})
  const [ascSort, setAscSort] = useState(false)

  const filterOptions = ["Date", "Subject", "Assignee", "Status", "Author", "Description"]
  const statusesList = ["Created", "In Progress", "Pending", "Closed"]

  const addNewRow = () => {
    try {
      let newArr = [...toDoList]
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let date = new Date()

      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      const time = date.toLocaleTimeString('en-US', { timeZone: timezone });

      const formattedDate = `${month}/${day}/${year}, ${time}`;

      const emptyRow = {
        id: null,
        date: formattedDate,
        subject: "",
        assignee: "",
        assigneeInfo: {},
        status: "",
        replies: [],
        description: "Please add a task description",
        taskMode: "edit",
        arrow: true,
        user: currentUser
      }

      newArr.unshift(emptyRow)
      setToDoList([...newArr])
      console.log("Row added!");
    } catch (e){
      console.log(e.message);
    }
  }

  const addNewSubTask = (index) => {
    try {
      const res = searchClosedTasks(index)

      if (res){
        toast.warning("You can not add a reply after the task is closed")
      } else {

        let newArr = [...toDoList]
        const parentTask = newArr[index]
  
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let date = new Date()
  
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        const time = date.toLocaleTimeString('en-US', { timeZone: timezone });
  
        const formattedDate = `${month}/${day}/${year}, ${time}`;
  
        const emptyRow = {
          id: null,
          foreignKey: parentTask.id,
          date: formattedDate,
          subject: parentTask.subject,
          assigneeInfo: {},
          assignee: "",
          status: "",
          description: "Please add a task description",
          taskMode: "edit",
          user: currentUser
        }

        newArr[index].replies.unshift(emptyRow)
        setToDoList(newArr)

        console.log("Reply added:");
        console.log(newArr[index]);
      }

    } catch(e){
      console.log("Error:");
      console.log(e);
    }
  }

  const removeRow = (index) => {
    try {
      let newArr = [...toDoList]
      newArr.splice(index, 1)
      setToDoList(newArr)

      console.log("Row removed!");
      return newArr
    } catch(e){
      console.log(e.message);
    }
  }

  const cancelSubTask = async (index, replyIndex) => {
    try {

      const tasks = (await getSystemTasks(systemHandleID)).data.data[0].tasks
      let newArr = [...toDoList]

      if (!newArr[index].replies[replyIndex].id){
        newArr[index].replies.splice(replyIndex, 1)
        setToDoList(newArr)

        return true
      } else {

        const subTask = tasks.find(element => {
          return element.id === newArr[index].id
        }).replies.find(element => {
          return element.id === newArr[index].replies[replyIndex].id
        })


        newArr[index].replies[replyIndex] = subTask
        newArr[index].replies[replyIndex].taskMode = "saved"

        return await saveReply(index, replyIndex)

      }

    } catch(e){
      console.log("Error canceling sub task:");
      console.log(e);
    }

  }

  const cancelSave = (index) => {
    let newArr = [...toDoList]
    let obj = newArr[index]

    if (!newArr[index].id){
      const tasks = removeRow(index)
      saveTasks(tasks, index)
    } else if (
      newArr[index].subject === "" &&
      newArr[index].assignee === "" &&
      newArr[index].status === "" &&
      (newArr[index].description === "" || newArr[index].description === "Please add a task description")
    ) {

      const tasks = removeRow(index)

      saveTasks(tasks, index).then(() => {
        removeEditableObj(obj)
      }).catch( err => {
        console.log(err);
      })
    } else {

      const recoverElement = editableTasks.find(element => {
        return element.id === obj.id
      })

      recoverElement.taskMode = "saved"
      newArr[index] = recoverElement

      saveTasks(newArr, index).then(() => {
        removeEditableObj(obj)
      }).catch( err => {
        console.log(err);
      })

    }

  }

  const changeArrowIcon = (index, mode) => {
    let newArr = [...toDoList]

    if (mode){
      newArr[index].arrow = true
    } else {
      newArr[index].arrow = false
    }

    setToDoList(newArr)

    console.log("Change arrow:");
    console.log(newArr);

  }

  const addEditableObj = (index) => {
    let newArr = [...editableTasks]
    let obj = {
      ...[...toDoList][index]
    }

    newArr.push(obj)

    setEditableTasks([...newArr])
  }

  const removeEditableObj = (obj) => {

    const newArr = editableTasks.filter(object => {
      return object.id !== obj.id;
    });

    setEditableTasks([...newArr])
  }

  const assignSubject = (event, index) => {

    try {

      let newArr = [...toDoList]

      if (newArr.length !== filterList.length && filterMode){
        index = findRightFilterTaskIdx(index)
      }

      if (newArr[index].user.username !== currentUser.username){
        toast.warning("Only the author of the task is able to edit it")
      } else if (searchClosedTasks(index)){
        toast.warning("The task cannot be edited after it is closed")
      } else {

        const value = event.target.value;

        newArr[index].subject = value

        setToDoList(newArr)

        console.log("Assign Subject:");
        console.log(newArr);
      }

    } catch(e){
      console.log("Assign subject error:");
      console.log(e);
    }

  }

  const assignUser = (event, index, replyIndex) => {
    // const handler = event.target.options[event.target.selectedIndex];
    // const text = handler.text

    try {


      if (replyIndex === "task"){
        let newArr = [...toDoList]

        if (newArr.length !== filterList.length && filterMode){
          index = findRightFilterTaskIdx(index)
        }

        if (newArr[index].user.username !== currentUser.username){
          toast.warning("Only the author of the task is able to edit it")
        } else if (searchClosedTasks(index)){
          toast.warning("The task cannot be edited after it is closed")
        } else {

          const value = event.target.value
          const assigneeInfo = users.find(user => user.username === value)
          newArr[index].assignee = value

          if (assigneeInfo){
            newArr[index].assigneeInfo = assigneeInfo
          } else {
            newArr[index].assigneeInfo = {}
          }

          setToDoList(newArr)

          console.log("Assign User:");
          console.log(newArr);
        }
      } else {
        console.log("Index:");
        console.log(index);

        console.log("Reply Index:");
        console.log(replyIndex);

        let newArr = [...toDoList]

        if (newArr.length !== filterList.length && filterMode){
          const currIdx = index

          index = findRightFilterTaskIdx(index)
          replyIndex = findRightFilterSubTaskIdx(index, currIdx, replyIndex)

        }

        if (newArr[index].replies[replyIndex].user.username !== currentUser.username){
          toast.warning("Only the author of the reply is able to edit it")
        } else if (searchClosedTasks(index)){
          toast.warning("The task cannot be edited after it is closed")
        } else {

          const value = event.target.value
          const assigneeInfo = users.find(user => user.username === value)
          newArr[index].replies[replyIndex].assignee = value

          if (assigneeInfo){
            newArr[index].replies[replyIndex].assigneeInfo = assigneeInfo
          } else {
            newArr[index].replies[replyIndex].assigneeInfo = {}
          }
          setToDoList(newArr)

          console.log("Assign User Reply");
          console.log(newArr[index]);
        }

      }
    } catch(e){
      console.log("Assign User Error:");
      console.log(e);
    }

  }

  const assignStatus = (event, index, replyIndex) => {

    try {

      if (replyIndex === "task"){

        let newArr = [...toDoList]

        if (newArr.length !== filterList.length && filterMode){
          index = findRightFilterTaskIdx(index)
        }

        if (newArr[index].user.username !== currentUser.username){
          toast.warning("Only the author of the task is able to edit it")
        } else if (searchClosedTasks(index)){
          toast.warning("The task cannot be edited after it is closed")
        } else {

          const value = event.target.value

          newArr[index].status = value
          setToDoList(newArr)

          console.log("Assign Status:");
          console.log(newArr);
        }


      } else {
        console.log("Index:");
        console.log(index);

        console.log("Reply Index:");
        console.log(replyIndex);

        let newArr = [...toDoList]

        if (newArr.length !== filterList.length && filterMode){
          const currIdx = index

          index = findRightFilterTaskIdx(index)
          replyIndex = findRightFilterSubTaskIdx(index, currIdx, replyIndex)

        }

        if (newArr[index].replies[replyIndex].user.username !== currentUser.username){
          toast.warning("Only the author of the reply is able to edit it")
        } else if (searchClosedTasks(index)){
          toast.warning("The task cannot be edited after it is closed")
        } else {

          const value = event.target.value

          newArr[index].replies[replyIndex].status = value
          setToDoList(newArr)

          console.log("Assign Status Reply");
          console.log(newArr[index]);
        }

      }

    } catch(e){
      console.log("Error:")
      console.log(e);
    }

  }

  const assignDescription = (event, index, replyIndex) => {

    try {

      if (replyIndex === "task"){

        let newArr = [...toDoList]

        if (newArr.length !== filterList.length && filterMode){
          index = findRightFilterTaskIdx(index)
        }

        if (newArr[index].user.username !== currentUser.username){
          toast.warning("Only the author of the task is able to edit it")
        } else if (searchClosedTasks(index)){
          toast.warning("The task cannot be edited after it is closed")
        } else {

          let value = event.target.value

          if (value === "Please add a task description" || value === "Please add a task descriptio"){
            value = ""
          }

          newArr[index].description = value
          setToDoList(newArr)

          console.log("Assign Description:");
          console.log(newArr);
        }

      } else {

        let newArr = [...toDoList]

        if (newArr.length !== filterList.length && filterMode){
          const currIdx = index

          index = findRightFilterTaskIdx(index)
          replyIndex = findRightFilterSubTaskIdx(index, currIdx, replyIndex)

        }

        if (newArr[index].replies[replyIndex].user.username !== currentUser.username){
          toast.warning("Only the author of the reply is able to edit it")
        } else if (searchClosedTasks(index)){
          toast.warning("The task cannot be edited after it is closed")
        } else {

          let value = event.target.value

          if (value === "Please add a task description" || value === "Please add a task descriptio"){
            value = ""
          }

          newArr[index].replies[replyIndex].description = value
          setToDoList(newArr)

          console.log("Assign Description:");
          console.log(newArr);
        }

      }

    } catch (e){
      console.log("Error:");
      console.log(e);
    }
  }

  // Functions to save the tasks
  const saveTasks = async (toDoList, index) => {

    const savedTasks = (await setSystemTasks(systemHandleID, toDoList)).data.data
    setToDoList([...savedTasks])
    // setStoredTasks([...savedTasks])
    setStoredTasks(JSON.parse(JSON.stringify(savedTasks)));

    console.log("Saved tasks:");
    console.log(savedTasks);

    if (index){
      return savedTasks[index]
    } else {
      return saveTasks
    }
  }

  const saveTask = async (index) => {
    const task = [...toDoList][index]

    console.log("Save Task:");
    console.log(task);

    const savedTasks = (await setSystemTask(systemHandleID, systemName, task)).data.data
    setToDoList([...savedTasks])
    // setStoredTasks([...savedTasks])
    setStoredTasks(JSON.parse(JSON.stringify(savedTasks)));
    setAscSort(false)
    // descendingTasksSort()
  }

  const saveReply = async (index, replyIndex) => {
    const reply = [...toDoList][index].replies[replyIndex]

    const savedTasks = (await setSystemReply(systemHandleID, systemName, reply)).data.data

    console.log("Saved tasks (reply):");
    console.log(savedTasks);

    setToDoList([...savedTasks])
    // setStoredTasks([...savedTasks])
    setStoredTasks(JSON.parse(JSON.stringify(savedTasks)));
    setAscSort(false)

  }

  // Filter functions
  const applyDateFilter = (event) => {

    let myDate

    if (!event){
      myDate = query
    } else {
      myDate = event.target.value
    }

    if (myDate === ""){
      setQuery("")
    } else {

      const dateParts = myDate.split('-');
      const formattedDate = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`
      const filteredList = []

      // const newFilterList = [...toDoList].filter(element => {
      //   const elementDateFormatted = element.date.split(',')[0].trim()
      //   return formattedDate === elementDateFormatted
      // })

      toDoList.forEach(element => {

        const elementDateFormatted = element.date.split(',')[0].trim()

        const repliesFiltered = element.replies.filter(reply => {
          const replyDateFormatted = reply.date.split(',')[0].trim()
          return formattedDate === replyDateFormatted
        })

        if (
          (elementDateFormatted === formattedDate) ||
          (element !== formattedDate && repliesFiltered.length > 0) ||
          (element.taskMode === "edit")
        ) {
          filteredList.push({
            ...element,
            arrow: true,
            replies: repliesFiltered
          })

        }
      })

      setFilterList([...filteredList])
      setQuery(myDate)
    }
  }

  const applySubjectFilter = (event) => {
    let value

    console.log("Query:");
    console.log(query);

    if (!event){
      value = query
    } else {
      value = event.target.value
    }

    if (value === ""){
      setFilterList([...toDoList])
      setQuery("")
    } else {

      const newFilterList = []

      toDoList.forEach(element => {

        const repliesFiltered = element.replies.filter(reply => {
            return (
              reply.subject.toLowerCase().includes(value.toLowerCase()) ||
              reply.taskMode === "edit"
            )
        })

        if (
          (element.subject.toLowerCase().includes(value.toLowerCase())) ||
          (!element.subject.toLowerCase().includes(value.toLowerCase()) && repliesFiltered.length > 0) ||
          (element.taskMode === "edit")
        ) {
          newFilterList.push({
            ...element,
            arrow: true,
            replies: repliesFiltered
          })

        }
      })

      // const newFilterList = toDoList.filter(element => {
      //   return (element.subject.toLowerCase().includes(value.toLowerCase()) || element.taskMode === "edit")
      // })

      setFilterList([...newFilterList])
      setQuery(value)
    }
  }

  const applyAssigneeFilter = (event) => {
    let value
    console.log("Assignee");

    if (!event){
      value = query
    } else {
      value = event.target.value
    }

    if (value === ""){
      setFilterList([...toDoList])
      setQuery("")
    } else {

      const newFilterList = []

      // const newFilterList = toDoList.filter(element => {
      //   return (element.assignee === value || element.taskMode === "edit")
      // })

      toDoList.forEach(element => {

        const repliesFiltered = element.replies.filter(reply => {
          return (reply.assignee === value || reply.taskMode === "edit")
        })

        if (
          (element.assignee === value) ||
          (element.assignee !== value && repliesFiltered.length > 0) ||
          (element.taskMode === "edit")
        ) {
          newFilterList.push({
            ...element,
            arrow: true,
            replies: repliesFiltered
          })

        }

      })

      setFilterList([...newFilterList])
      setQuery(value)
    }

  }

  const applyStatusFilter = (event) => {
    let value
    console.log("Status");

    if (!event){
      value = query
    } else {
      value = event.target.value
    }

    if (value === ""){
      setFilterList([...toDoList])
      setQuery("")
    } else {
      // const newFilterList = toDoList.filter(element => {
      //   return (element.status === value || element.taskMode === "edit")
      // })
      const newFilterList = []

      if (value === "Closed"){
        toDoList.forEach(element => {
          const repliesFiltered = element.replies.filter(reply => {
            return (reply.status === value || reply.taskMode === "edit")
          })

          if (element.status === "Closed" || repliesFiltered.length > 0){
            newFilterList.push({
              ...element,
              arrow: true,
            })
          }
        })

      } else {

        toDoList.forEach(element => {
  
          const repliesFiltered = element.replies.filter(reply => {
            return (reply.status === value || reply.taskMode === "edit")
          })
  
          if (
            (element.status === value) ||
            (element.status !== value && repliesFiltered.length > 0) ||
            (element.taskMode === "edit")
          ) {
            newFilterList.push({
              ...element,
              arrow: true,
              replies: repliesFiltered
            })
  
          }
  
        })

      }

      setFilterList([...newFilterList])
      setQuery(value)

    }
  }

  const applyAuthorFilter = async (event) => {
    let value
    console.log("Author");

    if (!event){
      value = query
    } else {
      value = event.target.value
    }


    if (value === ""){
      setFilterList([...toDoList])
      setQuery("")
    } else {
      // const newFilterList = toDoList.filter(element => {
      //   return (element.user.username === value || element.taskMode === "edit")
      // })

      const newFilterList = []

      toDoList.forEach(element => {

        const repliesFiltered = element.replies.filter(reply => {
          return (reply.user.username === value || reply.taskMode === "edit")
        })

        if (
          (element.user.username === value) ||
          (element.user.username !== value && repliesFiltered.length > 0) ||
          (element.taskMode === "edit")
        ) {
          newFilterList.push({
            ...element,
            arrow: true,
            replies: repliesFiltered
          })

        }

      })

      setFilterList([...newFilterList])
      setQuery(value)
    }
  }

  const applyDescriptionFilter = (event) => {

    let value

    if (!event){
      value = query
    } else {
      value = event.target.value
    }

    if (value === ""){
      setFilterList([...toDoList])
      setQuery("")
    } else {
      // const newFilterList = toDoList.filter(element => {
      //   return (element.description.toLowerCase().includes(value.toLowerCase()) || element.taskMode === "edit")
      // })
      const newFilterList = []

      toDoList.forEach(element => {

        const repliesFiltered = element.replies.filter(reply => {
            return (
              reply.description.toLowerCase().includes(value.toLowerCase()) ||
              reply.taskMode === "edit"
            )
        })

        if (
          (element.description.toLowerCase().includes(value.toLowerCase())) ||
          (!element.description.toLowerCase().includes(value.toLowerCase()) && repliesFiltered.length > 0) ||
          (element.taskMode === "edit")
        ) {
          newFilterList.push({
            ...element,
            arrow: true,
            replies: repliesFiltered
          })

        }
      })

      setFilterList([...newFilterList])
      setQuery(value)
    }
  }

  // Helpers
  const findRightFilterTaskIdx = (index) => {
    let newArr = [...toDoList]

    const id = [...filterList][index].id
    return newArr.findIndex(task => task.id === id)
  }

  const findRightFilterSubTaskIdx = (taskIdx, index, replyIndex) => {
    let newArr = [...toDoList]

    const id = [...filterList][index].replies[replyIndex].id
    return newArr[taskIdx].replies.findIndex(subTask => subTask.id === id)
  }

  const searchClosedTasks = (index) => {

    let tasks

    if (!toDoList[0].id){
      tasks = [...toDoList]
    } else {
      tasks = [...storedTasks]
    }

    if ((!tasks || tasks.length === 0) || (!tasks[index]) || (!toDoList[index].id) ){
      return false
    }

    const item = tasks[index]

    const repliesFiltered = item.replies.filter(reply => {
      return (reply.status === "Closed")
    })

    if (item.status === "Closed" || repliesFiltered.length > 0){
      return true 
    }


    return false

  }

  const shouldRenderElement = (index) => {

    try {
      if (filterMode) index = findRightFilterTaskIdx(index)

      const currTasks = [...toDoList]
      const element = currTasks[index]
  
      if (!element.id){
        return true
      }

      const savedTasks = [...storedTasks]
      const savedElemenet = savedTasks.find(task => task.id === element.id)

      if (savedElemenet.replies.length === 0 && savedElemenet.status !== "Closed"){
        return true
      }

      const repliesFiltered = savedElemenet.replies.filter(reply => reply.status === "Closed")

      if (savedElemenet.status === "Closed" || repliesFiltered.length > 0){
        return false
      }

      return true
    }catch(e){
      console.log("Error in shoulRenderElement() :");
      console.log(e);
    }


  }

  function formatPhoneNumber(phoneNumber){
    let formattedPhoneNumber = phoneNumber
    if (phoneNumber.slice(0, 2) === "+1" ){
      formattedPhoneNumber = phoneNumber.slice(2)
    }

    return ( '+1' + formattedPhoneNumber.replace(/[^0-9]/g, ''))
  }

  const ascendingTasksSort = () => {
    const tasks = [...toDoList]

    const sortedTasks = tasks.toSorted((a, b) => {
      const firstDate = new Date(a.date.replace(/,/g, ''))
      const secondDate = new Date(b.date.replace(/,/g, ''))

      return firstDate - secondDate
    })

    setToDoList([...sortedTasks])

    return sortedTasks
  }

  const descendingTasksSort = () => {
    const tasks = [...toDoList]

    const sortedTasks = tasks.toSorted((a, b) => {
      const firstDate = new Date(a.date.replace(/,/g, ''))
      const secondDate = new Date(b.date.replace(/,/g, ''))

      return secondDate - firstDate
    })

    console.log("Sorted Tasks:");
    console.log(sortedTasks);

    setToDoList([...sortedTasks])

    return sortedTasks

  }


  // Run hook only once after the render of the ToDoListComponent component occurs
  useEffect( () => {

    const getUsers = async () => {

      const users = (await getCompanyUsers(companyName)).data.data

      console.log("Users:");
      console.log(users);

      const usersList = users.map(element => {
        return {
          username: element.username,
          firstName: element.firstName,
          lastName: element.lastName,
          phoneNumber: formatPhoneNumber(element.phoneNumber),
          emailAddress: element.emailAddress
        }
      })

      console.log("Users Lists:");
      console.log(usersList);

      setUsers([...usersList])
    }

    const getTasks = async () => {

      const tasks = (await getSystemTasks(systemHandleID)).data.data[0].tasks

      if (tasks){
        setToDoList([...tasks])
        // setStoredTasks([...tasks])
        setStoredTasks(JSON.parse(JSON.stringify(tasks)));

      }
      console.log("Tasks:");
      console.log(tasks);

    }

    const obtainUser = async () => {
      const userObj = (await getUser()).data.data
      setCurrentUser(userObj)
    }

    getUsers()
    getTasks()
    obtainUser()
  }, [])

  useEffect(() => {

    // setFilterList([...toDoList])
    // setQuery("")


    if (filterMode){
      if (filterOption === ""){
        setFilterList([...toDoList])
      } else if (filterOption === "Date"){
        setFilterList([...toDoList])
        applyDateFilter(null)
      } else if (filterOption === "Subject"){
        setFilterList([...toDoList])
        applySubjectFilter(null)
      } else if (filterOption === "Assignee"){
        setFilterList([...toDoList])
        applyAssigneeFilter(null)
      } else if (filterOption === "Status"){
        setFilterList([...toDoList])
        applyStatusFilter(null)
      } else if (filterOption === "Author"){
        setFilterList([...toDoList])
        applyAuthorFilter(null)
      } else if (filterOption === "Description"){
        setFilterList([...toDoList])
        applyDescriptionFilter(null)
      }
    }

  }, [toDoList])

  useEffect(() => {

    const getAndSetFilterList = async (filterMode) => {

      const tasks = (await getSystemTasks(systemHandleID)).data.data[0].tasks

      if (tasks){
        setToDoList([...tasks])

        if (filterMode){
          setFilterList([...tasks])
        } else {
          setFilterList([])
        }
      }

      return tasks
    }

    getAndSetFilterList(filterMode)
    selectFilterOption("")
    setQuery("")

  }, [filterMode])

  useEffect(()=> {
    console.log("Filter List:");
    console.log(filterList);
  }, [filterList])

  useEffect(() => {
    const getAndSetFilterList = async (filterMode) => {

      const tasks = (await getSystemTasks(systemHandleID)).data.data[0].tasks

      if (tasks){
        setToDoList([...tasks])

        if (filterMode){
          setFilterList([...tasks])
        } else {
          setFilterList([])
        }
      }

      return tasks
    }

    getAndSetFilterList(filterMode)
    setQuery("")
  }, [filterOption])

  return (
    <>
      <div style={{
        display: "flex",
        justifyContent: "center"
      }}>
        <p className="text-center basicParagraph text-center"
          style={{
            width: "100%",
            fontSize: "12px",
            color: "#2d5c88",
            marginBottom: "30px"
           }}
        >
          Tasks
        </p>

      </div>

      {/* Bar to add a new Task */}
      <div style={{
        width: "100%",
        display: "flex",
        justifyContent: "center"
      }}>
        <div
          style={{
            height: "25px",
            width: "1000px",
            borderRadius: "5px",
            color: "#ffc000",
            backgroundColor: "#777575",
            fontWeight: "bold",
            fontSize: "11px",
            textAlign: "center",
            paddingTop: "5px",
            paddingBottom: "5px;"
          }}
        >
          <button
            className="btn btn-primary shadow-none"
            style={{
              padding: "0px",
              borderWidth: "0px",
              borderColor: "tranparent",
              marginTop: "-7px",
              color: "transparent",
              backgroundColor: "transparent",
            }}
            // disabled={filterMode ? true : false}
            onClick={()=> {
              addNewRow()
            }}
          >
            <FontAwesomeIcon
              icon="plus-circle"
              size="2x"
              color="#ffc000"
              title="Add a Task"
            />
          </button>
        </div>
      </div>

      {/* Bar to handle the filter Bars */}
      <div style={{
        position: "relative",
        overflow: "hidden",
        zIndex: "-1",
        width: "1000px",
        minHeight: "45px",
        maxHeight: `${filterOption === "Description" ? "auto" : "45px"} !important`,
        backgroundColor: "#ece9e9",
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "flex-end",
        paddingBottom: `${filterOption == "Description" ? "10px" : "0px"}`
      }}>

        <div style={{
          display: "flex",
          gap: "8px",
          height: "100%"
        }}>

          <div style={{
              paddingTop:"10px",
              display: "flex",
              flexFlow: "row nowrap",
              gap: "5px",
            }
          }>
            { filterMode && (
              <select
                className="form-control2"
                name={filterOption}
                value={filterOption}
                onChange={event => {
                  const value = event.target.value
                  selectFilterOption(value)
                }}
              >
                {filterOption === "" && (
                  <option selected value={filterOption}>Filter By</option>
                )}

                {filterOptions.map((option)=>
                  <option value={option}>{option}</option>
                )}
              </select>
            )}

            { filterOption !== "" && (
              <>
                { filterOption === "Date" && (
                  <>
                    <div className="form-group p-0 m-0">
                      <input type="date" name={filterOption} value={query} className="form-control2" id="datepicker" placeholder="Select Date"
                        onChange={(event) => applyDateFilter(event)}
                        // onClickCapture={()=> console.log("Input clicked!")}
                      />
                    </div>
                  </>
                )}

                { filterOption === "Subject" && (
                  <input style={{width: "180px", fontSize: "15px"}} value={query} type="text" className="form-control form-control-sm"
                    onChange={event=> applySubjectFilter(event)}
                    // onClickCapture={() => console.log("Input clicked!")}
                  />
                )}

                { filterOption === "Assignee" && (
                  <select
                    className="form-control2 mr-1"
                    value={query}
                    name={query}
                    onChange={event => {
                      applyAssigneeFilter(event)
                    }}
                    // onClickCapture={()=> console.log("Select clicked!")}
                  >
                    {query === "" && (
                        <option selected value={query}>Choose here</option>
                    )}

                    {users.map((user) =>
                      <>
                        <option value={user.username}>{user.username}</option>
                      </>
                    )}

                  </select>
                )}

                { filterOption === "Status" && (
                  <select
                    className="form-control2 mr-1"
                    value={query}
                    name={query}
                    onChange={event => {
                      applyStatusFilter(event)
                    }}
                    // onClickCapture={()=> console.log("Select clicked!")}
                  >
                    {query === "" && (
                        <option selected value={query}>Choose here</option>
                    )}

                    {statusesList.map((status) =>
                      <>
                        <option value={status}>{status}</option>
                      </>
                    )}

                  </select>
                )}

                { filterOption === "Author" && (
                  <select
                    className="form-control2 mr-1"
                    value={query}
                    name={query}
                    onChange={event => {
                      applyAuthorFilter(event)
                    }}
                    // onClickCapture={()=> console.log("Select clicked!")}
                  >
                    {query === "" && (
                        <option selected value={query}>Choose here</option>
                    )}

                    {users.map((user) =>
                      <>
                        <option value={user.username}>{user.username}</option>
                      </>
                    )}

                  </select>
                )}

                { filterOption === "Description" && (
                  < textarea
                    style={{width: "230px", fontSize: "15px"}}
                    value={query}
                    onChange={event=> applyDescriptionFilter(event)}
                    // onClickCapture={()=> console.log("Select clicked!")}
                    type="text" className="form-control form-control-sm"
                  />
                )}


              </>
            )}

          </div>

          <div style={{
            marginTop: "10px"
          }}>
            <button
              className="btn btn-primary shadow-none"
              style={{
                padding: "0px",
                borderWidth: "0px",
                borderColor: "tranparent",
                marginRight: "12px",
                color: "transparent",
                backgroundColor: "transparent",
              }}
              onClick={()=> {
                if (filterMode){
                  setFilterMode(false)
                  setAscSort(false)
                } else {
                  setFilterMode(true)
                  setAscSort(false)
                }
              }}
            >
              <FontAwesomeIcon
                icon={faSortAmountDown}
                size="1.2x"
                color={ filterMode ? "blue" : "grey" }
                title="Filters"
              />
            </button>
          </div>
        </div>
      </div>

      {/* TO DO LIST */}
      <div style={{
          display: "flex",
          justifyContent: "center"
        }}>

        { toDoList.length > 0 ? (
          <>
            {(filterMode && filterList.length <= 0) ? (
              <p className="text-center basicParagraph text-center"
                style={{
                  width: "100%",
                  fontSize: "12px",
                  color: "#2d5c88",
                  marginTop: "20px"
                }}
              >
                No matches found
              </p>
            ) : (
              <table
                className="table"
                style={{
                  marginTop: "20px",
                }}
              >
                <thead>
                  <tr>
                    <th style={{ margin: "0px", padding: "0px", height: "30px" }}>
                      <div className="p-0 m-0 d-flex flex-direction-row flex-wrap-nowrap align-items-end justify-content-center">
                        <p className="m-0 p-0">
                          Date
                        </p>
                        {(!filterMode) && (
                          <button
                            className="btn btn-outline-light m-0 ml-1 p-0"
                            style={{
                              display: "flex",
                              alignItems: "end",
                              borderWidth: "0px",
                            }}
                            onClick={() => {
                              if (ascSort){
                                descendingTasksSort()
                                setAscSort(false)
                              } else {
                                ascendingTasksSort()
                                setAscSort(true)
                              }
                            }}
                          >
                            { ascSort ? (

                              <FontAwesomeIcon
                                icon={faCaretUp}
                                size="sm"
                                color="gray"
                                title="Descending Order"
                                style={{ 
                                  border: "none",
                                  backgroundColor: "transparent",
                                  marginBottom: "2px"
                                }}
                              />
                            ): (
                              <FontAwesomeIcon
                                icon={faCaretDown}
                                size="sm"
                                color="gray"
                                title="Ascending order"
                                style={{ 
                                  border: "none",
                                  backgroundColor: "transparent",
                                  marginBottom: "2px"
                                }}
                              />
                            )}

                          </button>
                        )}
                      </div>
                    </th>
                    <th style={{ margin: "0px", padding: "0px", height: "30px" }}>
                      Subject
                    </th>
                    <th style={{ margin: "0px", padding: "0px", height: "30px" }}>
                      Assignee
                    </th>
                    <th style={{ margin: "0px", padding: "0px", height: "30px" }}>
                      Status
                    </th>
                    <th style={{ margin: "0px", padding: "0px", height: "30px"}} className="text-left">
                      <p className="d-inline-block mb-0 ml-3" style={{paddingBottom: "0.9px"}}>
                        Description
                      </p>
                    </th>
                    <th style={{ margin: "0px", padding: "0px", height: "30px" }}>
                      Actions
                    </th>
                  </tr>
                </thead>

                <tbody>

                  { ( filterMode ? filterList : toDoList ).map((task, index) =>
                    <>

                      {(shouldRenderElement(index) || filterMode === true && filterOption === "Status" && query === "Closed" ) && (
                        <>
                          { (task.taskMode === "edit") ? (

                            <>
                              <tr key={index}>

                                  <td className="col-1">
                                    <p>{task.date}</p>
                                  </td>

                                  <td className="col-1">
                                    <div style={{
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center"
                                    }}>
                                      <input style={{width: "120px", height: "25px"}} value={task.subject} name={`task-${index}`} onChange={(event) => assignSubject(event, index)} type="text" className="form-control form-control-sm" />
                                    </div>
                                  </td>

                                  <td className="col-1" >
                                    <select
                                      className="form-control2 mr-1"
                                      value={task.assignee}
                                      name={task.assignee}
                                      onChange={event => {
                                        assignUser(event, index, "task")
                                      }}
                                      style={{ fontSize: "12px", width:"110px", fontWeight: "bold", color:"#784feb" }}
                                    >
                                      {task.assignee === "" && (
                                          <option selected value={task.assignee}>Choose here</option>
                                      )}

                                      {users.map((user) =>
                                        <>
                                          {((user.username === task.assignee) && (task.assignee !== "")) ? (

                                            <option selected value={user.username}>{user.firstName} {user.lastName}</option>

                                          ) : (

                                            <option value={user.username}>{user.firstName} {user.lastName}</option>

                                          ) }
                                        </>
                                      )}

                                    </select>
                                  </td>

                                  <td className="col-1">
                                    <select
                                      className="form-control2 mr-1"
                                      value={task.status}
                                      name={task.status}
                                      onChange={event => {
                                        assignStatus(event, index, "task")
                                      }}
                                      style={{ fontSize: "12px", width:"110px", fontWeight: "bold", color:"#784feb" }}
                                    >

                                      {task.status === "" && (
                                        <option selected value={task.status}>Choose here</option>
                                      )}

                                      {statusesList.map((status) =>
                                        <>
                                          {((status === task.status) && (task.status !== "")) ? (

                                            <option selected value={status}>{status}</option>

                                          ) : (

                                            <option value={status}>{status}</option>

                                          ) }
                                        </>
                                      )}

                                    </select>
                                  </td>

                                  <td className="col-7 text-left">
                                    <p style={{marginLeft: "1px", fontSize: "9px", color:"red"}}>Authored By {task.user.username}</p>
                                    <textarea value={
                                        task.description
                                      }
                                      name={`taskDescription-${index}`}
                                      onChange={(event) => assignDescription(event, index, "task")}
                                      className="form-control form-control-sm"
                                    />
                                  </td>

                                  <td className="col-1">
                                    <div style={{
                                      flexFlow: "row nowrap",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      width: "100%",
                                      height: "100%"
                                    }}>
                                      <button
                                        className="btn btn-outline-light"
                                        style={{
                                          padding: "0px",
                                          borderWidth: "0px",
                                          marginRight: "5px"
                                        }}
                                        onClick={() => {
                                          let newArr = [...toDoList]

                                          if (newArr.length !== filterList.length && filterMode){
                                            const id = [...filterList][index].id
                                            const idx = newArr.findIndex(task => task.id === id)

                                            newArr[idx].taskMode = "saved"
                                            const obj = newArr[idx]

                                            saveTask(idx)
                                            removeEditableObj(obj)

                                          } else {

                                            newArr[index].taskMode = "saved"
                                            const obj = newArr[index]

                                            // saveTasks(newArr, index)
                                            saveTask(index)
                                            removeEditableObj(obj)
                                          }


                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faCheck}
                                          size="sm"
                                          color="green"
                                          title="Save"
                                        />
                                      </button>
                                      <button
                                        className="btn btn-outline-light"
                                        style={{
                                          padding: "0px",
                                          borderWidth: "0px",
                                        }}
                                        onClick={event => {
                                          let newArr = [...toDoList]

                                          if (newArr.length !== filterList.length && filterMode){
                                            const id = [...filterList][index].id
                                            const idx = newArr.findIndex(task => task.id === id)

                                            cancelSave(idx)
                                          } else {
                                            cancelSave(index)
                                          }
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faTimes}
                                          size="sm"
                                          color="red"
                                          title="cancel"
                                        />
                                      </button>

                                      {
                                        task.id &&
                                        task.subject !== "" &&
                                        task.assignee !== "" &&
                                        task.status !== "" &&
                                        (
                                          task.description !== "Please add a task description" &&
                                          task.description !== ""
                                        ) && (
                                        <button
                                          className="btn btn-outline-light"
                                          style={{
                                            padding: "0px 0px 0px 4px",
                                            borderWidth: "0px",
                                          }}
                                          // disabled={filterMode ? true : false}
                                          onClick={event => {

                                            let newArr = [...toDoList]

                                            if (newArr.length !== filterList.length && filterMode){
                                              const id = [...filterList][index].id
                                              const idx = newArr.findIndex(task => task.id === id)

                                              addNewSubTask(idx)
                                            } else {
                                              addNewSubTask(index)

                                            }

                                            if (!task.arrow){
                                              let newArr = [...toDoList]
                                              newArr.arrow = true

                                              setToDoList(newArr)
                                            }
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faReply}
                                            size="sm"
                                            color="dodgerblue"
                                            title="Reply"
                                          />
                                        </button>
                                      )}
                                    </div>
                                  </td>
                              </tr>

                            </>

                          ) : (

                            <>
                              <tr>

                                <td className="col-1">
                                  <p>
                                    {task.date}
                                  </p>
                                </td>

                                <td className="col-1">

                                  <div style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                  }}>
                                    <p style={{width: "120px"}}>
                                      {task.subject}
                                    </p>

                                  </div>

                                </td>

                                <td className="col-1">
                                  <p>
                                    {task.assignee}
                                  </p>
                                </td>

                                <td className="col-1">
                                  <p>
                                    {task.status}
                                  </p>
                                </td>

                                <td className="col-7 text-left">
                                  <p style={{marginLeft: "1px", fontSize: "9px", color:"red"}}>
                                    Authored By {task.user.username}
                                  </p>
                                  <p className="d-inline-block" style={{marginLeft: "1px", fontSize: "10px"}}>
                                    {task.description}
                                  </p>
                                </td>

                                <td className="col-1">
                                  <div style={{
                                    display: "flex",
                                    flexFlow: "row nowrap",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "5px",
                                    height: "100%"
                                  }}>

                                    <button
                                      className="btn btn-outline-light"
                                      style={{
                                        padding: "0px",
                                        borderWidth: "0px",
                                        marginRight: `${task.replies.length > 0 ? "0px" : "20px"}`
                                      }}
                                      onClick={() => {
                                        let newArr = [...toDoList]

                                        if (newArr.length !== filterList.length && filterMode){
                                          const id = [...filterList][index].id
                                          const idx = newArr.findIndex(task => task.id === id)

                                          newArr[idx].taskMode = "edit"
                                          addEditableObj(idx)

                                        } else {
                                          newArr[index].taskMode = "edit"
                                          addEditableObj(index)
                                        }

                                        setToDoList(newArr)
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon="edit"
                                        size="sm"
                                        color="green"
                                        title="Edit"
                                      />

                                    </button>

                                    {task.replies.length > 0 && (

                                      <>
                                        { (task.arrow === true) ? (
                                          <button
                                            className="btn btn-outline-light"
                                            style={{
                                              padding: "0px",
                                              borderWidth: "0px",
                                            }}
                                            onClick={() => {

                                              if (filterList.length !== toDoList.length && filterMode){
                                                const idx = findRightFilterTaskIdx(index)
                                                changeArrowIcon(idx, false)
                                              } else {
                                                changeArrowIcon(index, false)
                                              }
                                            }}
                                          >
                                              <FontAwesomeIcon
                                                icon={faCaretUp}
                                                size="md"
                                                color="#ffc000"
                                                title="asc-order"
                                                style={{
                                                  marginRight: "5px",
                                                  border: "none",
                                                  backgroundColor: "transparent"
                                                }}
                                              />

                                          </button>
                                        ) : (
                                          <button
                                            className="btn btn-outline-light"
                                            style={{
                                              padding: "0px",
                                              borderWidth: "0px",
                                            }}
                                            onClick={() => {
                                              if (filterList.length !== toDoList.length && filterMode){
                                                const idx = findRightFilterTaskIdx(index)
                                                changeArrowIcon(idx, true)
                                              } else {
                                                changeArrowIcon(index, true)
                                              }
                                            }}
                                          >
                                              <FontAwesomeIcon
                                                icon={faCaretDown}
                                                size="md"
                                                color="#ffc000"
                                                title="asc-order"
                                                style={{
                                                  marginRight: "5px",
                                                  border: "none",
                                                  backgroundColor: "transparent"
                                                }}
                                              />

                                          </button>
                                        )}

                                      </>

                                    )}

                                  </div>
                                </td>

                              </tr>

                            </>
                          )}

                          { (task.replies.length > 0  && task.arrow) && (
                            <>
                              {task.replies.map((reply, replyIndex)=>
                                <>
                                  {(reply.taskMode === "edit") ? (
                                    <>
                                      <tr className={"table-secondary"} >

                                        <td className="col-1">
                                          <p>{reply.date}</p>
                                        </td>

                                        <td className="col-1">
                                          <div style={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                          }}>
                                            <p style={{width: "120px"}}>
                                              {task.subject}
                                            </p>

                                          </div>

                                        </td>

                                        <td className="col-1">
                                          <select
                                            className="form-control2 mr-1"
                                            value={reply.assignee}
                                            name={reply.assignee}
                                            onChange={event => {
                                              assignUser(event, index, replyIndex)
                                            }}
                                            style={{ fontSize: "12px", width:"110px", fontWeight: "bold", color:"#784feb" }}
                                          >
                                            {reply.assignee === "" && (
                                                <option selected value={reply.assignee}>Choose here</option>
                                            )}

                                            {users.map((user) =>
                                              <>
                                                {((user.username === reply.assignee) && (reply.assignee !== "")) ? (

                                                  <option selected value={user.username}>{user.firstName} {user.lastName}</option>

                                                ) : (

                                                  <option value={user.username}>{user.firstName} {user.lastName}</option>

                                                ) }
                                              </>
                                            )}

                                          </select>
                                        </td>

                                        <td className="col-1">
                                          <select
                                            className="form-control2 mr-1"
                                            value={reply.status}
                                            name={reply.status}
                                            onChange={event => {
                                              assignStatus(event, index, replyIndex)
                                            }}
                                            style={{ fontSize: "12px", width:"110px", fontWeight: "bold", color:"#784feb" }}
                                          >

                                            {reply.status === "" && (
                                              <option selected value={reply.status}>Choose here</option>
                                            )}

                                            {statusesList.map((status) =>
                                              <>
                                                {((status === reply.status) && (reply.status !== "")) ? (
                                                  <option selected value={status}>{status}</option>
                                                ) : (
                                                  <option value={status}>{status}</option>
                                                ) }
                                              </>
                                            )}

                                          </select>
                                        </td>

                                        <td className="col-7 text-left">
                                          <p style={{marginLeft: "1px", fontSize: "9px", color:"#1E90FF"}}>Replied By {reply.user.username}</p>
                                          <textarea value={
                                              reply.description
                                            }
                                            name={`${reply.id ? "replyDescription-" + reply.id : "replyDescription-" + index + "-" + replyIndex}`}
                                            onChange={(event) => assignDescription(event, index, replyIndex)}
                                            className="form-control form-control-sm"
                                          />
                                        </td>

                                        <td className="col-1">
                                          <div style={{
                                            flexFlow: "row nowrap",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: "5px",
                                            width: "100%",
                                            height: "100%"
                                          }}>
                                            <button
                                              className="btn btn-outline-light shadow-none"
                                              style={{
                                                padding: "0px",
                                                borderWidth: "0px",
                                                marginRight: "5px"
                                              }}
                                              onClick={() => {
                                                let newArr = [...toDoList]

                                                if (newArr.length !== filterList.length && filterMode){
                                                  const taskIdx = findRightFilterTaskIdx(index)
                                                  const replyIdx = findRightFilterSubTaskIdx(taskIdx, index, replyIndex)

                                                  newArr[taskIdx].replies[replyIdx].taskMode = "saved"
                                                  saveReply(taskIdx, replyIdx)

                                                } else {
                                                  newArr[index].replies[replyIndex].taskMode = "saved"
                                                  saveReply(index, replyIndex)

                                                }
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                icon={faCheck}
                                                size="sm"
                                                color="green"
                                                title="Save"
                                              />
                                            </button>
                                            <button
                                              className="btn btn-outline-light shadow-none"
                                              style={{
                                                padding: "0px",
                                                borderWidth: "0px",
                                                outline: "none"
                                              }}
                                              onClick={event => {
                                                let newArr = [...toDoList]

                                                if (newArr.length !== filterList.length && filterMode){
                                                  const taskIdx = findRightFilterTaskIdx(index)
                                                  const replyIdx = findRightFilterSubTaskIdx(taskIdx, index, replyIndex)

                                                  cancelSubTask(taskIdx, replyIdx)

                                                } else {

                                                  cancelSubTask(index, replyIndex)
                                                }

                                              }}
                                            >
                                              <FontAwesomeIcon
                                                icon={faTimes}
                                                size="sm"
                                                color="red"
                                                title="cancel"
                                              />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  ) : (
                                    <>
                                      <tr className={"table-secondary"} >

                                        <td className="col-1">
                                          <p>{reply.date}</p>
                                        </td>

                                        <td className="col-1">

                                          <div style={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                          }}>
                                            <p style={{width: "120px"}}>
                                              {task.subject}
                                            </p>

                                          </div>
                                        </td>

                                        <td className="col-1">
                                          <p>
                                            {reply.assignee}
                                          </p>
                                        </td>

                                        <td className="col-1">
                                          <p>
                                            {reply.status}
                                          </p>
                                        </td>

                                        <td className="col-7 text-left">
                                          <p style={{marginLeft: "1px", fontSize: "9px", color:"#1E90FF"}}>
                                            Replied By {reply.user.username}
                                          </p>
                                          <p className="d-inline-block ml-1">
                                            {reply.description}
                                          </p>
                                        </td>

                                        <td className="col-1">
                                          <div style={{
                                            flexFlow: "column wrap",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100%"
                                          }}>

                                            <button
                                              className="btn btn-outline-light shadow-none"
                                              style={{
                                                padding: "0px",
                                                borderWidth: "0px",
                                                marginRight: "20px"
                                              }}
                                              onClick={() => {
                                                let newArr = [...toDoList]

                                                if (newArr.length !== filterList.length && filterMode){
                                                  const taskIdx = findRightFilterTaskIdx(index)
                                                  const replyIdx = findRightFilterSubTaskIdx(taskIdx, index, replyIndex)

                                                  if (newArr[taskIdx].replies.length !== filterList[index].replies.length){
                                                  } else {
                                                  }

                                                  console.log("Element in List:");
                                                  console.log(toDoList[taskIdx].replies[replyIdx]);

                                                  newArr[taskIdx].replies[replyIdx].taskMode = "edit"

                                                } else {
                                                  newArr[index].replies[replyIndex].taskMode = "edit"
                                                }

                                                setToDoList(newArr)

                                              }}
                                            >
                                              <FontAwesomeIcon
                                                icon="edit"
                                                size="sm"
                                                color="green"
                                                title="Edit"
                                              />

                                            </button>

                                          </div>
                                        </td>

                                      </tr>

                                    </>
                                  )}
                                </>
                              )}
                            </>
                          ) }
                        </>
                      )}

                    </>

                  )}

                </tbody>

              </table>
            )}
          </>


        ) : (
          <p className="text-center basicParagraph text-center"
            style={{
              width: "100%",
              fontSize: "12px",
              color: "#2d5c88",
              marginTop: "20px"
            }}
          >
            No tasks found
          </p>
        )}
      </div>
    </>
  )
}

export default SystemHistory;
