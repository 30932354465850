import React, { Component } from "react";

import * as echarts from "echarts/dist/echarts.js";
import "./dashboard.css";

class HistoryBarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base: new Date(),
      oneDay: 24 * 3600 * 1000,
      date: [],
      pvData: [],
      batteryData: [],
      renewableData: [],
      now: new Date()
    };
  }

  componentDidMount() {
    this.chart = echarts.init(document.getElementById("history-bar-chart"));
    this.generateGraph();
  }

  componentWillUnmount() {}

  generateGraph() {
    var option = {
      tooltip: {},
      grid: [
        {
          x: "3%",
          y: "10%",
          width: "90%",
          height: "70%",
          containLabel: true
        }
      ],
      title: [
        {
          text: "",
          textStyle: {
            fontFamily: "Lato,Helvetica Neue,Arial,Helvetica,sans-serif",
            color: "grey",
            fontSize: 16,
            fontWeight: "lighter"
          },
          x: "50%",
          y: "2%",
          textAlign: "center"
        }
      ],
      xAxis: [
        {
          gridIndex: 0,
          top: 50,
          width: "50%",
          bottom: "70%",
          left: 10,
          containLabel: true
        }
      ],
      yAxis: [
        {
          gridIndex: 0,
          textStyle: {
            fontFamily: "Lato,Helvetica Neue,Arial,Helvetica,sans-serif",
            color: "grey",
            fontWeight: "lighter"
          },
          type: "category",
          data: [
            "Off Peak",
            "Mid Peak",
            "On Peak",
            "Off Peak",
            "Mid Peak",
            "On Peak",
            "Taxes",
            "Meter Fee"
          ],
          axisLabel: {
            interval: 0,
            rotate: 30,
            align: "right"
          },
          splitLine: {
            show: false
          }
        }
      ],
      series: [
        {
          type: "bar",
          stack: "fixed",
          xAxisIndex: 0,
          yAxisIndex: 0,
          data: [
            { value: 10.12, name: "Meter Cost", itemStyle: { color: "Grey" } },
            { value: 19.36, name: "Taxes", itemStyle: { color: "DimGrey" } },
            {
              value: 54,
              name: "On-Peak kW",
              itemStyle: { color: "DodgerBlue" }
            },
            {
              value: 31,
              name: "Mid-Peak kW",
              itemStyle: { color: "CornflowerBlue" }
            },
            {
              value: 13.6,
              name: "Off-Peak kW",
              itemStyle: { color: "RoyalBlue" }
            },
            {
              value: 15.12,
              name: "On-Peak kWh",
              itemStyle: { color: "Orange" }
            },
            {
              value: 39.36,
              name: "Mid-Peak kWh",
              itemStyle: { color: "DarkOrange" }
            },
            {
              value: 105.45,
              name: "Off-Peak kWh",
              itemStyle: { color: "OrangeRed" }
            }
          ]
        }
      ]
    };

    // use configuration item and data specified to show chart
    this.chart.setOption(option);
  }

  render() {
    return <div id="history-bar-chart" />;
  }
}
export default HistoryBarChart;
