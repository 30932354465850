import React, { Component } from "react";
import { toast } from "react-toastify";
import { addOwner } from "../services/userService";
import {
  findSystemBySerialNumber,
  getSystem,
  getSystemInfo,
} from "../services/systemService";
import { setJwt } from "../services/httpService";
import { getUser, getUserByUsername } from "../services/userService";
import { getCompanyUsers } from "../services/companyService";
import CommForm from "./sfCommission";
import BackGroundRectangle from "../components/backgroundRect";

class InstallBoard extends Component {
  state = {
    systemId: "",
    serialNumber: "",
    macAddress: "",
    message: "",
    status: "",
    handleId: "",
    systems: [],
  };

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    const loggedInUser = (await getUser()).data.data;
    console.log("Logged in user's Info:", loggedInUser);
    const userIsAdmin = loggedInUser.admin;
    let userCompany = loggedInUser.company;
    let userName = loggedInUser.username;
    console.log("Use Name:", userName);
    console.log("Is User Admin", userIsAdmin);
    console.log("User's Company: ", userCompany);
    console.log("params", this.props.match.params);

    // New stuff
    const allUsers = (await getCompanyUsers(userCompany)).data.data;
    
    const adminUser = allUsers.filter((user) => { return user.admin && !user.subadmin});
    userName = adminUser[0].username;
    
    if (userName === "SGSuperUser") {
      userCompany = this.props.match.params.companyName;
      console.log("User test", userCompany);
    }
    console.log("Admin username", userName);
    this.setState({ userCompany, userName });
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const systemId = (await findSystemBySerialNumber(this.state.serialNumber))
      .data.data.system_id;
    console.log("Found Master Board System ID", systemId);

    const gottenSystem = await getSystem(systemId);
    console.log("snBoardForm: Gotten System", gottenSystem);

    if (systemId !== null) {
      const systemInfo = (await getSystem(systemId)).data.data[0].systemID;
      console.log("System", systemInfo);

      const macAddress = systemInfo.MAC;
      const handleId = systemInfo.id;
      const serialNumber = systemInfo.SN;

      const info = await getSystemInfo(handleId);
      const status = info.data.data.status;
      console.log("Status", status);

      this.setState({ status, macAddress, handleId, systemId, serialNumber });
      this.setState({ message: "This serial number is valid." });
    } else toast.error("Error: Invalid serial number.");
  };

  handleAddAdminUser = async (e) => {
    e.preventDefault();
    const res = await addOwner(this.state.userName, this.state.serialNumber, this.state.userCompany);
    console.log("Add owner res", res);
    toast.success("SimplGateway Installed.");
    window.location = `/Companies/systems/${this.state.userCompany}`;
  };

  handleChange = (e) => {
    //  const serialNumber = { ...this.state.serialNumber };
    const serialNumber = e.currentTarget.value;
    this.setState({ serialNumber });
  };

  render() {
    const status = this.state.status;
    if (status === "INSTALLED" || status === "ALIVE") {
      return (
        <React.Fragment>
          <div
            className="form-group"
            style={{
              width: "350px",
              position: "fixed",
              left: "50%",
              transform: "translateX(-50%)",
              marginTop: "150px",
              marginBottom: "150px",
              zIndex: 1,
            }}
          >
            <p className="basicParagraph">This gateway has been found.</p>
            <p className="basicParagraph">
              Click to install the gateway
            </p>
            <button
              className="btn btn-info btn-sm"
              onClick={this.handleAddAdminUser}
            >
              Install
            </button>
          </div>
          <BackGroundRectangle />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div
          className="form-group"
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            zIndex: 1,
          }}
        >
          <form onSubmit={this.handleSubmit}>
            <p className="basicParagraph">Install a new System.</p>
            <hr />
            <label className="basicParagraph" htmlFor="">
              Input serial number for SimplGateway:
            </label>
            <input
              value={this.state.serialNumber}
              onChange={this.handleChange}
              id="serialNumber"
              type="text"
              className="form-control2"
            />

            <button
              className="basicButton"
              style={{ width: "200px", marginTop: "10px" }}
            >
              Search for gateway
            </button>
          </form>
          <p />
          {this.state.message !== "" && (
            <div>
              <p className="message text-danger">
                {this.state.message} {this.state.macAddress}
              </p>
              <CommForm
                systemId={this.state.systemId}
                serialNumber={this.state.serialNumber}
                macAddress={this.state.macAddress}
              />
            </div>
          )}
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default InstallBoard;
