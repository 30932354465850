import React from "react";
import Joi from "joi-browser";
import { setJwt } from "../services/httpService";
import { masterCompany } from "../config.json";
import Form from "./common/form";
import {
  updateCompanyContact,
  getCompanies,
  renameCompany,
} from "../services/companyService";
import BackGroundRectangle from "../components/backgroundRect";

class EditCompany extends Form {
  state = {
    data: {
      companyName: "",
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      companyAddress: "",
    },
    errors: {},
  };

  schema = {
    companyName: Joi.string().required().label("Company Name"),
    contactName: Joi.string().required().label("Contact Name"),
    contactEmail: Joi.string().required().label("Contact Email Address"),
    contactPhone: Joi.string().required().label("Contact Phone Number"),
    companyAddress: Joi.string().required().label("Contact Address"),
  };

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    const getCompaniesInfo = await getCompanies();
    console.log("Get Companies:", getCompaniesInfo);
    const companies = getCompaniesInfo.data.data;
    const company = companies.filter(
      (thisCompany) =>
        thisCompany.companyName === this.props.match.params.companyName
    );
    console.log("Company", company);
    let newState = Object.assign({}, this.state.data);
    newState.companyName = company[0].companyName;
    if (company[0].contactInfo) {
      newState.contactName = company[0].contactInfo.contactName;
      newState.contactEmail = company[0].contactInfo.contactEmail;
      newState.contactPhone = company[0].contactInfo.contactPhone;
      newState.companyAddress = company[0].contactInfo.companyAddress;
    }
    this.setState({ data: newState });
  }

  doSubmit = async () => {
    const jwt = localStorage.getItem("token");
    setJwt(jwt);

    if (this.state.data.companyName !== this.props.match.params.companyName) {
      console.log("Current name is ", this.props.match.params.companyName);
      console.log("New Name is ", this.state.data.companyName);
      await renameCompany(
        this.state.data.companyName,
        this.props.match.params.companyName
      );
      let newState = Object.assign({}, this.state.data);
      newState.companyName = this.state.data.companyName;
      this.setState({ data: newState });
    }
    console.log("Company Name after Rename", this.state.data.companyName);
    await updateCompanyContact(
      this.state.data.companyName,
      this.state.data.contactName,
      this.state.data.contactEmail,
      this.state.data.contactPhone,
      this.state.data.companyAddress
    );

    await getCompanies();
    this.props.history.push("/companies");
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1,
          }}
        >
          <p className="basicParagraph">
            Update company name and contact information: You must be logged in
            as
            {masterCompany}'s Admin.
          </p>
          <form onSubmit={this.handleSubmit}>
            {this.renderInput("companyName", "Company Name")}
            {this.renderInput("contactName", "Contact Name")}
            {this.renderInput("contactEmail", "Contact Email Address")}
            {this.renderInput("contactPhone", "Contact Phone Number")}
            {this.renderInput("companyAddress", "Contact Mailing Address")}
            {this.renderButton("Update Company")}
          </form>
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default EditCompany;
