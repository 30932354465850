import React, { Component } from "react";

import "./dashboard.css";
import "../index.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import "react-datepicker/dist/react-datepicker.css";

function EnergyIndepProg(props) {
  let energyIndependence = (
    ((props.solarPowerProgress + props.battPowerProgress) * 100.0) /
    (props.solarPowerProgress +
      props.gridPowerProgress +
      props.battPowerProgress)
  ).toFixed(0);
  if (energyIndependence < 0.01) energyIndependence = 0.0;
  return (
    <React.Fragment>
      <ProgressBar
        style={{
          width: "85%",
          height: "5px",
          marginTop: "3px",
          transform: "translate(20px,0px)",
          boxShadow: "3px 5px 2px rgb(0 0 0 / 0.4)",
          zIndex: 2,
        }}
      >
        <ProgressBar
          className="orange-progress-bar"
          now={props.gridPowerProgress}
          key={1}
        />
        <ProgressBar variant="warning" now={props.solarPowerProgress} key={2} />
        <ProgressBar now={props.battPowerProgress} key={3} />
      </ProgressBar>
      <p
        className="basicParagraph"
        style={{
          fontSize: "12px",
          color: "tomato",
          marginTop: "3px",
          transform: "translate(220px,0px)",
          fontStyle: "italic",
        }}
      >
        eI: {energyIndependence}%
      </p>
    </React.Fragment>
  );
}
export default EnergyIndepProg;
