import React, { Component } from "react";

import "./dashboard.css";
import "../index.css";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";

class CostNSavingsModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <>
        <button
          className="btn btn-outline-light"
          style={{
            borderWidth: "0px",
            padding: "0px",
            float: "right",
            marginRight: "5px",
            position: "relative",
            right: "0px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="dollar-sign"
            size="sm"
            color="forestgreen"
            title="Tariff Data"
          />
        </button>

        <Modal
          style={{ width: "400px", alignContent: "center" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "14px", fontWeight: "600" }}
            >
              Usage, Savings and Net Costs for the Selected Period:
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th>Usage</th>
                  <th>Savings $ (%)</th>
                  <th>Net Costs</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ color: "tomato", lineHeight: "3px" }}>
                  <td style={{ textAlign: "left" }}>Energy:</td>
                  <td>${this.props.bldgEnergyValue}</td>
                  <td>
                    ${this.props.savingsEnergyValue}{" "}
                    <span
                      style={{
                        fontSize: "9px",
                        textAlign: "left",
                      }}
                    >
                      (%{this.props.percentEnergySavings})
                    </span>
                  </td>
                  <td>${this.props.gridEnergyValue}</td>
                </tr>
                <tr style={{ color: "orange", lineHeight: "3px" }}>
                  <td style={{ textAlign: "left" }}>Demand:</td>
                  <td>${this.props.bldgMaxDemand}</td>
                  <td>
                    ${this.props.demandSavings}{" "}
                    <span
                      style={{
                        fontSize: "9px",
                        textAlign: "left",
                      }}
                    >
                      (%{this.props.percentDemandSavings})
                    </span>
                  </td>
                  <td>${this.props.gridMaxDemand}</td>
                </tr>
                <tr style={{ color: "magenta", lineHeight: "3px" }}>
                  <td style={{ textAlign: "left" }}>Totals:</td>
                  <td>${this.props.totalUsage}</td>
                  <td>
                    ${this.props.totalSavings}{" "}
                    <span
                      style={{
                        fontSize: "9px",
                        textAlign: "left",
                      }}
                    >
                      (%{this.props.percentTotalSavings})
                    </span>
                  </td>
                  <td>${this.props.netCosts}</td>
                </tr>
              </tbody>
            </table>
            <hr></hr>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "left",
                marginBottom: "2px",
              }}
            >
              Energy Summary for This Period:
            </p>
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th>kWh</th>
                  <th>$</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ color: "green", lineHeight: "3px" }}>
                  <td style={{ textAlign: "left" }}>Building Consumption</td>
                  <td>{this.props.bldgIntervalTotal}</td>
                  <td>${this.props.bldgEnergyValue}</td>
                </tr>
                <tr style={{ color: "orange", lineHeight: "3px" }}>
                  <td style={{ textAlign: "right" }}>Solar Generation</td>
                  <td>{this.props.pvIntervalTotal}</td>
                  <td>${this.props.pvEnergyValue}</td>
                </tr>
                <tr style={{ color: "dodgerblue", lineHeight: "3px" }}>
                  <td style={{ textAlign: "right" }}>Battery Contribution</td>
                  <td>{this.props.battIntervalTotal}</td>
                  <td>${this.props.battEnergyValue}</td>
                </tr>
                <tr style={{ color: "purple", lineHeight: "3px" }}>
                  <td style={{ textAlign: "right" }}>Total Energy Savings</td>
                  <td>{this.props.savingsIntervalTotal}</td>
                  <td>${this.props.savingsEnergyValue}</td>
                </tr>
                <tr style={{ color: "magenta", lineHeight: "3px" }}>
                  <td style={{ textAlign: "left" }}>
                    Net Energy: Grid Consumption
                  </td>
                  <td>{this.props.gridIntervalTotal}</td>
                  <td>${this.props.gridEnergyValue}</td>
                </tr>
              </tbody>
            </table>
            <hr></hr>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "left",
                marginBottom: "2px",
              }}
            >
              Demand Summary for This Period:
            </p>
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th>kW</th>
                  <th>$</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style={{
                    color: "green",
                    lineHeight: "3px",
                  }}
                >
                  <td style={{ textAlign: "left" }}> Building Max Demand</td>
                  <td>{this.props.bldgMaxKW}</td>
                  <td>${this.props.bldgMaxDemand}</td>
                </tr>

                <tr
                  style={{
                    color: "purple",
                    lineHeight: "3px",
                  }}
                >
                  <td style={{ textAlign: "right" }}> Net Demand Savings:</td>
                  <td></td>
                  <td>${this.props.demandSavings}</td>
                </tr>
                <tr
                  style={{
                    color: "magenta",
                    lineHeight: "3px",
                  }}
                >
                  <td style={{ textAlign: "left" }}> Grid Max Demand</td>
                  <td>{this.props.gridMaxKW}</td>
                  <td>${this.props.gridMaxDemand}</td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default CostNSavingsModal;
