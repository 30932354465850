import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getAllUsers, restoreUser } from "../services/userService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setJwt } from "../services/httpService";
import { getCompanyUsers, getUserHistory } from "../services/companyService";
import Modal from "react-bootstrap/Modal";
import { masterCompany } from "../config.json";
import BackGroundRectangle from "../components/backgroundRect";
import { toast } from "react-toastify";
import ReactExport from "react-data-export";
import { ScrollView } from "@cantonjs/react-scroll-view";

class CompanyUsers extends Component {
  state = {
    companyUsers: [],
    allUsers: [],
    message: "",
    filteredUser: [],
  };

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    setJwt(jwt);

    const allUsers = await getAllUsers();
    const companyUsers = await getCompanyUsers(this.props.match.params.id);
    console.log("Props: ", this.props.match.params.id);

    this.setState({ companyUsers: companyUsers.data.data });
    this.setState({ allUsers: allUsers.data.data });

    let usersLastlogin;
    let userHistory = await getUserHistory(this.props.match.params.id);
    this.setState({ userHistory: userHistory.data.history });

    if (userHistory.data.history.user_history) {
      usersLastlogin = userHistory.data.history.user_history.map((history) => {
        return this.findLastLogin(history.username);
      });

      this.state.companyUsers.forEach((user) => {
        usersLastlogin.forEach((history) => {
          if (user.username === history.username) {
            user.lastLogin = history.date;
          }
        });
      });
      this.setState(companyUsers);
    }
  }

  handleReRegister = async (e) => {
    e.preventDefault();
    const userNameReRegister = this.state.userNameReRegister;
    await restoreUser(userNameReRegister);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const searchMethod = this.state.searchMethod;
    const allUsers = (await getAllUsers()).data.data;
    let filteredUser = [];
    if (this.state.searchField === "*") filteredUser = allUsers;
    else if (searchMethod === "by lastname")
      filteredUser = allUsers.filter(
        (thisUser) => thisUser.lastName === this.state.searchField
      );
    else if (searchMethod === "by firstname")
      filteredUser = allUsers.filter(
        (thisUser) => thisUser.firstName === this.state.searchField
      );
    else if (searchMethod === "by email")
      filteredUser = allUsers.filter(
        (thisUser) => thisUser.emailAddress === this.state.searchField
      );
    else if (searchMethod === "by username")
      filteredUser = allUsers.filter(
        (thisUser) => thisUser.username === this.state.searchField
      );
    if (filteredUser.length === 0) {
      this.setState({ message: "Error: User not found." });
      toast.error("Error: User not found.");
    } else {
      this.setState({
        message: "User(s) were found.",
      });
      this.setState({ filteredUser });
      toast.success("Success: Users found.");
      let excelDataSet = [0];
      for (let i = 0; i < filteredUser.length; i++) {
        excelDataSet[i] = JSON.parse(
          JSON.stringify({
            userName: filteredUser[i].username,
            firstName: filteredUser[i].firstName,
            lastName: filteredUser[i].lastName,
            email: filteredUser[i].emailAddress,
            phone: filteredUser[i].phoneNumber,
            admin: filteredUser[i].admin,
            verified: filteredUser[i].verified,
            company: filteredUser[i].company,
            updated: filteredUser[i].updated_at,
            created: filteredUser[i].created_at,
          })
        );
      }
      console.log("Excel Data JSON", excelDataSet);
      this.setState({ excelDataSet });
    }
  };

  handleSearchMethod = (e) => {
    const searchMethod = e.currentTarget.value;
    this.setState({ searchMethod });
  };
  handleChange = (e) => {
    const searchField = e.currentTarget.value;
    this.setState({ searchField });
  };

  handleChangeUserName = (e) => {
    const userNameReRegister = e.currentTarget.value;
    this.setState({ userNameReRegister });
  };
  // Find user's last login from the user history in companies table
  findLastLogin = (username) => {
    let userLastLogin;
    if (this.state.userHistory) {
      userLastLogin = this.state.userHistory.user_history.find(
        (history) => username === history.username
      );
      if (userLastLogin) {
        return userLastLogin;
      }
    }
  };

  render() {
    const userCount = this.state.companyUsers.length;
    const companyName = this.props.match.params.id;
    if (userCount === 0)
      return (
        <React.Fragment>
          <div
            style={{
              width: "350px",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              marginTop: "150px",
              marginBottom: "150px",
              zIndex: 1,
            }}
          >
            <p className="basicParagraph">
              {" "}
              {this.props.match.params.id} does not have an assigned Admin.
            </p>
            <p className="basicParagraph">
              {" "}
              Please ask your {masterCompany} representative to assign an Admin
              for {this.props.match.params.id}.
            </p>
          </div>
          {/* <BackGroundRectangle />*/}
        </React.Fragment>
      );
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    return (
      <React.Fragment>
        <div
          style={{
            width: "600px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1,
          }}
        >
          <table className="table">
            <thead>
              <tr>
                <td>{this.props.match.params.id}</td>
              </tr>
              {/*   <tr>
              <td>
                {" "}
                Add a registered user
                <Link style={{ marginLeft: "20px" }} to="/users/updateCompany">
                  <FontAwesomeIcon
                    icon="plus-circle"
                    size="lg"
                    color="tomato"
                    title="Register a new system"
                  />
                </Link>
              </td>
         </tr>*/}
            </thead>
          </table>

          <table className="table">
            <thead>
              <tr>
                <th>Username</th>
                <th>Name</th>
                <th>Admin</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {this.state.companyUsers.map((user) => (
                <tr key={user.username}>
                  <td>{user.username}</td>
                  <td>
                    {user.firstName} {user.lastName}
                  </td>
                  <td>{user.admin && "Yes"}</td>
                  <td>
                    <UserInfoModal
                      lastLogin={user.lastLogin}
                      username={user.username}
                      firstName={user.firstName}
                      lastName={user.lastName}
                      emailAddress={user.emailAddress}
                      phoneNumber={user.phoneNumber}
                      admin={user.admin}
                      verified={user.verified}
                      created_at={user.created_at}
                      updated_at={user.updated_at}
                    />
                  </td>
                  {/*       <td>
                  <Link to={`/users/basics/${user.user_id}`}>
                    <FontAwesomeIcon icon="edit" size="lg" color="brown" />
                  </Link>
                </td>
                <td>
                  <Link to={`/users/systems/${user.username}`}>
                    <FontAwesomeIcon icon="cogs" size="lg" color="Blue" />
                  </Link>
                </td>
                <td>
                  <Link to={`/users/delete/${user.user_id}`}>
                    <FontAwesomeIcon icon="trash-alt" size="lg" color="grey" />
                  </Link>
         </td>*/}
                </tr>
              ))}
            </tbody>
          </table>

          {companyName === masterCompany && (
            <div
              style={{
                width: "600px",
                height: "5000px",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                marginBottom: "350px",
                zIndex: 1,
              }}
            >
              <hr />
              <form onSubmit={this.handleReRegister}>
                <label className="basicParagraph" htmlFor="">
                  Enter User's username to Re-register:
                </label>
                <input
                  value={this.state.userNameReRegister}
                  onChange={this.handleChangeUserName}
                  id="userLastName"
                  type="text"
                  className="form-control2"
                />

                <button
                  className="basicButton"
                  style={{ width: "200px", marginTop: "10px" }}
                >
                  Re-Register This User
                </button>
              </form>
              <hr />
              <div>
                <label
                  className="basicParagraph"
                  style={{ height: "30px", width: "150px" }}
                >
                  Select a search method:
                </label>
                <select
                  className="form-control2"
                  value={this.state.searchMethod}
                  onChange={this.handleSearchMethod}
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    border: "none",
                    borderRadius: "0px",
                    borderBottom: "2px solid lightgrey",
                  }}
                >
                  <option value="Select Search">Select Search</option>
                  <option value="by username">Username</option>
                  <option value="by firstname">First Name</option>
                  <option value="by lastname">Last Name</option>
                  <option value="by email">Email</option>
                </select>
              </div>
              {/* {this.state.searchMethod === "by lastname" && (*/}
              <form onSubmit={this.handleSubmit}>
                <label className="basicParagraph" htmlFor="">
                  Enter User's Information Here:
                </label>
                <input
                  value={this.state.userLastName}
                  onChange={this.handleChange}
                  id="userLastName"
                  type="text"
                  className="form-control2"
                />

                <button
                  className="basicButton"
                  style={{ width: "200px", marginTop: "10px" }}
                >
                  Search for User
                </button>
              </form>
              {/*  )}*/}

              <hr />

              {this.state.message === "User(s) were found." && (
                <div>
                  <h4>The following users were found:</h4>
                  <p className="basicParagraph" style={{ marginLeft: "8%" }}>
                    Note: This list is only available to{" "}
                    {this.props.match.params.id}:
                  </p>
                  <ScrollView>
                    <table
                      className="table"
                      style={{ paddingBottom: "100px", marginBottom: "100px" }}
                    >
                      <thead>
                        <tr>
                          <th>Username</th>
                          <th>Name</th>
                          <th>email</th>
                          <th>
                            <td>
                              <ExcelFile
                                filename="Simpl Global User Data"
                                element={
                                  <button
                                    className="btn btn-outline-light"
                                    style={{
                                      padding: "0px",
                                      float: "right",
                                      marginRight: 150,
                                      marginTop: "00px",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon="file-excel"
                                      size="lg"
                                      color="green"
                                      title="Export User info to excel"
                                    />
                                  </button>
                                }
                              >
                                <ExcelSheet
                                  data={this.state.excelDataSet}
                                  name="UserData"
                                >
                                  <ExcelColumn
                                    label="Username"
                                    value="userName"
                                  />
                                  <ExcelColumn
                                    label="First Name"
                                    value="firstName"
                                  />
                                  <ExcelColumn
                                    label="Last Name"
                                    value="lastName"
                                  />
                                  <ExcelColumn label="Email" value="email" />
                                  <ExcelColumn label="Phone" value="phone" />
                                  <ExcelColumn label="Admin" value="admin" />
                                  <ExcelColumn
                                    label="Verified"
                                    value="verified"
                                  />
                                  <ExcelColumn
                                    label="Company Name"
                                    value="company"
                                  />
                                  <ExcelColumn
                                    label="Created"
                                    value="created"
                                  />
                                  <ExcelColumn
                                    label="Updated"
                                    value="updated"
                                  />
                                </ExcelSheet>
                              </ExcelFile>
                            </td>{" "}
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.state.filteredUser.map((user) => (
                          <tr key={user.username}>
                            <td>{user.username}</td>
                            <td>
                              {user.firstName} {user.lastName}
                            </td>
                            <td>{user.emailAddress}</td>
                            <td>
                              <Link to={`/unregUser/${user.username}`}>
                                <FontAwesomeIcon
                                  icon="trash-alt"
                                  size="lg"
                                  color="red"
                                  title="Delete User"
                                />
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </ScrollView>
                </div>
              )}
            </div>
          )}
        </div>
        {/* <BackGroundRectangle />*/}
      </React.Fragment>
    );
  }
}

export default CompanyUsers;

class UserInfoModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <>
        <button
          onClick={this.handleShow}
          className="btn btn-outline-light"
          style={{ padding: "0", borderWidth: "0px" }}
        >
          <FontAwesomeIcon
            icon="info-circle"
            size="sm"
            color="tomato"
            title="User Information"
          />
        </button>

        <Modal
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              User Information:
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">
              Last Log-in: {this.props.lastLogin}
            </p>
            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />

            <p className="modalTextSmall">User Name: {this.props.username}</p>

            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">
              Name: {this.props.firstName} {this.props.lastName}
            </p>

            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">{this.props.emailAddress}</p>
            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">{this.props.phoneNumber}</p>
            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">{this.props.admin && "Admin: Yes"}</p>
            <p className="modalTextSmall">{!this.props.admin && "Admin: No"}</p>
            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">
              {this.props.verified && "Verified: Yes"}
            </p>
            <p className="modalTextSmall">
              {!this.props.verified && "Verified: No"}
            </p>
            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">Created: {this.props.created_at}</p>
            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">Updated: {this.props.updated_at}</p>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
