import React from "react";

const SqPerimeterSvg = (props) => (
  <svg
    style={{ margin: "0px", padding: "0px", width: "800px", height: "425px" }}
  >
    <svg
      width="1000px"
      height="1000px"
      id="ICONS"
      version="1.1"
      viewBox="0 0 1000 1000"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <g>
        <rect
          x="2px"
          y="2px"
          width="420px"
          height="420px"
          fill="none"
          stroke={props.rectColor}
          stroke-width="1"
        ></rect>
      </g>
    </svg>
  </svg>
);
export default SqPerimeterSvg;
