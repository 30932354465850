import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import { getUser, unregisterUser } from "../services/userService";
import { setJwt } from "../services/httpService";
import BackGroundRectangle from "../components/backgroundRect";

class EditPswd extends Form {
  state = {
    errors: {}
  };

  doSubmit = async () => {
    try {
      const jwt = localStorage.getItem("token");
      setJwt(jwt);
      await unregisterUser(this.props.match.params.id);
      window.location = "/login";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors: errors });
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <button
          style={{
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1
          }}
          onClick={() => this.doSubmit()}
          className="btn btn-danger btn-sm"
        >
          Confirm to Unregister
        </button>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default EditPswd;
