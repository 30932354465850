import React from "react";

const SwitchOnSvg = (props) => (
  <svg
    style={{ margin: "0px", padding: "0px", height: "90px", width: "200px" }}
  >
    <svg
      height="100px"
      width="auto"
      id="ICONS"
      version="1.1"
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <g>
        <rect
          x="2"
          y="2"
          width="80"
          height="50"
          fill="none"
          stroke={props.rectColor}
          stroke-width="2"
        ></rect>
        <text
          x="8"
          y="15"
          font-family="Verdana"
          font-size="12px"
          font-weight="normal"
          fill={props.textColor}
        >
          {props.name}
        </text>
      </g>
    </svg>

    <svg
      height="100px"
      width="auto"
      id="ICONS"
      version="1.1"
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="green"
        d="m69 39h-17.18c-.41-1.15-1.48-1.97-2.76-1.99l.12-.02c.54-.09.91-.61.82-1.15s-.61-.91-1.15-.82l-17.6 3.01c-.55-.62-1.34-1.02-2.24-1.02-1.3 0-2.4.84-2.82 2h-17.19c-.55 0-1 .45-1 1s.45 1 1 1h17.18c.41 1.16 1.51 2 2.82 2 1.65 0 3-1.35 3-3 0-.03 0-.05 0-.08l16-2.73c-1.16.42-2 1.51-2 2.81 0 1.65 1.35 3 3 3 1.3 0 2.4-.84 2.82-2h17.18c.55 0 1-.45 1-1s-.45-1-1-1zm-40 2c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm20 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
      />
    </svg>
  </svg>
);
export default SwitchOnSvg;
